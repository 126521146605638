import PropTypes from 'prop-types';
import { withStyles, IconButton, Grid } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const styles = (theme) => ({
  tabs: {
    position: 'fixed',
    width: '100%',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.primary3,
    height: 48,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    paddingLeft: theme.spacing(4),
    zIndex: 1101, // see https://material-ui.com/layout/basics/#z-index
    lineHeight: '48px',
  },
  tabText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    paddingLeft: theme.spacing(0),
    lineHeight: '48px',
  },
});

const VmsInfoBar = (props) => {
  const { classes, backMode, onBack, backModeText, buttonDataCy } = props;
  let tabPath = '';

  if (backMode) {
    tabPath = (
      <IconButton data-cy={buttonDataCy} onClick={onBack}>
        <ChevronLeft />
      </IconButton>
    );
  }
  return (
    <Grid container className={classes.tabs}>
      <Grid item>{tabPath}</Grid>
      <Grid item className={classes.tabText}>
        {backModeText}
      </Grid>
    </Grid>
  );
};

VmsInfoBar.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsInfoBar);
