import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PersonOutline from '@material-ui/icons/PersonOutline';
import SwipeableViews from 'react-swipeable-views';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import VisitorTab from './VisitorTab';
import VmsDialog from '../VmsDialog';
import DocsTab from './DocsTab';
import EventsTab from './EventsTab';
import {
  user_roles,
  user_status,
  validateLogin,
  validatePhoneNumber,
} from 'AppSettings';
import Auth from 'modules/Auth';
import * as EmailValidator from 'email-validator';
import moment from 'moment';
import GroupDocsTab from './GroupDocsTab';
import GroupEventsTab from './GroupEventsTab';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  tabs: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
  },
  tab: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.2,
  },
  tabIndicator: {
    height: 4,
    backgroundColor: theme.palette.tabIndicator,
  },
  tabContent: {
    flex: 1,
    width: '100%',
    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
});

const InviteDialog = (props) => {
  const {
    classes,
    theme,
    handleChangeTabs,
    handleChangeTabsIndex,
    handleChangeInvitationData,
    handleChangeFileInvitationData,
    handleHostSuggestionSelected,
    handleHostSuggestionsClearRequested,
    handleUserSuggestionsClearRequested,
    handleUserSuggestionSelected,
    handleEmailSuggestionsClearRequested,
    onSend,
    onClose,
    onSave,
    open,
    onRemoveEvent,
    onSecurityItemChange,
    selectedDate,
    handlePhoneSuggestionsClearRequested,
    handleAddVisitorToGroupVisit,
    handleRemoveVisitorFromGroupVisit,
    handleChangeCurrentVisitFromGroup,
    invitationState,
    newInvitation,
    invitation,
    visit_purposes,
    documents,
    companyData,
    userSuggestions,
    visitorTypes,
    currentTab,
    handleDragDrop,
    handleDragDropFile,
    loading,
    accompanyAVisit,
    confirmVisitor,
  } = props;
  const { personal_files } = invitation;
  const { isGroupInvitation, size, index, invitations, group } =
    invitationState;

  const now = moment();

  const userClosed = invitation.user_status === user_status.CLOSED;
  const visitClosed =
    moment(invitation.departure).isBefore(now.startOf('day')) ||
    (!isGroupInvitation ? userClosed : false);

  const emptyInvite = index === size && size === 0;
  const groupNameValid = isGroupInvitation ? group : true;

  const phoneIsValid = userClosed || !validatePhoneNumber(invitation.phone);
  const emailIsValid =
    userClosed ||
    !invitation.email ||
    EmailValidator.validate(invitation.email);
  const loginIsValid = invitation.login
    ? validateLogin(invitation.login)
    : true;

  const userInvalid =
    invitation.name || invitation.email || invitation.phone
      ? !invitation.name ||
        (!invitation.email && !invitation.phone) ||
        !phoneIsValid ||
        !emailIsValid ||
        !invitation.language ||
        !loginIsValid
      : emptyInvite;

  //delegate is valid if it is empty or if entered value is valide email address
  const delegateIsValid =
    !invitation.delegate ||
    !invitation.delegate.trim() ||
    EmailValidator.validate(invitation.delegate);

  const invitationInvalid =
    !invitation.arrival ||
    !invitation.host ||
    !invitation.host_id ||
    (invitation.wifi_enabled &&
      (!invitation.wifi_username || !invitation.wifi_password));

  const saveDisabled =
    invitationInvalid ||
    !delegateIsValid ||
    visitClosed ||
    userInvalid ||
    !groupNameValid;

  //Check if theres a user who is not closed, has a valid email and if he is in a group invite, he is commited to the list of invites
  const validEmailExists = isGroupInvitation
    ? invitations.find(
        (inv, i) =>
          inv.user_status !== user_status.CLOSED &&
          EmailValidator.validate(inv.email)
      )
    : EmailValidator.validate(invitation.email);

  const sendDisabled = userInvalid || saveDisabled || !validEmailExists;
  const isVisitor = Auth.getUser().role === user_roles.VISITOR;

  const accompanimentDoesntExist = Boolean(
    isGroupInvitation
      ? invitations.find(
          (inv) =>
            !inv.accompaniments.find((acc) =>
              moment(acc.accompanied_date).isSame(now, 'day')
            )
        )
      : !invitation.accompaniments.find((acc) =>
          moment(acc.accompanied_date).isSame(now, 'day')
        )
  );
  const accompanimentEnabled = Boolean(
    !saveDisabled &&
      invitation.visit_id &&
      invitation.accompanimentRequired &&
      accompanimentDoesntExist
  );

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle=""
      onClose={onClose}
      dialogHeaderIcon={<PersonOutline />}
      buttonIcon={<Close />}
      closeButtonDataCy="invitation-button-close"
      buttonText={i18n.t('save')}
      buttonAction={!isVisitor ? onSave : null}
      buttonDisabled={saveDisabled}
      buttonDataCy="invitation-button-save"
      buttonSendAction={!isVisitor ? onSend : null}
      buttonSendDisabled={sendDisabled}
      buttonSendDataCy="invitation-button-send"
      buttonDeleteAction={
        invitation.accompanimentRequired && invitation.visit_id
          ? accompanyAVisit
          : undefined
      }
      buttonDeleteDisabled={!accompanimentEnabled}
      buttonDeleteDataCy="invitation-button-delete"
      buttonDeleteText={i18n.t('invite_form_accompaniment')}
      loading={loading}
      buttonConfirmAction={
        invitation.confirmationRequired ? confirmVisitor : undefined
      }
      buttonConfirmText={i18n.t('confirm_visitor')}
    >
      <Tabs
        value={currentTab}
        onChange={handleChangeTabs}
        variant="fullWidth"
        classes={{ indicator: classes.tabIndicator }}
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          label={i18n.t('invite_form_tab_visitor')}
        />
        <Tab className={classes.tab} label={i18n.t('invite_form_tab_docs')} />
        {!newInvitation && (
          <Tab
            className={classes.tab}
            label={i18n.t('invite_form_tab_events')}
          />
        )}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={currentTab}
        className={classes.tabContent}
        onChangeIndex={handleChangeTabsIndex}
      >
        <VisitorTab
          currentTab={currentTab}
          isGroupInvitation={isGroupInvitation}
          userClosed={userClosed}
          visitClosed={visitClosed}
          changeData={handleChangeInvitationData}
          invitation={invitation}
          visitPurposes={visit_purposes}
          userSuggestions={userSuggestions}
          companyData={companyData}
          onUserSuggestionsClearRequested={handleUserSuggestionsClearRequested}
          onUserSuggestionSelected={handleUserSuggestionSelected}
          onEmailSuggestionsClearRequested={
            handleEmailSuggestionsClearRequested
          }
          onEmailSuggestionSelected={handleUserSuggestionSelected}
          onPhoneSuggestionsClearRequested={
            handlePhoneSuggestionsClearRequested
          }
          onHostSuggestionSelected={handleHostSuggestionSelected}
          onHostSuggestionClearRequested={handleHostSuggestionsClearRequested}
          invitationState={invitationState}
          handleAddVisitorToGroupVisit={handleAddVisitorToGroupVisit}
          handleRemoveVisitorFromGroupVisit={handleRemoveVisitorFromGroupVisit}
          handleChangeCurrentVisitFromGroup={handleChangeCurrentVisitFromGroup}
          accompanimentDoesntExist={accompanimentDoesntExist}
          phoneValid={phoneIsValid}
          emailValid={emailIsValid}
          loginValid={loginIsValid}
          delegateValid={delegateIsValid}
        />
        {!isGroupInvitation ? (
          <DocsTab
            currentTab={currentTab}
            userClosed={userClosed}
            visitClosed={visitClosed}
            changeData={handleChangeInvitationData}
            changeFileData={handleChangeFileInvitationData}
            onDragDropChange={handleDragDrop}
            onDragDropFileChange={handleDragDropFile}
            invitation={invitation}
            documents={documents}
            personal_files={personal_files}
            visitorTypes={visitorTypes}
          />
        ) : (
          <GroupDocsTab
            currentTab={currentTab}
            invitationState={invitationState}
            visitClosed={visitClosed}
            changeData={handleChangeInvitationData}
            changeFileData={handleChangeFileInvitationData}
            onDragDropChange={handleDragDrop}
            onDragDropFileChange={handleDragDropFile}
            invitation={invitation}
            documents={documents}
            visitorTypes={visitorTypes}
          />
        )}
        {!newInvitation ? (
          <>
            {!isGroupInvitation ? (
              <EventsTab
                currentTab={currentTab}
                userClosed={userClosed}
                visitClosed={visitClosed}
                securityItems={invitation.securityItems}
                emailLogs={invitation.emailLogs}
                events={invitation.events}
                onRemoveEvent={onRemoveEvent}
                onSecurityItemChange={onSecurityItemChange}
                selectedDate={selectedDate}
              />
            ) : (
              <GroupEventsTab
                currentTab={currentTab}
                visitClosed={visitClosed}
                invitationState={invitationState}
                onRemoveEvent={onRemoveEvent}
                onSecurityItemChange={onSecurityItemChange}
                selectedDate={selectedDate}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </SwipeableViews>
    </VmsDialog>
  );
};

InviteDialog.propTypes = {
  newInvitation: PropTypes.bool.isRequired,
  currentTab: PropTypes.number.isRequired,
  visitorTypes: PropTypes.array.isRequired,
  visit_purposes: PropTypes.object.isRequired,
  companyData: PropTypes.object,
  dragDropState: PropTypes.object,
  dragDropFileState: PropTypes.object,
  documents: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  handleChangeTabs: PropTypes.func.isRequired,
  handleChangeTabsIndex: PropTypes.func.isRequired,
  handleChangeInvitationData: PropTypes.func.isRequired,
  handleChangeFileInvitationData: PropTypes.func.isRequired,
  handleDragDrop: PropTypes.func.isRequired,
  handleDragDropFile: PropTypes.func.isRequired,
  handleUserSuggestionsClearRequested: PropTypes.func.isRequired,
  handleUserSuggestionSelected: PropTypes.func.isRequired,
  handleEmailSuggestionsClearRequested: PropTypes.func.isRequired,
  handlePhoneSuggestionsClearRequested: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  accompanyAVisit: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(InviteDialog);
