import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Modal, Paper } from '@material-ui/core';
import { VmsDialogHeader } from 'components';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import { elevation } from 'assets/UISettings';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme, 700),
  container: {
    margin: 0,
    padding: 2 * theme.spacing(),
    '& > li:first-child': {
      paddingTop: 0,
    },
    '& > li:last-child': {
      paddingBottom: 0,
    },
  },
  childContainer: {
    display: 'block',
    padding: theme.spacing() + 'px 0',
  },
  image: {
    maxWidth: 'min(100%, 600px)',
    padding: 2 * theme.spacing() + 'px 0',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ExploreMoreContainer = ({
  classes,
  children,
  open,
  onClose,
  title,
  icon,
  itemDataCy,
}) => {
  const renderChildren = useMemo(() => {
    return children.map((child, i) => (
      <li data-cy={itemDataCy} key={i} className={classes.childContainer}>
        {child}
      </li>
    ));
  }, [children, itemDataCy, classes.childContainer]);

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsDialogHeader
          title={title}
          onClose={onClose}
          icon={icon}
          closeButtonDataCy="explore-more-button-close"
        />
        <ul className={classes.container}>{renderChildren}</ul>
      </Paper>
    </Modal>
  );
};

ExploreMoreContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  itemDataCy: PropTypes.string,
};

export default memo(withStyles(styles)(ExploreMoreContainer));
