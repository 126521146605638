import PropTypes from 'prop-types';
import { withStyles, Grid, Paper, Typography, Switch } from '@material-ui/core';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Email from '@material-ui/icons/Email';
import Chat from '@material-ui/icons/Chat';
import { elevation } from '../../assets/UISettings';
import i18n from '../../assets/i18n';
import Auth from '../../modules/Auth';

const styles = (theme) => ({
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(),
    minHeight: 134,
    boxSizing: 'border-box',
    height: '100%',
  },
  row: {
    height: 42,
  },
  switch: {
    float: 'right',
  },
  icon: {
    color: theme.palette.primary4,
    paddingLeft: theme.spacing(),
    paddingBottom: 12,
    paddingTop: 12,
  },
  font: {
    color: theme.palette.primary4,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: 14,
  },
});

const ProfileCardSettingsHalf = (props) => {
  const { classes, push, email, sms, changePush, changeEmail, changeSms } =
    props;
  return (
    <Grid item xs={12} md={6} className={classes.grid}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <Grid
          container
          direction={'column'}
          justifyContent={'space-around'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid container direction={'row'} className={classes.row}>
            <Grid item xs={1}>
              <PhoneIphone className={classes.icon} />
            </Grid>
            <Grid item xs={9} className={classes.row}>
              <Typography variant="body1" className={classes.font}>
                {i18n.t('profile_settings_push_notification')}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.row}>
              <Switch
                className={classes.switch}
                color="primary"
                checked={push}
                onChange={changePush}
                value="pushNotification"
              />
            </Grid>
          </Grid>
          <Grid container direction={'row'} className={classes.row}>
            <Grid item xs={1} className={classes.row}>
              <Email className={classes.icon} />
            </Grid>
            <Grid item xs={9} className={classes.row}>
              <Typography variant="body1" className={classes.font}>
                {i18n.t('profile_settings_email_notification')}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.row}>
              {Auth.isUserEmployee() && (
                <Switch
                  className={classes.switch}
                  color="primary"
                  checked={email}
                  value="emailNotification"
                  disabled
                />
              )}
              {!Auth.isUserEmployee() && (
                <Switch
                  className={classes.switch}
                  color="primary"
                  checked={email}
                  onChange={changeEmail}
                  value="emailNotification"
                />
              )}
            </Grid>
          </Grid>
          <Grid container direction={'row'} className={classes.row}>
            <Grid item xs={1} className={classes.row}>
              <Chat className={classes.icon} />
            </Grid>
            <Grid item xs={9} className={classes.row}>
              <Typography variant="body1" className={classes.font}>
                {i18n.t('profile_settings_sms_notification')}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.row}>
              <Switch
                className={classes.switch}
                color="primary"
                checked={sms}
                onChange={changeSms}
                value="smsNotification"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ProfileCardSettingsHalf.propTypes = {
  classes: PropTypes.object.isRequired,
  push: PropTypes.bool.isRequired,
  email: PropTypes.bool.isRequired,
  sms: PropTypes.bool.isRequired,
  changePush: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changeSms: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfileCardSettingsHalf);
