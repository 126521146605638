import {
  withStyles,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyle from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const vmsDialogRowCheckBoxCollection = (props) => {
  const { label, iconLeft, onChange, classes, rowClass, items } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <Grid item style={{ alignSelf: 'self-start' }}>
          <div className={classes.icon}>{iconLeft}</div>
        </Grid>
      )}
      <Grid item className={classes.rowContent}>
        <FormControl className={classes.formControl} fullWidth>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup>
            {items.map((item, idx) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    disabled={item.disabled}
                    onChange={onChange}
                    value={item.value}
                  />
                }
                label={item.label}
                key={idx}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

vmsDialogRowCheckBoxCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.string,
  rowClass: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      checked: PropTypes.bool,
    })
  ).isRequired,
};

export default withStyles(styles)(vmsDialogRowCheckBoxCollection);
