/**
 * Represents base data model for UserDocumentModel data
 */
class UserDocumentDataModel {
  constructor(
    name,
    document_id,
    random_order_of_questions,
    number_of_questions
  ) {
    this.name = name;
    this.document_id = document_id;
    this.random_order_of_questions = random_order_of_questions;
    this.number_of_questions = number_of_questions;
  }

  /**
   * Creates JSON representation of QuestionModel object.
   *
   * @returns {string} JSON representation of QuestionModel object
   */
  toJSON() {
    const data = {
      name: this.name,
      document_id: this.document_id,
      random_order_of_questions: this.random_order_of_questions,
      number_of_questions: this.number_of_questions,
    };
    return JSON.stringify(data);
  }
}

UserDocumentDataModel.fromJson = function (json) {
  return new UserDocumentDataModel(
    json.name,
    json.document_id,
    json.random_order_of_questions,
    json.number_of_questions
  );
};

export default UserDocumentDataModel;
