import PropTypes from 'prop-types';
import { withStyles, Tooltip, Fab } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';

const styles = (theme) => ({
  downloadButton: {
    flex: '0 0 auto',
    color: theme.palette.white,
    float: 'right',
    top: '10%',
  },
});

function DownloadButton(props) {
  const { classes, title, onDownload, buttonDataCy } = props;

  return (
    <Tooltip title={title}>
      <Fab
        data-cy={buttonDataCy}
        size="small"
        color="primary"
        className={classes.downloadButton}
        onClick={onDownload}
      >
        <CloudDownload />
      </Fab>
    </Tooltip>
  );
}

DownloadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  buttonDataCy: PropTypes.string,
};

export default withStyles(styles)(DownloadButton);
