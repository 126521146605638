import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class ItineraryServiceImpl extends AbstractService {
  create(itinerary) {
    var endpoint = 'itineraries';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(endpoint, itinerary, config);
  }

  /**
   * Update visit.
   *
   */
  update(itinerary) {
    var endpoint = 'itineraries/' + itinerary._id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, itinerary, config);
  }

  getItineraryForVisit(visitId) {
    const endpoint = 'visits/' + visitId + '/itinerary/';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }
}

const ItineraryService = new ItineraryServiceImpl();

export default ItineraryService;
