import { withStyles, Grid, FormControl } from '@material-ui/core';
import { VmsDateTimePicker } from 'components';
import { date_formats } from 'AppSettings';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  iconRight: {
    color: theme.palette.primary6,
  },
  rowContent: {
    flex: 1,
  },
});

const VmsDialogRowDateTimePicker = (props) => {
  const {
    placeholder,
    dateValue,
    iconLeft,
    onChange,
    classes,
    rowClass,
    disabled,
    arrivalDisabled,
    minStartDate,
  } = props;
  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.iconLeft}>{iconLeft}</div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <FormControl className={classes.textField} fullWidth>
              <VmsDateTimePicker
                value={dateValue[0]}
                onChange={onChange[0]}
                placeholder={placeholder[0]}
                format={date_formats.full_date_short}
                classes={{
                  endAdornment: classes.iconRight,
                  inputField: classes.pickerTextColor,
                }}
                disabled={disabled || arrivalDisabled}
                minDate={minStartDate}
                minDateMessage=""
                name="timeFrom"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.textField} fullWidth>
              <VmsDateTimePicker
                value={dateValue[1]}
                onChange={onChange[1]}
                placeholder={placeholder[1]}
                format={date_formats.full_date_short}
                classes={{
                  endAdornment: classes.iconRight,
                  inputField: classes.pickerTextColor,
                }}
                disabled={disabled}
                minDate={minStartDate}
                minDateMessage=""
                name="timeTo"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

VmsDialogRowDateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.arrayOf(PropTypes.func),
  dateValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  ),
  disabled: PropTypes.bool,
  arrivalDisabled: PropTypes.bool,
  rowClass: PropTypes.string.isRequired,
  minStartDate: PropTypes.object,
};

export default withStyles(styles)(VmsDialogRowDateTimePicker);
