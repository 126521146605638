import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { VisitService, ItineraryService } from 'services';
import Auth from 'modules/Auth';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { ItineraryTypeDialog, VisitItineraryCard } from 'components';
import { routes, time_configs, tab_indexes } from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import i18n from 'assets/i18n';
import { withRouter } from 'react-router-dom';

class ItineraryPage extends Component {
  state = {
    upcommingVisits: [],
    addVisit: undefined,
    addType: undefined,
    addItineraryData: undefined,
    itineraries: {},
  };

  componentDidMount() {
    this.loadData();
    this.interval = setInterval(
      () => this.loadData(),
      time_configs.REFRESH_DATA
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadData = () => {
    const { updateHasData } = this.props;
    VisitService.getListOfUpcommingVisitor(Auth.getUser()._id)
      .then((visits) => {
        const itineraries = [];
        visits.forEach((visit) => {
          // Fetch from server
          itineraries.push(
            ItineraryService.getItineraryForVisit(visit._id)
              .then((itinerary) => {
                this.setState((prevState) => {
                  prevState.itineraries[visit._id] = itinerary;
                  return prevState;
                });
              })
              .catch(function (error) {
                console.log(error);
                openSnackbar(error.message);
              })
          );
        });
        updateHasData(tab_indexes.ITINERARY, visits.length > 0 ? true : false);
        Promise.all(itineraries).then((values) => {
          this.setState({
            upcommingVisits: visits,
          });
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleAdd = (type, itinerary, visitId) => {
    this.setState({
      addVisit: visitId,
      addType: type,
      addItineraryData: itinerary,
    });
  };

  handleUpdate = (item, itinerary, visitId) => {
    this.props.history.push(routes.ITINERARY_DETAIL, {
      visitId: visitId,
      item: item,
      itinerary: itinerary,
    });
    this.props.history.push(routes.ITINERARY_DETAIL, {
      visitId: visitId,
      item: item,
      itinerary: itinerary,
    });
    this.props.history.go(-1); //This is a provisionary hack, idk how to fix it properly
    //History works everywhere but here
  };

  handleDone = (type, visitId) => {
    let itinerary = this.state.itineraries[visitId];
    if (!itinerary._id) {
      itinerary.visit = visitId;
      itinerary[type] = {
        completed: true,
      };
      ItineraryService.create(itinerary)
        .then(() => {
          openSnackbar(i18n.t('saved'));
          this.loadData();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      itinerary[type].completed = true;
      ItineraryService.update(itinerary)
        .then(() => {
          openSnackbar(i18n.t('saved'));
          this.loadData();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleCloseDialogs = () => {
    this.setState({
      addVisit: undefined,
      addType: undefined,
      addItineraryData: undefined,
    });
  };

  handleAddItineraryItem = (addType, itemType) => (event) => {
    let visitId = this.state.addVisit;
    let item = {
      category: addType,
      itemType: itemType,
      operator: '',
      number: '',
      place_departure: '',
      place_arrival: '',
      date_departure: null,
      date_arrival: null,
      status: '',
      airplane: '',
    };

    this.props.history.push(routes.ITINERARY_DETAIL, {
      visitId: visitId,
      item: item,
      itinerary: this.state.addItineraryData,
    });
  };

  handleStepClicked = (step, visitId) => {
    let activeSteps = (this.props.location.state || {}).activeSteps || {};
    let location = this.props.location;
    activeSteps[visitId] = step;
    if (location.state === undefined) {
      location.state = {
        activeSteps: activeSteps,
      };
    } else {
      location.state.activeSteps = activeSteps;
    }
    this.props.history.replace(location.pathname, location.state);
  };

  render() {
    const { upcommingVisits, addVisit, addType } = this.state;
    const { selectedVisit, onVisitChange } = this.props;

    return (
      <Aux>
        <Grid container direction="column">
          {upcommingVisits.map((visit) => {
            return (
              <VisitItineraryCard
                key={visit._id}
                visit={visit}
                itinerary={this.state.itineraries[visit._id]}
                addClicked={this.handleAdd}
                updateClicked={this.handleUpdate}
                doneClicked={this.handleDone}
                stepClicked={this.handleStepClicked}
                activeStep={
                  ((this.props.location.state || {}).activeSteps || {})[
                    visit._id
                  ]
                }
                selectedVisit={selectedVisit}
                onVisitChange={onVisitChange}
                itemDataCy="itinerary-list-item-itinerary"
              />
            );
          })}
        </Grid>
        <ItineraryTypeDialog
          open={addVisit !== undefined}
          onClose={this.handleCloseDialogs}
          onTypeAdd={this.handleAddItineraryItem}
          type={addType}
        />
      </Aux>
    );
  }
}

ItineraryPage.propTypes = {
  selectedVisit: PropTypes.object,
  onVisitChange: PropTypes.func.isRequired,
  updateHasData: PropTypes.func,
};

export default withRouter(ItineraryPage);
