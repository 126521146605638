import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import { MeetingRoomDetail, MeetingRoomCalendar } from 'components';
import commonStyles from 'assets/jss/commonStyles';
import {
  UserService,
  MeetingService,
  ConfigurationService,
  DocumentService,
} from 'services';
import Auth from 'modules/Auth';
import moment from 'moment';
import {
  time_configs,
  isMobileApp,
  isiOSMobileApp,
  vms_application_routes,
  configuration_settings_type,
  configuration_group,
} from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import buttonStyle from 'assets/jss/buttonStyle';
import classNames from 'classnames';
import i18n from 'assets/i18n';
import QRDialogReader from 'containers/meetingRoom/QRDialogReader';
import logoAppBarMobile from 'assets/images/logo_appbar_mobile.png';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...buttonStyle(theme),
  root: {
    position: 'fixed',
    width: 'calc(100% - ' + theme.spacing(8) + 'px)',
    height: 'calc(100% - ' + theme.spacing(8) + 'px)',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primaryIcon,
  },
  colorSecondary: {
    backgroundColor: theme.palette.red,
  },
  meetingRoomBody: {
    width: 'calc(100% - ' + theme.spacing(4) + 'px)',
    height: 'calc(100% - ' + theme.spacing(4) + 'px)',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
  },
  logo: {
    position: 'fixed',
    backgroundColor: theme.palette.white,
    width: 406,
    height: 64,
    left: 'calc(50% - 203px)',
  },
  logoImg: {
    height: 39.6,
    padding: theme.spacing(),
  },
  calendarContainer: {
    height: '100%',
  },
  cameraIcon: {
    marginRight: theme.spacing(),
  },
  contentRight: {
    marginRight: 8,
  },
});

class MeetingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualDate: moment(),
      meetings: [],
      todayMeetings: [],
      isSelected: false,
      selectedDate: moment(),
      countOfclickedOnLogo: 0,
      cameraId: 'user',
      openBeforeMinutes: 0,
      qrDialogState: {
        QRResult: i18n.t('meeting_room_insert_qrcode'),
        isSearch: false,
        openQRDialog: false,
        user: {},
      },
      companyLogos: {},
    };
    this.ongoingMeeting = undefined;
    this.nextMeeting = undefined;
  }

  componentDidMount() {
    this.loadLogo();
    if (isMobileApp() === true) {
      setTimeout(() => {
        window.location =
          vms_application_routes.SCREEN_ALWAYS_ON_ENABLED + 'true';
      }, 2000);
    }

    UserService.getUser(Auth.getUser()._id)
      .then((user) => {
        this.setState((prevState) => {
          prevState.user = user;
          prevState.qrDialogState.user = user;
          return prevState;
        });
        if (Auth.isUserMeetingRoom()) {
          UserService.getUser(Auth.getUser()._id)
            .then((user) => {
              this.setState((prevState) => {
                prevState.user = user;
                prevState.qrDialogState.user = user;
                return prevState;
              });
              if (
                isiOSMobileApp() === true &&
                user.lockUrl &&
                !user.lockUrl.startsWith('http:')
              ) {
                setTimeout(() => {
                  window.location =
                    vms_application_routes.CONNECT_TO_BLE_LOCK + user.lockUrl;
                }, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    this.interval = setInterval(() => {
      this.setState({ actualDate: moment() });
    }, 10000); //refresh every 10 seconds
    this.loadAllMeetings();
    this.interval = setInterval(
      () => this.loadAllMeetings(),
      time_configs.REFRESH_DATA
    );
  }

  loadLogo() {
    const logos = {};
    logos.logo_mobile = logoAppBarMobile;

    DocumentService.getCompanyLogos()
      .then((response) => {
        response.forEach((logo) => {
          logos[logo.name] = DocumentService.api + logo.downloadUrl;
        });

        this.setState({
          companyLogos: logos,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  loadAllMeetings = () => {
    this.loadMeetings(this.state.selectedDate);
    this.loadTodayMeetings();
    this.loadConfiguration();
  };

  loadConfiguration = () => {
    // Also load configuration change
    ConfigurationService.getConfigurationsForGroup(configuration_group.SETTINGS)
      .then((response) => {
        const minutes = response.find((c) => {
          return (
            c.key ===
            configuration_settings_type.OPEN_MEETINGROOM_BEFORE_MINUTES
          );
        });
        if (minutes) {
          this.setState({
            openBeforeMinutes: minutes.value,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadMeetings = (date) => {
    const self = this;
    MeetingService.getMeetingsForRoomAndDate(Auth.getUser().name, date)
      .then(function (response) {
        const visitors = response;
        self.setState({
          meetings: response,
        });
        console.log(visitors);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadTodayMeetings = () => {
    const self = this;
    MeetingService.getMeetingsForRoomAndDate(Auth.getUser().name, moment())
      .then(function (response) {
        const visitors = response;
        self.setState({
          todayMeetings: response,
        });
        console.log(visitors);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleChangeDate = (date) => {
    if (moment().format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
      this.setState({
        selectedDate: date,
        isSelected: true,
      });
      this.loadMeetings(date);
      this.interval = setInterval(() => {
        this.setState({ isSelected: false });
        this.loadAllMeetings();
      }, time_configs.RETURN_TO_CURRENT_DATE_MEETINGROOM);
    } else {
      this.setState({
        selectedDate: moment(),
        isSelected: false,
      });
    }
  };

  getOngoingMeeting = () => {
    const { todayMeetings } = this.state;
    return todayMeetings.find((m) => {
      return (
        moment(m.startTime) <= moment() &&
        moment(m.startTime).add(m.duration, 'minutes') > moment()
      );
    });
  };

  getNextMeeting = () => {
    const { todayMeetings } = this.state;
    for (let i = 0; i < todayMeetings.length; i++) {
      if (moment(todayMeetings[i].startTime) > moment()) {
        return todayMeetings[i];
      }
    }
  };

  logOut = () => {
    const { countOfclickedOnLogo } = this.state;
    this.setState((state) => {
      return { countOfclickedOnLogo: state.countOfclickedOnLogo + 1 };
    });
    if (countOfclickedOnLogo === 4) {
      this.props.history.push('/logout');
    }
  };

  callMobileNativeService = (enabled) => {
    window.location = vms_application_routes.QR_READING + enabled;
  };

  handleOpenQRDialog = () => (event) => {
    this.callMobileNativeService(true);
    this.setState((prevState) => {
      return (prevState.qrDialogState.openQRDialog = true);
    });
  };

  handleOnChangeQRDialogReader = (qrDialogState) => {
    this.setState({
      qrDialogState: qrDialogState,
    });
  };

  handleValidateUser = (userId) => {
    console.log('handleValidateUser', userId);
    if (this.ongoingMeeting) {
      let participants = JSON.parse(
        JSON.stringify(this.ongoingMeeting.participants || [])
      );
      participants.push({ user: this.ongoingMeeting.organiser }); // Allow access also for organiser
      return participants.find((p) => p.user._id === userId) !== undefined;
    }
    if (this.nextMeeting) {
      let participants = JSON.parse(
        JSON.stringify(this.nextMeeting.participants || [])
      );
      participants.push({ user: this.nextMeeting.organiser }); // Allow access also for organiser
      const allowOpenStartDate = moment(this.nextMeeting.startDate)
        .subtract(this.state.openBeforeMinutes, 'minutes')
        .toDate();
      const now = new Date();
      return (
        participants.find((p) => p.user._id === userId) !== undefined &&
        allowOpenStartDate < now
      );
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    const {
      user,
      selectedDate,
      isSelected,
      actualDate,
      meetings,
      todayMeetings,
      qrDialogState,
      companyLogos,
    } = this.state;
    this.ongoingMeeting = this.getOngoingMeeting();
    this.nextMeeting = this.getNextMeeting();

    const ongoingMeeting = this.ongoingMeeting;
    const nextMeeting = this.nextMeeting;

    const isOngoing = ongoingMeeting !== undefined;
    return (
      <Aux>
        <div
          className={classNames(
            classes.root,
            isOngoing ? classes.colorSecondary : classes.colorPrimary
          )}
        >
          <div className={classes.meetingRoomBody}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className={classes.gridFullSize}
            >
              <Grid item>
                {(ongoingMeeting || nextMeeting) && (
                  <MeetingRoomDetail
                    onGoing={isOngoing}
                    meeting={isOngoing === true ? ongoingMeeting : nextMeeting}
                  />
                )}
              </Grid>
              <Grid item className={classes.contentRight}>
                <Grid
                  container
                  direction="column"
                  className={classes.calendarContainer}
                >
                  <Grid item style={{ flex: 1 }}>
                    <MeetingRoomCalendar
                      meetings={isSelected === true ? meetings : todayMeetings}
                      user={user}
                      selectedDate={
                        isSelected === true ? selectedDate : actualDate
                      }
                      changeDate={this.handleChangeDate}
                      scanClicked={this.handleOpenQRDialog()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.logo}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                data-cy="meeting-room-image-logo"
                src={companyLogos.logo_mobile || logoAppBarMobile}
                alt="Company logo"
                className={classes.logoImg}
                onClick={this.logOut}
              />
            </Grid>
          </Grid>
        </div>
        <QRDialogReader
          qrDialogData={qrDialogState}
          onChange={this.handleOnChangeQRDialogReader}
          rejectUsed={false}
          validateUser={this.handleValidateUser}
          successMessage={i18n.t('snackbar_entry_success_welcome')}
          snackBarVariant={'big'}
          validateOnly
        />
      </Aux>
    );
  }
}

MeetingRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingRoom);
