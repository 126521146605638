import {
  WiDaySunny,
  WiNightClear,
  WiRain,
  WiSnow,
  WiFog,
  WiCloud,
  WiDayCloudy,
  WiNightAltCloudy,
  WiThunderstorm,
  WiDaySunnyOvercast,
  WiRainMix,
  WiNightAltPartlyCloudy,
  WiNightAltCloudyHigh,
  WiNightAltShowers,
  WiNightAltRain,
  WiNightAltThunderstorm,
  WiNightAltSnow,
  WiNightFog,
} from 'weather-icons-react';

const weatherMap = {
  '01d': WiDaySunny,
  '01n': WiNightClear,
  '10d': WiRain,
  '13d': WiSnow,
  '50d': WiFog,
  '04d': WiCloud,
  '03d': WiDayCloudy,
  '04n': WiNightAltCloudy,
  '11d': WiThunderstorm,
  '02d': WiDaySunnyOvercast,
  '09d': WiRainMix,
  '02n': WiNightAltPartlyCloudy,
  '03n': WiNightAltCloudyHigh,
  '09n': WiNightAltShowers,
  '10n': WiNightAltRain,
  '11n': WiNightAltThunderstorm,
  '13n': WiNightAltSnow,
  '50n': WiNightFog,
};

export const getWeatherIcon = (id) => {
  const icon = weatherMap[id];
  return icon ? icon : weatherMap['01d'];
};
