import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Paper,
  IconButton,
  Modal,
  MenuItem,
  Menu,
  ListItemIcon,
} from '@material-ui/core';
import {
  Event,
  FolderOpen,
  InsertDriveFile,
  CheckBox,
  CheckBoxOutlineBlank,
  Visibility,
  ArrowDropDown,
  ArrowDropUp,
  Language,
  Check,
} from '@material-ui/icons';
import { elevation } from 'assets/UISettings';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import {
  VmsDialogRowSelect,
  VmsInput,
  VmsDialogRowDragDrop,
  VmsDialogFooter,
  VmsDialogHeader,
} from 'components';
import DocumentService from 'services/DocumentService';
import {
  DOCUMENT_TYPE_INTERNAL_AND_IMAGES,
  document_accepted_mime_types,
  language_mapping,
} from 'AppSettings';
import modalStyle from 'assets/jss/modalStyle';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import locales from 'assets/momentlocales';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  iconInvisible: {
    color: theme.palette.common.white,
  },
  row: {
    padding: theme.spacing(2),
  },
  row2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toggleGroup: {
    marginBottom: '10%',
  },
  icon: {
    width: 30,
    height: 20,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  checkedFlag: {
    width: 40,
    height: 30,
    color: theme.palette.primary6,
    marginLeft: theme.spacing(2),
  },
  flag: {
    width: 40,
    height: 30,
  },
  menuFlag: {
    witdh: 30,
    height: 20,
    boxShadow: theme.shadows[3],
  },
});

class UploadDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      fileStatus: '',
      documentData: {
        name: '',
        documentType: '',
        publicDoc: false,
        required_for_entrance: false,
        expires_in: '',
        format: 'portrait',
        language: 'en',
      },
      languageMenuOpen: false,
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.changeData = this.changeData.bind(this);
  }

  onDrop(files) {
    this.setState({
      files,
    });
  }

  onDropAccepted() {
    this.setState({
      fileStatus: 'accepted',
    });
  }

  onDropRejected() {
    this.setState({
      fileStatus: 'rejected',
    });
  }

  handleUpload() {
    let self = this;
    const { files, documentData } = this.state;
    const {
      name,
      documentType,
      publicDoc,
      required_for_entrance,
      expires_in,
      format,
      language,
    } = documentData;

    if (files.length > 0) {
      const file = files[0];
      this.setState({
        uploading: true,
      });
      DocumentService.uploadDocument(
        name,
        documentType,
        '',
        publicDoc,
        file,
        required_for_entrance,
        expires_in,
        format,
        language
      )
        .then(function () {
          openSnackbar(i18n.t('saved'));
          self.handleClose();
        })
        .catch(function (error) {
          console.log(error);
          self.setState({
            uploading: false,
          });
          openSnackbar(error.message);
        });
    }
  }

  handleClose() {
    this.setState({
      files: [],
      uploading: false,
      fileStatus: '',
      documentData: {
        name: '',
        documentType: '',
        publicDoc: false,
        format: '',
        language: '',
      },
    });
    this.props.onClose();
  }

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeData(event, name, value) {
    const field = name ? name : event.target.name;
    const documentData = this.state.documentData;
    documentData[field] = value ? value : event.target.value;
    this.setState({
      documentData: documentData,
    });
  }

  changeRequiredForEntrance = () => {
    const { documentData } = this.state;
    const { required_for_entrance } = documentData;

    const newData = { ...documentData };
    newData.required_for_entrance = !required_for_entrance;

    this.setState({
      documentData: newData,
    });
  };

  handleClickPublic() {
    const documentData = this.state.documentData;
    documentData.publicDoc = !documentData.publicDoc;
    this.setState({
      documentData: documentData,
    });
  }

  handleOpenLanguageMenu(e) {
    this.setState({
      languageMenuOpen: e.currentTarget,
    });
  }

  render() {
    const { classes, documentTypes } = this.props;
    const { files, uploading, documentData, fileStatus, languageMenuOpen } =
      this.state;
    const { name, documentType, publicDoc, format } = documentData;
    const uploadDisabled = !documentType || files.length === 0 || !name;
    const docTypes = documentTypes || { key: [], value: [] };
    const notInternalOrImage = !(
      DOCUMENT_TYPE_INTERNAL_AND_IMAGES.includes(documentType) ||
      documentType === ''
    );
    return (
      <Modal
        className={classes.modal}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Paper className={classes.paper} elevation={elevation.light}>
          <VmsDialogHeader
            title={i18n.t('document_upload_header')}
            onClose={this.handleClose.bind(this)}
            icon={<Event />}
          />
          <Grid container>
            <VmsDialogRowSelect
              rowClass={classes.row}
              iconLeft={<FolderOpen />}
              placeholder={i18n.t('document_upload_type')}
              onChange={(event) => {
                this.changeData(event, 'documentType');
              }}
              selectTypes={docTypes}
              selectedType={documentType}
              inputName="documentType"
              required
            />
            <VmsInput
              rowClass={classes.row2}
              iconLeft={<InsertDriveFile />}
              textValue={name}
              inputName="name"
              placeholder={i18n.t('document_upload_name')}
              inputLabel={i18n.t('document_upload_name')}
              onChange={this.changeData}
              required
            />
            {notInternalOrImage ? (
              <>
                <VmsInput
                  rowClass={classes.row}
                  iconLeft={<Language style={{ color: '#c70039' }} />}
                  iconRight={
                    <>
                      <img
                        className={classes.menuFlag}
                        src={language_mapping[documentData.language]}
                        alt={i18n.t(`menu_language_${documentData.language}`)}
                      />
                      <IconButton style={{ paddingRight: '17px' }}>
                        {!languageMenuOpen ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )}
                      </IconButton>
                    </>
                  }
                  textValue={
                    documentData.language
                      ? i18n.t('menu_language_' + documentData.language) +
                        ` (${locales[documentData.language]})`
                      : i18n.t('preferred_language')
                  }
                  inputLabel={i18n.t('menu_language')}
                  onClick={(e) => this.handleOpenLanguageMenu(e)}
                />
                <Menu
                  variant="menu"
                  open={Boolean(languageMenuOpen)}
                  anchorEl={languageMenuOpen}
                  getContentAnchorEl={null}
                  disableAutoFocusItem
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => {
                    this.setState({ languageMenuOpen: false });
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: '50%',
                    },
                  }}
                >
                  {Object.keys(language_mapping).map((lang) => (
                    <MenuItem
                      key={lang}
                      value={lang}
                      onClick={(event) => {
                        this.setState({ languageMenuOpen: false });
                        this.changeData(event, 'language', lang);
                      }}
                    >
                      {documentData.language === lang ? (
                        <ListItemIcon className={classes.checkedFlag}>
                          <Check />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className={classes.icon}>
                          <Check />
                        </ListItemIcon>
                      )}
                      <img
                        className={classes.flag}
                        src={language_mapping[lang]}
                        alt={i18n.t(`menu_language_${lang}`)}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : null}
            <VmsInput
              rowClass={classes.row}
              iconLeft={<InsertDriveFile />}
              iconRight={
                <ToggleButtonGroup
                  className={classes.toggleGroup}
                  size="small"
                  color="orange"
                  value={format ? format : 'portrait'}
                >
                  <ToggleButton
                    onClick={(event) => {
                      this.changeData(event, 'format', 'portrait');
                    }}
                    name="format"
                    value="portrait"
                  >
                    {i18n.t('portrait')}
                  </ToggleButton>
                  <ToggleButton
                    onClick={(event) => {
                      this.changeData(event, 'format', 'landscape');
                    }}
                    name="format"
                    value="landscape"
                  >
                    {i18n.t('landscape')}
                  </ToggleButton>
                </ToggleButtonGroup>
              }
              textValue={i18n.t('document_format')}
              inputName="format"
              placeholder={i18n.t('document_format')}
              inputLabel={i18n.t('document_format')}
            />
            <VmsInput
              rowClass={classes.row}
              iconLeft={<Visibility className={classes.iconInvisible} />}
              iconRight={
                <IconButton onClick={this.handleClickPublic.bind(this)}>
                  {publicDoc ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </IconButton>
              }
              textValue={i18n.t('document_upload_public')}
              inputLabel={i18n.t('document_upload_public')}
              inputName="upload_document"
            />
            <VmsDialogRowDragDrop
              rowClass={classes.row}
              files={files}
              fileStatus={fileStatus}
              document_accepted_mime_types={document_accepted_mime_types}
              uploading={uploading}
              onDropAccepted={this.onDropAccepted.bind(this)}
              onDropRejected={this.onDropRejected.bind(this)}
              onDrop={this.onDrop.bind(this)}
            />
            <VmsDialogFooter
              onSave={this.handleUpload}
              saveDisabled={uploadDisabled || uploading}
            />
          </Grid>
        </Paper>
      </Modal>
    );
  }
}

UploadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documentTypes: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default withStyles(styles)(UploadDialog);
