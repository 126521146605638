import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import { InputAdornment, withStyles } from '@material-ui/core';
import DateRange from '@material-ui/icons/DateRange';
import { date_formats } from '../../../AppSettings';
import { VmsLocalize } from '../date-time';
import { useTranslation } from 'react-i18next';
import { browser_language } from 'AppSettings';

const styles = (theme) => {
  return {
    dateTimePickerField: {
      width: '100%',
    },
    endAdornment: {
      color: theme.palette.primary4,
      display: 'flex',
      alignSelf: 'center',
    },
    inputField: {
      color: theme.palette.primary4,
    },
  };
};

const VmsDateTimePicker = (props) => {
  const usedLanguage = browser_language();
  const [t] = useTranslation();
  const {
    classes,
    value,
    onChange,
    placeholder,
    disabled,
    minDate,
    minDateMessage,
    name,
  } = props;
  const format = props.format || date_formats.full_date_long;

  const onDateChange = (date) => {
    // if date is provided clear seconds and milliseconds and
    // convert it from moment object to Date object
    var newDate = date ? date.seconds(0).milliseconds(0).toDate() : null;
    onChange(newDate);
  };

  return (
    <VmsLocalize>
      <DateTimePicker
        disabled={disabled}
        className={classes.dateTimePickerField}
        minDate={minDate}
        minDateMessage={minDateMessage}
        value={value}
        format={format}
        clearable
        ampm={usedLanguage === 'en' || usedLanguage === 'ph'}
        onChange={onDateChange}
        InputProps={{
          name,
          endAdornment: (
            <InputAdornment className={classes.endAdornment} position="end">
              <DateRange />
            </InputAdornment>
          ),
          classes: {
            input: classes.inputField,
          },
          placeholder: placeholder,
        }}
        clearLabel={
          <span data-cy="date-time-picker-button-clear">{t('clear')}</span>
        }
        cancelLabel={
          <span data-cy="date-time-picker-button-cancel">{t('cancel')}</span>
        }
        okLabel={<span data-cy="date-time-picker-button-ok">{t('ok')}</span>}
      />
    </VmsLocalize>
  );
};

VmsDateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired, // calls onChange event with new 'date' as parameter
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // date value in Date format, can be null
  placeholder: PropTypes.string, // if value is null, picker shows placeholder
  format: PropTypes.string, //  format of date value within text field
  disabled: PropTypes.bool, // tells if picker should be disabled
  minDate: PropTypes.object, // minimal selectable date
  minDateMessage: PropTypes.string, // error message shown if selected date is lower than min date
  name: PropTypes.string,
};

export default withStyles(styles)(VmsDateTimePicker);
