import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import { VisitService } from 'services';
import Auth from 'modules/Auth';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { VisitCard } from 'components';
import { time_configs, tab_indexes, visit_card_modes } from 'AppSettings';

const styles = () => ({
  emptyView: {
    height: 'calc(100vh - 20vh)',
    display: 'flex',
  },
  emptyViewImage: {
    width: '100%',
    margin: 'auto',
  },
});

class VisitPage extends Component {
  state = {
    upcommingVisits: [],
  };

  componentDidMount() {
    this.loadVisits();
    this.interval = setInterval(
      () => this.loadVisits(),
      time_configs.REFRESH_DATA
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadVisits = () => {
    const { selectedVisit, onVisitChange, updateHasData } = this.props;
    VisitService.getListOfUpcommingVisitor(Auth.getUser()._id)
      .then((response) => {
        let selectedVisitClosed = false;
        if (selectedVisit) {
          if (!response.find((visit) => visit._id === selectedVisit._id)) {
            selectedVisitClosed = true;
          }
        }

        if (!selectedVisit || selectedVisitClosed) {
          if (response.length > 0) {
            onVisitChange(response[0]); //Set first visit as selected
          } else {
            onVisitChange(null);
          }
        }
        this.setState({
          upcommingVisits: response,
        });
        updateHasData(tab_indexes.VISIT, response.length > 0 ? true : false);
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  render() {
    const { upcommingVisits } = this.state;
    const { classes, selectedVisit, onVisitChange } = this.props;

    return (
      <Grid container direction="column" className={classes.content}>
        {upcommingVisits.map((visit, i) => {
          const isSelected = (selectedVisit || {})._id === visit._id;

          return (
            <VisitCard
              key={visit._id}
              index={i}
              mode={visit_card_modes.VISITOR}
              visit={visit}
              isSelected={isSelected}
              onVisitChange={onVisitChange}
              timezone={visit.timezone}
            />
          );
        })}
      </Grid>
    );
  }
}

VisitPage.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedVisit: PropTypes.object,
  onVisitChange: PropTypes.func.isRequired,
  updateHasData: PropTypes.func,
};

export default withStyles(styles)(VisitPage);
