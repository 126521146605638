import { Component } from 'react';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import {
  withStyles,
  Grid,
  IconButton,
  Tooltip,
  Box,
  Divider,
} from '@material-ui/core';
import { openSnackbar } from 'components/common/bars/SnackBar';
import LogsService from 'services/LogsService';
import i18n from 'assets/i18n';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import LogsTable from './LogsTable';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined';
import produce from 'immer';
import { emptySettingSearchState } from '../SettingsPage';

const styles = (theme) => ({
  accordion: {
    width: 'auto',
    backgroundColor: theme.palette.primary3,
    transition: 'none',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  mainBox: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  icons: {
    color: theme.palette.primaryIcon,
    paddingRight: theme.spacing(),
  },
  typography: {
    wordBreak: 'break-word',
  },

  accordionDetails: {
    overflow: 'auto',
    flexGrow: 1,
  },
  accordionSumary: {
    cursor: 'default',
  },
  accordionExpand: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1',
  },
  wrapper: {
    height: '100%',
    overflow: 'auto',
  },
  content: {
    margin: 0,
    cursor: 'default',
  },
  transform: {
    transform: 'rotate(180deg)',
  },
});

class LogsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      logsNames: [],
      fetchLogs: false,
      interval: undefined,
      accordionToggle: false,
      idOfLogs: 1,
      highlightedId: undefined,
      scrollableTarget: undefined,
    };
  }

  componentDidMount() {
    this.loadLogsFilesStats();
    this.loadlogs();
    this.setSearchSettings();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.emptySearchState();
  }

  loadlogs = () => {
    LogsService.getLogs()
      .then((results) => {
        const logs = [];
        let idOfLogs = this.state.idOfLogs;
        for (let i = 0; i < results.length; i++) {
          const data = results[i];
          data.id = idOfLogs;
          idOfLogs++;
          logs.push(data);
        }
        this.setState({ logs, idOfLogs });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadNewLogs = () => {
    const lastLogDate = this.state.logs[0].time;
    LogsService.getLogs(lastLogDate)
      .then((results) => {
        this.setState((prevState) =>
          produce(prevState, (draft) => {
            for (let i = results.length - 1; i >= 0; i--) {
              draft.logs.pop();
              results[i].id = draft.idOfLogs;
              draft.logs.unshift(results[i]);
              draft.idOfLogs++;
            }
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadLogsFilesStats = () => {
    LogsService.getLogsFiles()
      .then((results) => {
        this.setState({ logsNames: results });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  fetchLogsToogle = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.fetchLogs = !draft.fetchLogs;
        draft.interval = draft.fetchLogs
          ? setInterval(() => this.loadNewLogs(), 5000)
          : clearInterval(draft.interval);
      })
    );
  };

  handleAccordionOnClick = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.accordionToggle = !draft.accordionToggle;
      })
    );
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService = this.getLogsSuggestion;
      settingSearchState.onSuggestionsClearRequested =
        this.handleDeviceSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleDeviceSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleDeviceSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.data;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return this.handleFilterSuggestion(response);
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleFilterSuggestion = (logs) => {
    const { settingSearchState } = this.props;
    const filteredlogs = logs.filter((log) => {
      return log.data.includes(settingSearchState.searchValue);
    });
    return filteredlogs;
  };

  handleDeviceSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: undefined,
    });
  };

  handleDeviceSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.setState({
        highlightedId: suggestion.id,
      });
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.data;
      onChangeSearch(settingSearchState);
    }
  };

  handleDeviceSuggestionChange = (name, value) => (event) => {
    const field = name ?? event.target.name;
    const fieldValue = value ?? event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  getLogsSuggestion = async () => {
    const { logs } = this.state;
    const data = [];
    for (const line in logs) {
      let log = '';
      log =
        logs[line].time +
        ' host: ' +
        logs[line].hostname +
        ' level: ' +
        logs[line].level;
      if (logs[line].userId) {
        log = log + ' userID: ' + logs[line].userId;
      }
      if (logs[line].userName) {
        log = log + ' userName: ' + logs[line].userName;
      }
      if (logs[line].method) {
        log = log + ' method: ' + logs[line].method;
      }
      if (logs[line].tenantId) {
        log = log + ' tenantID: ' + logs[line].tenantId;
      }
      if (logs[line].url) {
        log = log + ' url: ' + logs[line].url;
      }
      if (logs[line].pid) {
        log = log + ' pid: ' + logs[line].pid;
      }
      if (logs[line].msg) {
        log = log + ' msg: ' + logs[line].msg;
      }
      if (logs[line].id) {
        log = log + ' logsId: ' + logs[line].id;
      }
      data.push({ data: log, id: logs[line].id });
    }
    return data;
  };

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  render() {
    const { classes } = this.props;
    const { logs, logsNames, fetchLogs, accordionToggle, highlightedId } =
      this.state;

    return (
      <div className={classes.mainBox}>
        <Accordion
          expanded={accordionToggle}
          className={classes.accordion}
          classes={{
            expanded: classes.accordionExpand,
          }}
          TransitionProps={{
            classes: {
              wrapper: classes.wrapper,
            },
          }}
        >
          <AccordionSummary
            id="panel"
            className={classes.accordionSumary}
            classes={{
              expanded: classes.content,
              content: classes.content,
            }}
            display="flex"
          >
            <Box
              className={classes.accordionToggle}
              display="flex"
              alignItems="center"
              flexGrow={1}
            >
              <Typography className={classes.heading}>
                {i18n.t('settings_menu_app_specific_logs_download')}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                title={
                  fetchLogs
                    ? i18n.t('settings_menu_app_specific_logs_fetch_logs_on')
                    : i18n.t('settings_menu_app_specific_logs_fetch_logs_off')
                }
              >
                <IconButton color="primary" onClick={this.fetchLogsToogle}>
                  {fetchLogs ? (
                    <RestorePageIcon />
                  ) : (
                    <RestorePageOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <IconButton onClick={this.handleAccordionOnClick}>
                {accordionToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </AccordionSummary>
          <div>
            <Divider />
            {logsNames.map((fileName, idx) => {
              const dateMoment = moment(fileName.date);
              return (
                <AccordionDetails
                  className={classes.accordionDetails}
                  key={idx}
                  display="flex"
                  flex={1}
                >
                  <DescriptionIcon className={classes.icons} />
                  <Grid alignItems="center" container>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <Typography className={classes.typography}>
                        {fileName.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={4} md={4} lg={3}>
                      <Typography direction="rtl">
                        {dateMoment.format('LL HH:mm')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <a
                        className={classes.link}
                        href={LogsService.api + fileName.url}
                        download
                      >
                        <CloudDownloadIcon className={classes.icons} />
                      </a>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              );
            })}
          </div>
        </Accordion>
        <LogsTable logs={logs} highlightedId={highlightedId} />
      </div>
    );
  }
}

LogsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(LogsPage);
