import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Close, AssignmentInd, Person, Contacts } from '@material-ui/icons';
import { openSnackbar, VmsDialog } from 'components';
import CsvParse from '@vtex/react-csv-parse';
import { Fragment, useRef } from 'react';
import { ExportService } from 'services';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));

const importTyes = [
  {
    role: 'employee',
    keys: ExportService.employeeKeys(),
    Icon: AssignmentInd,
  },
  {
    role: 'visitor',
    keys: ExportService.visitorKeys(),
    Icon: Person,
  },
];

const ImportUsersDialog = ({ open, onClose, loading, uploadUsers }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const inputRefs = useRef({});
  const setInputRef = (role, element) => {
    inputRefs.current[role] = element;
  };

  const openFileBrowser = (role) => {
    const input = inputRefs.current[role];
    if (input) {
      input.click();
    }
  };

  const handleParseError = ({ reason }) => {
    openSnackbar(reason);
  };

  return (
    <VmsDialog
      open={open}
      loading={loading}
      dialogHeaderTitle={t('import_users_dialog_title')}
      onClose={onClose}
      dialogHeaderIcon={<Contacts />}
      buttonIcon={<Close />}
      closeButtonDataCy="users-import-button-close"
    >
      <List>
        {importTyes.map((type) => {
          const { role, keys, Icon } = type;

          return (
            <Fragment key={role}>
              <ListItem button onClick={() => openFileBrowser(role)}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={t(`import_users_dialog_${role}`)}
                  secondary={t(`import_users_dialog_${role}_description`)}
                />
              </ListItem>
              <CsvParse
                keys={keys}
                onDataUploaded={(data) => uploadUsers(role, data)}
                onError={handleParseError}
                render={(onChange) => (
                  <input
                    ref={(element) => setInputRef(role, element)}
                    className={classes.input}
                    type="file"
                    onChange={onChange}
                    accept="text/csv"
                  />
                )}
              />
            </Fragment>
          );
        })}
      </List>
    </VmsDialog>
  );
};

export default ImportUsersDialog;
