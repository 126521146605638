import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  /*registerServiceWorker, */ unregister,
} from './registerServiceWorker';
/*
This code reenables serviceWorker, commented out for the ability to quickly reenable it if needed
import { openSnackbar } from "components/common/bars/SnackBar";
import i18n from "assets/i18n";
import { isMobileApp } from "AppSettings";

const onUpdate = () => {
  const message = isMobileApp() ? "app_new_release_mobile" : "app_new_release";
  openSnackbar(i18n.t(message), null, null, null, true);
}*/

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker({ onUpdate: onUpdate });
unregister();
