import { debounce } from '@material-ui/core';
import { openSnackbar } from 'components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UserService } from 'services';
import * as EmailValidator from 'email-validator';

const useSharedEmail = (
  email,
  userId,
  updateEmailSharedInfo,
  { wait = 500, disabled = false } = {}
) => {
  const [loading, setLoading] = useState(false);
  const lastEmailCheck = useRef();
  const lastUserId = useRef();

  const getEmailSharedInfo = useCallback((emailShared, emailSharedEnabled) => {
    return {
      emailShared,
      emailSharedEnabled,
    };
  }, []);

  const fetchEmailInfo = useCallback(
    async (email, userId) => {
      if (!email || disabled || !EmailValidator.validate(email)) {
        return;
      }

      setLoading(true);

      const now = new Date();
      lastEmailCheck.current = now;

      try {
        const response = await UserService.checkEmailUsed(email, userId);

        if (now === lastEmailCheck.current) {
          if (response.exists) {
            updateEmailSharedInfo(
              getEmailSharedInfo(response.emailShared, false)
            );
          }
        }
      } catch (err) {
        if (now === lastEmailCheck.current) {
          console.log(err);
          openSnackbar(err.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [updateEmailSharedInfo, getEmailSharedInfo, disabled]
  );

  const debounceFetchEmailInfo = useMemo(() => {
    return debounce(async (email, userId) => {
      fetchEmailInfo(email, userId);
    }, wait);
  }, [fetchEmailInfo, wait]);

  useEffect(() => {
    if (userId !== lastUserId.current) {
      fetchEmailInfo(email, userId);
    } else {
      //Until we get results we can assume the email has not yet been used
      updateEmailSharedInfo(getEmailSharedInfo(false, true));

      debounceFetchEmailInfo(email, userId);
    }
    lastUserId.current = userId;
  }, [
    fetchEmailInfo,
    debounceFetchEmailInfo,
    getEmailSharedInfo,
    updateEmailSharedInfo,
    email,
    userId,
  ]);

  return loading;
};

export default useSharedEmail;
