import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useLogout = () => {
  const history = useHistory();
  const [times, setTimes] = useState(0);

  const increment = () => {
    const total = times + 1;
    if (total === 4) {
      history.push('/logout');
      setTimes(0);
    } else {
      setTimes(total);
    }
  };

  return increment;
};

export default useLogout;
