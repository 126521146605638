import AbstractService from './AbstractService';
import Auth from 'modules/Auth';

class OtherServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'others/';
  }
  /**
   * Create an Other item.
   */
  create(name, category, type, data, priority) {
    let other = {
      name: name,
      category: category,
      type: type,
      data: data,
      priority: priority,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, other, config);
  }

  /**
   * Update an Other item.
   */
  update(id, name, category, type, data, priority) {
    let other = {
      name: name,
      category: category,
      type: type,
      data: data,
      priority: priority,
    };

    var endpoint = this.endpoint + id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, other, config);
  }

  /**
   * Get all existing Other items
   */
  getOthers() {
    var endpoint = this.endpoint;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getOthersAsVisitor(visitId) {
    var endpoint = this.endpoint + 'visitor/' + visitId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Removes an Other item
   * @param {string} id
   */
  deleteOther(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(this.endpoint + id, config);
  }

  suggestOthers = (searchValue) => {
    var endpoint = this.endpoint + 'suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };
}

const OtherService = new OtherServiceImpl();

export default OtherService;
