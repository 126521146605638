import UserModel from './UserModel';
import moment from 'moment';

class MeetingModel {
  /**
   * Meeting constructor.
   *
   * @param {string} [id=null] Meeting ID
   * @param {string} name Meeting title
   * @param {Date} startDate Start day of meeting (same as start time for now)
   * @param {Date} [endDate=null] End day of meeting reccurence (default null)
   * @param {Date} startTime Start time of meeting (same as start date for now)
   * @param {number} duration Duration of meeting in minutes
   * @param {Object} room ID of meeting room that will be used for this meeting
   * @param {string} notes Meeting notes
   * @param {Object[]} participants Array of participants
   */
  constructor(
    id,
    name,
    startDate,
    endDate = null,
    startTime,
    duration,
    room,
    notes,
    participants,
    organiser
  ) {
    this.id = id;
    this.name = name;
    this.startDate = startDate;
    // end date is there for future usage in case of implementing recurrent meetings
    this.endDate = endDate || startDate;
    this.startTime = startTime;
    this.duration = duration;
    this.room = room;
    this.notes = notes;
    this.participants = participants;
    this.organiser = organiser;
  }

  /**
   * Creates JSON representation of MeetingModel object.
   *
   * @returns {string} JSON representation of MeetingModel object
   */
  toJSON() {
    const data = {
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      startTime: this.startTime,
      duration: this.duration,
      room: this.room,
      notes: this.notes,
      participants: this.participants,
      organiser: this.organiser,
    };
    return JSON.stringify(data);
  }

  /**
   * Creates list with participants names.
   *
   * @returns {string[]} Array containing participants names
   */
  getParticipantNames() {
    var names = [];
    this.participants.forEach((item) => {
      if (item.user == null) {
        // if 'user' attribute is null, use 'name' attribute
        names.push(item.name);
      } else {
        // else get 'UserModel' object and use its 'name' property
        var user = UserModel.fromJson(item.user);
        names.push(user.name);
      }
    });
    return names;
  }

  /**
   * Creates JSON object representation of meeting usable in calendar.
   *
   * @returns {object} JSON object that can be used as event in calendar
   */
  getCalendarRepresentation() {
    // format start date using moment so calendar can handle it
    var start = moment(this.startDate).toDate();
    // calculate end time by adding duration to start date
    var end = this.getMeetingEndTime();
    return {
      // title, start, end are needed for displaying event in calendar
      title: this.name,
      start: start,
      end: end,
      // meeting object can be used to get detailed data about meeting for details window
      meetingObject: this,
    };
  }

  /**
   * Create JSON object containing all data needed for meeting detail form.
   *
   * @returns {object} JSON object that contains all data needed for meeting detail form
   */
  getModalFormData() {
    var start = moment(this.startDate).toDate();
    return {
      name: this.name,
      startDate: start,
      endDate: this.getMeetingEndTime(),
      place: this.room,
      participants: this.participants,
      notes: this.notes || '',
      newParticipant: '',
      organiser: this.organiser,
    };
  }

  /**
   * Calculates end time of meeting based on start time and duration of meeting.
   *
   * @returns {Date} End time of meeting
   */
  getMeetingEndTime() {
    return moment(this.startDate).add(this.duration, 'm').toDate();
  }
}

MeetingModel.fromJson = function (json) {
  return new MeetingModel(
    json._id,
    json.name,
    json.startDate,
    json.endDate,
    json.startTime,
    json.duration,
    json.room,
    json.notes,
    json.participants,
    json.organiser
  );
};

MeetingModel.getEmptyModalFormData = function () {
  return {
    name: '',
    startDate: null,
    endDate: null,
    place: '',
    participants: [],
    notes: '',
    newParticipant: '',
    organiser: {},
  };
};

MeetingModel.getEmptyModalFormData = function (place, organiser) {
  return {
    name: '',
    startDate: null,
    endDate: null,
    place: place,
    participants: [],
    notes: '',
    newParticipant: '',
    organiser: organiser,
  };
};

MeetingModel.fromModalFormData = function (formData) {
  // if start date is null, make sure that end date is null as well
  var endDate = formData.startDate ? formData.endDate : null;
  // if endDate is set, calculate duration.. else set default 60 minutes duration
  var duration = endDate
    ? moment
        .duration(moment(endDate).diff(moment(formData.startDate)))
        .asMinutes()
    : 60;
  // start date and start time are currently same date object
  return new MeetingModel(
    null,
    formData.name,
    formData.startDate,
    null,
    formData.startDate,
    duration,
    formData.place,
    formData.notes,
    formData.participants,
    formData.organiser
  );
};

export default MeetingModel;
