import { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'react-google-map';
import GoogleMapLoader from 'react-google-maps-loader';
import { ConfigurationService } from 'services';
import { configuration_group, configuration_settings_type } from 'AppSettings';

class VmsGoogleMap extends Component {
  state = {
    googleApiKey: null,
  };

  async componentDidMount() {
    const settings = await ConfigurationService.getConfigurationsForGroup(
      configuration_group.SETTINGS
    );
    const googleApiKey = (
      settings.find((c) => {
        return c.key === configuration_settings_type.GOOGLE_API_KEY;
      }) || {}
    ).value;
    this.setState({
      googleApiKey: googleApiKey,
    });
  }

  render() {
    if (!this.state.googleApiKey) {
      return null;
    }
    const { coordinates, center, zoom, height } = this.props;
    return (
      <GoogleMapLoader
        params={{
          key: this.state.googleApiKey,
          libraries: 'places,geometry',
        }}
        render={(googleMaps) => {
          return (
            googleMaps && (
              <div style={{ height: height ? height : '100%' }}>
                <GoogleMap
                  googleMaps={googleMaps}
                  center={center}
                  zoom={zoom ? zoom : 11}
                  coordinates={coordinates}
                />
              </div>
            )
          );
        }}
      />
    );
  }
}

VmsGoogleMap.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.object).isRequired,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number,
  height: PropTypes.string,
};

export default VmsGoogleMap;
