import { memo, Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  MenuItem,
  Menu,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  Divider,
  Hidden,
} from '@material-ui/core';
import Language from '@material-ui/icons/Language';
import PersonOutline from '@material-ui/icons/PersonOutline';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Help from '@material-ui/icons/Help';
import Reply from '@material-ui/icons/Reply';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import BusinessIcon from '@material-ui/icons/Business';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import Aux from 'hoc/Auxiliary';
import Auth from 'modules/Auth';
import TenantService from 'services/TenantService';
import { language_mapping } from 'AppSettings';

const styles = (theme) => ({
  menuColor: {
    fontSize: 14,
    color: theme.palette.primary6,
  },
  icon: {
    color: theme.palette.primary6,
  },
  nestedMenuItem: {
    paddingLeft: theme.spacing(9),
  },
  nestedText: {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  flag: {
    width: 36,
    height: 24,
  },
});

const menuStyle = {
  width: 222,
};

//Hidden with MenuItem has to be wrapped inside a custom component because Hidden cant have tabIndex and onFocus injected
const HiddenAssistantButton = memo(
  ({ classes, handleAssistantClick, tabIndex, onFocus }) => {
    return (
      <Hidden smUp>
        <MenuItem
          data-cy="profile-menu-list-item-assistant"
          onClick={handleAssistantClick}
          tabIndex={tabIndex}
          onFocus={onFocus}
        >
          <ListItemIcon className={classes.icon}>
            <Help />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuColor }}
            primary={i18n.t('menu_assistant')}
          />
        </MenuItem>
      </Hidden>
    );
  }
);

class ProfileMenu extends Component {
  constructor() {
    super();
    this.state = {
      tenants: [],
      selectedTenant: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { tenants } = this.state;
    if (
      (!tenants.length || prevProps.user !== this.props.user) &&
      Auth.isUserGlobalAdmin()
    ) {
      TenantService.getTenants()
        .then((response) => {
          this.setState({
            tenants: response,
          });
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          openSnackbar(err.message);
        });
    }
  }

  closeMenuBySelectingTenant = (tenantId) => (event) => {
    const { handleClose } = this.props;

    this.setState({ selectedTenant: tenantId });
    handleClose(event);
  };

  tenantTransition = () => {
    const { changeTenant } = this.props;
    const { selectedTenant } = this.state;
    if (selectedTenant) {
      this.setState({ selectedTenant: null });
      changeTenant(selectedTenant);
    }
  };

  render() {
    const { tenants } = this.state;
    const {
      classes,
      openLanguageMenu,
      help,
      visitorView,
      handleVisitorView,
      openTenantMenu,
      tenantMenuClick,
      handleAssistantClick,
      handleClose,
    } = this.props;
    return (
      <Menu
        id="menu-appbar"
        PaperProps={{
          style: menuStyle,
        }}
        getContentAnchorEl={null}
        anchorEl={this.props.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
        open={this.props.open}
        onClose={handleClose}
        TransitionProps={{
          onExited: this.tenantTransition,
        }}
      >
        <MenuItem
          data-cy="profile-menu-list-item-profile"
          onClick={this.props.profile}
        >
          <ListItemIcon className={classes.icon}>
            <PersonOutline />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuColor }}
            primary={i18n.t('menu_profile')}
          />
        </MenuItem>
        <MenuItem
          data-cy="profile-menu-list-item-languages"
          onClick={this.props.languageMenuClick}
        >
          <ListItemIcon className={classes.icon}>
            <Language />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuColor }}
            primary={i18n.t('menu_language')}
          />
          {openLanguageMenu ? (
            <ArrowDropUp className={classes.icon} />
          ) : (
            <ArrowDropDown className={classes.icon} />
          )}
        </MenuItem>
        <Collapse in={openLanguageMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(language_mapping).map((lang) => (
              <ListItem
                key={lang}
                button
                className={classes.nestedMenuItem}
                onClick={this.props.changeLanguage(lang)}
              >
                <ListItemIcon>
                  <img
                    className={classes.flag}
                    src={language_mapping[lang]}
                    alt={i18n.t(`menu_language_${lang}`)}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.nestedText}
                  classes={{ primary: classes.menuColor }}
                  primary={i18n.t(`menu_language_${lang}`)}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
        {Auth.isUserGlobalAdmin() && (
          <Aux>
            <MenuItem
              data-cy="profile-menu-list-item-tenants"
              onClick={tenantMenuClick}
            >
              <ListItemIcon className={classes.icon}>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.menuColor }}
                primary={i18n.t('menu_tenant')}
              />
              {openTenantMenu ? (
                <ArrowDropUp className={classes.icon} />
              ) : (
                <ArrowDropDown className={classes.icon} />
              )}
            </MenuItem>
            <Collapse in={openTenantMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {tenants.map((tenant) => (
                  <ListItem
                    key={tenant._id}
                    button
                    onClick={this.closeMenuBySelectingTenant(tenant._id)}
                    className={classes.nestedMenuItem}
                  >
                    <ListItemText
                      classes={{ primary: classes.menuColor }}
                      primary={tenant.name}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Aux>
        )}
        <MenuItem data-cy="profile-menu-list-item-help" onClick={help}>
          <ListItemIcon className={classes.icon}>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuColor }}
            primary={i18n.t('menu_help')}
          />
        </MenuItem>
        <HiddenAssistantButton
          classes={classes}
          handleAssistantClick={handleAssistantClick}
        />
        {(Auth.isUserAdmin() ||
          Auth.isUserEmployee() ||
          Auth.isUserAssistant()) &&
          !Auth.isUserGlobalAdmin() && (
            <MenuItem
              data-cy="profile-menu-list-item-visitor-view"
              onClick={handleVisitorView}
            >
              <ListItemIcon className={classes.icon}>
                <ViewCarousel />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.menuColor }}
                primary={
                  visitorView
                    ? i18n.t('menu_host_view')
                    : i18n.t('menu_visitor_view')
                }
              />
            </MenuItem>
          )}
        <Divider className={classes.divider} />
        <MenuItem
          data-cy="profile-menu-list-item-logout"
          onClick={this.props.logout}
        >
          <ListItemIcon className={classes.icon}>
            <Reply />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuColor }}
            primary={i18n.t('menu_logout')}
          />
        </MenuItem>
      </Menu>
    );
  }
}

ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  openLanguageMenu: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  languageMenuClick: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  profile: PropTypes.func.isRequired,
  help: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  visitorView: PropTypes.bool.isRequired,
  handleVisitorView: PropTypes.func.isRequired,
  openTenantMenu: PropTypes.bool.isRequired,
  tenantMenuClick: PropTypes.func.isRequired,
  changeTenant: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleAssistantClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfileMenu);
