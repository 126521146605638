import { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import ExploreButton from './ExploreButton';

const styles = (theme) => ({
  root: {
    overflowX: 'hidden', //Removes scrollbar which appears while using negative margin
  },
  scrollableContent: {
    margin: 0,
    padding: '0 ' + 2 * theme.spacing() + 'px',
    display: 'flex',
    overflowX: 'scroll',
    flexWrap: 'noWrap',
    paddingBottom: '1px',

    //TODO remove with Material UI 4
    MsOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& > li:first-child': {
      paddingLeft: 0,
    },
    '& > li:last-child': {
      paddingRight: 2 * theme.spacing(), //Setting this to 0 would stop the scrolling effect on the last item
    },
  },
  scrollableContentDesktop: {
    display: 'block',
    margin: '0 -' + theme.spacing() + 'px',
    //These two rules resets padding set in the previous rule
    '& > li:first-child': {
      paddingLeft: theme.spacing(),
    },
    '& > li:last-child': {
      paddingRight: theme.spacing(),
    },
  },
  childContainer: {
    display: 'block',
    flex: '0 0 60%',
    padding: '0 ' + theme.spacing() + 'px',
    maxWidth: '60%', //Without this, the first item in flex is bigger, I dont know how to properly fix this if it can be
  },
  childContainerDesktop: {
    display: 'inline-block',
    boxSizing: 'border-box',
  },
  moreButton: {
    float: 'right',
    marginRight: 2 * theme.spacing(),
    marginTop: theme.spacing(),
  },
});

const ExploreContainer = ({
  classes,
  children,
  slideable,
  desktopCount,
  moreText,
  onMore,
  itemDataCy,
}) => {
  const childrenToRender = [];

  for (let i = 0; i < children.length; i++) {
    if (!slideable && i >= desktopCount) {
      break;
    }

    childrenToRender.push(
      <li
        data-cy={itemDataCy}
        key={i}
        className={classNames([
          classes.childContainer,
          !slideable ? classes.childContainerDesktop : null,
        ])}
        style={
          !slideable ? { width: 'calc(100% / ' + desktopCount + ')' } : null
        }
      >
        {children[i]}
      </li>
    );
  }

  return (
    <div className={!slideable ? classes.root : null}>
      <ul
        className={classNames([
          classes.scrollableContent,
          !slideable ? classes.scrollableContentDesktop : null,
        ])}
      >
        {childrenToRender}
      </ul>
      {!slideable && children.length > desktopCount && (
        <div className={classes.moreButton}>
          <ExploreButton onClick={onMore}>{moreText}</ExploreButton>
        </div>
      )}
    </div>
  );
};

ExploreContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  slideable: PropTypes.bool.isRequired,
  desktopCount: PropTypes.number.isRequired,
  moreText: PropTypes.string.isRequired,
  onMore: PropTypes.func.isRequired,
  itemDataCy: PropTypes.string,
};

export default memo(withStyles(styles)(ExploreContainer));
