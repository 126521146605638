import {
  withStyles,
  Grid,
  FormControl,
  Input,
  InputAdornment,
  MenuItem,
  Menu,
  InputLabel,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RequiredIcon } from 'components';
import commonStyles from 'assets/jss/commonStyles';
import { useState } from 'react';

const styles = (theme) => ({
  ...commonStyles(theme),
  textField: {
    marginTop: '0 !important',
    caretColor: 'transparent',
    '& input:not([disabled])': {
      cursor: 'pointer',
    },
  },
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  iconRight: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
  },
});

const VmsDialogRowSelectCollection = (props) => {
  const {
    placeholder,
    iconLeft,
    onChange,
    classes,
    rowClass,
    selectTypesA,
    selectedTypeA,
    selectTypesB,
    selectedTypeB,
    selectTypesC,
    selectedTypeC,
    selectTypesD,
    selectedTypeD,
    headerTypeA,
    headerTypeB,
    headerTypeC,
    headerTypeD,
    disabled,
    required,
    inputDataCy,
    itemTypeADataCy,
    itemTypeBDataCy,
    itemTypeCDataCy,
    itemTypeDDataCy,
  } = props;
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onSelect = (name, value, option) => (event) => {
    setAnchorEl(null);
    onChange(name, value, option)(event);
  };

  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.iconLeft}>
          <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
        </div>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            htmlFor="documents"
            shrink={false}
            style={{
              transform: 'translate(0, 8px) scale(1)',
            }}
            disabled={disabled}
          >
            {required ? `${placeholder} (${t('required')})` : placeholder}
          </InputLabel>
          <Input
            data-cy={inputDataCy}
            className={classes.textField}
            fullWidth
            name="documents"
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                <ArrowDropDown className={classes.iconSelect} />
              </InputAdornment>
            }
            onClick={
              disabled ? undefined : (event) => setAnchorEl(event.target)
            }
          />
          <Menu
            id="documents-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                maxHeight: 48 * 6.5,
                overflowY: true,
              },
            }}
          >
            {selectTypesA && selectTypesA.length > 0 && (
              <MenuItem key={headerTypeA} disabled={true}>
                {headerTypeA}
              </MenuItem>
            )}
            {selectTypesA &&
              selectTypesA.map((option, index) => {
                const name =
                  selectTypesA.value && selectTypesA.value[index]
                    ? selectTypesA.value[index]
                    : option.data.name;
                const required_for_entrance = option.data.required_for_entrance
                  ? ` (${t('required_for_entrance')})`
                  : '';
                return (
                  <MenuItem
                    data-cy={itemTypeADataCy}
                    key={index + '_' + option.data.name}
                    selected={option.data.name === selectedTypeA}
                    value={option.data.name}
                    onClick={onSelect('documents', option, index)}
                  >
                    {`${name}${required_for_entrance}`}
                  </MenuItem>
                );
              })}
            {selectTypesB && selectTypesB.length > 0 && (
              <MenuItem key={headerTypeB} disabled={true}>
                {headerTypeB}
              </MenuItem>
            )}
            {selectTypesB &&
              selectTypesB.map((option, index) => {
                const name =
                  selectTypesB.value && selectTypesB.value[index]
                    ? selectTypesB.value[index]
                    : option.data.name;
                const required_for_entrance = option.data.required_for_entrance
                  ? ` (${t('required_for_entrance')})`
                  : '';
                return (
                  <MenuItem
                    data-cy={itemTypeBDataCy}
                    key={index + '__' + option.data.name}
                    selected={option.data.name === selectedTypeB}
                    value={option.data.name}
                    onClick={onSelect('documents', option, index)}
                  >
                    {`${name}${required_for_entrance}`}
                  </MenuItem>
                );
              })}
            {selectTypesC && selectTypesC.length > 0 && (
              <MenuItem key={headerTypeC} disabled={true}>
                {headerTypeC}
              </MenuItem>
            )}
            {selectTypesC &&
              selectTypesC.map((option, index) => {
                const name =
                  selectTypesC.value && selectTypesC.value[index]
                    ? selectTypesC.value[index]
                    : option.data.name;
                const required_for_entrance = option.data.required_for_entrance
                  ? ` (${t('required_for_entrance')})`
                  : '';
                return (
                  <MenuItem
                    data-cy={itemTypeCDataCy}
                    key={index + '__' + option.data.name}
                    selected={option.data.name === selectedTypeC}
                    value={option.data.name}
                    onClick={onSelect('documents', option, index)}
                  >
                    {`${name}${required_for_entrance}`}
                  </MenuItem>
                );
              })}
            {selectTypesD && selectTypesD.length > 0 && (
              <MenuItem key={headerTypeD} disabled={true}>
                {headerTypeD}
              </MenuItem>
            )}
            {selectTypesD &&
              selectTypesD.map((option, index) => {
                const name =
                  selectTypesD.value && selectTypesD.value[index]
                    ? selectTypesD.value[index]
                    : option.data.name;
                return (
                  <MenuItem
                    data-cy={itemTypeDDataCy}
                    key={index + '__' + option.data.name}
                    selected={option.data.name === selectedTypeD}
                    value={option.data.name}
                    onClick={onSelect('documents', option, index)}
                  >
                    {`${name}`}
                  </MenuItem>
                );
              })}
          </Menu>
        </FormControl>
      </Grid>
    </Grid>
  );
};

VmsDialogRowSelectCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.string,
  rowClass: PropTypes.string.isRequired,
  selectTypesA: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
      status: PropTypes.string,
    })
  ),
  selectedTypeA: PropTypes.string.isRequired,
  selectTypesB: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
      status: PropTypes.string,
    })
  ),
  selectedTypeB: PropTypes.string.isRequired,
  selectTypesC: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
      status: PropTypes.string,
    })
  ),
  selectedTypeC: PropTypes.string.isRequired,
  selectTypesD: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
      status: PropTypes.string,
    })
  ),
  selectedTypeD: PropTypes.string.isRequired,
  headerTypeA: PropTypes.string.isRequired,
  headerTypeB: PropTypes.string.isRequired,
  headerTypeC: PropTypes.string.isRequired,
  headerTypeD: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputDataCy: PropTypes.string,
  itemTypeADataCy: PropTypes.string,
  itemTypeBDataCy: PropTypes.string,
  itemTypeCDataCy: PropTypes.string,
  itemTypeDDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsDialogRowSelectCollection);
