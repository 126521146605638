import PropTypes from 'prop-types';
import classNames from 'classnames';
import { elevation } from '../../../assets/UISettings';
import { withStyles, Paper, Typography, Grid } from '@material-ui/core';
import reportsStyle from 'assets/jss/reportsStyle';
import { CardHeader, CardBody } from 'components/';
import i18n from '../../../assets/i18n';
import DownloadButton from './DownloadButton';

const numberCard = (props) => {
  const { classes, number, text, onDownload, buttonDataCy } = props;
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item>
              <Typography className={classes.numberFont}>{number}</Typography>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody plain className={classes.cardFooter}>
          <Typography
            className={classNames(
              classes.heading,
              classes.grid,
              classes.clampNumber
            )}
          >
            {text}
          </Typography>
          {onDownload && (
            <DownloadButton
              title={i18n.t('export')}
              onDownload={onDownload}
              buttonDataCy={buttonDataCy}
            />
          )}
        </CardBody>
      </Paper>
    </Grid>
  );
};

numberCard.propTypes = {
  classes: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  buttonDataCy: PropTypes.string,
};

export default withStyles(reportsStyle)(numberCard);
