import { Component } from 'react';
import { withStyles, Button, Grid } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import i18n from '../../../../assets/i18n';
import Color from 'color';
import { isMobileApp, isiOSMobileApp } from 'AppSettings';

const styles = (theme) => ({
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
  },
  dropzone: {
    width: '100%',
    height: 150,
    backgroundColor: theme.palette.primary3,
    borderBottom: `1px solid ${theme.palette.primary7}`,
  },
  dropzoneAccept: {
    backgroundColor: Color(theme.palette.primary.main).alpha(0.1).string(),
  },
  dropzoneReject: {
    backgroundColor: Color(theme.palette.error.main).alpha(0.1).string(),
  },
  dropZoneInfoIcon: {
    position: 'absolute',
    right: 13,
    bottom: 9,
  },
  dropZoneRejectedInfo: {
    position: 'absolute',
    left: 8,
    bottom: -13,
    fontSize: 8,
    color: theme.palette.error.main,
  },
  dropZoneAccepted: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  dropZoneRejected: {
    borderBottom: `2px solid ${theme.palette.error.main}`,
  },
  dndArea: {
    height: '100%',
  },
  buttonBrowse: {
    color: theme.palette.common.white,
  },
  dndInfo: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
  },
});

class VmsDialogRowDragDrop extends Component {
  constructor(props) {
    super(props);
    this.dropzoneRef = null;
  }

  setRef = (dropzone) => {
    this.dropzoneRef = dropzone;
  };

  openFileDialog = (event) => {
    this.dropzoneRef.open();
  };

  render() {
    const {
      classes,
      rowClass,
      fileStatus,
      document_accepted_mime_types,
      uploading,
      files,
      onDropAccepted,
      onDropRejected,
      onDrop,
      photoMode,
      fileMode,
      isFileMode,
      disabled,
      onBrowseCancel,
      buttonDataCy,
    } = this.props;

    const backgroundText =
      isMobileApp() || isiOSMobileApp()
        ? i18n.t('document_upload_select')
        : i18n.t('document_upload_dnd_here');

    if (this.props.openPhotoFileDialog) {
      setTimeout(this.openFileDialog, 100);
    }

    return (
      <Grid
        container
        className={rowClass}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <div className={classes.iconLeft}>
            {this.props.icon ? this.props.icon : <AttachFile />}
          </div>
        </Grid>
        <Grid item className={classes.rowContent}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.dropzone}
          >
            <Dropzone
              ref={this.setRef}
              name="document"
              className={classNames(
                classes.dropzone,
                fileStatus === 'accepted' ? classes.dropZoneAccepted : null,
                fileStatus === 'rejected' ? classes.dropZoneRejected : null
              )}
              disablePreview
              acceptClassName={classes.dropzoneAccept}
              rejectClassName={classes.dropzoneReject}
              accept={document_accepted_mime_types}
              multiple={fileMode === true}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              onDrop={onDrop}
              onFileDialogCancel={onBrowseCancel}
              disableClick={
                !isFileMode ? photoMode === true : fileMode === true
              }
              disabled={disabled}
            >
              <Grid
                container
                className={classes.dndArea}
                direction="column"
                alignItems="center"
                justifyContent="space-around"
              >
                <Grid item>
                  <div className={classes.dndInfo}>
                    {uploading
                      ? i18n.t('document_upload_uploading')
                      : null + files.length > 0 && !isFileMode
                      ? files[0].name
                      : backgroundText}
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    data-cy={buttonDataCy}
                    color="primary"
                    variant="contained"
                    className={classes.buttonBrowse}
                    onClick={
                      !isFileMode
                        ? photoMode
                          ? this.props.newPhoto
                          : null
                        : fileMode
                        ? this.props.newFile
                        : null
                    }
                    disabled={disabled}
                  >
                    {' '}
                    {/* We need null on click to prevent openDialog called 2x (Dropzone + Button)*/}
                    {photoMode
                      ? i18n.t('new_photo')
                      : isFileMode && fileMode
                      ? i18n.t('new_file')
                      : i18n.t('browse')}
                  </Button>
                </Grid>
              </Grid>
              {fileStatus === 'accepted' ? (
                <CheckCircle
                  color="primary"
                  className={classes.dropZoneInfoIcon}
                />
              ) : null}
              {fileStatus === 'rejected' ? (
                <div>
                  <Error color="error" className={classes.dropZoneInfoIcon} />
                  <div className={classes.dropZoneRejectedInfo}>
                    {i18n.t('document_upload_rejected_file_type')}
                  </div>
                </div>
              ) : null}
            </Dropzone>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

VmsDialogRowDragDrop.propTypes = {
  classes: PropTypes.object.isRequired,
  rowClass: PropTypes.string.isRequired,
  fileStatus: PropTypes.string.isRequired,
  document_accepted_mime_types: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  files: PropTypes.any.isRequired,
  onDropAccepted: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  onBrowseCancel: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  icon: PropTypes.element,
  photoMode: PropTypes.bool,
  fileMode: PropTypes.bool,
  isFileMode: PropTypes.bool,
  newPhoto: PropTypes.func,
  newFile: PropTypes.func,
  openPhotoFileDialog: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsDialogRowDragDrop);
