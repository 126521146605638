import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
  },
});

const TabContainer = ({ id, children, classes }) => {
  return (
    <div id={id} className={classes.container}>
      {children}
    </div>
  );
};

TabContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

export default withStyles(styles)(TabContainer);
