import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import i18n from 'assets/i18n';
import DocumentService from 'services/DocumentService';
import {
  type_constants,
  user_document_status_constants,
  visit_visitor_status_constants,
  user_roles,
  user_status,
  template_types,
  template_categories,
  removeByKey,
  validatePhoneNumber,
} from 'AppSettings';
import UserService from 'services/UserService';
import { openSnackbar } from 'components/common/bars/SnackBar';
import UserDocumentModel from 'services/models/UserDocumentModel';
import SecurityItemsService from 'services/SecurityItemsService';
import Aux from 'hoc/Auxiliary';
import { InviteDialog, TemplateEditor } from 'components';
import TemplateService from 'services/TemplateService';
import VisitService from 'services/VisitService';
import EmailService from 'services/EmailService';
import SecurityItemService from 'services/SecurityItemsService';
import {
  uploadPhotoBase64,
  uploadPhotoFile,
} from 'containers/VmsDialogDragDropPhoto';
import moment from 'moment';
import util from 'util';
import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import { SmsService } from 'services';
import useLoadData from './useLoadData';
import { withBus } from 'react-bus';
import * as EmailValidator from 'email-validator';
import Auth from 'modules/Auth';
import { CompanyDataContext } from 'components';
import { useTranslation } from 'react-i18next';
import locales from 'assets/momentlocales';

export const editInvitation = (visit) => {
  const user = visit.user || {};
  const host = visit.host || {};

  return {
    group: visit.group,
    visit_id: visit._id,
    user_id: visit.user._id,
    user_status: user.status,
    email: user.email,
    delegate: visit.delegate,
    phone: user.phone,
    language: user.language,
    name: user.name,
    arrival: new Date(visit.timeFrom),
    departure: new Date(visit.timeTo),
    company: user.company,
    host: host.name,
    host_id: host._id,
    purpose: visit.purpose,
    note: visit.note,
    wifi_username: visit.wifi_username,
    wifi_password: visit.wifi_password,
    visitorType: visit.visitorTypes.length
      ? visit.visitorTypes[visit.visitorTypes.length - 1]
      : '',
    visitorTypes: visit.visitorTypes,
    documents: [],
    documentsToRemove: [],
    personal_files: [],
    photo: visit.user.photo,
    photoUrl: null,
    activationUrl: ' ',
    activationUrlPlain: ' ',
    events: visit.events,
    wifi_enabled: visit.wifi_enabled,
    option1: visit.option1,
    option2: visit.option2,
    porterNote: visit.porterNote,
    status: visit.status,
    tenantId: user.tenantId,
    oldPhone: user.phone,
    dragDropState: null,
    dragDropFileState: null,
    securityItems: visit.securityItems,
    emailLogs: visit.emailLogs,
    inspectionActivities: visit.inspectionActivities,
    accompanimentRequired: visit.accompanimentRequired,
    confirmationRequired: visit.confirmationRequired,
    signInSelfRegistration: visit.signInSelfRegistration,
    accompaniments: visit.accompaniments,
    authenticationRequired: visit.authenticationRequired,
    emailShared: user.emailShared,
    emailSharedEnabled: true,
    login: user.login,
    selectDays: visit.selectDays,
    daysOff: visit.daysOff,
    datesOff: visit.datesOff,
  };
};

export const emptyInvitation = (language) => {
  const isAdmin = Auth.isUserAdmin();
  const user = Auth.getUser();

  return {
    group: null,
    visit_id: null, // In case we edit visit
    user_id: null, // In case we select user from suggestion
    user_status: user_status.PENDING,
    email: '',
    delegate: '',
    phone: '',
    language: language || '',
    name: '',
    arrival: null,
    departure: null,
    company: '',
    host: isAdmin ? '' : user.name,
    host_id: isAdmin ? '' : user._id, // In case we select user from suggestion
    purpose: '',
    note: '',
    wifi_username: '',
    wifi_password: '',
    visitorType: '',
    visitorTypes: [],
    documents: [],
    documentsToRemove: [],
    personal_files: [],
    status: visit_visitor_status_constants.INVITED,
    photo: null,
    photoUrl: null,
    activationUrl: ' ', // empty space for template data replacement to work
    activationUrlPlain: ' ',
    events: [],
    wifi_enabled: false,
    option1: false,
    option2: false,
    porterNote: '',
    tenantId: null,
    oldPhone: '',
    dragDropState: null,
    dragDropFileState: null,
    securityItems: [],
    emailLogs: [],
    inspectionActivities: [],
    accompanimentRequired: false,
    confirmationRequired: false,
    signInSelfRegistration: false,
    accompaniments: [],
    authenticationRequired: false,
    emailShared: false,
    emailSharedEnabled: true,
    login: '',
    selectDays: false,
    daysOff: [],
    datesOff: [],
  };
};

const createInvitationDetails = (invitation) => {
  return {
    delegate: invitation.delegate,
    arrival: invitation.arrival,
    departure: invitation.departure,
    host_id: invitation.host_id,
    host: invitation.host,
    purpose: invitation.purpose,
    note: invitation.note,
    status: invitation.status,
    wifi_username: invitation.wifi_username,
    wifi_password: invitation.wifi_password,
    wifi_enabled: invitation.wifi_enabled,
    option1: invitation.option1,
    option2: invitation.option2,
    porterNote: invitation.porterNote,
    accompanimentRequired: invitation.accompanimentRequired,
    confirmationRequired: invitation.confirmationRequired,
    signInSelfRegistration: invitation.signInSelfRegistration,
    accompaniments: invitation.accompaniments,
    visitorTypes: invitation.visitorTypes,
    authenticationRequired: invitation.authenticationRequired,
    emailShared: invitation.emailShared,
    emailSharedEnabled: true,
    login: invitation.login,
    selectDays: invitation.selectDays,
    daysOff: invitation.daysOff,
    datesOff: invitation.datesOff,
  };
};

const emptyEmail = () => {
  return {
    name: '',
    recipient: '',
    title: '',
    selectedValue: '',
    lang: '',
    editor: TemplateService.createEmpty(),
    invitation: null,
    disabledLang: true,
    visit_id: null,
    sendingEmail: false,
  };
};

const templateData = (invitation, lang = 'en', companyData) => {
  return {
    user: {
      name: invitation.name,
      email: invitation.email,
      status: invitation.status,
      registrationRequired: invitation.user_status === user_status.PENDING,
      company: invitation.company,
      activationUrl: invitation.activationUrl,
      activationUrlPlain: invitation.activationUrlPlain,
      login: invitation.login,
    },
    invite: {
      arrival: moment(invitation.arrival).locale(lang).format('LLLL'),
      host: {
        name: invitation.host,
      },
      user: {
        name: invitation.name,
      },
      purpose: invitation.purpose,
      note: invitation.note,
      wifi_username: invitation.wifi_username,
      wifi_password: invitation.wifi_password,
      wifi_enabled: invitation.wifi_enabled,
    },
    company: companyData,
  };
};

const createEmailState = () => {
  return {
    email: emptyEmail(),
    emailTemplates: [], //all emails
    inviteEmailTemplates: null, //used for select
    emailModalOpen: false,
    sendAllEnabled: false,
  };
};

const emailStateReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_TEMPLATES':
      return { ...state, ...action.payload };
    case 'EMAIL_CHANGE':
      return { ...state, email: { ...state.email, ...action.payload } };
    case 'OPEN_MODAL':
      return { ...state, emailModalOpen: true };
    case 'CLOSE_MODAL':
      return { ...state, emailModalOpen: false };
    case 'ENABLE_SEND_ALL':
      return { ...state, sendAllEnabled: true };
    case 'DISABLE_SEND_ALL':
      return { ...state, sendAllEnabled: false };
    default:
      throw new Error();
  }
};

//Used for copying the latest changes from the fields which every visitor in group invite shares
const createInvitationForGroupVisit = (groupInvitation, invitation) => {
  const {
    delegate,
    arrival,
    departure,
    purpose,
    option1,
    option2,
    host,
    host_id,
    wifi_enabled,
    wifi_password,
    wifi_username,
    accompanimentRequired,
    visitorType,
    visitorTypes,
    authenticationRequired,
  } = groupInvitation;
  return {
    ...invitation,
    delegate,
    arrival,
    departure,
    purpose,
    option1,
    option2,
    host,
    host_id,
    wifi_enabled,
    wifi_password,
    wifi_username,
    accompanimentRequired,
    visitorType,
    visitorTypes,
    authenticationRequired,
  };
};

//size can be smaller by 1 because it reflects the amount of visitors in the invite, it doesnt contain the empty invite
const createInvitationState = () => {
  return {
    invitations: [emptyInvitation()],
    group: '',
    isGroupInvitation: false,
    index: 0,
    size: 0,
  };
};

const assignVisitorTypeItemsToInvitation = (
  invitation,
  visitorType,
  documents
) => {
  invitation.visitorType = visitorType.key;
  //Docs
  visitorType.value.documents.forEach((doc) => {
    let type = undefined;
    switch (doc.type) {
      case 'document':
        type = 'documents';
        break;
      case 'signed':
        type = 'signed';
        break;
      case 'questionnaire':
        type = 'questionnaires';
        break;
      case 'feedback':
        type = 'feedback';
        break;
      default:
        break;
    }
    if (type) {
      const docData = documents[type].find(
        (document) => document.data.document_id === doc.document_id
      );
      if (docData) {
        const data = { ...docData.data }; // Create document data structure based on existing documents
        data.document_id = doc.document_id; // Add document id, is this needed?
        const newDoc = new UserDocumentModel(
          null,
          null,
          doc.type,
          docData.status,
          data
        );

        const userDocuments = [...invitation.documents];
        let found = false;
        if (docData.type === type_constants.FEEDBACK) {
          //Add feedback only if the invitationIds are not equal
          found = userDocuments.find(
            (document) =>
              document.data.document_id === newDoc.data.document_id &&
              (document.data.visit_id === newDoc.data.visit_id ||
                document.data.visit_id === invitation.visit_id)
          );
        } else {
          found = userDocuments.find(
            (document) =>
              document.data.name === newDoc.data.name &&
              document.data.type === newDoc.data.type
          );
        }

        if (!found) {
          userDocuments.push(newDoc);
          invitation.documents = userDocuments;
        }
      }
    }
  });
  //Inspection activities
  visitorType.value.inspectionActivities?.forEach((activity) => {
    const inspectionActivities = [...invitation.inspectionActivities];
    if (!inspectionActivities.find((act) => act.type === activity)) {
      inspectionActivities.push({ type: activity });
    }
    invitation.inspectionActivities = inspectionActivities;
  });

  //Accompaniment
  invitation.accompanimentRequired = visitorType.value.accompanimentRequired;

  //Authentication
  invitation.authenticationRequired = visitorType.value.authenticationRequired;

  //confirmation self registered user
  invitation.confirmationRequired = visitorType.value.confirmationRequired;

  invitation.signInSelfRegistration = visitorType.value.signInSelfRegistration;

  //Visitor types array
  const visitorTypes = [...invitation.visitorTypes];
  const existsIndex = visitorTypes.findIndex(
    (type) => type === visitorType.key
  );
  //If the type was already assigned, reassign it at the end of the array again
  if (existsIndex >= 0) {
    visitorTypes.splice(existsIndex, 1);
  }
  visitorTypes.push(visitorType.key);

  invitation.visitorTypes = visitorTypes;
};

const invitationStateReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_EMPTY': {
      return { ...createInvitationState(), invitations: [action.payload] };
    }
    case 'INIT_EXISTING': {
      const { invitations, index, group, editAsGroupInvite } = action.payload;
      const size = invitations.length;
      return {
        invitations,
        size,
        index,
        group,
        isGroupInvitation: editAsGroupInvite,
      };
    }
    case 'CHANGE_GROUP_NAME': {
      const group = action.payload;
      return { ...state, group };
    }
    case 'UPDATE_CURRENT_INVITATION': {
      const invitations = [...state.invitations];
      const invitation = { ...invitations[state.index], ...action.payload };
      invitations[state.index] = invitation;
      return { ...state, invitations };
    }
    case 'CHANGE_INVITATION_NAME': {
      const name = action.payload;
      const { index } = state;

      let invitation = state.invitations[index];

      if (invitation.name !== name) {
        invitation = {
          ...invitation,
          user_id: null,
          documents: [],
          documentsToRemove: [],
          personal_files: [],
          photo: null,
          photoUrl: null,
        };

        const invitations = [...state.invitations];
        invitations[index] = invitation;
        return { ...state, invitations };
      }

      return state;
    }
    case 'UPDATE_INVITATION': {
      const { index, data } = action.payload;
      const invitations = [...state.invitations];
      const invitation = { ...invitations[index], ...data };
      invitations[index] = invitation;
      return { ...state, invitations };
    }
    case 'ADD_VISITOR': {
      const { companyData, documents, visitorTypes } = action.payload;
      const invitations = [...state.invitations];
      let { index, size } = state;

      const currentInvite = invitations[index];
      const newCurrentInvite = createInvitationForGroupVisit(
        currentInvite,
        emptyInvitation((companyData.location || {}).language)
      );
      currentInvite.visitorTypes.forEach((typeName) => {
        const type = visitorTypes.find((type) => type.key === typeName);
        if (type) {
          assignVisitorTypeItemsToInvitation(newCurrentInvite, type, documents);
        }
      });

      if (index < size && size < invitations.length) {
        //We are looking at already created invitation and theres an empty invitation at the end of 'invitations' array
        invitations[size] = newCurrentInvite;
        index = size;
      } else {
        //Create empty invitation at the end of the array, increase size and point to it as the current invitation
        invitations.push(newCurrentInvite);
        if (index === size) {
          //We are at the end of valid invitations, create an empty invitation
          size++;
          index++;
        } else {
          index = size;
        }
      }

      return { ...state, invitations, index, size, isGroupInvitation: true };
    }
    case 'daysOff': {
      let invitations = state.invitations;
      let daysOff = [];
      if (invitations[state.index].daysOff)
        daysOff = invitations[state.index].daysOff;
      let index = daysOff?.lastIndexOf(action.payload);
      if (index === -1) {
        daysOff?.push(action.payload);
      } else {
        daysOff?.splice(index, 1);
      }
      invitations[state.index].daysOff = daysOff;
      return { ...state, invitations };
    }
    case 'addDatesOff': {
      let invitations = state.invitations;
      let datesOff = [];
      if (invitations[state.index].datesOff)
        datesOff = invitations[state.index].datesOff;
      let index = datesOff?.lastIndexOf(action.payload);
      if (index === -1) {
        datesOff?.push(action.payload);
        invitations[state.index].datesOff = datesOff;
        return { ...state, invitations };
      }
      return { ...state };
    }

    case 'removeDateOff': {
      let invitations = state.invitations;
      const index = action.payload;
      let datesOff = [...invitations[state.index].datesOff];
      datesOff?.splice(index, 1);
      invitations[state.index].datesOff = datesOff;
      return { ...state, invitations };
    }

    case 'REMOVE_INVITATION': {
      //Delete from the array only if we are editing a invitation not in the group list
      const { indexToRemove, companyData } = action.payload;

      const invitations = [...state.invitations];
      let { index, isGroupInvitation, size } = state;
      const currentInvite = invitations[index];

      invitations.splice(indexToRemove, 1);

      //If we are removing index which is lower than currently selected, lower the selected index since the array is shorter
      if (index > indexToRemove) {
        index -= 1;
      }

      //If indexToRemove is lower we are deleting a valid invitation which is not empty
      if (indexToRemove < size) {
        size--;
      }

      if (index === indexToRemove) {
        if (indexToRemove === 0 && !size) {
          isGroupInvitation = false;

          //If there is not any empty invite, create one
          if (!invitations.length) {
            const newCurrentInvite = createInvitationForGroupVisit(
              currentInvite,
              emptyInvitation((companyData.location || {}).language)
            );
            invitations.push(newCurrentInvite);
          }
        } else {
          index = size - 1;
        }
      }

      return { ...state, invitations, index, size, isGroupInvitation };
    }
    case 'CHANGE_CURRENT_INVITATION': {
      const index = action.payload;
      const invitations = [...state.invitations];
      const currentInvite = invitations[state.index];
      const newVisit = invitations[index];
      //Update info from the latest invitation
      const newCurrentInvite = createInvitationForGroupVisit(
        currentInvite,
        newVisit
      );
      invitations[index] = newCurrentInvite;
      return { ...state, invitations, index };
    }
    case 'UPDATE_DRAG_DROP_INVITATION': {
      const { index, data } = action.payload;
      const invitations = [...state.invitations];
      const invitation = { ...invitations[index], ...data };
      const dragDropState = { ...invitation.dragDropState, ...data };
      invitation.dragDropState = dragDropState;
      invitations[index] = invitation;
      return { ...state, invitations };
    }
    case 'ADD_DOCUMENT': {
      const { document, invitationIndex } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      //If invitationIndex is a number, it means it was called from DocsTab for the current and only invitation
      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }
      const invitation = { ...invitations[index] };

      let found = false;
      if (document.type === type_constants.FEEDBACK) {
        //Add feedback only if the visitIds are not equal
        found = invitation.documents.find(
          (doc) =>
            doc.data.document_id === document.data.document_id &&
            (doc.data.visit_id === document.data.visit_id ||
              doc.data.visit_id === invitation.visit_id)
        );
      } else {
        found = invitation.documents.find(
          (doc) =>
            doc.data.name === document.data.name &&
            doc.data.type === document.data.type
        );
      }

      if (!found) {
        const userDocuments = [...invitation.documents];
        userDocuments.push(document);
        invitation.documents = userDocuments;
        invitations[index] = invitation;

        return { ...state, invitations };
      } else {
        return state;
      }
    }
    case 'REMOVE_DOCUMENT': {
      const { indexToRemove, invitationIndex, document } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      //If invitationIndex is a number, it means it was called from DocsTab for the current and only invitation
      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }
      const invitation = { ...invitations[index] };

      const userDocuments = [...invitation.documents];
      const documentsToRemove = [...invitation.documentsToRemove];
      userDocuments.splice(indexToRemove, 1);
      if (document) {
        documentsToRemove.push(document);
      }

      invitation.documents = userDocuments;
      invitation.documentsToRemove = documentsToRemove;
      invitations[index] = invitation;

      return { ...state, invitations };
    }
    case 'ADD_GROUP_VISITOR_TYPE': {
      const { visitorType, documents } = action.payload;

      const invitations = [...state.invitations];
      invitations.forEach((vst, i) => {
        const invitation = { ...vst };
        assignVisitorTypeItemsToInvitation(invitation, visitorType, documents);
        invitations[i] = invitation;
      });
      return { ...state, invitations };
    }
    case 'ADD_VISITOR_TYPE': {
      const { visitorType, documents, index } = action.payload;

      const invitations = [...state.invitations];
      const invitation = invitations[index];
      console.log('documents to add', documents);
      assignVisitorTypeItemsToInvitation(invitation, visitorType, documents);
      invitations[index] = invitation;

      return { ...state, invitations };
    }
    case 'REMOVE_FILE': {
      const { indexToRemove, value, invitationIndex } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }

      const invitation = { ...invitations[index] };
      const personal_files = [...invitation.personal_files];
      const documentsToRemove = [...invitation.documentsToRemove];
      personal_files.splice(indexToRemove, 1);

      if (invitation.dragDropFileState) {
        const dragDropFileState = { ...invitation.dragDropFileState };
        const files = [...dragDropFileState.files];
        files.splice(0, 1);
        dragDropFileState.files = files;
        invitation.dragDropFileState = dragDropFileState;
      }

      if (value) {
        documentsToRemove.push(value);
      }

      invitation.personal_files = personal_files;
      invitation.documentsToRemove = documentsToRemove;
      invitations[index] = invitation;

      return { ...state, invitations };
    }
    case 'RENAME_FILE': {
      const { indexToRename, name, invitationIndex } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }

      const invitation = { ...invitations[index] };
      const personal_files = [...invitation.personal_files];
      const doc = { ...personal_files[indexToRename] };
      doc.data.customName = name;

      personal_files[indexToRename] = doc;
      invitation.personal_files = personal_files;
      invitations[index] = invitation;

      return { ...state, invitations };
    }
    case 'DRAG_DROP': {
      const { dragDropState, invitationIndex } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }

      const invitation = { ...invitations[index] };
      invitation.dragDropState = dragDropState;
      invitations[index] = invitation;

      return { ...state, invitations };
    }
    case 'DRAG_DROP_FILE': {
      const { dragDropFileState, invitationIndex } = action.payload;
      const invitations = [...state.invitations];
      let { index } = state;

      if (typeof invitationIndex === 'number') {
        index = invitationIndex;
      }

      const invitation = { ...invitations[index] };
      invitation.dragDropFileState = dragDropFileState;

      const personal_files = [...invitation.personal_files];
      if (
        dragDropFileState?.files != null &&
        dragDropFileState.files.length > 0
      ) {
        dragDropFileState.files.forEach((doc) => {
          const data = {
            name: doc.name,
            document_id: doc._id,
            data: doc,
            customName: '',
          };
          if (
            personal_files.filter(
              (e) => !e.data.id && e.data.name === data.name
            ).length < 1
          ) {
            personal_files.push(
              new UserDocumentModel(
                null,
                null,
                type_constants.PERSONAL_DOCUMENT,
                user_document_status_constants.READ,
                data
              )
            );
          }
        });
      }
      invitation.personal_files = personal_files;

      invitations[index] = invitation;

      return { ...state, invitations };
    }
    default:
      throw new Error();
  }
};

const emptyInvitationEmailData = () => {
  return {
    invitations: [],
    currentInvitationIndex: 0,
  };
};

const InviteVisitor = ({
  bus,
  page,
  open,
  selectedVisit,
  selectedDate,
  onClose,
  onSave,
  editAsGroupInvite,
  reset,
}) => {
  const [t] = useTranslation();
  const { companyData, loadingCompanyData } = useContext(CompanyDataContext);

  const [invitationState, dispatchInvitationState] = useReducer(
    invitationStateReducer,
    createInvitationState()
  );
  const currentInvitation = invitationState.invitations[invitationState.index];

  const [currentTab, setCurrentTab] = useState(0);
  const [emailState, dispatchEmailState] = useReducer(
    emailStateReducer,
    createEmailState()
  );
  const [visitorTypes, visit_purposes, documents, loadData] =
    useLoadData(dispatchEmailState);
  const invitationEmailData = useRef(emptyInvitationEmailData());

  const [loadingVisit, setLoadingVisit] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [accompanimentLoading, setAccompanimentLoading] = useState(false);
  const [savingVisit, setSavingVisit] = useState(false);
  const allLoadings =
    loadingVisit ||
    loadingEmail ||
    accompanimentLoading ||
    loadingCompanyData ||
    savingVisit;

  const reloadData = useCallback(
    (pg) => {
      if (pg === page) {
        loadData();
      }
    },
    [loadData, page]
  );

  useEffect(() => {
    bus.on('handleReloadData', reloadData);

    return () => bus.off('handleReloadData', reloadData);
  }, [bus, reloadData]);

  const onCloseDialog = useCallback(() => {
    dispatchInvitationState({
      type: 'INIT_EMPTY',
      payload: emptyInvitation((companyData.location || {}).language),
    });
    setCurrentTab(0);
    onClose();
  }, [onClose, companyData]);

  const loadUserPhoto = useCallback(async (photo_id) => {
    if (photo_id) {
      try {
        const document = await DocumentService.getDocument(photo_id);
        const photoUrl = document.downloadUrl
          ? DocumentService.api + document.downloadUrl
          : null;
        return photoUrl;
      } catch (error) {
        console.log(error);
        openSnackbar(error.message);
        return null;
      }
    }
    return null;
  }, []);

  const loadUserDocumentsById = useCallback(async (user_id) => {
    const documents = [];
    const personal_files = [];
    // Documents that are already assigned to user
    try {
      const userDocuments = await UserService.getUserDocuments(user_id);
      userDocuments.forEach((doc) => {
        const user_doc = UserDocumentModel.fromJson(doc);
        if (doc.type !== 'personal_document') {
          documents.push(user_doc);
        } else {
          personal_files.push(user_doc);
        }
      });
      return { documents, personal_files };
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
      return { documents: [], personal_files: [] };
    }
  }, []);

  const loadEmailLogs = useCallback(async (events) => {
    const messageIds = [];
    events.forEach((event) => {
      if (event.type === 'email') {
        messageIds.push(event.eventId);
      }
    });

    try {
      const status = await EmailService.getStatus(messageIds);
      return status.logs;
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
      return [];
    }
  }, []);

  const loadSecurityItems = useCallback(async (visit_id) => {
    try {
      const items = await SecurityItemsService.getSecurityItemsForVisit(
        visit_id
      );
      return items;
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
      return [];
    }
  }, []);

  const loadGroupVisitsWithData = useCallback(
    async (currentInvitation, group) => {
      const visits = await VisitService.getGroupVisits(group);

      //If index is not found, select the first visit
      let index = 0;
      const newInvitations = [];
      const dataPromises = [];
      for (let i = 0; i < visits.length; i++) {
        const visit = visits[i];

        let invitation;
        if (visit._id === currentInvitation.visit_id) {
          invitation = currentInvitation;
          index = i;
        } else {
          invitation = editInvitation(visit);
        }

        newInvitations.push(invitation);

        dataPromises.push(
          loadUserDocumentsById(invitation.user_id),
          loadUserPhoto(invitation.photo),
          loadEmailLogs(invitation.events),
          loadSecurityItems(invitation.visit_id)
        );
      }

      const promises = await Promise.allSettled(dataPromises);
      for (let i = 0; i < newInvitations.length; i++) {
        //We can destructure array the same way as objects, indexes are the keys
        const {
          [i * 4]: { value: documents },
          [i * 4 + 1]: { value: photoUrl },
          [i * 4 + 2]: { value: emailLogs },
          [i * 4 + 3]: { value: securityItems },
        } = promises;
        newInvitations[i] = {
          ...newInvitations[i],
          ...documents,
          photoUrl,
          emailLogs,
          securityItems,
        };
      }
      return { index, newInvitations };
    },
    [loadUserDocumentsById, loadUserPhoto, loadEmailLogs, loadSecurityItems]
  );

  const loadDataForInitialVisit = useCallback(
    async (invitation) => {
      const promises = await Promise.all([
        loadUserDocumentsById(invitation.user_id),
        loadUserPhoto(invitation.photo),
        loadEmailLogs(invitation.events),
        loadSecurityItems(invitation.visit_id),
      ]);

      const [documents, photoUrl, emailLogs, securityItems] = promises;
      return {
        ...invitation,
        ...documents,
        photoUrl,
        emailLogs,
        securityItems,
      };
    },
    [loadUserDocumentsById, loadUserPhoto, loadEmailLogs, loadSecurityItems]
  );

  const loadUserData = useCallback(async () => {
    //Prevent loading before companyData is loaded
    if (open && companyData) {
      if (selectedVisit) {
        setLoadingVisit(true);
        const visitInvitation = editInvitation(selectedVisit);

        try {
          const { group } = visitInvitation;
          if (group) {
            const { index, newInvitations } = await loadGroupVisitsWithData(
              visitInvitation,
              group
            );

            dispatchInvitationState({
              type: 'INIT_EXISTING',
              payload: {
                invitations: newInvitations,
                index,
                group,
                editAsGroupInvite,
              },
            });
          } else {
            const invitation = await loadDataForInitialVisit(visitInvitation);

            dispatchInvitationState({
              type: 'INIT_EXISTING',
              payload: {
                invitations: [invitation],
                index: 0,
                editAsGroupInvite,
              },
            });
          }
        } catch (err) {
          console.log(err);
          openSnackbar(err.message);
          onCloseDialog();
        }
        setLoadingVisit(false);
      } else {
        dispatchInvitationState({
          type: 'INIT_EMPTY',
          payload: emptyInvitation((companyData.location || {}).language),
        });
      }
    }
  }, [
    open,
    selectedVisit,
    editAsGroupInvite,
    companyData,
    onCloseDialog,
    loadDataForInitialVisit,
    loadGroupVisitsWithData,
  ]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  const addUserDocuments = async (user_id, documents, visit_id) => {
    let addDocumentPromises = [];
    for (let i = 0; i < documents.length; i++) {
      let d = documents[i];
      if (!d._id) {
        //If we are adding new feedback, attach visitId to it so we can pair it
        if (d.type === type_constants.FEEDBACK) {
          d.data.visit_id = visit_id;
        }
        addDocumentPromises.push(
          UserService.addDocument(user_id, d.type, d.status, d.data)
        );
      }
    }
    try {
      await Promise.all(addDocumentPromises);
      console.log('Added documents to user.');
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    }
  };

  const addUserFiles = async (user_id, documents) => {
    let addDocumentPromises = [];
    for (let i = 0; i < documents.length; i++) {
      let d = documents[i];
      if (!d._id) {
        addDocumentPromises.push(
          UserService.addDocument(user_id, d.type, d.status, d.data)
        );
      } else {
        addDocumentPromises.push(
          UserService.updateDocument(user_id, d._id, d.type, d.status, d.data)
        );
      }
    }
    try {
      await Promise.all(addDocumentPromises);
      console.log('Added documents to user.');
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    }
  };

  const removeUserDocuments = async (documents) => {
    let removeDocumentPromises = [];
    for (let i = 0; i < documents.length; i++) {
      removeDocumentPromises.push(UserService.removeDocument(documents[i]));
    }
    try {
      await Promise.all(removeDocumentPromises);
      console.log('Removed user documents.');
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    }
  };

  const addInspectionActivities = async (visit_id, inspectionActivities) => {
    const promises = [];
    inspectionActivities.forEach((activity) => {
      if (!activity._id) {
        promises.push(VisitService.addInspectionActivity(visit_id, activity));
      }
    });

    try {
      await Promise.all(promises);
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  // ############ PHOTO ############
  const uploadPhotoSuccess = (response) => {
    openSnackbar(i18n.t('saved'));
  };

  const uploadPhotoFailed = (error) => {
    openSnackbar(error.message);
  };

  const updateUploadingStateForInvite = (index) => (uploading) => {
    dispatchInvitationState({
      type: 'UPDATE_DRAG_DROP_INVITATION',
      payload: { index, data: { uploading } },
    });
  };

  const handleSavePhoto = async (user_id, user_name, invitation, index) => {
    const {
      base64,
      editedBase64,
      files,
      photoEditor,
      new: newClicked,
    } = invitation.dragDropState || {};
    if (newClicked) {
      await UserService.updateUserPhoto(user_id, null)
        .then(uploadPhotoSuccess)
        .catch(uploadPhotoFailed);
    } else if (editedBase64 && photoEditor) {
      try {
        let eb64 = photoEditor
          .getImageScaledToCanvas()
          .toDataURL('image/jpeg', 1.0);
        await uploadPhotoBase64(
          user_id,
          user_name,
          eb64,
          updateUploadingStateForInvite(index)
        )
          .then(uploadPhotoSuccess)
          .catch(uploadPhotoFailed);
      } catch (error) {
        await uploadPhotoFailed(error);
      }
    } else if (base64) {
      await uploadPhotoBase64(
        user_id,
        user_name,
        base64,
        updateUploadingStateForInvite(index)
      )
        .then(uploadPhotoSuccess)
        .catch(uploadPhotoFailed);
    } else if (files && files.length > 0) {
      await uploadPhotoFile(
        user_id,
        user_name,
        files,
        updateUploadingStateForInvite(index)
      )
        .then(uploadPhotoSuccess)
        .catch(uploadPhotoFailed);
    }
  };

  // ######### EMAIL #########
  const handleEmailTemplateChange = (index) => {
    const { currentInvitationIndex, invitations } = invitationEmailData.current;
    const invitation = invitations[currentInvitationIndex];

    const emailTemplates = emailState.emailTemplates;
    let template = emailTemplates[index];
    console.log('lnguage', template.lang);

    const data = templateData(invitation, locales[template.lang], companyData);
    template = TemplateService.replaceTemplate(template, data);
    const email = emptyEmail();
    email.name = template.name;
    email.lang = template.lang;
    email.visit_id = invitation.visit_id;
    email.recipient = invitation.email;
    if (invitation.delegate && invitation.delegate.trim()) {
      email.recipient = email.recipient + ', ' + invitation.delegate;
    }
    email.title = template.title;
    email.selectedValue = index;
    email.editor = TemplateService.createEditorFromHtml(template.body, 'html');
    dispatchEmailState({ type: 'EMAIL_CHANGE', payload: email });
  };

  const getActivationTokensForInvitation = async (invitation) => {
    const { user_id } = invitation;
    try {
      const activationToken = await UserService.getUserActivationToken(user_id);
      const activationUrlPlain = UserService.getUserActivationUrl(
        user_id,
        activationToken.activation_token
      );
      const activationUrl = util.format(
        '<a href="%s">%s</a>',
        activationUrlPlain,
        activationUrlPlain
      );
      invitation.activationUrl = activationUrl;
      invitation.activationUrlPlain = activationUrlPlain;
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailToNextVisitor = async () => {
    const emailTemplates = emailState.emailTemplates;
    const { currentInvitationIndex, invitations } = invitationEmailData.current;
    const invitation = invitations[currentInvitationIndex];
    const { language } = invitation;

    await getActivationTokensForInvitation(invitation);

    if (emailTemplates.length > 0) {
      const template = TemplateService.findNotificationTemplateByLanguage(
        emailTemplates,
        language
      );
      handleEmailTemplateChange(emailTemplates.indexOf(template));
    }
  };

  const handleEmailModalOpen = async (invitations) => {
    setLoadingEmail(true);

    dispatchEmailState({ type: 'OPEN_MODAL' });
    if (invitations.length > 1) {
      dispatchEmailState({ type: 'ENABLE_SEND_ALL' });
    }

    invitationEmailData.current.invitations = invitations;

    try {
      await handleEmailToNextVisitor();
    } catch (err) {
      console.log(err);
    }

    setLoadingEmail(false);
  };

  const changeEmailData = (name, value, index) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'selectedValue') {
      // Prefill the email template data
      handleEmailTemplateChange(fieldValue);
    } else {
      const email = { [field]: fieldValue };
      dispatchEmailState({ type: 'EMAIL_CHANGE', payload: email });
    }
  };

  const handleEmailModalClose = () => {
    dispatchEmailState({ type: 'CLOSE_MODAL' });
    dispatchEmailState({ type: 'DISABLE_SEND_ALL' });
    dispatchEmailState({ type: 'EMAIL_CHANGE', payload: emptyEmail() });
    invitationEmailData.current = emptyInvitationEmailData();
  };

  const handleEmailTextEditorChange = (value) => {
    const email = { editor: value };
    dispatchEmailState({ type: 'EMAIL_CHANGE', payload: email });
  };

  const handleSendEmail = async () => {
    const { recipient, title, editor, visit_id } = emailState.email;
    const { currentInvitationIndex, invitations } = invitationEmailData.current;

    dispatchEmailState({
      type: 'EMAIL_CHANGE',
      payload: { sendingEmail: true },
    });

    const templateHtml = TemplateService.draftToHtml(
      editor.getCurrentContent()
    );
    try {
      await EmailService.send(
        recipient,
        title,
        templateHtml,
        'visit',
        visit_id
      );
      openSnackbar(i18n.t('template_email_sent'));
      //If the email was sent to the last visitor in the group invitation, close the dialog, otherwise prepare data for another one
      if (currentInvitationIndex === invitations.length - 1) {
        handleEmailModalClose();
      } else {
        setLoadingEmail(true);

        invitationEmailData.current.currentInvitationIndex++;
        await handleEmailToNextVisitor();

        setLoadingEmail(false);
      }
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }

    dispatchEmailState({
      type: 'EMAIL_CHANGE',
      payload: { sendingEmail: false },
    });
  };

  const handleSendAllEmails = async () => {
    const {
      emailTemplates,
      email: { recipient, title, editor, visit_id },
    } = emailState;
    const { currentInvitationIndex, invitations } = invitationEmailData.current;

    dispatchEmailState({
      type: 'EMAIL_CHANGE',
      payload: { sendingEmail: true },
    });

    const promises = [];

    //If sending emails to every visitor in the group invite, firstly send the email to currently displayed visitor with currently
    //displayed content
    const templateHtml = TemplateService.draftToHtml(
      editor.getCurrentContent()
    );
    promises.push(
      EmailService.send(recipient, title, templateHtml, 'visit', visit_id)
    );

    //After that, iterate over the remaining visitors, compile templates and send them without displaying them to screen
    // + 1 because the first mail is already waiting to be sent
    for (let i = currentInvitationIndex + 1; i < invitations.length; i++) {
      const invitation = invitations[i];

      const { language } = invitation;

      await getActivationTokensForInvitation(invitation);

      if (emailTemplates.length > 0) {
        let template = TemplateService.findNotificationTemplateByLanguage(
          emailTemplates,
          language,
          companyData.location.language
        );
        const data = templateData(
          invitation,
          locales[template.lang],
          companyData
        );
        template = TemplateService.replaceTemplate(template, data);

        promises.push(
          EmailService.send(
            invitation.email,
            template.title,
            template.body,
            'visit',
            invitation.visit_id
          )
        );
      }
    }

    const results = await Promise.allSettled(promises);
    results.forEach((res) => {
      if (res.status === 'rejected') {
        const err = res.reason;
        console.log(err);
        openSnackbar(err.message);
      }
    });
    openSnackbar(i18n.t('template_email_sent'));

    handleEmailModalClose();

    dispatchEmailState({
      type: 'EMAIL_CHANGE',
      payload: { sendingEmail: false },
    });
  };

  const sendCreateSms = async (user) => {
    try {
      const smsTemplates = await Promise.all([
        TemplateService.getTemplatesByCategoryAndType(
          template_categories.SMS,
          template_types.USER_PROFILE_CREATED
        ),
        TemplateService.getTemplatesByCategoryAndType(
          template_categories.SMS,
          template_types.PIN
        ),
      ]);

      for (let i = 0; i < smsTemplates.length; i++) {
        smsTemplates[i] = TemplateService.findNotificationTemplateByLanguage(
          smsTemplates[i],
          user.language,
          companyData.location.language
        );
      }

      if (smsTemplates[0]) {
        const template = TemplateService.replaceTemplate(smsTemplates[0], {
          user: user,
          url: TemplateService.callbackUrl,
        });
        await SmsService.send(user.phone, template.body);
      }
      if (smsTemplates[1]) {
        const template = TemplateService.replaceTemplate(smsTemplates[1], {
          user: user,
        });
        await SmsService.send(user.phone, template.body);
      }
    } catch (err) {
      console.log(err);
      //Dont show errors related to sms
    }
  };

  const sendInvitationSms = async (invite) => {
    try {
      let smsTemplate = await TemplateService.getTemplatesByCategoryAndType(
        template_categories.SMS,
        template_types.INVITE
      );
      smsTemplate = TemplateService.findNotificationTemplateByLanguage(
        smsTemplate,
        invite.language,
        companyData.location.language
      );

      const data = templateData(invite, locales[smsTemplate.lang]);
      const template = TemplateService.replaceTemplate(smsTemplate, data);
      await SmsService.send(invite.phone, template.body);
    } catch (err) {
      console.log(err);
    }
  };

  const createUser = (invitation) => {
    const data = {
      name: invitation.name,
      email: invitation.email,
      company: invitation.company,
      phone: invitation.phone,
      language: invitation.language,
      role: user_roles.VISITOR,
      status: user_status.PENDING,
      emailShared: invitation.emailShared,
      login: invitation.login,
    };
    return UserService.create(data);
  };

  const createOrUpdateVisitors = async (visitorInvitations) => {
    for (let i = 0; i < visitorInvitations.length; i++) {
      const invite = visitorInvitations[i];
      const { user_id, email, phone, oldPhone } = invite;

      if (user_id) {
        if (oldPhone !== phone) {
          await UserService.updateVisitorPhone(user_id, phone);
        }
      } else {
        const response = await createUser(invite);
        invite.user_id = response._id;
        //In case the invitation saving fails, update the invitation with user_id
        dispatchInvitationState({
          type: 'UPDATE_INVITATION',
          payload: { index: i, data: { user_id: response._id } },
        });
        openSnackbar(i18n.t('snackbar_invite_createuser_success'));

        if (!email && phone) {
          const user = await UserService.getUser(invite.user_id);
          //Await here so the invitation SMS wont get sent first
          await sendCreateSms(user);
        }
      }
    }
  };

  const updateVisitorsVisitIds = (
    visitorInvitations,
    created = {},
    updated = {}
  ) => {
    //This basically just adds visit_ids to invitations in group invitation
    for (let i = 0; i < visitorInvitations.length; i++) {
      const invitation = visitorInvitations[i];

      if (created[invitation.user_id]) {
        invitation.visit_id = created[invitation.user_id];
      }

      //Update has higher priority
      if (updated[invitation.user_id]) {
        invitation.visit_id = updated[invitation.user_id];
      }
    }
  };

  const updateVisitorsData = async (visitorInvitations) => {
    // Paralelized on per user basis for https://redmine.ad.m2ms.sk/issues/13934
    const promises = visitorInvitations.map(async (_, i) => {
      const invite = visitorInvitations[i];
      const {
        user_id,
        visit_id,
        name,
        email,
        phone,
        documents: userDocuments,
        documentsToRemove,
        personal_files,
        inspectionActivities,
      } = invite;

      await addUserDocuments(user_id, userDocuments, visit_id);
      await removeUserDocuments(documentsToRemove);
      visitorInvitations[i].documentsToRemove = [];
      await addUserFiles(user_id, personal_files);
      await addInspectionActivities(visit_id, inspectionActivities);
      await handleSavePhoto(user_id, name, invite, i);
      //After removing docs, reset the documentsToRemove array
      dispatchInvitationState({
        type: 'UPDATE_INVITATION',
        payload: { index: i, data: { documentsToRemove: [] } },
      });

      if (!email && phone) {
        sendInvitationSms(invite);
      }
    });

    return Promise.all(promises);
  };

  const handleSaveInvite = (sendEmail) => async (event) => {
    const { isGroupInvitation, size, index, group, invitations } =
      invitationState;
    const arrival = moment(invitations[invitations.length - 1].arrival);
    arrival.add(9, 'days').add(3, 'months');
    if (arrival._d < invitations[invitations.length - 1].departure) {
      openSnackbar(
        'The visit is too long set the visit under 3 months and 9 days'
      );
      return;
    }
    setSavingVisit(true);

    console.log('Save invite with send email', sendEmail);

    const lastInvitation = invitations[invitations.length - 1];
    const validLastInvitation =
      lastInvitation.name &&
      (lastInvitation.email || lastInvitation.phone) &&
      (lastInvitation.phone
        ? !validatePhoneNumber(lastInvitation.phone)
        : true);

    const visitorInvitations = [];
    if (isGroupInvitation) {
      //If group invitation, the size depends whether or not the displayed user is added to the list of invites
      const invitationsSize =
        index >= size && validLastInvitation ? size + 1 : size;
      for (let i = 0; i < invitationsSize; i++) {
        visitorInvitations.push({ ...invitations[i] });
      }
    } else {
      visitorInvitations.push({ ...currentInvitation });
    }

    try {
      //Create or update visitors first
      await createOrUpdateVisitors(visitorInvitations);
      const userIds = visitorInvitations.map((vst) => vst.user_id);
      const visitorTypes = visitorInvitations.map((vst) => vst.visitorTypes);

      let response = {};
      if (!selectedVisit) {
        response = await VisitService.invite(
          userIds,
          visitorTypes,
          group,
          createInvitationDetails(currentInvitation)
        );
        openSnackbar(i18n.t('snackbar_invite_save_success'));
      } else {
        response = await VisitService.update(
          selectedVisit._id,
          userIds,
          visitorTypes,
          group,
          createInvitationDetails(currentInvitation),
          isGroupInvitation
        );
        openSnackbar(i18n.t('snackbar_invite_save_success'));
      }

      const { created, updated } = response;
      updateVisitorsVisitIds(visitorInvitations, created, updated);

      await updateVisitorsData(visitorInvitations);

      onCloseDialog();
      onSave();

      if (sendEmail) {
        handleEmailModalOpen(
          visitorInvitations
            .filter((vst) =>
              //Current invitation has the latest common info of all the invitations
              //Since we copy the common info only to currently selected invitation, it is required to copy the common info
              //to the rest of the invitations
              EmailValidator.validate(vst.email)
            )
            .map((invitation) =>
              createInvitationForGroupVisit(currentInvitation, invitation)
            )
        );
      }
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setSavingVisit(false);
    }
  };

  const handleUserSuggestionsClearRequested = () => (event) => {
    if (currentInvitation.user_id) {
      dispatchInvitationState({
        type: 'UPDATE_CURRENT_INVITATION',
        payload: {
          user_id: null,
          email: '',
          company: '',
          photo: null,
          phone: '',
          user_status: user_status.PENDING,
          language: (companyData.location || {}).language,
          emailShared: false,
          emailSharedEnabled: true,
          tenantId: null,
          login: '',
        },
      });
    }
  };

  const handleEmailSuggestionsClearRequested = () => (event) => {
    if (currentInvitation.user_id) {
      dispatchInvitationState({
        type: 'UPDATE_CURRENT_INVITATION',
        payload: {
          user_id: null,
          name: '',
          company: '',
          photo: null,
          phone: '',
          user_status: user_status.PENDING,
          language: (companyData.location || {}).language,
          emailShared: false,
          emailSharedEnabled: true,
          tenantId: null,
          login: '',
        },
      });
    }
  };

  const handlePhoneSuggestionsClearRequested = () => (event) => {
    //Since we are able to change phone, no clearing is necessary
  };

  const handleHostSuggestionsClearRequested = () => (event) => {
    dispatchInvitationState({
      type: 'UPDATE_CURRENT_INVITATION',
      payload: { host_id: null },
    });
  };

  const handleUserSuggestionSelected = (suggestion) => async (event) => {
    const suggestionObj = {};
    suggestionObj.user_id = suggestion._id;
    suggestionObj.name = suggestion.name;
    suggestionObj.email = suggestion.email ?? '';
    suggestionObj.phone = suggestion.phone ?? '';
    suggestionObj.user_status = suggestion.status ?? '';
    suggestionObj.language = suggestion.language ?? '';
    suggestionObj.company = suggestion.company;
    suggestionObj.photo = suggestion.photo;
    suggestionObj.oldPhone = suggestion.phone ?? '';
    suggestionObj.tenantId = suggestion.tenantId ?? '';
    suggestionObj.emailShared = suggestion.emailShared ?? false;
    suggestionObj.login = suggestion.login ?? '';
    const docs = await loadUserDocumentsById(suggestion._id);
    suggestionObj.documents = docs.documents;
    suggestionObj.personal_files = docs.personal_files;
    if (suggestion.photo) {
      suggestionObj.photoUrl = await loadUserPhoto(suggestion.photo);
    }
    dispatchInvitationState({
      type: 'UPDATE_CURRENT_INVITATION',
      payload: { ...suggestionObj },
    });
  };

  const handleHostSuggestionSelected = (suggestion) => (event) => {
    dispatchInvitationState({
      type: 'UPDATE_CURRENT_INVITATION',
      payload: { host_id: suggestion._id, host: suggestion.name },
    });
  };

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value);
  };

  const handleChangeTabsIndex = (index) => {
    setCurrentTab(index);
  };

  const handleRemoveEvent = (visitEvent, invitationIndex) => (event) => {
    const fromGroupEventsTab = typeof invitationIndex === 'number';
    const { invitations } = invitationState;
    const invitation = fromGroupEventsTab
      ? invitations[invitationIndex]
      : currentInvitation;
    console.log('Remove', visitEvent, invitation.visit_id);

    let events = [...invitation.events];
    events = removeByKey(events, { key: '_id', value: visitEvent.id });

    VisitService.deleteEvent(invitation.visit_id, visitEvent.id)
      .then((response) => {
        openSnackbar(i18n.t('removed'));
        //loadEmailLogs(events); //I dont think this is needed
        if (fromGroupEventsTab) {
          dispatchInvitationState({
            type: 'UPDATE_INVITATION',
            payload: { index: invitationIndex, data: { events } },
          });
        } else {
          dispatchInvitationState({
            type: 'UPDATE_CURRENT_INVITATION',
            payload: { events },
          });
        }
        onSave();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(i18n.t('visitor_was_update_error'));
      });
  };

  const handleSecurityItemChange =
    (item, action, invitationIndex) => (event) => {
      const fromGroupEventsTab = typeof invitationIndex === 'number';
      const item_id = item._id;
      console.log('handleSecurityItemChange', item, action);

      const success = async (response) => {
        openSnackbar(i18n.t('edited'));
        const securityItems = await loadSecurityItems(item.visit);
        if (fromGroupEventsTab) {
          dispatchInvitationState({
            type: 'UPDATE_INVITATION',
            payload: { index: invitationIndex, data: { securityItems } },
          });
        } else {
          dispatchInvitationState({
            type: 'UPDATE_CURRENT_INVITATION',
            payload: { securityItems },
          });
        }
      };
      const failure = (error) => {
        console.log(error);
        openSnackbar(i18n.t('visitor_was_update_error'));
      };

      if (action === 'invalidate') {
        SecurityItemsService.invalidateSecurityItem(item_id)
          .then(success)
          .catch(failure);
      }

      if (action === 'refresh') {
        SecurityItemsService.refreshSecurityItem(item_id)
          .then(success)
          .catch(failure);
      }
    };

  const handleChangeInvitationData = useCallback(
    (name, value, index, invitationIndex) => (event) => {
      const field = name !== undefined ? name : event.target.name;
      let fieldValue = value !== undefined ? value : event.target.value;
      if (field === 'email' || field === 'delegate') {
        fieldValue = fieldValue.trim();
      }

      if (field === 'addDatesOff') {
        dispatchInvitationState({ type: field, payload: fieldValue });
        return;
      }

      if (field === 'removeDateOff') {
        dispatchInvitationState({ type: field, payload: fieldValue });
        return;
      }
      if (field === 'daysOff') {
        dispatchInvitationState({ type: field, payload: fieldValue });
        return;
      }
      if (field === 'name') {
        // If we changed name (user from autosuggest), we need to clear the user_id
        dispatchInvitationState({
          type: 'CHANGE_INVITATION_NAME',
          payload: fieldValue,
        });
      }
      if (field === 'group') {
        dispatchInvitationState({
          type: 'CHANGE_GROUP_NAME',
          payload: fieldValue,
        });
      } else if (field === 'documents') {
        dispatchInvitationState({
          type: 'ADD_DOCUMENT',
          payload: { document: fieldValue, invitationIndex },
        });
      } else if (field === 'documents_remove') {
        dispatchInvitationState({
          type: 'REMOVE_DOCUMENT',
          payload: {
            indexToRemove: index,
            invitationIndex,
            document: fieldValue,
          },
        });
      } else if (field === 'visitorType') {
        if (invitationIndex === undefined) {
          console.log('neheasd', documents);
          dispatchInvitationState({
            type: 'ADD_GROUP_VISITOR_TYPE',
            payload: {
              visitorType: visitorTypes.find((type) => type.key === fieldValue),
              documents,
            },
          });
        } else {
          dispatchInvitationState({
            type: 'ADD_VISITOR_TYPE',
            payload: {
              visitorType: visitorTypes.find((type) => type.key === fieldValue),
              documents,
              index: invitationIndex,
            },
          });
        }
      } else {
        dispatchInvitationState({
          type: 'UPDATE_CURRENT_INVITATION',
          payload: { [field]: fieldValue },
        });
      }
    },
    [documents, visitorTypes]
  );

  const handleRemoveFile = (indexToRemove, value, invitationIndex) => {
    dispatchInvitationState({
      type: 'REMOVE_FILE',
      payload: { indexToRemove, value, invitationIndex },
    });
  };

  const handleChangeFileInvitationData =
    (name, value, index, invitationIndex) => (event) => {
      const field = name || event.target.name;
      let fieldValue = value ?? event.target.value;
      if (field === 'documents_remove') {
        handleOpenConfirmDialog(
          i18n.t('visits_file_dialog_remove_title'),
          i18n.t('visits_file_dialog_remove_description'),
          () => handleRemoveFile(index, value, invitationIndex)
        );
      } else if (field === 'rename') {
        dispatchInvitationState({
          type: 'RENAME_FILE',
          payload: { indexToRename: index, invitationIndex, name: fieldValue },
        });
      }
    };

  const handleDragDrop = (value, invitationIndex) => {
    dispatchInvitationState({
      type: 'DRAG_DROP',
      payload: { dragDropState: { ...value }, invitationIndex },
    });
  };

  const handleDragDropFile = (value, invitationIndex) => {
    dispatchInvitationState({
      type: 'DRAG_DROP_FILE',
      payload: { dragDropFileState: { ...value }, invitationIndex },
    });
  };

  const handleAddVisitorToGroupVisit = () => {
    dispatchInvitationState({
      type: 'ADD_VISITOR',
      payload: { companyData, documents, visitorTypes },
    });
  };

  const handleChangeCurrentVisitFromGroup = (index) => () => {
    dispatchInvitationState({
      type: 'CHANGE_CURRENT_INVITATION',
      payload: index,
    });
  };

  const handleRemoveVisitorFromGroupVisit = (indexToRemove) => () => {
    handleOpenConfirmDialog(
      i18n.t('dashboard_visitors_dialog_remove_title'),
      i18n.t('dashboard_visitors_dialog_remove_description'),
      () =>
        dispatchInvitationState({
          type: 'REMOVE_INVITATION',
          payload: { indexToRemove, companyData },
        })
    );
  };

  const handleConfirmVisitor = async () => {
    setAccompanimentLoading(true);
    try {
      await SecurityItemService.confirmVisit(selectedVisit._id);
      onCloseDialog();
      onSave();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
    setAccompanimentLoading(false);
  };

  const accompanyAVisit = async () => {
    const { isGroupInvitation } = invitationState;
    setAccompanimentLoading(true);

    try {
      await VisitService.accompanyAVisit(
        currentInvitation.visit_id,
        isGroupInvitation
      );
      openSnackbar(t('invite_form_accompaniment_successful'));

      onCloseDialog();
      onSave();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setAccompanimentLoading(false);
    }
  };

  return (
    <Aux>
      <InviteDialog
        newInvitation={!Boolean(selectedVisit)}
        invitation={currentInvitation}
        currentTab={currentTab}
        loading={allLoadings}
        visitorTypes={visitorTypes}
        visit_purposes={visit_purposes}
        companyData={companyData}
        documents={documents}
        open={open}
        handleChangeTabs={handleChangeTabs}
        handleChangeTabsIndex={handleChangeTabsIndex}
        handleChangeInvitationData={handleChangeInvitationData}
        handleChangeFileInvitationData={handleChangeFileInvitationData}
        handleDragDrop={handleDragDrop}
        handleDragDropFile={handleDragDropFile}
        handleUserSuggestionsClearRequested={
          handleUserSuggestionsClearRequested
        }
        handleUserSuggestionSelected={handleUserSuggestionSelected}
        handleEmailSuggestionsClearRequested={
          handleEmailSuggestionsClearRequested
        }
        handlePhoneSuggestionsClearRequested={
          handlePhoneSuggestionsClearRequested
        }
        handleHostSuggestionSelected={handleHostSuggestionSelected}
        handleHostSuggestionsClearRequested={
          handleHostSuggestionsClearRequested
        }
        onClose={onCloseDialog}
        onSave={handleSaveInvite()}
        onSend={handleSaveInvite(true)}
        onRemoveEvent={handleRemoveEvent}
        onSecurityItemChange={handleSecurityItemChange}
        selectedDate={selectedDate}
        selectedVisit={selectedVisit}
        invitationState={invitationState}
        handleAddVisitorToGroupVisit={handleAddVisitorToGroupVisit}
        handleRemoveVisitorFromGroupVisit={handleRemoveVisitorFromGroupVisit}
        handleChangeCurrentVisitFromGroup={handleChangeCurrentVisitFromGroup}
        accompanyAVisit={accompanyAVisit}
        confirmVisitor={handleConfirmVisitor}
      />
      <TemplateEditor
        open={emailState.emailModalOpen}
        loading={allLoadings}
        onClose={handleEmailModalClose}
        category={template_categories.EMAIL}
        type={template_types.INVITE}
        editMode={false}
        templateState={emailState.email}
        languages={true}
        onChange={changeEmailData}
        selectItems={emailState.inviteEmailTemplates}
        onTextEditorChange={handleEmailTextEditorChange}
        handleSendTemplate={handleSendEmail}
        handleSendAllTemplates={
          emailState.sendAllEnabled ? handleSendAllEmails : undefined
        }
      />
    </Aux>
  );
};

InviteVisitor.propTypes = {
  bus: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  selectedVisit: PropTypes.object,
  selectedDate: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  editAsGroupInvite: PropTypes.bool.isRequired,
};

const skipRender = (prevProps, nextProps) => {
  const open = nextProps.open || prevProps.open;
  return !open;
};

export default withBus()(memo(InviteVisitor, skipRender));
