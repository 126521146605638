import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.white,
    '& > button': {
      flex: 1,
      textTransform: 'none',
    },
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[3],
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));

const HistoryButtons = ({ labels = [], selected, onSelect }) => {
  const classes = useStyles();

  const handleSelect = (event, i) => {
    //Prevent unselecting already selected button
    if (i !== null && i !== undefined) {
      onSelect(i);
    }
  };

  return (
    <ToggleButtonGroup
      className={classes.root}
      size="small"
      value={selected}
      exclusive
      onChange={handleSelect}
      aria-label="my visits history"
    >
      {labels.map((label) => {
        return (
          <ToggleButton
            data-cy={label.buttonDataCy}
            key={label.key}
            value={label.key}
            classes={{ selected: classes.selected }}
          >
            {label.value}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

HistoryButtons.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default HistoryButtons;
