import PropTypes from 'prop-types';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import {
  AccessTime,
  CheckCircle,
  Edit,
  FileCopy,
  Flight,
  FolderOpen,
  Language,
  LocationOn,
  Search,
} from '@material-ui/icons';
import Hotel from '@material-ui/icons/Hotel';
import { itinerary_categories, itinerary_types_transport } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import {
  openSnackbar,
  VmsDialog,
  VmsDialogRowDateTimePicker,
  VmsInput,
} from 'components';
import produce from 'immer';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlightService, ItineraryService } from 'services';
import { useImmer } from 'use-immer';
import FlightSearchDialog from './FlighSearchDialog';

const flightNumberRe = /^([a-zA-Z]{3}|[a-zA-Z\d]{1,2})(?:\s?)(\d{1,4})$/;

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme, 400),
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(1),
  },
  dialogRowDialog: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
}));

const ItineraryDetailDialog = ({
  open,
  visitId,
  itinerary,
  originalItem,
  fullScreen,
  onClose,
  onSave,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [item, setItem] = useImmer({});

  const [searchOpen, setSearchOpen] = useState(false);

  const [flightNumber, setFlightNumber] = useState('');
  const [flightDate, setFlightDate] = useState(moment());

  const [updating, setUpdating] = useState(false);
  const [fetchingFlightStatus, setFetchingFlightStatus] = useState(false);
  const allLoadings = updating || fetchingFlightStatus;

  const handleOpenSearch = (event) => {
    setSearchOpen(true);

    setFlightNumber((prevNumber) => item.number ?? prevNumber);
    setFlightDate((prevDate) =>
      item.date_departure ? moment(item.date_departure) : prevDate
    );
  };

  const handleCloseSearch = useCallback((event) => {
    setSearchOpen(false);
  }, []);

  const handleFlight = useCallback(
    (flightNumber, flightDate) => async () => {
      // Check flight
      if (flightNumber && flightDate) {
        flightNumber = flightNumber.trim();
        if (flightNumberRe.test(flightNumber)) {
          setFetchingFlightStatus(true);

          try {
            const status = await FlightService.getFlight(
              flightNumber,
              flightDate.format('YYYY-MM-DD')
            );
            setItem((draft) => {
              draft.number = flightNumber;
              if (status.operator) draft.operator = status.operator;
              if (status.departureAirport)
                draft.place_departure = status.departureAirport;
              if (status.arrivalAirport)
                draft.place_arrival = status.arrivalAirport;
              if (status.departureGate)
                draft.place_departure = `${
                  draft.place_departure || ''
                } (GATE: ${status.departureGate})`;
              if (status.arrivalGate)
                draft.place_arrival = `${draft.place_arrival || ''} (GATE: ${
                  status.arrivalGate
                })`;
              if (status.departureDateLocal)
                draft.date_departure = moment(
                  Date.parse(status.departureDateLocal)
                ).toDate();
              if (status.arrivalDateLocal)
                draft.date_arrival = moment(
                  Date.parse(status.arrivalDateLocal)
                ).toDate();
              if (status.equipmentName) draft.airplane = status.equipmentName;
              draft.status = t(`flight_status_${status.status}`);
            });

            openSnackbar(t('itinerary_entry_flight_found'));
          } catch (err) {
            openSnackbar(t('itinerary_entry_flight_notfound'));
          } finally {
            setFetchingFlightStatus(false);
          }
        } else {
          openSnackbar(t('itinerary_entry_flight_number_invalid'));
        }
      } else {
        openSnackbar(t('itinerary_entry_flight_number_invalid'));
      }
    },
    [setItem, t]
  );

  const onChange = (name, value) => (event) => {
    const field = name ?? event.target.name;
    const fieldValue = value ?? event.target.value;
    setItem((draft) => {
      draft[field] = fieldValue;
    });
  };

  const handleArrivalChange = (date) => {
    const departure = item.date_departure;
    if (departure && date < departure) {
      date = moment(departure).add(1, 'hours').toDate();
    }
    onChange('date_arrival', date)();
  };

  const handleDepartureChange = (date) => {
    onChange('date_departure', date)();
    const arrival = item.date_arrival;
    if (arrival && date >= arrival) {
      const arrival = moment(date).add(1, 'hours').toDate();
      handleArrivalChange(arrival);
    }
  };

  const handleSave = async () => {
    const { category } = item;

    setUpdating(true);
    try {
      if (!itinerary._id) {
        const newItinerary = {
          visit: visitId,
          [category]: {
            items: [item],
          },
        };

        await ItineraryService.create(newItinerary);
        onSave(newItinerary);
      } else {
        const newItinerary = produce(itinerary, (draft) => {
          if (!item._id) {
            // Add to itinerary
            draft[category].items.push(item);
          } else {
            // Update itinerary item, the item will always be found
            const index = draft[category].items.findIndex(
              (i) => i._id === item._id
            );
            draft[category].items[index] = item;
          }
        });

        await ItineraryService.update(newItinerary);
        onSave(newItinerary);
      }

      openSnackbar(t('saved'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleDelete = async () => {
    // Always update
    const { category } = item;
    const newItinerary = produce(itinerary, (draft) => {
      // Update itinerary item, the item will always be found
      const index = draft[category].items.findIndex((i) => i._id === item._id);
      if (index > -1) {
        draft[category].items.splice(index, 1);
      }
    });

    setUpdating(true);
    try {
      await ItineraryService.update(newItinerary);
      openSnackbar(t('removed'));
      onSave(newItinerary);
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    setItem(originalItem);

    // Check flight
    const { itemType, date_departure, number } = originalItem;
    if (
      itemType === itinerary_types_transport.FLIGHT &&
      date_departure &&
      number
    ) {
      handleFlight(number, date_departure)();
    }
  }, [originalItem, handleFlight, setItem]);

  return (
    <>
      <VmsDialog
        open={open}
        xsPaperMode={true}
        dialogHeaderTitle={
          item._id ? t('itinerary_item_edit') : t('itinerary_item_new')
        }
        onClose={onClose}
        closeButtonDataCy="itinerary-detail-button-close"
        dialogHeaderIcon={<FolderOpen />}
        buttonIcon={<CheckCircle className={classes.fabIcon} />}
        buttonText={t('save')}
        buttonDisabled={false}
        buttonAction={handleSave}
        buttonDataCy="itinerary-detail-button-save"
        buttonDeleteAction={handleDelete}
        buttonDeleteDisabled={!item._id}
        buttonDeleteDataCy="itinerary-detail-button-delete"
        loading={allLoadings}
      >
        {item.category === itinerary_categories.TRANSPORT && (
          <>
            <VmsInput
              rowClass={classes.dialogRow}
              iconLeft={<FileCopy />}
              textValue={item.number}
              placeholder={t('itinerary_entry_number')}
              inputLabel={t('itinerary_entry_number')}
              onChange={onChange('number')}
              inputName="number"
              iconRight={
                item.itemType === itinerary_types_transport.FLIGHT ? (
                  <IconButton color="primary" onClick={handleOpenSearch}>
                    <Search />
                  </IconButton>
                ) : undefined
              }
            />
            <VmsInput
              rowClass={classes.dialogRow2}
              iconLeft={<FolderOpen />}
              textValue={item.operator}
              placeholder={t('itinerary_entry_operator')}
              inputLabel={t('itinerary_entry_operator')}
              onChange={onChange('operator')}
              inputName="operator"
            />
            <VmsDialogRowDateTimePicker
              rowClass={classes.dialogRow2}
              iconLeft={<AccessTime />}
              dateValue={[item.date_departure, item.date_arrival]}
              placeholder={[
                t('itinerary_entry_departure'),
                t('itinerary_entry_arrival'),
              ]}
              onChange={[handleDepartureChange, handleArrivalChange]}
            />
            <Grid container className={classes.dialogRow2}>
              <Grid item>
                <Language className={classes.iconLeft} />
              </Grid>
              <Grid item className={classes.rowContent}>
                <Grid container spacing={1} direction="row">
                  <Grid item xs={6}>
                    <VmsInput
                      textValue={item.place_departure}
                      placeholder={t('itinerary_entry_departure')}
                      inputLabel={t('itinerary_entry_departure')}
                      inputClasses={{ input: classes.fullWidth }}
                      onChange={onChange('place_departure')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <VmsInput
                      textValue={item.place_arrival}
                      placeholder={t('itinerary_entry_arrival')}
                      inputLabel={t('itinerary_entry_arrival')}
                      inputClasses={{ input: classes.fullWidth }}
                      onChange={onChange('place_arrival')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {item.category === itinerary_categories.ACCOMODATION && (
          <>
            <VmsInput
              rowClass={classes.dialogRow}
              iconLeft={<Hotel />}
              textValue={item.operator}
              placeholder={t('hotel')}
              inputLabel={t('hotel')}
              onChange={onChange('operator')}
              inputName="operator"
            />
            <VmsInput
              rowClass={classes.dialogRow2}
              iconLeft={<LocationOn />}
              textValue={item.place_arrival}
              placeholder={t('itinerary_location')}
              inputLabel={t('itinerary_location')}
              onChange={onChange('place_arrival')}
              inputName="place_arrival"
            />
            <VmsDialogRowDateTimePicker
              rowClass={classes.dialogRow2}
              iconLeft={<AccessTime />}
              dateValue={[item.date_departure, item.date_arrival]}
              placeholder={[
                t('itinerary_entry_arrival'),
                t('itinerary_entry_departure'),
              ]}
              onChange={[handleDepartureChange, handleArrivalChange]}
            />
          </>
        )}
        {item.itemType === itinerary_types_transport.FLIGHT && (
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<Flight />}
            textValue={item.airplane}
            placeholder={t('itinerary_entry_airplane')}
            inputLabel={t('itinerary_entry_airplane')}
            onChange={onChange('airplane')}
            inputName="airplane"
          />
        )}
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<Edit />}
          textValue={item.status}
          placeholder={t('itinerary_entry_status')}
          inputLabel={t('itinerary_entry_status')}
          onChange={onChange('status')}
          inputName="status"
        />
      </VmsDialog>
      <FlightSearchDialog
        classes={classes}
        open={searchOpen}
        loading={fetchingFlightStatus}
        fullScreen={fullScreen}
        onClose={handleCloseSearch}
        handleFlight={handleFlight}
        flightDate={flightDate}
        flightNumber={flightNumber}
        setFlightDate={setFlightDate}
        setFlightNumber={setFlightNumber}
      />
    </>
  );
};

ItineraryDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  visitId: PropTypes.string,
  itinerary: PropTypes.object.isRequired,
  originalItem: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ItineraryDetailDialog;
