import Flight from '@material-ui/icons/Flight/';
import Taxi from '@material-ui/icons/LocalTaxi';
import Train from '@material-ui/icons/Train';
import Hotel from '@material-ui/icons/Hotel';
import Bus from '@material-ui/icons/DirectionsBus';
import Folder from '@material-ui/icons/Folder';
import i18n from 'assets/i18n';

import flagUK from 'assets/images/flag_uk.png';
import flagSK from 'assets/images/flag_sk.png';
import flagDE from 'assets/images/flag_de.png';
import flagCZ from 'assets/images/flag_cz.png';
import flagES from 'assets/images/flag_es.png';
import flagFR from 'assets/images/flag_fr.png';
import flagHU from 'assets/images/flag_hu.png';
import flagZH from 'assets/images/flag_zh.png';
import flagIN from 'assets/images/flag_in.png';
import flagIT from 'assets/images/flag_it.png';
import flagJA from 'assets/images/flag_ja.png';
import flagKR from 'assets/images/flag_kr.png';
import flagMY from 'assets/images/flag_my.png';
import flagNL from 'assets/images/flag_nl.png';
import flagPH from 'assets/images/flag_ph.png';
import flagPL from 'assets/images/flag_pl.png';
import flagPT from 'assets/images/flag_pt.png';
import flagRO from 'assets/images/flag_ro.png';
import flagRU from 'assets/images/flag_ru.png';
import flagTH from 'assets/images/flag_th.png';
import flagNLBE from 'assets/images/flag_nlbe.png';
import avatar1 from 'assets/images/avatar1.png';
import avatar2 from 'assets/images/avatar2.png';
import avatar3 from 'assets/images/avatar3.png';
import avatar4 from 'assets/images/avatar4.png';
import avatar5 from 'assets/images/avatar5.png';
import avatar6 from 'assets/images/avatar6.png';
import avatar7 from 'assets/images/avatar7.png';
import avatar8 from 'assets/images/avatar8.png';
import avatar9 from 'assets/images/avatar9.png';
import avatar10 from 'assets/images/avatar10.png';
import avatar11 from 'assets/images/avatar11.png';
import avatar12 from 'assets/images/avatar12.png';
import avatar13 from 'assets/images/avatar13.png';
import avatar14 from 'assets/images/avatar14.png';
import avatar15 from 'assets/images/avatar15.png';
import avatar16 from 'assets/images/avatar16.png';
import avatar17 from 'assets/images/avatar17.png';
import avatar18 from 'assets/images/avatar18.png';
import avatar19 from 'assets/images/avatar19.png';
import avatar20 from 'assets/images/avatar20.png';
import moment from 'moment';

export const reversedLocales = {
  sk: 'sk',
  en: 'en',
  de: 'de',
  cs: 'cz',
  es: 'es',
  fr: 'fr',
  hu: 'hu',
  'zh-cn': 'zh',
  hi: 'in',
  it: 'it',
  ja: 'ja',
  ko: 'kr',
  ms: 'my',
  nl: 'nl',
  'tl-ph': 'ph',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  th: 'th',
  'nl-be': 'nlbe',
};

export const language_mapping = {
  en: flagUK,
  sk: flagSK,
  de: flagDE,
  cz: flagCZ,
  es: flagES,
  fr: flagFR,
  hu: flagHU,
  zh: flagZH,
  in: flagIN,
  it: flagIT,
  ja: flagJA,
  kr: flagKR,
  my: flagMY,
  nlbe: flagNLBE,
  nl: flagNL,
  ph: flagPH,
  pl: flagPL,
  pt: flagPT,
  ro: flagRO,
  ru: flagRU,
  th: flagTH,
};

export const avatar_mapping = {
  av1: avatar1,
  av2: avatar2,
  av3: avatar3,
  av4: avatar4,
  av5: avatar5,
  av6: avatar6,
  av7: avatar7,
  av8: avatar8,
  av9: avatar9,
  av10: avatar10,
  av11: avatar11,
  av12: avatar12,
  av13: avatar13,
  av14: avatar14,
  av15: avatar15,
  av16: avatar16,
  av17: avatar17,
  av18: avatar18,
  av19: avatar19,
  av20: avatar20,
};

const passwordRegex =
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])).{10,}$/;
const loginRegex1 = /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~"(),:;<>@[\\\]]+$/; // Any of these characters will do
const loginRegex2 = /[a-zA-Z]+/; // But an alphabet character is required

export const types = {
  document: 'app_settings_type_document',
  questionnaire: 'app_settings_type_questionnaire',
  feedback: 'app_settings_type_feedback',
  visit_purpose: 'app_settings_type_visit_purpose',
  meeting_room: 'app_settings_type_meeting_room',
  visitor_type: 'app_settings_type_visitor_type',
  department: 'app_settings_type_department',
  inspection: 'app_settings_type_inspection',
  signed_document: 'app_settings_type_signed_document',
};

export const delimiter = '#;#';

export const type_constants = {
  DOCUMENT: 'document',
  QUESTIONNAIRE: 'questionnaire',
  FEEDBACK: 'feedback',
  SIGNED: 'signed',
  VISIT_PURPOSE: 'visit_purpose',
  MEETING_ROOM: 'meeting_room',
  PERSONAL_DOCUMENT: 'personal_document',
  DEPARTMENT: 'department',
  INSPECTION: 'inspection',
  SIGNED_DOCUMENT: 'signed_document',
};

export const QUESTIONNAIRE_TYPE_FEEDBACK = 'FEEDBACK';

export const DOCUMENT_PROFILE_PHOTO = 'profile_photo_internal';

export const DOCUMENT_TYPE_INTERNAL_AND_IMAGES = [
  'profile_photo_internal',
  'signed_document_internal',
  'image',
  'Image',
  'images',
  'Images',
  'video',
  'Video',
  'logo',
  'explore',
];

export const user_document_status_constants = {
  UNAVAILABLE: 'unavailable',
  PENDING: 'pending',
  READ: 'read',
  SIGNED: 'signed',
  COMPLETED: 'completed',
};

export const visit_event_status_constants = {
  SIGNED_IN: 'signedIn',
  SIGNED_OUT: 'signedOut',
  EMERGENCY: 'emergency',
};

export const visit_visitor_status_constants = {
  INVITED: 'invited',
  CONFIRMED: 'confirmed',
  CLOSED: 'closed',
};

export const template_category_types = {
  email: {
    invite: 'app_settings_template_invite',
    activate: 'app_settings_template_email_activate',
    reset_password: 'app_settings_template_email_reset_password',
    feedback: 'app_settings_template_email_feedback',
    signed_document: 'app_settings_template_email_signed_document',
    meeting_created: 'app_settings_template_email_meeting_created',
    meeting_updated: 'app_settings_template_email_meeting_updated',
    meeting_deleted: 'app_settings_template_email_meeting_deleted',
    visitor_at_gatehouse: 'app_settings_template_visitor_at_gatehouse',
    visitor_left_premises: 'app_settings_template_visitor_left_premises',
    emergency_message: 'app_settings_template_emergency_message',
    user_profile_updated: 'app_settings_template_user_profile_updated',
    user_profile_created: 'app_settings_template_user_profile_created',
    authentication_code: 'app_settings_template_authentication_code',
    access_code: 'app_settings_template_access_code',
    self_registration_created: 'app_settings_self_registration_created',
  },
  push: {
    visitor_at_gatehouse: 'app_settings_template_visitor_at_gatehouse',
    visitor_left_premises: 'app_settings_template_visitor_left_premises',
    emergency_message: 'app_settings_template_emergency_message',
    review_of_required_documents:
      'app_settings_template_review_of_required_documents',
  },
  sms: {
    visitor_at_gatehouse: 'app_settings_template_visitor_at_gatehouse',
    visitor_left_premises: 'app_settings_template_visitor_left_premises',
    emergency_message: 'app_settings_template_emergency_message',
    review_of_required_documents:
      'app_settings_template_review_of_required_documents',
    user_profile_created: 'app_settings_template_user_profile_created',
    user_profile_updated: 'app_settings_template_user_profile_updated',
    pin: 'app_settings_template_pin',
    invite: 'app_settings_template_invite',
    authentication_code: 'app_settings_template_authentication_code',
    access_code: 'app_settings_template_access_code',
    self_registration_created: 'app_settings_self_registration_created',
  },
  template: {
    privacy_policy: 'app_settings_template_privacy_policy',
    terms_of_use: 'app_settings_template_terms_of_use',
    rules_of_behaviour: 'app_settings_template_rules_of_behaviour',
  },
  signed: {
    signed_document: 'app_settings_template_signed_document',
  },
  assistant: {
    my_visits: 'app_settings_assistant_my_visits',
    host_visits: 'app_settings_assistant_host_visits',
    meeting_rooms: 'app_settings_assistant_meeting_rooms',
    settings: 'app_settings_assistant_settings',
    reports: 'app_settings_assistant_reports',
    explore: 'app_settings_assistant_explore',
    user_visits: 'app_settings_assistant_user_visits',
    itinerary: 'app_settings_assistant_itinerary',
    documents: 'app_settings_assistant_documents',
    porter_visits: 'app_settings_assistant_porter_visits',
  },
};

export const template_categories = {
  EMAIL: 'email',
  PUSH: 'push',
  SMS: 'sms',
  TEMPLATE: 'template',
  SIGNED: 'signed',
  ASSISTANT: 'assistant',
};

export const template_types = {
  INVITE: 'invite',
  EMAIL_ACTIVATE: 'activate',
  SELF_REGISTRATION_CREATED: 'self_registration_created',
  EMAIL_FEEDBACK: 'feedback',
  EMAIL_SIGNED_DOCUMENT: 'signed_document',
  EMAIL_MEETING_CREATED: 'meeting_created',
  EMAIL_MEETING_UPDATED: 'meeting_updated',
  EMAIL_MEETING_DELETED: 'meeting_deleted',
  VISITOR_AT_GATEHOUSE: 'visitor_at_gatehouse',
  VISITOR_LEFT_PREMISES: 'visitor_left_premises',
  EMERGENCY_MESSAGE: 'emergency_message',
  USER_PROFILE_UPDATED: 'user_profile_updated',
  USER_PROFILE_CREATED: 'user_profile_created',
  PIN: 'pin',
  ACCESS_CODE: 'access_code',
};

export const document_accepted_mime_types =
  'image/jpeg, image/png, application/pdf, video/mp4';

export const photo_accepted_mime_types = 'image/jpeg, image/png';

export const personal_file_accepted_mime_types =
  'image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const date_formats = {
  full_date_short: 'D.M.YYYY H:mm',
  full_date_long: 'dddd, D.M.YYYY H:mm',
  date: 'DD.MM.YYYY',
  hours: 'H:mm',
};

export const user_roles = {
  ADMIN: 'admin',
  VISITOR: 'visitor',
  EMPLOYEE: 'employee',
  MEETING_ROOM: 'meetingroom',
  PORTER: 'porter',
  TURNSTILE: 'turnstile',
  ASSISTANT: 'assistant',
  GLOBAL_ADMIN: 'globaladmin',
  SIGNING_TABLET: 'signingtablet',
};

export const user_status = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CLOSED: 'closed',
  NOT_AVAILABLE: 'not available',
};

export const notification_type = {
  PUSH: 'push',
  SMS: 'sms',
  EMAIL: 'email',
};

export const scheduled_job_status = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  FAILED: 'failed',
};

export const configuration_group = {
  SETTINGS: 'settings',
  TYPES: 'types',
  LOCATION: 'location',
  LOCATION_SETTINGS: 'location_settings',
  TENANT_SETTINGS: 'tenant_settings',
};

export const configuration_location_type = {
  NAME: 'name',
  ADDRESS: 'address',
  CONTACT: 'contact',
  POSITION: 'position',
  LANGUAGE: 'language',
};

export const configuration_location_settings_type = {
  KEY: 'key',
};

export const configuration_settings_type = {
  FEEDBACK_AFTER: 'feedback_send_after_minutes',
  OPEN_MEETINGROOM_BEFORE_MINUTES: 'open_meeting_room_before_minutes',
  GOOGLE_API_KEY: 'google_api_key',
};

export const configuration_tenant_settings_type = {
  PRINTER_URL: 'printer_url',
};

export const mime_types = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  MP4: 'video/mp4',
};

export const vms_application_routes = {
  ADD_DEVICE_TOKEN: 'vms://addDeviceToken',
  SCREEN_ALWAYS_ON_ENABLED: 'vms://screenAlwaysOn/?enabled=',
  PRINT: 'vms://print/?printerUrl=',
  QR_READING: 'vms://qrReading/?enabled=',
  UNLOCK_DOOR: 'vms://unlock/?doorUrl=%s?securityItem=%s',
  CONNECT_TO_BLE_LOCK: 'vms://connect_to_ble_lock/?lockName=',
  OPEN_URL: 'vms://openUrl/?url=',
  ACTIVITY: 'vms://activity/?enabled=',
  TAKE_PICTURE: 'vms://takePicture',
};

export const validatePassword = function (password) {
  return !passwordRegex.test(password);
};

export const validateLogin = (login) =>
  loginRegex1.test(login) && loginRegex2.test(login);

/**
 * Function that checks if userAgent contains flag indicating that app is running in VMS mobile
 * application web view.
 */
export const isMobileApp = function () {
  const idx = navigator.userAgent.indexOf('/MobileVmsApp');
  return idx > -1;
};

export const isiOSMobileApp = function () {
  const idx = navigator.userAgent.indexOf('/MobileVmsApp/iOS');
  return idx > -1;
};

export const isShellApp = function () {
  const idx = navigator.userAgent.indexOf('ShellVmsApp');
  return idx > -1;
};

export const getFocusOnInput = function (e) {
  if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
    e.target.blur();
    e.target.focus();
  }
};

export const other_categories = {
  MEAL: 'meal',
  ACCOMODATION: 'accomodation',
  TRANSPORT: 'transport',
  REFRESHMENT: 'refreshment',
  WIFI: 'wifi',
  OTHER: 'other',
};
export const days = {
  MONDAY: 'monday',
  TUESDAT: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export const other_types = {
  URL: 'url',
  CSV: 'csv',
  //EMAIL: 'email', <- TODO
  TEXT: 'text',
  WYSIWYG: 'wysiwyg',
  HTML: 'html',
};

export const supported_languages = [
  'en',
  'sk',
  'de',
  'cz',
  'es',
  'fr',
  'hu',
  'zh',
  'in',
  'it',
  'ja',
  'kr',
  'my',
  'nlbe',
  'nl',
  'ph',
  'pl',
  'pt',
  'ro',
  'ru',
  'th',
];

export const browser_language = function () {
  let lang = (i18n.language || 'en').split('-')[0];
  if (!supported_languages.includes(lang)) {
    lang = 'en';
  }
  return lang;
};

export const routes = {
  MY_VISITS: '/myvisits',
  VISITORS: '/visitors',
  MEETINGROOMS: '/meetingrooms',
  SETTINGS: '/settings',
  REPORTS: '/reports',
  VISITOR: '/visitor',
  DOCUMENTS: '/documents',
  ITINERARY: '/itinerary',
  EXPLORE: '/explore',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_TRAININGS: '/settings/trainings',
  SETTINGS_FEEDBACK: '/settings/feedback',
  SETTINGS_EMAIL: '/settings/email',
  SETTINGS_PUSH: '/settings/push',
  SETTINGS_SMS: '/settings/sms',
  SETTINGS_SITE_TEMPLATES: '/settings/sitetemplates',
  SETTINGS_SIGNED: '/settings/signed',
  SETTINGS_COMPANY: '/settings/company',
  SETTINGS_DOCUMENTS: '/settings/documents',
  SETTINGS_TYPES: '/settings/types',
  SETTINGS_CONFIGURATIONS: '/settings/configurations',
  SETTINGS_GLOBAL_CONFIGURATIONS: '/settings/globalconfigurations',
  SETTINGS_SCHEDULEDJOBS: '/settings/scheduledJobs',
  SETTINGS_OTHERS: '/settings/others',
  SETTINGS_PERIPHERALS: '/settings/peripherals',
  SETTINGS_ASSISTANT: '/settings/assistant',
  SETTINGS_EXPLORE: '/settings/explore',
  SETTINGS_INSPECTION: '/settings/inspection',
  SETTINGS_LOGS: '/settings/logs',
  PRINT_VISIT: '/print/visit',
  ITINERARY_DETAIL: '/itinerary/detail',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',
  RULES_OF_BEHAVIOUR: '/rules-of-behaviour',
  PROFILE: '/profile',
};

export const globaladmin_tab_routes = [
  routes.VISITORS,
  routes.MEETINGROOMS,
  routes.SETTINGS,
  routes.REPORTS,
];

export const employee_tab_routes = [
  routes.MY_VISITS,
  routes.VISITORS,
  routes.MEETINGROOMS,
  routes.SETTINGS,
  routes.REPORTS,
];

export const visitor_tab_routes = [
  routes.EXPLORE,
  routes.VISITOR,
  routes.ITINERARY,
  routes.DOCUMENTS,
];

export const porter_tab_routes = [routes.VISITORS];

export const time_configs = {
  RETURN_TO_CURRENT_DATE_MEETINGROOM: 60 * 1000, //60 seconds
  REFRESH_DATA: 1 * 60 * 1000, //1 MINUTE
};

export const itinerary_categories = {
  TRANSPORT: 'transport',
  ACCOMODATION: 'accomodation',
};

export const itinerary_types_transport = {
  FLIGHT: 'flight',
  TAXI: 'taxi',
  TRAIN: 'train',
  BUS: 'bus',
};

export const itinerary_types_accomodation = {
  HOTEL: 'hotel',
};

//This is done twice for bidirectional access
export const assistant_tabs = {
  my_visits: 0,
  host_visits: 1,
  meeting_rooms: 2,
  settings: 3,
  reports: 4,
  explore: 0,
  user_visits: 1,
  itinerary: 2,
  documents: 3,
  porter_visits: 0,
};

export const assistant_tabs_employee = {
  0: 'my_visits',
  1: 'host_visits',
  2: 'meeting_rooms',
  3: 'settings',
  4: 'reports',
};

export const assistant_tabs_visitor = {
  0: 'explore',
  1: 'user_visits',
  2: 'itinerary',
  3: 'documents',
};

export const assistant_tabs_porter = {
  0: 'porter_visits',
};

export const explore_types = {
  TITLE_IMAGE: 'title_image',
  NEWS: 'news',
  WEATHER: 'weather',
  PLACE: 'place',
  THING: 'thing',
};

export const itinerary_type_icon = function (type) {
  switch (type) {
    case itinerary_types_transport.FLIGHT:
      return Flight;
    case itinerary_types_transport.TAXI:
      return Taxi;
    case itinerary_types_transport.TRAIN:
      return Train;
    case itinerary_types_transport.BUS:
      return Bus;
    case itinerary_types_accomodation.HOTEL:
      return Hotel;
    default:
      return Folder;
  }
};

export const process_event_types = {
  SENT_EMAIL: 'sent_email',
  INVITATION_CREATED: 'invitation_created',
  INVITATION_UPDATED: 'invitation_updated',
  INVITATION_DELETED: 'invitation_deleted',
  ACCOUNT_ACTIVATION: 'account_activation',
  FORGOT_PASSWORD: 'forgot_password',
  PASSWORD_RESET: 'password_reset',
  SENT_DOCUMENT: 'sent_document',
  SENT_SIGNED_DOCUMENT: 'sent_signed_document',
  SENT_QUESTIONNAIRE: 'sent_questionnaire',
  MEETING_CREATED: 'meeting_created',
  MEETING_UPDATED: 'meeting_updated',
  MEETING_DELETED: 'meeting_deleted',
  MEETING_CREATED_ATTENDEE: 'meeting_created_attendee',
  MEETING_UPDATED_ATTENDEE: 'meeting_updated_attendee',
  MEETING_DELETED_ATTENDEE: 'meeting_deleted_attendee',
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  DOCUMENT_READ: 'document_read',
  DOCUMENT_SIGNED: 'document_signed',
  QUESTIONNAIRE_FILLED: 'questionnaire_filled',
  USER_STATUS_CHANGED: 'user_status_changed',
  FEEDBACK_MADE_AVAILABLE: 'feedback_made_available',
  SECURITY_ITEM_GENERATED: 'security_item_generated',
  INSPECTION_ACTIVITY_CHECKED: 'inspection_activity_checked',
  VISIT_ACCOMPANIED: 'visit_accompanied',
  EMERGENCY: 'emergency',
};

export const sanitizePhoneNumber = function (phoneNumber) {
  let phone = phoneNumber;
  if (phone) {
    phone = phone.replace(/[^0-9\\+]+/g, '');
    if (phone.length > 17) {
      phone = phone.substring(0, 17);
    }
  }
  return phone;
};

//Returns true if invalid
export const validatePhoneNumber = function (phoneNumber) {
  // eslint-disable-next-line
  const regex = /^(?:\+|00)[0-9]\d{1,14}$/g;
  return phoneNumber && !regex.test(phoneNumber);
};

export const validatePin = function (phoneNumber) {
  let phone = phoneNumber;
  if (phone) {
    phone = phone.replace(/[^0-9]+/g, '');
  }
  return phone;
};

export const removeByKey = function (array, params) {
  array.some(function (item, index) {
    if (array[index][params.key] === params.value) {
      // found it!
      array.splice(index, 1);
      return true; // stops the loop
    }
    return false;
  });
  return array;
};

export const tab_indexes = {
  MY_VISITS: 0,
  VISIT: 1,
  ITINERARY: 2,
  DOCUMENTS: 3,
};

export const my_visits_history = {
  UPCOMING: 'upcoming',
  PREVIOUS: 'previous',
  ALL: 'all',
};

export const invite_tab_indexes = {
  VISITOR: 0,
  DOCS: 1,
  EVENTS: 2,
};

//Display info about depending on the mode
export const visit_card_modes = {
  VISITOR: 'host',
  HOST: 'user',
};

export const findCheckedInspectionForDate = (inspections, momentDate) => {
  return inspections?.find((insp) =>
    moment(insp.checked_date).isSame(momentDate, 'date')
  );
};

export const pin_settings = {
  minLength: 4,
  maxLength: 6,
};

export const filter_types = {
  LANGUAGE: 'languageFilter',
  TYPE: 'typeFilter',
};
