import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, TextField, Button, Typography } from '@material-ui/core';
import { VmsInfoBar } from 'components';
import { Link, Redirect, withRouter } from 'react-router-dom';
import i18n from 'assets/i18n';
import UserService from 'services/UserService';
import * as qs from 'query-string';
import commonStyles from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';
import Auth from 'modules/Auth';
import { validatePassword } from 'AppSettings';
import PasswordHelp from 'components/common/popups/PasswordHelp';
import { openSnackbar } from 'components/common/bars/SnackBar';
import classNames from 'classnames';

const styles = (theme) => ({
  ...commonStyles(theme),
  loginModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  modalStyle: {
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(6),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'min(500px, 100%)',
  },
  sectionWrapper: {
    padding: `${theme.spacing(2)}px 0`,
  },
});

const STAGE_RESULT = {
  NONE: '',
  OK: 'ok',
  ERR: 'err',
};

const STAGE = {
  REQUEST: 'request',
  VERIFY: 'verify',
  RESET: 'reset',
};

class ResetPasswordPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: '',
        passwordVerify: '',
        email: '',
        stage: STAGE.REQUEST,
        stageResult: STAGE_RESULT.NONE,
        actionDisabled: false,
        errorMessage: '',
      },
    };
  }

  handleGoToDashboard = () => {
    const { history } = this.props;
    //In case the reset page was opened from link and there are no entries in the history, redirect to home page
    if (history.length > 1) {
      history.go(-1);
    } else {
      history.replace('/');
    }
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeData = (event) => {
    const field = event.target.name;
    const data = this.state.data;
    data[field] = event.target.value;

    this.setState({
      data,
    });
  };

  componentWillMount() {
    this.action = this.props.match.params.action;
    this.resetToken = qs.parse(this.props.location.search).jwt;
  }

  componentDidMount() {
    if (this.action === STAGE.VERIFY) {
      this.verifyResetPassword();
    }
  }

  failure = (error) => {
    console.log(error);
    if (error.networkError) {
      openSnackbar(error.message);
    } else if (error.code) {
      openSnackbar(error.message);

      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          actionDisabled: false,
        },
      });
    } else {
      this.setState(function (prevState) {
        prevState.data.stageResult = STAGE_RESULT.ERR;
        prevState.data.actionDisabled = false;
        prevState.data.errorMessage = error.message;
        return prevState;
      });
    }
  };

  success = () => {
    this.setState(function (prevState) {
      prevState.data.stageResult = STAGE_RESULT.OK;
      prevState.data.actionDisabled = false;
      return prevState;
    });
  };

  verifyResetPassword = () => {
    this.setState(function (prevState) {
      prevState.data.actionDisabled = true;
      prevState.data.message = i18n.t(
        'account_reset_password_verify_inprogress_message'
      );
      return prevState;
    });
    UserService.verifyResetPassword(this.resetToken)
      .then(this.success)
      .catch(this.failure);
  };

  requestResetPassword = () => {
    this.setState(function (prevState) {
      prevState.data.actionDisabled = true;
      return prevState;
    });
    UserService.requestResetPassword(this.state.data.email)
      .then(this.success)
      .catch(this.failure);
  };

  handleResetPassword = () => {
    let self = this;
    this.setState(function (prevState) {
      prevState.data.actionDisabled = true;
      return prevState;
    });
    UserService.resetPassword(this.resetToken, this.state.data.password)
      .then(function (response) {
        Auth.authenticateUser(response.token, response.refresh_token);
        self.setState(function (prevState) {
          prevState.data.actionDisabled = true;
          self.action = STAGE.RESET;
          return prevState;
        });
      })
      .catch(this.failure);
  };

  requestForm = () => {
    const { classes } = this.props;
    const { email, stageResult, actionDisabled } = this.state.data;
    let message = i18n.t('account_reset_password_request_message');
    if (stageResult === STAGE_RESULT.OK)
      message = i18n.t('account_reset_password_request_success_message');
    if (stageResult === STAGE_RESULT.ERR)
      message = i18n.t('account_reset_password_request_failure_message');
    return (
      <Aux>
        <div className={classes.sectionWrapper}>
          <Typography variant="h6">
            {i18n.t('account_reset_password_request_title')}
          </Typography>
          <div>
            <Typography variant="body1">{message}</Typography>
          </div>
          {stageResult !== STAGE_RESULT.OK &&
          stageResult !== STAGE_RESULT.ERR ? (
            <div className={classes.container}>
              <div>
                <TextField
                  required
                  name="email"
                  label={i18n.t('account_reset_password_placeholder_email')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  onChange={this.changeData}
                  value={email}
                />
              </div>
              <div>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.requestResetPassword}
                  disabled={actionDisabled}
                >
                  {i18n.t('account_reset_password_request_button')}
                </Button>
              </div>
            </div>
          ) : (
            <Button
              className={classes.button}
              component={Link}
              to="/login"
              variant="contained"
              color="primary"
            >
              {i18n.t('account_activation_proceed_to_login')}
            </Button>
          )}
        </div>
      </Aux>
    );
  };

  verifyForm = () => {
    const {
      classes,
      history: { location },
    } = this.props;
    const {
      password,
      passwordVerify,
      stageResult,
      actionDisabled,
      errorMessage,
    } = this.state.data;
    const passwordInvalid = validatePassword(password);
    const requestDisabled =
      passwordInvalid || password !== passwordVerify || actionDisabled;
    let message = i18n.t('account_reset_password_verify_inprogress_message');
    if (stageResult === STAGE_RESULT.OK)
      message = i18n.t('account_reset_password_verify_success_message');
    if (stageResult === STAGE_RESULT.ERR) message = errorMessage;
    //if(stageResult === STAGE_RESULT.ERR) message = i18n.t('account_reset_password_verify_failure_message');

    const referrer = (location.state || {}).referrer;
    const title =
      referrer && referrer === 'reset'
        ? i18n.t('account_reset_password_expired_title')
        : i18n.t('account_reset_password_request_title');
    return (
      <Aux>
        <div className={classes.sectionWrapper}>
          <Typography variant="h6">{title}</Typography>
          <div>
            <Typography variant="body1">{message}</Typography>
          </div>
          {stageResult === STAGE_RESULT.OK ? (
            <div className={classes.container}>
              <div>
                <TextField
                  required
                  name="password"
                  label={i18n.t('account_activation_password_placeholder')}
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  fullWidth
                  onChange={this.changeData}
                  value={password}
                />
                <TextField
                  required
                  name="passwordVerify"
                  label={i18n.t(
                    'account_activation_password_verify_placeholder'
                  )}
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  fullWidth
                  onChange={this.changeData}
                  value={passwordVerify}
                />
                {password.length > 0 && passwordInvalid && <PasswordHelp />}
              </div>
              <div>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.handleResetPassword}
                  disabled={requestDisabled}
                >
                  {i18n.t('account_reset_password_request_button')}
                </Button>
              </div>
            </div>
          ) : (
            <Button
              className={classes.button}
              component={Link}
              to="/login"
              variant="contained"
              color="primary"
            >
              {i18n.t('account_activation_proceed_to_login')}
            </Button>
          )}
        </div>
      </Aux>
    );
  };

  render() {
    const RequestForm = this.requestForm;
    const VerifyForm = this.verifyForm;

    //Used in redirect - if the users agreeTerms have been reset, on password change it would leat to empty login form
    //which would result in not authenticating properly
    //The result of this action is in componentDidMount method in LoginPage component
    const { classes, history } = this.props;
    const {
      data: { password },
    } = this.state;
    const locationState = ((history || {}).location || {}).state || {};
    const { referrer, email } = locationState;

    return (
      <Aux>
        <VmsInfoBar backMode={true} onBack={this.handleGoToDashboard} />
        <div className={classNames(classes.mainContainer, classes.modalStyle)}>
          {this.action === STAGE.REQUEST && <RequestForm />}
          {this.action === STAGE.VERIFY && <VerifyForm />}
          {this.action === STAGE.RESET && (
            <Redirect
              to={{
                pathname: Auth.getHomePage(),
                state: { referrer, email, password },
              }}
              stat
            />
          )}
        </div>
      </Aux>
    );
  }
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ResetPasswordPage));
