import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Files from '@material-ui/icons/Description';
import CameraDialog from 'containers/CameraDialog';
import { VmsDialogRowDragDrop } from 'components';
import { FileDialog } from 'components/';
import {
  type_constants,
  personal_file_accepted_mime_types,
  vms_application_routes,
  isMobileApp,
  isiOSMobileApp,
} from 'AppSettings';
import DocumentService from 'services/DocumentService';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  avatarEditor: {
    borderRadius: 60,
  },
});

export const createDragDropState = () => {
  return {
    files: [],
    base64: null,
    fileStatus: '',
    cameraDialogOpen: false,
    fileDialogOpen: false,
    openPhotoFileDialog: false,
    fileUrl: '',
    fileName: 'N/A',
    file: null,
    uploading: false,
  };
};

export const uploadFiles = (user_id, file, updateUploadingState) => {
  const documentData = {
    name: file.data.name,
    documentType: type_constants.PERSONAL_DOCUMENT,
    publicDoc: false,
  };
  const { name, documentType, publicDoc } = documentData;

  return new Promise(function (resolve, reject) {
    if (!!file) {
      updateUploadingState(true);
      console.log('file', file);
      DocumentService.uploadDocument(
        name,
        documentType,
        '',
        publicDoc,
        file.data.data
      )
        //UserDocument
        .then(function (document) {
          resolve(document);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    } else {
      reject(new Error('No files!'));
    }
  });
};

class VmsDialogDragDropFile extends Component {
  constructor(props) {
    super();
    this.editor = null;
  }

  onChange = () => {
    this.props.onChange(this.props.dragDropFileState);
  };

  handleBrowse = () => {
    this.props.dragDropFileState.openPhotoFileDialog = true; // Open system file input browse dialog
    this.props.dragDropFileState.fileDialogOpen = false; // Close this dialog
    this.onChange();
  };

  handleFileDialog = (open) => (event) => {
    this.props.dragDropFileState.openPhotoFileDialog = false;
    this.props.dragDropFileState.fileDialogOpen = open;
    this.onChange();
  };

  handleCameraDialog = (open) => (event) => {
    if (isMobileApp() && !isiOSMobileApp() && open) {
      //Android only
      window.location = vms_application_routes.TAKE_PICTURE;

      /**
       * Global function used by Android app
       */
      window.photoCaptured = (base64) => {
        console.log('Photo captured');
        this.handleCapture(base64);
      };
      this.props.dragDropFileState.fileDialogOpen = false;
      this.onChange();
      return;
    }
    this.props.dragDropFileState.fileDialogOpen = false;
    console.log('open');
    console.log(open);
    this.props.dragDropFileState.cameraDialogOpen = open;
    this.onChange();
  };

  handleCapture = (base64Image) => {
    this.props.dragDropFileState.files = [];
    this.props.dragDropFileState.base64 = base64Image;
    this.props.dragDropFileState.fileStatus = '';
    this.props.dragDropFileState.cameraDialogOpen = false;
    this.onChange();
  };

  // ######### DRAG DROP #######
  filesOnDrop = (files) => {
    this.props.dragDropFileState.openPhotoFileDialog = false;
    this.props.dragDropFileState.fileDialogOpen = false;
    this.props.dragDropFileState.files.push(...files);
    this.onChange();
  };

  cancelBrowse = () => {
    this.props.dragDropFileState.openPhotoFileDialog = false;
    this.onChange();
  };

  filesOnDropAccepted = () => {
    this.props.dragDropFileState.fileStatus = 'accepted';
    this.onChange();
  };

  filesOnDropRejected = () => {
    this.props.dragDropFileState.fileStatus = 'rejected';
    this.onChange();
  };

  setEditorRef = (editorRef) => {
    this.editor = editorRef;
    if (this.editor) {
      this.props.dragDropFileState.fileEditor = this.editor;
      this.onChange();
    }
  };

  render() {
    const { classes, dragDropFileState, disabled, buttonDataCy, itemDataCy } =
      this.props;
    return (
      <Aux>
        <VmsDialogRowDragDrop
          rowClass={classes.dialogRow}
          files={dragDropFileState.files}
          fileStatus={dragDropFileState.fileStatus}
          document_accepted_mime_types={personal_file_accepted_mime_types}
          uploading={dragDropFileState.uploading}
          onDropAccepted={this.filesOnDropAccepted}
          onDropRejected={this.filesOnDropRejected}
          onBrowseCancel={this.cancelBrowse}
          onDrop={this.filesOnDrop}
          icon={<Files />}
          photoMode={false}
          fileMode={true}
          isFileMode={true}
          newFile={this.handleFileDialog(true)}
          openPhotoFileDialog={dragDropFileState.openPhotoFileDialog}
          disabled={disabled}
          buttonDataCy={buttonDataCy}
        />
        <FileDialog
          open={dragDropFileState.fileDialogOpen}
          onClose={this.handleFileDialog(false)}
          takePhoto={this.handleCameraDialog(true)}
          browseFiles={this.handleBrowse}
          itemDataCy={itemDataCy}
        />
        {dragDropFileState.cameraDialogOpen && (
          <CameraDialog
            onClose={this.handleCameraDialog(false)}
            capture={this.handleCapture}
          />
        )}
      </Aux>
    );
  }
}

VmsDialogDragDropFile.defaultProps = {
  dragDropFileState: {
    files: [],
    base64: null,
    fileStatus: '',
    cameraDialogOpen: false,
    fileDialogOpen: false,
    openPhotoFileDialog: false,
    fileUrl: '',
    fileName: '',
    file: null,
    uploading: false,
  },
};

VmsDialogDragDropFile.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dragDropFileState: PropTypes.shape({
    fileDialogOpen: PropTypes.bool,
    openPhotoFileDialog: PropTypes.bool,
    cameraDialogOpen: PropTypes.bool,
    files: PropTypes.array,
    fileStatus: PropTypes.string,
    fileUrl: PropTypes.string,
    fileName: PropTypes.string,
    file: PropTypes.object,
    uploading: PropTypes.bool,
    buttonDataCy: PropTypes.string,
    itemDataCy: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(VmsDialogDragDropFile);
