import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class SmsServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'sms';
  }
  /**
   * Send email to specified addresses.
   *
   * @param {string} to String containing MSISDN separated by comma
   * @param {string} text text of sms
   */
  send(to, text) {
    var emailData = {
      to: to,
      text: text.replace(/<(?:.|\n)*?>/gm, ''),
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, emailData, config);
  }

  /**
   * Send SMS template to specified user.
   *
   * @param {string} to Array of person phones which should get SMS separated by comma
   * @param {object} template Notification template
   */
  sendTemplate(to, template) {
    return this.send(to, template.body);
  }
}

const SmsService = new SmsServiceImpl();

export default SmsService;
