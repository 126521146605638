import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { elevation } from '../../../assets/UISettings';
import { withStyles, Paper, Typography, Grid } from '@material-ui/core';
import reportsStyle from 'assets/jss/reportsStyle';
import { CardHeader, CardBody } from 'components/';
import ChartistGraph from 'react-chartist';
import i18n from '../../../assets/i18n';
import 'assets/css/reports.css';
import DownloadButton from './DownloadButton';
import classNames from 'classnames';

const styles = (theme) => ({
  ...reportsStyle(theme),
  textWrapper: {
    width: '100%',
  },
});

const graphCard = (props) => {
  const {
    classes,
    chartData,
    type,
    heading,
    subheading,
    onDownload,
    buttonDataCy,
  } = props;

  if (!chartData) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <GraphWrapper chartData={chartData} type={type} />
        </CardHeader>
        <CardBody plain className={classes.cardFooter}>
          <div className={classes.textWrapper}>
            <Typography
              className={classNames(classes.heading, classes.clampGraph)}
            >
              {heading}
            </Typography>
            <Typography
              className={classNames(classes.subHeading, classes.clampGraph)}
            >
              {subheading}
            </Typography>
          </div>
          {onDownload && (
            <DownloadButton
              title={i18n.t('export')}
              onDownload={onDownload}
              buttonDataCy={buttonDataCy}
            />
          )}
        </CardBody>
      </Paper>
    </Grid>
  );
};

class GraphWrapper extends PureComponent {
  render() {
    const { chartData, type } = this.props;

    return (
      <ChartistGraph
        className={'ct-chart'}
        data={chartData.data}
        type={type}
        options={chartData.options}
        responsiveOptions={chartData.responsiveOptions}
        listener={chartData.animation}
      />
    );
  }
}

graphCard.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  footer: PropTypes.string.isRequired,
  chartData: PropTypes.any,
  type: PropTypes.string.isRequired,
  buttonDataCy: PropTypes.string,
};

export default withStyles(styles)(graphCard);
