import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Auth from 'modules/Auth';
import UploadDialog from 'containers/settings/documents/UploadDialog';
import { EditDocumentDialog } from 'components/index';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import i18n from 'assets/i18n';
import {
  DocumentService,
  ExportService,
  ConfigurationService,
} from 'services/index';
import { openSnackbar } from 'components/common/bars/SnackBar';
import classNames from 'classnames';
import {
  type_constants,
  routes,
  DOCUMENT_TYPE_INTERNAL_AND_IMAGES,
} from 'AppSettings';
import tableStyles from 'assets/jss/tableStyles';
import { emptySettingSearchState } from '../SettingsPage';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { handleOpenConfirmDialog } from '../../../components/common/dialogs/ConfirmDialog';
import locales from 'assets/momentlocales';

const styles = (theme) => ({
  ...tableStyles(theme),
  link: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'inline-block',
  },
});

class DocumentsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Auth.getUser(),
      value: 0,
      documents: [],
      documentTypes: null,
      modalOpen: false,
      editModalOpen: false,
      tableHeaderState: createTableHeaderState([]),
      menuAnchorEl: null,
      currentItem: {},
      editData: {
        name: '',
        documentType: '',
        isPublic: false,
        required_for_entrance: false,
        expires_in: '',
        language: 'en',
        format: '',
      },
      highlightedId: '',
    };

    this.tableHeader = null;
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadData();
    this.loadDocumentTypes();
    this.setSearchSettings();
    EditDocumentDialog.preload();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_DOCUMENTS) this.handleNewClick();
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService = DocumentService.suggestDocuments;
      settingSearchState.onSuggestionsClearRequested =
        this.handleDocumentSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleDocumentSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleDocumentSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.name;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleDocumentSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleDocumentSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestDocument(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.name;
      onChangeSearch(settingSearchState);
    }
  };

  handleDocumentSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestDocument = (_id) => {
    const { documents, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = documents.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  handleEditModalClose = () => {
    this.setState({ editModalOpen: false });
    this.loadData();
  };

  handleNewModalClose = () => {
    this.setState({ modalOpen: false });
    this.loadData();
  };

  handleNewClick = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleEditModalOpen = (item) => (event) => {
    this.setState({
      editModalOpen: true,
      menuAnchorEl: null,
      currentItem: item,
      editData: {
        name: item.name,
        documentType: item.type,
        isPublic: item.public,
        required_for_entrance: item.required_for_entrance,
        expires_in: item.expires_in,
        language: item.language,
        format: item.format,
      },
    });
  };

  loadData = async () => {
    const self = this;
    DocumentService.getDocuments()
      .then(function (documents) {
        self.setState(function (prevState) {
          prevState.currentItem = {};
          prevState.documents = documents;
          prevState.tableHeaderState.data = documents;
          return prevState;
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadDocumentTypes = async () => {
    const self = this;
    ConfigurationService.getTypesForConfiguration(type_constants.DOCUMENT)
      .then(function (docTypes) {
        const types =
          ConfigurationService.convertTypesArrayToKeyValueObject(docTypes);
        self.setState({
          documentTypes: types,
        });
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_document_dialog_remove_selected_title'),
      i18n.t('settings_document_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => (event) => {
    const { documents, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = documents;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = documents.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      DocumentService.deleteDocument(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            self.setState(function (prevState) {
              prevState.documents = array;
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadData();
        });
    });
  };

  handleDeleteDocumentOpenDialog = (documentId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_document_dialog_remove_title'),
      i18n.t('settings_document_dialog_remove_description'),
      this.handleDeleteDocument(documentId)
    );
  };

  handleDeleteDocument = (documentId) => (event) => {
    var self = this;
    DocumentService.deleteDocument(documentId)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  handleEditSave = () => {
    var self = this;
    const { currentItem, editData } = this.state;
    const {
      name,
      documentType,
      isPublic,
      required_for_entrance,
      expires_in,
      format,
    } = editData;
    DocumentService.updateDocument(
      currentItem._id,
      name,
      documentType,
      '',
      isPublic,
      currentItem.language,
      required_for_entrance,
      expires_in,
      format
    )
      .then(function (response) {
        openSnackbar(i18n.t('saved'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  handleEditChangeData = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    this.setState(function (prevState) {
      prevState.editData[field] = fieldValue;
      return prevState;
    });
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleLanguageChange = (lang) => {
    const { currentItem } = this.state;
    this.setState({
      currentItem: {
        ...currentItem,
        language: lang,
      },
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  render() {
    const { classes } = this.props;
    const {
      documents,
      menuAnchorEl,
      currentItem,
      editData,
      tableHeaderState,
      highlightedId,
    } = this.state;
    const { rowsPerPage, page, selected } = tableHeaderState;
    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                exportData={ExportService.documents(selected)}
                exportDataFilename={'documents.csv'}
                prevPageButtonDataCy="settings-documents-button-previous-page"
                nextPageButtonDataCy="settings-documents-button-next-page"
              />
              <TableBody>
                {documents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((document) => {
                    const isSelected = this.isSelected(document);
                    const notInternalOrImage = !(
                      DOCUMENT_TYPE_INTERNAL_AND_IMAGES.includes(
                        document.type
                      ) || document.type === ''
                    );
                    return (
                      <TableRow
                        key={document._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === document._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onClick={this.handleClickRow(document)}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                          onClick={this.handleEditModalOpen(document)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {document.name}
                          </Typography>
                        </TableCell>

                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                        >
                          {notInternalOrImage ? (
                            <Typography
                              variant="body2"
                              className={classNames(
                                classes.header,
                                classes.tableInfoFont
                              )}
                              s
                              noWrap
                            >
                              {document.language &&
                                i18n.t('menu_language_' + document.language) +
                                  ` (${locales[document.language]})`}
                            </Typography>
                          ) : null}
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.textAlignRight
                          )}
                          padding="none"
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {document.type}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox,
                            classes.textAlignRight
                          )}
                          padding="none"
                        >
                          {document.public ? (
                            <Visibility className={classes.tableIcon} />
                          ) : (
                            <VisibilityOff className={classes.tableIcon} />
                          )}
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none"
                        >
                          <IconButton
                            className={classes.tableMenuButton}
                            aria-owns={menuAnchorEl ? 'table-menus' : null}
                            aria-haspopup="true"
                            onClick={(event) =>
                              this.handleMenuOpen(event, document)
                            }
                          >
                            <MoreHoriz className={classes.tableIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem>
            <a
              className={classes.link}
              href={DocumentService.api + currentItem.downloadUrl}
              download
            >
              {i18n.t('menu_download')}
            </a>
          </MenuItem>
          <MenuItem onClick={this.handleEditModalOpen(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          <MenuItem
            onClick={this.handleDeleteDocumentOpenDialog(currentItem._id)}
          >
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <UploadDialog
          open={this.state.modalOpen}
          onClose={this.handleNewModalClose}
          documentTypes={this.state.documentTypes}
        />
        {currentItem._id ? (
          <EditDocumentDialog
            open={this.state.editModalOpen}
            onClose={this.handleEditModalClose}
            documentType={editData.documentType}
            documentTypes={this.state.documentTypes}
            mimeType={currentItem.mimetype}
            item={currentItem}
            downloadUrl={DocumentService.api + currentItem.downloadUrl}
            name={editData.name}
            changeData={this.handleEditChangeData}
            onSave={this.handleEditSave}
            isPublicDocument={editData.isPublic}
            required_for_entrance={editData.required_for_entrance}
            expires_in={editData.expires_in}
            editLanguages={this.handleLanguageChange}
            format={editData.format}
          />
        ) : null}
      </div>
    );
  }
}

DocumentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(DocumentsPage);
