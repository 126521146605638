import PropTypes from 'prop-types';
import { withStyles, Grid, Paper, Typography, Button } from '@material-ui/core';
import { isiOSMobileApp } from 'AppSettings';
import i18n from 'assets/i18n';
import commonStyles from 'assets/jss/commonStyles';
import { elevation } from 'assets/UISettings';
import { VmsLocalize } from 'components';
import { Calendar } from '@material-ui/pickers';
import moment from 'moment';
import qrCodeImage from 'assets/images/qr_code.png';

const styles = (theme) => ({
  ...commonStyles(theme),
  mainContainer: {
    width: 354,
    height: '100%',
    alignContent: 'flex-start',
  },
  paperHeader: {
    height: 444,
  },
  paper: {
    overflow: 'auto',
    padding: theme.spacing(2),
    height: 'calc(100vh - ' + (isiOSMobileApp() ? 700 : 604) + 'px)',
  },
  calendarHeader: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primaryIcon,
  },
  calendarTitle: {
    color: theme.palette.white,
    fontSize: 24,
  },
  calendarTime: {
    paddingTop: theme.spacing(2),
    color: theme.palette.white,
    fontSize: 40,
    fontWeight: 450,
  },
  meetingLabelOnGoing: {
    color: 'black',
  },
  meetingLabel: {
    color: theme.palette.primary5,
  },
  meetingLabelItem: {
    width: '70%',
  },
  calendar: {
    height: 460,
    marginTop: theme.spacing(2),
    width: '100%',
  },
  meetings: {
    flexGrow: 1,
    maxHeight: 'calc(100% - ' + (isiOSMobileApp() ? 96 : 480) + 'px)',
    width: '100%',
  },
  scanButton: {
    width: '100%',
    height: 96,
  },
  QRButton: {
    color: 'white',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 64,
  },
  QRButtonImage: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
});

const meetingRoomCalendar = (props) => {
  const { classes, user, changeDate, selectedDate, meetings } = props;
  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item className={classes.calendar}>
        <Paper className={classes.paperHeader} elevation={elevation.light}>
          <div className={classes.calendarHeader}>
            <Typography noWrap className={classes.calendarTitle}>
              {(user || {}).name || ''}
            </Typography>
            <Typography className={classes.calendarTime}>
              {moment(selectedDate).format('MMM DD')} {'\u00A0 \u00A0'}{' '}
              {moment(selectedDate).format('HH : mm')}
            </Typography>
          </div>
          <VmsLocalize>
            <div>
              <div className="picker">
                <Calendar
                  date={selectedDate}
                  onChange={changeDate}
                  leftArrowButtonProps={{
                    'data-cy': 'meeting-room-button-calendar-left',
                  }}
                  rightArrowButtonProps={{
                    'data-cy': 'meeting-room-button-calendar-right',
                  }}
                />
              </div>
            </div>
          </VmsLocalize>
        </Paper>
      </Grid>
      <Grid item className={classes.meetings}>
        <Paper className={classes.paper} elevation={elevation.light}>
          {meetings.map((m) => {
            return (
              <Grid
                key={m._id}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                wrap="nowrap"
              >
                <Grid item className={classes.meetingLabelItem}>
                  {moment(m.startTime) <= moment(selectedDate) &&
                  moment(m.startTime).add(m.duration, 'minutes') >
                    moment(selectedDate) ? (
                    <Typography className={classes.meetingLabelOnGoing} noWrap>
                      {m.name}
                    </Typography>
                  ) : (
                    <Typography className={classes.meetingLabel} noWrap>
                      {m.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  {moment(m.startTime) <= moment(selectedDate) &&
                  moment(m.startTime).add(m.duration, 'minutes') >
                    moment(selectedDate) ? (
                    <Typography className={classes.meetingLabelOnGoing}>
                      {moment(m.startTime).format(
                        moment(m.startTime).isSame(moment(selectedDate), 'day')
                          ? 'HH:mm'
                          : 'DD.MM.YYYY HH:mm'
                      )}{' '}
                      -{' '}
                      {moment(m.startTime)
                        .add(m.duration, 'minutes')
                        .format(
                          moment(m.startTime)
                            .add(m.duration, 'minutes')
                            .isSame(moment(selectedDate), 'day')
                            ? 'HH:mm'
                            : 'DD.MM.YYYY HH:mm'
                        )}
                    </Typography>
                  ) : (
                    <Typography className={classes.meetingLabel}>
                      {moment(m.startTime).format(
                        moment(m.startTime).isSame(moment(selectedDate), 'day')
                          ? 'HH:mm'
                          : 'DD.MM.YYYY HH:mm'
                      )}{' '}
                      -{' '}
                      {moment(m.startTime)
                        .add(m.duration, 'minutes')
                        .format(
                          moment(m.startTime)
                            .add(m.duration, 'minutes')
                            .isSame(moment(selectedDate), 'day')
                            ? 'HH:mm'
                            : 'DD.MM.YYYY HH:mm'
                        )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Paper>
      </Grid>
      {isiOSMobileApp() === true && (
        <Grid item className={classes.scanButton}>
          <Button
            color="primary"
            variant="contained"
            className={classes.QRButton}
            onClick={props.scanClicked}
          >
            <img className={classes.QRButtonImage} src={qrCodeImage} alt="QR" />
            {i18n.t('scan_qr_code')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

meetingRoomCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(meetingRoomCalendar);
