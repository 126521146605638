import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  MobileStepper,
  Grid,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  List,
  Button,
  Typography,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import i18n from 'assets/i18n';
import QuestionService from 'services/QuestionService';
import { openSnackbar } from '../../common/bars/SnackBar';
import QuestionnaireDialog from './QuestionnaireDialog';
import UserService from 'services/UserService';
import Parser from 'html-react-parser';
import { user_document_status_constants, user_roles } from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import commonStyles from 'assets/jss/commonStyles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Auth from 'modules/Auth';

const styles = (theme) => ({
  ...commonStyles(theme),
  answers: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
    overflow: 'auto',
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  iconSmall: {
    fontSize: 20,
  },
  htmlParser: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
    overflow: 'auto',
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  stepper: {
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  grid: {
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
});

export const createEmptyQuestionnaireState = () => {
  return {
    activeIndex: 0,
    questionIDs: [],
    question: {
      _id: '',
      questionTitle: '',
      question: '',
      answers: [],
      language: 'en',
    },
    checked: [],
    isLoading: true,
    myAnswers: [], //[{_id: '', answer: true, checked: []}]
    isOpenSubmit: false,
    json: { date_of_filling_in: {}, questions: [] },
    required_for_entrance: false,
    expires_in: '',
    numberOfQuestions: undefined,
    randomOrderOfQuestions: false,
  };
};

export const editQuestionnaireState = (questionnaire) => {
  return {
    activeIndex: questionnaire.activeIndex,
    questionIDs: questionnaire.questionIDs,
    question: questionnaire.question,
    checked: questionnaire.checked,
    isLoading: questionnaire.isLoading,
    myAnswers: questionnaire.myAnswers,
    isOpenSubmit: questionnaire.isOpenSubmit,
    json: questionnaire.json,
    required_for_entrance: questionnaire.required_for_entrance,
    expires_in: questionnaire.expires_in,
    numberOfQuestions: questionnaire.numberOfQuestions,
    randomOrderOfQuestions: questionnaire.randomOrderOfQuestions,
  };
};

class DocumentQuestionnaireDetail extends Component {
  componentDidMount() {
    this.loadDocument();
  }

  componentDidUpdate(prevProps) {
    if (this.props.questionnaireId !== prevProps.questionnaireId) {
      const { questionnaireId, tenantId } = this.props;
      this.loadQuestionIDs(tenantId, questionnaireId);
    }
  }

  loadDocument() {
    const {
      documentQuestionnaireState,
      documentId,
      questionnaireId,
      tenantId,
    } = this.props;

    UserService.getUserDocument(documentId)
      .then((response) => {
        const data = response.data || {};
        documentQuestionnaireState.required_for_entrance =
          data.required_for_entrance;
        documentQuestionnaireState.expires_in = data.expires_in;
        documentQuestionnaireState.numberOfQuestions = data.number_of_questions;
        documentQuestionnaireState.randomOrderOfQuestions =
          data.random_order_of_questions;
        this.onChangeState();

        if (questionnaireId) {
          this.loadQuestionIDs(tenantId, questionnaireId);
        }
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err.message);
      });
  }

  loadQuestionIDs = (tenantId, documentId) => {
    var self = this;
    const { documentQuestionnaireState } = this.props;
    console.log('documentQuestionnaireState', documentQuestionnaireState);
    QuestionService.getQuestionIDsByLanguages(
      tenantId,
      documentId,
      this.props.user?._id
    )
      .then(function (response) {
        documentQuestionnaireState.questionIDs = response;
        if (response.length < documentQuestionnaireState.numberOfQuestions) {
          documentQuestionnaireState.numberOfQuestions = response.length;
        }
        if (documentQuestionnaireState.randomOrderOfQuestions)
          self.randomOrder();
        self.onChangeState();
        if (response.length > 0) {
          self.loadQuestion(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };
  randomOrder = () => {
    let { documentQuestionnaireState } = this.props;
    for (
      let index = 0;
      index < documentQuestionnaireState.questionIDs.length;
      index++
    ) {
      //random number in range 0 - array length
      let rand = Math.floor(
        Math.random() * documentQuestionnaireState.questionIDs.length
      );
      //switch to items in array
      [
        documentQuestionnaireState.questionIDs[index],
        documentQuestionnaireState.questionIDs[rand],
      ] = [
        documentQuestionnaireState.questionIDs[rand],
        documentQuestionnaireState.questionIDs[index],
      ];
    }
  };

  onChangeState = () => {
    this.props.onChange(this.props.documentQuestionnaireState);
  };

  handleToggleCheckbox = (value) => () => {
    const { documentQuestionnaireState } = this.props;
    const { checked, question, myAnswers } = documentQuestionnaireState;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let answer = true;
    let copy = myAnswers;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length) {
      for (let i = 0; i < question.answers.length; i++) {
        if (!question.answers[i].isCorrect && newChecked.includes(i)) {
          answer = false;
          break;
        }
      }

      let found = false;
      for (let i = 0; i < myAnswers.length; i++) {
        if (myAnswers[i]._id === question._id) {
          copy[i].answer = answer;
          copy[i].checked = newChecked;
          found = true;
          break;
        }
      }
      if (!found) {
        copy.push({ _id: question._id, answer: answer, checked: newChecked });
      }
    } else {
      answer = false; //If nothing is selected, the answer is false

      //Remove question if no answer is provided
      const i = copy.findIndex((a) => a._id === question.id);
      copy.splice(i, 1);
    }

    this.editOrCreateJsonQuestionByID(
      question._id,
      question,
      newChecked,
      answer
    );

    documentQuestionnaireState.checked = newChecked;
    documentQuestionnaireState.myAnswers = copy;
    this.onChangeState();
  };

  editOrCreateJsonQuestionByID = (
    id,
    question,
    checkedIndexOfAnswers,
    isCorrect
  ) => {
    const { documentQuestionnaireState } = this.props;
    const { json } = documentQuestionnaireState;
    let containts = false;
    let checkedAnswers = [];

    if (checkedIndexOfAnswers.length) {
      checkedIndexOfAnswers.forEach((index) => {
        checkedAnswers.push(question.answers[index].answer);
      });

      for (let i = 0; i < json.questions.length; i++) {
        if (json.questions[i].question._id === id) {
          if (question !== undefined) {
            json.questions[i].question = question;
          }
          if (checkedIndexOfAnswers !== undefined) {
            json.questions[i].checkedAnswers = checkedAnswers;
          }
          if (isCorrect !== undefined) {
            json.questions[i].isCorrect = isCorrect;
          }
          containts = true;
          break;
        }
      }
      if (!containts) {
        json.date_of_filling_in = Date.now();
        json.questions.push({
          question: question,
          checkedAnswers: checkedAnswers,
          isCorrect: isCorrect,
        });
      }
    } else {
      //Remove question if no answer is provided
      const i = json.questions.findIndex((q) => q._id === question.id);
      json.questions.splice(i, 1);
    }

    this.onChangeState();
  };

  handleNext = () => {
    const { documentQuestionnaireState } = this.props;
    const { activeIndex, questionIDs } = documentQuestionnaireState;
    if (
      activeIndex !== questionIDs.length - 1 &&
      activeIndex !== documentQuestionnaireState.numberOfQuestions - 1
    ) {
      this.loadQuestion(activeIndex + 1);
      documentQuestionnaireState.activeIndex = activeIndex + 1;
      this.onChangeState();
    } else {
      this.loadSumbit();
    }
  };

  handleBack = () => {
    const { documentQuestionnaireState } = this.props;
    const { activeIndex } = documentQuestionnaireState;
    this.loadQuestion(activeIndex - 1);
    documentQuestionnaireState.activeIndex = activeIndex - 1;
    this.onChangeState();
  };

  resetQuestionnaire = () => {
    const { documentQuestionnaireState } = this.props;

    documentQuestionnaireState.checked = [];
    documentQuestionnaireState.myAnswers = [];
    documentQuestionnaireState.json = { date_of_filling_in: {}, questions: [] };
    documentQuestionnaireState.activeIndex = 0;
    documentQuestionnaireState.isOpenSubmit = false;
    this.props.onChange(documentQuestionnaireState);

    this.loadQuestion(0);
  };

  loadSumbit = () => {
    const { documentQuestionnaireState } = this.props;
    const { questionnaireName, questionnaireId } = this.props;
    let { json, myAnswers, activeIndex } = documentQuestionnaireState;
    let sumOfCorrect = 0;
    myAnswers.forEach((a) => {
      if (a.answer) {
        sumOfCorrect++;
      }
    });
    json.date_of_filling_in = Date.now();
    json.count_of_correct = sumOfCorrect;
    json.count_of_questions = myAnswers.length;
    json.name = questionnaireName;
    json.document_id = questionnaireId;

    documentQuestionnaireState.question = { question: '', answers: [] };
    documentQuestionnaireState.activeIndex = activeIndex + 1;
    documentQuestionnaireState.isOpenSubmit = true;
    this.onChangeState();
    console.log(json);
  };

  loadQuestion = (index) => {
    const self = this;
    const { documentQuestionnaireState } = this.props;
    const { questionIDs } = documentQuestionnaireState;
    documentQuestionnaireState.question = { question: '', answers: [] };
    documentQuestionnaireState.checked = [];
    documentQuestionnaireState.isOpenSubmit = false;
    this.onChangeState();
    if (questionIDs.length > index) {
      QuestionService.getQuestion(questionIDs[index]._id)
        .then(function (response) {
          documentQuestionnaireState.question = response;
          documentQuestionnaireState.checked =
            documentQuestionnaireState.myAnswers.find(
              (a) => a._id === response._id
            ) !== undefined
              ? documentQuestionnaireState.myAnswers.find(
                  (a) => a._id === response._id
                ).checked
              : [];
          self.props.onChange(documentQuestionnaireState);
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleSendAnswers = () => {
    const self = this;
    const { json, required_for_entrance, expires_in } =
      this.props.documentQuestionnaireState;
    const { documentId } = this.props;
    const status = user_document_status_constants.COMPLETED;
    //user_id, user_document_id, status, data
    UserService.sendQuestionnaireAnswers(documentId, status, {
      ...json,
      required_for_entrance,
      expires_in,
    })
      .then(function (response) {
        openSnackbar(i18n.t('sended'));
        self.props.onChange(createEmptyQuestionnaireState(), true);
        self.handleGoToDocuments();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleGoToDocuments = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, theme, documentQuestionnaireState } = this.props;
    const {
      questionIDs,
      question,
      checked,
      isOpenSubmit,
      myAnswers,
      activeIndex,
      json,
      numberOfQuestions,
    } = documentQuestionnaireState;
    const { count_of_correct } = json;

    const style =
      Auth.getUser().role === user_roles.SIGNING_TABLET
        ? { marginTop: theme.spacing(5), padding: theme.spacing(2) }
        : null;

    return (
      <Aux>
        {!isOpenSubmit && (
          <Grid
            container
            //spacing={2}
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            className={classNames(classes.grid, classes.contentOfVmsInfoBar)}
          >
            <Grid item className={classes.htmlParser} style={style}>
              <Typography
                data-cy="questionnaire-text-question"
                variant="h5"
                component="h3"
              >
                {activeIndex + 1 + '. '} {question.questionTitle}
              </Typography>
              {Parser(question.question)}
            </Grid>
            <Grid item>
              <List className={classes.answers}>
                {question.answers.map((ans, index) => (
                  <ListItem
                    data-cy="questionnaire-list-item-answer"
                    key={index}
                    dense
                    button
                    className={classes.listItem}
                    onClick={this.handleToggleCheckbox(index)}
                  >
                    <ListItemText
                      data-cy="questionnaire-text-answer-name"
                      primary={ans.answer}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        data-cy="questionnaire-checkbox-answer-checkbox"
                        color="primary"
                        onChange={this.handleToggleCheckbox(index)}
                        checked={checked.indexOf(index) !== -1}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <MobileStepper
                variant="progress"
                steps={questionIDs.length + 1}
                position="static"
                activeStep={activeIndex}
                nextButton={
                  <Button
                    data-cy="questionnaire-button-next"
                    size="small"
                    onClick={this.handleNext}
                    disabled={activeIndex === questionIDs.length}
                  >
                    {i18n.t('next')}
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    data-cy="questionnaire-button-back"
                    size="small"
                    onClick={this.handleBack}
                    disabled={activeIndex === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    {i18n.t('back')}
                  </Button>
                }
                className={classes.stepper}
              />
            </Grid>
          </Grid>
        )}
        {isOpenSubmit && (
          <div className={classes.contentOfVmsInfoBar}>
            <QuestionnaireDialog
              open={isOpenSubmit}
              totalQuestions={numberOfQuestions}
              totalAnsweredQuestions={myAnswers.length}
              totalCorrectAnswers={count_of_correct}
              sendAnswers={this.handleSendAnswers}
              resetQuestionnaire={this.resetQuestionnaire}
              onClose={this.handleBack}
            />
          </div>
        )}
      </Aux>
    );
  }
}

DocumentQuestionnaireDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  documentQuestionnaireState: PropTypes.shape({
    activeIndex: PropTypes.number.isRequired,
    question: PropTypes.shape({
      _id: PropTypes.string,
      questionTitle: PropTypes.string,
      answers: PropTypes.array,
      language: PropTypes.string,
      numberOfQuestions: PropTypes.number,
      randomOrderOfQuestions: PropTypes.bool,
    }),
    checked: PropTypes.array,
    isLoading: PropTypes.bool,
    myAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        checked: PropTypes.any,
        answer: PropTypes.bool,
      })
    ),
    isOpenSubmit: PropTypes.bool,
    json: PropTypes.shape({
      date_of_filling_in: PropTypes.any,
      questions: PropTypes.array,
    }),
  }).isRequired,
  tenantId: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DocumentQuestionnaireDetail)
);
