import PropTypes from 'prop-types';
import { Grid, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { routes, type_constants } from 'AppSettings';
import tableStyles from 'assets/jss/tableStyles';
import {
  openSnackbar,
  useMenu,
  useSettingsSearch,
  useTableHeader,
} from 'components';
import Auth from 'modules/Auth';
import { memo, useCallback, useEffect, useState } from 'react';
import { withBus } from 'react-bus';
import { useTranslation } from 'react-i18next';
import {
  ConfigurationService,
  InspectionService,
  TemplateService,
} from 'services';
import InspectionEditDialog from './InspectionEditDialog';
import InspectionTable from './InspectionTable';
import InspectionFilterMenu from './InspectionFilterMenu';
import useFilterInspection from './useFilterInspection';

const useStyles = makeStyles((theme) => ({
  ...tableStyles(theme),
}));

const InspectionPage = ({ bus, onChangeSearch }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [inspectionActivities, setInspectionActivities] = useState([]);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [savingActivity, setSavingActivity] = useState(false);

  const [loadingInspectionTypes, setLoadingInspectionTypes] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState([]);

  const highlightedId = useSettingsSearch(
    onChangeSearch,
    InspectionService.getActivitiesSuggestions
  );

  const loadData = useCallback(() => {
    InspectionService.getActivities(Auth.getUser().tenantId)
      .then((response) => {
        setInspectionActivities(response);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  }, []);

  const loadInspectionTypes = useCallback(async () => {
    setLoadingInspectionTypes(true);
    try {
      const response = await ConfigurationService.getTypesForConfiguration(
        type_constants.INSPECTION
      );
      setInspectionTypes(response.map((type) => type.key));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setLoadingInspectionTypes(false);
    }
  }, []);

  const onDeleteSuccess = useCallback(
    (deleted) => {
      openSnackbar(t('removed'));
      setInspectionActivities((prevActivities) =>
        prevActivities.filter(
          (item) => !deleted.find((dlt) => dlt._id === item._id)
        )
      );
    },
    [t]
  );

  const onDeleteFailure = useCallback(
    (err) => {
      console.log(err);
      openSnackbar(err.message);
      loadData();
    },
    [loadData]
  );

  const {
    typeFilters,
    languageFilter,
    filterMenuAnchor,
    filteredInspectionActivities,
    handleFilterAllClick,
    handleOpenFilter,
    handleCloseFilter,
    allTypeFilterChecked,
    allLanguageFilterChecked,
    handleFilterCategoryClick,
    handleFilterLanguageClick,
  } = useFilterInspection(inspectionActivities, inspectionTypes);

  const {
    tableHeaderState,
    setTableHeaderPage,
    setTableHeaderRef,
    handleChangeTableHeader,
    handleRemoveSelectedItemsOpenDialog,
    handleClickRow,
  } = useTableHeader(
    filteredInspectionActivities,
    InspectionService.remove,
    onDeleteSuccess,
    onDeleteFailure,
    t('settings_explore_dialog_remove_selected_title'),
    t('settings_explore_dialog_remove_selected_description')
  );

  const { rowsPerPage } = tableHeaderState;
  let { page } = tableHeaderState;

  const {
    clickedItem,
    menuAnchorEl,
    handleMenuOpen,
    handleMenuClose,
    handleMenuRemoveItemOpenDialog,
  } = useMenu(
    InspectionService.remove,
    onDeleteSuccess,
    onDeleteFailure,
    t('settings_explore_dialog_remove_title'),
    t('settings_explore_dialog_remove_description')
  );

  // ########## ACTIVITY ##########
  const handleNewClick = (pathname) => {
    if (pathname === routes.SETTINGS_INSPECTION) {
      const editor = TemplateService.createEmpty();
      setCurrentActivity({
        editor,
        text: TemplateService.draftToHtml(editor.getCurrentContent()),
        document: {},
        lang: 'en',
      });
    }
  };

  const onEditModalOpen = useCallback(
    (activity) => (event) => {
      setCurrentActivity({
        ...activity,
        editor: TemplateService.createEditorFromHtml(activity.text),
      });
      handleMenuClose();
    },
    [handleMenuClose]
  );

  const onEditModalClose = (event) => {
    setCurrentActivity(null);
  };

  const onActivitySave = async (documentId) => {
    try {
      setSavingActivity(true);
      //Creating a copy here so we can delete the editor field
      const item = documentId
        ? { ...currentActivity, document: documentId }
        : { ...currentActivity };
      delete item.editor;
      if (currentActivity._id) {
        await InspectionService.update(item);
      } else {
        await InspectionService.create(item);
      }
      openSnackbar(t('saved'));
      setCurrentActivity(null);
      loadData();
      onEditModalClose();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setSavingActivity(false);
    }
  };

  const onActivityChange = (name, value) => (event) => {
    const field = name ?? event.target.name;
    const fieldValue = value ?? event.target.value;
    setCurrentActivity((prevActivity) => ({
      ...prevActivity,
      [field]: fieldValue,
    }));
  };

  // ########## EFFECTS ##########
  useEffect(() => {
    bus.on('handleAddClick', handleNewClick);

    return () => bus.off('handleAddClick', handleNewClick);
  }, [bus]);

  useEffect(() => {
    loadData();
    loadInspectionTypes();
  }, [loadData, loadInspectionTypes]);

  useEffect(() => {
    if (highlightedId) {
      const index = filteredInspectionActivities.findIndex(
        (u) => u._id === highlightedId
      );
      const curPage = Math.floor(index / rowsPerPage);

      setTableHeaderPage(curPage);
    }
  }, [
    highlightedId,
    rowsPerPage,
    filteredInspectionActivities,
    setTableHeaderPage,
  ]);

  return (
    <>
      <Grid container>
        <Grid item className={classes.settingsTableGridContent}>
          <InspectionTable
            classes={classes}
            tableHeaderState={tableHeaderState}
            inspectionActivities={filteredInspectionActivities}
            page={page}
            rowsPerPage={rowsPerPage}
            highlightedId={highlightedId}
            handleClickRow={handleClickRow}
            onEditModalOpen={onEditModalOpen}
            menuAnchorEl={menuAnchorEl}
            handleMenuOpen={handleMenuOpen}
            setTableHeaderRef={setTableHeaderRef}
            handleChangeTableHeader={handleChangeTableHeader}
            handleRemoveSelectedItemsOpenDialog={
              handleRemoveSelectedItemsOpenDialog
            }
            handleOpenFilter={handleOpenFilter}
          />
        </Grid>
      </Grid>
      <Menu
        id="inspection-settings-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onEditModalOpen(clickedItem)}>
          {t('menu_edit')}
        </MenuItem>
        <MenuItem onClick={handleMenuRemoveItemOpenDialog}>
          {t('menu_delete')}
        </MenuItem>
      </Menu>
      <InspectionEditDialog
        open={Boolean(currentActivity)}
        activity={currentActivity}
        inspectionTypes={inspectionTypes}
        onClose={onEditModalClose}
        onChange={onActivityChange}
        onSave={onActivitySave}
        loading={savingActivity || loadingInspectionTypes}
      />
      <InspectionFilterMenu
        typeFilter={typeFilters}
        languageFilter={languageFilter}
        filterMenuAnchor={filterMenuAnchor}
        handleCloseFilter={handleCloseFilter}
        handleFilterCategoryClick={handleFilterCategoryClick}
        handleFilterLanguageClick={handleFilterLanguageClick}
        handleFilterAllClick={handleFilterAllClick}
        stateOfTypeFilters={allTypeFilterChecked()}
        stateOfLanguageFilters={allLanguageFilterChecked()}
      />
    </>
  );
};

InspectionPage.propTypes = {
  bus: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
};

export default memo(withBus()(InspectionPage));
