import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Paper, Modal } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import modalStyle from 'assets/jss/modalStyle';
import commonStyles from 'assets/jss/commonStyles';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import {
  VmsDialogRowDragDrop,
  VmsDialogFooter,
  VmsDialogHeader,
  VmsDialogRowFilePreview,
} from 'components';
import DocumentService from 'services/DocumentService';
import { elevation } from 'assets/UISettings';
import { photo_accepted_mime_types } from 'AppSettings';
import compose from 'recompose/compose';
import { withBus } from 'react-bus';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme, 400),
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  row: {
    padding: theme.spacing(2),
  },
});

class UploadLogoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      fileStatus: '',
      wasChanged: false,
    };
  }

  // ######### DRAG DROP #######
  onDrop = (files) => {
    this.setState({
      files,
    });
  };

  onDropAccepted = () => {
    this.setState({
      fileStatus: 'accepted',
    });
  };

  onDropRejected = () => {
    this.setState({
      fileStatus: 'rejected',
    });
  };

  // ########## ELSE ##########
  handleUpload = () => {
    const { files } = this.state;
    const { updateLogo, name } = this.props;

    if (files.length > 0) {
      const file = files[0];
      this.setState({
        uploading: true,
      });
      DocumentService.uploadDocument(name, 'logo', '', true, file)
        .then(() => {
          this.setState({
            wasChanged: true,
          });
          openSnackbar(i18n.t('saved'));
          updateLogo();
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            uploading: false,
          });
          openSnackbar(error.message);
        });
    }
  };

  handleDelete = () => {
    const {
      removeLogo,
      logo: { _id, name },
    } = this.props;

    DocumentService.deleteDocument(_id)
      .then(() => {
        this.setState({
          wasChanged: true,
        });
        removeLogo(name);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleClose = () => {
    const { wasChanged } = this.state;
    const { bus } = this.props;

    this.setState({
      files: [],
      uploading: false,
      fileStatus: '',
    });
    this.props.onClose();

    if (wasChanged) {
      bus.emit('reloadLogos');
    }
  };

  render() {
    const { classes, open, logo, name } = this.props;
    const { files, uploading, fileStatus } = this.state;

    const uploadDisabled = files.length === 0;
    const downloadUrl = (logo || {}).downloadUrl;
    const imgUrl = DocumentService.api + downloadUrl;

    return (
      <Modal className={classes.modal} open={open} onClose={this.handleClose}>
        <Paper className={classes.paper} elevation={elevation.light}>
          <VmsDialogHeader
            title={i18n.t(`change_${name}`)}
            onClose={this.handleClose}
            icon={<Event />}
          />
          <Grid container>
            {downloadUrl && (
              <VmsDialogRowFilePreview
                rowClass={classes.dialogRow}
                previewComponent={
                  <img
                    src={imgUrl}
                    className={classes.imagePreview}
                    alt="Logo"
                  />
                }
                downloadUrl={imgUrl}
              />
            )}
            {!downloadUrl && (
              <VmsDialogRowDragDrop
                rowClass={classes.row}
                files={files}
                fileStatus={fileStatus}
                document_accepted_mime_types={photo_accepted_mime_types}
                uploading={uploading}
                onDropAccepted={this.onDropAccepted}
                onDropRejected={this.onDropRejected}
                onDrop={this.onDrop}
              />
            )}
            <VmsDialogFooter
              onSave={!downloadUrl ? this.handleUpload : null}
              onDelete={downloadUrl ? this.handleDelete : null}
              saveDisabled={uploadDisabled || uploading}
            />
          </Grid>
        </Paper>
      </Modal>
    );
  }
}

UploadLogoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.object,
  updateLogo: PropTypes.func.isRequired,
  removeLogo: PropTypes.func.isRequired,
};

export default compose(withBus(), withStyles(styles))(UploadLogoDialog);
