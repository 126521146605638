import flatten from 'flat';

class ExportServiceImpl {
  // Use JSON.parse(JSON.stringify(data)) wherever we modify the contents!!!

  // Import CSV keys, the order of columns must match the order of keys
  visitorKeys() {
    return ['name', 'surname', 'email', 'phone', 'company'];
  }

  employeeKeys() {
    return [
      'personal_number',
      'name',
      'surname',
      'email',
      'phone',
      'unknown',
      'assistant',
      'language',
      'company',
    ];
  }

  lunchKeys() {
    return ['date', 'type', 'id', 'description', 'extra', 'allergens', 'image'];
  }

  /**
   * Prepare User data for export
   *
   * @param {string} data
   */
  users(data) {
    let headers = [
      '_id',
      'name',
      'email',
      'role',
      'status',
      'company',
      'phone',
      'assistant._id',
      'assistant.name',
      'deputy._id',
      'deputy.name',
    ];
    return { headers: headers, data: this._flatten(data) };
  }

  documents(data) {
    let headers = ['_id', 'name', 'originalname', 'type', 'public'];
    return { headers: headers, data: data };
  }

  templates(data) {
    let headers = [
      '_id',
      'name',
      'category',
      'type',
      'title',
      'body',
      'is_default',
    ];
    let encodedData = [];
    for (let i = 0, max = data.length; i < max; i++) {
      encodedData.push(JSON.parse(JSON.stringify(data[i])));
      encodedData[i].body = this._sanitize(encodedData[i].body);
    }
    return { headers: headers, data: encodedData };
  }

  configurations(data) {
    let headers = ['_id', 'key', 'value', 'description', 'group'];
    return { headers: headers, data: data };
  }

  questionnaires(data) {
    let headers = ['_id', 'type', 'questionTitle', 'question', 'answers'];
    let encodedData = [];
    for (let i = 0, max = data.length; i < max; i++) {
      encodedData.push(JSON.parse(JSON.stringify(data[i])));
      encodedData[i].question = this._sanitize(encodedData[i].question);
      let answers = ' ';
      for (let j = 0; j < encodedData[i].answers.length; j++) {
        answers += encodedData[i].answers[j].answer + ' ';
      }
      encodedData[i].answers = answers;
    }
    return { headers: headers, data: encodedData };
  }

  visits(data) {
    let headers = [
      '_id',
      'timeFrom',
      'user._id',
      'user.name',
      'status',
      'purpose',
      'note',
      'user.email',
      'user.role',
      'user.status',
      'user.company',
      'user.phone',
      'host._id',
      'host.name',
      'host.email',
    ];
    return { headers: headers, data: this._flatten(data) };
  }

  _flatten(data) {
    let flatData = [];
    for (let i = 0, max = data.length; i < max; i++) {
      // react-csv doesnt like null values in composite fields, e. g. assistant._id
      // null properties caused almost untraceable bug
      // thats why we need to get rid of null or undefined props
      let obj = flatten(data[i]);
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      flatData.push(JSON.parse(JSON.stringify(obj)));
    }
    return flatData;
  }

  _sanitize(string) {
    //remove html tags from string
    let tmp = document.createElement('DIV');
    tmp.innerHTML = string;
    return tmp.textContent || tmp.innerText || '';
  }
}

const ExportService = new ExportServiceImpl();

export default ExportService;
