import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import compose from 'recompose/compose';
import { VmsInfoBar, ItineraryDetailDialog } from 'components';
import i18n from 'assets/i18n';
import commonStyle from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyle(theme),
});

class ItineraryDetailPage extends Component {
  constructor(props) {
    super(props);
    this.itinerary = props.location.state.itinerary;
    this.visitId = props.location.state.visitId;
    this.item = props.location.state.item;
  }

  handleGoToDashboard = () => {
    this.props.history.go(-1);
  };

  render() {
    const { classes, fullScreen } = this.props;

    return (
      <>
        <VmsInfoBar
          backMode={true}
          backModeText={i18n.t('tab_visitor_itinerary')}
          onBack={this.handleGoToDashboard}
        />
        <div className={classes.contentOfVmsInfoBar}>
          <ItineraryDetailDialog
            open={true}
            visitId={this.visitId}
            itinerary={this.itinerary}
            originalItem={this.item}
            fullScreen={fullScreen}
            onClose={this.handleGoToDashboard}
            onSave={this.handleGoToDashboard}
          />
        </div>
      </>
    );
  }
}

ItineraryDetailPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withMobileDialog(),
  withStyles(styles)
)(ItineraryDetailPage);
