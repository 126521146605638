import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Paper,
  Grid,
  InputAdornment,
  Hidden,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Auth from 'modules/Auth';
import { VmsAutocomplete, VmsDrawer } from 'components';
import SafetyTrainingsPage from './safetyTrainings/SafetyTrainingsPage';
import { elevation } from 'assets/UISettings';
import { Route, NavLink } from 'react-router-dom';
import i18n from 'assets/i18n';
import classNames from 'classnames';
import ScheduledJobsPage from './jobs/ScheduledJobsPage';
import PeripheralsPage from './peripherals/PeripheralsPage';
import {
  DocumentsPage,
  UsersPage,
  CompanyPage,
  TemplatesPage,
  OthersPage,
  TypesPage,
  ExploreSettingsPage,
  FeedbackPage,
  ConfigurationsPage,
} from './index';
import { template_categories, routes } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import InspectionPage from './inspection';
import LogsPage from './logs/LogsPage';

const styles = (theme) => ({
  ...commonStyles(theme),
  paperMenu: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
      height: 'calc( 100vh - 236px)',
    },
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  active: {
    color: theme.palette.common.black,
  },
});

const PushTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.PUSH}
      prevPageButtonDataCy="settings-push-templates-button-previous-page"
      nextPageButtonDataCy="settings-push-templates-button-next-page"
      {...props}
    />
  );
};

const EmailTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.EMAIL}
      prevPageButtonDataCy="settings-email-templates-button-previous-page"
      nextPageButtonDataCy="settings-email-templates-button-next-page"
      {...props}
    />
  );
};

const SmsTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.SMS}
      prevPageButtonDataCy="settings-sms-templates-button-previous-page"
      nextPageButtonDataCy="settings-sms-templates-button-next-page"
      {...props}
    />
  );
};

const SiteTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.TEMPLATE}
      prevPageButtonDataCy="settings-site-templates-button-previous-page"
      nextPageButtonDataCy="settings-site-templates-button-next-page"
      {...props}
    />
  );
};

const SignedDocTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.SIGNED}
      prevPageButtonDataCy="settings-signed-docs-button-previous-page"
      nextPageButtonDataCy="settings-signed-docs-button-next-page"
      {...props}
    />
  );
};

const AssistantTemplatesPage = (props) => {
  return (
    <TemplatesPage
      category={template_categories.ASSISTANT}
      prevPageButtonDataCy="settings-assistant-button-previous-page"
      nextPageButtonDataCy="settings-assistant-button-next-page"
      {...props}
    />
  );
};

export const emptySettingSearchState = () => {
  return {
    searchValue: '',
    disableSearch: true,
    suggestionService: function () {},
    onSuggestionsClearRequested: function () {},
    onSuggestionSelected: function () {},
    onChangeSearch: function () {},
    suggestionItemValue: function () {},
    filterSuggestionsResponse: function () {},
  };
};

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Auth.getUser(),
      value: 0,
      snackbarOpen: false,
      snackbarMsg: '',
      templateModalOpen: true,
      settingSearchState: emptySettingSearchState(),
      drawerOpen: false,
    };
  }

  componentDidMount() {
    this.props.bus.on('toggleDrawer', this.busToggleDrawer);
  }

  componentWillUnmount() {
    this.props.bus.off('toggleDrawer', this.busToggleDrawer);
  }

  busToggleDrawer = (data) => {
    if (data.pathname.startsWith(routes.SETTINGS))
      this.toggleDrawer(data.open)();
  };

  toggleDrawer = (open) => () => {
    this.setState({
      drawerOpen: open,
    });
  };

  onChangeSearch = (settingSearchState) => {
    this.setState((prevState) => {
      return {
        settingSearchState: {
          ...prevState.settingSearchState,
          ...settingSearchState,
        },
      };
    });
  };

  render() {
    const { classes } = this.props;
    const { settingSearchState } = this.state;
    const {
      searchValue,
      disableSearch,
      suggestionService,
      onSuggestionsClearRequested,
      onSuggestionSelected,
      onChangeSearch,
      suggestionItemValue,
      filterSuggestionsResponse,
    } = settingSearchState;

    const isUserAdmin = Auth.isUserAdmin();
    const isUserGlobalAdmin = Auth.isUserGlobalAdmin();

    const menuContent = (
      <>
        <Paper className={classes.paperMenu} elevation={elevation.light}>
          {/* <div className={classes.menuTitle}>{i18n.t('settings_menu_general')}</div> 
          <hr className={classes.divider}/>
          <div className={classes.menuTitle}>{i18n.t('settings_menu_app_specific')}</div> */}
          <NavLink
            data-cy="settings-link-users"
            className={classNames(classes.link, classes.menuCategory)}
            activeClassName={classes.active}
            to="/settings/users"
          >
            {i18n.t('settings_menu_general_users')}
          </NavLink>
          <br></br>
          <NavLink
            data-cy="settings-link-company"
            className={classNames(classes.link, classes.menuCategory)}
            activeClassName={classes.active}
            to="/settings/company"
          >
            {i18n.t('settings_menu_app_specific_company')}
          </NavLink>
          <br />
          {isUserAdmin && (
            <>
              <NavLink
                data-cy="settings-link-trainings"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/trainings"
              >
                {i18n.t('settings_menu_app_specific_trainings')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-feedback"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/feedback"
              >
                {i18n.t('settings_menu_app_specific_feedback')}
              </NavLink>
              <br />

              {/* <div className={classes.menuCategory}>{i18n.t('settings_menu_app_specific_notifications')}</div> */}
              <NavLink
                data-cy="settings-link-email"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/email"
              >
                {i18n.t('settings_menu_app_specific_notifications_email')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-push"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/push"
              >
                {i18n.t('settings_menu_app_specific_notifications_push')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-sms"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/sms"
              >
                {i18n.t('settings_menu_app_specific_notifications_sms')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-signed"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/signed"
              >
                {i18n.t('settings_menu_app_specific_signed_documents')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-site-templates"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/sitetemplates"
              >
                {i18n.t('settings_menu_app_specific_site_templates')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-inspection"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/inspection"
              >
                {i18n.t('settings_menu_app_specific_inspection')}
              </NavLink>
              <br />

              {/* <div className={classes.menuCategory}>{i18n.t('settings_menu_app_specific_documents')}</div> */}
              <NavLink
                data-cy="settings-link-documents"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/documents"
              >
                {i18n.t('settings_menu_app_specific_documents_uploading')}
              </NavLink>
              <br />

              <NavLink
                data-cy="settings-link-types"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/types"
              >
                {i18n.t('settings_menu_app_specific_types')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-configurations"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/configurations"
              >
                {i18n.t('settings_menu_app_specific_configurations')}
              </NavLink>
              <br />
              {isUserGlobalAdmin && (
                <>
                  <NavLink
                    data-cy="settings-link-configurations"
                    className={classNames(classes.link, classes.menuCategory)}
                    activeClassName={classes.active}
                    to="/settings/globalconfigurations"
                  >
                    {i18n.t('settings_menu_app_specific_global_configurations')}
                  </NavLink>
                  <br />
                </>
              )}

              <NavLink
                data-cy="settings-link-scheduled-jobs"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/scheduledJobs"
              >
                {i18n.t('settings_menu_app_specific_scheduled_jobs')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-others"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/others"
              >
                {i18n.t('settings_menu_app_specific_others')}
              </NavLink>
              <br />

              <NavLink
                data-cy="settings-link-peripherals"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/peripherals"
              >
                {i18n.t('settings_menu_app_specific_peripherals')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-assistant"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/assistant"
              >
                {i18n.t('settings_menu_app_specific_assistant')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-explore"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/explore"
              >
                {i18n.t('settings_menu_app_specific_explore')}
              </NavLink>
              <br />
              <NavLink
                data-cy="settings-link-logs"
                className={classNames(classes.link, classes.menuCategory)}
                activeClassName={classes.active}
                to="/settings/logs"
              >
                {i18n.t('settings_menu_app_specific_logs')}
              </NavLink>
            </>
          )}
        </Paper>
      </>
    );

    return (
      <>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.grid} xs={12} md={4} lg={3}>
            <div className={classes.search}>
              <VmsAutocomplete
                customClass={classes.searchField}
                inputClass={classes.inputSearchField}
                disabled={disableSearch}
                disableUnderline
                inputName={'searchSettings'}
                suggestionService={suggestionService}
                suggestionItemValue={suggestionItemValue}
                filterSuggestionsResponse={filterSuggestionsResponse}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                onChange={onChangeSearch}
                inputValue={searchValue}
                placeholder={i18n.t('search')}
                endAdornment={
                  <InputAdornment
                    className={classes.searchAdornment}
                    position="end"
                  >
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            <Hidden smDown>{menuContent}</Hidden>
          </Grid>
          <Grid item className={classes.gridContent} xs={12} md={8} lg={9}>
            <Paper className={classes.paperTable} elevation={elevation.light}>
              <Route
                exact
                path="/settings/users"
                render={() => (
                  <UsersPage
                    settingSearchState={settingSearchState}
                    onChangeSearch={this.onChangeSearch}
                  />
                )}
              />
              <Route
                exact
                path="/settings/company"
                render={() => (
                  <CompanyPage
                    settingSearchState={settingSearchState}
                    onChangeSearch={this.onChangeSearch}
                  />
                )}
              />
              {isUserAdmin && (
                <>
                  <Route
                    exact
                    path="/settings/email"
                    render={() => (
                      <EmailTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/push"
                    render={() => (
                      <PushTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/sms"
                    render={() => (
                      <SmsTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/signed"
                    render={() => (
                      <SignedDocTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/sitetemplates"
                    render={() => (
                      <SiteTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/trainings"
                    render={() => (
                      <SafetyTrainingsPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/feedback"
                    render={() => (
                      <FeedbackPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/documents"
                    render={() => (
                      <DocumentsPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/types"
                    render={() => (
                      <TypesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/configurations"
                    render={() => (
                      <ConfigurationsPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                        group="tenant_settings"
                      />
                    )}
                  />
                  {(isUserGlobalAdmin || isUserAdmin) && (
                    <Route
                      exact
                      path="/settings/globalconfigurations"
                      render={() => (
                        <ConfigurationsPage
                          settingSearchState={settingSearchState}
                          onChangeSearch={this.onChangeSearch}
                          group="settings"
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path="/settings/scheduledJobs"
                    component={ScheduledJobsPage}
                  />
                  <Route
                    exact
                    path="/settings/others"
                    render={() => (
                      <OthersPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/peripherals"
                    render={() => (
                      <PeripheralsPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/assistant"
                    render={() => (
                      <AssistantTemplatesPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/explore"
                    render={() => (
                      <ExploreSettingsPage
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/inspection"
                    render={() => (
                      <InspectionPage onChangeSearch={this.onChangeSearch} />
                    )}
                  />
                  <Route
                    exact
                    path="/settings/logs"
                    render={() => (
                      <LogsPage
                        settingSearchState={settingSearchState}
                        onChangeSearch={this.onChangeSearch}
                      />
                    )}
                  />
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
        <VmsDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          clickCloses={true}
        >
          {menuContent}
        </VmsDrawer>
      </>
    );
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(SettingsPage);
