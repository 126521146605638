import PropTypes from 'prop-types';
import { withStyles, TextField, Button, Typography } from '@material-ui/core';
import TermsDialog from 'containers/TermsDialog';
import { Link } from 'react-router-dom';
import i18n from 'assets/i18n';
import commonStyles from 'assets/jss/commonStyles';
import { validatePassword } from 'AppSettings';
import util from 'util';
import PasswordHelp from '../popups/PasswordHelp';
import { useEffect, useState } from 'react';
import { ConfigurationService } from 'services';

const styles = (theme) => ({
  ...commonStyles(theme),
  formControl: {
    margin: theme.spacing(),
  },
});

const AccountActivationForm = (props) => {
  const { classes, handleActivate, onChange, onChangeTermsData, data } = props;
  const {
    message,
    user,
    password,
    passwordVerify,
    stage,
    agreePrivacy,
    agreeBehaviour,
  } = data;

  const [useBehaviour, setUseBehaviour] = useState('true');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await ConfigurationService.getUseBehaviourRulesConfig();
      setUseBehaviour(response);
    } catch (e) {
      console.log(e);
    }
  };
  const passwordInvalid = validatePassword(password);
  let activateDisabled =
    passwordInvalid ||
    password !== passwordVerify ||
    stage === 'activating' ||
    agreePrivacy === false;
  activateDisabled =
    useBehaviour === 'true'
      ? activateDisabled || agreeBehaviour === false
      : activateDisabled;
  let renderMessage = user
    ? util.format(i18n.t('account_activation_welcome'), user)
    : message;
  return (
    <div>
      <Typography variant="h6">{i18n.t('account_activation_title')}</Typography>
      <div>
        <Typography variant="body1">{renderMessage}</Typography>
      </div>
      {stage === 'activate' || stage === 'activating' ? (
        <div className={classes.container}>
          <div>
            <TextField
              required
              name="password"
              label={i18n.t('account_activation_password_placeholder')}
              className={classes.textField}
              type="password"
              margin="normal"
              fullWidth
              onChange={onChange}
              value={password}
              error={password.length > 0 && passwordInvalid}
            />
            <TextField
              required
              name="passwordVerify"
              label={i18n.t('account_activation_password_verify_placeholder')}
              className={classes.textField}
              type="password"
              margin="normal"
              fullWidth
              onChange={onChange}
              value={passwordVerify}
              error={passwordVerify !== password ? true : false}
            />
            {password.length > 0 && passwordInvalid && <PasswordHelp />}
          </div>
          <TermsDialog
            onChange={onChangeTermsData}
            useBehaviour={useBehaviour}
          />
          <div>
            <Button
              className={classes.button}
              style={{ marginTop: 16, marginBottom: 16 }}
              variant="contained"
              color="primary"
              disabled={activateDisabled}
              onClick={handleActivate}
            >
              {i18n.t('account_activation_button')}
            </Button>
          </div>
        </div>
      ) : (
        <Button
          className={classes.button}
          component={Link}
          to="/login"
          variant="contained"
          color="primary"
        >
          {i18n.t('account_activation_proceed_to_login')}
        </Button>
      )}
    </div>
  );
};

AccountActivationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleActivate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountActivationForm);
