import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = (theme) => ({
  button: {
    textTransform: 'none',
    fontWeight: 400,
    height: '100%',
    padding: 0,
    paddingLeft: theme.spacing(),
    marginLeft: -theme.spacing(),
  },
});

const ExploreButton = ({
  classes,
  children,
  onClick,
  target,
  href,
  rel,
  buttonDataCy,
}) => {
  return (
    <Button
      data-cy={buttonDataCy}
      className={classes.button}
      onClick={onClick}
      target={target}
      href={href}
      rel={rel}
    >
      {children}
      <KeyboardArrowRight />
    </Button>
  );
};

ExploreButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  buttonDataCy: PropTypes.string,
};

//Used only in components without state, no need for React.memo
export default withStyles(styles)(ExploreButton);
