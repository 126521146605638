import PropTypes from 'prop-types';
import { withStyles, Grid, Button, Typography } from '@material-ui/core';
import { Announcement } from '@material-ui/icons';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import mobileStyles from '../../../assets/jss/mobileStyles';
import classNames from 'classnames';
import { user_document_status_constants } from '../../../AppSettings';
import i18n from '../../../assets/i18n';
import moment from 'moment';

const styles = (theme) => ({
  ...mobileStyles(),
  gridItem: {
    width: '100%',
    minHeight: 80,
    position: 'relative',
  },
  itemContent: {
    width: '100%',
    height: '100%',
  },
  button: {
    textTransform: 'none',
    padding: 8,
  },
  badge: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  badgeInvisible: {
    background: 'white',
  },
  flex: {
    flex: 1,
  },
  documentName: {
    color: 'black',
  },
  documentNameCompl: {
    color: theme.palette.primary4,
  },
  documentDesc: {
    color: theme.palette.primary5,
  },
  documentDescCompl: {
    color: theme.palette.primary8,
  },
  disclosure: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    color: theme.palette.primary4,
  },
  requiredIcon: {
    marginRight: theme.spacing(),
  },
  requiredIconPending: {
    color: theme.palette.primary.main,
  },
});

function DocumentItem(props) {
  const { classes, item, onClick } = props;
  const { required_for_entrance, expires_in } = item.data;
  const valid_until = moment(item.valid_until);
  const now = moment();

  const completed =
    item.status !== user_document_status_constants.PENDING &&
    !(required_for_entrance && expires_in && valid_until.isBefore(now));

  const expires_in_days = expires_in
    ? valid_until.diff(now, 'days')
    : undefined;

  let displayText;
  if (required_for_entrance) {
    if (!completed) {
      displayText = i18n.t('required_for_entrance');
    } else {
      if (expires_in) {
        displayText = i18n.t('expires_in', { days: expires_in_days });
      }
    }
  }

  return (
    <Grid
      data-cy="documents-list-item-document"
      item
      className={classes.gridItem}
    >
      <Button
        data-cy="documents-button-open-document"
        className={classNames(
          classes.parentWithShadow,
          classes.itemContent,
          classes.button
        )}
        classes={{
          label: classes.itemContent,
        }}
        onClick={onClick}
      >
        <Grid
          container
          spacing={1}
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.itemContent}
        >
          <Grid item>
            <div
              className={classNames(
                classes.badge,
                completed ? classes.badgeInvisible : null
              )}
            />
          </Grid>
          <Grid item className={classes.flex}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item container direction="row" spacing={1}>
                <Grid item container sm={displayText ? 3 : 12}>
                  <Typography
                    variant="body2"
                    className={
                      completed
                        ? classes.documentNameCompl
                        : classes.documentName
                    }
                    noWrap
                  >
                    {item.data.name}
                  </Typography>
                </Grid>
                <Grid item container sm={9} direction="row">
                  {displayText && (
                    <Grid item container direction="row">
                      <Announcement
                        className={classNames(
                          classes.requiredIcon,
                          completed
                            ? classes.documentNameCompl
                            : classes.requiredIconPending
                        )}
                      />
                      <Typography
                        variant="body2"
                        className={
                          completed
                            ? classes.documentNameCompl
                            : classes.documentName
                        }
                      >
                        {displayText}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  className={
                    completed ? classes.documentDescCompl : classes.documentDesc
                  }
                >
                  {item.data.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  className={
                    completed ? classes.documentDescCompl : classes.documentDesc
                  }
                >
                  {i18n.t(
                    'document_status_' + (completed ? item.status : 'pending')
                  ) +
                    ' - ' +
                    item.companyName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.disclosure}>
            <KeyboardArrowRight />
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}

DocumentItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(DocumentItem);
