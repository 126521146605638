import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  InputAdornment,
  LinearProgress,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';
import { VisitCard, useInfiniteLoading, VmsAutocomplete } from 'components';
import {
  routes,
  tab_indexes,
  my_visits_history,
  visit_card_modes,
} from 'AppSettings';
import moment from 'moment-timezone';
import commonStyles from 'assets/jss/commonStyles';
import { VisitService } from 'services';
import compose from 'recompose/compose';
import { withBus } from 'react-bus';
import InviteVisitor from 'containers/dashboard/visitor/InviteVisitor';
import classNames from 'classnames';
import HistoryButtons from './HistoryButtons/HistoryButtons';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import useSuggestion from './useSuggestion';
import useSelectedVisit from './useSelectedVisit';
import useInvitationState from './useInvitationState';
import { openSnackbar } from 'components/common/bars/SnackBar';

const styles = (theme) => ({
  ...commonStyles(theme),
  searchBackground: {
    backgroundColor: theme.palette.white,
  },
  loading: {
    height: 4,
  },
  search: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  pageWrapper: {
    minHeight: `calc(100vh - 140px)`,
  },
  sticky: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 0,
    },
  },
  infinite: {
    overflow: 'unset !important',
    height: 'unset !important',
  },
});

const MyVisits = ({ classes, bus, user, updateExportType, updateHasData }) => {
  const [t] = useTranslation();

  const historyLabels = useMemo(
    () => [
      {
        key: my_visits_history.UPCOMING,
        value: t('my_visits_upcoming'),
        buttonDataCy: 'my-visits-button-upcomming',
      },
      {
        key: my_visits_history.PREVIOUS,
        value: t('my_visits_previous'),
        buttonDataCy: 'my-visits-button-previous',
      },
      {
        key: my_visits_history.ALL,
        value: t('my_visits_all'),
        buttonDataCy: 'my-visits-button-all',
      },
    ],
    [t]
  );
  const [selectedHistory, setSelectedHistory] = useState(historyLabels[0].key);

  //Infinite scroll
  const id = (user || {})._id;
  const fetchService = useCallback(
    (currentPage, pageSize) => {
      if (id) {
        return VisitService.getListOfVisitsHost(
          id,
          selectedHistory,
          currentPage,
          pageSize
        );
      }
      return [];
    },
    [id, selectedHistory]
  );
  const {
    data: visits,
    hasMore,
    fetchMore,
    resetLoader,
    isLoading,
    replaceData,
  } = useInfiniteLoading(fetchService);

  //Selected visit
  const [
    selectedVisit,
    setSelectedVisit,
    setSelectedVisitHit,
    selectedVisitRef,
  ] = useSelectedVisit('tabContainer-0', fetchMore);

  //Suggestions
  const onSuggestionSelected = useCallback(
    (visit) => {
      setSelectedVisit(visit);
      setSelectedVisitHit(false);
    },
    [setSelectedVisit, setSelectedVisitHit]
  );
  const itemSuggestionValue = useCallback(
    (visit) => {
      return (
        visit.user.name +
        ', ' +
        t('visiting') +
        ' ' +
        visit.host.name +
        ', ' +
        moment(visit.timeFrom).format('L')
      );
    },
    [t]
  );
  const [
    visitorSuggestionValue,
    handleVisitorSuggestionChange,
    handleVisitorSuggestionSelected,
    handleVisitorSuggestionsClearRequested,
    clearSuggestion,
  ] = useSuggestion(onSuggestionSelected, itemSuggestionValue);
  const suggestionService = (name) => {
    return VisitService.getHostVisitsSuggestions(name, selectedHistory);
  };

  //Reset
  const reset = useCallback(() => {
    setSelectedVisit(null);
    setSelectedVisitHit(false);
    resetLoader();
    clearSuggestion();
  }, [setSelectedVisit, setSelectedVisitHit, resetLoader, clearSuggestion]);

  useEffect(() => {
    //Resets on selected history change since resetLoader depends on it
    reset();
  }, [reset]);

  //Invitation state
  const [
    inviteDialogOpen,
    inviteDialogVisit,
    inviteDialogEditAsGroupInvite,
    handleEditInviteDialogVisit,
    handleCloseInviteDialogVisit,
  ] = useInvitationState();
  const onSaveInviteDialogVisit = async () => {
    //Find out how many items should be replaced and from which index
    let oldCount = 0;
    let minIndex = Number.MAX_SAFE_INTEGER;
    if (inviteDialogVisit.group) {
      let foundFirst = false;
      for (let i = 0; i < visits.length; i++) {
        const vst = visits[i];
        if (vst.group === inviteDialogVisit.group) {
          foundFirst = true;
          oldCount++;

          if (i < minIndex) {
            minIndex = i;
          }
        } else {
          if (foundFirst) {
            break;
          }
        }
      }
    } else {
      minIndex = visits.findIndex((vst) => vst === inviteDialogVisit);
      oldCount++;
    }

    //Get the updated state of the replaced visits
    try {
      let newVisits = [];
      let updatedVisit;

      //In case we deleted the visit we initially selected
      try {
        updatedVisit = await VisitService.getVisit(inviteDialogVisit._id);
      } catch (err) {
        if (err.status !== 404) {
          throw err;
        }
      }

      const visit = updatedVisit || inviteDialogVisit;
      if (visit.group) {
        newVisits = await VisitService.getGroupVisits(visit.group);
      } else {
        newVisits.push(visit);
      }

      replaceData(minIndex, oldCount, newVisits);
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  //React bus
  const reloadData = useCallback(
    (pathname) => {
      if (pathname === routes.MY_VISITS) {
        reset();
      }
    },
    [reset]
  );

  useEffect(() => {
    bus.on('handleReloadData', reloadData);

    return () => {
      bus.off('handleReloadData', reloadData);
    };
  }, [bus, reloadData]);

  //Update the background
  useEffect(() => {
    if (!isLoading) {
      updateHasData(tab_indexes.MY_VISITS, visits.length);
    }
  }, [updateHasData, visits.length, isLoading]);

  //Update export type
  useEffect(() => {
    updateExportType(selectedHistory);
  }, [updateExportType, selectedHistory]);

  const date = moment();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.pageWrapper}
      >
        <Grid
          item
          className={classNames(classes.sticky, classes.grid)}
          xs={12}
          md={4}
          lg={3}
        >
          <div className={classes.search}>
            <HistoryButtons
              labels={historyLabels}
              selected={selectedHistory}
              onSelect={setSelectedHistory}
            />
            <VmsAutocomplete
              customClass={classNames(
                classes.searchField,
                classes.searchBackground
              )}
              inputClass={classes.inputSearchField}
              disableUnderline
              inputName="searchVisitors"
              suggestionService={suggestionService}
              suggestionItemValue={itemSuggestionValue}
              filterSuggestionsResponse={(response) => {
                return response;
              }}
              onSuggestionsClearRequested={
                handleVisitorSuggestionsClearRequested
              }
              onSuggestionSelected={handleVisitorSuggestionSelected}
              onChange={handleVisitorSuggestionChange}
              inputValue={visitorSuggestionValue}
              placeholder={t('visitor_find_visitor')}
              endAdornment={
                <InputAdornment
                  className={classes.searchAdornment}
                  position="end"
                >
                  <Search />
                </InputAdornment>
              }
            />
          </div>
        </Grid>
        <Grid item className={classes.gridContent} xs={12} md={8} lg={9}>
          <InfiniteScroll
            className={classes.infinite}
            dataLength={visits.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<LinearProgress className={classes.loading} />}
            scrollableTarget="tabContainer-0"
          >
            {visits.map((visit, i) => {
              const isSelected = (selectedVisit || {})._id === visit._id;

              return (
                <VisitCard
                  key={visit._id}
                  index={i}
                  visit={visit}
                  mode={visit_card_modes.HOST}
                  onClick={handleEditInviteDialogVisit}
                  gridRef={isSelected ? selectedVisitRef : null}
                  isSelected={isSelected}
                />
              );
            })}
          </InfiniteScroll>
        </Grid>
      </Grid>
      <InviteVisitor
        page={routes.MY_VISITS}
        open={inviteDialogOpen}
        selectedVisit={inviteDialogVisit}
        selectedDate={date}
        onClose={handleCloseInviteDialogVisit}
        onSave={onSaveInviteDialogVisit}
        editAsGroupInvite={inviteDialogEditAsGroupInvite}
        reset={reset}
      />
    </>
  );
};

MyVisits.propTypes = {
  classes: PropTypes.object.isRequired,
  bus: PropTypes.object.isRequired,
  updateHasData: PropTypes.func,
};

export default compose(withBus(), withStyles(styles))(withRouter(MyVisits));
