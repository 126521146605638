const cardStyle = (theme) => ({
  card: {
    margin: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '350px',
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

export default cardStyle;
