import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { ErrorBoundary, VmsInput } from 'components';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  correct: {
    color: theme.palette.primaryIcon,
  },
  incorrect: {
    color: theme.palette.primary4,
  },
  maxWidth: {
    width: 570,
  },
  answers: {
    paddingLeft: theme.spacing(6),
  },
});

const safetyResultDialog = (props) => {
  const { classes, document, openDialog, onClose } = props;
  const onChangeInput = () => (event) => {};
  return (
    <Aux>
      {document.hasOwnProperty('data') && openDialog && (
        <Dialog
          open={openDialog}
          onClose={onClose()}
          aria-labelledby="safety-result"
        >
          <DialogContent>
            <ErrorBoundary>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" id="safety-result">
                        {document.data.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {document.data.count_of_correct +
                          ' / ' +
                          document.data.count_of_questions}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {document.data.questions.map((q, i) => {
                  return (
                    <Aux key={q.question._id}>
                      <Grid item className={classes.maxWidth}>
                        <VmsInput
                          rowClass={classes.dialogRow3}
                          iconLeft={
                            q.isCorrect === true ? (
                              <CheckCircle className={classes.correct} />
                            ) : (
                              <HighlightOff className={classes.incorrect} />
                            )
                          }
                          textValue={q.question.questionTitle}
                          inputName={`question_${i}`}
                          placeholder=""
                          inputLabel=""
                          onChange={onChangeInput}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item className={classes.answers}>
                            {q.checkedAnswers.map((a) => {
                              return (
                                <div key={a}>
                                  {'• ' + a}
                                  <br />
                                </div>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Aux>
                  );
                })}
              </Grid>
            </ErrorBoundary>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose()} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Aux>
  );
};

safetyResultDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(safetyResultDialog);
