import { Component } from 'react';
import { NumberCard, GraphCard } from 'components/';
import { Grid } from '@material-ui/core';
import ReportService from 'services/ReportService';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { routes } from 'AppSettings';
import { withBus } from 'react-bus';
import deepEqual from 'deep-equal';
import fileDownload from 'js-file-download';
import moment from 'moment';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countOfVisits: 0,
      countOfUniqueVisitors: 0,
      countOfVisitorToday: 0,
      visitorsLastSixMonths: undefined,
      visitorsPerDay: undefined,
      visitorsPerWeek: undefined,
      visitorsLastMonth: undefined,
      visitorsNextMonth: undefined,
      visitorsNextWeek: undefined,
      successRateOfVisitsLastSixMonths: undefined,
    };
  }

  componentDidMount() {
    this.props.bus.on('handleReloadData', this.busHandleReloadData);
    this.loadStatistics();
  }

  componentWillUnmount() {
    this.props.bus.off('handleReloadData', this.busHandleReloadData);
  }

  busHandleReloadData = (page) => {
    if (page === routes.REPORTS) setTimeout(this.loadStatistics, 750);
  };

  loadStatistics = () => {
    const self = this;
    ReportService.getCountOfVisits()
      .then(function (response) {
        if (!deepEqual(self.state.countOfVisits, response)) {
          self.setState({
            countOfVisits: response,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getCountOfDistinctVisitors()
      .then(function (response) {
        if (!deepEqual(self.state.countOfUniqueVisitors, response)) {
          self.setState({
            countOfUniqueVisitors: response,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getCountOfVisitorsToday()
      .then(function (response) {
        if (!deepEqual(self.state.countOfVisitorToday, response)) {
          self.setState({
            countOfVisitorToday: response,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getVisitorsPerDay()
      .then(function (response) {
        const data = ReportService.getVisitorsPerDayChartData(response);
        if (!deepEqual(self.state.visitorsPerDay, data)) {
          self.setState({
            visitorsPerDay: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getVisitorsPerWeek()
      .then(function (response) {
        const data = ReportService.getVisitorsPerWeekChartData(response);
        if (!deepEqual(self.state.visitorsPerWeek, data)) {
          self.setState({
            visitorsPerWeek: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getVisitorsPerMonths()
      .then(function (response) {
        const data = ReportService.getVisitorsPerMonthsChartData(response);
        if (!deepEqual(self.state.visitorsLastSixMonths, data)) {
          self.setState({
            visitorsLastSixMonths: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });

    ReportService.getSuccesRateOfVisitsLastSixMonths()
    .then(function (response) {
      const data = ReportService.getSuccesRateOfVisitsLastSixMonthsChartData(response);
      if (!deepEqual(self.state.successRateOfVisitsLastSixMonths, data)) {
        self.setState({
          successRateOfVisitsLastSixMonths: data,
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      openSnackbar(error.message);
    });

    ReportService.getVisitorsLastMonth()
      .then(function (response) {
        const data = ReportService.getVisitorsMonthChartData(response);
        if (!deepEqual(self.state.visitorsLastSixMonths, data)) {
          self.setState({
            visitorsLastMonth: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getVisitorsNextMonth()
      .then(function (response) {
        const data = ReportService.getVisitorsMonthChartData(response);
        if (!deepEqual(self.state.visitorsLastSixMonths, data)) {
          self.setState({
            visitorsNextMonth: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    ReportService.getVisitorsNextWeek()
      .then(function (response) {
        const data = ReportService.getVisitorsPerWeekChartData(response);
        if (!deepEqual(self.state.visitorsPerWeek, data)) {
          self.setState({
            visitorsNextWeek: data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
     
  };

  download = (type) => {
    ReportService.downloadReport(type)
      .then(function (response) {
        const timestamp = moment();
        fileDownload(response, type + timestamp.toISOString() + '.csv');
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  render() {
    const {
      visitorsLastSixMonths,
      visitorsPerDay,
      visitorsPerWeek,
      countOfVisits,
      countOfUniqueVisitors,
      countOfVisitorToday,
      visitorsLastMonth,
      visitorsNextMonth,
      visitorsNextWeek,
      successRateOfVisitsLastSixMonths,
      
    } = this.state;
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <NumberCard
            number={countOfVisits}
            text={i18n.t('reports_number_of_visits')}
            onDownload={() => this.download('VisitorsAllTime')}
            buttonDataCy="reports-button-download-visitors-all-time"
          />
          <NumberCard
            number={countOfUniqueVisitors}
            text={i18n.t('reports_number_of_unique_visitors')}
          />
          <NumberCard
            number={countOfVisitorToday}
            text={i18n.t('reports_number_of_visitors_today')}
            onDownload={() => this.download('VisitorsToday')}
            buttonDataCy="reports-button-download-visitors-today"
          />
          <GraphCard
            chartData={visitorsPerDay}
            type="Bar"
            heading={i18n.t('reports_number_of_visitors_per_day')}
            subheading={i18n.t(
              'reports_number_of_visitor_particular_time_interval'
            )}
            footer="updated 4 minutes ago"
          />
          <GraphCard
            chartData={visitorsPerWeek}
            type="Bar"
            heading={i18n.t('reports_number_of_visitors_per_week')}
            subheading={i18n.t(
              'reports_number_of_visitor_particular_day_of_week'
            )}
            footer="updated 4 minutes ago"
          />
          <GraphCard
            chartData={visitorsLastSixMonths}
            type="Line"
            heading={i18n.t('reports_visitors_last_six_months')}
            subheading={i18n.t('reports_visitors_particular_last_six_months')}
            onDownload={() => this.download('VisitorsLastSixMonths')}
            footer="updated 4 minutes ago"
            buttonDataCy="reports-button-download-visitors-last-six-months"
          />
          <GraphCard
            chartData={visitorsLastMonth}
            type="Bar"
            heading={i18n.t('reports_number_of_visitors_last_month')}
            subheading={i18n.t(
              'reports_number_of_visitors_particular_last_month'
            )}
            onDownload={() => this.download('VisitorsLastMonth')}
            footer="updated 4 minutes ago"
            buttonDataCy="reports-button-download-visitors-last-month"
          />
          <GraphCard
            chartData={visitorsNextWeek}
            type="Bar"
            heading={i18n.t('reports_number_of_visitors_next_week')}
            subheading={i18n.t(
              'reports_number_of_visitors_particular_next_week'
            )}
            onDownload={() => this.download('VisitorsNextMonth')}
            footer="updated 4 minutes ago"
            buttonDataCy="reports-button-download-visitors-next-month"
          />
          <GraphCard
            chartData={visitorsNextMonth}
            type="Bar"
            heading={i18n.t('reports_number_of_visitors_next_month')}
            subheading={i18n.t(
              'reports_number_of_visitors_particular_next_month'
            )}
            onDownload={() => this.download('VisitorsNextWeek')}
            footer="updated 4 minutes ago"
            buttonDataCy="reports-button-download-visitors-next-week"
          />
          <GraphCard
            chartData={successRateOfVisitsLastSixMonths}
            type="Bar"
            heading={i18n.t('reports_success_rate_of_visits_last_six_months')}
            subheading={i18n.t(
              'reports_success_rate_of_visits_particular_last_six_months'
            )}
            onDownload={() => this.download('VisitorsLastSixMonths')}
            footer="updated 4 minutes ago"
            buttonDataCy="reports-button-download-success-rate-of-visits-last-six-months"/>
        </Grid>
      </div>
    );
  }
}

export default withBus()(Dashboard);
