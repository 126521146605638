import PropTypes from 'prop-types';
import { withStyles, Modal, Paper } from '@material-ui/core';
import Schedule from '@material-ui/icons/Schedule';
import Event from '@material-ui/icons/Event';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Group from '@material-ui/icons/Group';
import {
  VmsDialogRowLabel,
  VmsDialogHeader,
  VmsDialogRowLabelCollection,
} from '../../common';
import i18n from '../../../assets/i18n';
import modalStyle from '../../../assets/jss/modalStyle';
import commonStyle from '../../../assets/jss/commonStyles';
import { date_formats } from '../../../AppSettings';
import { elevation } from '../../../assets/UISettings';
import moment from 'moment';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

const scheduledJobDialog = (props) => {
  const { classes, onClose, open, item } = props;
  const type = item ? i18n.t(`app_settings_type_${item.type}`) : '';
  const formattedDate = item
    ? moment(item.deliveryDate).format(date_formats.full_date_long)
    : '';
  const status = item ? i18n.t(`scheduled_job_status_${item.status}`) : '';
  const users = item ? (item.users || []).map((u) => u.name) : [];

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="Scheduled job dialog"
      aria-describedby="Dialog showing scheduled job info"
      open={open}
      onClose={onClose}
    >
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsDialogHeader icon={<Event />} title={type} onClose={onClose} />
        <VmsDialogRowLabel
          rowClass={classes.dialogRowSmall}
          iconLeft={<Schedule />}
          textValue={formattedDate}
        />
        <VmsDialogRowLabel
          rowClass={classes.dialogRowSmall}
          iconLeft={<FolderOpen />}
          textValue={status}
        />
        <VmsDialogRowLabelCollection
          iconLeft={<Group />}
          noItemsText={'No recepients'}
          items={users}
        />
      </Paper>
    </Modal>
  );
};

scheduledJobDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
    deliveryDate: PropTypes.string,
    status: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default withStyles(styles)(scheduledJobDialog);
