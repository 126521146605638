import {
  memo,
  useState,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  withWidth,
  Typography,
  Divider,
  Hidden,
} from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import LandscapeIcon from '@material-ui/icons/Landscape';
import {
  ExploreNewsCard,
  ExploreThingCard,
  ExploreContainer,
  ExploreGrid,
  ExploreNewsRead,
  ExploreThingRead,
  ExploreMoreContainer,
  ExploreNewsListItem,
  ExploreThingListItem,
} from 'components';
import { VmsGoogleMap } from 'components';
import {
  ExploreService,
  DocumentService,
  ConfigurationService,
  VisitService,
} from 'services';
import {
  explore_types,
  configuration_group,
  configuration_location_type,
} from 'AppSettings';
import Coordinates from 'coordinate-parser';
import { useTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import OtherPage from 'containers/dashboard/visitor/OtherPage';
import { openSnackbar } from 'components/common/bars/SnackBar';

const styles = (theme) => ({
  container: {
    margin: -2 * theme.spacing(),
    marginBottom: 0,
    '& hr': {
      margin: 2 * theme.spacing() + 'px 0',
    },
  },
  image: {
    width: '100%',
    display: 'block',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      marginBottom: 2 * theme.spacing(),
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '220px',
    },
    [theme.breakpoints.only('sm')]: {
      maxHeight: '240px',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '270px',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '310px',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '350px',
    },
  },
  imageTitle: {
    position: 'absolute', //These next 6 properties make the title floatable with background
    bottom: '40px',
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: theme.palette.primary.opacity09,
    padding: theme.spacing(),
    color: theme.palette.secondary.main,
    fontWeight: 300,
  },
  imageContainer: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      flex: 3,
    },
  },
  heading: {
    fontWeight: 300,
    fontSize: 20,
    padding: '0 ' + 2 * theme.spacing() + 'px',
    marginBottom: theme.spacing(),
  },
  firstRowLg: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  firstRowMd: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 4,
      marginTop: 2 * theme.spacing(),
    },
  },
  secondRow: {
    paddingBottom: 2 * theme.spacing(),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  news: {
    [theme.breakpoints.up('md')]: {
      flex: 3,
    },
  },
  places: {
    [theme.breakpoints.up('md')]: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  things: {
    [theme.breakpoints.up('md')]: {
      flex: 4,
    },
  },
  map: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      minHeight: 240,
      padding: '0 ' + 2 * theme.spacing() + 'px',
    },
  },
});

const mapInfoReducer = (state, action) => {
  const newState = { ...state };
  newState[action.type] = action.payload;
  return newState;
};

const desktopViewBreakpoints = ['md', 'lg', 'xl'];

const ExplorePage = ({
  classes,
  width,
  user,
  selectedVisit,
  handleChangeDashboardTabs,
  onVisitChange,
}) => {
  const [t] = useTranslation();
  const [image, setImage] = useState({});
  const [weather, setWeather] = useState({});
  const [news, setNews] = useState([]);
  const [mapInfo, dispatchMapInfo] = useReducer(mapInfoReducer, {});
  const [things, setThings] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [currentThing, setCurrentThing] = useState(null);
  const [moreNewsOpen, setMoreNewsOpen] = useState(false);
  const [moreThingsOpen, setMoreThingsOpen] = useState(false);
  const [visitCount, setVisitCount] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const lastFetch = useRef();

  const { center, coordinates } = mapInfo;

  const tenantId = selectedVisit
    ? selectedVisit.tenantId
    : user.tenantId || user.created_in;

  const desktopView = useMemo(() => {
    return desktopViewBreakpoints.includes(width);
  }, [width]);

  const newsIcon = useMemo(() => <AnnouncementIcon />, []);
  const thingsIcon = useMemo(() => <LandscapeIcon />, []);

  const openNews = useCallback(
    (news) => (event) => {
      setCurrentNews(news);
    },
    []
  );

  const closeNews = useCallback((event) => {
    setCurrentNews(null);
  }, []);

  const openThing = useCallback(
    (thing) => (event) => {
      setCurrentThing(thing);
    },
    []
  );

  const closeThing = useCallback((event) => {
    setCurrentThing(null);
  }, []);

  const openMoreNews = useCallback((event) => {
    setMoreNewsOpen(true);
  }, []);

  const closeMoreNews = useCallback((event) => {
    setMoreNewsOpen(false);
  }, []);

  const openMoreThings = useCallback((event) => {
    setMoreThingsOpen(true);
  }, []);

  const closeMoreThings = useCallback((event) => {
    setMoreThingsOpen(false);
  }, []);

  const renderNews = useMemo(() => {
    return news.map((n) => (
      <ExploreNewsCard key={n._id} news={n} onClick={openNews} />
    ));
  }, [news, openNews]);

  const renderThings = useMemo(() => {
    return things
      .map((i) => {
        return i.rating > 0 && i.reviews > 0 ? (
          <ExploreThingCard key={i._id} thing={i} onClick={openThing} />
        ) : null;
      })
      .filter((item) => item !== null);
  }, [things, openThing]);

  const renderMoreNews = useMemo(() => {
    return news.map((n) => (
      <ExploreNewsListItem key={n._id} news={n} onClick={openNews} />
    ));
  }, [news, openNews]);

  const renderMoreThings = useMemo(() => {
    return things.map((i) => (
      <>
        {(i.rating > 0 || i.reviews > 0) && (
          <ExploreThingListItem key={i._id} thing={i} onClick={openThing} />
        )}
      </>
    ));
  }, [things, openThing]);

  useEffect(() => {
    if (tenantId) {
      ConfigurationService.getConfigurationsForGroup(
        configuration_group.LOCATION,
        tenantId
      )
        .then((response) => {
          const name = response.find(
            (c) => c.key === configuration_location_type.NAME
          );
          const position = response.find(
            (c) => c.key === configuration_location_type.POSITION
          );

          setCompanyName(name.value);

          let payload;
          try {
            const coordinates = new Coordinates(position.value);
            payload = {
              lat: coordinates.getLatitude(),
              lng: coordinates.getLongitude(),
            };
          } catch (error) {
            console.log(error);
            payload = { lat: 0, lng: 0 };
          } finally {
            dispatchMapInfo({ type: 'center', payload: payload });
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  }, [tenantId]);

  useEffect(() => {
    if (tenantId) {
      VisitService.getTodaysVisits(tenantId)
        .then((response) => {
          setVisitCount(response.count);
        })
        .catch((error) => {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  }, [tenantId]);

  useEffect(() => {
    const now = new Date();
    lastFetch.current = now;

    if (tenantId) {
      //Reset the Explore page
      setLoaded(false);
      ExploreService.getItems(tenantId)
        .then((response) => {
          if (now !== lastFetch.current) {
            return;
          }

          if (!response.length) {
            return;
          }

          const news = [];
          const places = [];
          const things = [];

          response.forEach((item) => {
            switch (item.type) {
              case explore_types.TITLE_IMAGE:
                setImage({
                  src: DocumentService.api + (item.document || {}).downloadUrl,
                  alt: item.name,
                });
                break;
              case explore_types.WEATHER:
                setWeather(item);
                break;
              case explore_types.NEWS:
                news.push(item);
                break;
              case explore_types.PLACE:
                try {
                  const coordinates = new Coordinates(item.position);
                  const mapItem = {
                    position: {
                      lat: coordinates.getLatitude(),
                      lng: coordinates.getLongitude(),
                    },
                    title: item.name,
                  };
                  places.push(mapItem);
                } catch (err) {
                  console.log(err);
                }
                break;
              case explore_types.THING:
                things.push(item);
                break;
              default:
                break;
            }
          });

          setNews(news);
          dispatchMapInfo({ type: 'coordinates', payload: places });
          setThings(things);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  }, [tenantId]);

  return (
    loaded && (
      <>
        <div className={classes.container}>
          <div className={classes.firstRowLg}>
            <div className={classes.imageContainer}>
              <img className={classes.image} src={image.src} alt={image.alt} />
              <Typography
                className={classes.imageTitle}
                variant="subtitle1"
                align="center"
              >
                {t('explore_welcome', {
                  city: weather.name || '',
                  name: user.name || '',
                })}
              </Typography>
            </div>
            <div className={classes.firstRowMd}>
              <ExploreGrid
                weather={weather}
                handleChangeDashboardTabs={handleChangeDashboardTabs}
                visitCount={visitCount}
              />
              <Hidden mdUp>
                <Divider light />
              </Hidden>
              <div className={classes.news}>
                <Typography
                  className={classes.heading}
                  variant="h6"
                  component="h2"
                >
                  {t('explore_news', { company: companyName })}
                </Typography>
                <ExploreContainer
                  slideable={!desktopView}
                  desktopCount={2}
                  moreText={t('explore_more_articles')}
                  onMore={openMoreNews}
                  itemDataCy="explore-list-item-news"
                >
                  {renderNews}
                </ExploreContainer>
              </div>
            </div>
          </div>
          <Divider light />
          <div className={classes.secondRow}>
            <div className={classes.places}>
              <Typography
                className={classes.heading}
                variant="h6"
                component="h2"
              >
                {t('explore_places')}
              </Typography>
              <div className={classes.map}>
                {/*A weird bug with GMaps API causes component to not rerender when passing props so 
                we render when both needed props are loaded (they are fetched from 2 different APIs*/}
                {center && coordinates && (
                  <VmsGoogleMap
                    key={mapInfo}
                    coordinates={coordinates}
                    center={center}
                    zoom={15}
                    height={desktopView ? null : '160px'}
                  />
                )}
              </div>
            </div>
            <Hidden mdUp>
              <Divider light />
            </Hidden>
            <div className={classes.things}>
              <Typography
                className={classes.heading}
                variant="h6"
                component="h2"
              >
                {t('explore_things', { city: weather.name || '' })}
              </Typography>
              <ExploreContainer
                slideable={!desktopView}
                desktopCount={3}
                moreText={t('explore_more_things')}
                onMore={openMoreThings}
                itemDataCy="explore-list-item-thing"
              >
                {renderThings}
              </ExploreContainer>
            </div>
          </div>
          <Divider light />
          <Typography className={classes.heading} variant="h6" component="h2">
            {t('other')}
          </Typography>
          <OtherPage
            selectedVisit={selectedVisit}
            onVisitChange={onVisitChange}
          />
        </div>
        {currentNews && (
          <ExploreNewsRead news={currentNews} onClose={closeNews} />
        )}
        {currentThing && (
          <ExploreThingRead thing={currentThing} onClose={closeThing} />
        )}
        {moreNewsOpen && (
          <ExploreMoreContainer
            title={t('explore_more_articles')}
            icon={newsIcon}
            open={moreNewsOpen}
            onClose={closeMoreNews}
            itemDataCy="explore-more-list-item-news"
          >
            {renderMoreNews}
          </ExploreMoreContainer>
        )}
        {moreThingsOpen && (
          <ExploreMoreContainer
            title={t('explore_more_things')}
            icon={thingsIcon}
            open={moreThingsOpen}
            onClose={closeMoreThings}
            itemDataCy="explore-more-list-item-thing"
          >
            {renderMoreThings}
          </ExploreMoreContainer>
        )}
      </>
    )
  );
};

ExplorePage.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  user: PropTypes.shape({
    tenantId: PropTypes.string,
    createdIn: PropTypes.string,
  }).isRequired,
  selectedVisit: PropTypes.shape({
    tenantId: PropTypes.string.isRequired,
  }),
  handleChangeDashboardTabs: PropTypes.func.isRequired,
  onVisitChange: PropTypes.func.isRequired,
};

export default memo(compose(withWidth(), withStyles(styles))(ExplorePage));
