import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Business } from '@material-ui/icons';
import { openSnackbar, VmsInput } from 'components/common';
import { useEffect, useState } from 'react';
import { SecurityItemsService } from 'services';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    '&>*': {
      paddingBottom: theme.spacing(),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  button: {
    color: theme.palette.white,
  },
  codeInput: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
  },
  notActiveContainer: {
    padding: `${theme.spacing()}px 0 0`,
    color: theme.palette.primary4,
  },
}));

const getTime = (date) => {
  return moment(date).valueOf();
};

const QRActivationRow = ({ securityItem, setQrCodeActive }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const now = moment();

  const activationAvailableToday =
    now.isSameOrAfter(moment(securityItem.validFrom)) &&
    now.isSameOrBefore(moment(securityItem.validTo));

  const { confirmedUses, authentication } = securityItem;
  const {
    nextAttemptAvailable,
    itemValidUntil,
    sendCodeAllowed: sendAllowed,
  } = authentication || {};
  const nextAttemptAvailableNormed = nextAttemptAvailable
    ? moment(nextAttemptAvailable)
    : now.clone();
  const itemValidUntilNormed = itemValidUntil
    ? moment(itemValidUntil)
    : now.clone();

  const [code, setCode] = useState('');

  const {
    hours,
    minutes,
    seconds,
    isRunning: isRunningNextAttempt,
    restart: restartNextAttempt,
  } = useTimer({
    expiryTimestamp: getTime(nextAttemptAvailableNormed),
    autoStart: moment().isBefore(moment(nextAttemptAvailableNormed)),
  });
  const secondsTotal = hours * 60 * 60 + minutes * 60 + seconds;

  const { isRunning: isRunningItemValid, restart: restartItemValid } = useTimer(
    {
      expiryTimestamp: getTime(itemValidUntilNormed),
      autoStart: moment().isBefore(moment(itemValidUntilNormed)),
    }
  );

  const [sendCodeAllowed, setSendCodeAllowed] = useState(sendAllowed);

  const sendCode = async () => {
    try {
      const response = await SecurityItemsService.requestAuthenticationCode(
        securityItem._id
      );
      restartNextAttempt(getTime(response.nextAttemptAvailable));
      setSendCodeAllowed(response.sendCodeAllowed);
      openSnackbar(t('visit_activation_code_sent'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  const activate = async () => {
    try {
      const response = await SecurityItemsService.authenticateItem(
        securityItem._id,
        code.trim()
      );
      const validUntil = getTime(response.itemValidUntil);

      restartItemValid(validUntil);
      setCode('');

      const timeLeft = Math.round((validUntil - Date.now()) / (60 * 1000));
      openSnackbar(t('visit_activation_code_activated', { timeLeft }));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  useEffect(() => {
    setQrCodeActive(isRunningItemValid);
  }, [setQrCodeActive, isRunningItemValid]);

  return (
    !isRunningItemValid &&
    !confirmedUses && (
      <Grid item container xs={12} className={classes.root}>
        {!activationAvailableToday && (
          <Grid item xs={12} className={classes.notActiveContainer}>
            <Typography variant="body1" noWrap>
              {t('visit_activation_code_not_available')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Button
            data-cy="visit-card-button-send-code"
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={sendCode}
            disabled={
              isRunningNextAttempt ||
              !sendCodeAllowed ||
              !activationAvailableToday
            }
          >
            {!isRunningNextAttempt && !sendCodeAllowed
              ? t('visit_activation_code_contact_administrator')
              : `${t('visit_activation_code_send')}${
                  isRunningNextAttempt ? ' (' + secondsTotal + ')' : ''
                }`}
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <VmsInput
            rowClass={classes.codeInput}
            iconLeft={<Business />}
            textValue={code}
            inputName="code"
            placeholder={t('visit_activation_code')}
            inputLabel={t('visit_activation_code')}
            onChange={(e) => setCode(e.target.value)}
            disabled={!activationAvailableToday}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            data-cy="visit-card-button-activate-qr"
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={activate}
            disabled={!activationAvailableToday}
          >
            {t('visit_activation_code_activate')}
          </Button>
        </Grid>
      </Grid>
    )
  );
};

export default QRActivationRow;
