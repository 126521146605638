import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { vms_application_routes } from 'AppSettings';
import SecurityItemsService from 'services/SecurityItemsService';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import util from 'util';

const styles = (theme) => ({
  dialogSize: {
    height: 600 + theme.spacing(7),
    width: 600,
    overflow: 'hidden',
  },
  resultGrid: {
    padding: theme.spacing(2),
  },
  resultFont: {
    color: theme.palette.primary4,
  },
  cameraSwitch: {
    top: 600 - theme.spacing(6),
    right: theme.spacing(),
    position: 'absolute',
    zIndex: 1,
    color: theme.palette.white,
  },
});

class QrDialogReader extends Component {
  constructor() {
    super();
    this.userId = null;
    this.visitId = null;
  }

  callUnlockDoor = (doorUrl, securityItemId) => {
    setTimeout(() => {
      window.location = util.format(
        vms_application_routes.UNLOCK_DOOR,
        doorUrl,
        securityItemId
      );
    }, 50);
  };

  callActivity = (enabled) => {
    console.log('callActivity' + enabled);
    setTimeout(() => {
      window.location = vms_application_routes.ACTIVITY + enabled;
    }, 50);
  };

  componentDidMount() {
    window.codeReadingFinished = (securityItem) => {
      this.userId = null;
      this.visitId = null;
      if (securityItem === '###CLOSED_QR_READER###') {
        this.props.qrDialogData.isSearch = false;
        this.props.qrDialogData.openQRDialog = false;
        this.onChange();
        return;
      }
      // this.callActivity(true); This is done automatically in native code, to avoid multiple window.location changes
      const onCompleted = this.props.onCompleted;
      this.props.qrDialogData.isSearch = true;
      const validateUser = this.props.validateUser;
      let rejectUsed = this.props.rejectUsed;
      this.onChange();
      SecurityItemsService.validate(securityItem)
        .then((response) => {
          if (
            response.hasOwnProperty('name') &&
            (rejectUsed === false ||
              (rejectUsed === true && response.used === false)) &&
            (!validateUser || validateUser(response.userId))
          ) {
            this.userId = response.userId;
            this.visitId = response.visitId;
            this.props.qrDialogData.QRResult = util.format(
              this.props.successMessage,
              response.name
            );
            this.callUnlockDoor(
              this.props.qrDialogData.user.lockUrl,
              securityItem
            );
            // openSnackbar after opening the lock
          } else {
            this.props.qrDialogData.QRResult = util.format(
              '%s\n(%s)',
              i18n.t('snackbar_entry_failure'),
              response.name
            );
            this.callActivity(false);
            if (onCompleted) onCompleted(false);
            openSnackbar(
              this.props.qrDialogData.QRResult,
              null,
              null,
              this.props.snackBarVariant
            );
          }
          this.props.qrDialogData.isSearch = false;
          this.props.qrDialogData.openQRDialog = false;
          this.onChange();
        })
        .catch((error) => {
          this.callActivity(false);
          this.props.qrDialogData.QRResult = i18n.t('snackbar_entry_failure');
          this.props.qrDialogData.isSearch = false;
          this.props.qrDialogData.openQRDialog = false;
          openSnackbar(
            this.props.qrDialogData.QRResult,
            null,
            null,
            this.props.snackBarVariant
          );
          if (onCompleted) onCompleted(false);
          this.onChange();
        });
    };

    /**
     * Global function
     */
    window.doorUnlockedResult = (result, securityItem) => {
      this.callActivity(false);
      if (result !== 'unlock_ok') {
        if (result === 'unlock_failed') {
          result = i18n.t('unlock_error');
        }
        openSnackbar(result, null, null, this.props.snackBarVariant);
        if (this.props.onCompleted) this.props.onCompleted(false);
      } else {
        openSnackbar(
          this.props.qrDialogData.QRResult,
          null,
          null,
          this.props.snackBarVariant
        );
        if (!this.props.validateOnly) {
          SecurityItemsService.increaseUse(securityItem);
        }
        if (this.props.onCompleted)
          this.props.onCompleted(true, this.userId, this.visitId);
      }
    };
  }

  onChange = () => {
    const { qrDialogData, onChange } = this.props;
    onChange(qrDialogData);
  };

  render() {
    return null;
  }
}

QrDialogReader.propTypes = {
  classes: PropTypes.object.isRequired,
  rejectUsed: PropTypes.bool.isRequired, // Deny access for already used securityItems during validity period
  validateUser: PropTypes.func, // If provided validate user id in case security item is valid for unlocking (e.g. user is in meeting)
  onCompleted: PropTypes.func, // if provided will be called when QR code evaluation is finished
  successMessage: PropTypes.string.isRequired,
  snackBarVariant: PropTypes.string, // Provide 'big' for big snackbar
  validateOnly: PropTypes.bool, // Whether to only validate the QR code and not increment its use
};

export default withStyles(styles)(QrDialogReader);
