import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { photo_accepted_mime_types } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import { VmsDialogRowDragDrop, VmsDialogRowFilePreview } from 'components';
import { DocumentService } from 'services';

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const VmsDialogDragDropImage = ({
  name,
  imageState,
  document,
  onEdit,
  onDrop,
  onDropAccepted,
  onDropRejected,
}) => {
  const classes = useStyles();

  const { files, fileStatus, uploading, base64, editExisting } = imageState;
  const { downloadUrl } = document || {};

  return (
    <>
      {base64 && (
        <VmsDialogRowFilePreview
          iconLeft={<AttachFile className={classes.required} />}
          rowClass={classes.dialogRow}
          previewComponent={
            <img
              src={base64}
              className={classes.imagePreview}
              alt={name ?? ''}
            />
          }
          downloadUrl={base64}
          editMode
          onEditClick={onEdit}
        />
      )}
      {downloadUrl && !base64 && !editExisting && (
        <VmsDialogRowFilePreview
          iconLeft={<AttachFile className={classes.required} />}
          rowClass={classes.dialogRow}
          previewComponent={
            <img
              src={DocumentService.api + downloadUrl}
              className={classes.imagePreview}
              alt={name}
            />
          }
          downloadUrl={DocumentService.api + downloadUrl}
          editMode
          onEditClick={onEdit}
        />
      )}
      {(editExisting || (!base64 && !downloadUrl)) && (
        <VmsDialogRowDragDrop
          icon={<AttachFile className={classes.required} />}
          rowClass={classes.dialogRow}
          files={files}
          fileStatus={fileStatus}
          document_accepted_mime_types={photo_accepted_mime_types}
          uploading={uploading}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          onDrop={onDrop}
        />
      )}
    </>
  );
};

VmsDialogDragDropImage.propTypes = {
  name: PropTypes.string,
  imageState: PropTypes.object.isRequired,
  document: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDropAccepted: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
};

export default VmsDialogDragDropImage;
