import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Paper, Modal, Hidden, Fab, Grid } from '@material-ui/core';
import { VmsDialogHeader } from './header';
import { VmsDialogFooter } from './footer';
import Delete from '@material-ui/icons/Delete';
import { elevation } from '../../../assets/UISettings';
import classNames from 'classnames';
import modalStyle from 'assets/jss/modalStyle';
import commonStyles from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';
import { LoadingBackdrop, useCloseDialogWithLoading } from 'components';

const useStyles = makeStyles((theme) => ({
  ...modalStyle(theme, 448),
  ...commonStyles(theme),
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabDelete: {
    right: theme.spacing(11),
  },
  cardContainer: {
    margin: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  children: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
  },
  columnLayout: {
    flexDirection: 'column',
  },
}));

const VmsDialog = (props) => {
  const {
    open,
    xsPaperMode,
    onClose,
    loading,
    width,
    closeButtonDataCy,
    buttonDataCy,
    buttonSendDataCy,
    buttonDeleteDataCy,
    buttonAction,
    buttonSendAction,
    buttonDeleteAction,
    buttonConfirmAction,
  } = props;

  const classes = useStyles();
  const closeIfNotLoading = useCloseDialogWithLoading(onClose, loading);

  const dialog = (
    <Modal className={classes.modal} open={open} onClose={closeIfNotLoading}>
      <Paper
        style={{ maxWidth: width }}
        className={classNames(classes.paper, classes.root)}
        elevation={elevation.light}
      >
        <VmsDialogHeader
          title={props.dialogHeaderTitle}
          onClose={closeIfNotLoading}
          icon={props.dialogHeaderIcon}
          closeButtonDataCy={closeButtonDataCy}
        />
        <div className={classes.children}>
          <Grid className={classes.columnLayout} container direction="row">
            {props.children}
          </Grid>
        </div>
        {(buttonAction || buttonSendAction || buttonDeleteAction) && (
          <VmsDialogFooter
            buttonText={props.buttonText}
            onSave={buttonAction}
            saveDisabled={props.buttonDisabled}
            onSend={buttonSendAction}
            sendDisabled={props.buttonSendDisabled}
            onDelete={buttonDeleteAction}
            deleteDisabled={props.buttonDeleteDisabled}
            deleteText={props.buttonDeleteText}
            onConfirm={buttonConfirmAction}
            confirmText={props.buttonConfirmText}
            confirmDisabled={props.buttonConfirmDisabled}
            buttonSaveDataCy={buttonDataCy}
            buttonSendDataCy={buttonSendDataCy}
            buttonDeleteDataCy={buttonDeleteDataCy}
          />
        )}
        <LoadingBackdrop loading={loading} />
      </Paper>
    </Modal>
  );

  return (
    <div>
      {xsPaperMode ? <Hidden xsDown>{dialog}</Hidden> : <Aux>{dialog}</Aux>}
      {xsPaperMode && open && (
        <Hidden smUp>
          <Paper className={classes.cardContainer} elevation={elevation.light}>
            {props.children}
            <LoadingBackdrop loading={loading} />
          </Paper>
          {props.buttonDeleteAction && (
            <Fab
              data-cy={buttonDeleteDataCy}
              color="primary"
              className={classNames(
                classes.fab,
                classes.button,
                classes.fabDelete
              )}
              onClick={props.buttonDeleteAction}
              disabled={props.buttonDeleteDisabled}
            >
              <Delete />
            </Fab>
          )}
          <Fab
            data-cy={buttonDataCy}
            color="primary"
            className={classNames(classes.fab, classes.button)}
            onClick={props.buttonAction}
            disabled={props.buttonDisabled}
          >
            {props.buttonIcon}
          </Fab>
        </Hidden>
      )}
    </div>
  );
};

VmsDialog.propTypes = {
  dialogHeaderTitle: PropTypes.string,
  dialogHeaderIcon: PropTypes.element.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.element.isRequired,
  buttonAction: PropTypes.func,
  buttonSendAction: PropTypes.func,
  xsPaperMode: PropTypes.bool, // This is special mode that paper instead of dialog is used on some pages where its better
  width: PropTypes.number, // Override paper max-width
  buttonDeleteAction: PropTypes.func,
  buttonDeleteText: PropTypes.string,
  buttonDeleteDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  closeButtonDataCy: PropTypes.string,
  buttonDataCy: PropTypes.string,
  buttonSendDataCy: PropTypes.string,
  buttonDeleteDataCy: PropTypes.string,
};

const skipUpdate = (prevProps, nextProps) => {
  const open = prevProps.open || nextProps.open;
  return !open;
};

export default memo(VmsDialog, skipUpdate);
