import PropTypes from 'prop-types';
import {
  makeStyles,
  Grid,
  Paper,
  IconButton,
  Modal,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { FileCopy, GetApp, Publish } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import {
  CustomEditorButton,
  LoadingBackdrop,
  openSnackbar,
  useCloseDialogWithLoading,
  useImageUpload,
} from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { elevation } from 'assets/UISettings';
import { language_mapping } from 'AppSettings';
import { DocumentService, TemplateService } from 'services';
import { Editor } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import VmsDialogDragDropImage from 'containers/VmsDialogDragDropImage';
import { useFileUpload } from 'use-file-upload';

const useStyles = makeStyles((theme) => ({
  ...modalStyle(theme, 820),
  ...commonStyles(theme),
  customPaper: {
    overflow: 'hidden',
    display: 'flex',
  },
  content: {
    overflow: 'auto',
    flex: 1,
  },
  header: {
    height: 56,
    backgroundColor: theme.palette.primary.main,
  },
  titleHeaderInput: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
  },
  buttonHeader: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
  editorInputToolbar: {
    padding: theme.spacing(),
  },
  formControl: {
    minWidth: 120,
  },
  editorInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #f3f3f3',
    fontSize: 14,
    padding: '4px 16px',
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  fullWidth: {
    flex: 1,
  },
  flag: {
    width: 36,
    height: 24,
  },
  editor: {
    height: '30vh !important', // Must override internal 100%
    padding: theme.spacing(2),
    border: '1px solid #f3f3f3',
  },
  editorHTMLInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    '& textarea': {
      padding: '4px 16px',
    },
  },
  editorHTMLInputWrapper: {
    fontSize: 14,
  },
  buttonSave: {
    margin: theme.spacing(2),
    color: theme.palette.common.white,
  },
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const InspectionEditDialog = ({
  open,
  activity,
  inspectionTypes,
  onClose,
  onChange,
  onSave,
  loading,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [saving, setSaving] = useState(false);

  const {
    imageState,
    upload,
    onNew,
    onEdit,
    onDrop,
    onDropAccepted,
    onDropRejected,
  } = useImageUpload(activity?.name, 'inspection', '', true);
  const { uploading, files, base64, editExisting } = imageState;

  const allLoadings = uploading || loading || saving;
  const close = () => {
    onNew();
    onClose();
  };
  const closeIfNotLoading = useCloseDialogWithLoading(close, allLoadings);

  const { name, lang, type, editor, text, document } = activity || {};
  const { downloadUrl, _id: documentId } = document || {};

  // eslint-disable-next-line no-unused-vars
  const [_, uploadFileAsTemplate] = useFileUpload();

  const downloadTemplate = () => {
    const html = TemplateService.draftToHtml(editor.getCurrentContent());
    const blob = new Blob([html], {
      type: 'text/html',
    });
    const url = URL.createObjectURL(blob);
    const a = window.document.createElement('a');
    a.href = url;
    a.download = `${name || 'unknown'}.html`;
    a.click();
    setTimeout(() => URL.revokeObjectURL(url));
  };

  const uploadTemplate = () => {
    uploadFileAsTemplate({ accept: 'text/html' }, async ({ file }) => {
      const template = await file.text();
      onChange('editor', TemplateService.createEditorFromHtml(template))();
    });
  };

  const save = async () => {
    setSaving(true);
    try {
      //Copy is used so that we dont upload the same doc again in case the save fails
      const copy = { ...activity };
      if (downloadUrl && base64) {
        await DocumentService.deleteDocument(documentId);
        copy.document = {};
        onChange(document, {});
      }
      if (!copy.document._id) {
        const response = await upload();
        if (response) {
          onChange(document, { _id: response._id });
          onSave(response._id);
        }
      } else {
        onSave();
      }
      onNew();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal className={classes.modal} open={open} onClose={closeIfNotLoading}>
      <Paper
        className={classNames(classes.paper, classes.customPaper)}
        elevation={elevation.light}
      >
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Grid item className={classes.fullWidth}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <FileCopy className={classes.icon} />
                </Grid>
                <Grid item className={classes.fullWidth}>
                  <TextField
                    name="name"
                    onChange={onChange('name')}
                    value={name || ''}
                    placeholder={t('inspection_name')}
                    id="inspection-activity-name"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.titleHeaderInput,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.buttonHeader}
                aria-label="Close"
                onClick={closeIfNotLoading}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container className={classes.content}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.editorInputToolbar}
            >
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={type || ''}
                      placeholder={t('inspection_type')}
                      onChange={onChange('type')}
                      inputProps={{
                        name: 'type',
                        className: classes.editorInput,
                      }}
                    >
                      {inspectionTypes &&
                        inspectionTypes.map((inspectionType) => (
                          <MenuItem
                            key={inspectionType}
                            selected={inspectionType === type}
                            value={inspectionType}
                          >
                            {inspectionType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <FormControl>
                    <Select
                      value={lang || ''}
                      onChange={onChange('lang')}
                      displayEmpty
                      inputProps={{
                        name: 'lang',
                        className: classes.editorInput,
                      }}
                    >
                      {Object.keys(language_mapping).map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          <img
                            className={classes.flag}
                            src={language_mapping[lang]}
                            alt={t(`menu_language_${lang}`)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Editor
                editorState={editor}
                wrapperClassName={classes.textEditor}
                editorClassName={classes.editor}
                placeholder={t('template_placeholder')}
                onEditorStateChange={(editor) => onChange('editor', editor)}
                toolbarCustomButtons={[
                  <CustomEditorButton
                    title="Download template"
                    onClick={downloadTemplate}
                    Icon={GetApp}
                  />,
                  <CustomEditorButton
                    title="Upload template"
                    onClick={uploadTemplate}
                    Icon={Publish}
                  />,
                ]}
                customBlockRenderFunc={TemplateService.customBlockRenderFunc}
              />
            </Grid>
            <VmsDialogDragDropImage
              name={activity?.name}
              imageState={imageState}
              document={document}
              onEdit={onEdit}
              onDrop={onDrop}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonSave}
              onClick={save}
              disabled={
                !name ||
                !text ||
                !type ||
                !lang ||
                !((downloadUrl && !editExisting) || files.length)
              }
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
        <LoadingBackdrop loading={allLoadings} />
      </Paper>
    </Modal>
  );
};

InspectionEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  activity: PropTypes.object,
  inspectionTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InspectionEditDialog;
