import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import Send from '@material-ui/icons/Send';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import DoneAll from '@material-ui/icons/DoneAll';
import Done from '@material-ui/icons/Done';
import { VmsDialog, VmsInput } from 'components';
import i18n from 'assets/i18n';
import commonStyles from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  ...commonStyles(theme),
});

const QuestionnaireDialog = (props) => {
  const {
    classes,
    open,
    totalQuestions,
    totalAnsweredQuestions,
    totalCorrectAnswers,
    sendAnswers,
    onClose,
    resetQuestionnaire,
  } = props;
  const onChangeInput = () => (event) => {};
  const correct = totalQuestions === totalCorrectAnswers;
  return (
    <Aux>
      {open && (
        <Aux>
          <VmsDialog
            open={open}
            dialogHeaderTitle={i18n.t('document_questionnaire_summarize')}
            dialogHeaderIcon={<PlaylistAdd />}
            closeButtonDataCy="questionnaire-button-close"
            buttonText={correct ? i18n.t('send') : i18n.t('try_again')}
            buttonIcon={<Send />}
            buttonAction={correct ? sendAnswers : resetQuestionnaire}
            buttonDisabled={false}
            buttonDataCy="questionnaire-button-send"
            onClose={onClose}
          >
            <Grid
              container
              className={classes.rowContent2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <VmsInput
                rowClass={classes.dialogRow}
                iconLeft={<QuestionAnswer />}
                textValue={
                  i18n.t('document_questionnaire_questions') +
                  ' ' +
                  totalQuestions
                }
                inputName="question_count"
                onChange={onChangeInput}
              />
            </Grid>
            <Grid
              container
              className={classes.rowContent2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <VmsInput
                rowClass={classes.dialogRow}
                iconLeft={<DoneAll />}
                textValue={
                  i18n.t('document_questionnaire_answers') +
                  ' ' +
                  totalAnsweredQuestions
                }
                inputName="answers_count"
                onChange={onChangeInput}
              />
            </Grid>
            <Grid
              container
              className={classes.rowContent2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <VmsInput
                rowClass={classes.dialogRow}
                iconLeft={<Done />}
                textValue={
                  i18n.t('document_questionnaire_correct_answers') +
                  ' ' +
                  totalCorrectAnswers
                }
                inputName="correct_count"
                onChange={onChangeInput}
              />
            </Grid>
          </VmsDialog>
        </Aux>
      )}
    </Aux>
  );
};
QuestionnaireDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  totalAnsweredQuestions: PropTypes.number.isRequired,
  totalCorrectAnswers: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuestionnaireDialog);
