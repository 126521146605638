import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { TypeDialog } from 'components';
import { openSnackbar } from 'components/common/bars/SnackBar';
import {
  ExportService,
  DocumentService,
  QuestionService,
  ConfigurationService,
  TemplateService,
  FeedbackService,
  InspectionService,
} from 'services';
import ConfigurationModel from 'services/models/ConfigurationModel';
import i18n from 'assets/i18n';
import tableStyles from 'assets/jss/tableStyles';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import { emptySettingSearchState } from '../SettingsPage';
import { type_constants, types, routes, delimiter } from 'AppSettings';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { handleOpenConfirmDialog } from '../../../components/common/dialogs/ConfirmDialog';
import produce from 'immer';

const styles = (theme) => ({
  ...tableStyles(theme),
});

class TypesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configurations: [],
      menuAnchorEl: {},
      currentItem: {},
      editItem: {
        name: '',
        type: '',
        mixedType: {
          type: '',
          documents: [],
          inspectionActivities: [],
          accompanimentRequired: false,
          authenticationRequired: false,
          confirmationRequired: false,
          useForSelfRegistration: false,
          signInSelfRegistration: false,
        },
        required_for_entrance: false,
        expires_in: '',
      },
      dialogOpen: false,
      dialogEditMode: false,
      tableHeaderState: createTableHeaderState([]),
      highlightedId: '',
      documents: {
        documents: [],
        questionnaires: [],
        signed: [],
        feedback: [],
      },
      inspectionActivityTypes: { key: [], value: [] },
    };

    this.tableHeader = null;
  }

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  componentDidMount() {
    this.loadData();
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadInspectionActivityTypes();
    this.setSearchSettings();
    this.loadUserDocuments();
    this.loadUserQuestionnaires();
    this.loadUserFeedback();
    this.loadUserSignedDocuments();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_TYPES) this.handleNewClick();
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService = (searchValue) =>
        ConfigurationService.suggestConfigurationsForGroup(
          searchValue,
          'types'
        );
      settingSearchState.onSuggestionsClearRequested =
        this.handleConfigurationSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleConfigurationSuggestionSelected;
      settingSearchState.onChangeSearch =
        this.handleConfigurationSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        const val =
          typeof suggestion.value === 'object'
            ? suggestion.value.type
            : suggestion.value;
        return (
          suggestion.key?.split(delimiter)[0] +
          ', ' +
          i18n.t(`app_settings_type_${val}`)
        );
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleConfigurationSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleConfigurationSuggestionSelected = (suggestion) => (event) => {
    console.log(suggestion);
    if (suggestion) {
      this.highlightSuggestConfiguration(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      const val =
        typeof suggestion.value === 'object'
          ? suggestion.value.type
          : suggestion.value;
      settingSearchState.searchValue =
        suggestion.key + ', ' + i18n.t(`app_settings_type_${val}`);
      onChangeSearch(settingSearchState);
    }
  };

  handleConfigurationSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (
      field === 'searchSettings' &&
      this.state.visitorSuggestionValue !== fieldValue
    ) {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestConfiguration = (_id) => {
    const { configurations, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = configurations.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  loadData = async () => {
    try {
      const results = await ConfigurationService.getConfigurationsForGroup(
        'types'
      );
      const configs = results.map((result) =>
        ConfigurationModel.fromJson(result).getDataForSettingsPage()
      );

      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.currentItem = {};
          draft.configurations = configs;
          draft.tableHeaderState.data = configs;
        })
      );
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  loadInspectionActivityTypes = async () => {
    try {
      const result = await InspectionService.getDistinctActivityTypes();
      const inspectionActivityTypes = { key: [], value: [] };
      result.forEach((res) => {
        inspectionActivityTypes.key.push(res);
        inspectionActivityTypes.value.push(res);
      });
      this.setState({ inspectionActivityTypes });
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    this.handleMenuClose('tableMenu')();
    handleOpenConfirmDialog(
      i18n.t('settings_type_dialog_remove_selected_title'),
      i18n.t('settings_type_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => (event) => {
    const { configurations, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = configurations;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = configurations.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      ConfigurationService.deleteConfiguration(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            self.loadData();
            self.setState(function (prevState) {
              prevState.configurations = array;
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadData();
        });
    });
  };

  handleNewClick = () => {
    this.setState({
      dialogOpen: true,
      dialogEditMode: false,
      currentItem: ConfigurationModel.getEmptyDataForSettingsPage(),
      editItem: {
        name: '',
        type: '',
        mixedType: {
          type: '',
          documents: [],
          inspectionActivities: [],
          accompanimentRequired: false,
          authenticationRequired: false,
          confirmationRequired: false,
          useForSelfRegistration: false,
          signInSelfRegistration: false,
        },
      },
    });
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleDialogOpen = (item) => (event) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.dialogOpen = true;
        draft.dialogEditMode = true;
        draft.menuAnchorEl = {};
        draft.currentItem = item;

        draft.editItem.name = item.name;
        draft.editItem.required_for_entrance = item.required_for_entrance;
        draft.editItem.expires_in = item.expires_in;
        draft.editItem.random_order_of_questions =
          item.random_order_of_questions;
        draft.editItem.number_of_questions = item.number_of_questions;
        if (item.type.hasOwnProperty('type')) {
          draft.editItem.type = item.type.type;
          draft.editItem.mixedType.type = item.type.type;
          draft.editItem.mixedType.documents = item.type.documents.map(
            (doc) => {
              return doc.hasOwnProperty('data') ? doc : JSON.parse(doc);
            }
          );
          draft.editItem.mixedType.inspectionActivities =
            item.type.inspectionActivities;
          draft.editItem.mixedType.accompanimentRequired =
            item.type.accompanimentRequired;
          draft.editItem.mixedType.authenticationRequired =
            item.type.authenticationRequired;
          draft.editItem.mixedType.confirmationRequired =
            item.type.confirmationRequired;
          draft.editItem.mixedType.useForSelfRegistration =
            item.type.useForSelfRegistration;
          draft.editItem.mixedType.signInSelfRegistration =
            item.type.signInSelfRegistration;
        } else {
          draft.editItem.type = item.type;
        }
      })
    );
  };

  handleDialogOnClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleDialogOnSave = async () => {
    const { bus } = this.props;
    const originalData = this.state.currentItem.originalData;
    const { editItem } = this.state;
    let newValues = { name: editItem.name, type: editItem.type };
    let visitorTypeChanged = false;
    if (types[editItem.type] === types.visitor_type) {
      newValues.type = editItem.mixedType;
      visitorTypeChanged = true;
    } else if (types[editItem.type] === types.questionnaire) {
      newValues.required_for_entrance = editItem.required_for_entrance;
      newValues.expires_in = editItem.expires_in;
      newValues.number_of_questions = editItem.number_of_questions;
      newValues.random_order_of_questions = editItem.random_order_of_questions;
    } else if (
      types[editItem.type] === types.document ||
      types[editItem.type] === types.signed_document
    ) {
      newValues.required_for_entrance = editItem.required_for_entrance;
      newValues.expires_in = editItem.expires_in;
    }
    try {
      if (originalData) {
        const updateConfig = ConfigurationModel.getSettingsPageUpdateBody(
          newValues,
          originalData.isTypeConfiguration
        );
        await ConfigurationService.update(originalData._id, updateConfig);
      } else {
        const newConfig = ConfigurationModel.fromSettingsPageData(newValues);
        await ConfigurationService.create(newConfig);
      }
      this.handleDialogOnClose(); // Close dialog
      openSnackbar(i18n.t('saved'));
      this.loadData(); // Reload data

      //Reload types for InviteVisitor
      if (visitorTypeChanged) {
        bus.emit('handleReloadData', routes.VISITORS);
      }
    } catch (err) {
      if (err.code === 11000) {
        openSnackbar(i18n.t('duplicate_name'));
      } else {
        openSnackbar(err.message);
      }
    }
  };

  handleDialogOnChange = (name, value, index) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        if (field === 'documents') {
          if (
            !draft.editItem.mixedType.documents.find(
              (doc) =>
                doc.data.name === fieldValue.data.name &&
                doc.type === fieldValue.type
            )
          ) {
            draft.editItem.mixedType.documents.push(fieldValue);
            draft.menuAnchorEl.documentsMenuOpen = null;
          }
        } else if (field === 'documents_remove') {
          draft.editItem.mixedType.documents.splice(index, 1);
        } else if (field === 'inspection_type') {
          if (
            !draft.editItem.mixedType.inspectionActivities.find(
              (activity) => activity === fieldValue
            )
          ) {
            draft.editItem.mixedType.inspectionActivities.push(fieldValue);
          }
        } else if (field === 'inspection_type_remove') {
          draft.editItem.mixedType.inspectionActivities.splice(index, 1);
        } else if (field === 'accompanimentRequired') {
          draft.editItem.mixedType.accompanimentRequired = fieldValue;
        } else if (field === 'authenticationRequired') {
          draft.editItem.mixedType.authenticationRequired = fieldValue;
        } else if (field === 'confirmationRequired') {
          draft.editItem.mixedType.confirmationRequired = fieldValue;
        } else if (field === 'useForSelfRegistration') {
          draft.editItem.mixedType.useForSelfRegistration = fieldValue;
        } else if (field === 'signInSelfRegistration') {
          draft.editItem.mixedType.signInSelfRegistration = fieldValue;
        } else {
          draft.editItem[field] = fieldValue;
          if (field === 'type') {
            draft.editItem.mixedType.type = fieldValue;
          }
        }
      })
    );
  };

  handleDeleteConfigurationOpenDialog = (item) => (event) => {
    this.handleMenuClose('tableMenu')();
    handleOpenConfirmDialog(
      i18n.t('settings_type_dialog_remove_title'),
      i18n.t('settings_type_dialog_remove_description'),
      this.handleDeleteConfiguration(item)
    );
  };

  handleDeleteConfiguration = (item) => (event) => {
    const self = this;
    ConfigurationService.deleteConfiguration(item.originalData._id)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.loadData();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
    this.setState({
      menuAnchorEl: {},
      currentItem: ConfigurationModel.getEmptyDataForSettingsPage(),
    });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item.originalData);
  };

  loadUserDocuments = () => {
    const self = this;
    const { documents } = this.state;
    DocumentService.getDistinctTypes()
      .then(function (doc) {
        let userDocuments = [];

        doc.map((d) => {
          let data = {
            data: {
              name: d.name,
              required_for_entrance: d.required_for_entrance,
            },
            document_id: d.name,
            type: type_constants.DOCUMENT,
          };
          userDocuments.push(data);
          return userDocuments;
        });
        documents.documents = userDocuments;
        self.setState({
          documents,
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documents.documents = [];
        self.setState({
          documents,
        });
      });
  };

  loadUserQuestionnaires = () => {
    const self = this;
    const { documents } = this.state;
    QuestionService.getDistinctTypes()
      .then(function (types) {
        let userDocuments = [];
        types.map((type) => {
          let data = {
            data: {
              name: type.name,
              required_for_entrance: type.required_for_entrance,
            },
            document_id: type.name,
            type: type_constants.QUESTIONNAIRE,
          };
          userDocuments.push(data);
          return userDocuments;
        });
        documents.questionnaires = userDocuments;
        self.setState({
          documents,
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documents.questionnaires = [];
        self.setState({
          documents,
        });
      });
  };

  loadUserFeedback = () => {
    const { documents } = this.state;
    FeedbackService.getDistinctTypes()
      .then((types) => {
        const userDocuments = [];
        types.forEach((type) => {
          const data = {
            data: { name: type },
            document_id: type,
            type: type_constants.FEEDBACK,
          };
          userDocuments.push(data);
        });
        documents.feedback = userDocuments;
        this.setState({
          documents,
        });
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err.message);
        documents.feedback = [];
        this.setState({
          documents,
        });
      });
  };

  loadUserSignedDocuments = () => {
    const self = this;
    const { documents } = this.state;
    TemplateService.getDistinctTypesForSignedDocuments()
      .then(function (templates) {
        let userDocuments = [];
        templates.map((t) => {
          let data = {
            data: {
              name: t.name,
              required_for_entrance: t.required_for_entrance,
            },
            document_id: t.name,
            type: type_constants.SIGNED,
          };
          userDocuments.push(data);
          return userDocuments;
        });
        documents.signed = userDocuments;
        self.setState({
          documents,
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documents.signed = [];
        self.setState({
          documents,
        });
      });
  };

  handleMenuOpen = (menu, item) => (event) => {
    let target = event.currentTarget;
    this.setState(function (prevState) {
      prevState.menuAnchorEl[menu] = target;
      if (item) {
        prevState.currentItem = item;
      }
      return prevState;
    });
  };

  handleMenuClose = (menu) => (event) => {
    this.setState(function (prevState) {
      prevState.menuAnchorEl[menu] = null;
      prevState.currentItem = {};
      return prevState;
    });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  render() {
    const { classes } = this.props;
    const {
      configurations,
      menuAnchorEl,
      currentItem,
      dialogOpen,
      editItem,
      dialogEditMode,
      tableHeaderState,
      highlightedId,
      documents,
      inspectionActivityTypes,
    } = this.state;
    const { tableMenu } = menuAnchorEl;

    const { rowsPerPage, page, selected } = tableHeaderState;
    const visibleItems = configurations.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // tells if current item is type configuration or general configuration
    const currentItemIsTypeConfig = currentItem.originalData
      ? currentItem.originalData.isTypeConfiguration
      : true;

    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                exportData={ExportService.configurations(selected)}
                exportDataFilename={'types.csv'}
                limitedView={false}
                prevPageButtonDataCy="settings-types-button-previous-page"
                nextPageButtonDataCy="settings-types-button-next-page"
              />
              <TableBody>
                {visibleItems.map((item, idx) => {
                  const isSelected = this.isSelected(item.originalData);
                  let type = (item.type || {}).type
                    ? (item.type || {}).type
                    : item.type;
                  const configurationTranslation = i18n.t(
                    `app_settings_type_${type}`
                  );
                  return (
                    <TableRow
                      key={idx}
                      hover
                      role="checkbox"
                      selected={isSelected || highlightedId === item._id}
                      aria-checked={isSelected}
                      className={classes.tableRow}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                      >
                        <Checkbox
                          checked={isSelected}
                          color="primary"
                          onClick={this.handleClickRow(item)}
                        />
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellAlignLeft
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {item.name.split(delimiter)[0]}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.textAlignRight
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {configurationTranslation}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                        padding="none"
                      >
                        <IconButton
                          className={classes.tableMenuButton}
                          aria-owns={tableMenu ? 'table-tableMenu' : null}
                          aria-haspopup="true"
                          onClick={this.handleMenuOpen('tableMenu', item)}
                        >
                          <MoreHoriz className={classes.tableIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={tableMenu}
          open={Boolean(tableMenu)}
          onClose={this.handleMenuClose('tableMenu')}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleDialogOpen(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          {currentItem.originalData &&
          currentItem.originalData.isTypeConfiguration ? (
            <MenuItem
              onClick={this.handleDeleteConfigurationOpenDialog(currentItem)}
            >
              {i18n.t('menu_delete')}
            </MenuItem>
          ) : null}
        </Menu>
        <TypeDialog
          open={dialogOpen}
          inspectionActivityTypes={inspectionActivityTypes}
          onClose={this.handleDialogOnClose}
          onSave={this.handleDialogOnSave}
          onChange={this.handleDialogOnChange}
          editMode={dialogEditMode}
          typeEditing={currentItemIsTypeConfig}
          item={editItem}
          documents={documents}
          menuAnchorEl={menuAnchorEl}
          onMenuClose={this.handleMenuClose}
          onMenuOpen={this.handleMenuOpen}
        />
      </div>
    );
  }
}

TypesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(TypesPage);
