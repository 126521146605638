import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class ExploreServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'explore/';
  }

  getItems(tenantId) {
    const endpoint = this.endpoint + tenantId;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  create(item) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(this.endpoint, item, config);
  }

  update(item) {
    const endpoint = this.endpoint + item._id;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.put(endpoint, item, config);
  }

  remove = (id) => {
    const endpoint = this.endpoint + id;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  };

  getItemsSuggestions = (searchValue) => {
    const endpoint = this.endpoint + 'suggestions/' + searchValue;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };
}

const ExploreService = new ExploreServiceImpl();

export default ExploreService;
