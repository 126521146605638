import { browser_language, language_mapping } from 'AppSettings';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  flag: {
    width: 36,
    height: 24,
  },
});

const LanguageMenu = ({ classes, handleLanguage }) => {
  const [t] = useTranslation();

  return (
    <Select
      value={browser_language()}
      onChange={handleLanguage()}
      displayEmpty
      name="lang"
      disableUnderline
    >
      {Object.keys(language_mapping).map((lang) => (
        <MenuItem key={lang} value={lang}>
          <img
            className={classes.flag}
            src={language_mapping[lang]}
            alt={t(`menu_language_${lang}`)}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default withStyles(styles)(LanguageMenu);
