import 'moment/locale/sk';
import 'moment/locale/de';
import 'moment/locale/cs';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/zh-cn';
import 'moment/locale/hi';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/ms';
import 'moment/locale/nl';
import 'moment/locale/tl-ph';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/th';
import 'moment/locale/nl-be';

const locales = {
  sk: 'sk',
  en: 'en',
  de: 'de',
  cz: 'cs',
  es: 'es',
  fr: 'fr',
  hu: 'hu',
  zh: 'zh-cn',
  in: 'hi',
  it: 'it',
  ja: 'ja',
  kr: 'ko',
  my: 'ms',
  nl: 'nl',
  ph: 'tl-ph',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  th: 'th',
  nlbe: 'nl-be',
};
export default locales;
