import PropTypes from 'prop-types';
import { IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Email } from '@material-ui/icons';
import { VmsInput } from 'components/common/material-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useLayoutEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  emailProgressBar: {
    position: 'absolute',
    width: '100%',
    height: 2,
    top: -2,
    right: 0,
  },
  row: {
    width: '100%',
    boxSizing: 'border-box',
  },
  progressBarWrapper: {
    position: 'relative',
  },
  emailProgressBarHidden: {
    display: 'none',
  },
}));

const EmailSharedRow = ({
  dialogRowClass,
  emailShared,
  loadingEmailCheck,
  onEmailSharedClick,
  disabled,
  checkboxDataCy,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const inputRef = useRef();
  const progressRef = useRef();

  useLayoutEffect(() => {
    const width = window.getComputedStyle(inputRef.current).width;
    progressRef.current.style.width = width;
  }, []);

  return (
    <div className={classNames(dialogRowClass, classes.row)}>
      <VmsInput
        inputRef={inputRef}
        disabled={true}
        iconLeft={<Email />}
        iconRight={
          <IconButton
            data-cy={checkboxDataCy}
            disabled={disabled}
            onClick={onEmailSharedClick}
          >
            {emailShared ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </IconButton>
        }
        textValue={t('invite_form_email_shared')}
        inputLabel={t('invite_form_email_shared')}
      />
      <div className={classes.progressBarWrapper}>
        <LinearProgress
          ref={progressRef}
          className={classNames(
            classes.emailProgressBar,
            !loadingEmailCheck ? classes.emailProgressBarHidden : undefined
          )}
        />
      </div>
    </div>
  );
};

EmailSharedRow.propTypes = {
  dialogRowClass: PropTypes.string.isRequired,
  emailShared: PropTypes.bool.isRequired,
  loadingEmailCheck: PropTypes.bool.isRequired,
  onEmailSharedClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  checkboxDataCy: PropTypes.string,
};

export default EmailSharedRow;
