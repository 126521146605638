import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  Check,
  FolderOpen,
  Language,
} from '@material-ui/icons';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filter_types, language_mapping } from 'AppSettings';
import InspectionAllListItem from './InspectionAllListItem';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary6,
  },
  menuColor: {
    fontSize: 14,
    color: theme.palette.primary6,
  },
  nestedMenuItem: {
    paddingLeft: theme.spacing(9),
  },
  singleNestedMenuItem: {
    paddingLeft: theme.spacing(9) + 56,
  },
  nestedText: {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  flag: {
    width: 36,
    height: 24,
    marginRight: theme.spacing(2),
  },
  listItemWrapper: {
    display: 'flex',
  },
  //Since ref is not forwarded and styles doesnt support props on this old MUI version, this is a hacky solution
  menu: {
    maxHeight: `calc(100% - 96px)`,
  },
}));

const InspectionFilterMenu = ({
  typeFilter,
  languageFilter,
  filterMenuAnchor,
  handleCloseFilter,
  handleFilterCategoryClick,
  handleFilterLanguageClick,
  handleFilterAllClick,
  stateOfTypeFilters,
  stateOfLanguageFilters,
}) => {
  const [t] = useTranslation();
  const [openLanguageCollapse, setOpenLanguageCollapse] = useState(false);
  const [openTypeCollapse, setOpenTypeCollapse] = useState(false);
  const classes = useStyles();

  const onClose = () => {
    setOpenLanguageCollapse(false);
    setOpenTypeCollapse(false);
    handleCloseFilter();
  };

  return (
    <Menu
      className={classes.menu}
      id="template-filter-menu"
      anchorEl={filterMenuAnchor}
      open={Boolean(filterMenuAnchor)}
      onClose={onClose}
    >
      <MenuItem onClick={() => setOpenTypeCollapse(!openTypeCollapse)}>
        <ListItemIcon className={classes.icon}>
          <FolderOpen />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.menuColor }}
          primary={t('menu_type')}
        />
        {openTypeCollapse ? (
          <ArrowDropUp className={classes.icon} />
        ) : (
          <ArrowDropDown className={classes.icon} />
        )}
      </MenuItem>

      <Collapse in={openTypeCollapse} timeout="auto">
        <List disablePadding>
          <InspectionAllListItem
            filterType={filter_types.TYPE}
            filterStatus={stateOfTypeFilters}
            handleFilterAllClick={handleFilterAllClick(filter_types.TYPE)}
            classes={classes}
          />
          {Object.keys(typeFilter).map((type) => (
            <ListItem
              className={
                typeFilter[type]
                  ? classes.nestedMenuItem
                  : classes.singleNestedMenuItem
              }
              component="li"
              button
              key={type}
              onClick={handleFilterCategoryClick(type)}
            >
              {typeFilter[type] && (
                <ListItemIcon className={classes.icon}>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.menuColor }}
                primary={type}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />

      <MenuItem onClick={() => setOpenLanguageCollapse(!openLanguageCollapse)}>
        <ListItemIcon className={classes.icon}>
          <Language />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.menuColor }}
          primary={t('menu_language')}
        />
        {openLanguageCollapse ? (
          <ArrowDropUp className={classes.icon} />
        ) : (
          <ArrowDropDown className={classes.icon} />
        )}
      </MenuItem>

      <Collapse in={openLanguageCollapse} timeout="auto">
        <List disablePadding>
          <InspectionAllListItem
            filterStatus={stateOfLanguageFilters}
            handleFilterAllClick={handleFilterAllClick(filter_types.LANGUAGE)}
            classes={classes}
          />
          {Object.keys(languageFilter).map((lang) => {
            const enabledLanguage = languageFilter[lang];

            return (
              <ListItem
                className={
                  enabledLanguage
                    ? classes.nestedMenuItem
                    : classes.singleNestedMenuItem
                }
                component="li"
                button
                key={lang}
                onClick={handleFilterLanguageClick(lang)}
              >
                {enabledLanguage && (
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                )}
                <div className={classes.listItemWrapper}>
                  <img
                    className={classes.flag}
                    src={language_mapping[lang]}
                    alt={t(`menu_language_${lang}`)}
                  />
                  <ListItemText
                    className={classes.nestedText}
                    classes={{ primary: classes.menuColor }}
                    primary={t(`menu_language_${lang}`)}
                  />
                </div>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </Menu>
  );
};

InspectionFilterMenu.propTypes = {
  typeFilter: PropTypes.object.isRequired,
  languageFilter: PropTypes.object.isRequired,
  filterMenuAnchor: PropTypes.object,
  handleCloseFilter: PropTypes.func.isRequired,
  handleFilterCategoryClick: PropTypes.func.isRequired,
  handleFilterLanguageClick: PropTypes.func.isRequired,
  handleFilterAllClick: PropTypes.func.isRequired,
  stateOfTypeFilters: PropTypes.bool.isRequired,
  stateOfLanguageFilters: PropTypes.bool.isRequired,
};

export default memo(InspectionFilterMenu);
