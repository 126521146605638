import PropTypes from 'prop-types';
import { CheckCircle, FolderOpen } from '@material-ui/icons';
import { openSnackbar, VmsDialog } from 'components';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import VisitItineraryStepper from './VisitItineraryStepper';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { ItineraryService } from 'services';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
}));

const VisitItineraryCardDialog = ({
  open,
  loading,
  visit,
  itinerary,
  onClose,
  onAdd,
  onEdit,
  onDone,
  ...rest
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [savingItinerary, setSavingItinerary] = useState(false);
  const allLoadings = loading || savingItinerary;

  const onItineraryStepDone = async (type) => {
    setSavingItinerary(true);
    try {
      if (!itinerary._id) {
        const newItinerary = produce(itinerary, (draft) => {
          draft.visit = visit._id;
          if (!draft[type]) {
            draft[type] = { items: [] };
          }
          draft[type].completed = true;
        });
        await ItineraryService.create(newItinerary);
      } else {
        const newItinerary = produce(itinerary, (draft) => {
          draft[type].completed = true;
        });
        await ItineraryService.update(newItinerary);
      }
      openSnackbar(t('saved'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setSavingItinerary(false);
      onDone();
    }
  };

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={t('tab_visitor_itinerary')}
      onClose={onClose}
      dialogHeaderIcon={<FolderOpen />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={t('close')}
      buttonDisabled={false}
      buttonAction={onClose}
      loading={allLoadings}
    >
      <div className={classes.content}>
        <VisitItineraryStepper
          visit={visit}
          itinerary={itinerary}
          doneClicked={onItineraryStepDone}
          addClicked={onAdd}
          updateClicked={onEdit}
          {...rest}
        />
      </div>
    </VmsDialog>
  );
};

VisitItineraryCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  visit: PropTypes.object.isRequired,
  itinerary: PropTypes.object.isRequired,
};

export default VisitItineraryCardDialog;
