import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Description } from '@material-ui/icons';
import { VmsButtonFAB } from 'components';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RootRef,
  Typography,
} from '@material-ui/core';
import i18n from 'assets/i18n';
import { TemplateService } from 'services';
import commonStyles from 'assets/jss/commonStyles';
import { browser_language } from 'AppSettings';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const styles = (theme) => ({
  ...commonStyles(theme),
  loginModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  termsHelpNote: {
    color: theme.palette.primary.main,
  },
  termsNote: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    left: theme.spacing(2),
    color: theme.palette.white,
  },
  documentIcon: {
    marginRight: theme.spacing(),
  },
});

class TermsDialog extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      data: {
        agreeTerms: false, // 0
        agreePrivacy: false, // 1
        agreeBehaviour: false, // 2
        dialogOpenKind: -1,
        dialogOpen: false,
        dialogTitle: 'Title',
        dialogContent: '... content ...',
        termsDisabled: true,
        privacyDisabled: true,
        behaviourDisabled: true,
      },
      lang: undefined,
      buttonDisabled: true,
      showFab: true,
    };

    this.documents = {
      privacy_policy: '',
      terms_of_use: '',
      rules_of_behaviour: '',
    };

    this.dialogContentRef = null;
  }

  componentDidMount() {
    console.log(this.props);
    this.loadData(browser_language());
  }

  componentDidUpdate() {
    if (this.state.lang && this.state.lang !== browser_language()) {
      this.loadData(browser_language());
    }
  }

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeData = (event) => {
    const field = event.target.name;
    const data = this.state.data;
    const value =
      field === 'agreeTerms' ||
      field === 'agreePrivacy' ||
      field === 'agreeBehaviour'
        ? event.target.checked
        : event.target.value;
    data[field] = value;

    this.setState({
      data: data,
    });

    this.props.onChange(field, value);
  };

  loadData(lang) {
    let documentPromises = [];
    documentPromises.push(
      TemplateService.getPublicTemplateByType('privacy_policy', lang)
    );
    documentPromises.push(
      TemplateService.getPublicTemplateByType('terms_of_use', lang)
    );
    documentPromises.push(
      TemplateService.getPublicTemplateByType('rules_of_behaviour', lang)
    );

    Promise.all(documentPromises)
      .then(async (documents) => {
        if (documents[0].length === 0) {
          documents[0] = await TemplateService.getPublicTemplateByType(
            'privacy_policy',
            'en'
          );
        }
        if (documents[1].length === 0) {
          documents[1] = await TemplateService.getPublicTemplateByType(
            'terms_of_use',
            'en'
          );
        }
        if (documents[2].length === 0) {
          documents[2] = await TemplateService.getPublicTemplateByType(
            'rules_of_behaviour',
            'en'
          );
        }
        this.documents.privacy_policy = (
          ((documents || [])[0] || [])[0] || {}
        ).body;
        this.documents.terms_of_use = (
          ((documents || [])[1] || [])[0] || {}
        ).body;
        this.documents.rules_of_behaviour = (
          ((documents || [])[2] || [])[0] || {}
        ).body;
        console.log(this.documents);
        this.setState({
          lang: lang,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleDialogClose = () => {
    this.setState((prevState) => {
      const result = { ...prevState, data: prevState.data };

      result.data.dialogOpen = false;
      return result;
    });
  };

  onDialogScroll = (e) => {
    this.enableCheckboxIfDialogRead(e.target);
  };

  //Since button click's event doesn't contain the scrollable area as target, we need to store it and access it like so
  scroll = () => {
    if (this.dialogContentRef) {
      this.dialogContentRef.scrollBy({
        top: this.dialogContentRef.clientHeight * 0.85,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  enableCheckboxIfDialogRead = (el) => {
    const bottom = el.clientHeight + Math.ceil(el.scrollTop) >= el.scrollHeight;

    if (bottom) {
      this.setState((prevState) => {
        const { dialogOpenKind } = prevState.data;
        const result = { ...prevState, data: { ...prevState.data } };

        switch (dialogOpenKind) {
          case 0:
            result.data.termsDisabled = false;
            break;

          case 1:
            result.data.privacyDisabled = false;
            break;

          case 2:
            result.data.behaviourDisabled = false;
            break;

          default:
            break;
        }

        result.showFab = result.buttonDisabled = false;

        return result;
      });
    } else {
      const { showFab } = this.state;
      if (!showFab) {
        this.setState({
          showFab: true,
        });
      }
    }
  };

  handleDialogOpenTerms = () => {
    this.setState((prevState) => {
      prevState.data.dialogOpenKind = 0;
      prevState.data.dialogOpen = true;
      prevState.data.dialogTitle = i18n.t('terms_of_use');
      prevState.data.dialogContent = this.documents.terms_of_use;
      prevState.showFab = prevState.buttonDisabled =
        prevState.data.termsDisabled; //Copy the state of the checkbox
      return prevState;
    });
  };

  handleDialogOpenPrivacy = () => {
    this.setState((prevState) => {
      prevState.data.dialogOpenKind = 1;
      prevState.data.dialogOpen = true;
      prevState.data.dialogTitle = i18n.t('privacy_policy');
      prevState.data.dialogContent = this.documents.privacy_policy;
      prevState.showFab = prevState.buttonDisabled =
        prevState.data.privacyDisabled;
      return prevState;
    });
  };

  handleDialogOpenBehaviour = () => {
    this.setState((prevState) => {
      prevState.data.dialogOpenKind = 2;
      prevState.data.dialogOpen = true;
      prevState.data.dialogTitle = i18n.t('rules_of_behaviour');
      prevState.data.dialogContent = this.documents.rules_of_behaviour;
      prevState.showFab = prevState.buttonDisabled =
        prevState.data.behaviourDisabled;
      return prevState;
    });
  };

  //This piece of code ensures that if the text is not scrollable, it will enable the checkbox and the close button
  //on its mount
  dialogMounted = (element) => {
    if (element) {
      this.enableCheckboxIfDialogRead(element);
    }
    this.dialogContentRef = element;
  };

  render() {
    const { classes } = this.props;
    const { data, buttonDisabled, showFab } = this.state;
    const {
      agreeTerms,
      agreePrivacy,
      agreeBehaviour,
      dialogOpen,
      dialogContent,
      dialogTitle,
      termsDisabled,
      privacyDisabled,
      behaviourDisabled,
    } = data;
    return (
      <div>
        <br />
        <div className={classes.termsHelpNote}>
          {i18n.t('terms_of_use_help')}
        </div>
        <br />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTerms}
                onChange={this.changeData}
                inputProps={{
                  name: 'agreeTerms',
                }}
                value="agreeTerms"
                color="primary"
                disabled={termsDisabled}
              />
            }
            label={i18n.t('agree_with_terms_of_use')}
          />
          <br />
          <Button color="primary" onClick={this.handleDialogOpenTerms}>
            <Description className={classes.documentIcon} />
            {i18n.t('with_terms_of_use')}
          </Button>
        </div>
        <div className={classes.termsNote}>
          &bull; {i18n.t('terms_of_use_note')}
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreePrivacy}
                onChange={this.changeData}
                inputProps={{
                  name: 'agreePrivacy',
                }}
                value="agreePrivacy"
                color="primary"
                disabled={privacyDisabled}
              />
            }
            label={i18n.t('agree_with_privacy_policy')}
          />
          <br />
          <Button color="primary" onClick={this.handleDialogOpenPrivacy}>
            <Description className={classes.documentIcon} />
            {i18n.t('with_privacy_policy')}
          </Button>
        </div>
        {this.props.useBehaviour === 'true' ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeBehaviour}
                  onChange={this.changeData}
                  inputProps={{
                    name: 'agreeBehaviour',
                  }}
                  value="agreeBehaviour"
                  color="primary"
                  disabled={behaviourDisabled}
                />
              }
              label={i18n.t('agree_with_rules_of_behaviour')}
            />
            <br />
            <Button color="primary" onClick={this.handleDialogOpenBehaviour}>
              <Description className={classes.documentIcon} />
              {i18n.t('with_rules_of_behaviour')}
            </Button>
          </div>
        ) : null}
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h6">{dialogTitle}</Typography>
            <Typography variant="caption" component="p" color="primary">
              {i18n.t('read_the_whole_document')}
            </Typography>
          </DialogTitle>
          {/*Needs to be wrapped in RootRef to get underlying div*/}
          <RootRef rootRef={this.dialogMounted}>
            <DialogContent onScroll={this.onDialogScroll}>
              <DialogContentText id="alert-dialog-description">
                <span dangerouslySetInnerHTML={{ __html: dialogContent }} />
                {/*Having div here produces invalid HTML*/}
              </DialogContentText>
              {showFab && (
                <VmsButtonFAB customStyle={classes.fab} onClick={this.scroll}>
                  <KeyboardArrowDownIcon />
                </VmsButtonFAB>
              )}
            </DialogContent>
          </RootRef>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              color="primary"
              disabled={buttonDisabled}
            >
              {i18n.t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

TermsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TermsDialog);
