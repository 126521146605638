import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Button } from '@material-ui/core';
import commonStyles from 'assets/jss/commonStyles';
import { UserService, VisitService } from 'services';
import Auth from 'modules/Auth';
import {
  isMobileApp,
  isiOSMobileApp,
  vms_application_routes,
  visit_event_status_constants,
} from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import buttonStyle from 'assets/jss/buttonStyle';
import classNames from 'classnames';
import i18n from 'assets/i18n';
import QRDialogReader from 'containers/meetingRoom/QRDialogReader';
import { openSnackbar } from 'components/common/bars/SnackBar';
import logo from 'assets/images/logo_appbar_mobile.png';
import qrCodeImage from 'assets/images/qr_code.png';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...buttonStyle(theme),
  root: {
    position: 'fixed',
    width: 'calc(100% - ' + theme.spacing(8) + 'px)',
    height: 'calc(100% - ' + theme.spacing(8) + 'px)',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primaryIcon,
  },
  meetingRoomBody: {
    width: 'calc(100% - ' + theme.spacing(4) + 'px)',
    height: 'calc(100% - ' + theme.spacing(4) + 'px)',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
  },
  logo: {
    position: 'fixed',
    backgroundColor: theme.palette.white,
    width: 406,
    height: 64,
    left: 'calc(50% - 203px)',
  },
  logoImg: {
    width: 216,
    height: 39.6,
    padding: theme.spacing(),
  },
  QRButton: {
    color: 'white',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 64,
  },
  QRButtonImage: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  container: {
    height: '100%',
  },
});

class TurnstilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countOfclickedOnLogo: 0,
      cameraId: 'user',
      qrDialogState: {
        QRResult: i18n.t('meeting_room_insert_qrcode'),
        isSearch: false,
        openQRDialog: false,
        user: {},
      },
    };
  }

  componentDidMount() {
    if (isMobileApp() === true) {
      setTimeout(() => {
        window.location =
          vms_application_routes.SCREEN_ALWAYS_ON_ENABLED + 'true';
      }, 2000);
    }

    UserService.getUser(Auth.getUser()._id)
      .then((user) => {
        this.setState((prevState) => {
          prevState.qrDialogState.user = user;
          return prevState;
        });
        if (
          isiOSMobileApp() === true &&
          user.lockUrl &&
          !user.lockUrl.startsWith('http:')
        ) {
          this.handleOpenQRDialog()();
          setTimeout(() => {
            window.location =
              vms_application_routes.CONNECT_TO_BLE_LOCK + user.lockUrl;
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  logOut = () => {
    const { countOfclickedOnLogo } = this.state;
    this.setState((state) => {
      return { countOfclickedOnLogo: state.countOfclickedOnLogo + 1 };
    });
    if (countOfclickedOnLogo === 4) {
      this.props.history.push('/logout');
    }
  };

  callMobileNativeService = (enabled) => {
    window.location = vms_application_routes.QR_READING + enabled;
  };

  handleOpenQRDialog = () => (event) => {
    this.callMobileNativeService(true);
    this.setState((prevState) => {
      return (prevState.qrDialogState.openQRDialog = true);
    });
  };

  handleOnChangeQRDialogReader = (qrDialogState) => {
    this.setState({
      qrDialogState: qrDialogState,
    });
  };

  handleOnQRReadCompleted = async (success, userId, visitId) => {
    setTimeout(() => {
      this.handleOpenQRDialog()();
    }, 3000); // TODO: Tune this for best experience, but 3000 is set to be same as SnackBar autohide

    if (success && visitId) {
      try {
        await VisitService.addEvent(
          visitId,
          visit_event_status_constants.SIGNED_OUT
        );
      } catch (err) {
        console.log(err);
        openSnackbar(err.message);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { qrDialogState } = this.state;

    return (
      <Aux>
        <div className={classNames(classes.root, classes.colorPrimary)}>
          <div className={classes.meetingRoomBody}>
            {isiOSMobileApp() === true && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
              >
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.QRButton}
                    onClick={this.handleOpenQRDialog()}
                  >
                    <img
                      className={classes.QRButtonImage}
                      src={qrCodeImage}
                      alt="QR"
                    />
                    {i18n.t('scan_qr_code')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <div className={classes.logo}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={logo}
                alt="Company logo"
                className={classes.logoImg}
                onClick={this.logOut}
              />
            </Grid>
          </Grid>
        </div>
        <QRDialogReader
          qrDialogData={qrDialogState}
          onChange={this.handleOnChangeQRDialogReader}
          onCompleted={this.handleOnQRReadCompleted}
          rejectUsed={false}
          successMessage={i18n.t('snackbar_entry_success_goodbye')}
          snackBarVariant={'big'}
        />
      </Aux>
    );
  }
}

TurnstilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TurnstilePage);
