import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Paper,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import RestaurantMenu from '@material-ui/icons/RestaurantMenu';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { VmsButtonFAB, VmsInfoBar, ErrorBoundary } from 'components';
import EmailService from 'services/EmailService';
import TemplateService from 'services/TemplateService';
import Aux from 'hoc/Auxiliary';
import objectPath from 'object-path';
import i18n from 'i18next';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { user_roles } from 'AppSettings';
import { withRouter } from 'react-router-dom';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  previewPaper: {
    height: 'inherit',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.primary6,
  },
  badge: {
    top: -4,
    right: -4,
    // The border color match the background color.
    border: `1px solid ${theme.palette.background.paper}`,
    color: theme.palette.common.white,
    fontSize: 10,
    letterSpacing: 0.5,
    width: 16,
    height: 16,
  },
  badgeContainer: {
    position: 'absolute',
    top: 4,
    left: 4,
  },
  badgePlaceholder: {
    width: 80,
    height: 96,
  },
  mealRow: {
    display: 'inline-flex',
  },
  mealDesc: {
    fontSize: 12,
  },
  mealDate: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary4,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    letterSpacing: 1,
  },
  mealType: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary5,
    letterSpacing: 1.8,
  },
  mealAllergens: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.primary5,
    letterSpacing: 0.5,
  },
  mealImage: {
    position: 'relative',
    top: 0,
    left: 0,
    width: 80,
    height: 96,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: theme.palette.primary3,
    backgroundSize: 'cover',
    color: theme.palette.primary8,
    display: 'flex',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },
  noMealIcon: {
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 'auto',
  },
  mealButton: {
    width: '100%',
    padding: theme.spacing(0),
    textTransform: 'none',
    userSelect: 'none',
    textAlign: 'left',
    lineHeight: '1em',
  },
});

class MealItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };
    this.meals = {};
  }

  handleChangeAmount = (step) => (event) => {
    if (!this.props.canChangeAmount(this.props.meal)) {
      // cannot change the amount, e.g. only one meal can be ordered
      return;
    }
    this.setState(function (prevState) {
      if (prevState.amount === 0 && step <= 0) {
        return null;
      }
      prevState.amount = prevState.amount + step;
      this.props.onChangeAmount(this.props.meal, prevState.amount);
      return prevState;
    });
  };

  render() {
    const { classes, previewMode, onlyOne } = this.props;
    let meal = this.props.meal;
    let desc = meal.id ? meal.id + '. ' + meal.description : meal.description;
    if (meal.extra) desc = desc + ' + ' + meal.extra;

    const mealImage = meal.image ? (
      <img
        className={classes.mealImage}
        src={meal.image}
        alt={meal.description}
      />
    ) : (
      <div className={classes.mealImage}>
        <RestaurantMenu className={classes.noMealIcon} />
      </div>
    );
    let amount = this.state.amount;
    let renderAmount = amount;
    let max = onlyOne ? 1 : 99;
    if (onlyOne && amount === 1) {
      renderAmount = '✓';
    }
    const element = (
      <Grid container direction="row" style={{ marginTop: 8 }} spacing={1}>
        <Grid item style={{ position: 'relative' }}>
          {mealImage}
          {amount > 0 && (
            <Badge
              color="primary"
              badgeContent={renderAmount}
              className={classes.badgeContainer}
              classes={{ badge: classes.badge }}
            >
              <div className={classes.badgePlaceholder} />
            </Badge>
          )}
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: '100%' }}
          >
            <Grid item>
              <div className={classes.mealDesc}>{desc}</div>
            </Grid>
            <Grid item>
              <div className={classes.mealAllergens}>{meal.allergens}</div>
            </Grid>
          </Grid>
        </Grid>
        {!(previewMode || onlyOne) && (
          <Grid item>
            <Paper style={{ width: '32px', height: '100%' }}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Grid item>
                  <IconButton
                    disabled={amount === max}
                    onClick={this.handleChangeAmount(1)}
                    style={{ left: -8, top: -8 }}
                  >
                    <ArrowDropUp />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!amount}
                    onClick={this.handleChangeAmount(-1)}
                    style={{ left: -8, bottom: -8 }}
                  >
                    <ArrowDropDown />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    );

    return (
      <Aux>
        {onlyOne === true ? (
          <Button
            className={classes.mealButton}
            onClick={this.handleChangeAmount(amount > 0 ? -1 : 1)}
          >
            {element}
          </Button>
        ) : (
          <div>{element}</div>
        )}
      </Aux>
    );
  }
}

class MealElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
    };
    this.mealItems = null;
    this.editData = {};
  }

  componentDidMount() {
    let data = this.props.data;
    // Process the meal rows to hierarchy
    this.meals = {};
    if (data.data) {
      data.data.forEach((meal) => {
        objectPath.push(this.meals, [meal.date, meal.type], {
          date: meal.date,
          type: meal.type,
          id: meal.id,
          description: meal.description,
          extra: meal.extra,
          allergens: meal.allergens,
          image: meal.image,
        });
      });
    }
    this.mealItems = this.createMealItems();
    this.setState({}); // Make sure to trigger render
  }

  handleGoToDashboard = () => {
    this.props.history.go(-1);
  };

  getMealKey = (meal) => {
    return meal.id ? meal.id + '. ' + meal.description : meal.description;
  };

  handleChangeAmount = (item, amount) => {
    let editData = this.editData;
    let meal = JSON.parse(JSON.stringify(item));
    // We are constructing order basket structure, so we identify the meal by its id and description.
    // Id mign not be present, so description should be unique
    let key = this.getMealKey(meal);
    if (amount > 0) {
      objectPath.set(editData, ['meals', meal.date, meal.type, key], amount);
    } else {
      objectPath.del(editData, ['meals', meal.date, meal.type, key]);
      if (
        this.isEmpty(objectPath.get(editData, ['meals', meal.date, meal.type]))
      ) {
        objectPath.del(editData, ['meals', meal.date, meal.type]);
      }
      if (this.isEmpty(objectPath.get(editData, ['meals', meal.date]))) {
        objectPath.del(editData, ['meals', meal.date]);
      }
    }
    this.setState({}); // Make sure to trigger render
  };

  isEmpty = (obj) => {
    return obj && obj.constructor === Object && Object.keys(obj).length === 0;
  };

  hasSomeMeal = () => {
    return (
      this.editData && this.editData.meals && !this.isEmpty(this.editData.meals)
    );
  };

  getOrderedItemsText = () => {
    let orderedItemsText = '';
    if (this.editData && this.editData.meals) {
      let meals = this.editData.meals;
      Object.keys(meals).forEach((date) => {
        orderedItemsText = orderedItemsText + date + '\n';
        Object.keys(meals[date]).forEach((type) => {
          orderedItemsText = orderedItemsText + type + '\n';
          Object.keys(meals[date][type]).forEach((item) => {
            orderedItemsText =
              orderedItemsText + meals[date][type][item] + 'x  ' + item + '\n';
          });
          orderedItemsText = orderedItemsText + '\n';
        });
        orderedItemsText = orderedItemsText + '\n';
      });
    }
    return orderedItemsText;
  };

  handleOrderItems = (open) => (event) => {
    this.setState({
      confirmOpen: open,
    });
  };

  success = () => {
    openSnackbar(i18n.t('others_order_sent'));
    this.onClose();
  };

  failure = (error) => {
    console.log(error);
    openSnackbar(error.message);
  };

  onClose = () => {
    this.setState({
      confirmOpen: false,
    });
    this.editData = {};
    this.props.history.go(-1);
  };

  sendOrder = () => {
    let { user, visit, item } = this.props.location.state || this.props;
    try {
      let recipients = [];
      switch (user.role) {
        case user_roles.VISITOR:
          const hostAssistantEmail = (
            ((visit || {}).host || {}).assistant || {}
          ).email;
          if (hostAssistantEmail) recipients.push(hostAssistantEmail);
          const hostEmail = ((visit || {}).host || {}).email;
          if (hostEmail) recipients.push(hostEmail);
          break;
        default:
          const userAssistantEmail = ((user || {}).assistant || {}).email;
          if (userAssistantEmail) recipients.push(userAssistantEmail);
          break;
      }
      recipients.push(user.email); // Push own email for record
      recipients = recipients.join(', '); // To email string
      let title = item.data.subject;
      let replace = {
        user: user,
        items: this.getOrderedItemsText(),
      };
      let template = TemplateService.replaceTemplate(item.data, replace);
      let htmlBody = template.body.replace('\n', '<br/>'); // Replace plaintext new lines with html
      EmailService.send(recipients, title, htmlBody)
        .then(this.success)
        .catch(this.failure);
    } catch (error) {
      this.failure(error);
    }
  };

  createMealItems = () => {
    const { classes, previewMode, onlyOne } = this.props;
    let paperBuffer = [];
    let meals = this.meals;
    let key = 1;
    Object.keys(meals).forEach((date) => {
      let buffer = [];
      buffer.push(
        <div key={key++} className={classes.mealDate} align="right">
          {date}
        </div>
      );
      Object.keys(meals[date]).forEach((type) => {
        buffer.push(
          <div key={key++} className={classes.mealType} align="center">
            {type}
          </div>
        );
        meals[date][type].forEach((meal) => {
          buffer.push(
            <Aux key={key++}>
              <MealItem
                classes={classes}
                meal={meal}
                canChangeAmount={this.handleCanChangeAmount}
                onChangeAmount={this.handleChangeAmount}
                previewMode={previewMode}
                onlyOne={onlyOne}
              />
            </Aux>
          );
        });
        buffer.push(<br key={key++} />);
      });
      paperBuffer.push(
        <Paper
          className={classes.previewPaper}
          style={{ whiteSpace: 'pre-wrap' }}
          key={key++}
        >
          {buffer}
        </Paper>
      );
    });

    return <Aux>{paperBuffer}</Aux>;
  };

  handleCanChangeAmount = (meal) => {
    let key = this.getMealKey(meal);
    let editData = this.editData;
    // In onlyOne mode in case there is ordered soup or meal from that day do not allow to change amount for otehr MealItem
    let canChange =
      !this.props.onlyOne ||
      objectPath.get(editData, ['meals', meal.date, meal.type]) === undefined ||
      objectPath.get(editData, ['meals', meal.date, meal.type, key]) !==
        undefined;
    return canChange;
  };

  render() {
    console.log('render ->', this.mealItems);
    const { classes, previewMode } = this.props;
    return (
      <Aux>
        <ErrorBoundary>
          {previewMode ? (
            this.mealItems
          ) : (
            <Aux>
              <VmsInfoBar backMode={true} onBack={this.handleGoToDashboard} />
              <div className={classes.contentOfVmsInfoBar}>
                {this.mealItems}
              </div>
            </Aux>
          )}

          {this.hasSomeMeal() && (
            <VmsButtonFAB onClick={this.handleOrderItems(true)}>
              <Check />
            </VmsButtonFAB>
          )}
          <Dialog
            open={this.state.confirmOpen}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
          >
            <DialogTitle>{i18n.t('others_order_items')}</DialogTitle>
            <DialogContent style={{ whiteSpace: 'pre-wrap' }}>
              {this.getOrderedItemsText()}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleOrderItems(false)} color="primary">
                {i18n.t('cancel')}
              </Button>
              <Button onClick={this.sendOrder} color="primary">
                {i18n.t('order')}
              </Button>
            </DialogActions>
          </Dialog>
        </ErrorBoundary>
      </Aux>
    );
  }
}

MealElement.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  previewMode: PropTypes.bool,
  onlyOne: PropTypes.bool,
};

export default withStyles(styles)(withRouter(MealElement));
