import { useCallback, useEffect, useState } from 'react';

const useSelectedVisit = (target, fetchMore) => {
  const [scrollableTarget, setScrollableTarget] = useState();
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [selectedVisitHit, setSelectedVisitHit] = useState(false);

  useEffect(() => {
    setScrollableTarget(document.getElementById(target));
  }, [target]);

  //Scroll to selected visit
  const selectedVisitRef = useCallback(
    (node) => {
      if (node !== null && scrollableTarget) {
        setSelectedVisitHit(true);
        scrollableTarget.scrollTo({
          behavior: 'smooth',
          top: node.offsetTop - 16,
        });
      }
    },
    [scrollableTarget]
  );

  //If a visit is selected from search and the visit isnt loaded in infitine scroller, fetch data until it is
  useEffect(() => {
    if (selectedVisit) {
      if (!selectedVisitHit) {
        fetchMore();
      }
    }
  }, [selectedVisit, selectedVisitHit, fetchMore]);

  return [
    selectedVisit,
    setSelectedVisit,
    setSelectedVisitHit,
    selectedVisitRef,
  ];
};

export default useSelectedVisit;
