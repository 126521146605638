import { withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});

const NotificationForVisitor = ({ classes, history, host }) => {
  const [t] = useTranslation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      history.push('/');
    }, 20000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return (
    <>
      <NavLink activeClassName={classes.active} to="/">
        <ChevronLeft className={classes.navLinkBack} />
      </NavLink>
      <div className={classes.container}>
        <h2>
          {t('please_contact_host')} {host.name}{' '}
          {host.phone ? t('phone') : null} {host.phone}{' '}
          {host?.email ? t('email') : null} {host.email}
        </h2>
      </div>
    </>
  );
};

export default withStyles(styles)(NotificationForVisitor);
