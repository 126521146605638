const modalStyle = (theme, width) => ({
  modal: {
    display: 'flex',
  },
  paper: {
    margin: 'auto',
    boxShadow: theme.shadows[5],
    maxWidth: width,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      overflow: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
  },
  modalStyle: {
    height: 'inherit',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '300px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '300px',
    },
  },
});

export default modalStyle;
