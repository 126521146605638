import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Auth from 'modules/Auth';
import {
  DocumentQuestionnaireDetail,
  DocumentPreview,
  VmsInfoBar,
  createEmptyQuestionnaireState,
  editQuestionnaireState,
  DocumentSignedPreview,
  DocumentSigningCanvas,
} from 'components';
import { withRouter } from 'react-router-dom';
import Aux from 'hoc/Auxiliary';
import i18n from 'assets/i18n';
import moment from 'moment';
import TemplateService from '../../../services/TemplateService';
import FeedbackDetail, {
  createEmptyFeedbackState,
  editFeedbackState,
} from './FeedbackDetail';

const styles = (theme) => ({});

class DocumentsDetail extends Component {
  state = {
    user: Auth.getUser(),
    isQuestionnaireOpen: false,
    documentQuestionnaireState: createEmptyQuestionnaireState(),
    feedbackState: createEmptyFeedbackState(),
    isDocumentOpen: false,
    documentData: {},
    documentId: undefined,
    questionnaireId: undefined,
    questionnaireName: undefined,
    isSignedDocumentOpen: false,
    isSigningCanvasOpen: false,
    companyData: undefined,
  };

  handleQuestionnaireOnChange = (documentQuestionnaireState, onClose) => {
    if (onClose) {
      this.setState({
        documentQuestionnaireState: editQuestionnaireState(
          documentQuestionnaireState
        ),
      });
    } else {
      this.setState({
        documentQuestionnaireState: editQuestionnaireState(
          documentQuestionnaireState
        ),
      });
    }
  };

  handleFeedbackOnChange = (feedbackState, onClose) => {
    if (onClose) {
      this.setState({
        feedbackState: editFeedbackState(feedbackState),
      });
    } else {
      this.setState({
        feedbackState: editFeedbackState(feedbackState),
      });
    }
  };

  handleCloseDocument = (onClose) => {
    this.props.history.goBack();
  };

  handleGoToDashboard = () => {
    const { isSigningCanvasOpen } = this.state;
    if (isSigningCanvasOpen) {
      this.setState({
        isSigningCanvasOpen: false,
      });
    } else {
      this.props.history.go(-1);
    }
  };

  handleGoToSigningCanvas = () => {
    this.setState({
      isSigningCanvasOpen: true,
    });
  };

  componentDidMount() {
    const { tenantId } = this.props.location.state;
    TemplateService.getCompanyData(tenantId)
      .then((data) => {
        this.setState({
          companyData: data,
        });
      })
      .catch((error) => {
        // Nothing todo
      });
  }

  render() {
    const {
      isQuestionnaireOpen,
      isDocumentOpen,
      documentData,
      questionnaireId,
      documentId,
      questionnaireName,
      isSignedDocumentOpen,
      isDocumentPending,
      tenantId,
      isFeedbackOpen,
      feedbackId,
      feedbackName,
      visitId,
    } = this.props.location.state;
    const {
      documentQuestionnaireState,
      feedbackState,
      isSigningCanvasOpen,
      companyData,
    } = this.state;
    const user = this.props.location.state.user || this.state.user;
    const location = window.location;

    const templateData = {
      company: companyData,
      user: {
        name: user.name,
        company: user.company || '',
        email: user.email || '',
        phone: user.phone || '',
        signedDocumentsUrlPlain:
          location.protocol +
          '//' +
          location.hostname +
          (location.port ? ':' + location.port : '') +
          '/documents',
        questionaireUrlPlain:
          location.protocol +
          '//' +
          location.hostname +
          (location.port ? ':' + location.port : '') +
          '/documents',
      },
      date: moment().format('D.M.YYYY'),
    };

    return (
      <Aux>
        <VmsInfoBar
          backMode={true}
          backModeText={i18n.t('tab_visitor_documents')}
          onBack={this.handleGoToDashboard}
        />
        {isQuestionnaireOpen && (
          <DocumentQuestionnaireDetail
            user={
              this.props.location.state.user
                ? this.props.location.state.user
                : Auth.getUser()
            }
            documentId={documentId}
            questionnaireId={questionnaireId}
            questionnaireName={questionnaireName}
            documentQuestionnaireState={documentQuestionnaireState}
            onChange={this.handleQuestionnaireOnChange}
            tenantId={tenantId}
          />
        )}
        {isFeedbackOpen && (
          <FeedbackDetail
            documentId={documentId}
            feedbackId={feedbackId}
            visitId={visitId}
            feedbackName={feedbackName}
            feedbackState={feedbackState}
            onChange={this.handleFeedbackOnChange}
            tenantId={tenantId}
          />
        )}
        {isDocumentOpen && (
          <DocumentPreview
            documentId={documentId}
            documentData={documentData}
            onClose={this.handleCloseDocument}
            documentPending={isDocumentPending}
          />
        )}
        {isSignedDocumentOpen && !isSigningCanvasOpen && (
          <DocumentSignedPreview
            documentData={documentData}
            onRead={this.handleGoToSigningCanvas}
            templateData={templateData}
          />
        )}
        {isSignedDocumentOpen && isSigningCanvasOpen && (
          <DocumentSigningCanvas
            user={user}
            documentId={documentId}
            tenantId={tenantId}
            documentData={documentData}
            onClose={this.handleCloseDocument}
            templateData={templateData}
          />
        )}
      </Aux>
    );
  }
}

DocumentsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DocumentsDetail));
