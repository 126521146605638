export const colors = {
  shipGray: '#4D4D4D',
  white: '#ffffff',
  whiteTwo: 'rgba(245, 245, 245, 1.0)',
  whiteThree: 'rgba(239, 239, 239, 1.0)',
  whiteFour: 'rgba(240, 240, 240, 1.0)',
  whiteFive: 'rgba(214, 214, 214, 1.0)',
  orangeYellow: 'rgba(255, 165, 0, 1.0)',
  brownishGrey: 'rgba(110, 110, 110, 1.0)',
  warmGrey: 'rgba(115, 115, 115, 1.0)',
  warmGreyTwo: 'rgba(150, 150, 150, 1.0)',
  greyFour: '#F0F0F0',
  pinkishGrey: 'rgba(205, 205, 205, 1.0)',
  red: 'rgba(255, 45, 55, 1.0)',
  orangeYellow09: 'rgba(255, 165, 0, 0.9)',
  whiteGray: 'rgba(255, 255, 255, 0.54)',
  required: '#C70039',
};

export const elevation = {
  standard: 4,
  light: 2,
};
