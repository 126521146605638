import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import clampText from 'assets/jss/clampText';
import { DocumentService } from 'services';
import ExploreStarContainer from './ExploreStarContainer';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  media: {
    height: 140,
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
  },
  title: {
    margin: 0,
  },
  cardContentWrapper: {
    width: '100%',
  },
  text: {
    ...clampText(`${theme.typography.body1.lineHeight}em`, 2),
  },
});

const ExploreThingCard = ({ classes, thing, onClick }) => {
  const { name, document, rating, reviews, text } = thing;
  const image = DocumentService.api + (document || {}).downloadUrl;

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea} onClick={onClick(thing)}>
        <div className={classes.cardContentWrapper}>
          <CardMedia className={classes.media} image={image} title={name} />
          <CardContent>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h6"
              component="h3"
              noWrap
            >
              {name}
            </Typography>
            <ExploreStarContainer rating={rating} reviews={reviews} />
            <Typography
              className={classes.text}
              variant="body1"
              color="textSecondary"
              component="p"
            >
              {text}
            </Typography>
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};

ExploreThingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  thing: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(ExploreThingCard));
