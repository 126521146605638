import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Grid } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/PersonOutline';
import LockOutlined from '@material-ui/icons/LockOutlined';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { VmsInput, HelpDialog } from 'components';
import i18n from 'assets/i18n';
import buttonStyle from 'assets/jss/buttonStyle';
import commonStyles from 'assets/jss/commonStyles';
import cardStyle from 'assets/jss/cardStyle';
import Aux from 'hoc/Auxiliary.js';
import { NavLink } from 'react-router-dom';

import loginLogo from 'assets/images/login_logo.jpg';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...buttonStyle(theme),
  ...cardStyle(theme),
  forgotPassword: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary4,
    fontSize: 12,
    fontWeight: 200,
  },
  rememberMe: {
    width: '100%',
    margin: 0,
  },
  rememberMeLabel: {
    color: theme.palette.primary4,
    fontSize: 12,
    fontWeight: 200,
  },
  needHelp: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary4,
    fontSize: 12,
    fontWeight: 200,
    cursor: 'pointer',
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openHelp: false,
    };
  }

  handleHelp = (open) => (event) => {
    this.setState({
      openHelp: open,
    });
  };

  render() {
    const { classes, onChange, user, onKeyPress, onSubmit } = this.props;
    const { openHelp } = this.state;
    return (
      <Aux>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={loginLogo}
            title="Image"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="subtitle1"
              color="primary"
              align="left"
            >
              Visitor Management System
            </Typography>
            <VmsInput
              id="email-field"
              rowClass={classes.dialogRow}
              iconRight={<PersonOutline />}
              textValue={user.email}
              placeholder={i18n.t('email')}
              inputLabel={i18n.t('email')}
              onChange={onChange}
              inputName={'email'}
            />
            <VmsInput
              id="password-field"
              rowClass={classes.dialogRow}
              iconRight={<LockOutlined />}
              textValue={user.password}
              placeholder={i18n.t('password')}
              inputLabel={i18n.t('password')}
              onChange={onChange}
              inputName={'password'}
              inputType="password"
              onKeyPress={onKeyPress}
            />
            <Grid item>
              <FormControlLabel
                className={classes.rememberMe}
                classes={{ label: classes.rememberMeLabel }}
                control={
                  <Checkbox
                    checked={user.rememberMe}
                    onChange={onChange}
                    inputProps={{
                      name: 'rememberMe',
                    }}
                    value="rememberMe"
                    color="primary"
                  />
                }
                label={i18n.t('remember_me')}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <NavLink
                      className={classes.link}
                      to="/account/resetpassword/request"
                    >
                      <Typography
                        className={classes.forgotPassword}
                        variant="caption"
                      >
                        {i18n.t('account_forgot_password')}
                      </Typography>
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.needHelp}
                      onClick={this.handleHelp(true)}
                      variant="caption"
                    >
                      {i18n.t('need_help')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  id="login-button"
                  data-cy="login-button-login"
                  className={classes.buttonBasic}
                  style={{ marginBottom: 0 }}
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                >
                  {i18n.t('login')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <HelpDialog openHelp={openHelp} handleHelp={this.handleHelp} />
      </Aux>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm);
