import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import UserService from 'services/UserService';
import { openSnackbar } from 'components/common/bars/SnackBar';
import {
  type_constants,
  user_document_status_constants,
  tab_indexes,
} from 'AppSettings';
import { FeedbackDialog, QuestionnaireDialog } from '../../../components';
import { DocumentItem } from 'components';
import Aux from 'hoc/Auxiliary';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { DocumentService, TemplateService } from 'services';

const styles = (theme) => ({});

class DocumentsPage extends Component {
  state = {
    documents: [],
    isQuestionnaireOpen: false,
    isDocumentOpen: false,
    isQuestionnaireResultOpen: false,
    documentData: {},
    documentId: undefined,
    questionnaireId: undefined,
    questionnaireName: undefined,
    isSignedDoucmentOpen: false,
    isDocumentPending: true,
    tenantId: undefined,

    isFeedbackOpen: false,
    isFeedbackResultOpen: false,
    feedbackId: undefined,
    feedbackName: undefined,
    visitId: undefined,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user && user._id) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.loadData();
    }
  }

  loadData = async () => {
    const { user, updateHasData, changeShowBadge } = this.props;
    try {
      //get specific documents for user by language
      await DocumentService.getDocumentByTypeAndLanguage(user._id);
      //get specific signed documents for use by language
      await TemplateService.getSignedDocumentsForUser(user._id);

      UserService.getNonPersonalDocuments(user._id)
        .then((response) => {
          const documents = response.filter((doc) => {
            return doc.status !== user_document_status_constants.UNAVAILABLE;
          });
          this.setState({ documents });

          if (updateHasData) {
            updateHasData(
              tab_indexes.DOCUMENTS,
              documents.length > 0 ? true : false
            );
          }

          if (changeShowBadge) {
            for (const doc of documents) {
              const { expires_in } = doc.data;
              const valid_until = moment(doc.valid_until);
              const expired = expires_in && valid_until.isBefore(moment());

              if (
                doc.status === user_document_status_constants.PENDING ||
                expired
              ) {
                changeShowBadge(tab_indexes.DOCUMENTS, true);
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } catch (err) {
      console.log(err);
      openSnackbar(err);
    }
  };

  handleOnClickItem = (item) => (event) => {
    const valid_until = moment(item.valid_until);
    const now = moment();
    const expired =
      item.data.required_for_entrance &&
      item.data.expires_in &&
      valid_until.isBefore(now);

    if (item.type === type_constants.QUESTIONNAIRE) {
      if (item.status !== user_document_status_constants.COMPLETED || expired) {
        this.setState({
          isQuestionnaireOpen: true,
          questionnaireId: item.data.document_id,
          questionnaireName: item.data.name,
          documentId: item._id,
          tenantId: item.tenantId,
        });
      } else {
        this.setState({
          isQuestionnaireResultOpen: true,
          documentData: item.data,
        });
      }
    } else if (item.type === type_constants.FEEDBACK) {
      if (item.status !== user_document_status_constants.COMPLETED) {
        this.setState({
          isFeedbackOpen: true,
          feedbackId: item.data.document_id,
          feedbackName: item.data.name,
          documentId: item._id,
          tenantId: item.tenantId,
          visitId: item.data.visit_id,
        });
      } else {
        this.setState({
          isFeedbackResultOpen: true,
          documentData: item.data,
        });
      }
    } else if (
      item.type === type_constants.DOCUMENT ||
      (item.type === type_constants.SIGNED &&
        item.status === user_document_status_constants.SIGNED &&
        !expired)
    ) {
      this.setState({
        isDocumentOpen: true,
        questionnaireId: item.data.document_id,
        questionnaireName: item.data.name,
        documentData: item.data.document,
        documentId: item._id,
        isDocumentPending:
          item.status === user_document_status_constants.PENDING || expired,
        tenantId: item.tenantId,
      });
    } else if (item.type === type_constants.SIGNED) {
      this.setState({
        isSignedDocumentOpen: true,
        documentId: item._id,
        documentData: item.data,
        tenantId: item.tenantId,
      });
    }
  };

  handleCloseQuestionnaireResult = () => {
    this.setState({
      isQuestionnaireResultOpen: false,
      documentData: {},
    });
  };

  handleCloseFeedbackResult = () => {
    this.setState({
      isFeedbackResultOpen: false,
      documentData: {},
      visitId: undefined,
    });
  };

  render() {
    const { user } = this.props;
    const {
      documents,
      isQuestionnaireOpen,
      isDocumentOpen,
      questionnaireId,
      questionnaireName,
      documentData,
      documentId,
      isSignedDocumentOpen,
      isDocumentPending,
      tenantId,
      isQuestionnaireResultOpen,
      isFeedbackOpen,
      isFeedbackResultOpen,
      feedbackId,
      feedbackName,
      visitId,
    } = this.state;
    if (
      isDocumentOpen ||
      isQuestionnaireOpen ||
      isSignedDocumentOpen ||
      isFeedbackOpen
    ) {
      return (
        <Redirect
          push={true}
          to={{
            pathname: '/documents/detail',
            state: {
              isQuestionnaireOpen,
              isDocumentOpen,
              questionnaireId,
              questionnaireName,
              documentData,
              documentId,
              isSignedDocumentOpen,
              isDocumentPending,
              user,
              tenantId,
              isFeedbackOpen,
              feedbackId,
              feedbackName,
              visitId,
            },
          }}
        />
      );
    }
    return (
      <Aux>
        {!isQuestionnaireOpen && !isDocumentOpen && (
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="space-between"
          >
            {documents.map((item, idx) => {
              return (
                <DocumentItem
                  key={`documentItem-${idx}`}
                  item={item}
                  onClick={this.handleOnClickItem(item)}
                />
              );
            })}
          </Grid>
        )}
        {isQuestionnaireResultOpen && (
          <QuestionnaireDialog
            open={true}
            totalQuestions={documentData.count_of_questions}
            totalAnsweredQuestions={documentData.questions.length}
            totalCorrectAnswers={documentData.count_of_correct}
            onClose={this.handleCloseQuestionnaireResult}
          />
        )}
        {isFeedbackResultOpen && (
          <FeedbackDialog
            open={true}
            totalQuestions={documentData.totalQuestions}
            totalAnsweredQuestions={documentData.questions.length}
            onClose={this.handleCloseFeedbackResult}
          />
        )}
      </Aux>
    );
  }
}

DocumentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateHasData: PropTypes.func,
  changeShowBadge: PropTypes.func,
};

export default withStyles(styles)(DocumentsPage);
