import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyle from 'assets/jss/commonStyles';
import { RequiredIcon } from 'components';
import { VmsSelect } from 'components/common/material-ui';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const VmsDialogRowSelect = (props) => {
  const {
    placeholder,
    iconLeft,
    onChange,
    classes,
    rowClass,
    selectTypes,
    selectedType,
    inputName,
    disabled,
    multiple,
    renderValue,
    required,
    selectDataCy,
    itemDataCy,
  } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <Grid item>
          <div className={classes.icon}>
            <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
          </div>
        </Grid>
      )}
      <VmsSelect
        placeholder={placeholder}
        onChange={onChange}
        selectTypes={selectTypes}
        selectedType={selectedType}
        inputName={inputName}
        disabled={disabled}
        multiple={multiple}
        renderValue={renderValue}
        required={required}
        selectDataCy={selectDataCy}
        itemDataCy={itemDataCy}
      />
    </Grid>
  );
};

VmsDialogRowSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rowClass: PropTypes.string,
  selectTypes: PropTypes.object.isRequired,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  inputName: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  selectDataCy: PropTypes.string,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsDialogRowSelect);
