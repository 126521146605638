import { createTheme } from '@material-ui/core/styles';
import { colors } from './UISettings';

export default createTheme({
  palette: {
    primary: {
      main: colors.orangeYellow,
      opacity09: colors.orangeYellow09,
    },
    secondary: {
      main: colors.whiteTwo,
    },
    primary3: colors.whiteFour,
    primary4: colors.warmGrey,
    primary5: colors.warmGreyTwo,
    primary6: colors.brownishGrey,
    primary7: colors.whiteFive,
    primary8: colors.pinkishGrey,
    tabIndicator: colors.greyFour,
    primaryIcon: colors.orangeYellow,
    white: colors.white,
    red: colors.red,
    required: colors.required,
  },
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: colors.whiteThree,
        position: 'relative',
        zIndex: 1,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        color: colors.brownishGrey,
        letterSpacing: 0.5,
        fontWeight: 500,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.whiteFive,
        fontWeight: 300,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: colors.orangeYellow,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${colors.orangeYellow}`,
        },
        '&:before': {
          borderBottom: `1px solid ${colors.whiteFive}`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${colors.orangeYellow}`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted ${colors.whiteFive}`,
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'unset',
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 48,
      },
    },
    MuiStepIcon: {
      text: {
        fill: 'white',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: colors.whiteTwo,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: colors.white,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        color: colors.white,
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: colors.white,
      },
      toolbarTxt: {
        color: colors.whiteGray,
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        backgroundColor: colors.white,
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        color: colors.white,
      },
    },
  },
  icon: {
    color: colors.brownishGrey,
  },
});
