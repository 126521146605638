class QuestionModel {
  constructor(_id, questionTitle, question, answers, type, language) {
    this._id = _id;
    this.questionTitle = questionTitle;
    this.question = question;
    this.answers = answers;
    this.type = type;
    this.language = language;
  }

  /**
   * Creates JSON representation of QuestionModel object.
   *
   * @returns {string} JSON representation of QuestionModel object
   */
  toJSON() {
    const data = {
      questionTitle: this.questionTitle,
      question: this.question,
      answers: this.answers,
      type: this.type,
      language: this.language,
    };
    return JSON.stringify(data);
  }
}

QuestionModel.fromJson = function (json) {
  return new QuestionModel(
    json._id,
    json.questionTitle,
    json.question,
    json.answers,
    json.type
  );
};

export default QuestionModel;
