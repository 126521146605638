const clampText = (lineHeight, lines) => ({
  display: '-webkit-box',
  width: '100%',
  height: `calc(${lineHeight} * ${lines})`, //Fallback for non-webkit
  margin: '0 auto',
  WebkitLineClamp: lines,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export default clampText;
