import {
  ChevronLeft,
  LockOutlined,
  PersonOutlineRounded,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import UserService from 'services/UserService';
import { Button, withStyles } from '@material-ui/core';
import { VmsInput, openSnackbar } from 'components';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import commonStyles from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: theme.spacing(10),
    color: 'white',
    fontSize: '1.3em',
    flex: '1',
  },
  backLink: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    position: 'absolute',
    backgroundColor: 'orange',
    borderRadius: '50%',
    color: 'white',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  loginForm: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
    padding: '30px',
    width: '35%',
    textAlign: 'center',
    marginTop: '-15%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '30px',
  },
  gridContainter: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  },
});

const TabletLogin = ({ classes, login, navigate }) => {
  const loginInput = useRef(null);
  const password = useRef(null);

  const [t] = useTranslation();

  const handleLogin = async () => {
    const data = {
      password: password.current.children[0].value,
      email: loginInput.current.children[0].value,
    };

    const response = await UserService.tabletEmployeeLogin(data);
    if (response.user) {
      login(response.user);
      navigate.push('/');
    } else {
      openSnackbar(response.message);
    }
  };

  const handleRedirect = () => {
    navigate.push('/sendPin');
  };
  return (
    <>
      <NavLink activeClassName={classes.active} to="/">
        <ChevronLeft className={classes.backLink} />
      </NavLink>
      <div className={classes.gridContainter}>
        <div className={classes.loginForm}>
          <h1>{t('employee_login')}</h1>
          <VmsInput
            rowClass={classes.dialogRow}
            inputLabel="login"
            className={classes.loginInput}
            inputRef={loginInput}
            placeholder={t('email_login')}
            iconRight={<PersonOutlineRounded />}
            inputName={'login'}
          />
          <VmsInput
            rowClass={classes.dialogRow}
            className={classes.loginInput}
            inputRef={password}
            placeholder={t('password')}
            iconRight={<LockOutlined />}
            inputType="password"
            inputName={'password'}
          />
          <div className={classes.buttons}>
            <Button
              className={classes.loginButton}
              color="primary"
              variant="contained"
              onClick={handleLogin}
            >
              <div className={classes.text}>{t('login')}</div>
            </Button>
            <Button
              className={classes.loginButton}
              color="primary"
              variant="contained"
              onClick={handleRedirect}
            >
              <div className={classes.text}>{t('get_access_code')}</div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(TabletLogin);
