import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class SecurityItemsServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'securityitems/';
  }

  /**
   * Validate security string
   */
  validate(string) {
    let endpoint = this.endpoint + 'validate/' + string;
    return this.get(endpoint);
  }

  /**
   * Increase usage counter
   */
  increaseUse(string) {
    let endpoint = this.endpoint + 'increase_use/' + string;
    return this.get(endpoint);
  }

  /**
   * Get active security item for user
   */
  getActiveSecurityItem(userId) {
    var endpoint = 'users/' + userId + '/securityitems/active';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getActiveSecurityItemForVisit(visitId) {
    var endpoint = 'visits/' + visitId + '/securityitem';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Get security items for visit
   */
  getSecurityItemsForVisit(visitId) {
    var endpoint = 'visits/' + visitId + '/securityitems/';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getUser(securityItem) {
    const endpoint = `${this.endpoint}user/${securityItem}`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  invalidateSecurityItem(itemId) {
    const endpoint = `${this.endpoint}invalidate/${itemId}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, null, config);
  }

  refreshSecurityItem(itemId) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    var data = {
      refreshItemId: itemId,
    };

    return this.post(this.endpoint, data, config);
  }

  requestAuthenticationCode(itemId) {
    const endpoint = `${this.endpoint}request_authentication/${itemId}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, null, config);
  }

  authenticateItem(itemId, code) {
    const endpoint = `${this.endpoint}authenticate/${itemId}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const data = {
      code,
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, data, config);
  }

  forceAuthenticateItem(itemId) {
    const endpoint = `${this.endpoint}force_authenticate/${itemId}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, null, config);
  }

  confirmVisit(visitId) {
    const endpoint = `${this.endpoint}confirmVisit/${visitId}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };
    return this.post(endpoint, null, config);
  }
}

const SecurityItemsService = new SecurityItemsServiceImpl();

export default SecurityItemsService;
