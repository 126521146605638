import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { VmsDialogRowDragDrop, VmsDialogRowFilePreview } from 'components';
import { PhotoDialog } from 'components/';
import CameraDialog from 'containers/CameraDialog';
import DocumentService from 'services/DocumentService';
import UserService from 'services/UserService';
import {
  photo_accepted_mime_types,
  DOCUMENT_PROFILE_PHOTO,
  vms_application_routes,
  isMobileApp,
  isiOSMobileApp,
} from 'AppSettings';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  avatarEditor: {
    borderRadius: 60,
  },
});

const _photoUrl = (url) => {
  return url ? (url.startsWith('http') ? url : DocumentService.api + url) : '';
};

export const createDragDropStateWithPhoto = (photo) => {
  let photoUrl = photo ? _photoUrl(photo.downloadUrl) : '';

  return {
    files: [],
    base64: null,
    fileStatus: '',
    cameraDialogOpen: false,
    photoDialogOpen: false,
    openPhotoFileDialog: false,
    photoUrl: photoUrl,
    photoName: photo ? photo.name : 'N/A',
    photo: photo,
    uploading: false,
    new: false,
  };
};

export const uploadPhotoBase64 = (
  user_id,
  user_name,
  base64,
  updateUploadingState
) => {
  return new Promise(function (resolve, reject) {
    if (base64) {
      updateUploadingState(true);
      DocumentService.uploadPhoto('Photo ' + user_name, '', base64)
        .then(function (document) {
          UserService.updateUserPhoto(user_id, document._id)
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              console.log(error);
              updateUploadingState(false);
              reject(error);
            });
        })
        .catch(function (error) {
          console.log(error);
          updateUploadingState(false);
          reject(error);
        });
    } else {
      reject(new Error('No base64 data!'));
    }
  });
};

export const uploadPhotoFile = (
  user_id,
  user_name,
  files,
  updateUploadingState
) => {
  const documentData = {
    name: 'Photo ' + user_name,
    documentType: DOCUMENT_PROFILE_PHOTO,
    publicDoc: false,
  };
  const { name, documentType, publicDoc } = documentData;

  return new Promise(function (resolve, reject) {
    if (files.length > 0) {
      const file = files[0];
      updateUploadingState(true);
      DocumentService.uploadDocument(name, documentType, '', publicDoc, file)
        .then(function (document) {
          UserService.updateUserPhoto(user_id, document._id)
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              console.log(error);
              updateUploadingState(false);
              reject(error);
            });
        })
        .catch(function (error) {
          console.log(error);
          updateUploadingState(false);
          reject(error);
        });
    } else {
      reject(new Error('No files!'));
    }
  });
};

class VmsDialogDragDropPhoto extends Component {
  constructor(props) {
    super();
    this.editor = null;
  }

  onChange = () => {
    this.props.onChange(this.props.dragDropState);
  };

  // ############ PHOTO ############
  handleBrowse = () => {
    this.props.dragDropState.openPhotoFileDialog = true; // Open system file input browse dialog
    this.props.dragDropState.photoDialogOpen = false; // Close this dialog
    this.onChange();
  };

  handlePhotoDialog = (open) => (event) => {
    this.props.dragDropState.openPhotoFileDialog = false;
    this.props.dragDropState.photoDialogOpen = open;
    this.onChange();
  };

  handleCameraDialog = (open) => (event) => {
    if (isMobileApp() && !isiOSMobileApp() && open) {
      //Android only
      window.location = vms_application_routes.TAKE_PICTURE;

      /**
       * Global function used by Android app
       */
      window.photoCaptured = (base64) => {
        console.log('Photo captured');
        this.handleCapture(base64);
      };
      this.props.dragDropState.photoDialogOpen = false;
      this.onChange();
      return;
    }
    this.props.dragDropState.photoDialogOpen = false;
    this.props.dragDropState.cameraDialogOpen = open;
    this.onChange();
  };

  handleCapture = (base64Image) => {
    this.props.dragDropState.files = [];
    this.props.dragDropState.base64 = base64Image;
    this.props.dragDropState.fileStatus = '';
    this.props.dragDropState.cameraDialogOpen = false;
    this.props.dragDropState.new = false;
    this.onChange();
  };

  photoNew = () => {
    this.props.dragDropState.files = [];
    this.props.dragDropState.base64 = null;
    this.props.dragDropState.fileStatus = '';
    this.props.dragDropState.photoUrl = null;
    this.props.dragDropState.editedBase64 = false;
    this.props.dragDropState.new = true;
    this.onChange();
  };

  // ######### DRAG DROP #######
  photoOnDrop = (files) => {
    this.props.dragDropState.openPhotoFileDialog = false;
    this.props.dragDropState.photoDialogOpen = false;
    this.props.dragDropState.base64 = files[0]; // So it is treated like base64, but beware, this is not valid base64 file!!!
    this.props.dragDropState.editedBase64 = true; // We need to extract base64 from editor
    this.props.dragDropState.files = [];
    this.props.dragDropState.new = false;
    this.onChange();
  };

  photoOnDropAccepted = () => {
    this.props.dragDropState.fileStatus = 'accepted';
    this.onChange();
  };

  photoOnDropRejected = () => {
    this.props.dragDropState.fileStatus = 'rejected';
    this.onChange();
  };

  cancelBrowse = () => {
    this.props.dragDropState.openPhotoFileDialog = false;
    this.onChange();
  };

  // ########## ROTATE ##########
  handleRotateLeft = () => {
    let rotate = this.props.dragDropState.rotate || 0;
    this.props.dragDropState.rotate = rotate - 90;
    this.onChange();
  };

  handleRotateRight = () => {
    let rotate = this.props.dragDropState.rotate || 0;
    this.props.dragDropState.rotate = rotate + 90;
    this.onChange();
  };

  handleImageChange = () => {
    this.props.dragDropState.editedBase64 = true;
  };

  setEditorRef = (editorRef) => {
    this.editor = editorRef;
    if (this.editor) {
      this.props.dragDropState.photoEditor = this.editor;
      this.onChange();
    }
  };

  render() {
    const { classes, dragDropState, disabled, buttonDataCy, itemDataCy } =
      this.props;
    return (
      <Aux>
        {dragDropState.photoUrl && !dragDropState.base64 && (
          <VmsDialogRowFilePreview
            editMode={!disabled}
            onEditClick={this.photoNew}
            onRotateLeftClick={disabled ? false : this.handleRotateLeft}
            onRotateRightClick={disabled ? false : this.handleRotateRight}
            rowClass={classes.dialogRow}
            iconLeft={<PhotoCamera />}
            previewComponent={
              <AvatarEditor
                ref={this.setEditorRef}
                rotate={dragDropState.rotate}
                width={120}
                height={120}
                border={0}
                onImageChange={this.handleImageChange}
                crossOrigin="anonymous"
                image={dragDropState.photoUrl}
                className={classes.avatarEditor}
                alt={dragDropState.photoName}
              />
            }
            downloadUrl={dragDropState.photoUrl}
          />
        )}
        {dragDropState.base64 && (
          <VmsDialogRowFilePreview
            editMode={!disabled}
            avatarMode={true}
            onEditClick={this.photoNew}
            onRotateLeftClick={disabled ? false : this.handleRotateLeft}
            onRotateRightClick={disabled ? false : this.handleRotateRight}
            rowClass={classes.dialogRow}
            iconLeft={<PhotoCamera />}
            previewComponent={
              <AvatarEditor
                ref={this.setEditorRef}
                rotate={dragDropState.rotate}
                width={120}
                height={120}
                border={0}
                onImageChange={this.handleImageChange}
                crossOrigin="anonymous"
                image={dragDropState.base64}
                className={classes.avatarEditor}
                alt={dragDropState.photoName}
              />
            }
          />
        )}
        {!dragDropState.photoUrl && !dragDropState.base64 && (
          <VmsDialogRowDragDrop
            rowClass={classes.dialogRow}
            files={dragDropState.files}
            fileStatus={dragDropState.fileStatus}
            document_accepted_mime_types={photo_accepted_mime_types}
            uploading={dragDropState.uploading}
            onDropAccepted={this.photoOnDropAccepted}
            onDropRejected={this.photoOnDropRejected}
            onBrowseCancel={this.cancelBrowse}
            onDrop={this.photoOnDrop}
            icon={<PhotoCamera />}
            photoMode={true}
            newPhoto={this.handlePhotoDialog(true)}
            openPhotoFileDialog={dragDropState.openPhotoFileDialog}
            disabled={disabled}
            buttonDataCy={buttonDataCy}
          />
        )}
        <PhotoDialog
          open={dragDropState.photoDialogOpen}
          onClose={this.handlePhotoDialog(false)}
          takePhoto={this.handleCameraDialog(true)}
          browse={this.handleBrowse}
          itemDataCy={itemDataCy}
        />
        {dragDropState.cameraDialogOpen && (
          <CameraDialog
            onClose={this.handleCameraDialog(false)}
            capture={this.handleCapture}
          />
        )}
      </Aux>
    );
  }
}

VmsDialogDragDropPhoto.defaultProps = {
  dragDropState: {
    files: [],
    base64: null,
    fileStatus: '',
    cameraDialogOpen: false,
    photoDialogOpen: false,
    openPhotoFileDialog: false,
    photoUrl: '',
    photoName: '',
    photo: null,
    uploading: false,
  },
};

VmsDialogDragDropPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dragDropState: PropTypes.shape({
    photoDialogOpen: PropTypes.bool,
    cameraDialogOpen: PropTypes.bool,
    files: PropTypes.array,
    base64: PropTypes.string,
    fileStatus: PropTypes.string,
    photoUrl: PropTypes.string,
    photoName: PropTypes.string,
    photo: PropTypes.object,
    uploading: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  buttonDataCy: PropTypes.string,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(VmsDialogDragDropPhoto);
