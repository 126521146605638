import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { colors } from '../../../assets/UISettings';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  transparent: {
    opacity: 0.5,
  },
  growingItem: {
    flexGrow: 1,
  },
  colorItem: {
    display: 'block',
    borderRadius: '50%',
    backgroundColor: 'blue',
    width: 32,
    paddingTop: 32, // aspect ratio is 1:1
    margin: 8,
  },
  labelItem: {
    fontSize: 14,
    color: colors.brownishGrey,
  },
  buttonItem: {},
});

class MeetingRoomItem extends Component {
  render() {
    const { classes, roomName } = this.props;

    return (
      <div className={classNames(classes.root)}>
        <div className={classNames(classes.root, classes.growingItem)}>
          <div className={classes.colorItem} />
          <label className={classNames(classes.labelItem, classes.growingItem)}>
            {roomName}
          </label>
        </div>
      </div>
    );
  }
}

MeetingRoomItem.propTypes = {
  classes: PropTypes.object.isRequired,
  roomName: PropTypes.string.isRequired,
};

export default withStyles(styles)(MeetingRoomItem);
