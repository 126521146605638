import AbstractService from './AbstractService';
import Chartist from 'chartist';
import Auth from '../modules/Auth';
import moment from 'moment';
// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

class ReportServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'reports/';
  }

  getSuccesRateOfVisitsLastSixMonthsChartData(response) {
    const months = [''];
    response.map((r) => {
      months.push(moment.monthsShort(r.month - 1));
      return null;
    });
    months.push('');
    const counts = [NaN];
    response.map((r) => {
      if (r.count !== 0) {
        counts.push((r.success / r.count) * 100);
      } else {
        counts.push(0);
      }
      return null;
    });
    counts.push(0);
    return {
      data: {
        labels: months,
        series: [counts],
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 100, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function (data) {
          if (data.type === 'bar') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          }
        },
      },
    };
  }

  getVisitorsPerMonthsChartData(response) {
    const months = [''];
    response.map((r) => {
      months.push(moment.monthsShort(r.month - 1));
      return null;
    });
    const counts = [NaN];
    response.map((r) => {
      counts.push(r.count);
      return null;
    });
    const max = Math.max(...counts) + 2;
    return {
      data: {
        labels: months,
        series: [counts],
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: max, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function (data) {
          if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === 'point') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          }
        },
      },
    };
  }

  getVisitorsPerWeekChartData(response) {
    const days = [];
    response.map((r) => {
      days.push(moment.weekdaysShort(r.day));
      return null;
    });
    const counts = [];
    response.map((r) => {
      counts.push(r.count);
      return null;
    });
    const max =
      Math.max(
        ...response.map((r) => {
          return r.count;
        })
      ) + 2;
    return {
      data: {
        labels: days,
        series: [counts],
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: max, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function (data) {
          if (data.type === 'bar') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          }
        },
      },
    };
  }

  getVisitorsPerDayChartData(response) {
    const hours = response.map((r) => {
      return r.hour;
    });
    const counts = response.map((r) => {
      return r.count;
    });
    const max = Math.max(...counts) + 2;
    return {
      data: {
        labels: hours,
        series: [counts],
      },
      options: {
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: max,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0,
        },
      },
      responsiveOptions: [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              },
            },
          },
        ],
      ],
      animation: {
        draw: function (data) {
          if (data.type === 'bar') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          }
        },
      },
    };
  }

  getVisitorsMonthChartData(response) {
    const weeks = [];
    response.map((r) => {
      weeks.push(moment().isoWeek(r.week).format('W - YYYY'));
      return null;
    });
    const counts = [];
    response.map((r) => {
      counts.push(r.count);
      return null;
    });
    const max =
      Math.max(
        ...response.map((r) => {
          return r.count;
        })
      ) + 2;
    return {
      data: {
        labels: weeks,
        series: [counts],
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: max, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function (data) {
          if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === 'point') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          } else if (data.type === 'bar') {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease',
              },
            });
          }
        },
      },
    };
  }

  getSuccesRateOfVisitsLastSixMonths = () => {
    const endpoint = this.endpoint + 'successRateOfVisistsLastSixMonths';
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getCountOfVisits = () => {
    const endpoint = this.endpoint + 'totalCountOfVisits';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getCountOfDistinctVisitors = () => {
    const endpoint = this.endpoint + 'totalCountOfDistinctVisitors';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getCountOfVisitorsToday = () => {
    const endpoint = this.endpoint + 'CountOfVisitorsToday';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsPerDay = () => {
    const endpoint = this.endpoint + 'visitorsPerDay';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsPerWeek = () => {
    const endpoint = this.endpoint + 'visitorsPerWeek';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsPerMonths = () => {
    const endpoint = this.endpoint + 'visitorsLastSixMonths';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsLastMonth = () => {
    const endpoint = this.endpoint + 'visitorsLastMonth';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsNextMonth = () => {
    const endpoint = this.endpoint + 'visitorsNextMonth';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitorsNextWeek = () => {
    const endpoint = this.endpoint + 'visitorsNextWeek';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  downloadReport = (type) => {
    const endpoint = this.endpoint + 'download' + type;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };
}

const ReportService = new ReportServiceImpl();

export default ReportService;
