import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import {
  PeripheralStatusDialog,
  PeripheralCreateNewDialog,
} from 'components/index';
import { openSnackbar } from 'components/common/bars/SnackBar';
import PeripheralsService from 'services/PeripheralsService';
import i18n from 'assets/i18n';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import tableStyles from 'assets/jss/tableStyles';
import { routes } from '../../../AppSettings';
import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import { emptySettingSearchState } from '../SettingsPage';

const styles = (theme) => ({
  ...tableStyles(theme),
});

class PeripheralsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statuses: [],
      menuAnchorEl: null,
      currentItem: {},
      dialogOpen: false,
      createNewDialogOpen: false,
      tableHeaderState: createTableHeaderState([]),
      newDeviceInfo: this.createEmptyNewDeviceInfo(),
      activityTimer: null,
      highlightedId: '',
    };

    this.tableHeader = null;
  }

  createEmptyNewDeviceInfo = () => {
    return {
      saveDisabled: false,
    };
  };

  componentDidMount() {
    this.loadData();
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.setSearchSettings();
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_PERIPHERALS) this.handleNewClick();
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService =
        PeripheralsService.getDeviceSuggestions;
      settingSearchState.onSuggestionsClearRequested =
        this.handleDeviceSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleDeviceSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleDeviceSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.deviceName;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  handleDeviceSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleDeviceSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestDevice(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.name;
      onChangeSearch(settingSearchState);
    }
  };

  handleDeviceSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestDevice = (_id) => {
    const { statuses, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = statuses.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  handleNewClick = () => {
    this.setState({
      createNewDialogOpen: true,
      menuAnchorEl: null,
    });
  };

  loadData = () => {
    PeripheralsService.getPeripheralStatuses()
      .then((results) => {
        this.setState(function (prevState) {
          prevState.statuses = results;
          prevState.tableHeaderState.data = results;
          return prevState;
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItems = () => {
    const { statuses, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = statuses;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = statuses.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      PeripheralsService.deleteDevice(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            self.setState(function (prevState) {
              prevState.statuses = array;
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadData();
        });
    });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleDialogOpen = (item) => (event) => {
    this.setState({
      dialogOpen: true,
      menuAnchorEl: null,
      currentItem: item,
    });
  };

  handleDialogOnClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleCreateNewDialogClose = () => {
    this.setState({
      createNewDialogOpen: false,
      newDeviceInfo: this.createEmptyNewDeviceInfo(),
    });
    this.loadData();
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  changeNewDeviceInfo = (event) => {
    const { newDeviceInfo } = this.state;
    var attrKey = event.target.name;
    var attrVal = event.target.value;
    newDeviceInfo[attrKey] = attrVal;
    this.setState({ newDeviceInfo: newDeviceInfo });
  };

  handleNewDeviceSave = () => {
    const self = this;
    const { deviceId, deviceName } = this.state.newDeviceInfo;
    let data = {
      deviceId: deviceId,
      deviceName: deviceName,
    };

    this.startActivity();
    PeripheralsService.createDevice(data)
      .then(function (device) {
        openSnackbar(i18n.t('saved'));
        self.stopActivity();
        self.handleCreateNewDialogClose();
      })
      .catch(function (error) {
        self.stopActivity();
        openSnackbar(error.message);
      });
  };

  startActivity = () => {
    const { newDeviceInfo } = this.state;
    let activityTimer = this.state.activityTimer;
    if (activityTimer) clearTimeout(activityTimer);
    activityTimer = setTimeout(() => {
      newDeviceInfo.saveDisabled = true;
      this.setState({ newDeviceInfo: newDeviceInfo });
    }, 250);
    this.setState({ activityTimer: activityTimer });
  };

  stopActivity = () => {
    const { newDeviceInfo, activityTimer } = this.state;
    if (activityTimer) clearTimeout(activityTimer);
    newDeviceInfo.saveDisabled = false;
    this.setState({ newDeviceInfo: newDeviceInfo });
  };

  handleRemoveDeviceOpenDialog = (id) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_peripherals_dialog_remove_title'),
      i18n.t('settings_peripherals_dialog_remove_description'),
      this.handleRemoveDevice(id)
    );
  };

  handleRemoveDevice = (id) => (event) => {
    var self = this;
    PeripheralsService.deleteDevice(id)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      statuses,
      menuAnchorEl,
      currentItem,
      dialogOpen,
      tableHeaderState,
      createNewDialogOpen,
      highlightedId,
    } = this.state;
    const { rowsPerPage, page } = tableHeaderState;

    const visibleItems = statuses.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItems}
                prevPageButtonDataCy="settings-peripherals-button-previous-page"
                nextPageButtonDataCy="settings-peripherals-button-next-page"
              />
              <TableBody>
                {visibleItems.map((item, idx) => {
                  const isSelected = this.isSelected(item);
                  return (
                    <TableRow
                      key={idx}
                      hover
                      role="checkbox"
                      selected={isSelected || highlightedId === item._id}
                      aria-checked={isSelected}
                      className={classes.tableRow}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                      >
                        <Checkbox
                          checked={isSelected}
                          color="primary"
                          onClick={this.handleClickRow(item)}
                        />
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellAlignLeft
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {item.deviceName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.textAlignRight
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {i18n.t(`settings_peripherals_${item.status}`)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                        padding="none"
                      >
                        <IconButton
                          className={classes.tableMenuButton}
                          aria-owns={menuAnchorEl ? 'table-menus' : null}
                          aria-haspopup="true"
                          onClick={(event) => this.handleMenuOpen(event, item)}
                        >
                          <MoreHoriz className={classes.tableIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleDialogOpen(currentItem)}>
            {i18n.t('menu_show')}
          </MenuItem>
          <MenuItem
            onClick={this.handleRemoveDeviceOpenDialog(currentItem._id)}
          >
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <PeripheralStatusDialog
          open={dialogOpen}
          onClose={this.handleDialogOnClose}
          item={currentItem}
        />
        <PeripheralCreateNewDialog
          open={createNewDialogOpen}
          onSave={this.handleNewDeviceSave}
          onClose={this.handleCreateNewDialogClose}
          onChange={this.changeNewDeviceInfo}
          newDeviceInfo={this.state.newDeviceInfo}
        />
      </div>
    );
  }
}

PeripheralsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(PeripheralsPage);
