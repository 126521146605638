import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';
import { DOCUMENT_PROFILE_PHOTO } from 'AppSettings';

class DocumentServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'documents/';
  }

  /**
   * Upload document
   */
  uploadDocument(
    name,
    type,
    description,
    publicDoc,
    documentBlob,
    required_for_entrance,
    expires_in,
    format,
    language
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('type', type);
    bodyFormData.append('public', publicDoc);
    bodyFormData.append('description', description);
    bodyFormData.append('document', documentBlob);
    bodyFormData.append('format', format);
    bodyFormData.append('language', language);

    if (required_for_entrance) {
      bodyFormData.append('required_for_entrance', required_for_entrance);
    }
    if (expires_in) {
      bodyFormData.append('expires_in', expires_in);
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, bodyFormData, config);
  }

  /**
   * Upload photo
   */
  uploadPhoto(name, description, base64) {
    const endpoint = this.endpoint + 'photo/';
    const documentData = {
      name: name,
      type: DOCUMENT_PROFILE_PHOTO,
      description: description,
      public: false,
      base64: base64,
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(endpoint, documentData, config);
  }

  /**
   * Update a document.
   */
  updateDocument(
    id,
    name,
    documentType,
    description,
    isDocumentPublic,
    language,
    required_for_entrance,
    expires_in,
    format
  ) {
    var documentData = {
      name: name,
      type: documentType,
      description: description,
      public: isDocumentPublic,
      language: language,
      required_for_entrance,
      expires_in,
      format: format,
    };
    var endpoint = this.endpoint + id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, documentData, config);
  }

  getCompanyLogos() {
    const endpoint = this.endpoint + 'logos/';

    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Returns all stored documents
   * @param {string} category
   */
  getDocuments() {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  /**
   * Returns all stored documents of specified type
   * @param {string} type
   */
  getDocumentsWithoutInternalAndImages(type) {
    const endpoint = this.endpoint + 'without-internal-documents-and-images';
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  suggestDocuments = (searchValue) => {
    var endpoint = 'documents/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  /**
   * Returns all stored documents
   * @param {string} category
   */
  getDocument(document_id) {
    let endpoint = this.endpoint + document_id;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Removes a document
   * @param {string} id
   */
  deleteDocument(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(this.endpoint + id, config);
  }

  getDistinctTypes() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint + 'types', config);
  }

  getDocumentByTypeAndLanguage(userId) {
    let endpoint = this.endpoint + 'findDocumentByTypeAndLanguage/' + userId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }
}

const DocumentService = new DocumentServiceImpl();

export default DocumentService;
