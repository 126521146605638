import {
  withStyles,
  Input,
  InputAdornment,
  FormHelperText,
  FormControl,
  IconButton,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import PropTypes from 'prop-types';
import i18n from '../../../assets/i18n';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 57,
    marginLeft: 7,
  },
  form: {
    width: '100%',
    padding: theme.spacing(),
  },
  input: {
    margin: theme.spacing(),
    flexGrow: 1,
  },
  removePadding: {
    marginLeft: 50,
  },
});

const Answer = (props) => {
  const {
    classes,
    isCorrect,
    answer,
    index,
    showRemove,
    onInputChange,
    onCheckboxChange,
    showError,
    onRemove,
    onKeyPress,
  } = props;
  let deleteBtn;
  if (showRemove) {
    deleteBtn = (
      <IconButton onClick={() => onRemove(index)}>
        <RemoveCircleOutline aria-label="Delete" />
      </IconButton>
    );
  } else {
    deleteBtn = <div className={classes.removePadding}></div>;
  }

  return (
    <div className={classes.container}>
      {deleteBtn}
      <FormControl
        className={classes.form}
        error
        aria-describedby="name-error-text"
      >
        <Input
          placeholder={i18n.t('questions_answer_placeholder')}
          className={classes.input}
          inputProps={{
            'aria-label': 'Description',
          }}
          onChange={(event) => onInputChange(index, event.target.value)}
          onKeyPress={onKeyPress}
          value={answer}
          endAdornment={
            onCheckboxChange ? (
              <InputAdornment position="end">
                <IconButton onClick={(_) => props.onCheckboxChange(index)}>
                  {isCorrect ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </IconButton>
              </InputAdornment>
            ) : null
          }
          error={showError}
        />
        {showError === true && (
          <FormHelperText className={classes.input} id="name-error-text">
            {i18n.t('questionnaire_confirm_answer')}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

Answer.propTypes = {
  classes: PropTypes.object.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  showRemove: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func,
  showError: PropTypes.bool.isRequired,
  onRemove: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default withStyles(styles)(Answer);
