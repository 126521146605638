class UserModel {
  constructor(
    id,
    name,
    email,
    login,
    password,
    role,
    status,
    company,
    phone,
    pushTokens,
    allowMeetingRoomBooking
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.login = login;
    this.password = password;
    this.role = role;
    this.status = status;
    this.company = company;
    this.phone = phone;
    this.pushTokens = pushTokens || [];
    this.allowMeetingRoomBooking = allowMeetingRoomBooking;
  }

  toJSON() {
    let data = {
      name: this.name,
      email: this.email,
      login: this.login,
      password: this.password,
      role: this.role,
      status: this.status,
      company: this.company,
      phone: this.phone,
      pushTokens: this.pushTokens,
      allowMeetingRoomBooking: this.allowMeetingRoomBooking,
    };
    if (!this.email) {
      delete data['email']; // Lets assure sparse email field in UserModel, "", null are considered unique
    }
    return JSON.stringify(data);
  }
}

UserModel.fromJson = function (json) {
  return new UserModel(
    json._id,
    json.name,
    json.login,
    json.email,
    json.password,
    json.role,
    json.status,
    json.company,
    json.phone,
    json.pushTokens,
    json.allowMeetingRoomBooking
  );
};

export default UserModel;
