import Loadable from 'react-loadable';
import Loading from 'components/common/Loading';

const TemplateEditor = Loadable({
  loader: () =>
    import('./TemplateEditor') /* webpackChunkName: "TemplateEditor" */,
  loading: Loading,
});

const QuestionEditor = Loadable({
  loader: () =>
    import('./QuestionEditor') /* webpackChunkName: "SafetyTrainingsEditor" */,
  loading: Loading,
});
export { TemplateEditor, QuestionEditor };
export { default as CustomEditorButton } from './CustomEditorButton';
