import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';
import { openProgressBar } from 'components/common/bars/ProgressBar';

class PhotoUploadServiceImp extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'documents/';
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
  }

  uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData();
      if (file.name.includes('.')) {
        bodyFormData.append(
          'name',
          file.name.substring(0, file.name.lastIndexOf('.'))
        );
      } else {
        bodyFormData.append('name', file.name);
      }

      bodyFormData.append('type', 'image');
      bodyFormData.append('public', true);
      bodyFormData.append('description', '');
      bodyFormData.append('document', file);

      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: Auth.getToken(),
      };

      var config = {
        headers: headers,
      };

      this.post(this.endpoint, bodyFormData, config)
        .then(
          function (resp) {
            let url = this.api + 'public/' + resp._id;
            var obj = {};
            obj.data = {};
            obj.data.link = url;
            resolve(obj);
          }.bind(this)
        )
        .catch(
          function (error) {
            openProgressBar(false);
            reject(this._errorFromErrorResponse(error));
          }.bind(this)
        );
    });
  }
}

const PhotoUploadService = new PhotoUploadServiceImp();

export default PhotoUploadService;
