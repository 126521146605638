import { useEffect, useState } from 'react';

import { vms_application_routes } from 'AppSettings';
import { SecurityItemsService } from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { useTranslation } from 'react-i18next';

const setReading = (state) => {
  if (window.cameraManager) {
    // Windows tablet shell
    if (state) {
      console.log('openQrCodeWindow');
      window.cameraManager.openQrCodeWindow();
    }
  } else {
    // Apple tablet
    window.location = `${vms_application_routes.QR_READING}${state}`;
  }
};

const useUserFromQR = () => {
  const [t] = useTranslation();

  const [user, setUser] = useState();

  const enableScanner = () => {
    setReading(true);
  };

  const login = (user) => {
    setUser(user);
  };

  const logOut = () => {
    setUser(undefined);
  };

  // the Effect Hook lets you perform side effects in function components
  useEffect(() => {
    // called from Windows tablet shell 1.6.0 and higher app: this.ExecuteJavascript($"onScannedCode('{QrCodeReaderForm.DecodedString}');");
    window.onScannedCode = (scannerResponse) => {
      console.log('onScannedCode() invoked');

      if (scannerResponse === '###CLOSED_QR_READER###') {
        setReading(false);
      } else {
        try {
          SecurityItemsService.getUser(scannerResponse)
            .then((response) => {
              setReading(false);
              setUser(response);
            })
            .catch(() => {
              openSnackbar(t('invalid_qr_code'));
            });
        } catch (e) {
          console.log(e);
        }
      }
    };

    // called from Windows tablet shell 1.0.0
    window.codeReadingFinished = (scannerResponse) => {
      console.log('codeReadingFinished() invoked');

      if (scannerResponse === '###CLOSED_QR_READER###') {
        setReading(false);
      } else {
        try {
          SecurityItemsService.getUser(scannerResponse)
            .then((response) => {
              setReading(false);
              setUser(response);
            })
            .catch(() => {
              openSnackbar(t('invalid_qr_code'));
            });
        } catch (e) {
          console.log(e);
        }
      }
    };

    return () => {
      delete window.codeReadingFinished;
    };
  }, [t]);

  return [user, enableScanner, logOut, login];
};

export default useUserFromQR;
