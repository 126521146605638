import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { colors } from '../../assets/UISettings';

const styles = (theme) => ({
  rbcTopLeftCorner: {
    content: '',
    display: 'block',
    height: '10pt',
    width: '10pt',
    color: colors.whiteFour,
    borderTop: '2px solid',
    borderLeft: '2px solid',
  },
  rbcBottomRightCorner: {
    content: '',
    display: 'block',
    alignSelf: 'flex-end',
    height: '10pt',
    width: '10pt',
    color: colors.whiteFour,
    borderBottom: '2px solid',
    borderRight: '2px solid',
  },
  rbcHeader: {
    fontSize: 14,
    color: colors.warmGreyTwo,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rbcHeaderLabel: {
    alignSelf: 'center',
    fontFamily: 'sans-serif',
    fontWeight: 500,
  },
});

class CalendarMonthHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.rbcHeader}>
        <span className={classes.rbcTopLeftCorner} />
        <label className={classes.rbcHeaderLabel}>{this.props.label}</label>
        <span className={classes.rbcBottomRightCorner} />
      </div>
    );
  }
}

CalendarMonthHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarMonthHeader);
