import { Component } from 'react';
import PropTypes from 'prop-types';
import Search from '@material-ui/icons/Search';
import {
  Grid,
  InputAdornment,
  withStyles,
  TextField,
  MenuItem,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  CalendarComponent,
  MeetingForm,
  MeetingRoomItem,
  VmsAutocomplete,
  VmsDrawer,
  withCompanyDataContext,
} from 'components';
import { openSnackbar } from 'components/common/bars/SnackBar';
import {
  MeetingService,
  UserService,
  ConfigurationService,
  ExchangeService,
  EmailService,
  TemplateService,
  VisitService,
} from 'services';
import MeetingModel from 'services/models/MeetingModel';
import i18n from 'assets/i18n';
import {
  type_constants,
  routes,
  template_categories,
  template_types,
  date_formats,
} from 'AppSettings';
import moment from 'moment';
import commonStyles from 'assets/jss/commonStyles';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import Auth from '../../modules/Auth';
import { handleOpenConfirmDialog } from '../../components/common/dialogs/ConfirmDialog';
import * as EmailValidator from 'email-validator';
import Aux from 'hoc/Auxiliary';
import { convertDateTimeToDate } from 'components/calendar/CalendarComponent';
import classNames from 'classnames';
import emptyImage from 'assets/jss/emptyImage';

import MeetingRoom from 'assets/images/MeetingRoom.jpg';
import produce from 'immer';

const styles = (theme) => ({
  ...commonStyles(theme),
  inputNoPadding: {
    padding: 0,
  },
  meetingRoomsDivItem: {
    boxShadow: theme.shadows[3],
    borderRadius: 4,
  },
  calendarDiv: {
    height: 'calc(100vh - 130px)',
    minHeight: '550px',
    width: '100%',
  },
  calendarGrid: {
    height: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: 4,
  },
  calendarComp: {
    width: '95%',
    height: '95%',
    margin: 'auto',
  },
  button: {
    color: 'white',
    position: 'absolute',
    right: 56,
    bottom: 56,
    zIndex: 5,
  },
  searchAdornment: {
    color: theme.palette.primary8,
    alignSelf: 'center',
    paddingRight: 8,
  },
  emptyView: {
    height: 'calc(100vh - 20vh)',
    display: 'flex',
  },
  topPadding: {
    paddingTop: '10px',
  },
  text: {
    padding: '10px',
  },
  noMeetingRoomData: emptyImage(MeetingRoom),
});

class MeetingRoomsPage extends Component {
  constructor() {
    super();

    this.state = {
      showMeetingModal: false, // tells if modal view should be visible
      editMode: false, // tells if modal view should be in edit mode
      meetingEditable: false,
      meetingData: MeetingModel.getEmptyModalFormData(),
      events: [], // events visible in calendar (subset of meetings in calendar format)
      selectedMeetingId: null,
      rooms: { key: [], value: [] }, // all rooms
      selectedRoomKey: '', // selected room
      meetingSuggestionValue: '', // value of meeting search field
      drawerOpen: false,
      calendarDate: new Date(),
      calendarView: 'month',
      selectedRoomNote: '',
      showEmptyBackground: true,
      meetingDataChanged: false,
      upcommingGroupVisits: { key: [], value: [] },
    };
  }

  componentDidMount() {
    this.props.bus.on('toggleDrawer', this.busToggleDrawer);
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.props.bus.on('handleReloadData', this.busHandleReloadData);
    this.loadData();
  }

  componentWillUnmount() {
    this.props.bus.off('toggleDrawer', this.busToggleDrawer);
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.props.bus.off('handleReloadData', this.busHandleReloadData);
  }

  busToggleDrawer = (data) => {
    if (data.pathname === routes.MEETINGROOMS) this.toggleDrawer(data.open)();
  };

  busHandleAddClick = (pathname) => {
    if (pathname === routes.MEETINGROOMS) this.handleAddButton();
  };

  busHandleReloadData = (pathname) => {
    if (pathname === routes.MEETINGROOMS) this.loadData();
  };

  loadOrganiserProfile = () => {
    UserService.getUser(Auth.getUser()._id)
      .then((response) => {
        this.setState((state) => {
          return (state.meetingData.organiser = response);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadData = () => {
    const { calendarDate, calendarView } = this.state;
    const calendarDateM = moment(calendarDate);
    //Load data one month prior because the calendar view might display days from previous month
    const loadFrom = calendarDateM.clone().startOf('month').add(-1, 'month');
    this.loadRooms((response) => {
      const rooms = response.map((room) => room.key);
      const firstRoomKey = rooms.length > 0 ? rooms[0] : '';
      if (firstRoomKey) {
        this.loadMeetings(firstRoomKey, loadFrom, (events) => {
          //Background image will be shown if there are no events in current month and in days from previous/upcomming months
          const ranges = this.getTimeRangesForView(calendarDateM, calendarView);
          const showEmptyBackground = this.shouldShowBackground(
            events,
            ...ranges
          );

          // after page load, select all rooms and show all events
          UserService.getUserByName(firstRoomKey).then((response) => {
            this.setState({
              rooms: { key: rooms, value: rooms },
              selectedRoomKey: firstRoomKey,
              events,
              selectedRoomNote: response ? response.note : '',
              showEmptyBackground,
            });
          });
        });
      }
    });
    this.loadOrganiserProfile();
    this.loadUpcommingGroupVisits();
    //this.loadExchangeCalendar();
  };

  loadUpcommingGroupVisits = async () => {
    try {
      const visits = await VisitService.getUpcommingGroupVisitsWithVisitors();
      const upcommingGroupVisits = { key: [], value: [] };
      visits.forEach((visit) => {
        upcommingGroupVisits.value.push(
          `${visit.group} (${visit.users.map((v) => v.name).join(', ')})`
        );
        upcommingGroupVisits.key.push(visit.users);
      });
      this.setState({ upcommingGroupVisits });
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  getTimeRangesForView = (selectedDate, view) => {
    const date = moment(selectedDate);
    const rangeStart = date.clone().startOf(view);
    const rangeEnd = date.clone().endOf(view);
    if (view === 'month') {
      rangeStart.startOf('week');
      rangeEnd.endOf('week');
    }
    return [rangeStart, rangeEnd];
  };

  success = () => {
    openSnackbar(i18n.t('template_email_sent'));
  };

  failure = (error) => {
    console.log(error);
    openSnackbar(error.message);
  };

  toggleDrawer = (open) => () => {
    this.setState({
      drawerOpen: open,
    });
  };

  /**
   * Function that gets list of available meeting rooms.
   *
   * @param {function} callback Function that is called after successfull rooms load
   */
  loadRooms = (callback) => {
    ConfigurationService.getTypesForConfiguration(type_constants.MEETING_ROOM)
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  /**
   * Function that gets list of all meetings.
   *
   * @param {function} callback Function that is called after successfull meetings load
   */
  loadMeetings = (roomKey, date, callback) => {
    //Have to make it as start of date because the calendar component may change the date
    //Start of the date ensures we can get events from 3 consecutive months
    const quarterDate = moment(date).startOf('month');

    MeetingService.getMeetingsForRoomAndDate(roomKey, quarterDate, 'quarter')
      .then((response) => {
        const calendarEvents = this.convertMeetingsToEvents(response);
        callback(calendarEvents);
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadExchangeCalendar = () => {
    const mailbox = 'some.user@outlook.com';
    ExchangeService.configure(mailbox, 'plain_password');

    let from = moment().startOf('month');
    let to = moment().endOf('month');
    ExchangeService.loadExchangeCalendar(mailbox, from.format(), to.format())
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  closeModalAndReloadMeetings = () => {
    const { selectedRoomKey, calendarDate, calendarView } = this.state;
    this.loadMeetings(selectedRoomKey, calendarDate, (events) => {
      const ranges = this.getTimeRangesForView(calendarDate, calendarView);
      const showEmptyBackground = this.shouldShowBackground(events, ...ranges);
      this.setState({
        showMeetingModal: false,
        selectedMeetingId: null,
        events,
        showEmptyBackground,
      });
    });
  };

  convertMeetingsToEvents = (meetings) => {
    var events = meetings.map((meeting) =>
      MeetingModel.fromJson(meeting).getCalendarRepresentation()
    );
    return events;
  };

  handleOnChangeRoom = (event) => {
    const roomKey = event.target.value;
    const { calendarDate, calendarView } = this.state;
    this.loadMeetings(roomKey, calendarDate, (events) => {
      UserService.getUserByName(roomKey).then((response) => {
        const ranges = this.getTimeRangesForView(calendarDate, calendarView);
        const showEmptyBackground = this.shouldShowBackground(
          events,
          ...ranges
        );
        this.setState({
          events: events,
          selectedRoomKey: roomKey,
          selectedRoomNote: response ? response.note : '',
          showEmptyBackground,
        });
      });
    });
  };

  handleSelectReservation = (event) => {
    const meetingData = event.meetingObject.getModalFormData();
    const meetingEditable = moment(meetingData.endDate).isSameOrAfter(moment());
    this.setState({
      meetingData,
      showMeetingModal: true,
      editMode: false,
      meetingEditable,
      selectedMeetingId: event.meetingObject.id,
    });
  };

  handleSelectSlot = (slotInfo) => {
    // TODO: show add meeting modal with selected slot data
  };

  handleAddButton = () => {
    const { selectedRoomKey } = this.state;
    const { organiser } = this.state.meetingData;
    let meetingData = MeetingModel.getEmptyModalFormData(
      selectedRoomKey,
      organiser
    );
    // Default meeting name
    meetingData.name = '';
    this.setState({
      meetingData: meetingData,
      showMeetingModal: true,
      editMode: true,
      meetingEditable: false,
      meetingDataChanged: true,
    });
  };

  handleCancelReservationModal = () => {
    // only hide modal window
    this.setState({
      showMeetingModal: false,
      selectedMeetingId: null,
      meetingEditable: false,
    });
  };

  handleSaveReservationModal = () => {
    const { selectedMeetingId, meetingData, meetingDataChanged } = this.state;

    if (!meetingDataChanged) {
      this.handleCancelReservationModal();
      return;
    }

    if (selectedMeetingId) {
      // update meeting
      MeetingService.updateMeeting(selectedMeetingId, meetingData)
        .then((response) => {
          this.sendMeetingEmail('updated');
          this.closeModalAndReloadMeetings();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      // create meeting
      MeetingService.create(meetingData)
        .then((response) => {
          this.sendMeetingEmail('created');
          this.closeModalAndReloadMeetings();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleDeleteReservationOpenDialog = () => {
    handleOpenConfirmDialog(
      i18n.t('dashboard_meetingroom_dialog_remove_title'),
      i18n.t('dashboard_meetingroom_dialog_remove_description'),
      this.handleDeleteReservationModal()
    );
  };

  handleDeleteReservationModal = () => (event) => {
    const { selectedMeetingId, meetingEditable } = this.state;
    MeetingService.deleteMeeting(selectedMeetingId)
      .then((response) => {
        if (meetingEditable) {
          this.sendMeetingEmail('deleted');
        }
        this.closeModalAndReloadMeetings();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleOnChangeReservationModal = (name, value) => (event) => {
    //const field = event.target.name;
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    const currentData = this.state.meetingData;
    currentData[field] = fieldValue;
    this.setState({
      currentData,
      meetingDataChanged: true,
    });
  };

  sendMeetingEmail = (status) => {
    const { companyDataContext } = this.props;
    let templateName = undefined;
    switch (status) {
      case 'created':
        templateName = template_types.EMAIL_MEETING_CREATED;
        break;
      case 'updated':
        templateName = template_types.EMAIL_MEETING_UPDATED;
        break;
      case 'deleted':
        templateName = template_types.EMAIL_MEETING_DELETED;
        break;
      default:
        return;
    }
    let { participants, organiser, name, notes, place, startDate, endDate } =
      this.state.meetingData;
    let recipients = []; // array of email notification recipients
    let recipientsIds = []; // array of receipents id to prevent the same email twice
    let attendees = []; // array of meeting attendees
    participants.forEach((p) => {
      if (
        EmailValidator.validate(p.user.email) &&
        recipientsIds.indexOf(p.user._id) === -1
      ) {
        recipients.push(p.user);
        recipientsIds.push(p.user._id);
      }
      attendees.push(p.user.name);
    });
    if (
      EmailValidator.validate(organiser.email) &&
      recipientsIds.indexOf(organiser._id) === -1
    ) {
      recipients.push(organiser);
      recipientsIds.push(organiser._id);
    }
    if (!recipients.length) {
      return;
    }

    TemplateService.getTemplatesByCategoryAndType(
      template_categories.EMAIL,
      templateName
    )
      .then(async (templates) => {
        if (!templates.length) {
          return;
        }
        let assistant;
        if (this.state.meetingData.organiser.assistant?.name) {
          assistant = this.state.meetingData.organiser.assistant;
        } else if (this.state.meetingData.organiser.assistant?.length > 0) {
          try {
            assistant = await UserService.getUser(
              this.state.meetingData.organiser.assistant
            );
          } catch (err) {
            console.log(err);
          }
        }

        attendees = attendees.join('<br/>');
        for (let i = 0; i < recipients.length; i++) {
          try {
            const replace = {
              company: companyDataContext.companyData,
              meeting: {
                participant: {
                  name: recipients[i].name,
                },
                participants: attendees,
                organiser: {
                  name: organiser.name,
                },
                assistant: assistant,
                name: name,
                notes: notes,
                place: place,
                startDate: moment(startDate).format(
                  date_formats.full_date_short
                ),
                endDate: moment(endDate).format(date_formats.full_date_short),
              },
            };
            const template = TemplateService.findNotificationTemplateByLanguage(
              templates,
              recipients[i].language,
              companyDataContext.companyData.location.language
            );
            const title = template.title;
            const compiledTemplate = TemplateService.replaceTemplate(
              template,
              replace
            );
            const htmlBody = compiledTemplate.body.replace('\n', '<br/>'); // Replace plaintext new lines with html
            EmailService.send(recipients[i].email, title, htmlBody)
              .then(this.success)
              .catch(this.failure);
          } catch (error) {
            this.failure(error);
          }
        }
      })
      .catch(this.failure);
  };

  handleOnChangeDateReservationModal = (newDate, name) => {
    const currentData = this.state.meetingData;
    // if startDate is edited and endDate isn't set or endDate would be lower than start date -> set endDate to 1 hour after startDate
    if (
      name === 'startDate' &&
      (!currentData['endDate'] || currentData['endDate'] < newDate)
    ) {
      const endDate = moment(newDate).add(1, 'hour').toDate();
      currentData['endDate'] = endDate;
    }
    currentData[name] = newDate;
    this.setState({
      currentData,
      meetingDataChanged: true,
    });
  };

  handleOnEditReservationModal = () => {
    this.setState({
      editMode: true,
      meetingDataChanged: false,
    });
  };

  handleOnRemoveParticipantModal = (index) => {
    const currentData = this.state.meetingData;
    const participants = currentData.participants.slice();
    participants.splice(index, 1);
    currentData['participants'] = participants;
    this.setState({
      currentData,
      meetingDataChanged: true,
    });
  };

  handleMeetingSuggestionsClearRequested = () => (event) => {
    /*this.setState({
      meetingSuggestions: []
    });*/
  };

  handleMeetingSuggestionSelected = (suggestion) => (event) => {
    this.setState({
      meetingSuggestionValue: '',
      calendarDate: convertDateTimeToDate(
        suggestion.startDate,
        Auth.getUser().timezone
      ),
      calendarView: 'day',
    });
  };

  handleMeetingSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (
      field === 'searchMeeting' &&
      this.state.visitorSuggestionValue !== fieldValue
    ) {
      this.setState({
        meetingSuggestionValue: fieldValue ? fieldValue : '',
      });
    }
  };

  handleCalendarDateChanged = (date) => {
    this.setState({
      calendarDate: date,
    });
  };

  handleCalendarViewChanged = (view) => {
    this.setState({
      calendarView: view,
    });
  };

  handleUserSuggestionsClearRequested = () => (event) => {
    /*this.setState({
      userSuggestions: []
    });*/
  };

  handleUserSuggestionSelected = (suggestion) => (event) => {
    const currentData = this.state.meetingData;
    const participants = currentData.participants.slice();
    // modify data and update state
    // TODO: distinguish between adding registered user and simple name
    // Add participant only if he is not already in list of participants
    if (!participants.find((item) => item.user._id === suggestion._id)) {
      participants.push({ user: suggestion });
      currentData['participants'] = participants;
    }
    currentData.newParticipant = '';
    this.setState({
      currentData,
      meetingDataChanged: true,
    });
  };

  renderMeetingRoomItem = (value) => {
    var room = this.state.rooms.key.find(
      (room) => room === this.state.selectedRoomKey
    );
    return <MeetingRoomItem roomName={room} />;
  };

  shouldShowBackground = (events, startOfRange, endOfRange) => {
    const index = events.findIndex((event) => {
      return (
        moment(event.start).isSameOrBefore(endOfRange) &&
        moment(event.end).isSameOrAfter(startOfRange)
      );
    });
    return index < 0;
  };

  handleRangeChange = (range, view) => {
    const { events, selectedRoomKey, calendarDate, calendarView } = this.state;
    const selectedView = view || calendarView;

    let startOfRange;
    let endOfRange;
    if (selectedView === 'month') {
      startOfRange = moment(range.start);
      endOfRange = moment(range.end);
    } else {
      startOfRange = moment(range[0]);
      //Add a day or a week appropriately and then subtract one day and do end of the day to cover only the specified period
      endOfRange = startOfRange
        .clone()
        .add(1, selectedView)
        .add(-1, 'day')
        .endOf('day')
        .clone();
    }

    //Theres a weird bug when this doesnt work without clone, idk why though
    const startOfMonth = moment(calendarDate).startOf('month').clone();
    const endOfMonth = startOfMonth.clone().add(1, 'month').clone();

    //If any date reaches outside of the currently selected month, reload data
    if (
      startOfRange.isBefore(startOfMonth) ||
      endOfRange.isSameOrAfter(endOfMonth)
    ) {
      this.loadMeetings(
        selectedRoomKey,
        startOfRange.clone().startOf('month'),
        (events) => {
          const showEmptyBackground = this.shouldShowBackground(
            events,
            startOfRange,
            endOfRange
          );

          this.setState({ showEmptyBackground, events });
        }
      );
    } else {
      const showEmptyBackground = this.shouldShowBackground(
        events,
        startOfRange,
        endOfRange
      );

      this.setState({ showEmptyBackground });
    }
  };

  handleGroupSelected = (event) => {
    const users = event.target.value;
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        const participants = draft.meetingData.participants;

        users.forEach((user) => {
          if (!participants.find((p) => p.user._id === user._id)) {
            participants.push({ user });
          }
        });

        draft.meetingDataChanged = true;
      })
    );
  };

  render() {
    const { classes } = this.props;
    const {
      rooms,
      meetingSuggestionValue,
      selectedRoomKey,
      calendarDate,
      calendarView,
      selectedRoomNote,
      showEmptyBackground,
      meetingEditable,
      upcommingGroupVisits,
    } = this.state;

    const leftPanel = (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <VmsAutocomplete
            suggestionService={(value) =>
              MeetingService.getMeetingSuggestion(selectedRoomKey, value)
            }
            filterSuggestionsResponse={(suggestion) => {
              return suggestion.reverse();
            }}
            suggestionItemValue={(suggestion) => {
              return (
                suggestion.name +
                ', ' +
                moment(suggestion.startDate).format('L') +
                ' ' +
                moment(suggestion.startDate).format('LT')
              );
            }}
            inputName="searchMeeting"
            customClass={classes.searchField}
            inputClass={classes.inputSearchField}
            onSuggestionsClearRequested={
              this.handleMeetingSuggestionsClearRequested
            }
            onSuggestionSelected={this.handleMeetingSuggestionSelected}
            onChange={this.handleMeetingSuggestionChange}
            inputValue={meetingSuggestionValue}
            placeholder={i18n.t('meeting_rooms_search')}
            disableUnderline
            endAdornment={
              <InputAdornment
                className={classes.searchAdornment}
                position="end"
              >
                <Search />
              </InputAdornment>
            }
          />
        </Grid>
        {selectedRoomKey === '' ? null : (
          <Grid item>
            <Grid item className={classes.modalRowRightSide}>
              <TextField
                data-cy="meeting-rooms-select-room"
                value={selectedRoomKey}
                className={classes.meetingRoomsDivItem}
                fullWidth
                select
                onChange={this.handleOnChangeRoom}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.inputNoPadding,
                  },
                }}
                SelectProps={{
                  renderValue: this.renderMeetingRoomItem,
                }}
              >
                {rooms.key.map((room) => (
                  <MenuItem
                    data-cy="meeting-rooms-list-item-room"
                    key={room}
                    value={room}
                  >
                    {room}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {selectedRoomNote && (
              <Grid item className={classes.topPadding}>
                <Paper className={classes.paper}>
                  <Typography className={classes.text}>
                    {selectedRoomNote}
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    );

    return (
      <Aux>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          wrap="nowrap"
          spacing={2}
        >
          <Hidden smDown>
            <Grid item xs>
              {leftPanel}
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9} className={classes.calendarDiv}>
            <Grid
              container
              className={classNames(
                classes.calendarGrid,
                showEmptyBackground ? classes.noMeetingRoomData : undefined
              )}
            >
              <Grid item className={classes.calendarComp}>
                <CalendarComponent
                  onSelectEvent={this.handleSelectReservation}
                  onSelectSlot={this.handleSelectSlot}
                  onNavigate={this.handleCalendarDateChanged}
                  onRangeChange={this.handleRangeChange}
                  onView={this.handleCalendarViewChanged}
                  events={this.state.events}
                  date={calendarDate}
                  view={calendarView}
                  timezone={Auth.getUser().timezone}
                />
              </Grid>
              <MeetingForm
                shouldOpen={this.state.showMeetingModal}
                onCancel={this.handleCancelReservationModal}
                onSave={this.handleSaveReservationModal}
                onDelete={this.handleDeleteReservationOpenDialog}
                onChange={this.handleOnChangeReservationModal}
                onChangeDate={this.handleOnChangeDateReservationModal}
                onEdit={this.handleOnEditReservationModal}
                onRemoveParticipant={this.handleOnRemoveParticipantModal}
                editable={meetingEditable}
                meetingData={this.state.meetingData}
                editMode={this.state.editMode}
                meetingRooms={this.state.rooms}
                existingMeeting={this.state.selectedMeetingId != null}
                onUserSuggestionsClearRequested={
                  this.handleUserSuggestionsClearRequested
                }
                onUserSuggestionSelected={this.handleUserSuggestionSelected}
                upcommingGroupVisits={upcommingGroupVisits}
                handleGroupSelected={this.handleGroupSelected}
              />
            </Grid>
          </Grid>
        </Grid>
        <VmsDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
        >
          {leftPanel}
        </VmsDrawer>
      </Aux>
    );
  }
}

MeetingRoomsPage.propTypes = {
  companyDataContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withCompanyDataContext(),
  withBus(),
  withStyles(styles)
)(MeetingRoomsPage);
