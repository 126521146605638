import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import clampText from 'assets/jss/clampText';
import { DocumentService } from 'services';

const styles = (theme) => ({
  media: {
    height: 140,
  },
  content: {
    backgroundColor: theme.palette.primary8,
    padding: theme.spacing(),
  },
  title: {
    lineHeight: 1.4,
    ...clampText('1.4em', 2),
  },
});

const ExploreNewsCard = ({ classes, news, onClick }) => {
  const { name, document } = news;
  const image = DocumentService.api + (document || {}).downloadUrl;

  return (
    <Card>
      <CardActionArea onClick={onClick(news)}>
        <CardMedia className={classes.media} image={image} title={name} />
        <CardContent className={classes.content}>
          <Typography
            className={classes.title}
            gutterBottom
            variant="body2"
            color="textPrimary"
            component="h3"
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ExploreNewsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  news: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    document: PropTypes.shape({
      downloadUrl: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

//Used in useMemo in ExplorePage, thus no React.memo
export default withStyles(styles)(ExploreNewsCard);
