import {
  withStyles,
  Grid,
  FormControl,
  Input,
  Menu,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import commonStyles from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
  },
});

const VmsDialogRowEditDropDown = (props) => {
  const {
    placeholder,
    iconLeft,
    onChange,
    classes,
    rowClass,
    selectTypes,
    selectedType,
    inputName,
    inputValue,
    onMenuOpen,
    onMenuClose,
    anchorEl,
    onSelectItem,
    disabled,
  } = props;
  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.iconLeft}>{iconLeft}</div>
      </Grid>
    );
  }
  let rightIcon;
  if (!disabled) {
    rightIcon = (
      <InputAdornment position="end" className={classes.AdornmentIcon}>
        <IconButton onClick={onMenuOpen}>
          <ArrowDropDown />
        </IconButton>
      </InputAdornment>
    );
  } else {
    rightIcon = (
      <InputAdornment position="end" className={classes.AdornmentIcon}>
        <IconButton>
          <ArrowDropDown />
        </IconButton>
      </InputAdornment>
    );
  }
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <FormControl className={classes.formControl} fullWidth>
          <Input
            type="text"
            name={inputName}
            value={inputValue}
            placeholder={placeholder}
            fullWidth
            onChange={onChange}
            endAdornment={rightIcon}
            disabled={disabled}
          />
          <Menu
            id={inputName}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuClose}
          >
            {selectTypes.map((option) => (
              <MenuItem
                key={option}
                selected={option === selectedType}
                value={option}
                onClick={onSelectItem(inputName, option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </FormControl>
      </Grid>
    </Grid>
  );
};

VmsDialogRowEditDropDown.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.string,
  rowClass: PropTypes.string.isRequired,
  selectTypes: PropTypes.array.isRequired,
  selectedType: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  onMenuClose: PropTypes.func.isRequired, // Handle open menus
  onMenuOpen: PropTypes.func.isRequired, // Handle open menus
  anchorEl: PropTypes.object,
  onSelectItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(VmsDialogRowEditDropDown);
