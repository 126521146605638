import { withStyles, IconButton, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';

const styles = (theme) => ({
  header: {
    height: 56,
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,
  },
  buttonHeader: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
  titleHeaderInput: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: theme.typography.h6.fontSize,
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
});

const VmsDialogHeader = (props) => {
  const { classes, title, onClose, icon, closeButtonDataCy } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Grid item>
            <div className={classes.titleHeaderInput}>{title}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          data-cy={closeButtonDataCy}
          className={classes.buttonHeader}
          aria-label="Close"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
};

VmsDialogHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  closeButtonDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsDialogHeader);
