import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Paper,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import i18n from 'assets/i18n';
import UserService from '../../../services/UserService';
import TemplateService from '../../../services/TemplateService';
import { openSnackbar } from '../../common/bars/SnackBar';
import { VmsButtonFAB } from '../../common/';
import commonStyles from 'assets/jss/commonStyles';
import buttonStyle from '../../../assets/jss/buttonStyle';
import Aux from 'hoc/Auxiliary';
import { elevation } from '../../../assets/UISettings';
import SignatureCanvas from 'react-signature-canvas';
import { template_categories, template_types } from 'AppSettings';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...buttonStyle(theme),
  root: {
    height: 'inherit',
    width: 'calc(100% - ' + theme.spacing(4) + 'px)',
  },
  paper: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    padding: theme.spacing(),
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
  },
  paperMessage: {
    margin: `0 ${theme.spacing()}px ${theme.spacing(2)}px`,
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  paperContainer: {
    height: `calc(100vh - 140px - ${theme.spacing()}px)`,

    padding: `0 ${theme.spacing()}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  paperWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  canvas: {
    width: '100%',
  },
  clearButton: {
    position: 'fixed',
    margin: 0,
    bottom: theme.spacing(6),
    right: theme.spacing(17),
    top: 'auto',
    left: 'auto',
    color: theme.palette.common.white,
  },
});

class DocumentSigningCanvas extends Component {
  state = {
    canvasClear: true,
    loading: false,
  };

  signaturePad = {};

  handleSetAsSigned = (documentId) => (event) => {
    this.setState({ loading: true });
    const { onClose, documentId, documentData, tenantId, user } = this.props;
    let data = this.props.templateData;
    const urlData = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
    const self = this;
    // add image to template data so signature tag can be replaced with it
    const signatureDiv =
      '<div class="{classname}"><img src="' +
      urlData +
      '"/><p>{signaturetext}</p></div>';
    if (documentData.body.indexOf('{leftsignature=') > -1) {
      // if template contains leftsignature, get its value and update documentData.body
      const leftSignatureValueAndTemp =
        TemplateService.getParameterValueFromTemplate(
          'leftsignature',
          documentData.body
        );
      data['leftsignature'] = signatureDiv
        .replace('{classname}', 'leftSignature')
        .replace('{signaturetext}', leftSignatureValueAndTemp.value);
      documentData.body = leftSignatureValueAndTemp.template;
    } else if (documentData.body.indexOf('{rightsignature=') > -1) {
      // else if template contains rightsignature, get its value and update documentData.body
      const rightSignatureValueAndTemp =
        TemplateService.getParameterValueFromTemplate(
          'rightsignature',
          documentData.body
        );
      data['rightsignature'] = signatureDiv
        .replace('{classname}', 'rightSignature')
        .replace('{signaturetext}', rightSignatureValueAndTemp.value);
      documentData.body = rightSignatureValueAndTemp.template;
    }

    const template = TemplateService.replaceTemplate(documentData, data);

    TemplateService.getTemplatesByCategoryAndTypeForTenant(
      tenantId,
      template_categories.EMAIL,
      template_types.EMAIL_SIGNED_DOCUMENT
    )
      .then(function (res) {
        let email_template = null;
        if (res.length > 0) {
          const template = TemplateService.findNotificationTemplateByLanguage(
            res,
            user.language,
            data.company.location.language
          );
          email_template = TemplateService.replaceTemplate(template, data);
        }
        UserService.signUserDocument(documentId, template, email_template)
          .then(function (response) {
            openSnackbar(i18n.t('document_status_signed'));
            onClose(true);
          })
          .catch(function (error) {
            console.log(error);
            openSnackbar(error.message);
            self.setState({ loading: false });
          });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        self.setState({ loading: false });
      });
  };

  handleClear = () => {
    this.signaturePad.clear();
    this.setState({
      canvasClear: true,
    });
  };

  onCanvasBegin = () => {
    this.setState({
      canvasClear: false,
    });
  };

  render() {
    const { classes, documentId } = this.props;
    const { canvasClear, loading } = this.state;
    const buttonsDisabled = canvasClear || loading;

    return (
      <Aux classname={classes.root}>
        <div className={classes.contentOfVmsInfoBar}>
          <div className={classes.paperContainer}>
            <Paper className={classes.paperMessage} elevation={elevation.light}>
              <Typography variant="body1">
                {i18n.t('document_signing_canvas')}
              </Typography>
            </Paper>
            <div className={classes.paperWrapper}>
              <Paper className={classes.paper} elevation={elevation.light}>
                <SignatureCanvas
                  canvasProps={{ className: classes.canvas }}
                  ref={(ref) => {
                    this.signaturePad = ref;
                  }}
                  onBegin={this.onCanvasBegin}
                />
              </Paper>
            </div>
          </div>
        </div>
        <VmsButtonFAB
          data-cy="signed-button-clear"
          onClick={this.handleClear}
          disabled={buttonsDisabled}
          customStyle={classes.clearButton}
        >
          <Clear />
        </VmsButtonFAB>
        <VmsButtonFAB
          data-cy="signed-button-sign"
          onClick={this.handleSetAsSigned(documentId)}
          disabled={buttonsDisabled}
        >
          <Check />
        </VmsButtonFAB>
        {loading && (
          <CircularProgress className={classes.fabProgress} size={68} />
        )}
      </Aux>
    );
  }
}

DocumentSigningCanvas.propTypes = {
  classes: PropTypes.object.isRequired,
  documentId: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  documentData: PropTypes.shape({
    body: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DocumentSigningCanvas);
