import PropTypes from 'prop-types';
import { withStyles, Grid, Button } from '@material-ui/core';
import FolderOpen from '@material-ui/icons/FolderOpen';
import CheckCircle from '@material-ui/icons/CheckCircle';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Reorder from '@material-ui/icons/Reorder';
import Folder from '@material-ui/icons/Folder';
import Link from '@material-ui/icons/Link';
import EventNote from '@material-ui/icons/EventNote';
import Email from '@material-ui/icons/Email';
import Subject from '@material-ui/icons/Subject';
import AttachFile from '@material-ui/icons/AttachFile';
import WebAsset from '@material-ui/icons/WebAsset';
import i18n from 'assets/i18n';
import commonStyle from 'assets/jss/commonStyles';
import {
  VmsDialog,
  VmsInput,
  VmsDialogRowSelect,
  VmsDialogRowTextField,
  VmsImportData,
} from 'components';
import { other_categories, other_types } from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import * as EmailValidator from 'email-validator';
import ExportService from 'services/ExportService';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'services/PhotoUploadService';
import PhotoUploadService from '../../../services/PhotoUploadService';

const styles = (theme) => ({
  ...commonStyle(theme),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1 / 4),
  },
});

export const createEmptyOtherState = () => {
  return {
    _id: null,
    name: '',
    category: '',
    type: '',
    priority: 10,
    data: null,
  };
};

const otherDialog = (props) => {
  const { classes, onSave, onClose, onChange, open, item } = props;
  const saveDisabled =
    !item.name || !item.category || !item.type || !item.priority;

  const toSelectArray = (array) => {
    let selectArray = { key: [], value: [] };
    Object.keys(array).forEach((key, idx) => {
      selectArray.key[idx] = array[key];
      selectArray.value[idx] = array[key];
    });
    return selectArray;
  };

  const isString = (obj) => {
    return Object.prototype.toString.call(obj) === '[object String]';
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  let categories = toSelectArray(other_categories);
  let types = toSelectArray(other_types);

  let data = item.data;
  switch (item.type) {
    case other_types.URL:
    case other_types.TEXT:
      data = item.data && isString(item.data) ? item.data : '';
      break;
    case other_types.EMAIL:
      data = item.data
        ? item.data
        : {
            to: '',
            subject: '',
            body: '',
          };
      break;
    case other_types.CSV:
      data = item.data
        ? item.data
        : {
            data: null,
            subject: '',
            body: '',
          };
      break;
    case other_types.HTML:
      data = item.data ? item.data : '';
      break;
    case other_types.WYSIWYG:
      data = item.data ? item.data : { editor: undefined };
      break;
    default:
      break;
  }

  let importKeys = [];
  switch (item.category) {
    case other_categories.MEAL:
    case other_categories.REFRESHMENT:
      importKeys = ExportService.lunchKeys();
      break;
    default:
      break;
  }

  const handleImportCsv = (data) => {
    onChange('data.data', data)(); // call the on change function with data and empty event
  };

  const handleHTMLEditorChange = (value) => {
    onChange('data.editor', value)(); // call the on change function with data and empty event
  };

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={
        item._id
          ? i18n.t('others_dialog_title_update')
          : i18n.t('others_dialog_title_new')
      }
      onClose={onClose}
      dialogHeaderIcon={<InsertDriveFile />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onSave}
      buttonDisabled={saveDisabled}
      width={
        item.type === other_types.HTML || item.type === other_types.WYSIWYG
          ? 800
          : undefined
      }
    >
      <VmsInput
        rowClass={classes.dialogRow}
        iconLeft={<InsertDriveFile />}
        textValue={item.name}
        placeholder={i18n.t('others_name')}
        inputLabel={i18n.t('others_name')}
        onChange={onChange('name')}
        inputName={'name'}
        required
      />
      <VmsDialogRowSelect
        rowClass={classes.dialogRow2}
        iconLeft={<Folder />}
        placeholder={i18n.t('others_category')}
        onChange={onChange('category')}
        selectTypes={categories}
        selectedType={item.category}
        inputName={'category'}
        required
      />
      <VmsDialogRowSelect
        rowClass={classes.dialogRow2}
        iconLeft={<FolderOpen />}
        placeholder={i18n.t('others_type')}
        onChange={onChange('type')}
        selectTypes={types}
        selectedType={item.type}
        inputName={'type'}
        required
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Reorder />}
        textValue={item.priority.toString()}
        placeholder={i18n.t('others_priority')}
        inputLabel={i18n.t('others_priority')}
        onChange={onChange('priority')}
        inputName={'priority'}
        inputType={'number'}
        required
      />
      {item.type === other_types.URL && (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<Link />}
          textValue={data}
          placeholder={i18n.t('others_url')}
          inputLabel={i18n.t('others_url')}
          onChange={onChange('data')}
          inputName={'data'}
          inputError={!isValidUrl(data)}
        />
      )}
      {item.type === other_types.TEXT && (
        <VmsDialogRowTextField
          rowClass={classes.dialogRow2}
          iconLeft={<EventNote />}
          textValue={data}
          inputName={'data'}
          placeholder={i18n.t('others_text')}
          inputLabel={i18n.t('others_text')}
          onChange={onChange('data')}
          multiline={true}
          rows={5}
          maxLength={4096}
        />
      )}
      {item.type === other_types.EMAIL && (
        <Aux>
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<Email />}
            textValue={data.to}
            placeholder={i18n.t('others_email_to')}
            inputLabel={i18n.t('others_email_to')}
            onChange={onChange('data.to')}
            inputName={'data.to'}
            inputError={!EmailValidator.validate(data.to)}
          />
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<Subject />}
            textValue={data.subject}
            placeholder={i18n.t('others_email_subject')}
            inputLabel={i18n.t('others_email_subject')}
            onChange={onChange('data.subject')}
            inputName={'data.subject'}
            inputError={!data.subject || data.subject.length === 0}
          />
          <VmsDialogRowTextField
            rowClass={classes.dialogRow2}
            iconLeft={<EventNote />}
            textValue={data.body}
            inputName={'data.body'}
            placeholder={i18n.t('others_email_body')}
            inputLabel={i18n.t('others_email_body')}
            onChange={onChange('data.body')}
            multiline={true}
            rows={5}
            maxLength={4096}
          />
        </Aux>
      )}
      {item.type === other_types.CSV && (
        <Aux>
          <Grid
            container
            className={classes.dialogRow2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <div className={classes.icon}>
                <AttachFile />
              </div>
            </Grid>
            <Grid item className={classes.rowContent}>
              <VmsImportData
                importKeys={importKeys}
                onImportData={handleImportCsv}
              />
              {data && data.data && data.data.length > 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={props.onPreview}
                >
                  {i18n.t('preview')}
                </Button>
              )}
            </Grid>
          </Grid>
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<Subject />}
            textValue={data.subject}
            placeholder={i18n.t('others_email_subject')}
            inputLabel={i18n.t('others_email_subject')}
            onChange={onChange('data.subject')}
            inputName={'data.subject'}
            inputError={!data.subject || data.subject.length === 0}
          />
          <VmsDialogRowTextField
            rowClass={classes.dialogRow2}
            iconLeft={<EventNote />}
            textValue={data.body}
            inputName={'data.body'}
            placeholder={i18n.t('others_email_body')}
            inputLabel={i18n.t('others_email_body')}
            onChange={onChange('data.body')}
            multiline={true}
            rows={5}
            maxLength={4096}
          />
        </Aux>
      )}
      {item.type === other_types.WYSIWYG && (
        <Aux>
          <Grid
            container
            className={classes.dialogRow2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item style={{ alignSelf: 'self-start' }}>
              <div className={classes.icon}>
                <WebAsset />
              </div>
            </Grid>
            <Grid item className={classes.rowContent}>
              <Editor
                editorState={data.editor}
                wrapperClassName={classes.textEditor}
                editorClassName={classes.editor}
                placeholder={i18n.t('others_html_placeholder')}
                onEditorStateChange={handleHTMLEditorChange}
                toolbar={{
                  image: {
                    uploadEnabled: true,
                    uploadCallback: PhotoUploadService.uploadImageCallBack,
                    previewImage: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Aux>
      )}
      {item.type === other_types.HTML && (
        <VmsDialogRowTextField
          rowClass={classes.dialogRow2}
          iconLeft={<WebAsset />}
          textValue={data}
          inputName={'data'}
          placeholder={i18n.t('others_html_placeholder')}
          inputLabel={i18n.t('others_html_placeholder')}
          onChange={onChange('data')}
          multiline={true}
          rows={10}
        />
      )}
    </VmsDialog>
  );
};

otherDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    type: PropTypes.string,
    priority: PropTypes.number,
    data: PropTypes.any,
  }).isRequired,
  onPreview: PropTypes.func.isRequired,
};

otherDialog.defaultProps = {
  item: {
    _id: null,
    name: '',
    category: '',
    type: '',
    priority: 10,
    data: null,
  },
};

export default withStyles(styles)(otherDialog);
