import PropTypes from 'prop-types';
import { IconButton, Grid, Fab, Button } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Schedule from '@material-ui/icons/Schedule';
import Place from '@material-ui/icons/Place';
import Group from '@material-ui/icons/Group';
import EventNote from '@material-ui/icons/EventNote';
import Create from '@material-ui/icons/Create';
import Event from '@material-ui/icons/Event';
import moment from 'moment';
import classNames from 'classnames';
import i18n from '../../../assets/i18n';
import { date_formats } from '../../../AppSettings';

const MeetingFormDetail = (props) => {
  const {
    classes,
    onCancel,
    onEdit,
    editable,
    meetingData,
    showHeader,
    onDelete,
  } = props;

  const name = meetingData.name;
  const startTime = moment(meetingData.startDate);
  const endTime = moment(meetingData.endDate);
  var formattedEndTime = '';
  var formattedStartTime = '';
  if (startTime.isSame(endTime, 'day')) {
    formattedStartTime = startTime.format(date_formats.full_date_long);
    formattedEndTime = endTime.format(date_formats.hours);
  } else {
    formattedStartTime = startTime.format(date_formats.full_date_short);
    formattedEndTime = endTime.format(date_formats.full_date_short);
  }
  const formattedTime = `${formattedStartTime} - ${formattedEndTime}`;
  const place = meetingData.place;
  const participants = meetingData.participants;
  const notes = meetingData.notes;

  return (
    <div>
      <Grid container direction="column">
        {!showHeader ? null : (
          <Grid container alignItems="center" className={classes.modalHeader}>
            <Grid item xs className={classes.leftIconContainer}>
              <Event />
            </Grid>
            <Grid item xs={9}>
              <label data-cy="meeting-detail-text-name">{name}</label>
            </Grid>
            <Grid item xs>
              <IconButton
                data-cy="meeting-detail-button-close"
                onClick={onCancel}
                className={classes.modalButton}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Grid container alignItems="center" className={classes.modalRow}>
          <Grid item className={classes.leftIconContainer}>
            <Schedule />
          </Grid>
          <Grid item className={classes.modalRowRightSide}>
            <label data-cy="meeting-detail-text-date">{formattedTime}</label>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.modalRow}>
          <Grid item className={classes.leftIconContainer}>
            <Place />
          </Grid>
          <Grid item className={classes.modalRowRightSide}>
            <label data-cy="meeting-detail-text-room">{place}</label>
          </Grid>
        </Grid>
        {participants.length > 0 ? (
          participants.map((participant, idx) => (
            <Grid
              container
              key={idx}
              alignItems="center"
              className={classes.modalRow}
            >
              <Grid item className={classes.leftIconContainer}>
                {idx === 0 ? <Group /> : null}
              </Grid>
              <Grid item className={classes.modalRowRightSide}>
                <label data-cy="meeting-detail-list-item-participant">
                  {participant.user ? participant.user.name : participant.name}
                </label>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container alignItems="center" className={classes.modalRow}>
            <Grid item className={classes.leftIconContainer}>
              <Group />
            </Grid>
            <Grid item className={classes.modalRowRightSide}>
              <label data-cy="meeting-detail-list-item-participant">
                {i18n.t('meeting_rooms_form_no_participants')}
              </label>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          className={classNames(classes.modalRow, classes.modalRowMultiLine)}
          wrap="nowrap"
        >
          <Grid item className={classes.leftIconContainer}>
            <EventNote />
          </Grid>
          <Grid
            item
            className={classNames(
              classes.multilineContent,
              classes.modalRowRightSide
            )}
          >
            <label data-cy="meeting-detail-text-notes">{notes}</label>
          </Grid>
        </Grid>
      </Grid>
      {!editable ? (
        <div className={classes.detailDeleteButtonWrapper}>
          <Button
            data-cy="meeting-detail-button-delete"
            variant="contained"
            color="primary"
            className={classNames(
              classes.modalButton,
              classes.detailDeleteButton
            )}
            onClick={onDelete}
          >
            {i18n.t('meeting_rooms_form_delete_button')}
          </Button>
        </div>
      ) : (
        <Fab
          data-cy="meeting-detail-button-edit"
          color="primary"
          className={classes.floatingButton}
          onClick={onEdit}
        >
          <Create />
        </Fab>
      )}
    </div>
  );
};

MeetingFormDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  meetingData: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MeetingFormDetail;
