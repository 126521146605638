import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import classNames from 'classnames';
import { VmsTableHeader } from 'components';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FilterIconButton } from 'components/index';

const InspectionTable = ({
  classes,
  tableHeaderState,
  inspectionActivities,
  setTableHeaderRef,
  handleChangeTableHeader,
  handleRemoveSelectedItemsOpenDialog,
  page,
  rowsPerPage,
  highlightedId,
  handleClickRow,
  onEditModalOpen,
  menuAnchorEl,
  handleMenuOpen,
  handleOpenFilter,
}) => {
  const [t] = useTranslation();
  const isItemSelected = (tableHeaderState, item) =>
    tableHeaderState.selected.findIndex((i) => i._id === item._id) !== -1;

  return (
    <Table className={classes.table}>
      <VmsTableHeader
        onRef={setTableHeaderRef}
        onChange={handleChangeTableHeader}
        tableHeaderState={tableHeaderState}
        onRemoveSelectedItems={handleRemoveSelectedItemsOpenDialog}
        additionalButton={<FilterIconButton onClick={handleOpenFilter} />}
        prevPageButtonDataCy="settings-inspection-button-previous-page"
        nextPageButtonDataCy="settings-inspection-button-next-page"
      />
      <TableBody>
        {inspectionActivities
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item) => {
            const isSelected = isItemSelected(tableHeaderState, item);
            return (
              <TableRow
                key={item._id}
                hover
                role="checkbox"
                selected={isSelected || highlightedId === item._id}
                aria-checked={isSelected}
                className={classes.tableRow}
              >
                <TableCell
                  padding="checkbox"
                  className={classNames(
                    classes.tableCell,
                    classes.tableCellCheckbox
                  )}
                >
                  <Checkbox
                    checked={isSelected}
                    color="primary"
                    onClick={handleClickRow(item)}
                  />
                </TableCell>
                <TableCell
                  scope="row"
                  className={classes.tableCell}
                  padding="none"
                  onClick={onEditModalOpen(item)}
                >
                  <Typography
                    variant="body2"
                    className={classNames(
                      classes.header,
                      classes.tableInfoFont
                    )}
                    noWrap
                  >
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell
                  scope="row"
                  className={classNames(classes.tableCell)}
                  padding="none"
                  onClick={onEditModalOpen(item)}
                >
                  <Typography
                    variant="body2"
                    className={classNames(
                      classes.header,
                      classes.tableInfoFont
                    )}
                    noWrap
                  >
                    {t('menu_language_' + item.lang) + ` (${item.lang})`}
                  </Typography>
                </TableCell>
                <TableCell
                  scope="row"
                  className={classNames(
                    classes.tableCell,
                    classes.tableCellAlignLeft
                  )}
                  padding="none"
                  onClick={onEditModalOpen(item)}
                >
                  <Typography
                    variant="body2"
                    className={classNames(
                      classes.header,
                      classes.tableInfoFont,
                      classes.tableCellAlignLeft
                    )}
                    noWrap
                  >
                    {item.type}
                  </Typography>
                </TableCell>
                <TableCell
                  scope="row"
                  className={classNames(
                    classes.tableCell,
                    classes.tableCellCheckbox
                  )}
                  padding="none"
                >
                  <IconButton
                    className={classes.tableMenuButton}
                    aria-owns={menuAnchorEl ? 'table-menus' : null}
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, item)}
                  >
                    <MoreHoriz className={classes.tableIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

InspectionTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderState: PropTypes.object.isRequired,
  inspectionActivities: PropTypes.array.isRequired,
  setTableHeaderRef: PropTypes.func.isRequired,
  handleChangeTableHeader: PropTypes.func.isRequired,
  handleRemoveSelectedItemsOpenDialog: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  highlightedId: PropTypes.string,
  handleClickRow: PropTypes.func.isRequired,
  onEditModalOpen: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.object,
  handleMenuOpen: PropTypes.func.isRequired,
};

export default memo(InspectionTable);
