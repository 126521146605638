import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import clampText from 'assets/jss/clampText';
import { DocumentService } from 'services';
import ExploreStarContainer from './ExploreStarContainer';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flex: '0 0 70%',
    boxSizing: 'border-box',
  },
  image: {
    flex: '0 0 30%',
  },
  title: {
    ...clampText(theme.typography.h6.lineHeight, 1),
  },
  text: {
    ...clampText(theme.typography.body1.lineHeight, 1),
  },
});

const ExploreThingListItem = ({ classes, thing, onClick }) => {
  const { name, document, rating, reviews, text } = thing;
  const image = DocumentService.api + (document || {}).downloadUrl;

  return (
    <Card>
      <CardActionArea onClick={onClick(thing)}>
        <div className={classes.root}>
          <CardMedia className={classes.image} image={image} title={name} />
          <CardContent className={classes.content}>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h6"
              component="h1"
              data-cy="explore-more-text-thing-name"
            >
              {name}
            </Typography>
            <ExploreStarContainer rating={rating} reviews={reviews} />
            <Typography
              className={classes.text}
              variant="body1"
              color="textSecondary"
              component="p"
            >
              {text}
            </Typography>
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};

ExploreThingListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  thing: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(ExploreThingListItem));
