import { filter_types } from 'AppSettings';
import { useEffect, useMemo, useState } from 'react';
import { supported_languages } from 'AppSettings';
import { useImmer } from 'use-immer';

const getSupportedLanguageFilters = () => {
  const languageFilter = {};
  for (const lang of supported_languages) {
    languageFilter[lang] = true;
  }
  return languageFilter;
};

const useFilterInspection = (inspectionActivities, inspectionTypes) => {
  const [typeFilters, setTypeFilters] = useImmer({});
  const [languageFilter, setLanguageFilter] = useImmer(
    getSupportedLanguageFilters()
  );
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);

  const handleFilterCategoryClick = (key) => () => {
    setTypeFilters((draft) => {
      draft[key] = !draft[key];
    });
  };

  const handleFilterLanguageClick = (language) => () => {
    setLanguageFilter((draft) => {
      draft[language] = !draft[language];
    });
  };

  const handleOpenFilter = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterMenuAnchor(null);
  };

  const allTypeFilterChecked = () => {
    const filter = typeFilters;

    for (let status in filter) {
      if (filter.hasOwnProperty(status)) {
        if (!filter[status]) {
          return false;
        }
      }
    }

    return true;
  };

  const allLanguageFilterChecked = () => {
    const filter = languageFilter;

    for (let status in filter) {
      if (filter.hasOwnProperty(status)) {
        if (!filter[status]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleFilterAllClick = (filterType) => () => {
    let statusOfFilters = false;
    switch (filterType) {
      case filter_types.TYPE:
        statusOfFilters = allTypeFilterChecked();
        setTypeFilters((draft) => {
          if (!statusOfFilters) {
            for (let id in draft) {
              if (draft.hasOwnProperty(id)) {
                draft[id] = true;
              }
            }
          } else {
            for (let id in draft) {
              if (draft.hasOwnProperty(id)) {
                draft[id] = false;
              }
            }
          }
        });
        break;
      case filter_types.LANGUAGE:
        statusOfFilters = allLanguageFilterChecked();
        setLanguageFilter((draft) => {
          if (!statusOfFilters) {
            for (let id in draft) {
              if (draft.hasOwnProperty(id)) {
                draft[id] = true;
              }
            }
          } else {
            for (let id in draft) {
              if (draft.hasOwnProperty(id)) {
                draft[id] = false;
              }
            }
          }
        });
        break;
      default:
        break;
    }
  };

  const filteredInspectionActivities = useMemo(() => {
    return inspectionActivities.filter((insp) => {
      return typeFilters[insp.type] && languageFilter[insp.lang];
    });
  }, [inspectionActivities, typeFilters, languageFilter]);

  useEffect(() => {
    setTypeFilters(
      Object.fromEntries(inspectionTypes.map((activity) => [activity, true]))
    );
  }, [inspectionTypes, setTypeFilters]);

  return {
    typeFilters,
    languageFilter,
    filterMenuAnchor,
    filteredInspectionActivities,
    handleFilterAllClick,
    handleOpenFilter,
    handleCloseFilter,
    allTypeFilterChecked,
    allLanguageFilterChecked,
    handleFilterCategoryClick,
    handleFilterLanguageClick,
  };
};

export default useFilterInspection;
