import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyle from '../../../../assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const vmsDialogRowLabel = (props) => {
  const { textValue, iconLeft, classes, rowClass } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <Grid item>
          <div className={classes.icon}>{iconLeft}</div>
        </Grid>
      )}
      <Grid item className={classes.rowContent}>
        <label className={classes.label}>{textValue}</label>
      </Grid>
    </Grid>
  );
};

vmsDialogRowLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  textValue: PropTypes.string,
  rowClass: PropTypes.string,
};

export default withStyles(styles)(vmsDialogRowLabel);
