import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
} from '@material-ui/core';
import { Flight } from '@material-ui/icons';
import { Calendar } from '@material-ui/pickers';
import { elevation } from 'assets/UISettings';
import {
  LoadingBackdrop,
  useCloseDialogWithLoading,
  VmsInput,
  VmsLocalize,
} from 'components';
import { useTranslation } from 'react-i18next';

const FlightSearchDialog = ({
  classes,
  open,
  loading,
  fullScreen,
  flightNumber,
  flightDate,
  setFlightNumber,
  setFlightDate,
  onClose,
  handleFlight,
}) => {
  const [t] = useTranslation();

  const closeIfNotLoading = useCloseDialogWithLoading(onClose, loading);

  const onChangeFlightNumber = (event) => {
    setFlightNumber(event.target.value.trim());
  };

  return (
    <Dialog open={open} onClose={closeIfNotLoading} fullScreen={fullScreen}>
      <DialogContent>
        <DialogContentText>
          <VmsInput
            rowClass={classes.dialogRowDialog}
            iconLeft={<Flight />}
            textValue={flightNumber}
            placeholder={t('itinerary_entry_flight_number')}
            inputLabel={t('itinerary_entry_flight_number')}
            onChange={onChangeFlightNumber}
            inputName={'number'}
          />
          <Paper className={classes.paper} elevation={elevation.light}>
            <VmsLocalize>
              <div>
                <div className="picker">
                  <Calendar date={flightDate} onChange={setFlightDate} />
                </div>
              </div>
            </VmsLocalize>
          </Paper>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="itinerary-flight-button-cancel"
          onClick={onClose}
          color="primary"
        >
          {t('cancel')}
        </Button>
        <Button
          data-cy="itinerary-flight-button-search"
          onClick={handleFlight(flightNumber, flightDate)}
          color="primary"
          autoFocus
        >
          {t('search')}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loading} />
    </Dialog>
  );
};

FlightSearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  flightNumber: PropTypes.string,
  flightDate: PropTypes.object,
  setFlightNumber: PropTypes.func.isRequired,
  setFlightDate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleFlight: PropTypes.func.isRequired,
};

export default FlightSearchDialog;
