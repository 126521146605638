import PropTypes from 'prop-types';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

//Backdrop takes the entire width and height of the parent
const LoadingBackdrop = ({ loading }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={Boolean(loading)}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingBackdrop;
