import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const InspectionAllListItem = ({
  filterStatus,
  handleFilterAllClick,
  classes,
}) => {
  const [t] = useTranslation();
  return (
    <ListItem
      className={
        !filterStatus ? classes.singleNestedMenuItem : classes.nestedMenuItem
      }
      component="li"
      button
      onClick={handleFilterAllClick}
    >
      {filterStatus && (
        <ListItemIcon className={classes.icon}>
          <DoneAllOutlinedIcon />
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.menuColor }}
        primary={t(!filterStatus ? `select_all` : `deselect_all`)}
      />
    </ListItem>
  );
};

InspectionAllListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  filterStatus: PropTypes.bool.isRequired,
  handleFilterAllClick: PropTypes.func.isRequired,
};

export default InspectionAllListItem;
