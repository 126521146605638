import Loadable from 'react-loadable';
import Loading from 'components/common/Loading';

const EditDocumentDialog = Loadable({
  loader: () =>
    import('./EditDocumentDialog') /* webpackChunkName: "EditDocumentDialog" */,
  loading: Loading,
});

export { EditDocumentDialog };
