import PropTypes from 'prop-types';
import {
  Modal,
  makeStyles,
  Paper,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { PermIdentity } from '@material-ui/icons';
import { browser_language, findCheckedInspectionForDate } from 'AppSettings';
import modalStyle from 'assets/jss/modalStyle';
import { elevation } from 'assets/UISettings';
import classNames from 'classnames';
import { openSnackbar } from 'components/common/bars';
import { useCloseDialogWithLoading } from 'components/common/hooks';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentService,
  InspectionService,
  TemplateService,
  VisitService,
} from 'services';
import { useImmer } from 'use-immer';
import { VmsDialogHeader } from '../header';
import { CompanyDataContext } from 'components';

const useStyles = makeStyles((theme) => ({
  ...modalStyle(theme, 800),
  paperDialog: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonBasic: {
    color: theme.palette.common.white,
  },
  container: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
    width: '100%',
    margin: 0,
  },
  buttonRow: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(),
  },
  buttonsPagination: {
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
      flex: 1,
    },
  },
  confirmWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  htmlContainer: {
    breakWord: 'break-word',
  },
  pagination: {
    width: 'initial',
  },
}));

const InspectionDialog = ({ open, onClose, onCheck, visit, selectedDate }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { companyData, loadingCompanyData } = useContext(CompanyDataContext);

  //A copy is needed because after checking an activity we want to see it as checked
  const [visitCopy, setVisitCopy] = useImmer({});

  //Inspection activites texts
  const [inspectionActivities, setInspectionActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);

  //Currently displayed activity
  const [currentActivityIndex, setCurrentActivityIndex] = useState(-1);

  //Process of checking an activity
  const [checkingActivity, setCheckingActivity] = useState(false);

  //Loading
  const loading = loadingActivities || checkingActivity || loadingCompanyData;
  const closeIfNotLoading = useCloseDialogWithLoading(onClose, loading);

  const validActivityIndex = currentActivityIndex >= 0;

  //Stuff from inspection activity texts
  const currentActivity = validActivityIndex
    ? inspectionActivities[currentActivityIndex]
    : {};
  const { document, text, name } = currentActivity;
  const { downloadUrl } = document || {};

  //Stuff from the concrete inspection activity in the visit
  const currentActivityVisit = validActivityIndex
    ? visitCopy.inspectionActivities[currentActivityIndex]
    : {};
  const { _id } = currentActivityVisit;
  const checked = Boolean(
    findCheckedInspectionForDate(currentActivityVisit.inspections, selectedDate)
  );

  useEffect(() => {
    if (open) {
      setVisitCopy({ ...visit });
    } else {
      setVisitCopy({});
    }
  }, [open, visit, setVisitCopy]);

  useEffect(() => {
    if (open) {
      const loadInspectionActivities = async () => {
        setLoadingActivities(true);

        try {
          const promises = [];
          visit.inspectionActivities.forEach((activityType) => {
            promises.push(
              new Promise(async (resolve, reject) => {
                const response =
                  await InspectionService.getInspectionActivitiesForType(
                    activityType.type
                  );
                const activity =
                  TemplateService.findNotificationTemplateByLanguage(
                    response,
                    browser_language(),
                    companyData.location.language
                  );

                if (!activity) {
                  reject(
                    new Error(
                      t('inspection_no_activity', { type: activityType.type })
                    )
                  );
                }
                resolve(activity);
              })
            );
          });
          const responses = await Promise.all(promises);
          setInspectionActivities(responses);
          setCurrentActivityIndex(
            visit.inspectionActivities.findIndex(
              (act) =>
                !findCheckedInspectionForDate(act.inspections, selectedDate)
            )
          );
        } catch (err) {
          console.log(err);
          openSnackbar(err.message);
          onClose();
        } finally {
          setLoadingActivities(false);
        }
      };

      loadInspectionActivities();
    } else {
      setCurrentActivityIndex(-1);
    }
  }, [open, onClose, t, visit, companyData.location.language, selectedDate]);

  const onBack = () => {
    setCurrentActivityIndex((prevIndex) => --prevIndex);
  };

  const onNext = () => {
    setCurrentActivityIndex((prevIndex) => ++prevIndex);
  };

  const checkActivity = async () => {
    setCheckingActivity(true);

    try {
      await VisitService.checkInspectionActivity(visit._id, _id);

      onCheck();
      openSnackbar(t('inspection_dialog_checked'));

      setVisitCopy((draft) => {
        draft.inspectionActivities[currentActivityIndex].inspections.push({
          checked_date: new Date(),
        });
      });

      //Skip current index since setVisitCopy is not sync
      const nextActivityIndex = visitCopy.inspectionActivities.findIndex(
        (act, i) =>
          !findCheckedInspectionForDate(act.inspections, selectedDate) &&
          i !== currentActivityIndex
      );
      if (nextActivityIndex === -1) {
        onClose();
      } else {
        setCurrentActivityIndex(nextActivityIndex);
      }
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setCheckingActivity(false);
    }
  };

  return (
    <Modal className={classes.modal} open={open} onClose={closeIfNotLoading}>
      <Paper
        className={classNames(classes.paper, classes.paperDialog)}
        elevation={elevation.light}
      >
        <VmsDialogHeader
          closeButtonDataCy="inspection-button-close"
          title={name}
          onClose={onClose}
          icon={<PermIdentity />}
        />

        <Grid
          container
          className={classes.container}
          alignContent="stretch"
          spacing={2}
        >
          <Grid item container md={5} alignContent="center">
            {downloadUrl && (
              <img
                src={DocumentService.api + downloadUrl}
                className={classes.image}
                alt="Inspection activity"
              />
            )}
          </Grid>
          <Grid item container md={7} alignContent="center">
            <div
              className={classes.htmlContainer}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Grid>
        </Grid>

        <Grid className={classes.buttonRow} container spacing={2}>
          <Grid
            item
            container
            className={classes.buttonsPagination}
            justifyContent="space-between"
          >
            <Button
              data-cy="inspection-button-back"
              color="primary"
              variant="contained"
              className={classes.buttonBasic}
              disabled={!validActivityIndex || currentActivityIndex === 0}
              onClick={onBack}
            >
              {t('back')}
            </Button>
            <Grid
              item
              container
              className={classes.pagination}
              alignContent="center"
            >
              <Typography variant="subtitle1" component="span">
                {currentActivityIndex + 1} / {inspectionActivities.length}
              </Typography>
            </Grid>
            <Button
              data-cy="inspection-button-next"
              color="primary"
              variant="contained"
              className={classes.buttonBasic}
              disabled={
                !validActivityIndex ||
                currentActivityIndex === inspectionActivities.length - 1
              }
              onClick={onNext}
            >
              {t('next')}
            </Button>
          </Grid>

          <Grid
            item
            container
            className={classes.confirmWrapper}
            justifyContent="flex-end"
          >
            <Button
              data-cy="inspection-button-confirm"
              color="primary"
              variant="contained"
              className={classes.buttonBasic}
              disabled={!validActivityIndex || checked}
              onClick={checkActivity}
            >
              {t('check')}
            </Button>
          </Grid>
        </Grid>
        <LoadingBackdrop loading={loading} />
      </Paper>
    </Modal>
  );
};

InspectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  visit: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
};

export default InspectionDialog;
