import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Button,
} from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Email from '@material-ui/icons/Email';
import AccessTime from '@material-ui/icons/AccessTime';
import Place from '@material-ui/icons/Place';
import People from '@material-ui/icons/People';
import EventNote from '@material-ui/icons/EventNote';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import LinearScale from '@material-ui/icons/LinearScale';
import Map from '@material-ui/icons/Map';
import WatchLater from '@material-ui/icons/WatchLater';
import { elevation } from 'assets/UISettings';
import { DocumentService, PassService, SecurityItemsService } from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import appleWalletLogo from 'assets/images/apple_wallet_en.svg';
import googlePayLogo from 'assets/images/save_to_google_pay_dark.png';
import QRCode from 'qrcode.react';
import i18n from 'assets/i18n';
import { ReactComponent as QRIcon } from 'assets/images/QR_icon.svg';
import { Announcement, EmojiPeople, HowToRegRounded } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import {
  avatar_mapping,
  visit_card_modes,
  visit_event_status_constants,
  visit_visitor_status_constants,
} from 'AppSettings';
import QRActivationRow from './QRActivationRow';

const styles = (theme) => ({
  grid: {
    height: 142,
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    borderStyle: 'solid',
    borderColor: theme.palette.white,
  },
  color: {
    color: theme.palette.primary4,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.primary4,
  },
  contact: {
    color: theme.palette.primary4,
  },
  visitIcon: {
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
  },
  avatar: {
    width: 41,
    height: 38,
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      width: 110,
      height: 110,
      marginBottom: 0,
    },
  },
  centerText: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  qrCode: {
    paddingTop: 16,
  },
  buttonsContainer: {
    textAlign: 'center',
  },
  showQRButton: {
    background: 'black',
    color: 'white',
    textTransform: 'none',
  },
  selectedVisit: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  googleLogo: {
    maxWidth: 'min(100%, 272px)',
  },
  qrIcon: {
    width: 24,
    height: 24,
    '& g': {
      stroke: 'white',
    },
  },
  requiredDocsWrapper: {
    alignItems: 'flex-start',
  },
});

class VisitCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      photoId: '',
      appleWalletDownloadUrl: null,
      googlePayJwt: null,
      showQR: false,
      qrCodeActive: false,
    };
  }

  loadPhotoUrl = (photoId) => {
    const self = this;
    if (photoId) {
      DocumentService.getDocument(photoId)
        .then(function (response) {
          self.setState({
            photoId: photoId,
            photoUrl: response.downloadUrl,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  componentDidMount() {
    const { mode, visit } = this.props;
    this.loadPhotoUrl(visit[mode].photo);
    if (mode === visit_card_modes.VISITOR) {
      this.loadAppleWalletUrl();
      this.loadGooglePayJwt();
      this.loadSecurityItem();
    }
  }

  componentDidUpdate(prevProps) {
    const { mode, visit } = this.props;
    if (visit[mode].photo !== prevProps.visit[prevProps.mode].photo) {
      this.loadPhotoUrl(visit[mode].photo);
    }
  }

  loadAppleWalletUrl = () => {
    const self = this;
    PassService.getApplePassForVisit(this.props.visit._id)
      .then(function (response) {
        self.setState({
          appleWalletDownloadUrl: response.downloadUrl,
        });
      })
      .catch(function (error) {
        if (!error.message.includes('404')) {
          // Not found is valid case, security item is invalid or validTo is in past, so we dont render button
          console.log(error);
          openSnackbar(error.message);
        }
      });
  };

  loadGooglePayJwt = () => {
    const self = this;
    PassService.getGooglePayJwtForVisit(this.props.visit._id)
      .then(function (response) {
        self.setState({
          googlePayJwt: response.google_pay_jwt,
        });
      })
      .catch(function (error) {
        if (!error.message.includes('404')) {
          // Not found is valid case, security item is invalid or validTo is in past, so we dont render button
          console.log(error);
          openSnackbar(error.message);
        }
      });
  };

  loadSecurityItem = () => {
    SecurityItemsService.getActiveSecurityItemForVisit(this.props.visit._id)
      .then((response) => {
        this.setState({
          securityItem: response,
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  openOrCloseQRCode = () => {
    this.setState((prevState) => {
      return {
        showQR: !prevState.showQR,
      };
    });
  };

  setQrCodeActive = (active) => {
    this.setState({ qrCodeActive: active });
  };

  render() {
    const {
      classes,
      visit,
      onClick,
      isSelected,
      onVisitChange,
      timezone,
      gridRef,
      index,
      mode,
      theme,
    } = this.props;
    const {
      timeFrom: date,
      address,
      purpose,
      note,
      accompanimentRequired,
      accompaniments,
    } = visit;
    const { name, email, phone, company, avatar } = visit[mode];

    const {
      photoUrl,
      appleWalletDownloadUrl,
      googlePayJwt,
      showQR,
      securityItem,
      qrCodeActive,
    } = this.state;
    const imgUrl = avatar
      ? avatar_mapping[avatar]
      : photoUrl
      ? DocumentService.api + photoUrl
      : '/images/avatar.png';
    const dateMoment = moment(date);
    const dateTimezone = dateMoment.clone().tz(timezone);
    const accompanimentExists = accompaniments?.find((acc) =>
      moment(acc.accompanied_date).isSame(moment(), 'day')
    );

    const now = moment();

    const lastEvent = visit.events
      .filter((event) => {
        const timestamp = moment(event.eventTimestamp);

        return (
          [
            visit_event_status_constants.EMERGENCY,
            visit_event_status_constants.SIGNED_IN,
            visit_event_status_constants.SIGNED_OUT,
          ].includes(event.type) && now.isSame(timestamp, 'day')
        );
      })
      .reduce((oldestEvent, nextEvent, index, array) => {
        if (!array.length < 2) {
          return nextEvent;
        }

        const oldestTimestamp = moment(oldestEvent.eventTimestamp);
        const nextTimestamp = moment(nextEvent.timestamp);

        return nextTimestamp.isSameOrAfter(oldestTimestamp)
          ? nextTimestamp
          : oldestTimestamp;
      }, null);

    const visitStatus = !lastEvent
      ? visit_visitor_status_constants.INVITED
      : lastEvent.type === visit_event_status_constants.SIGNED_IN
      ? visit_visitor_status_constants.CONFIRMED
      : visit_visitor_status_constants.CLOSED;

    return (
      <Grid data-cy="visit-card-list-item-visit" item xs={12}>
        {/*If there's a selected visit, this component is used for visitor and not for profile and thus is clickable*/}
        <Paper
          ref={gridRef}
          className={classNames(
            classes.paper,
            isSelected ? classes.selectedVisit : undefined
          )}
          elevation={elevation.light}
          onClick={onVisitChange ? () => onVisitChange(visit) : undefined}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={2}>
              <Avatar src={imgUrl} className={classes.avatar} />
            </Grid>
            <Grid item container xs={12} md={9}>
              <Grid item className={classes.centerText} xs={12} md={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid className={classes.item} item>
                    <People className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {name}
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <Email className={classes.visitIcon} />
                    <Typography
                      className={classes.contact}
                      variant="body1"
                      noWrap
                    >
                      {email}
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <PhoneIphone className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {phone}
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <EventNote className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {note}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.centerText} xs={12} md={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid className={classes.item} item>
                    <AccessTime className={classes.visitIcon} />
                    <Typography variant="body1" className={classes.contact}>
                      {`${dateMoment.format('LL HH:mm')} ${moment.tz
                        .guess()
                        .replace('_', ' ')}`}{' '}
                      ({i18n.t('yourTimezone')})
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <BusinessCenter className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {company}
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <Map className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {address}
                    </Typography>
                  </Grid>
                  {mode === visit_card_modes.VISITOR && (
                    <Grid className={classes.item} item>
                      <WatchLater className={classes.visitIcon} />
                      <Typography
                        variant="body1"
                        className={classes.contact}
                        //noWrap
                      >
                        {`${dateTimezone.format('LL HH:mm')} ${timezone.replace(
                          '_',
                          ' '
                        )}`}{' '}
                        ({i18n.t('hostTimezone')})
                      </Typography>
                    </Grid>
                  )}
                  {mode === visit_card_modes.HOST && accompanimentRequired && (
                    <Grid className={classes.item} item>
                      <EmojiPeople
                        color={accompanimentExists ? undefined : 'primary'}
                        className={classes.visitIcon}
                      />
                      <Typography
                        variant="body1"
                        className={classes.contact}
                        noWrap
                      >
                        {i18n.t('invite_form_accompaniment_required')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item className={classes.centerText} xs={12} md={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid className={classes.item} item>
                    <Place className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {purpose}
                    </Typography>
                  </Grid>
                  <Grid className={classes.item} item>
                    <LinearScale className={classes.visitIcon} />
                    <Typography
                      variant="body1"
                      className={classes.contact}
                      noWrap
                    >
                      {i18n.t(`visitor_${visitStatus}`)}
                    </Typography>
                  </Grid>
                  {visit.requiresDocs && (
                    <Grid
                      className={classNames(
                        classes.item,
                        classes.requiredDocsWrapper
                      )}
                      item
                    >
                      <Announcement
                        className={classes.visitIcon}
                        color="primary"
                      />
                      <Typography variant="body1" className={classes.contact}>
                        {i18n.t('required_document_review')}
                      </Typography>
                    </Grid>
                  )}
                  {visit.confirmationRequired && (
                    <Grid className={classNames(classes.item)} item>
                      <HowToRegRounded
                        className={classes.visitIcon}
                        color="primary"
                      />
                      <Typography variant="body1" className={classes.contact}>
                        {i18n.t('confirmation_required')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {mode === visit_card_modes.VISITOR &&
                securityItem &&
                securityItem.authenticationRequired && (
                  <QRActivationRow
                    securityItem={securityItem}
                    setQrCodeActive={this.setQrCodeActive}
                  />
                )}

              {mode === visit_card_modes.VISITOR && (
                <>
                  {appleWalletDownloadUrl != null && (
                    <Grid item xs={12} md={4}>
                      <a
                        href={PassService.api + appleWalletDownloadUrl}
                        download
                      >
                        <img src={appleWalletLogo} alt="Apple Wallet" />
                      </a>
                    </Grid>
                  )}
                  {googlePayJwt != null && (
                    <Grid item xs={12} md={4}>
                      <a
                        href={`https://www.android.com/payapp/savetoandroidpay/${googlePayJwt}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className={classes.googleLogo}
                          src={googlePayLogo}
                          alt="Google Pay"
                        />
                      </a>
                    </Grid>
                  )}
                  {securityItem && (
                    <Grid item xs={12} md={4}>
                      <Button
                        data-cy="visit-card-button-show-qr"
                        variant="contained"
                        disableRipple
                        onClick={this.openOrCloseQRCode}
                        style={{ backgroundColor: 'black', boxShadow: 'none' }} //Sadly has to be here, otherwise it wouldnt work
                        className={classes.showQRButton}
                      >
                        <QRIcon className={classes.qrIcon} />
                        &nbsp;
                        {showQR
                          ? i18n.t('profile_card_visit_hide_access_code')
                          : i18n.t('profile_card_visit_show_access_code')}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {mode === visit_card_modes.HOST && onClick && (
              <Grid item xs={12} md={1}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <IconButton
                      data-cy="visit-card-button-details"
                      onClick={() => onClick(visit, index)}
                    >
                      <KeyboardArrowRight className={classes.color} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          {mode === visit_card_modes.VISITOR && securityItem && showQR && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.qrCode}
            >
              <Grid item>
                <QRCode
                  fgColor={
                    securityItem.authenticationRequired &&
                    !securityItem.confirmedUses &&
                    !qrCodeActive
                      ? theme.palette.action.disabled
                      : undefined
                  }
                  value={securityItem.securityItem}
                  size={254}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    );
  }
}

VisitCard.propTypes = {
  classes: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  gridRef: PropTypes.func,
  onVisitChange: PropTypes.func,
  timezone: PropTypes.string,
  index: PropTypes.number,
};

export default React.memo(withStyles(styles, { withTheme: true })(VisitCard));
