import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Image from '@material-ui/icons/Image';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from 'assets/i18n';
import { isiOSMobileApp } from 'AppSettings';

const styles = (theme) => ({
  fontColor: {
    color: theme.palette.primary6,
  },
});

const PhotoDialog = (props) => {
  const { classes, open, itemDataCy } = props;

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" className={classes.fontColor}>
        {i18n.t('profile_photo_dialog_title')}
      </DialogTitle>
      <div>
        <List>
          {!isiOSMobileApp() === true && (
            <ListItem data-cy={itemDataCy} button onClick={props.takePhoto}>
              <PhotoCamera className={classes.fontColor} />
              <ListItemText
                primary={i18n.t('profile_photo_dialog_photo')}
                className={classes.fontColor}
              />
            </ListItem>
          )}
          <ListItem data-cy={itemDataCy} button onClick={props.browse}>
            <Image className={classes.fontColor} />
            <ListItemText
              primary={
                isiOSMobileApp() === true
                  ? i18n.t('profile_photo_dialog_browse_ios')
                  : i18n.t('profile_photo_dialog_browse')
              }
              className={classes.fontColor}
            />
          </ListItem>
        </List>
      </div>
    </Dialog>
  );
};

PhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  takePhoto: PropTypes.func.isRequired,
  browse: PropTypes.func.isRequired,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles)(PhotoDialog);
