import PropTypes from 'prop-types';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import classNames from 'classnames';
import { VmsInput } from 'components/common/material-ui';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.required,
  },
  iconWrapper: {
    paddingRight: theme.spacing(2),
    flex: 0,
  },
  listWrapper: {
    flex: 1,
    width: '100%',
    margin: 0,
  },
  removeButton: {
    width: 24,
    height: 24,
  },
  chipWrapper: {
    maxWidth: '100%',
  },
  chip: {
    maxWidth: '100%',
  },
  selected: {
    border: `solid ${theme.palette.primary.main}`,
  },
  error: {
    border: `solid ${theme.palette.error.main}`,
  },
  inputListWrapper: {
    flex: 1,
  },
  input: {
    paddingBottom: theme.spacing(),
  },
  groupSize: {
    maxWidth: 24, //Otherwise text-overflow wouldnt work
  },
}));

const GroupVisitorList = ({
  dialogRow,
  invitationState,
  handleRemoveVisitorFromGroupVisit,
  handleChangeCurrentVisitFromGroup,
  disabledSwitching,
  visitClosed,
  changeGroupName,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { invitations, size, index, group } = invitationState;

  return (
    <Grid container className={dialogRow}>
      <Grid item container className={classes.iconWrapper} direction="column">
        <div className={classes.icon}>
          <GroupIcon />
        </div>
        <Grid item container justifyContent="center">
          <Typography className={classes.groupSize} variant="caption" noWrap>
            {size}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        className={classes.inputListWrapper}
        direction="column"
      >
        <VmsInput
          rowClass={classes.input}
          textValue={group}
          inputName="group"
          placeholder={t('invite_form_group')}
          inputLabel={t('invite_form_group')}
          onChange={changeGroupName}
          disabled={visitClosed}
        />
        <Grid
          item
          container
          spacing={1}
          className={classes.listWrapper}
          zeroMinWidth
        >
          {invitations.map((invitation, i) => {
            const selected = i === index;

            return (
              <Grid item key={`${invitation.email}#${invitation.phone}`}>
                <Chip
                  data-cy="invitation-button-select-group-user"
                  className={classNames(
                    classes.chip,
                    selected ? classes.selected : undefined,
                    selected && disabledSwitching && !visitClosed
                      ? classes.error
                      : undefined
                  )}
                  label={invitation.name}
                  disabled={disabledSwitching && !selected}
                  onClick={handleChangeCurrentVisitFromGroup(i)}
                  onDelete={
                    !visitClosed
                      ? handleRemoveVisitorFromGroupVisit(i)
                      : undefined
                  }
                  deleteIcon={
                    <Cancel data-cy="invitation-button-delete-group-user" />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

GroupVisitorList.propTypes = {
  dialogRow: PropTypes.string.isRequired,
  invitationState: PropTypes.object.isRequired,
  handleRemoveVisitorFromGroupVisit: PropTypes.func.isRequired,
  handleChangeCurrentVisitFromGroup: PropTypes.func.isRequired,
  disabledSwitching: PropTypes.bool.isRequired,
  visitClosed: PropTypes.bool.isRequired,
  changeGroupName: PropTypes.func.isRequired,
};

export default GroupVisitorList;
