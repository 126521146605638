import PropTypes from 'prop-types';
import { withStyles, Grid, IconButton, Typography } from '@material-ui/core';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Cancel from '@material-ui/icons/Cancel';
import Refresh from '@material-ui/icons/Refresh';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import i18n from 'i18next';
import moment from 'moment';
import { invite_tab_indexes, visit_event_status_constants } from 'AppSettings';
import { memo } from 'react';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  documentStatus: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  documentLabelItem: {
    flexGrow: 1,
  },
  documentLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    maxWidth: 132,
  },
  tabContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  iconContainer: {
    width: theme.spacing(8),
    marginLeft: -12,
  },
  eventRow: {
    margin: `0 ${theme.spacing(2)}px`,
  },
});

const EventsTab = ({
  classes,
  events,
  securityItems,
  emailLogs,
  onRemoveEvent,
  onSecurityItemChange,
  selectedDate,
  userClosed,
  visitClosed,
}) => {
  let maxDateEventId;
  let maxDate = moment(0);

  const signedIn = events.find((event) => {
    const eventTimestamp = moment(event.eventTimestamp);
    return (
      event.type === visit_event_status_constants.SIGNED_IN &&
      eventTimestamp.isSame(selectedDate, 'day')
    );
  });
  const EmergencyEmails = events.filter((e) => {
    const eventDate = moment(e.created_date);
    return (
      e.type === visit_event_status_constants.EMERGENCY &&
      eventDate.isSame(selectedDate, 'day')
    );
  });
  if (signedIn) {
    const eventTimestamp = moment(signedIn.eventTimestamp);
    if (eventTimestamp.isAfter(maxDate)) {
      maxDateEventId = signedIn._id;
      maxDate = eventTimestamp;
    }
  }

  const signedOut = events.find((event) => {
    const eventTimestamp = moment(event.eventTimestamp);
    return (
      event.type === visit_event_status_constants.SIGNED_OUT &&
      eventTimestamp.isSame(selectedDate, 'day')
    );
  });
  if (signedOut) {
    const eventTimestamp = moment(signedOut.eventTimestamp);
    if (eventTimestamp.isAfter(maxDate)) {
      maxDateEventId = signedOut._id;
      maxDate = eventTimestamp;
    }
  }

  let eventsToRender = [];
  if (signedIn)
    eventsToRender.push({
      id: signedIn._id,
      type: visit_event_status_constants.SIGNED_IN,
      label: i18n.t('visitor_signed_in'),
      status: moment(signedIn.eventTimestamp).format(
        i18n.t('visitor_local_date_full')
      ),
    });
  if (signedOut)
    eventsToRender.push({
      id: signedOut._id,
      type: visit_event_status_constants.SIGNED_OUT,
      label: i18n.t('visitor_signed_out'),
      status: moment(signedOut.eventTimestamp).format(
        i18n.t('visitor_local_date_full')
      ),
    });

  try {
    securityItems
      .filter((item) => {
        const validFrom = moment(item.validFrom);
        return validFrom.isSame(selectedDate, 'day');
      })
      .forEach((item, index, filteredItems) => {
        //Render buttons only on the last security item
        const isLast = index === filteredItems.length - 1;
        if (new Date(item.validTo) < new Date()) {
          item.status = 'expired';
        }
        eventsToRender.push({
          type: 'securityItem',
          item: item,
          label: i18n.t('securityItem'),
          subLabel: i18n.t(item.status),
          status: moment(item.validFrom).format(
            i18n.t('visitor_local_date_full')
          ),
          subStatus: moment(item.validTo).format(
            i18n.t('visitor_local_date_full')
          ),
          isLast,
        });
      });
  } catch (error) {
    console.log(error);
  }
  try {
    emailLogs.forEach((log) => {
      eventsToRender.push({
        type: 'email',
        label: i18n.t('email'),
        subLabel: log.subject,
        status: moment(log.created_date).format(
          i18n.t('visitor_local_date_full')
        ),
        subStatus: log.status,
      });
    });
  } catch (error) {
    console.log(error);
  }

  if (EmergencyEmails) {
    EmergencyEmails.reverse().forEach((emergencyEmail) => {
      eventsToRender.push({
        id: EmergencyEmails[0]._id,
        type: visit_event_status_constants.EMERGENCY,
        label: i18n.t('app_settings_template_emergency_message'),
        status: moment(emergencyEmail.created_date).format(
          i18n.t('visitor_local_date_full')
        ),
      });
    });
  }
  let eventsChildren = [];

  eventsToRender.forEach((event, i) => {
    // Default = this is just empty placeholder
    let removeElement = (
      <IconButton className={classes.iconLeft} disabled={true}></IconButton>
    );

    // SignIn - SignOut event
    if (
      event.type === visit_event_status_constants.SIGNED_IN ||
      event.type === visit_event_status_constants.SIGNED_OUT
    ) {
      const signRemoveDisabled = event.id !== maxDateEventId;
      removeElement = (
        <IconButton
          className={classes.iconLeft}
          onClick={onRemoveEvent(event)}
          disabled={userClosed || signRemoveDisabled || visitClosed}
        >
          <RemoveCircleOutline />
        </IconButton>
      );
    }

    // Security Item
    if (event.type === 'securityItem' && event.isLast) {
      const action = event.item.status === 'valid' ? 'invalidate' : 'refresh';
      const icon = event.item.status === 'valid' ? <Cancel /> : <Refresh />;
      removeElement = (
        <IconButton
          className={classes.iconLeft}
          onClick={onSecurityItemChange(event.item, action)}
          disabled={userClosed || visitClosed}
        >
          {icon}
        </IconButton>
      );
    }

    eventsChildren.push(
      <Grid item key={`event_${i}`} className={classes.eventRow}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item className={classes.iconContainer}>
            {removeElement}
          </Grid>
          <Grid item className={classes.documentLabelItem}>
            <Typography className={classes.documentLabel} noWrap>
              {event.label}
            </Typography>
            <Typography className={classes.documentLabel} noWrap>
              {event.subLabel}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.documentStatus} noWrap>
              {event.status}
            </Typography>
            <Typography
              className={classes.documentStatus}
              noWrap
              align="center"
            >
              {event.subStatus}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid item className={classes.tabContainer}>
      <Grid
        container
        className={classes.contentOverflow}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.rowContent}>
          <Grid container direction="column">
            {eventsChildren}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

EventsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
  onSecurityItemChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  securityItems: PropTypes.array.isRequired,
  emailLogs: PropTypes.array.isRequired,
  userClosed: PropTypes.bool.isRequired,
  visitClosed: PropTypes.bool.isRequired,
};

const skipUpdate = (prevProps, nextProps) => {
  const currentTab = nextProps.currentTab || prevProps.currentTab;
  return currentTab !== invite_tab_indexes.EVENTS;
};

export default withStyles(styles)(memo(EventsTab, skipUpdate));
