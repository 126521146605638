import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import commonStyle from '../../../assets/jss/commonStyles';

const styles = (theme) => ({
  required: commonStyle(theme).required,
});

const RequiredIcon = ({ classes, required, children }) => {
  if (!required) {
    return children;
  }

  return Children.map(children, (icon) => {
    const className = classNames(icon.props.className, classes.required);
    const props = { ...icon.props, className };

    return cloneElement(icon, props);
  });
};

RequiredIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export default withStyles(styles)(RequiredIcon);
