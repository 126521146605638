import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import i18n from 'assets/i18n';

const InstanceDialog = ({ open, handleCloseDialog }) => (
  <Dialog open={open}>
    <DialogTitle>{i18n.t('instance_open')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {i18n.t('instance_open_description')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDialog} color="primary" autoFocus>
        {i18n.t('confirm')}
      </Button>
    </DialogActions>
  </Dialog>
);

InstanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default InstanceDialog;
