import PropTypes from 'prop-types';
import { makeStyles, Grid, Button } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  buttonBasic: {
    color: theme.palette.common.white,
  },
  fabContainer: {
    width: '100%',
    margin: 0,
  },
}));

const AddRemoveVisitor = ({
  dialogRow,
  addButtonDisabled,
  removeButtonEnabled,
  handleAddVisitorToGroupVisit,
  handleRemoveVisitorFromGroupVisit,
  index,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid
      container
      spacing={2}
      className={classNames(dialogRow, classes.fabContainer)}
      justifyContent="flex-end"
    >
      <Grid item>
        <Button
          data-cy="invitation-button-remove-visitor"
          className={classes.buttonBasic}
          color="primary"
          variant="contained"
          disabled={!removeButtonEnabled}
          onClick={handleRemoveVisitorFromGroupVisit(index)}
        >
          {t('invite_form_remove_visitor')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-cy="invitation-button-add-visitor"
          className={classes.buttonBasic}
          color="primary"
          variant="contained"
          disabled={addButtonDisabled}
          onClick={handleAddVisitorToGroupVisit}
        >
          {t('invite_form_add_visitor')}
        </Button>
      </Grid>
    </Grid>
  );
};

AddRemoveVisitor.propTypes = {
  dialogRow: PropTypes.string.isRequired,
  addButtonDisabled: PropTypes.bool.isRequired,
  removeButtonEnabled: PropTypes.bool.isRequired,
  handleAddVisitorToGroupVisit: PropTypes.func.isRequired,
  handleRemoveVisitorFromGroupVisit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default AddRemoveVisitor;
