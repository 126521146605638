import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import i18n from 'assets/i18n';

const styles = (theme) => ({});

let openDialogFn;

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: '',
      description: '',
      handleConfirm: function () {},
    };
  }

  componentDidMount() {
    openDialogFn = this.handleOpenConfirmDialog;
  }

  handleOpenConfirmDialog = (
    title,
    description,
    handleConfirm,
    handleReject
  ) => {
    this.setState({
      open: true,
      title,
      description,
      handleConfirm,
      handleReject,
    });
  };

  handleCloseDialogs = () => {
    this.setState({
      open: false,
    });
  };

  handleConfirm = () => {
    this.handleCloseDialogs();
    this.state.handleConfirm();
  };

  handleReject = () => {
    const { handleReject } = this.state;
    this.handleCloseDialogs();
    if (handleReject) {
      handleReject();
    }
  };

  render() {
    const { open, title, description } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleCloseDialogs}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="confirm-dialog-button-no"
            onClick={this.handleReject}
            color="primary"
          >
            {i18n.t('no')}
          </Button>
          <Button
            data-cy="confirm-dialog-button-yes"
            onClick={this.handleConfirm}
            color="primary"
            autoFocus
          >
            {i18n.t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export function handleOpenConfirmDialog(
  title,
  description,
  handleConfirm,
  handleReject
) {
  if (openDialogFn !== undefined)
    openDialogFn(title, description, handleConfirm, handleReject);
}

export default withStyles(styles)(ConfirmDialog);
