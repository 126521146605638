import { openSnackbar } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from 'services';

const useUploadUsers = () => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const uploadUsers = async (role, data) => {
    setLoading(true);
    try {
      const { statistics } = await UserService.importUsers(data, role);
      setResult(statistics);
      openSnackbar(t('snackbar_users_import_success'));
    } catch (err) {
      openSnackbar(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetResult = () => {
    setResult(null);
  };

  return { result, loading, uploadUsers, resetResult };
};

export default useUploadUsers;
