import 'core-js/features/array';
import 'core-js/features/string';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/promise/all-settled';

import { Component } from 'react';
import './App.css';
import BaseAppBarWithRouter from './containers/BaseAppBar';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './assets/i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import '@fontsource/roboto';
import theme from './assets/theme';
import { Provider as BusProvider } from 'react-bus';
import moment from 'moment-timezone';
import SnackbarProviderWithStyle from 'containers/SnackbarProviderWithStyle';
import { pdfjs } from 'react-pdf';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry.js';
import { enableES5, setAutoFreeze } from 'immer';

enableES5(); //TODO remove when deprecating IE
setAutoFreeze(false); //TODO When getting rid of mutating the state remove this line

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class App extends Component {
  constructor() {
    super();
    // Disable logging to console for production
    // See https://facebook.github.io/react-native/docs/performance.html#using-consolelog-statements
    if (process.env.NODE_ENV !== 'development') {
      console.log = () => {};
    }
  }

  render() {
    // Set the start of the week to be monday globally
    moment.updateLocale(i18n.t('locale'), {
      week: {
        dow: 1,
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale={i18n.language}
        >
          <I18nextProvider i18n={i18n}>
            <Router basename="/">
              <BusProvider>
                <SnackbarProviderWithStyle maxSnack={5}>
                  <BaseAppBarWithRouter />
                </SnackbarProviderWithStyle>
              </BusProvider>
            </Router>
          </I18nextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
export default App;
