import AbstractService from './AbstractService';
import QuestionModel from './models/QuestionModel';
import Auth from '../modules/Auth';

class QuestionServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'questions/';
  }
  /**
   * Get list of all questions.
   */
  getQuestions() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  getQuestionSuggestions = (searchValue) => {
    var endpoint = 'questions/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  /**
   * Get question.
   */
  getQuestion(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint + id, config);
  }

  /**
   * Get question IDs.
   */
  getQuestionIDs(tenantId, type) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(this.endpoint + 'type/' + tenantId + '/' + type, config);
  }

  getQuestionIDsByLanguages(tenantId, type, userId) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(
      this.endpoint + 'type/' + tenantId + '/' + type + '/' + userId,
      config
    );
  }

  /**
   * Get distinct question types.
   */
  getDistinctTypes(type) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint + 'types/', config);
  }

  /**
   * Create a question.
   */
  create(questionTitle, questionBody, answers, type, language) {
    var question = new QuestionModel(
      null,
      questionTitle,
      questionBody,
      answers,
      type,
      language
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, question.toJSON(), config);
  }

  /**
   * Remove a question.
   */
  remove_question(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(this.endpoint + id, config);
  }

  /**
   * Edit a question.
   */
  edit_question(id, questionTitle, questionBody, answers, type, language) {
    var question = new QuestionModel(
      id,
      questionTitle,
      questionBody,
      answers,
      type,
      language
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.put(this.endpoint + id, question.toJSON(), config);
  }
}

const QuestionService = new QuestionServiceImpl();

export default QuestionService;
