import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Cancel from '@material-ui/icons/Cancel';
import { VmsExportLink } from '.';
import tableStyles from '../../../assets/jss/tableStyles';
import i18n from '../../../assets/i18n';

const styles = (theme) => ({
  ...tableStyles(theme),
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'start',
  },
  rightCornerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    color: theme.palette.action.active,
  },
  font: {
    fontSize: '0.75rem',
  },
});

export const createTableHeaderState = (data) => {
  return {
    page: 0,
    rowsPerPage: 10,
    selected: [],
    data: data,
  };
};

class VmsTableHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.tableHeaderState = this.props.tableHeaderState;
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onChange = () => {
    this.props.onChange(this.tableHeaderState);
  };

  handleClickRow = (item) => {
    const { selected } = this.tableHeaderState;
    const selectedIndex = selected.findIndex((i) => item._id === i._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.tableHeaderState.selected = newSelected;
    this.onChange();
  };

  handleSelectAllRowsClick = (checked, visibleItems) => (event) => {
    if (checked) {
      this.tableHeaderState.selected = visibleItems.map((item) => item);
    } else {
      this.tableHeaderState.selected = [];
    }
    this.onChange();
  };

  handleChangePage = (event, page) => {
    this.tableHeaderState.page = page;
    this.onChange();
  };

  handleChangeRowsPerPage = (event) => {
    this.tableHeaderState.rowsPerPage = event.target.value;
    this.tableHeaderState.page = 0;
    this.onChange();
  };

  render() {
    this.tableHeaderState = this.props.tableHeaderState;
    const {
      classes,
      exportData,
      exportDataFilename,
      cancelMode,
      limitedView,
      selectFunction,
      leftButton,
      additionalButton,
      prevPageButtonDataCy,
      nextPageButtonDataCy,
    } = this.props;
    const { selected, rowsPerPage, page, data } = this.tableHeaderState;
    const visibleItems = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .filter(selectFunction ? selectFunction : () => true);
    const allRowsChecked =
      selected.length === visibleItems.length && selected.length > 0;
    const removeSelectedTooltip = cancelMode ? 'cancel' : 'delete';
    const removeSelectedAriaLabel = cancelMode ? 'Cancel' : 'Delete';
    const removeSelectedIcon = cancelMode ? <Cancel /> : <Delete />;

    return (
      <thead>
        <tr>
          <th colSpan={6}>
            <div className={classes.paginationHeader}>
              <div className={classes.checkboxWrapper}>
                {!limitedView &&
                  visibleItems &&
                  Boolean(visibleItems.length) && (
                    <Checkbox
                      color="primary"
                      checked={allRowsChecked}
                      className={classes.headCheckbox}
                      onChange={this.handleSelectAllRowsClick(
                        !allRowsChecked,
                        visibleItems
                      )}
                    />
                  )}
                {leftButton}
              </div>
              {selected.length > 0 ? (
                <Typography variant="subtitle1">
                  {selected.length} {i18n.t('selected')}
                </Typography>
              ) : (
                <div> </div>
              )}

              {selected.length > 0 ? (
                <div className={classes.headDelete}>
                  <Tooltip title={i18n.t(removeSelectedTooltip)}>
                    <IconButton
                      aria-label={removeSelectedAriaLabel}
                      onClick={this.props.onRemoveSelectedItems}
                    >
                      {removeSelectedIcon}
                    </IconButton>
                  </Tooltip>
                  {exportData ? (
                    <VmsExportLink
                      downloadData={exportData}
                      filename={exportDataFilename}
                    />
                  ) : null}
                </div>
              ) : (
                <div className={classes.rightCornerWrapper}>
                  <TablePagination
                    classes={{
                      caption: classes.font,
                    }}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('meta_rows_per_page')}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                      classes: {
                        colorInherit: classes.buttons,
                      },
                      'data-cy': prevPageButtonDataCy,
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                      classes: {
                        colorInherit: classes.buttons,
                      },
                      'data-cy': nextPageButtonDataCy,
                    }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                  {additionalButton}
                </div>
              )}
            </div>
          </th>
        </tr>
      </thead>
    );
  }
}

VmsTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  tableHeaderState: PropTypes.object.isRequired,
  onRemoveSelectedItems: PropTypes.func.isRequired,
  exportData: PropTypes.object,
  exportDataFilename: PropTypes.string,
  leftButton: PropTypes.node,
  limitedView: PropTypes.bool,
  selectFunction: PropTypes.func,
  additionalButton: PropTypes.element,
  prevPageButtonDataCy: PropTypes.string,
  nextPageButtonDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsTableHeader);
