import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const styles = (theme) => ({
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
    position: 'relative',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'inline-block',
    width: '100%',
    height: '100%',
  },
  dropzone: {
    width: '100%',
    height: 150,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    borderBottom: `1px solid ${theme.palette.primary7}`,
  },
});

const VmsDialogRowPhoto = (props) => {
  const { downloadUrl, classes, rowClass, previewComponent, iconLeft } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <div className={classes.iconLeft}>
          {iconLeft ? iconLeft : <PhotoCamera />}
        </div>
      </Grid>
      <Grid item className={classes.rowContent}>
        <a href={downloadUrl} className={classes.link} download>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.dropzone}
          >
            {previewComponent}
          </Grid>
        </a>
      </Grid>
    </Grid>
  );
};

VmsDialogRowPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.element,
  downloadUrl: PropTypes.string,
  previewComponent: PropTypes.any.isRequired,
  rowClass: PropTypes.string.isRequired,
};

export default withStyles(styles)(VmsDialogRowPhoto);
