import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { LoginForm } from 'components';
import TermsDialog from 'containers/TermsDialog';
import Auth from 'modules/Auth';
import { Redirect, withRouter } from 'react-router-dom';
import { openSnackbar } from 'components/common/bars/SnackBar';
import i18n from 'assets/i18n';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import {
  getFocusOnInput,
  isMobileApp,
  isShellApp,
  vms_application_routes,
} from 'AppSettings';
import { ConfigurationService, UserService } from 'services';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme),
});

const getInitialState = () => {
  return {
    user: {
      email: '',
      name: '',
      password: '',
      rememberMe: false,
    },
    data: {
      agreeTerms: false,
      agreePrivacy: false,
      agreeBehaviour: false,
    },
    useBehaviour: false,
  };
};

class LoginPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = getInitialState();

    this.changeUser = this.changeUser.bind(this);
    this.processForm = this.processForm.bind(this);
  }

  componentDidMount() {
    let self = this;
    Auth.rememberMeRestoreSession(function (error, success) {
      if (success) {
        self.setState({
          errors: {},
        });
        //If application is running in mobile VMS app, call custom url so mobile app knows that
        //it should register device token
        if (isMobileApp() === true) {
          setTimeout(() => {
            window.location = vms_application_routes.ADD_DEVICE_TOKEN;
          }, 1000);
        }
      }
    });

    if (isMobileApp() === true) {
      //Deactivate Screen always on
      window.location =
        vms_application_routes.SCREEN_ALWAYS_ON_ENABLED + 'false';
    }

    //Solve issue with virtual keyboard
    if (isShellApp() === true) {
      document.body.addEventListener('click', getFocusOnInput);
    }

    //Replace email and password from expired password page
    //See render in ResetPasswordPage component
    const { user } = this.state;
    const { history } = this.props;
    const locationState = ((history || {}).location || {}).state || {};

    if (locationState.referrer === 'reset') {
      const newUser = {
        ...user,
        email: locationState.email,
        password: locationState.password,
      };
      this.setState({ user: newUser });
    }
    this.loadData();
  }

  async loadData() {
    try {
      const response = await ConfigurationService.getUseBehaviourRulesConfig();
      this.setState({ useBehaviour: response });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeUser(event) {
    sessionStorage.removeItem('visitor_view'); //Ensure that visitor_view flag is cleared in case a user closes web browser and tries to log as another one
    const field = event.target.name;
    const user = this.state.user;
    user[field] =
      field === 'rememberMe' ? event.target.checked : event.target.value;
    if (field === 'rememberMe') {
      Auth.setRememberMe(user[field]);
    }
    this.setState({
      user,
    });
  }

  changeTermsData = (field, value) => {
    this.setState((prevState) => {
      prevState.data[field] = value;
      return prevState;
    });
  };

  /**
   * Process the form.
   *
   * @param {object} event - the JavaScript event object
   */
  processForm(event) {
    // prevent default action. in this case, action is the form submission event
    event.preventDefault();

    console.log('email:', this.state.user.email);
    console.log('password:', this.state.user.password);

    const email = this.state.user.email;
    const password = this.state.user.password;
    // Make a request for a user with a given ID
    var self = this;
    Auth.signIn(email, password, this.props.history)
      .then(function () {
        // change the component-container state
        self.setState({
          errors: {},
        });
        // if application is running in mobile VMS app, call custom url so mobile app knows that
        // it should register device token
        if (isMobileApp() === true) {
          window.location = vms_application_routes.ADD_DEVICE_TOKEN;
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.networkError) {
          openSnackbar(error.message);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.resetToken
        ) {
          //Password expired
          self.setState({
            resetToken: error.response.data.resetToken,
          });
        } else if (error.status === 429) {
          openSnackbar(i18n.t('status_error_429'));
        } else {
          openSnackbar(error.message);
        }
      });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.processForm(event);
    }
  };

  handleAgreeWithTerms = async (event) => {
    UserService.updateUserWithData(Auth.getUser()._id, {
      agreeTerms: this.state.data.agreeTerms,
    })
      .then((response) => {
        if (
          Auth.isUserAuthenticated() &&
          !this.state.user.email &&
          !this.state.user.password
        ) {
          this.setState((prevState) => {
            return {
              ...prevState,
              user: {
                email: Auth.getUser().name,
                name: '',
                password: this.props.history.location.state.password,
                rememberMe: false,
              },
            };
          });
        }
        openSnackbar(i18n.t('saved'));
        this.processForm(new Event('agree'));
      })
      .catch((error) => {
        openSnackbar(error.message);
      });
  };

  handleCancel = (event) => {
    Auth.deauthenticateUser();
    this.setState(getInitialState());
  };

  render() {
    const {
      resetToken,
      data: { agreePrivacy, agreeBehaviour },
      user: { email },
      useBehaviour,
    } = this.state;
    const nextDisabled =
      useBehaviour === 'true'
        ? agreePrivacy === false || agreeBehaviour === false
        : agreePrivacy === false;
    const isAgreeTermsNeeded = Auth.isAgreeTermsNeeded();
    const isUserAuthenticated = Auth.isUserAuthenticated();
    return (
      // Wait with proceeding to application if user is required to agree to terms of use and privacy policy
      isUserAuthenticated && !isAgreeTermsNeeded ? (
        <Redirect
          to={{
            pathname: Auth.getHomePage(),
            state: { referrer: 'login' },
          }}
        />
      ) : resetToken ? (
        <Redirect
          to={{
            pathname: UserService.passwordEndpoint,
            search: '?jwt=' + resetToken,
            state: { referrer: 'reset', email },
          }}
        />
      ) : (
        <div align="center">
          <LoginForm
            onSubmit={this.processForm}
            onKeyPress={this.handleKeyPress}
            onChange={this.changeUser}
            user={this.state.user}
          />
          {isUserAuthenticated && isAgreeTermsNeeded && (
            <Aux>
              <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {i18n.t('terms_of_use')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div>{i18n.t('terms_of_user_changed')}</div>
                    <TermsDialog
                      onChange={this.changeTermsData}
                      useBehaviour={useBehaviour}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCancel} color="primary">
                    {i18n.t('cancel')}
                  </Button>
                  <Button
                    onClick={this.handleAgreeWithTerms}
                    color="primary"
                    disabled={nextDisabled}
                  >
                    {i18n.t('next')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Aux>
          )}
        </div>
      )
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(LoginPage));
