import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { AccessTime, BusinessCenter, People } from '@material-ui/icons';
import { elevation } from 'assets/UISettings';
import moment from 'moment';
import PropTypes from 'prop-types';
import VisitItineraryStepper from './VisitItineraryStepper';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    borderStyle: 'solid',
    borderColor: theme.palette.white,
  },
  selectedVisit: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  contact: {
    color: theme.palette.primary4,
    paddingLeft: theme.spacing(),
    display: 'inline-flex',
    alignItems: 'center',
  },
  visitIcon: {
    marginRight: theme.spacing(2),
  },
}));

const VisitItineraryCard = ({
  visit,
  selectedVisit,
  onVisitChange,
  itemDataCy,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid data-cy={itemDataCy} item xs={12}>
      <Paper
        className={`${classes.paper} ${
          selectedVisit && visit._id === selectedVisit._id
            ? classes.selectedVisit
            : undefined
        }`}
        elevation={elevation.light}
        onClick={onVisitChange ? () => onVisitChange(visit) : undefined}
      >
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <Typography variant="body1" className={classes.contact}>
              <People className={classes.visitIcon} />
              {visit.host?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" className={classes.contact}>
              <BusinessCenter className={classes.visitIcon} />
              {visit.host?.company}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" className={classes.contact}>
              <AccessTime className={classes.visitIcon} />
              {moment(visit.timeFrom).format('LL HH:mm')}
            </Typography>
          </Grid>
        </Grid>
        <VisitItineraryStepper visit={visit} {...rest} />
      </Paper>
    </Grid>
  );
};

VisitItineraryCard.propTypes = {
  addClicked: PropTypes.func.isRequired,
  updateClicked: PropTypes.func.isRequired,
  doneClicked: PropTypes.func.isRequired,
  stepClicked: PropTypes.func,
  visit: PropTypes.object.isRequired,
  itinerary: PropTypes.object.isRequired,
  selectedVisit: PropTypes.object,
  onVisitChange: PropTypes.func,
  itemDataCy: PropTypes.string,
};

export default VisitItineraryCard;
