import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab, Hidden } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TabContainer } from 'components';
import Aux from 'hoc/Auxiliary';
import TabLabel from './TabLabel';

const styles = (theme) => ({
  tabs: {
    position: 'fixed',
    zIndex: 1101, // see https://material-ui.com/layout/basics/#z-index
    //top: 64 // we use breakpoints 64 | 48 (xs)
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      boxShadow: theme.shadows[5],
      top: 64,
    },
    [theme.breakpoints.down('xs')]: {
      boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)',
      top: 48,
    },
  },
  tab: {
    color: theme.palette.primary6,
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  tabWrapper: {
    paddingRight: theme.spacing(2),
    flexDirection: 'row',
  },
  heightOfContent: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 96px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 112px)',
    },
  },
  //TODO remove with Material UI 4
  scroller: {
    MsOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const TabbedView = (props) => {
  const {
    classes,
    theme,
    index,
    onChangeTabs,
    onChangeViews,
    tabNames,
    tabContents,
    showBadges,
  } = props;

  const tabs = Object.entries(tabNames).map(([tabId, name], idx) => (
    <Tab
      data-cy={`nav-tab-${tabId}`}
      key={`tabName-${idx}`}
      classes={{
        textColorInherit: classes.tab,
        wrapper: classes.tabWrapper,
      }}
      label={
        <TabLabel
          tabId={tabId}
          name={name}
          showBadge={Boolean(showBadges[idx])}
        />
      }
    />
  ));

  return (
    <Aux>
      <Hidden smUp>
        <Tabs
          classes={{ scroller: classes.scroller }}
          variant="scrollable"
          indicatorColor="primary"
          value={index}
          onChange={onChangeTabs}
          className={classes.tabs}
          scrollButtons="off"
        >
          {tabs}
        </Tabs>
      </Hidden>
      <Hidden xsDown>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          value={index}
          onChange={onChangeTabs}
          className={classes.tabs}
        >
          {tabs}
        </Tabs>
      </Hidden>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={index}
        onChangeIndex={onChangeViews}
        transform="none"
        slideClassName={classes.heightOfContent}
      >
        {tabContents.map((node, idx) => (
          <TabContainer
            id={`tabContainer-${idx}`}
            key={`tabContainer-${idx}`}
            dir={theme.direction}
          >
            {node}
          </TabContainer>
        ))}
      </SwipeableViews>
    </Aux>
  );
};

TabbedView.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChangeTabs: PropTypes.func.isRequired,
  onChangeViews: PropTypes.func.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabContents: PropTypes.arrayOf(PropTypes.node).isRequired,
  showBadges: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(TabbedView);
