import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import i18n from 'assets/i18n';
import { VmsDialogRowDocumentCollection } from 'components';
import VmsDialogDragDropPhoto from 'containers/VmsDialogDragDropPhoto';
import { createDragDropStateWithPhoto } from 'containers/VmsDialogDragDropPhoto';
import { createDragDropState } from 'containers/VmsDialogDragDropFile';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import VmsDialogDragDropFile from 'containers/VmsDialogDragDropFile';
import Auth from 'modules/Auth';
import UserSelectAvatar from './UserSelectAvatar';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  container: {
    alignContent: 'flex-start',
  },
});

class UserDocsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocument: {},
    };
  }

  handleOnClick = (document) => (event) => {
    this.setState({
      selectedDocument: document,
    });
  };

  render() {
    const {
      classes,
      changeFileData,
      userEdit,
      disableEdit,
      dragDropState,
      dragDropFileState,
      onDragDropFileChange,
      onDragDropChange,
      item,
      onChange,
    } = this.props;

    const photoObject = userEdit.editData.photo
      ? {
          _id: userEdit.editData.photo,
          downloadUrl: userEdit.editData.photoUrl,
          name: i18n.t('invite_form_docs_photo'),
        }
      : null;

    const dragDropStateFinal = dragDropState
      ? dragDropState
      : createDragDropStateWithPhoto(photoObject);
    const dragDropFileStateFinal = dragDropFileState
      ? dragDropFileState
      : createDragDropState();

    return (
      <Grid container className={classes.container}>
        {!Auth.isUserVisitor() && (
          <VmsDialogDragDropFile
            onChange={onDragDropFileChange}
            dragDropFileState={dragDropFileStateFinal}
            disabled={disableEdit}
            buttonDataCy="user-button-drag-drop-file"
            itemDataCy="user-list-item-drag-drop-file"
          />
        )}
        {!Auth.isUserVisitor() && (
          <VmsDialogRowDocumentCollection
            documents={userEdit.personal_files}
            onChange={changeFileData}
            onClick={this.handleOnClick}
            disabled={disableEdit}
            itemDataCy="user-list-item-uploaded-document"
            textDocNameDataCy="user-text-uploaded-document-name"
            buttonRemoveDocDataCy="user-button-remove-uploaded-document"
          />
        )}
        <VmsDialogDragDropPhoto
          onChange={onDragDropChange}
          dragDropState={dragDropStateFinal}
          disabled={disableEdit}
          buttonDataCy="user-button-drag-drop-photo"
          itemDataCy="user-list-item-drag-drop-photo"
        />
        <UserSelectAvatar
          item={item}
          onChange={onChange}
          disabled={disableEdit}
        />
      </Grid>
    );
  }
}

UserDocsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  changeFileData: PropTypes.func.isRequired,
  userEdit: PropTypes.object.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  dragDropState: PropTypes.object,
  dragDropFileState: PropTypes.object,
  onDragDropFileChange: PropTypes.func.isRequired,
  onDragDropChange: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDocsTab);
