import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { ErrorBoundary, VmsInput } from 'components';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import moment from 'moment';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  maxWidth: {
    width: 570,
  },
  answers: {
    paddingLeft: theme.spacing(2),
  },
});

const FeedbackResultDialog = (props) => {
  const { classes, document, openDialog, onClose } = props;
  let {
    visit: { timeFrom, timeTo },
  } = document.data;

  timeFrom = moment(timeFrom).format('L');
  timeTo = moment(timeTo).format('L');

  const onChangeInput = () => (event) => {};
  return (
    <Dialog
      open={openDialog}
      onClose={onClose()}
      aria-labelledby="feedback-result"
    >
      <DialogContent>
        <ErrorBoundary>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" id="feedback-result">
                    {document.data.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" id="feedback-result">
                    {`${timeFrom} - ${timeTo}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {document.data.answered +
                      ' / ' +
                      document.data.totalQuestions}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {document.data.questions.map((q, i) => {
              return (
                <div key={q.question._id}>
                  <Grid item className={classes.maxWidth}>
                    <VmsInput
                      rowClass={classes.dialogRow2}
                      textValue={q.question.questionTitle}
                      inputName={`question_${i}`}
                      placeholder=""
                      inputLabel=""
                      onChange={onChangeInput}
                      inputProps={{
                        disabled: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className={classes.answers}>
                        {q.checkedAnswers.map((a) => (
                          <div key={a}>
                            {'• ' + a}
                            <br />
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Grid>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FeedbackResultDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(FeedbackResultDialog);
