import { openSnackbar } from 'components/common/bars/SnackBar';
import { useCallback, useState } from 'react';
import { useImmer } from 'use-immer';

const useInfiniteLoading = (service, load = true, pageSize = 20) => {
  const [data, setData] = useImmer([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const mergeData = useCallback(
    (newData, page) => {
      if (newData.length) {
        setData((oldData) => {
          oldData.splice(page * pageSize, pageSize, ...newData);
        });
        setCurrentPage(page + 1);
      } else {
        setHasMore(false);
      }
    },
    [pageSize, setData]
  );

  const fetchMore = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await service(currentPage, pageSize);
      mergeData(response, currentPage);
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [service, currentPage, pageSize, mergeData]);

  const resetLoader = useCallback(() => {
    setData([]);
    setHasMore(true);
    setCurrentPage(0);

    if (!load) {
      setData([]);
      setHasMore(false);
    } else {
      setIsLoading(true);

      const fetch = async () => {
        try {
          const response = await service(0, pageSize);
          mergeData(response, 0);
        } catch (err) {
          console.log(err);
          openSnackbar(err.message);
          setHasMore(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetch();
    }
  }, [pageSize, load, mergeData, service, setData]);

  const replaceData = async (from, count, items) => {
    //If we are replacing the same amount of items nothing else has to be done
    if (count === items.length) {
      setData((draft) => {
        draft.splice(from, count, ...items);
      });
    } else {
      //In case we are placing less items set the page appropriately so it can load additional items if needed
      if (items.length < count) {
        setData((draft) => {
          draft.splice(from, count, ...items);
          setCurrentPage(Math.floor(draft.length / pageSize));
        });

        //In case we are placing more items cut short the array to the previously loaded size, before that check if it oveflows
        //If so tell the loader there is data to be fetched
      } else {
        setData((draft) => {
          draft.splice(from, count, ...items);

          const maxPageSize = (currentPage + 1) * pageSize;
          if (draft.length > maxPageSize) {
            setHasMore(true);
          }

          draft.splice(maxPageSize);
        });
      }
    }
  };

  return { data, hasMore, fetchMore, resetLoader, isLoading, replaceData };
};

export default useInfiniteLoading;
