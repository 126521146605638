import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, Typography } from '@material-ui/core';
import { VmsInfoBar, ErrorBoundary } from 'components';
import MealElement from 'containers/dashboard/visitor/details/MealElement';
import {
  other_categories,
  other_types,
  isMobileApp,
  vms_application_routes,
} from 'AppSettings';
import TemplateService from 'services/TemplateService';
import { withRouter } from 'react-router-dom';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import i18n from 'assets/i18n';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  previewPaper: {
    height: 'inherit',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

class OtherDetail extends Component {
  handleGoToDashboard = () => {
    this.props.history.go(-1);
  };

  openInNewTab = (url) => {
    if (isMobileApp() === true) {
      window.location = vms_application_routes.OPEN_URL + url;
    } else {
      let win = window.open(url, '_blank');
      win.focus();
    }
  };

  onClose = () => {
    this.props.history.go(-1);
  };

  render() {
    const { item } = this.props.location.state || this.props;
    const { classes } = this.props;

    if (!item) this.handleGoToDashboard();

    const textElement = (props, text) => {
      // Replace any placeholders
      let replace = {
        user: props.user,
        visit: props.visit,
        company: props.company,
      };
      let template = {
        body: text || '',
      };
      template = TemplateService.replaceTemplate(template, replace);
      return (
        <ErrorBoundary>
          <VmsInfoBar
            backMode={true}
            backModeText={i18n.t('tab_visitor_other')}
            onBack={this.handleGoToDashboard}
          />
          <div className={classes.contentOfVmsInfoBar}>
            <Paper
              className={classes.previewPaper}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <Typography variant="subtitle1">{template.body}</Typography>
            </Paper>
          </div>
        </ErrorBoundary>
      );
    };

    const htmlElement = (html) => {
      return (
        <ErrorBoundary>
          <VmsInfoBar
            backMode={true}
            backModeText={i18n.t('tab_visitor_other')}
            onBack={this.handleGoToDashboard}
          />
          <div className={classes.contentOfVmsInfoBar}>
            <Paper
              className={classes.previewPaper}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Paper>
          </div>
        </ErrorBoundary>
      );
    };

    let data = JSON.stringify(item.data);
    switch (item.type) {
      case other_types.TEXT:
        let text = item.data;
        return textElement(this.props.location.state || this.props, text);
      case other_types.WYSIWYG:
      case other_types.HTML:
        let html = item.data;
        return htmlElement(html);
      case other_types.URL:
        let url = item.data;
        this.openInNewTab(url);
        setTimeout(this.onClose, 250); // Close this detail after render
        return null;
      case other_types.CSV:
        if (
          item.category === other_categories.MEAL ||
          item.category === other_categories.REFRESHMENT
        ) {
          data = item.data;
          return (
            <MealElement
              data={data}
              previewMode={this.props.previewMode}
              onlyOne={item.category === other_categories.MEAL}
            />
          );
        } else {
          setTimeout(this.onClose, 250); // Close this detail after render
          return null;
        }
      default:
        return textElement(this.props.location.state || this.props, data);
    }
  }
}

OtherDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(OtherDetail));
