import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import CheckCircle from '@material-ui/icons/CheckCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import Comment from '@material-ui/icons/Comment';
import { VmsInput } from 'components';
import i18n from '../../../assets/i18n';
import modalStyle from '../../../assets/jss/modalStyle';
import VmsDialog from '../../common/dialogs/VmsDialog';
import classNames from 'classnames';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  row: {
    padding: theme.spacing(2),
  },
  row2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fabIcon: {
    color: theme.palette.common.white,
  },
});

const LocationSettingsDialog = (props) => {
  const { classes, onSave, onClose, onChange, open, selectedItem, title } =
    props;
  const saveDisabled =
    selectedItem.value === undefined ||
    selectedItem.value === '' ||
    selectedItem.key === undefined ||
    selectedItem.key === '';
  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={title}
      onClose={onClose}
      dialogHeaderIcon={<Settings />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onSave}
      buttonDisabled={saveDisabled}
    >
      <VmsInput
        rowClass={classNames(classes.row2, classes.row)}
        iconLeft={<VpnKey />}
        textValue={selectedItem && selectedItem.key ? selectedItem.key : ''}
        placeholder={i18n.t('settings_company_location_settings_key')}
        inputLabel={i18n.t('settings_company_location_settings_key')}
        onChange={onChange('key')}
        required
      />
      <VmsInput
        rowClass={classes.row2}
        iconLeft={<Comment />}
        textValue={selectedItem && selectedItem.value ? selectedItem.value : ''}
        placeholder={i18n.t('settings_company_location_settings_value')}
        inputLabel={i18n.t('settings_company_location_settings_value')}
        onChange={onChange('value')}
        required
      />
    </VmsDialog>
  );
};

LocationSettingsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default withStyles(styles)(LocationSettingsDialog);
