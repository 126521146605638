import { colors } from '../UISettings';

const commonStyles = (theme) => ({
  mainContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 48,
    height: '100%',
  },
  icon: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  iconSelect: {
    color: theme.palette.primary6,
    position: 'absolute',
    right: theme.spacing(2),
    top: 'calc(50% - 12px)',
    pointerEvents: 'none',
  },
  rowContent: {
    flex: 1,
    width: 0,
  },
  rowContent2: {
    flex: 1,
  },
  dialogRow: {
    padding: theme.spacing(2),
  },
  dialogRow2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogRow3: {
    paddingRight: theme.spacing(3),
  },
  dialogRow4: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  dialogRowSmall: {
    padding: theme.spacing(2),
    height: 52,
  },
  dialogRowNote: {
    height: 136,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogRowNoteSmall: {
    height: 80,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
  },
  bigAvatar: {
    width: 120,
    height: 120,
  },
  label: {
    color: theme.palette.primary6,
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary4,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  footer: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  dropDownMenu: {
    maxHeight: 48 * 4.5,
    width: 300,
    right: 20,
    overflow: 'auto',
  },
  dropDownMenuItem: {
    width: '100%',
    boxSizing: 'border-box',
  },
  dropDownMenuAutoComplete: {
    zIndex: theme.zIndex.modal + 1,
    maxHeight: 48 * 4.5,
    overflow: 'auto',
  },
  AdornmentIcon: {
    color: theme.palette.primary6,
  },
  searchField: {
    height: 48,
    boxShadow: theme.shadows[3],
    borderRadius: 4,
  },
  inputSearchField: {
    padding: theme.spacing(),
  },
  inputField: {
    paddingLeft: theme.spacing(2),
  },
  searchAdornment: {
    color: colors.pinkishGrey,
    paddingRight: theme.spacing(2),
  },
  flexGrow: {
    flexGrow: 1,
  },
  contentOfVmsInfoBar: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    width: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    height: `calc(100vh - ${theme.spacing(12)}px)`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.spacing(14)}px)`,
    },
  },
  drawerPaper: {
    padding: theme.spacing(2),
    minWidth: '240px',
    maxWidth: '80%',
  },
  menuTitle: {
    color: theme.palette.primary4,
    marginBottom: theme.spacing(2),
    fontSize: 18,
    fontWeight: 500,
  },
  menuCategory: {
    color: theme.palette.primary5,
    marginBottom: theme.spacing(),
    fontSize: 16,
    fontWeight: 500,
  },
  menuSubcategory: {
    color: theme.palette.primary5,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(),
    fontSize: 16,
    fontWeight: 500,
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  divider: {
    backgroundColor: theme.palette.primary3,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    height: 2,
    border: 0,
  },
  paperTable: {
    height: 'calc(100vh - 140px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 220px)',
    },
    overflow: 'auto',
  },
  gridInheritance: {
    width: 'inherit',
    height: 'inherit',
  },
  gridFullSize: {
    width: '100%',
    height: '100%',
  },
  contentOverflow: {
    height: 'inherit',
    overflow: 'auto',
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  gridContent: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  search: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    height: 64,
  },
  dashboardCalendar: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    display: 'inline-block',
    marginBottom: '16px',
    width: 'calc(100% - 16px)',
    boxSizing: 'border-box',
  },
  required: {
    color: theme.palette.required,
  },
});

export default commonStyles;
