import { useCallback } from 'react';

const useCloseDialogWithLoading = (close, loading) => {
  const closeIfNotLoading = useCallback(() => {
    if (!loading) {
      close();
    }
  }, [close, loading]);

  return closeIfNotLoading;
};

export default useCloseDialogWithLoading;
