import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import buttonStyle from 'assets/jss/buttonStyle';
import { withBus } from 'react-bus';

const styles = (theme) => ({
  ...buttonStyle(theme),
});

const buttonMenu = withBus()((props) => {
  const toggleDrawer = () => {
    props.bus.emit('toggleDrawer', { pathname: props.pathname, open: true });
  };
  return (
    <IconButton
      style={props.style}
      className={props.classes.menu}
      onClick={toggleDrawer}
    >
      <Menu />
    </IconButton>
  );
});

buttonMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default withStyles(styles)(buttonMenu);
