import PropTypes from 'prop-types';
import { withStyles, Grid, IconButton } from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import PersonOutline from '@material-ui/icons/Person';
import AccessTime from '@material-ui/icons/AccessTime';
import Place from '@material-ui/icons/Place';
import People from '@material-ui/icons/People';
import EventNote from '@material-ui/icons/EventNote';
import BusinessCenter from '@material-ui/icons/Business';
import Person from '@material-ui/icons/Person';
import WifiLock from '@material-ui/icons/WifiLock';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import NetworkWifi from '@material-ui/icons/NetworkWifi';
import SignalWifi4Bar from '@material-ui/icons/SignalWifi4Bar';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import VpnKey from '@material-ui/icons/VpnKey';
import Flag from '@material-ui/icons/Flag';
import i18n from 'assets/i18n';
import {
  VmsInput,
  VmsDialogRowAutocomplete,
  VmsDialogRowDateTimePicker,
  VmsDialogRowSelect,
  VmsDialogRowTextField,
  EmailSharedRow,
  useSharedEmail,
  VmsDialogRowCheckBoxCollection,
  VmsDialogRowSimpleCollection,
} from 'components';
import Auth from 'modules/Auth';
import { sanitizePhoneNumber, invite_tab_indexes, days } from 'AppSettings';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import UserService from 'services/UserService';
import moment from 'moment-timezone';
import Aux from 'hoc/Auxiliary';
import { VmsLocalizationList } from 'components/common/localization';
import AddRemoveVisitor from './AddRemoveVisitor';
import GroupVisitorList from './GroupVisitorList';
import { memo, useCallback } from 'react';
import {
  EmojiPeople,
  ViewWeek,
  CalendarToday,
  HowToRegRounded,
} from '@material-ui/icons';
import VmsDialogRowDatePicker from '../../rows/VmsDialogRowDatePicker';
const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  fullWidth: {
    width: '100%',
  },
  rowWithDay: {
    width: '50%',
  },
});

const VisitorTab = (props) => {
  const {
    classes,
    changeData,
    invitation,
    visitPurposes,
    onHostSuggestionClearRequested,
    onHostSuggestionSelected,
    onUserSuggestionsClearRequested,
    onUserSuggestionSelected,
    onEmailSuggestionsClearRequested,
    companyData,
    onPhoneSuggestionsClearRequested,
    handleAddVisitorToGroupVisit,
    handleRemoveVisitorFromGroupVisit,
    handleChangeCurrentVisitFromGroup,
    invitationState,
    userClosed,
    visitClosed,
    isGroupInvitation,
    accompanimentDoesntExist,
    phoneValid,
    emailValid,
    loginValid,
    delegateValid,
  } = props;
  const { index, size } = invitationState;
  const isVisitor = Auth.isUserVisitor();
  const isAdmin = Auth.isUserAdmin();
  let company = {};

  const onEmailSharedInfoChecked = useCallback(
    (emailSharedInfo) => {
      changeData('emailShared', emailSharedInfo.emailShared)();
      changeData('emailSharedEnabled', emailSharedInfo.emailSharedEnabled)();
    },
    [changeData]
  );
  const loadingEmailCheck = useSharedEmail(
    invitation.email,
    invitation.user_id,
    onEmailSharedInfoChecked,
    { disabled: Boolean(invitation.user_id) }
  );

  if (Array.isArray(companyData)) {
    companyData.forEach((setting) => {
      company[setting.key] = setting.value;
    });
  }

  const userEditDisabled = invitation.user_id !== null;
  const isEditMode = invitation.visit_id !== null;
  const arrivalDisabled =
    invitation.visit_id && moment(invitation.arrival).isBefore(moment());
  const addButtonDisabled =
    !invitation.name ||
    (!invitation.email && !invitation.phone) ||
    visitClosed ||
    !phoneValid ||
    !emailValid ||
    !invitation.language ||
    !loginValid;
  const removeButtonEnabled = Boolean(isGroupInvitation && !visitClosed);
  const disabledSwitching =
    !invitation.email &&
    !invitation.phone &&
    index < size &&
    (!phoneValid || !emailValid || !invitation.language || !loginValid);
  const isPhoneRequired = Boolean(!invitation.email && invitation.phone);
  const tenantIsSame = invitation.tenantId
    ? Auth.getUser().tenantId === invitation.tenantId
    : true;

  const handleArrivalChange = (date) => {
    changeData('arrival', date)();
    if (!invitation.departure || date >= invitation.departure) {
      let departure = moment(date).endOf('day').toDate();
      handleDepartureChange(departure);
    }
  };

  const handleDepartureChange = (date) => {
    if (invitation.arrival && date <= invitation.arrival) {
      date = moment(invitation.arrival).add(1, 'hours').toDate();
    }
    changeData('departure', date)();
  };

  const handlePhoneChange = () => (event) => {
    changeData('phone', sanitizePhoneNumber(event.target.value))();
  };

  const handleWifiEnabled = () => {
    changeData('wifi_enabled', !invitation.wifi_enabled)();
  };

  const handleOption1Enabled = () => {
    changeData('option1', !invitation.option1)();
  };

  const handleOption2Enabled = () => {
    changeData('option2', !invitation.option2)();
  };

  const handleSharedEmailEnabled = () => {
    changeData('emailShared', !invitation.emailShared)();
  };

  const handleSelectDays = () => {
    changeData('selectDays', !invitation.selectDays)();
  };

  const addDateOff = (date) => {
    changeData('addDatesOff', date)();
  };

  const handleRemoveDateOff = (inputName, item, index) => () => {
    console.log('nehe', inputName, item, index);
    changeData(inputName, index)();
  };

  const handleChangeDaysOff = (event) => {
    changeData('daysOff', event.target.value)();
  };

  const filterResponses = (response) => {
    return response.filter(
      (visitor) =>
        !invitationState.invitations.find(
          (invitation) => visitor._id === invitation.user_id
        )
    );
  };
  let weekDays = [];

  let other_days = Object.values(days);
  other_days.forEach((day) => {
    weekDays.push({
      label: i18n.t(day),
      value: day,
      checked: invitation.daysOff?.includes(day) ? false : true,
    });
  });

  return (
    <Grid item>
      {isGroupInvitation && (
        <GroupVisitorList
          dialogRow={classes.dialogRow}
          invitationState={invitationState}
          disabledSwitching={disabledSwitching}
          visitClosed={visitClosed}
          handleRemoveVisitorFromGroupVisit={handleRemoveVisitorFromGroupVisit}
          handleChangeCurrentVisitFromGroup={handleChangeCurrentVisitFromGroup}
          changeGroupName={changeData('group')}
        />
      )}
      <VmsDialogRowAutocomplete
        rowClass={isGroupInvitation ? classes.dialogRow2 : classes.dialogRow}
        iconLeft={<PersonOutline />}
        inputName="name"
        disabled={isEditMode || isVisitor}
        onSuggestionsClearRequested={onUserSuggestionsClearRequested}
        onSuggestionSelected={onUserSuggestionSelected}
        inputValue={invitation.name}
        placeholder={i18n.t('invite_form_name')}
        onChange={changeData}
        suggestionService={(searchValue) =>
          UserService.getUserSuggestions(searchValue, false, true)
        }
        filterSuggestionsResponse={filterResponses}
        suggestionItemValue={(suggestion) => {
          return suggestion.name;
        }}
        required
        itemDataCy="invitation-autocomplete-name"
      />
      <VmsDialogRowAutocomplete
        rowClass={classes.dialogRow2}
        iconLeft={<Email />}
        inputName="email"
        disabled={userEditDisabled || isVisitor}
        onSuggestionsClearRequested={onEmailSuggestionsClearRequested}
        onSuggestionSelected={onUserSuggestionSelected}
        inputValue={invitation.email}
        placeholder={`${i18n.t('invite_form_email')}${
          isPhoneRequired ? ` (${i18n.t('required_phone')})` : ''
        }`}
        inputLabel={`${i18n.t('invite_form_email')}${
          isPhoneRequired ? ` (${i18n.t('required_phone')})` : ''
        }`}
        onChange={changeData}
        suggestionService={(searchValue) =>
          UserService.getUserEmailSuggestions(searchValue, false, true)
        }
        filterSuggestionsResponse={filterResponses}
        suggestionItemValue={(suggestion) => {
          return `${suggestion.email}${
            suggestion.emailShared
              ? ` (${i18n.t('invite_form_shared')}, ${suggestion.name})`
              : ''
          }`;
        }}
        required={!isPhoneRequired}
        inputError={!emailValid}
        itemDataCy="invitation-autocomplete-email"
      />
      <EmailSharedRow
        dialogRowClass={classes.dialogRow2}
        emailShared={invitation.emailShared}
        loadingEmailCheck={loadingEmailCheck}
        onEmailSharedClick={handleSharedEmailEnabled}
        disabled={
          userEditDisabled ||
          visitClosed ||
          isVisitor ||
          arrivalDisabled ||
          !invitation.emailSharedEnabled
        }
        checkboxDataCy="invitation-checkbox-email-shared"
      />
      {!userEditDisabled ? (
        <VmsDialogRowAutocomplete
          rowClass={classes.dialogRow2}
          iconLeft={<PhoneIphone />}
          inputName="phone"
          disabled={isVisitor || visitClosed}
          onSuggestionsClearRequested={onPhoneSuggestionsClearRequested}
          onSuggestionSelected={onUserSuggestionSelected}
          inputValue={invitation.phone ?? ''}
          placeholder={`${i18n.t('invite_form_phone')}${
            !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
          }`}
          inputLabel={`${i18n.t('invite_form_phone')}${
            !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
          }`}
          onChange={handlePhoneChange}
          suggestionService={(searchValue) =>
            UserService.getUserPhoneSuggestions(searchValue, false, true)
          }
          filterSuggestionsResponse={filterResponses}
          suggestionItemValue={(suggestion) => {
            return suggestion.phone;
          }}
          required={isPhoneRequired}
          inputError={!phoneValid}
          itemDataCy="invitation-autocomplete-phone"
        />
      ) : (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<PhoneIphone />}
          textValue={invitation.phone ?? ''}
          inputName="phone"
          placeholder={`${i18n.t('invite_form_phone')}${
            !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
          }`}
          inputLabel={`${i18n.t('invite_form_phone')}${
            !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
          }`}
          onChange={changeData('phone')}
          disabled={userClosed || visitClosed || !tenantIsSame}
          required={isPhoneRequired}
          inputError={!phoneValid}
        />
      )}
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<PersonOutline />}
        textValue={invitation.login}
        inputName="login"
        placeholder={i18n.t('invite_form_login')}
        inputLabel={i18n.t('invite_form_login')}
        onChange={changeData('login')}
        disabled={isVisitor || visitClosed || userEditDisabled}
        inputError={!loginValid}
      />
      <VmsLocalizationList
        lang={invitation.language}
        onChange={changeData('language')}
        iconLeft={<Flag />}
        rowClass={classes.dialogRow2}
        disabled={invitation.user_id || visitClosed}
        justifyContent="flex-start"
        required
        selectDataCy="invitation-select-language"
        itemDataCy="invitation-list-item-language"
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<BusinessCenter />}
        textValue={invitation.company}
        inputName="company"
        placeholder={i18n.t('invite_form_company')}
        inputLabel={i18n.t('invite_form_company')}
        onChange={changeData('company')}
        disabled={isVisitor || visitClosed || userEditDisabled}
      />
      <AddRemoveVisitor
        dialogRow={classes.dialogRow2}
        addButtonDisabled={addButtonDisabled}
        removeButtonEnabled={removeButtonEnabled}
        index={index}
        handleAddVisitorToGroupVisit={handleAddVisitorToGroupVisit}
        handleRemoveVisitorFromGroupVisit={handleRemoveVisitorFromGroupVisit}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Email />}
        textValue={invitation.delegate}
        inputName="delegate"
        placeholder={i18n.t('invite_form_email_delegate')}
        inputLabel={i18n.t('invite_form_email_delegate')}
        onChange={changeData('delegate')}
        disabled={visitClosed}
        inputError={!delegateValid}
      />
      <VmsDialogRowDateTimePicker
        rowClass={classes.dialogRow2}
        iconLeft={<AccessTime className={classes.required} />}
        dateValue={[invitation.arrival, invitation.departure]}
        placeholder={[
          i18n.t('invite_form_arrival'),
          i18n.t('invite_form_departure'),
        ]}
        onChange={[handleArrivalChange, handleDepartureChange]}
        disabled={isVisitor || visitClosed}
        arrivalDisabled={arrivalDisabled}
        minStartDate={moment().startOf('day')}
      />
      <VmsInput
        disabled={true}
        rowClass={classes.dialogRow2}
        iconLeft={<ViewWeek />}
        iconRight={
          <IconButton onClick={handleSelectDays}>
            {invitation.selectDays ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </IconButton>
        }
        textValue={i18n.t('select_week_days')}
      />
      {invitation.selectDays && (
        <Grid item className={classes.dialogRow2}>
          <Grid direction="row" container>
            <VmsDialogRowCheckBoxCollection
              rowClass={classes.rowWithDay}
              items={[weekDays[0], weekDays[1], weekDays[2], weekDays[3]]}
              onChange={handleChangeDaysOff}
            />
            <VmsDialogRowCheckBoxCollection
              rowClass={classes.rowWithDay}
              items={[weekDays[4], weekDays[5], weekDays[6]]}
              onChange={handleChangeDaysOff}
            />
          </Grid>
        </Grid>
      )}
      <VmsDialogRowDatePicker
        rowClass={classes.dialogRow2}
        iconLeft={<CalendarToday />}
        onChange={addDateOff}
        placeholder={i18n.t('choose_date_off')}
        value={null}
      />
      {invitation.datesOff?.length > 0 && (
        <VmsDialogRowSimpleCollection
          onChange={handleRemoveDateOff}
          collection={invitation.datesOff}
          inputName="removeDateOff"
        />
      )}
      <VmsDialogRowSelect
        rowClass={classes.dialogRow2}
        iconLeft={<Place />}
        placeholder={i18n.t('invite_form_purpose')}
        onChange={changeData('purpose')}
        inputName="purpose"
        disabled={isVisitor || visitClosed || arrivalDisabled}
        selectTypes={visitPurposes}
        selectedType={invitation.purpose}
        inputValue={invitation.purpose}
        selectDataCy="invitation-select-purpose"
        itemDataCy="invitation-list-item-purpose"
      />
      <Grid item className={classes.dialogRow2}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <VmsInput
              iconLeft={<IconButton disabled />}
              textValue={i18n.t('invite_form_purpose_placeholder')}
              placeholder={i18n.t('invite_form_purpose_placeholder')}
              inputLabel={i18n.t('invite_form_purpose_placeholder')}
              disabled={true}
              inputClasses={{ input: classes.fullWidth }}
              iconRight={
                <IconButton
                  data-cy="invitation-button-purpose-placeholder"
                  disabled={isVisitor || visitClosed || arrivalDisabled}
                  onClick={handleOption1Enabled}
                >
                  {invitation.option1 ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </IconButton>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <VmsInput
              iconLeft={<IconButton disabled />}
              textValue={i18n.t('invite_form_purpose_placeholder2')}
              placeholder={i18n.t('invite_form_purpose_placeholder2')}
              inputLabel={i18n.t('invite_form_purpose_placeholder2')}
              disabled={true}
              inputClasses={{ input: classes.fullWidth }}
              iconRight={
                <IconButton
                  data-cy="invitation-button-purpose-placeholder2"
                  disabled={isVisitor || visitClosed || arrivalDisabled}
                  onClick={handleOption2Enabled}
                >
                  {invitation.option2 ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <VmsDialogRowAutocomplete
        rowClass={classes.dialogRow2}
        iconLeft={<People />}
        inputName="host"
        onSuggestionsClearRequested={onHostSuggestionClearRequested}
        onSuggestionSelected={onHostSuggestionSelected}
        inputValue={invitation.host}
        placeholder={i18n.t('invite_form_host')}
        onChange={changeData}
        disabled={isVisitor || visitClosed || arrivalDisabled}
        suggestionService={UserService.getHostSuggestions}
        filterSuggestionsResponse={(response) => response}
        suggestionItemValue={(suggestion) => {
          return suggestion.name;
        }}
        required
        itemDataCy="invitation-autocomplete-host"
      />
      {invitation.accompanimentRequired && (
        <VmsInput
          inputName="accompanimentRequired"
          disabled={true}
          rowClass={classes.dialogRow2}
          iconLeft={<EmojiPeople />}
          iconRight={
            <IconButton disabled={true}>
              <CheckBox color="primary" />
            </IconButton>
          }
          textValue={
            accompanimentDoesntExist
              ? i18n.t('invite_form_accompaniment_required')
              : i18n.t('invite_form_accompaniment_confirmed')
          }
          inputLabel={
            accompanimentDoesntExist
              ? i18n.t('invite_form_accompaniment_required')
              : i18n.t('invite_form_accompaniment_confirmed')
          }
        />
      )}
      {invitation.confirmationRequired && (
        <VmsInput
          inputName="confirmationRequired"
          disabled={true}
          rowClass={classes.dialogRow2}
          iconLeft={<HowToRegRounded />}
          iconRight={
            <IconButton disabled={true}>
              <CheckBox color="primary" />
            </IconButton>
          }
          textValue={i18n.t('confirmation required')}
          inputLabel={i18n.t('confirmation required')}
        />
      )}
      <VmsInput
        disabled={true}
        rowClass={classes.dialogRow2}
        iconLeft={<SignalWifi4Bar />}
        iconRight={
          <IconButton
            data-cy="invitation-button-purpose-wifi-enabled"
            disabled={visitClosed || isVisitor || arrivalDisabled}
            onClick={handleWifiEnabled}
          >
            {invitation.wifi_enabled ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </IconButton>
        }
        textValue={i18n.t('invite_form_wifi_enabled')}
        inputLabel={i18n.t('invite_form_wifi_enabled')}
      />
      {invitation.wifi_enabled && (
        <Aux>
          <Grid item className={classes.dialogRow2}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={6}>
                <VmsInput
                  iconLeft={<NetworkWifi />}
                  textValue={company.wifi_ssid}
                  placeholder={i18n.t('invite_form_wifi_ssid')}
                  inputLabel={i18n.t('invite_form_wifi_ssid')}
                  disabled={true}
                  inputClasses={{ input: classes.fullWidth }}
                />
              </Grid>
              <Grid item xs={6}>
                <VmsInput
                  iconLeft={<WifiLock />}
                  textValue={company.wifi_psk}
                  placeholder={i18n.t('invite_form_wifi_psk')}
                  inputLabel={i18n.t('invite_form_wifi_psk')}
                  disabled={true}
                  inputClasses={{ input: classes.fullWidth }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.dialogRow2}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={6}>
                <VmsInput
                  iconLeft={<Person className={classes.required} />}
                  textValue={invitation.wifi_username}
                  inputName="wifi_username"
                  placeholder={i18n.t('invite_form_wifi_username')}
                  inputLabel={i18n.t('invite_form_wifi_username')}
                  onChange={changeData('wifi_username')}
                  inputClasses={{ input: classes.fullWidth }}
                  disabled={visitClosed || arrivalDisabled}
                />
              </Grid>
              <Grid item xs={6}>
                <VmsInput
                  iconLeft={<VpnKey className={classes.required} />}
                  textValue={invitation.wifi_password}
                  inputName="wifi_password"
                  placeholder={i18n.t('invite_form_wifi_password')}
                  inputLabel={i18n.t('invite_form_wifi_password')}
                  onChange={changeData('wifi_password')}
                  inputClasses={{ input: classes.fullWidth }}
                  disabled={visitClosed || arrivalDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Aux>
      )}
      <VmsDialogRowTextField
        rowClass={classes.dialogRowNote}
        iconLeft={<EventNote />}
        textValue={invitation.note}
        inputName="note"
        placeholder={i18n.t('invite_form_note')}
        inputLabel={i18n.t('invite_form_note')}
        onChange={changeData('note')}
        disabled={isVisitor || visitClosed}
        multiline={true}
        rows={5}
        maxLength={512}
      />
      {isAdmin && (
        <VmsDialogRowTextField
          rowClass={classes.dialogRowNoteSmall}
          iconLeft={<EventNote />}
          textValue={invitation.porterNote}
          inputName={'porterNote'}
          placeholder={i18n.t('invite_form_note_porter')}
          inputLabel={i18n.t('invite_form_note_porter')}
          onChange={changeData('porterNote')}
          disabled={visitClosed}
          multiline={true}
          rows={2}
          maxLength={256}
        />
      )}
    </Grid>
  );
};

VisitorTab.propTypes = {
  classes: PropTypes.object.isRequired,
  changeData: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  visitPurposes: PropTypes.object.isRequired,
  onHostSuggestionClearRequested: PropTypes.func.isRequired,
  onHostSuggestionSelected: PropTypes.func.isRequired,
  onUserSuggestionsClearRequested: PropTypes.func.isRequired,
  onUserSuggestionSelected: PropTypes.func.isRequired,
  onEmailSuggestionsClearRequested: PropTypes.func.isRequired,
  companyData: PropTypes.object,
  onPhoneSuggestionsClearRequested: PropTypes.func.isRequired,
  handleAddVisitorToGroupVisit: PropTypes.func.isRequired,
  handleRemoveVisitorFromGroupVisit: PropTypes.func.isRequired,
  handleChangeCurrentVisitFromGroup: PropTypes.func.isRequired,
  invitationState: PropTypes.object.isRequired,
  userClosed: PropTypes.bool.isRequired,
  visitClosed: PropTypes.bool.isRequired,
  isGroupInvitation: PropTypes.bool.isRequired,
  accompanimentDoesntExist: PropTypes.bool.isRequired,
  emailValid: PropTypes.bool.isRequired,
  phoneValid: PropTypes.bool.isRequired,
  loginValid: PropTypes.bool.isRequired,
  delegateValid: PropTypes.bool.isRequired,
};

const skipUpdate = (prevProps, nextProps) => {
  const currentTab = nextProps.currentTab || prevProps.currentTab;
  return currentTab !== invite_tab_indexes.VISITOR;
};

export default withStyles(styles)(memo(VisitorTab, skipUpdate));
