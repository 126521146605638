import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Auth from 'modules/Auth';
import UserEditor from './UserEditor';
import { FilterIconButton } from 'components';
import {
  createEmptyUserEditorState,
  createUserEditorState,
} from './UserEditor';
import i18n from 'assets/i18n';
import { UserService, ExportService, ConfigurationService } from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import classNames from 'classnames';
import tableStyles from 'assets/jss/tableStyles';
import { emptySettingSearchState } from '../SettingsPage';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { routes, type_constants, user_roles, user_status } from 'AppSettings';
import UserDocumentModel from 'services/models/UserDocumentModel';
import UserFilterMenu from './UserFilterMenu';
import produce from 'immer';
import { withCompanyDataContext } from 'components/common/context';
import { CloudUpload } from '@material-ui/icons';
import ImportUsers from './ImportUsers';

const styles = (theme) => ({
  ...tableStyles(theme),
});

class UsersPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      user: Auth.getUser(),
      value: 0,
      users: [],
      modalOpen: false,
      menuAnchorEl: null,
      currentItem: {},
      importOpen: false,
      userEditState: createEmptyUserEditorState(
        props.companyDataContext.companyData.location.language
      ),
      tableHeaderState: createTableHeaderState([]),
      highlightedId: '',
      filterAnchorEl: null,
      rolesFilter: this.setupFilterRoles(),
      statusFilter: this.setupFilterStatuses(),
      filteredUsers: [],
    };

    this.tableHeader = null;
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadData();
    this.setSearchSettings();
    this.loadDepartments();
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_USERS) this.handleNewClick();
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  loadDepartments = () => {
    ConfigurationService.getTypesForConfiguration(type_constants.DEPARTMENT)
      .then((response) => {
        let departments = { key: [], value: [] };
        response.forEach((department) => {
          departments.key.push(department.key);
          departments.value.push(department.key);
        });

        this.setState((prevState) => {
          return {
            ...prevState,
            userEditState: {
              ...prevState.userEditState,
              departments: departments,
            },
          };
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleImportOpen = () => {
    this.setState({
      importOpen: true,
    });
  };

  handleImportClose = (reloadUsers) => {
    this.setState({
      importOpen: false,
    });

    if (reloadUsers) {
      this.loadData();
    }
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleUserSave = () => {
    this.handleModalClose();
    this.loadData();
  };

  handleNewClick = () => {
    this.setState((prevState) => {
      const { departments } = prevState.userEditState;
      const { companyDataContext } = this.props;
      return {
        userEditState: createEmptyUserEditorState(
          companyDataContext.companyData.location.language,
          departments
        ),
        modalOpen: true,
      };
    });
  };

  handleEditModalOpen = (item) => async (event) => {
    const files = await this.loadUserDocumentsById(item._id);
    this.setState((prevState) => {
      const { departments } = prevState.userEditState;
      return {
        modalOpen: true,
        menuAnchorEl: null,
        currentItem: item,
        userEditState: createUserEditorState(item, departments, files),
      };
    });
  };

  loadData = async () => {
    UserService.getUsers()
      .then((users) => {
        this.setState(
          (prevState) =>
            produce(prevState, (draft) => {
              draft.currentItem = {};
              draft.users = users;
              draft.filteredUsers = users;
              draft.tableHeaderState.data = users;
            }),
          this.filterUsersAndSave
        );
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadUserDocumentsById = async (user_id) => {
    try {
      const userDocuments = await UserService.getUserDocuments(user_id);
      return userDocuments
        .filter((ud) => ud.type === type_constants.PERSONAL_DOCUMENT)
        .map((doc) => {
          return UserDocumentModel.fromJson(doc);
        });
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
      return [];
    }
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_user_dialog_remove_selected_title'),
      i18n.t('settings_user_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => async (event) => {
    const {
      tableHeaderState: { selected },
    } = this.state;

    const promises = selected.map((user) => UserService.deleteUser(user._id));
    try {
      await Promise.all(promises);
      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.tableHeaderState.selected = [];
        })
      );
      openSnackbar(i18n.t('removed'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      this.loadData();
    }
  };

  handleRemoveUserOpenDialog = (userId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_user_dialog_remove_title'),
      i18n.t('settings_user_dialog_remove_description'),
      this.handleRemoveUser(userId)
    );
  };

  handleRemoveUser = (userId) => (event) => {
    var self = this;
    UserService.deleteUser(userId)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  handleEditUserEditContainer = (userEditState) => {
    this.setState({
      userEditState: userEditState,
    });
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleMenuClosed = () => {
    this.setState({ currentItem: {} });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  handleUserSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleUserSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestUser(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.name;
      onChangeSearch(settingSearchState);
    }
  };

  handleUserSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestUser = (_id) => {
    const { filteredUsers, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = filteredUsers.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService = this.getSuggestions;
      settingSearchState.onSuggestionsClearRequested =
        this.handleUserSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleUserSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleUserSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.name;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return this.filterUsers(response);
      };
      onChangeSearch(settingSearchState);
    }
  };

  getSuggestions = () => {
    const { settingSearchState } = this.props;

    return UserService.getUserSuggestions(
      settingSearchState.searchValue === ''
        ? '.*'
        : settingSearchState.searchValue,
      false,
      false,
      false,
      ['name', 'email', 'phone']
    );
  };

  setupFilterRoles = () => {
    const filter = {};
    for (const roleKey in user_roles) {
      if (Object.hasOwnProperty.call(user_roles, roleKey)) {
        const role = user_roles[roleKey];
        //Skip global admin
        if (role === user_roles.GLOBAL_ADMIN) {
          continue;
        }
        filter[role] = true;
      }
    }
    return filter;
  };

  setupFilterStatuses = () => {
    const filter = {};
    for (const statusKey in user_status) {
      if (Object.hasOwnProperty.call(user_status, statusKey)) {
        if (user_status[statusKey] !== 'closed') {
          const status = user_status[statusKey];
          filter[status] = true;
        }
      }
    }
    return filter;
  };

  filterUsers = (users) => {
    const { rolesFilter, statusFilter } = this.state;
    return users.filter((user) => {
      return statusFilter[user.status] && rolesFilter[user.role];
    });
  };

  filterUsersAndSave = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        const filteredUsers = this.filterUsers(draft.users);
        draft.filteredUsers = filteredUsers;
        draft.tableHeaderState.data = filteredUsers;

        const maxPages = Math.max(
          Math.ceil(filteredUsers.length / draft.tableHeaderState.rowsPerPage) -
            1,
          0
        );
        if (draft.tableHeaderState.page > maxPages) {
          draft.tableHeaderState.page = maxPages;
        }
      })
    );
  };

  handleFilterRoleClicked = (role) => () => {
    this.setState(
      (prevState) =>
        produce(prevState, (draft) => {
          draft.rolesFilter[role] = !draft.rolesFilter[role];
        }),
      this.filterUsersAndSave
    );
  };

  handleFilterStatusClicked = (status) => () => {
    this.setState(
      (prevState) =>
        produce(prevState, (draft) => {
          draft.statusFilter[status] = !draft.statusFilter[status];
        }),
      this.filterUsersAndSave
    );
  };

  handleFilterRoleAllClick = () => {
    this.setState((prevState) => {
      const filter = [
        { ...prevState.rolesFilter },
        { ...prevState.statusFilter },
      ];
      if (!this.allFiltersChecked()) {
        for (let id in filter) {
          for (let status in filter[id]) {
            if (filter[id].hasOwnProperty(status)) {
              filter[id][status] = true;
            }
          }
        }
      } else {
        for (let id in filter) {
          for (let status in filter[id]) {
            if (filter[id].hasOwnProperty(status)) {
              filter[id][status] = false;
            }
          }
        }
      }
      return { rolesFilter: filter[0], statusFilter: filter[1] };
    }, this.filterUsersAndSave);
  };

  allFiltersChecked = () => {
    const filter = [
      { ...this.state['rolesFilter'] },
      { ...this.state['statusFilter'] },
    ];
    for (let id in filter) {
      for (let status in filter[id]) {
        if (filter[id].hasOwnProperty(status)) {
          if (!filter[id][status]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  handleOpenFilter = (event) => {
    this.setState({
      filterAnchorEl: event.target,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      filterAnchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      filteredUsers,
      menuAnchorEl,
      currentItem,
      tableHeaderState,
      highlightedId,
      filterAnchorEl,
      rolesFilter,
      statusFilter,
      importOpen,
    } = this.state;
    const { rowsPerPage, page, selected } = tableHeaderState;
    const loggedInUserId = Auth.getUser()._id;
    const isUserNotAdmin = !Auth.isUserAdmin();
    const isTenantNotSame =
      currentItem.tenantId && Auth.getUser().tenantId !== currentItem.tenantId;
    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                exportData={ExportService.users(selected)}
                exportDataFilename={'users.csv'}
                leftButton={
                  !isUserNotAdmin && (
                    <IconButton
                      aria-label="Import users"
                      onClick={this.handleImportOpen}
                    >
                      <CloudUpload />
                    </IconButton>
                  )
                }
                limitedView={isUserNotAdmin}
                additionalButton={
                  <FilterIconButton
                    data-cy="settings-users-button-filter"
                    onClick={this.handleOpenFilter}
                  />
                }
                prevPageButtonDataCy="settings-users-button-previous-page"
                nextPageButtonDataCy="settings-users-button-next-page"
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    const isSelected = this.isSelected(user);
                    return (
                      <TableRow
                        key={user._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === user._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          {!isUserNotAdmin && (
                            <Checkbox
                              checked={isSelected}
                              color="primary"
                              onClick={this.handleClickRow(user)}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classes.tableCell}
                          padding="none"
                          onClick={this.handleEditModalOpen(user)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {user.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(classes.tableCell)}
                          padding="none"
                          onClick={this.handleEditModalOpen(user)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {user.email}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(classes.tableCell)}
                          padding="none"
                          onClick={this.handleEditModalOpen(user)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {user.company}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.textAlignRight
                          )}
                          padding="none"
                          onClick={this.handleEditModalOpen(user)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {i18n.t(`role_${user.role}`)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none"
                        >
                          {!isUserNotAdmin && (
                            <IconButton
                              className={classes.tableMenuButton}
                              aria-owns={menuAnchorEl ? 'table-menus' : null}
                              aria-haspopup="true"
                              onClick={(event) =>
                                this.handleMenuOpen(event, user)
                              }
                            >
                              <MoreHoriz className={classes.tableIcon} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionProps={{
            onExited: this.handleMenuClosed,
          }}
        >
          <MenuItem onClick={this.handleEditModalOpen(currentItem)}>
            {isTenantNotSame ? i18n.t('menu_show') : i18n.t('menu_edit')}
          </MenuItem>
          {!isTenantNotSame && currentItem._id !== loggedInUserId && (
            <MenuItem
              onClick={this.handleRemoveUserOpenDialog(currentItem._id)}
            >
              {i18n.t('menu_delete')}
            </MenuItem>
          )}
        </Menu>
        <UserEditor
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          onSave={this.handleUserSave}
          onChange={this.handleEditUserEditContainer}
          userEditState={this.state.userEditState}
          adminMode={true}
        />
        <ImportUsers open={importOpen} onClose={this.handleImportClose} />
        <UserFilterMenu
          anchor={filterAnchorEl}
          rolesFilter={rolesFilter}
          statusFilter={statusFilter}
          onClose={this.handleCloseFilter}
          onRoleClick={this.handleFilterRoleClicked}
          onStatusClick={this.handleFilterStatusClicked}
          onRoleAllClick={this.handleFilterRoleAllClick}
          stateOfFilters={this.allFiltersChecked()}
        />
      </div>
    );
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withCompanyDataContext(),
  withBus(),
  withStyles(styles, { withTheme: true })
)(UsersPage);
