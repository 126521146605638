import { DatePicker } from '@material-ui/pickers';
import { InputAdornment, withStyles } from '@material-ui/core';
import DateRange from '@material-ui/icons/DateRange';
import { date_formats } from '../../../AppSettings';
import { VmsLocalize } from '../date-time';
import { useTranslation } from 'react-i18next';

const styles = (theme) => {
  return {
    datePickerField: {
      width: '100%',
    },
    endAdornment: {
      color: theme.palette.primary4,
      display: 'flex',
      alignSelf: 'center',
    },
    inputField: {
      color: theme.palette.primary4,
    },
  };
};

const VmsDatePicker = (props) => {
  const [t] = useTranslation();
  const {
    classes,
    value,
    onChange,
    placeholder,
    disabled,
    minDate,
    minDateMessage,
    name,
  } = props;
  const format = props.format || date_formats.full_date_long;

  const onDateChange = (date) => {
    // if date is provided clear seconds and milliseconds and
    // convert it from moment object to Date object
    onChange(date.format(date_formats.date));
  };

  return (
    <VmsLocalize>
      <DatePicker
        disabled={disabled}
        className={classes.datePickerField}
        minDate={minDate}
        minDateMessage={minDateMessage}
        value={value}
        format={format}
        clearable
        ampm={false}
        onChange={onDateChange}
        InputProps={{
          name,
          endAdornment: (
            <InputAdornment className={classes.endAdornment} position="end">
              <DateRange />
            </InputAdornment>
          ),
          classes: {
            input: classes.inputField,
          },
          placeholder: placeholder,
        }}
        clearLabel={
          <span data-cy="date-time-picker-button-clear">{t('clear')}</span>
        }
        cancelLabel={
          <span data-cy="date-time-picker-button-cancel">{t('cancel')}</span>
        }
        okLabel={<span data-cy="date-time-picker-button-ok">{t('ok')}</span>}
      />
    </VmsLocalize>
  );
};

export default withStyles(styles)(VmsDatePicker);
