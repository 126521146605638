import {
  template_categories,
  template_types,
  type_constants,
  user_document_status_constants,
} from 'AppSettings';
import { openSnackbar } from 'components';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  ConfigurationService,
  DocumentService,
  FeedbackService,
  QuestionService,
  TemplateService,
} from 'services';
import UserDocumentModel from 'services/models/UserDocumentModel';

const emptyDocumentState = () => {
  return {
    documents: [],
    questionnaires: [],
    signed: [],
    feedback: [],
  };
};

const documentStateReducer = (state, action) => {
  switch (action.type) {
    case 'DOCUMENTS':
    case 'QUESTIONNAIRES':
    case 'SIGNED':
    case 'FEEDBACK':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const useLoadData = (emailDispatcher) => {
  const [visitorTypes, setVisitorTypes] = useState([]);
  const [visit_purposes, setVisitPurposes] = useState({});
  const [documents, documentsDispatcher] = useReducer(
    documentStateReducer,
    emptyDocumentState()
  );

  const loadVisitPurposes = useCallback(() => {
    ConfigurationService.getTypesForConfiguration(type_constants.VISIT_PURPOSE)
      .then(function (response) {
        const purposes = { key: [], value: [] };
        response.forEach((purpose) => {
          const key = purpose.key.split('#')[0];
          purposes.key.push(key);
          purposes.value.push(key);
        });
        setVisitPurposes(purposes);
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        setVisitPurposes([]);
      });
  }, []);

  const loadEmailTemplates = useCallback(() => {
    TemplateService.getTemplatesByCategoryAndType(
      template_categories.EMAIL,
      template_types.INVITE
    )
      .then(function (emailTemplates) {
        const inviteEmailTemplates = { key: [], value: [] };
        for (var i = 0; i < emailTemplates.length; i++) {
          inviteEmailTemplates.key[i] = i;
          inviteEmailTemplates.value[i] = emailTemplates[i].name;
        }
        if (emailDispatcher)
          emailDispatcher({
            type: 'LOAD_TEMPLATES',
            payload: { emailTemplates, inviteEmailTemplates },
          });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        if (emailDispatcher)
          emailDispatcher({
            type: 'LOAD_TEMPLATES',
            payload: { emailTemplates: [], inviteEmailTemplates: {} },
          });
      });
  }, [emailDispatcher]);

  const loadVisitorType = useCallback(() => {
    ConfigurationService.getVisitorTypes()
      .then((types) => {
        setVisitorTypes(types);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
        setVisitorTypes([]);
      });
  }, []);

  const loadUserDocuments = useCallback(() => {
    DocumentService.getDistinctTypes()
      .then(function (response) {
        const documents = [];
        response.forEach((doc) => {
          const data = {
            name: doc.name,
            document_id: doc.name,
            customName: doc.customName,
            required_for_entrance: doc.required_for_entrance,
            expires_in: doc.expires_in,
          };
          documents.push(
            new UserDocumentModel(
              null,
              null,
              type_constants.DOCUMENT,
              user_document_status_constants.PENDING,
              data
            )
          );
        });
        documentsDispatcher({ type: 'DOCUMENTS', payload: { documents } });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documentsDispatcher({ type: 'DOCUMENTS', payload: { documents: [] } });
      });
  }, []);

  const loadUserQuestionnaires = useCallback(() => {
    QuestionService.getDistinctTypes()
      .then(function (types) {
        const questionnaires = [];
        types.forEach((type) => {
          const data = {
            name: type.name,
            document_id: type.name,
            required_for_entrance: type.required_for_entrance,
            expires_in: type.expires_in,
            random_order_of_questions: type.random_order_of_questions,
            number_of_questions: type.number_of_questions,
          };
          questionnaires.push(
            new UserDocumentModel(
              null,
              null,
              type_constants.QUESTIONNAIRE,
              user_document_status_constants.PENDING,
              data
            )
          );
        });
        documentsDispatcher({
          type: 'QUESTIONNAIRES',
          payload: { questionnaires },
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documentsDispatcher({
          type: 'QUESTIONNAIRES',
          payload: { questionnaires: [] },
        });
      });
  }, []);

  const loadUserSignedDocuments = useCallback(() => {
    TemplateService.getDistinctTypesForSignedDocuments()
      .then(function (templates) {
        const signed = [];
        templates.forEach((t) => {
          const data = {
            name: t.name,
            body: t.name,
            document_id: t.name,
            required_for_entrance: t.required_for_entrance,
            expires_in: t.expires_in,
          };
          signed.push(
            new UserDocumentModel(
              null,
              null,
              type_constants.SIGNED,
              user_document_status_constants.PENDING,
              data
            )
          );
        });
        documentsDispatcher({ type: 'SIGNED', payload: { signed } });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documentsDispatcher({ type: 'SIGNED', payload: { signed: [] } });
      });
  }, []);

  const loadUserFeedback = useCallback(() => {
    FeedbackService.getDistinctTypes()
      .then((types) => {
        const feedback = [];
        types.forEach((type) => {
          const data = { name: type, document_id: type };
          feedback.push(
            new UserDocumentModel(
              null,
              null,
              type_constants.FEEDBACK,
              user_document_status_constants.UNAVAILABLE,
              data
            )
          );
        });
        documentsDispatcher({ type: 'FEEDBACK', payload: { feedback } });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        documentsDispatcher({ type: 'FEEDBACK', payload: { feedback: [] } });
      });
  }, []);

  const loadData = useCallback(() => {
    loadVisitPurposes();
    loadEmailTemplates();
    loadVisitorType();

    loadUserDocuments();
    loadUserQuestionnaires();
    loadUserSignedDocuments();
    loadUserFeedback();
  }, [
    loadVisitPurposes,
    loadEmailTemplates,
    loadVisitorType,
    loadUserDocuments,
    loadUserQuestionnaires,
    loadUserSignedDocuments,
    loadUserFeedback,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [visitorTypes, visit_purposes, documents, loadData];
};

export default useLoadData;
