import { Paper, Typography, withStyles } from '@material-ui/core';
import commonStyle from 'assets/jss/commonStyles';
import i18n from 'assets/i18n';

const styles = (theme) => ({
  ...commonStyle(theme),
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  text: {
    padding: 0,
  },
});

const PasswordHelp = (props) => {
  const { classes } = props;
  return (
    <Paper className={classes.paper}>
      <Typography className={classes.text}>
        {i18n.t('password_text')}
        <ul>
          <li>{i18n.t('password_element1')}</li>
          <li>{i18n.t('password_element2')}</li>
          <li>{i18n.t('password_element3')}</li>
          <li>{i18n.t('password_element4')}</li>
        </ul>
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(PasswordHelp);
