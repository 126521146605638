import Loadable from 'react-loadable';
import Loading from 'components/common/Loading';

export { default as DocumentItem } from './DocumentItem';
export {
  default as DocumentQuestionnaireDetail,
  createEmptyQuestionnaireState,
  editQuestionnaireState,
} from './DocumentQuestionnaireDetail';
export { default as QuestionnaireDialog } from './QuestionnaireDialog';
export {
  default as FeedbackDetail,
  createEmptyFeedbackState,
  editFeedbackState,
} from './FeedbackDetail';
export { default as FeedbackDialog } from './FeedbackDialog';
export { default as DocumentDetail } from './DocumentDetail';
export { default as DocumentSignedPreview } from './DocumentSignedPreview';
export { default as DocumentSigningCanvas } from './DocumentSigningCanvas';
const DocumentPreview = Loadable({
  loader: () =>
    import('./DocumentPreview') /* webpackChunkName: "DocumentPreview" */,
  loading: Loading,
});
export { DocumentPreview };
