import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Grid } from '@material-ui/core';
import commonStyles from 'assets/jss/commonStyles';
import moment from 'moment';
import deepEqual from 'deep-equal';
import { VmsAvatar } from 'components';
import i18n from 'assets/i18n';

const styles = (theme) => ({
  ...commonStyles(theme),
  padding: {
    paddingLeft: theme.spacing(2),
    paddingTop: 66,
    height: 'calc(100% - 182px)',
  },
  title1: {
    color: theme.palette.primary8,
    fontSize: 24,
    letterSpacing: 1.5,
  },
  title2: {
    color: theme.palette.primary4,
    fontSize: 47,
    letterSpacing: 3.1,
    paddingTop: theme.spacing(2),
    height: '2.9em',
    overflow: 'hidden',
  },
  titleTime: {
    color: theme.palette.primary4,
    fontSize: 24,
    letterSpacing: 1.5,
    paddingTop: theme.spacing(2),
  },
  userName: {
    color: theme.palette.primary5,
    fontSize: 18,
    letterSpacing: 1.2,
    padding: theme.spacing(),
  },
  avatar: {
    width: 48,
    height: 48,
  },
  participants: {
    marginTop: 47,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 196px)',
  },
  maxWidth: {
    maxWidth: '50vw',
    height: '100%',
  },
  TopPadding: {
    paddingTop: theme.spacing(),
  },
});

class MeetingRoomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meeting: {},
      photoUrls: [], //[{participant_id, photoUrl},...]
      onGoing: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { meeting, onGoing } = this.props;
    if (
      !deepEqual(this.state.meeting, meeting) ||
      !deepEqual(this.state.onGoing, onGoing)
    ) {
      this.setState({ meeting: meeting, onGoing: onGoing });
    }
  }

  render() {
    const { classes } = this.props;
    const { meeting, onGoing } = this.state;
    return (
      <div className={classes.padding}>
        {onGoing ? (
          <Typography className={classes.title1}>
            {i18n.t('meeting_room_ongoing_meeting')}
          </Typography>
        ) : (
          <Typography className={classes.title1}>
            {i18n.t('meeting_room_next_meeting')}
          </Typography>
        )}
        {meeting.hasOwnProperty('name') &&
          meeting.hasOwnProperty('startTime') &&
          meeting.hasOwnProperty('duration') &&
          meeting.hasOwnProperty('participants') && (
            <div className={classes.maxWidth}>
              <Typography className={classes.title2}>{meeting.name}</Typography>
              <Typography className={classes.titleTime}>
                {moment(meeting.startTime).format(
                  moment(meeting.startTime).isSame(moment(), 'day')
                    ? 'HH:mm'
                    : 'DD.MM.YYYY HH:mm'
                ) +
                  ' - ' +
                  moment(meeting.startTime)
                    .add(meeting.duration, 'minutes')
                    .format(
                      moment(meeting.startTime)
                        .add(meeting.duration, 'minutes')
                        .isSame(moment(), 'day')
                        ? 'HH:mm'
                        : 'DD.MM.YYYY HH:mm'
                    )}
              </Typography>
              <div className={classes.participants}>
                {meeting.hasOwnProperty('organiser') && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <VmsAvatar
                        avatar={meeting.organiser.avatar}
                        alt={
                          meeting.organiser
                            ? meeting.organiser.name
                            : 'avatar_image'
                        }
                        photoId={(meeting.organiser || {}).photo}
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.userName}>
                        {meeting.organiser.name} (
                        {i18n.t('meeting_room_organiser')})
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {meeting.participants.map((p, index) => {
                  return (
                    <Grid
                      className={classes.TopPadding}
                      key={index}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <VmsAvatar
                          avatar={p.avatar}
                          alt={p.user ? p.user.name : 'avatar_image'}
                          photoId={(p.user || {}).photo}
                          className={classes.avatar}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.userName}>
                          {p.user.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  }
}

MeetingRoomDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingRoomDetail);
