import { successColor } from 'assets/jss/material-dashboard-react.jsx';
import commonStyles from 'assets/jss/commonStyles';
import clampText from './clampText';

const reportsStyle = (theme) => ({
  ...commonStyles(theme),
  paper: {
    padding: theme.spacing(2),
  },
  successText: {
    color: successColor,
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      top: '0px',
      position: 'relative',
      marginRight: '8px',
      marginLeft: '0px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '0px',
      position: 'relative',
      marginRight: '8px',
      marginLeft: '0px',
    },
  },
  grid: {
    width: 'inherit',
    height: 'inherit',
  },
  cardHeader: {
    height: 140,
  },
  cardFooter: {
    height: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
    display: 'flex',
  },
  numberFont: {
    fontSize: 64,
    letterSpacing: 0.7,
    color: theme.palette.white,
  },
  clampNumber: {
    ...clampText(`${theme.typography.body1.lineHeight}em`, 2),
  },
  clampGraph: {
    ...clampText(`${theme.typography.body1.lineHeight}em`, 1),
  },
});

export default reportsStyle;
