import Answer from './Answer';
import { Container, Draggable } from 'react-smooth-dnd';

const AnswerList = (props) => {
  const getGhostParent = () => {
    return document.body;
  };
  return (
    <div>
      <Container
        onDrop={props.onDrop}
        lockAxis="y"
        getGhostParent={getGhostParent}
      >
        {props.answers.map((ans, i) => (
          <Draggable key={i}>
            <Answer
              isCorrect={ans.isCorrect}
              answer={ans.answer}
              key={i}
              index={i}
              onRemove={props.onRemove}
              onInputChange={props.onInputChange}
              onCheckboxChange={props.onCheckboxChange}
              showRemove={true}
              showError={false}
            />
          </Draggable>
        ))}
      </Container>
    </div>
  );
};

export default AnswerList;
