const tableStyles = (theme) => ({
  tableInfoFont: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary4,
  },
  textAlignRight: {
    textAlign: 'right',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  tableEditButtons: {
    width: 130,
    minWidth: 130,
  },
  tableCell: {
    maxWidth: 400,
  },
  tableRow: {
    height: 64,
  },
  headCheckbox: {
    marginLeft: 4,
  },
  tableCellCheckbox: {
    width: 64,
  },
  tableMenuButton: {
    marginLeft: theme.spacing(2),
  },
  tableIcon: {
    color: theme.palette.primary4,
  },
  headDelete: {
    marginRight: theme.spacing(0),
  },
  paginationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary3,
    color: theme.palette.common.white,
    height: 48,
  },
  settingsTableGridHeader: {
    width: '100%',
  },
  settingsTableGridContent: {
    overflow: 'visible',
    width: '100%',
    height: 'calc(100vh - 188px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 268px)',
    },
  },
});

export default tableStyles;
