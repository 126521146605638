import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CompanyContact, CompanyTable } from '../../../components';
import {
  createEmptyCompanyContactState,
  editCompanyContactState,
} from '../../../components/settings/company/CompanyContact';
import {
  createEmptyCompanyTableState,
  editCompanyTableState,
} from '../../../components/settings/company/CompanyTable';
import ConfigurationService from '../../../services/ConfigurationService';
import {
  configuration_location_type,
  configuration_group,
} from '../../../AppSettings';
import { openSnackbar } from '../../../components/common/bars/SnackBar';
import UploadLogoDialog from './UploadLogoDialog';
import Aux from 'hoc/Auxiliary';
import Auth from 'modules/Auth';
import { DocumentService } from 'services';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  firstGridContainerRow: {
    height: 283,
  },
  inputRow: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
});

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyContactState: createEmptyCompanyContactState(),
      companyTableState: createEmptyCompanyTableState(),
      company_id: '',
      uploadLogoDialogOpen: false,
      companyLogos: {},
      logoName: null,
    };
  }

  componentDidMount() {
    this.loadCompanyContact();
    this.loadCompanyLogo();
  }

  //Logo is loaded twice (in BaseAppBar), to avoid this, we would have to either use Context or Redux, TODO discuss if we have budget and time to
  //refactor this
  loadCompanyLogo = () => {
    DocumentService.getCompanyLogos()
      .then((response) => {
        const logos = {};
        response.forEach((logo) => {
          logos[logo.name] = logo;
        });
        this.setState({
          companyLogos: logos,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadCompanyContact = () => {
    const self = this;
    ConfigurationService.getConfigurationsForGroup(configuration_group.LOCATION)
      .then(function (response) {
        const name = response.find((c) => {
          return c.key === configuration_location_type.NAME;
        });
        const address = response.find((c) => {
          return c.key === configuration_location_type.ADDRESS;
        });
        const contact = response.find((c) => {
          return c.key === configuration_location_type.CONTACT;
        });
        const position = response.find((c) => {
          return c.key === configuration_location_type.POSITION;
        });
        const language = response.find((c) => {
          return c.key === configuration_location_type.LANGUAGE;
        });

        const companyContact = {
          name: name ? name.value : '',
          address: address ? address.value : '',
          contact: contact ? contact.value : '',
          position: position ? position.value : '',
          timezone: Auth.getUser().timezone,
          lang: language ? language.value : '',
        };
        self.setState({
          companyContactState: editCompanyContactState(companyContact),
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleContactChange = (companyContact) => {
    this.setState({
      companyContactState: editCompanyContactState(companyContact),
    });
  };

  handleTableChange = (companyTable) => {
    this.setState({
      companyTableState: editCompanyTableState(companyTable),
    });
  };

  handleUploadDialogClose = () => {
    this.setState({
      uploadLogoDialogOpen: false,
    });
  };

  handleUploadDialogOpen = (name) => () => {
    this.setState({
      uploadLogoDialogOpen: true,
      logoName: name,
    });
  };

  handeDeleteLogo = (name) => {
    const { companyLogos } = this.state;
    const result = { ...companyLogos };
    delete result[name];

    this.setState({
      companyLogos: result,
    });
  };

  render() {
    const { settingSearchState, onChangeSearch } = this.props;
    const {
      companyContactState,
      companyTableState,
      uploadLogoDialogOpen,
      companyLogos,
      logoName,
    } = this.state;

    return (
      <Aux>
        <CompanyContact
          companyContactState={companyContactState}
          onChange={this.handleContactChange}
          onLogoUploadClick={this.handleUploadDialogOpen}
        />
        <CompanyTable
          companyTableState={companyTableState}
          onChange={this.handleTableChange}
          settingSearchState={settingSearchState}
          onChangeSearch={onChangeSearch}
        />
        <UploadLogoDialog
          open={uploadLogoDialogOpen}
          onClose={this.handleUploadDialogClose}
          logo={companyLogos[logoName]}
          updateLogo={this.loadCompanyLogo}
          removeLogo={this.handeDeleteLogo}
          name={logoName}
        />
      </Aux>
    );
  }
}

CompanyPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CompanyPage);
