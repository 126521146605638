import { withStyles, Grid, Fab } from '@material-ui/core';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import CloudDownload from '@material-ui/icons/CloudDownload';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import Edit from '@material-ui/icons/Edit';
import commonStyles from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
    position: 'relative',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    height: '100%',
  },
  dropzone: {
    width: '100%',
    height: 150,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    backgroundColor: theme.palette.primary3,
    borderBottom: `1px solid ${theme.palette.primary7}`,
  },
  dropZoneDownloadIcon: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.common.white,
  },
  dropZoneEditIcon: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(),
    color: theme.palette.common.white,
  },
  dropZoneRotateLeftIcon: {
    position: 'absolute',
    left: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.common.white,
  },
  dropZoneRotateRigthIcon: {
    position: 'absolute',
    left: theme.spacing(7),
    top: theme.spacing(),
    color: theme.palette.common.white,
  },
});

const VmsDialogRowFilePreview = (props) => {
  const {
    downloadUrl,
    classes,
    rowClass,
    previewComponent,
    iconLeft,
    editMode,
    onEditClick,
    onRotateLeftClick,
    onRotateRightClick,
  } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <div className={classes.iconLeft}>
          {iconLeft ? iconLeft : <AttachFile className={classes.required} />}
        </div>
      </Grid>
      <Grid item className={classes.rowContent}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.dropzone}
        >
          {previewComponent}
        </Grid>
        <a href={downloadUrl} className={classes.link} download>
          <Fab
            disabled={!downloadUrl}
            size="small"
            color="primary"
            className={classes.dropZoneDownloadIcon}
          >
            <CloudDownload />
          </Fab>
        </a>
        {editMode && (
          <Fab
            size="small"
            color="primary"
            className={classes.dropZoneEditIcon}
            onClick={onEditClick}
          >
            <Edit />
          </Fab>
        )}
        {onRotateLeftClick && (
          <Fab
            size="small"
            color="primary"
            className={classes.dropZoneRotateLeftIcon}
            onClick={onRotateLeftClick}
          >
            <RotateLeft />
          </Fab>
        )}
        {onRotateRightClick && (
          <Fab
            size="small"
            color="primary"
            className={classes.dropZoneRotateRigthIcon}
            onClick={onRotateRightClick}
          >
            <RotateRight />
          </Fab>
        )}
      </Grid>
    </Grid>
  );
};

VmsDialogRowFilePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.element,
  downloadUrl: PropTypes.string,
  previewComponent: PropTypes.any.isRequired,
  rowClass: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default withStyles(styles)(VmsDialogRowFilePreview);
