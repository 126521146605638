import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyles from '../../../../assets/jss/commonStyles';
import VmsDialogRowLabel from './VmsDialogRowLabel';

const styles = (theme) => ({
  ...commonStyles(theme),
  placeholderDiv: {
    height: 24,
    width: 24,
  },
});

/**
 * Component which makes array of strings into dialog row labels displayed under each other.
 * Provided left icon is visible ony in first row.
 */
const vmsDialogRowLabelCollection = (props) => {
  const { classes, iconLeft, noItemsText } = props;
  const items = props.items || [];
  return (
    <div>
      {items.length > 0 ? (
        items.map((item, idx) => (
          <VmsDialogRowLabel
            key={`rowLabelCollection_${idx}`}
            rowClass={classes.dialogRowSmall}
            // if idx is 0, show defined icon.. else show placeholder (empty) div
            iconLeft={
              idx === 0 ? iconLeft : <div className={classes.placeholderDiv} />
            }
            textValue={item}
          />
        ))
      ) : (
        <VmsDialogRowLabel
          rowClass={classes.dialogRowSmall}
          iconLeft={iconLeft}
          textValue={noItemsText}
        />
      )}
    </div>
  );
};

vmsDialogRowLabelCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.string),
  noItemsText: PropTypes.string, // text shown if now items are provided
};

export default withStyles(styles)(vmsDialogRowLabelCollection);
