import PropTypes from 'prop-types';
import { withStyles, Grid, Button } from '@material-ui/core';
import {
  CompanyDataContext,
  VmsGoogleMap,
  VmsInput,
  VmsLocalizationList,
} from 'components';
import i18n from '../../../assets/i18n';
import classNames from 'classnames';
import Coordinates from 'coordinate-parser';
import commonStyles from '../../../assets/jss/commonStyles';
import ConfigurationService from '../../../services/ConfigurationService';
import { openSnackbar } from '../../common/bars/SnackBar';
import {
  configuration_group,
  configuration_location_type,
} from '../../../AppSettings';
import Auth from '../../../modules/Auth';
import { useContext } from 'react';

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    height: '100%',
  },
  firstGridContainerRow: {
    height: 283,
  },
  inputRow: {
    padding: theme.spacing(2),
  },
  map: {
    paddingBottom: theme.spacing(2),
  },
  width: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  buttonRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonGrid: {
    margin: -theme.spacing(),
    maxWidth: `calc(50% + ${theme.spacing(2)}px)`,
    flexBasis: `calc(50% + ${theme.spacing(2)}px)`,
  },
  buttonSpacing: {
    margin: theme.spacing(),
  },
});

export const createEmptyCompanyContactState = () => {
  return {
    name: '',
    address: '',
    contact: '',
    position: '', //new Coordinates('49.216401, 18.744863')//{ lat: 49.216401, lng: 18.744863 },//Zilina's position.... position: { lat: 0, lng: 0 },
    timezone: Auth.getUser().timezone,
    lang: '',
  };
};

export const editCompanyContactState = (companyContact) => {
  return {
    name: companyContact.name,
    address: companyContact.address,
    contact: companyContact.contact,
    position: companyContact.position,
    timezone: companyContact.timezone,
    lang: companyContact.lang,
  };
};

const CompanyContact = (props) => {
  const { classes, companyContactState, onLogoUploadClick } = props;
  const { revalidateCompanyData } = useContext(CompanyDataContext);
  const { name, address, contact, position, timezone, lang } =
    companyContactState;
  const isUserNotAdmin = !Auth.isUserAdmin();

  const onChangeState = () => {
    props.onChange(companyContactState);
  };

  const changeData = (inputName, inputValue) => (event) => {
    const field = inputName !== undefined ? inputName : event.target.name;
    const fieldValue =
      inputValue !== undefined ? inputValue : event.target.value;
    switch (field) {
      case 'name':
        companyContactState.name = fieldValue;
        break;
      case 'address':
        companyContactState.address = fieldValue;
        break;
      case 'contact':
        companyContactState.contact = fieldValue;
        break;
      case 'position':
        companyContactState.position = fieldValue;
        break;
      case 'lang':
        companyContactState.lang = fieldValue;
        break;
      default:
        break;
    }
    onChangeState();
  };

  const getPositionLattitude = () => {
    if (isValidPosition(position)) {
      return new Coordinates(position).getLatitude();
    } else {
      return null;
    }
  };

  const getPositionLongitude = () => {
    if (isValidPosition(position)) {
      return new Coordinates(position).getLongitude();
    } else {
      return null;
    }
  };

  const isValidPosition = function (position) {
    var isValid;
    try {
      isValid = true;
      new Coordinates(position);
      return isValid;
    } catch (error) {
      isValid = false;
      return isValid;
    }
  };

  const canSave = () => {
    return isValidPosition(position) && name && address && contact && lang;
  };

  const handleSave = () => (event) => {
    let configurations = [];
    let config = {
      key: configuration_location_type.NAME,
      value: name,
      group: configuration_group.LOCATION,
    };
    if (name !== '') {
      configurations.push(config);
    }
    config = {
      key: configuration_location_type.ADDRESS,
      value: address,
      group: configuration_group.LOCATION,
    };
    if (address !== '') {
      configurations.push(config);
    }
    config = {
      key: configuration_location_type.POSITION,
      value: position,
      group: configuration_group.LOCATION,
    };
    if (position !== '' && isValidPosition(position)) {
      configurations.push(config);
    }
    config = {
      key: configuration_location_type.CONTACT,
      value: contact,
      group: configuration_group.LOCATION,
    };
    if (contact !== '') {
      configurations.push(config);
    }
    config = {
      key: configuration_location_type.LANGUAGE,
      value: lang,
      group: configuration_group.LOCATION,
    };
    if (lang !== '') {
      configurations.push(config);
    }
    for (let i = 0; i < configurations.length; i++) {
      ConfigurationService.updateConfigurationForGroup(
        configuration_group.LOCATION,
        configurations[i]
      )
        .then(function (response) {
          if (i + 1 === configurations.length) {
            openSnackbar(i18n.t('settings_company_location_saved'));
            revalidateCompanyData();
          }
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  const center = { lat: getPositionLattitude(), lng: getPositionLongitude() };
  const coordinates = [{ position: center }];

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item md={6} xs={12} className={classes.width}>
        <Grid
          container
          className={classNames(classes.root, classes.inputRow)}
          direction="row"
          justifyContent="space-around"
          alignItems="stretch"
          spacing={2}
        >
          <VmsInput
            rowClass={props.classes.dialogRow}
            textValue={name}
            placeholder={i18n.t('settings_company_location_name')}
            inputLabel={i18n.t('settings_company_location_name')}
            onChange={changeData('name')}
            disabled={isUserNotAdmin}
          />
          <VmsInput
            rowClass={props.classes.dialogRow}
            textValue={address}
            placeholder={i18n.t('settings_company_location_address')}
            inputLabel={i18n.t('settings_company_location_address')}
            onChange={changeData('address')}
            disabled={isUserNotAdmin}
          />
          <VmsInput
            rowClass={props.classes.dialogRow}
            textValue={contact}
            placeholder={i18n.t('settings_company_location_contact')}
            inputLabel={i18n.t('settings_company_location_contact')}
            onChange={changeData('contact')}
            disabled={isUserNotAdmin}
          />
          <VmsInput
            rowClass={props.classes.dialogRow}
            textValue={position}
            placeholder={i18n.t('settings_company_location_gps')}
            inputLabel={i18n.t('settings_company_location_gps')}
            onChange={changeData('position')}
            inputError={position !== '' && !isValidPosition(position)}
            disabled={isUserNotAdmin}
          />
          <VmsInput
            rowClass={props.classes.dialogRow}
            textValue={timezone}
            placeholder={i18n.t('settings_company_timezone')}
            inputLabel={i18n.t('settings_company_timezone')}
            disabled={true}
          />
          <VmsLocalizationList
            lang={lang}
            onChange={changeData('lang')}
            rowClass={props.classes.dialogRow}
            justifyContent="flex-end"
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} className={classes.map}>
        {getPositionLattitude() !== null && getPositionLongitude() !== null && (
          <VmsGoogleMap coordinates={coordinates} center={center} />
        )}
      </Grid>
      <Grid item xs={12} className={classes.buttonRow}>
        <Grid container>
          <Grid item xs={6} className={classes.buttonGrid}>
            {!isUserNotAdmin && (
              <Button
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.buttonSpacing)}
                disabled={!canSave()}
                onClick={handleSave()}
              >
                {i18n.t('save')}
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            alignItems="flex-end"
            justifyContent="flex-end"
            container
            className={classes.buttonGrid}
          >
            {!isUserNotAdmin && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.buttonSpacing)}
                  onClick={onLogoUploadClick('logo')}
                >
                  {i18n.t('change_logo')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.buttonSpacing)}
                  onClick={onLogoUploadClick('logo_mobile')}
                >
                  {i18n.t('change_logo_mobile')}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CompanyContact.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  companyContactState: PropTypes.object.isRequired,
  onLogoUploadClick: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(CompanyContact);
