import {
  Grid,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProcessEventService from 'services/ProcessEventService';
import { process_event_types } from 'AppSettings';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteLoading } from 'components';
import { Fragment } from 'react';

const styles = (theme) => ({
  scroll: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: theme.spacing(),
  },
  container: {
    padding: theme.spacing(2),
    '& div': {
      width: '100%',
    },
    height: '100%',
    overflow: 'auto',
  },
  date: {
    flex: '0 1',
  },
  dateLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  event: {
    flex: '1 0',
  },
  eventLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary5,
  },
  loading: {
    height: 4,
  },
});

const third_party_postfix = '_third_party';

const getTypeMessage = (t, event) => {
  const message = 'users_events_' + event.type;
  const triggered_by = (event.triggered_by || {}).name;
  const triggered_by_id = (event.triggered_by || {})._id;
  const triggered_on = (event.triggered_on || {}).name;
  const data = event.data || {};
  switch (event.type) {
    case process_event_types.SENT_EMAIL:
      return t(message, {
        user: triggered_by,
        subject: data.subject,
        email: data.to,
      });

    case process_event_types.INVITATION_CREATED:
      if (data.host && data.host._id && data.host._id !== triggered_by_id) {
        return t(`${message}${third_party_postfix}`, {
          host: data.host.name,
          user: data.user.name,
          created_by: triggered_by,
        });
      }
      return t(message, { host: triggered_by, user: triggered_on });

    case process_event_types.INVITATION_UPDATED:
      if (data.host && data.host._id && data.host._id !== triggered_by_id) {
        return t(`${message}${third_party_postfix}`, {
          host: data.host.name,
          user: data.user.name,
          created_by: triggered_by,
        });
      }
      return t(message, { host: triggered_by, user: triggered_on });

    case process_event_types.INVITATION_DELETED:
      if (data.host && data.host._id && data.host._id !== triggered_by_id) {
        return t(`${message}${third_party_postfix}`, {
          host: data.host.name,
          user: data.user.name,
          created_by: triggered_by,
        });
      }
      return t(message, { host: triggered_by, user: triggered_on });

    case process_event_types.ACCOUNT_ACTIVATION:
      return t(message, { user: triggered_by });

    case process_event_types.FORGOT_PASSWORD:
      return t(message, { user: triggered_by });

    case process_event_types.PASSWORD_RESET:
      return t(message, { user: triggered_by });

    case process_event_types.SENT_DOCUMENT:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.SENT_SIGNED_DOCUMENT:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.SENT_QUESTIONNAIRE:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.MEETING_CREATED:
      return t(message, { host: triggered_by, name: data.name });

    case process_event_types.MEETING_UPDATED:
      return t(message, { host: triggered_by, name: data.name });

    case process_event_types.MEETING_DELETED:
      return t(message, { host: triggered_by, name: data.name });

    case process_event_types.MEETING_CREATED_ATTENDEE:
      return t(message, {
        host: triggered_by,
        user: triggered_on,
        name: data.name,
      });

    case process_event_types.MEETING_UPDATED_ATTENDEE:
      return t(message, {
        host: triggered_by,
        user: triggered_on,
        name: data.name,
      });

    case process_event_types.MEETING_DELETED_ATTENDEE:
      return t(message, {
        host: triggered_by,
        user: triggered_on,
        name: data.name,
      });

    case process_event_types.ARRIVAL:
      return t(message, { user: triggered_on });

    case process_event_types.DEPARTURE:
      return t(message, { user: triggered_on });

    case process_event_types.DOCUMENT_READ:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.DOCUMENT_SIGNED:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.QUESTIONNAIRE_FILLED:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.USER_STATUS_CHANGED:
      const oldState = data.old || {};
      const newState = data.new || {};
      return t(message, {
        triggered_by,
        triggered_on,
        oldStatus: t(`status_${oldState.status}`),
        newStatus: t(`status_${newState.status}`),
      });

    case process_event_types.FEEDBACK_MADE_AVAILABLE:
      return t(message, { user: triggered_by, document: data.data.name });

    case process_event_types.SECURITY_ITEM_GENERATED:
      return t(message, {
        triggered_by,
        triggered_on,
        validFrom: moment(data.validFrom).format('l HH:mm'),
        validTo: moment(data.validTo).format('l HH:mm'),
      });

    case process_event_types.INSPECTION_ACTIVITY_CHECKED:
      return t(message, { triggered_by, triggered_on, activity: data.type });

    case process_event_types.VISIT_ACCOMPANIED:
      return t(message, { triggered_by, triggered_on });

    case process_event_types.EMERGENCY:
      return t(message, { triggered_by, triggered_on });
    default:
      return t('users_events_unknown');
  }
};

const UserEventsTab = ({ classes, id }) => {
  const [t] = useTranslation();
  const fetchService = useCallback(
    (currentPage, pageSize) => {
      return ProcessEventService.getEvents(id, currentPage, pageSize);
    },
    [id]
  );

  const {
    data: events,
    hasMore,
    fetchMore,
    resetLoader,
  } = useInfiniteLoading(fetchService, id);

  //Initial fetch
  useEffect(() => {
    resetLoader();
  }, [resetLoader]);

  return (
    <Grid id="user-events-scroll" className={classes.container} container>
      <InfiniteScroll
        className={classes.scroll}
        dataLength={events.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={<LinearProgress className={classes.loading} />}
        scrollableTarget="user-events-scroll"
      >
        {events.map((event, i) => {
          return (
            <Fragment key={i}>
              <Typography className={classes.dateLabel} noWrap>
                {moment(event.created_date).format('l HH:mm')}
              </Typography>
              <Typography className={classes.eventLabel}>
                {getTypeMessage(t, event)}
              </Typography>
            </Fragment>
          );
        })}
      </InfiniteScroll>
    </Grid>
  );
};

export default memo(withStyles(styles)(UserEventsTab));
