import PropTypes from 'prop-types';
import { withStyles, Grid, Button } from '@material-ui/core';

import ChevronRight from '@material-ui/icons/ChevronRight';
import commonStyles from 'assets/jss/commonStyles';
import classNames from 'classnames';
import { itinerary_type_icon } from 'AppSettings';

const styles = (theme) => ({
  ...commonStyles(theme),
  itemContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.palette.primary3,
    padding: theme.spacing(1),
    textTransform: 'none',
    userSelect: 'none',
    textAlign: 'left',
    lineHeight: '1em',
  },
  itemIcon: {
    color: theme.palette.primary4,
  },
  chevron: {
    color: theme.palette.primary3,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    //color from commonStyles.heading
  },
  subHeading: {
    color: theme.palette.primary8,
    fontSize: 10,
    fontWeight: 500,
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  centerSelf: {
    alignSelf: 'center',
  },
  itemMargin: {
    marginLeft: theme.spacing(1),
  },
  itemButton: {
    width: '100%',
    padding: 0,
    marginBottom: theme.spacing(1),
  },
});

const ItineraryItem = (props) => {
  const { classes, onClick, item } = props;
  const { type } = item;
  var { titleFrom, subTitleFrom, titleTo, subTitleTo } = item;
  if (!titleFrom) titleFrom = <br />; // keep empty row
  if (!titleTo) titleTo = <br />;
  var Icon = itinerary_type_icon(type);
  return (
    <div>
      <Button className={classes.itemButton} onClick={onClick}>
        <Grid
          container
          direction="row"
          className={classes.itemContainer}
          justifyContent="space-between"
        >
          <Grid item style={{ width: `calc(100% - 24px)` }}>
            <Grid container direction="row">
              <Grid item className={classes.centerSelf}>
                <Icon className={classes.itemIcon} />
              </Grid>
              <Grid
                item
                className={classes.itemMargin}
                style={{ width: `calc(100% - 32px)` }}
              >
                <Grid container>
                  <Grid container direction="row" justifyContent="space-evenly">
                    <Grid item>
                      <Grid container direction="column">
                        <Grid
                          item
                          className={classNames(
                            classes.heading,
                            classes.title,
                            classes.centerSelf
                          )}
                        >
                          {titleFrom}
                        </Grid>
                        <Grid
                          item
                          className={classNames(
                            classes.subHeading,
                            classes.centerSelf
                          )}
                        >
                          {subTitleFrom}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid
                          item
                          className={classNames(
                            classes.heading,
                            classes.title,
                            classes.centerSelf
                          )}
                        >
                          {titleTo}
                        </Grid>
                        <Grid
                          item
                          className={classNames(
                            classes.subHeading,
                            classes.centerSelf
                          )}
                        >
                          {subTitleTo}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.centerSelf}>
            <ChevronRight className={classes.chevron} />
          </Grid>
        </Grid>
      </Button>
    </div>
  );
};

ItineraryItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(ItineraryItem);
