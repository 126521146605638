import { useCallback, useEffect, useReducer, useRef } from 'react';
import { handleOpenConfirmDialog } from '../dialogs/ConfirmDialog';
import { createTableHeaderState } from '../material-ui/VmsTableHeader';

const tableHeaderStateReducer = (state, action) => {
  switch (action.type) {
    case 'DATA':
      return { ...state, data: action.payload, selected: [] };
    case 'NEW':
      return { ...state, ...action.payload };
    case 'SELECTED':
      return { ...state, selected: action.payload };
    case 'PAGE':
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

const useTableHeader = (
  data,
  deleteService,
  onDeleteSuccess,
  onDeleteFailure,
  removeDialogTitle,
  removeDialogText
) => {
  const [tableHeaderState, dispatchTableHeaderState] = useReducer(
    tableHeaderStateReducer,
    createTableHeaderState([])
  );
  const tableHeader = useRef(null);
  const { selected } = tableHeaderState;

  const setTableHeaderPage = useCallback((page) => {
    dispatchTableHeaderState({ type: 'PAGE', payload: page });
  }, []);

  const setTableHeaderRef = useCallback((ref) => {
    tableHeader.current = ref;
  }, []);

  const handleChangeTableHeader = useCallback((tableHeaderState) => {
    dispatchTableHeaderState({ type: 'NEW', payload: tableHeaderState });
  }, []);

  const handleRemoveSelectedItems = useCallback(
    () => async (event) => {
      const deleteArray = selected.map((item) => deleteService(item._id));

      try {
        await Promise.all(deleteArray);
        dispatchTableHeaderState({ type: 'SELECTED', payload: [] });
        onDeleteSuccess(selected);
      } catch (err) {
        onDeleteFailure(err);
      }
    },
    [selected, onDeleteSuccess, onDeleteFailure, deleteService]
  );

  const handleRemoveSelectedItemsOpenDialog = useCallback(() => {
    handleOpenConfirmDialog(
      removeDialogTitle,
      removeDialogText,
      handleRemoveSelectedItems()
    );
  }, [handleRemoveSelectedItems, removeDialogTitle, removeDialogText]);

  const handleClickRow = useCallback(
    (item) => () => {
      tableHeader.current.handleClickRow(item);
    },
    [tableHeader]
  );

  useEffect(() => {
    dispatchTableHeaderState({ type: 'DATA', payload: data });
  }, [data]);

  return {
    tableHeaderState,
    setTableHeaderPage,
    setTableHeaderRef,
    handleChangeTableHeader,
    handleRemoveSelectedItemsOpenDialog,
    handleClickRow,
  };
};

export default useTableHeader;
