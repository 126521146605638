import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import Send from '@material-ui/icons/Send';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import DoneAll from '@material-ui/icons/DoneAll';
import { VmsDialog, VmsInput } from 'components';
import i18n from 'assets/i18n';
import commonStyles from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
});

const FeedbackDialog = (props) => {
  const {
    classes,
    open,
    totalQuestions,
    totalAnsweredQuestions,
    sendAnswers,
    onClose,
  } = props;
  const onChangeInput = () => (event) => {};
  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={i18n.t('document_feedback_summarize')}
      dialogHeaderIcon={<PlaylistAdd />}
      closeButtonDataCy="feedback-button-close"
      buttonText={i18n.t('send')}
      buttonIcon={<Send />}
      buttonAction={sendAnswers}
      buttonDisabled={false}
      buttonDataCy="feedback-button-send"
      onClose={onClose}
    >
      <Grid
        container
        className={classes.rowContent2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<QuestionAnswer />}
          textValue={
            i18n.t('document_questionnaire_questions') + ' ' + totalQuestions
          }
          inputName="question_count"
          onChange={onChangeInput}
        />
      </Grid>
      <Grid
        container
        className={classes.rowContent2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<DoneAll />}
          textValue={
            i18n.t('document_questionnaire_answers') +
            ' ' +
            totalAnsweredQuestions
          }
          inputName="answers_count"
          onChange={onChangeInput}
        />
      </Grid>
    </VmsDialog>
  );
};
FeedbackDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  totalAnsweredQuestions: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  sendAnswers: PropTypes.func.isRequired,
};

export default withStyles(styles)(FeedbackDialog);
