import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentService } from 'services';
import { useImmer } from 'use-immer';
import { openSnackbar } from '../bars';

const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target.result;
      resolve(url);
    };
    reader.onerror = (e) => {
      reader.abort();
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};

const emptyImageState = () => ({
  files: [],
  uploading: false,
  fileStatus: '',
  base64: '',
  editExisting: false,
});

const useImageUpload = (
  name,
  type,
  description,
  publicDoc,
  required_for_entrance,
  expires_in
) => {
  const [t] = useTranslation();
  const [imageState, setImageState] = useImmer(emptyImageState);

  const { files } = imageState;

  const upload = async () => {
    if (files.length > 0) {
      const file = files[0];
      setImageState((draft) => {
        draft.uploading = true;
      });
      try {
        const response = await DocumentService.uploadDocument(
          name,
          type,
          description,
          publicDoc,
          file,
          required_for_entrance,
          expires_in
        );
        //const response = await DocumentService.uploadDocument(item.name, 'explore', '', true, file);
        if (response._id) {
          return response;
        } else {
          //Highly unlikely this will ever happen
          console.log('Missing _id from document response');
          openSnackbar(t('error_boundary_generic'));
        }
      } catch (err) {
        console.log(err);
        openSnackbar(err.message);
      } finally {
        setImageState((draft) => {
          draft.uploading = false;
        });
      }
    }
    return null;
  };

  const onNew = useCallback(() => {
    setImageState(emptyImageState());
  }, [setImageState]);

  const onEdit = useCallback(() => {
    setImageState({ ...emptyImageState(), editExisting: true });
  }, [setImageState]);

  const onDrop = useCallback(
    async (files) => {
      setImageState((draft) => {
        draft.files = files;
      });
      try {
        const file = files[0];
        const base64 = await getBase64FromFile(files[0]);
        if (file) {
          setImageState((draft) => {
            draft.base64 = base64;
            draft.editExisting = false;
          });
        }
      } catch (err) {
        console.log(err);
        openSnackbar(err.message);
      }
    },
    [setImageState]
  );

  const onDropAccepted = useCallback(() => {
    setImageState((draft) => {
      draft.fileStatus = 'accepted';
    });
  }, [setImageState]);

  const onDropRejected = useCallback(() => {
    setImageState((draft) => {
      draft.fileStatus = 'rejected';
    });
  }, [setImageState]);

  return {
    imageState,
    upload,
    onNew,
    onEdit,
    onDrop,
    onDropAccepted,
    onDropRejected,
  };
};

export default useImageUpload;
