import {
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';

const useStyles = makeStyles((theme) => ({
  menuColor: {
    fontSize: 14,
    color: theme.palette.primary6,
  },
  singleNestedMenuItem: {
    paddingLeft: 56,
  },
  icon: {
    color: theme.palette.primary6,
  },
}));

const UserFilterMenu = ({
  anchor,
  rolesFilter,
  statusFilter,
  onClose,
  onRoleClick,
  onStatusClick,
  onRoleAllClick,
  stateOfFilters,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Menu
      id="user-filter-menu"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
    >
      <MenuItem onClick={onRoleAllClick}>
        {stateOfFilters && (
          <ListItemIcon className={classes.icon}>
            <DoneAllOutlinedIcon />
          </ListItemIcon>
        )}
        <ListItemText
          className={!stateOfFilters ? classes.singleNestedMenuItem : undefined}
          classes={{ primary: classes.menuColor }}
          primary={t(!stateOfFilters ? `select_all` : `deselect_all`)}
        />
      </MenuItem>
      <Divider />
      {Object.keys(rolesFilter).map((role) => {
        const checked = rolesFilter[role];
        return (
          <MenuItem key={role} onClick={onRoleClick(role)}>
            {checked && (
              <ListItemIcon className={classes.icon}>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText
              className={!checked ? classes.singleNestedMenuItem : undefined}
              classes={{ primary: classes.menuColor }}
              primary={t(`role_${role}`)}
            />
          </MenuItem>
        );
      })}
      <Divider />
      {Object.keys(statusFilter).map((status) => {
        const checked = statusFilter[status];
        return (
          <MenuItem key={status} onClick={onStatusClick(status)}>
            {checked && (
              <ListItemIcon className={classes.icon}>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText
              className={!checked ? classes.singleNestedMenuItem : undefined}
              classes={{ primary: classes.menuColor }}
              primary={t(`status_${status}`)}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

UserFilterMenu.propTypes = {
  anchor: PropTypes.object,
  rolesFilter: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onRoleClick: PropTypes.func.isRequired,
  onRoleAllClick: PropTypes.func.isRequired,
  stateOfFilters: PropTypes.bool.isRequired,
};

export default memo(UserFilterMenu);
