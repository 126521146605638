import { Avatar, makeStyles, Typography } from '@material-ui/core';
import {
  Create,
  Close,
  Contacts,
  Update,
  NotInterested,
  Error as ErrorIcon,
  FormatListNumbered,
  People,
  Check,
} from '@material-ui/icons';
import { VmsDialog } from 'components';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  root: {
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  statistics: {
    display: 'grid',
    gridTemplateColumns: '24px auto auto',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  statisticIcon: {
    color: theme.palette.action.active,
  },
  statisticValue: {
    justifySelf: 'flex-end',
  },
  errors: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(7),
  },
  error: { display: 'flex', gap: theme.spacing(2) },
  errorNumber: { width: 24, height: 24, fontSize: '0.8rem' },
}));

const statisticsIcons = {
  total: People,
  created: Create,
  updated: Update,
  deactivated: NotInterested,
  unchanged: Check,
  failed: ErrorIcon,
};

const ImportResultsDialog = ({ open, onClose, statistics }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const { errors, ...restStatistics } = statistics || {};

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={t('import_users_result_dialog_title')}
      onClose={onClose}
      dialogHeaderIcon={<Contacts />}
      buttonIcon={<Close />}
      closeButtonDataCy="users-import-result-button-close"
      buttonAction={onClose}
      buttonText={t('ok')}
      buttonDataCy="users-import-result-button-ok"
    >
      <div className={classes.root}>
        <div className={classes.statistics}>
          {Object.entries(restStatistics).map(([stat, value]) => {
            const Icon = statisticsIcons[stat];
            return (
              <Fragment key={stat}>
                <Icon className={classes.statisticIcon} />
                <Typography>
                  {t(`import_users_result_dialog_${stat}`)}
                </Typography>
                <Typography className={classes.statisticValue}>
                  {value}
                </Typography>
              </Fragment>
            );
          })}
          {!!errors?.length && (
            <>
              <FormatListNumbered className={classes.statisticIcon} />
              <Typography>{t('import_users_result_dialog_errors')}</Typography>
            </>
          )}
        </div>
        <div className={classes.errors}>
          {errors?.map((error, index) => (
            <div key={index} className={classes.error}>
              <Avatar className={classes.errorNumber}>{index + 1}</Avatar>
              <Typography>{error}</Typography>
            </div>
          ))}
        </div>
      </div>
    </VmsDialog>
  );
};

export default ImportResultsDialog;
