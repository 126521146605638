import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  UserDialog,
  PasswordDialog,
  PinDialog,
  TemplateEditor,
  CompanyDataContext,
} from 'components';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import tableStyles from 'assets/jss/tableStyles';
import {
  uploadPhotoBase64,
  uploadPhotoFile,
} from 'containers/VmsDialogDragDropPhoto';
import {
  user_status,
  user_roles,
  template_categories,
  template_types,
  user_document_status_constants,
  type_constants,
} from 'AppSettings';
import Auth from 'modules/Auth';
import util from 'util';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import UserDocumentModel from 'services/models/UserDocumentModel';
import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import {
  SmsService,
  UserService,
  DocumentService,
  TemplateService,
  EmailService,
} from 'services';
import { useCallback, useContext, useState } from 'react';

const styles = (theme) => ({
  ...tableStyles(theme),
});

const emptyData = (language) => {
  return {
    id: null,
    name: '',
    email: '',
    login: '',
    company: '',
    role: user_roles.VISITOR,
    status: user_status.PENDING,
    department: null,
    phone: '',
    pin: '',
    photo: null,
    photoUrl: null,
    lockUrl: '',
    agreeTerms: null,
    allowMeetingRoomBooking: null,
    tenantId: null,
    passwordNeverExpires: null,
    lastLogin: null,
    language: language ?? '',
    note: '',
    avatar: null,
    emailShared: false,
    emailSharedEnabled: true,
  };
};

const emptyPhoto = () => {
  return {
    base64: null,
    files: [],
    uploading: false,
    fileStatus: '',
  };
};

const emptyPassword = () => {
  return {
    oldPassword: '',
    newPassword: '',
    newPasswordVerify: '',
  };
};

const emptyPin = () => {
  return {
    oldPin: '',
    newPin: '',
    newPinVerify: '',
  };
};

const emptyEmail = () => {
  return {
    name: '',
    recipient: '',
    title: '',
    selectedValue: '',
    editor: TemplateService.createEmpty(),
    invitation: null,
    sendingEmail: false,
    emailTemplates: [],
    emailTemplatesItems: { key: [], value: [] },
    disabledLang: true,
  };
};

export const createEmptyUserEditorState = (language, departments) => {
  //If we are creating a new user, we need to store departments
  return {
    editData: emptyData(language),
    photo: emptyPhoto(),
    photoDialogOpen: false,
    cameraDialogOpen: false,
    passwordDialogOpen: false,
    passwordData: emptyPassword(),
    pinDialogOpen: false,
    pinData: emptyPin(),
    emailDialogOpen: false,
    emailData: emptyEmail(),
    departments: departments ?? { key: [], value: [] },
    personal_files: [],
    documentsToRemove: [],
    tabs: {
      value: 0,
    },
  };
};

export const createUserEditorState = (user, departments, files) => {
  //So the departments wont get reseted
  return {
    editData: {
      id: user._id,
      name: user.name || '',
      email: user.email || '',
      avatar: user.avatar || '',
      login: user.login || '',
      company: user.company || '',
      role: user.role || '',
      status: user.status || '',
      department: user.department || null,
      phone: user.phone || '',
      pin: user.pin || '',
      photo: user.photo,
      photoUrl: user.photo
        ? DocumentService.api + user.photo.downloadUrl
        : null,
      lockUrl: user.lockUrl,
      agreeTerms: user.agreeTerms || null,
      allowMeetingRoomBooking: user.allowMeetingRoomBooking || null,
      tenantId: user.tenantId || null,
      passwordNeverExpires: user.passwordNeverExpires || null,
      lastLogin: user.lastLogin || null,
      language: user.language || '',
      note: user.note || '',
      emailShared: user.emailShared || false,
      emailSharedEnabled: true,
    },
    photo: emptyPhoto(),
    photoDialogOpen: false,
    cameraDialogOpen: false,
    passwordDialogOpen: false,
    passwordData: emptyPassword(),
    pinDialogOpen: false,
    pinData: emptyPin(),
    emailDialogOpen: false,
    emailData: emptyEmail(),
    invitationEmailData: {},
    departments,
    personal_files: files || [],
    documentsToRemove: [],
    tabs: {
      value: 0,
    },
  };
};

const UserEditor = (props) => {
  const {
    onClose,
    onSave,
    userEditState,
    adminMode,
    visitorView,
    onChange: onChng,
  } = props;
  const { companyData } = useContext(CompanyDataContext);

  const [savingUser, setSavingUser] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const allLoadings = savingUser || loadingTemplates;

  // Prevent opening password dialog within dialog (affects mobile view)
  const editorOpen =
    props.open &&
    !userEditState.passwordDialogOpen &&
    !userEditState.pinDialogOpen;

  const authenticatedUser = Auth.getUser();
  const disableEdit = Boolean(
    (!Auth.isUserAdmin() &&
      authenticatedUser._id !== userEditState.editData.id) ||
      (userEditState.editData.tenantId &&
        userEditState.editData.tenantId !== authenticatedUser.tenantId)
  );

  const onChange = useCallback(() => {
    onChng(userEditState);
  }, [onChng, userEditState]);

  const sendCreateSms = async (userId) => {
    try {
      const smsTemplates = await Promise.all([
        TemplateService.getTemplatesByCategoryAndType(
          template_categories.SMS,
          template_types.USER_PROFILE_CREATED
        ),
        TemplateService.getTemplatesByCategoryAndType(
          template_categories.SMS,
          template_types.PIN
        ),
      ]);

      const user = await UserService.getUser(userId);
      for (let i = 0; i < smsTemplates.length; i++) {
        smsTemplates[i] = TemplateService.findNotificationTemplateByLanguage(
          smsTemplates[i],
          user.language,
          companyData.location.language
        );
      }

      if (smsTemplates[0]) {
        const template = TemplateService.replaceTemplate(smsTemplates[0], {
          user,
          url: TemplateService.callbackUrl,
        });
        await SmsService.send(user.phone, template.body);
      }
      if (smsTemplates[1]) {
        const template = TemplateService.replaceTemplate(smsTemplates[1], {
          user,
        });
        await SmsService.send(user.phone, template.body);
      }
    } catch (err) {
      console.log(err);
      //Dont show errors related to sms
    }
  };

  const handleEditSave = (sendEmail) => async (event) => {
    const { editData, personal_files, documentsToRemove } = userEditState;
    const { name } = editData;
    const data = {
      name: editData.name,
      email: editData.email,
      login: editData.login,
      company: editData.company,
      phone: editData.phone,
      role: editData.role,
      status: editData.status,
      department: editData.department,
      lockUrl: editData.lockUrl,
      agreeTerms: editData.agreeTerms,
      allowMeetingRoomBooking: editData.allowMeetingRoomBooking,
      passwordNeverExpires: editData.passwordNeverExpires,
      language: editData.language,
      note: editData.note,
      avatar: editData.avatar,
      emailShared: editData.emailShared,
    };

    setSavingUser(true);
    try {
      if (editData.id) {
        await UserService.updateUserWithData(editData.id, data);
        await Promise.all([
          handleSavePhoto(editData.id, name),
          addUserDocuments(editData.id, personal_files),
          removeUserDocuments(documentsToRemove),
        ]);
      } else {
        const user = await UserService.create(data);
        openSnackbar(i18n.t('snackbar_invite_createuser_success'));

        await Promise.all([
          handleSavePhoto(user._id, name),
          addUserDocuments(user._id, personal_files),
        ]);

        if (sendEmail) {
          userEditState.editData.id = user._id;
        }

        if (!editData.email) {
          sendCreateSms(user._id);
        }
      }
      openSnackbar(i18n.t('saved'));
      onSave();

      if (sendEmail) {
        handleEmailModalOpen();
      }
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setSavingUser(false);
    }
  };

  const handleDragDropChange = (value) => {
    userEditState.dragDropState = value;
    onChange();
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  const handleEditChangeData = useCallback(
    (name, value) => (event) => {
      const field = name !== undefined ? name : event.target.name;
      const fieldValue = value !== undefined ? value : event.target.value;
      userEditState.editData[field] = fieldValue;
      onChange();
    },
    [onChange, userEditState.editData]
  );

  /**
   * Change the password object.
   *
   * @param {object} event - the JavaScript event object
   */
  const handlePasswordChangeData = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    userEditState.passwordData[field] = fieldValue;
    onChange();
  };

  const handlePasswordDialog = (open) => (event) => {
    if (!disableEdit) {
      if (open) userEditState.passwordData = emptyPassword();
      userEditState.passwordDialogOpen = open;
      onChange();
    }
  };

  /**
   * Change the pin object.
   *
   * @param {object} event - the JavaScript event object
   */
  const handlePinChangeData = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    userEditState.pinData[field] = fieldValue;
    onChange();
  };

  const handlePinDialog = (open) => (event) => {
    if (open)
      userEditState.pinData = {
        pin: userEditState.editData.pin,
      };
    userEditState.pinDialogOpen = open;
    onChange();
  };

  // ############ PHOTO ############
  const handleSavePhoto = async (user_id, user_name) => {
    const {
      base64,
      editedBase64,
      files,
      photoEditor,
      new: newClicked,
    } = userEditState.dragDropState || {};
    if (newClicked) {
      await UserService.updateUserPhoto(user_id, null);
    } else if (editedBase64 && photoEditor) {
      let eb64 = photoEditor
        .getImageScaledToCanvas()
        .toDataURL('image/jpeg', 1.0);
      await uploadPhotoBase64(user_id, user_name, eb64, updateUploadingState);
    } else if (base64) {
      await uploadPhotoBase64(user_id, user_name, base64, updateUploadingState);
    } else if (files && files.length > 0) {
      await uploadPhotoFile(user_id, user_name, files, updateUploadingState);
    }
  };

  const updateUploadingState = (uploading) => {
    userEditState.dragDropState.uploading = uploading;
    onChange();
  };

  // ########### USER FILES ###########
  const addUserDocuments = (user_id, documents) => {
    const addDocumentPromises = [];
    for (let i = 0; i < documents.length; i++) {
      const d = documents[i];
      if (!d._id) {
        addDocumentPromises.push(
          UserService.addDocument(user_id, d.type, d.status, d.data)
        );
      } else {
        addDocumentPromises.push(
          UserService.updateDocument(user_id, d._id, d.type, d.status, d.data)
        );
      }
    }
    return Promise.all(addDocumentPromises);
  };

  const removeUserDocuments = (documents) => {
    const removeDocumentPromises = [];
    for (let i = 0; i < documents.length; i++) {
      removeDocumentPromises.push(UserService.removeDocument(documents[i]));
    }
    return Promise.all(removeDocumentPromises);
  };

  // ########### SEND ACTIVATION ###########
  const handleSendActivation = () => {
    loadEmailTemplates();
  };

  const handleSendEmail = () => {
    const { recipient, title, editor } = userEditState.emailData;
    const templateHtml = TemplateService.draftToHtml(
      editor.getCurrentContent()
    );
    userEditState.emailData.sendingEmail = true;
    onChange();
    EmailService.send(recipient, title, templateHtml)
      .then(function () {
        openSnackbar(i18n.t('template_email_sent'));
        handleEmailModalClose();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        userEditState.emailData.sendingEmai = false;
        onChange();
      });
  };

  const loadEmailTemplates = async () => {
    setLoadingTemplates(true);
    try {
      // Load invitation email templates
      let templateType =
        userEditState.editData.role === user_roles.VISITOR
          ? template_types.EMAIL_ACTIVATE
          : template_types.USER_PROFILE_CREATED;
      let templates = await TemplateService.getTemplatesByCategoryAndType(
        template_categories.EMAIL,
        templateType
      );
      let emailTemplates = { key: [], value: [] };
      for (var i = 0; i < templates.length; i++) {
        emailTemplates.key[i] = i;
        emailTemplates.value[i] = templates[i].name;
      }
      userEditState['emailData'].emailTemplates = templates;
      userEditState['emailData'].emailTemplatesItems = emailTemplates;
      onChange();
      handleEditSave(true)();
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const handleEmailChangeData = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'selectedValue') {
      // Prefill the email template data
      handleEmailTemplateChange(fieldValue);
    } else {
      userEditState.emailData[field] = fieldValue;
      onChange();
    }
  };

  const handleEmailTextEditorChange = (value) => {
    userEditState.emailData['editor'] = value;
    onChange();
  };

  const emailTemplateData = (data) => {
    return {
      user: {
        name: data.name,
        email: data.email,
        company: data.company,
        activationUrl: data.activationUrl,
        activationUrlPlain: data.activationUrlPlain,
        login: data.login,
      },
      company: companyData,
    };
  };

  const handleEmailTemplateChange = (index) => {
    const emailTemplates = userEditState.emailData.emailTemplates;
    let template = emailTemplates[index];
    let data = emailTemplateData(userEditState.editData); // Fill email template data and then replace placeholders
    template = TemplateService.replaceTemplate(template, data);
    userEditState.emailData.name = template.name;
    userEditState.emailData.lang = template.lang;
    userEditState.emailData.recipient = userEditState.editData.email;
    userEditState.emailData.title = template.title;
    userEditState.emailData.selectedValue = index;
    userEditState.emailData.editor = TemplateService.createEditorFromHtml(
      template.body,
      'html'
    );
    onChange();
  };

  const handleEmailModalOpen = () => {
    const emailTemplates = userEditState.emailData.emailTemplates;
    if (emailTemplates.length > 0) {
      const template = TemplateService.findNotificationTemplateByLanguage(
        emailTemplates,
        userEditState.editData.language,
        companyData.location.language
      );
      handleEmailTemplateChange(emailTemplates.indexOf(template));
    }
    userEditState.emailDialogOpen = true;
    onChange();
    const user_id = userEditState.editData.id;
    UserService.getUserActivationToken(user_id)
      .then(function (activationToken) {
        let activationUrl = UserService.getUserActivationUrl(
          user_id,
          activationToken.activation_token
        );
        let activationUrlPlain = activationUrl;
        activationUrl = util.format(
          '<a href="%s">%s</a>',
          activationUrl,
          activationUrl
        );
        if (emailTemplates.length > 0) {
          userEditState.editData.activationUrl = activationUrl;
          userEditState.editData.activationUrlPlain = activationUrlPlain;
          handleEmailTemplateChange(userEditState.emailData.selectedValue);
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  const handleEmailModalClose = () => {
    userEditState.emailDialogOpen = false;
    userEditState.emailData = emptyEmail();
    userEditState.invitationEmailData = {};
    onChange();
  };

  // ########### CHANGE PIN ################
  const handleChangePin = async () => {
    const {
      editData: { id },
      pinData: { oldPin, newPin },
    } = userEditState;
    try {
      if (adminMode) {
        await UserService.changePinAsAdmin(id, newPin);
      } else {
        await UserService.changePin(id, oldPin, newPin);
      }
      userEditState.editData.pin = newPin;
      openSnackbar(i18n.t('saved'));
      handlePinDialog(false)();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  // ########### CHANGE PASSWORD ###########

  const handleChangePassword = () => {
    if (adminMode) {
      // In admin mode we change password as admin
      UserService.changePasswordAsAdmin(
        userEditState.editData.id,
        userEditState.passwordData.newPassword
      )
        .then(function (response) {
          console.log(response);
          openSnackbar(i18n.t('saved'));
          handlePasswordDialog(false)();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      // In profile mode we also compare the old password and change the token
      UserService.changePassword(
        userEditState.editData.id,
        userEditState.passwordData.oldPassword,
        userEditState.passwordData.newPassword
      )
        .then(function (response) {
          console.log(response);
          Auth.authenticateUser(response.token, response.refresh_token);
          openSnackbar(i18n.t('saved'));
          handlePasswordDialog(false)();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  const handleChangeTabs = (event, value) => {
    props.userEditState.tabs = { value };
    onChange();
  };

  const handleChangeTabsIndex = (index) => {
    props.userEditState.tabs = { value: index };
    onChange();
  };

  const handleChangeFileUserData = (name, value, index) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    let fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'documents_remove') {
      handleOpenConfirmDialog(
        i18n.t('visits_file_dialog_remove_title'),
        i18n.t('visits_file_dialog_remove_description'),
        () => handleRemoveFile(index, value)
      );
    } else if (field === 'rename') {
      userEditState.personal_files[index].data.customName = event.target.value;
    } else {
      console.log(field, fieldValue);
      userEditState[field] = fieldValue;
    }

    onChange();
  };

  const handleRemoveFile = (index, value) => {
    userEditState.personal_files.splice(index, 1);
    if (userEditState.dragDropFileState) {
      userEditState.dragDropFileState.files.splice(0, 1);
    }
    if (value) {
      userEditState.documentsToRemove.push(value);
    }

    onChange();
  };

  const handleDragDropFile = (value) => {
    userEditState.dragDropFileState = value;
    if (value?.files != null && value.files.length > 0) {
      value.files.forEach((doc) => {
        let data = {
          name: doc.name,
          document_id: doc._id,
          data: doc,
          customName: '',
        };
        if (
          userEditState.personal_files.filter(
            (e) => !e.data.id && e.data.name === data.name
          ).length < 1
        ) {
          userEditState.personal_files.push(
            new UserDocumentModel(
              null,
              null,
              type_constants.PERSONAL_DOCUMENT,
              user_document_status_constants.READ,
              data
            )
          );
        }
      });
    }
    onChange();
  };

  return (
    <div>
      <UserDialog
        open={editorOpen}
        loading={allLoadings}
        handleChangeTabs={handleChangeTabs}
        userEditState={userEditState}
        handleChangeTabsIndex={handleChangeTabsIndex}
        onClose={onClose}
        onChange={handleEditChangeData}
        onSave={handleEditSave()}
        onSend={handleSendActivation}
        item={userEditState.editData}
        adminMode={adminMode}
        dragDropState={userEditState.dragDropState}
        onDragDropChange={handleDragDropChange}
        onChangePassword={handlePasswordDialog(true)}
        onChangePin={handlePinDialog(true)}
        xsPaperMode={props.xsPaperMode}
        disableEdit={disableEdit}
        departments={userEditState.departments}
        visitorView={visitorView}
        handleChangeFileUserData={handleChangeFileUserData}
        handleDragDrop={handleDragDropChange}
        handleDragDropFile={handleDragDropFile}
      />
      <PasswordDialog
        open={userEditState.passwordDialogOpen}
        dialogState={userEditState.passwordData}
        onChange={handlePasswordChangeData}
        onPasswordChange={handleChangePassword}
        adminMode={adminMode}
        onClose={handlePasswordDialog(false)}
        xsPaperMode={props.xsPaperMode}
      />
      <PinDialog
        open={userEditState.pinDialogOpen}
        dialogState={userEditState.pinData}
        onChange={handlePinChangeData}
        onPinChange={handleChangePin}
        adminMode={adminMode}
        onClose={handlePinDialog(false)}
        xsPaperMode={props.xsPaperMode}
      />
      <TemplateEditor
        open={userEditState.emailDialogOpen}
        loading={allLoadings}
        onClose={handleEmailModalClose}
        category={template_categories.EMAIL}
        type={template_types.EMAIL_ACTIVATE}
        editMode={false}
        templateState={userEditState.emailData}
        languages={true}
        onChange={handleEmailChangeData}
        selectItems={userEditState.emailData.emailTemplatesItems}
        onTextEditorChange={handleEmailTextEditorChange}
        handleSendTemplate={handleSendEmail}
      />
    </div>
  );
};

UserEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  userEditState: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  adminMode: PropTypes.bool, // In admin mode we allow to change role and status
  xsPaperMode: PropTypes.bool, // This is special mode that paper instead of dialog is used on some pages where its better
  visitorView: PropTypes.bool,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(UserEditor);
