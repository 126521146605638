import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/LockOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { VmsInput } from 'components';
import VmsDialog from './VmsDialog';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { validatePassword } from 'AppSettings';
import PasswordHelp from '../popups/PasswordHelp';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  passwordDialogContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

const passwordDialog = (props) => {
  const {
    classes,
    xsPaperMode,
    open,
    onChange,
    onClose,
    onPasswordChange,
    adminMode,
  } = props;
  let { dialogState } = props;
  const passwordValid = validatePassword(dialogState.newPassword);
  if (!dialogState) {
    dialogState = {};
  }
  const saveDisabled =
    (!dialogState.oldPassword && !adminMode) ||
    !dialogState.newPassword ||
    !dialogState.newPasswordVerify ||
    dialogState.newPassword !== dialogState.newPasswordVerify ||
    validatePassword(dialogState.newPassword);
  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={i18n.t('password_dialog_title')}
      onClose={onClose}
      dialogHeaderIcon={<PersonOutline />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onPasswordChange}
      buttonDisabled={saveDisabled}
      xsPaperMode={xsPaperMode}
    >
      {!adminMode && (
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<LockOutlined />}
          textValue={dialogState.oldPassword}
          placeholder={i18n.t('password_dialog_old_password')}
          inputLabel={i18n.t('password_dialog_old_password')}
          onChange={onChange('oldPassword')}
          inputType={'password'}
          inputName={'oldPassword'}
        />
      )}
      <VmsInput
        rowClass={adminMode ? classes.dialogRow : classes.dialogRow2}
        iconLeft={<LockOutlined />}
        textValue={dialogState.newPassword}
        placeholder={i18n.t('password_dialog_new_password')}
        inputLabel={i18n.t('password_dialog_new_password')}
        onChange={onChange('newPassword')}
        inputType={'password'}
        inputName={'newPassword'}
        inputError={dialogState.newPassword && passwordValid ? true : false}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<LockOutlined />}
        textValue={dialogState.newPasswordVerify}
        placeholder={i18n.t('password_dialog_new_password_verify')}
        inputLabel={i18n.t('password_dialog_new_password_verify')}
        onChange={onChange('newPasswordVerify')}
        inputType={'password'}
        inputName={'newPasswordVerify'}
        inputError={
          dialogState.newPassword !== dialogState.newPasswordVerify
            ? true
            : false
        }
      />
      {dialogState.newPassword && passwordValid && (
        <div className={classes.passwordDialogContainer}>
          <PasswordHelp />
        </div>
      )}
    </VmsDialog>
  );
};

passwordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  adminMode: PropTypes.bool,
  dialogState: PropTypes.shape({
    oldPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    newPasswordVerify: PropTypes.string.isRequired,
    status: PropTypes.string,
  }).isRequired,
  xsPaperMode: PropTypes.bool,
};

export default withStyles(styles)(passwordDialog);
