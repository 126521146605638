import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Modal,
  Paper,
  Collapse,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {
  FolderOpen,
  ExpandLess,
  ExpandMore,
  PermDeviceInformation,
  PermIdentity,
  FormatAlignJustify,
  List,
  AccessTime,
} from '@material-ui/icons';
import { VmsDialogRowLabel, VmsDialogHeader } from '../../common';
import modalStyle from '../../../assets/jss/modalStyle';
import commonStyle from '../../../assets/jss/commonStyles';
import { elevation } from '../../../assets/UISettings';
import Aux from 'hoc/Auxiliary';
import i18n from 'assets/i18n';
import moment from 'moment/moment';

const styles = (theme) => ({
  ...modalStyle(theme, 800),
  ...commonStyle(theme),
});

class PeripheralStatusDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectsOpened: [],
    };
  }

  setObjectOpenState = (index) => {
    const { objectsOpened } = this.state;
    if (objectsOpened[index] === undefined) {
      objectsOpened[index] = true;
    } else {
      objectsOpened[index] = !objectsOpened[index];
    }
    this.setState({ objectsOpened: objectsOpened });
  };

  render() {
    const { classes, onClose, open, item } = this.props;
    const message = item
      ? item.envelope
        ? item.envelope.message
        : undefined
      : undefined;
    const deviceName = item ? item.deviceName : '';
    const { objectsOpened } = this.state;

    return (
      <Modal
        className={classes.modal}
        aria-labelledby="Peripheral status dialog"
        aria-describedby="Dialog showing peripheral status info"
        open={open}
        onClose={onClose}
      >
        <Paper className={classes.paper} elevation={elevation.light}>
          <VmsDialogHeader
            icon={<PermDeviceInformation />}
            title={deviceName}
            onClose={onClose}
          />
          <VmsDialogRowLabel
            rowClass={classes.dialogRowSmall}
            iconLeft={<AccessTime />}
            textValue={
              item.lastActivity
                ? i18n.t('last_activity') +
                  ' ' +
                  moment(item.lastActivity).format('YYYY-MM-DD HH:mm:ss')
                : null
            }
          />
          {message ? (
            <Aux>
              <VmsDialogRowLabel
                rowClass={classes.dialogRowSmall}
                iconLeft={<PermIdentity />}
                textValue={message.id}
              />
              <VmsDialogRowLabel
                rowClass={classes.dialogRowSmall}
                iconLeft={<FolderOpen />}
                textValue={message.source}
              />
              <VmsDialogRowLabel
                rowClass={classes.dialogRowSmall}
                iconLeft={<FormatAlignJustify />}
                textValue={message.format}
              />
              <Aux>
                {message.objects &&
                  message.objects.map((object, index) => {
                    const opened = objectsOpened[index]
                      ? objectsOpened[index]
                      : false;
                    return (
                      <Aux key={index}>
                        <ListItem
                          button
                          onClick={() => this.setObjectOpenState(index)}
                        >
                          <ListItemText
                            primary={
                              i18n.t('settings_peripherals_message') +
                              (index + 1)
                            }
                          />
                          {opened ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={opened}>
                          {object &&
                            Object.keys(object).map((key, index) => {
                              return (
                                <VmsDialogRowLabel
                                  key={index}
                                  rowClass={classes.dialogRowSmall}
                                  iconLeft={<List />}
                                  textValue={key + ': ' + object[key]}
                                />
                              );
                            })}
                        </Collapse>
                      </Aux>
                    );
                  })}
              </Aux>
            </Aux>
          ) : (
            <VmsDialogRowLabel
              rowClass={classes.dialogRowSmall}
              iconLeft={<PermIdentity />}
              textValue={i18n.t('settings_peripherals_no_messages')}
            />
          )}
        </Paper>
      </Modal>
    );
  }
}

PeripheralStatusDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdDate: PropTypes.string,
    deliveryDate: PropTypes.string,
    deviceId: PropTypes.string,
    deviceName: PropTypes.string,
    status: PropTypes.string,
    envelope: PropTypes.shape({
      date: PropTypes.string,
      ttl: PropTypes.number,
      version: PropTypes.number,
      message: PropTypes.shape({
        format: PropTypes.string,
        id: PropTypes.string,
        source: PropTypes.string,
        objects: PropTypes.array,
      }),
    }),
  }),
};

export default withStyles(styles)(PeripheralStatusDialog);
