import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Parser from 'html-react-parser';
import TemplateService from '../../../services/TemplateService';
import { VmsButtonFAB } from '../../common/';
import commonStyles from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';
import { elevation } from '../../../assets/UISettings';
import Auth from '../../../modules/Auth';

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    height: '100vh',
    width: 'calc(100% - ' + theme.spacing(4) + 'px)',
  },
  document: {
    width: 'calc(100% - ' + theme.spacing(6) + 'px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(),
    minHeight: 'calc(100vh - 140px - ' + theme.spacing(3) + 'px)',
  },
});

class DocumentSignedPreview extends Component {
  state = {
    content: '',
  };

  componentDidMount() {
    const currentUser = Auth.getUser();
    let template = this.props.documentData;
    if (currentUser) {
      template = TemplateService.replaceTemplate(
        template,
        this.props.templateData
      );
    }
    this.setState({
      content: template.body,
    });
    console.log('content', this.state.content);
  }

  render() {
    const { classes, onRead } = this.props;
    const { content } = this.state;

    return (
      <Aux classname={classes.root}>
        <div className={classes.contentOfVmsInfoBar}>
          <Paper className={classes.document} elevation={elevation.light}>
            {Parser(content)}
          </Paper>
        </div>
        <VmsButtonFAB data-cy="signed-button-read" onClick={onRead}>
          <Check />
        </VmsButtonFAB>
      </Aux>
    );
  }
}

DocumentSignedPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  documentData: PropTypes.shape({
    body: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onRead: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DocumentSignedPreview);
