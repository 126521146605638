import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Auth from 'modules/Auth';
import { FilterIconButton, TemplateEditor } from 'components/index';
import i18n from 'assets/i18n';
import {
  TemplateService,
  EmailService,
  ExportService,
  SmsService,
  PushNotificationService,
  ConfigurationService,
} from 'services/index';
import { openSnackbar } from 'components/common/bars/SnackBar';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import {
  template_category_types,
  template_categories,
  routes,
  supported_languages,
  filter_types,
  type_constants,
} from 'AppSettings';
import classNames from 'classnames';
import tableStyles from 'assets/jss/tableStyles';
import { emptySettingSearchState } from '../SettingsPage';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { handleOpenConfirmDialog } from '../../../components/common/dialogs/ConfirmDialog';
import TemplatesFilterMenu from './TemplatesFilterMenu';
import produce from 'immer';
import locales from 'assets/momentlocales';

const styles = (theme) => ({
  ...tableStyles(theme),
});

/**
 * Universal page that can be used to show and add templates for different template categories.
 *
 * @param {string} category Type of templates shown on this page
 */
class TemplatesPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      user: Auth.getUser(),
      value: 0,
      template: {
        lang: '',
        name: '',
        recipient: '',
        title: '',
        selectedValue: '',
        editor: TemplateService.createEmpty(),
        recipientObject: null, // this contains selected user in case that template editor is used for push notification template
        templateType: null,
        required_for_entrance: false,
        expires_in: '',
      },
      templates: [],
      filteredTemplates: [],
      typeFilter: this.setupTypeFilter(),
      languageFilter: this.setupLanguageFilter(),
      templateModalOpen: false,
      templateOid: null,
      currentItem: {},
      menuAnchorEl: null,
      tableHeaderState: createTableHeaderState([]),
      highlightedId: '',
      filterMenuAnchor: null,
      notificationCategoryTypes: { key: [], value: [] },
      signedDocumentTypes: { key: [], value: [] },
      templateChanged: false,
    };

    this.tableHeader = null;

    this.referrer = ((this.props.location || {}).state || {}).referrer || {};
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadTemplates();
    this.setSearchSettings();
    this.loadNotificationCategoryTypes();
    this.loadSignedDocumentTypes();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (
      pathname === routes.SETTINGS_EMAIL ||
      pathname === routes.SETTINGS_PUSH ||
      pathname === routes.SETTINGS_SMS ||
      pathname === routes.SETTINGS_SITE_TEMPLATES ||
      pathname === routes.SETTINGS_SIGNED ||
      pathname === routes.SETTINGS_ASSISTANT
    ) {
      this.handleNewClick();
    }
  };

  loadNotificationCategoryTypes() {
    const { category } = this.props;

    // Notification category types that are shown in template type dropdown
    const notificationCategoryTypes = { key: [], value: [] };
    const configuredTypes = template_category_types[category];
    if (configuredTypes) {
      const keys = Object.keys(configuredTypes);
      for (var i = 0; i < keys.length; i++) {
        notificationCategoryTypes.key[i] = keys[i];
        notificationCategoryTypes.value[i] = configuredTypes[keys[i]];
      }
    }
    this.setState({ notificationCategoryTypes });
  }

  async loadSignedDocumentTypes() {
    try {
      const response = await ConfigurationService.getTypesForConfiguration(
        type_constants.SIGNED_DOCUMENT
      );
      const signedDocumentTypes = { key: [], value: [] };
      for (let i = 0; i < response.length; i++) {
        signedDocumentTypes.key[i] = response[i].key;
        signedDocumentTypes.value[i] = response[i].key;
      }
      console.log('signedDocumentTypes', signedDocumentTypes);
      this.setState({ signedDocumentTypes });
    } catch (err) {
      console.log(err);
    }
  }

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      switch (this.props.category) {
        case template_categories.PUSH:
          settingSearchState.suggestionService =
            TemplateService.suggestPUSHTemplates;
          break;
        case template_categories.EMAIL:
          settingSearchState.suggestionService =
            TemplateService.suggestEMAILTemplates;
          break;
        case template_categories.TEMPLATE:
          settingSearchState.suggestionService =
            TemplateService.suggestTEMPLATETemplates;
          break;
        case template_categories.SIGNED:
          settingSearchState.suggestionService =
            TemplateService.suggestSIGNEDTemplates;
          break;
        case template_categories.SMS:
          settingSearchState.suggestionService =
            TemplateService.suggestSMSTemplates;
          break;
        case template_categories.ASSISTANT:
          settingSearchState.suggestionService =
            TemplateService.suggestAssistantTemplates;
          break;
        default:
          break;
      }
      TemplateService.getTemplatesByCategory(this.props.category);
      settingSearchState.onSuggestionsClearRequested =
        this.handleTemplateSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleTemplateSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleTemplateSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.name;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return this.filterTemplates(response);
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleTemplateSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleTemplateSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestTemplate(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.name;
      onChangeSearch(settingSearchState);
    }
  };

  handleTemplateSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestTemplate = (_id) => {
    const { filteredTemplates, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = filteredTemplates.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  handleTemplateModalClose = () => {
    this.setState({ templateModalOpen: false });
    this.loadTemplates();
  };

  //TODO Mutating state, this should not be done
  onTextEditorChange = (value) => {
    const template = this.state.template;

    //Check whether or not the text in the template changed
    const editorHtml = TemplateService.draftToHtml(value.getCurrentContent());
    if (template.editorHtml !== editorHtml) {
      this.setState({
        templateChanged: true,
      });
    }

    template['editor'] = value;
    template['editorHTML'] = editorHtml;
    this.setState({
      template,
    });
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  handleChangeData = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    console.log('field', field, fieldValue);
    const template = this.state.template;
    template[field] = fieldValue;
    this.setState({
      template: template,
      templateChanged: true,
    });
  };

  handleNewClick = () => {
    this.setState({
      template: {
        name: '',
        lang: 'en',
        recipient: '',
        title: '',
        selectedValue: '',
        editor: TemplateService.createEmpty(),
        recipientObject: null,
        required_for_entrance: false,
        expires_in: '',
        templateType: null,
      },
      templateOid: null,
      templateModalOpen: true,
      menuAnchorEl: null,
      templateChanged: true,
    });
  };

  handleEditTemplate = (template) => (event) => {
    const editor = TemplateService.createEditorFromHtml(template.body);
    const editorHtml = TemplateService.draftToHtml(editor.getCurrentContent());
    this.setState({
      template: {
        name: template.name,
        lang: template.lang,
        recipient: '',
        title: template.title,
        selectedValue: template.type,
        editor,
        editorHtml,
        required_for_entrance: template.required_for_entrance,
        expires_in: template.expires_in,
        templateType: template.templateType,
      },
      templateOid: template._id,
      templateModalOpen: true,
      menuAnchorEl: null,
      templateChanged: false,
    });
  };

  handleSaveTemplate = () => {
    const self = this;
    const {
      templateOid,
      notificationCategoryTypes,
      template: {
        name,
        lang,
        title,
        editor,
        required_for_entrance,
        expires_in,
        templateType,
      },
      templateChanged,
    } = this.state;

    if (!templateChanged) {
      openSnackbar(i18n.t('snackbar_template_save_success'));
      this.handleTemplateModalClose();
      return;
    }

    let selectedValue = this.state.template.selectedValue;
    const { category, bus } = this.props;
    const templateContent =
      category === template_categories.PUSH
        ? editor.getCurrentContent().getPlainText() // store plain text for Push Notifications
        : TemplateService.draftToHtml(editor.getCurrentContent());

    // in case of signed document, set template type manualy as there is only one type
    if (
      category === template_categories.SIGNED &&
      notificationCategoryTypes.key.length > 0
    ) {
      selectedValue = notificationCategoryTypes.key[0];
    }

    if (templateOid) {
      TemplateService.update(
        templateOid,
        name,
        lang,
        category,
        selectedValue,
        title,
        templateContent,
        false,
        required_for_entrance,
        expires_in,
        templateType
      ) // TODO: Implement is_default
        .then(function () {
          openSnackbar(i18n.t('snackbar_template_save_success'));
          self.handleTemplateModalClose();

          //If assistant was changed, reload page
          if (category === template_categories.ASSISTANT) {
            bus.emit('reloadApp', true);
          }
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      TemplateService.create(
        name,
        lang,
        category,
        selectedValue,
        title,
        templateContent,
        false,
        required_for_entrance,
        expires_in,
        templateType
      )
        .then(function () {
          openSnackbar(i18n.t('snackbar_template_save_success'));
          self.handleTemplateModalClose();

          if (category === template_categories.ASSISTANT) {
            bus.emit('reloadApp', true);
          }
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleDeleteTemplateOpenDialog = (templateId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_template_dialog_remove_title'),
      i18n.t('settings_template_dialog_remove_description'),
      this.handleDeleteTemplate(templateId)
    );
  };

  handleDeleteTemplate = (templateId) => (event) => {
    var self = this;
    TemplateService.deleteTemplate(templateId)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.handleMenuClose();
        self.loadTemplates();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  // sent email, push or SMS notification
  handleSendTemplate = () => {
    const { recipient, title, editor, recipientObject } = this.state.template;
    const success = () => {
      openSnackbar(i18n.t(`template_${this.props.category}_sent`));
    };
    const failure = (error) => {
      console.log(error);
      openSnackbar(error.message);
    };

    switch (this.props.category) {
      case template_categories.EMAIL:
        const htmlBody = TemplateService.draftToHtml(
          editor.getCurrentContent()
        );
        EmailService.send(recipient, title, htmlBody)
          .then(success)
          .catch(failure);
        break;
      case template_categories.PUSH:
        const plainBody = editor.getCurrentContent().getPlainText();
        PushNotificationService.send([recipientObject._id], title, plainBody)
          .then(success)
          .catch(failure);
        break;
      case template_categories.SMS:
        const smsText = editor.getCurrentContent().getPlainText();
        SmsService.send(recipient.replace(/\s/g, ''), smsText)
          .then(success)
          .catch(failure);
        break;
      default:
        break;
    }
  };

  loadTemplates = () => {
    TemplateService.getTemplatesByCategory(this.props.category)
      .then((templates) => {
        this.setState(function (prevState) {
          prevState.templates = templates;
          prevState.filteredTemplates = templates;
          prevState.tableHeaderState.data = templates;
          return prevState;
        }, this.filterTemplatesAndSave);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItemsDialogOpen = () => {
    handleOpenConfirmDialog(
      i18n.t('settings_template_dialog_remove_selected_title'),
      i18n.t('settings_template_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => async (event) => {
    const {
      tableHeaderState: { selected },
    } = this.state;

    const promises = selected.map((template) =>
      TemplateService.deleteTemplate(template._id)
    );
    try {
      await Promise.all(promises);
      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.tableHeaderState.selected = [];
        })
      );
      openSnackbar(i18n.t('removed'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      this.loadTemplates();
    }
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  // Functions required by autosuggest in template editor
  /* handleUserSuggestionsFetchRequested = ({ value }) => {
   console.log('handleFetchRequest', value)
   this.getUserSuggestions(value);
   };

   // debounced function so it only gets suggestions every 500ms
   getUserSuggestions = debounce((value) => {
   var self = this;
   UserService.getUserSuggestions(value).then(function (response) {
   self.setState({
   userSuggestions: response
   });
   }).catch(function (error) {
   console.log(error);
   openSnackbar(error.message);
   });
   }, 500);*/

  handleUserSuggestionsClearRequested = () => (event) => {
    /*this.setState({
     userSuggestions: []
     });*/
  };

  handleUserSuggestionSelected = (suggestion) => (event) => {
    const template = this.state.template;
    template['recipient'] = suggestion.name;
    template['recipientObject'] = suggestion;
    this.setState({
      template,
    });
  };

  setupTypeFilter = () => {
    const { category } = this.props;

    const configuredTypes = template_category_types[category];
    const typeFilter = {};
    if (configuredTypes) {
      for (const key of Object.keys(configuredTypes)) {
        typeFilter[key] = true;
      }
    }

    return typeFilter;
  };

  setupLanguageFilter = () => {
    const languageFilter = {};
    for (const lang of supported_languages) {
      languageFilter[lang] = true;
    }

    return languageFilter;
  };

  handleOpenFilter = (event) => {
    const target = event.currentTarget;
    this.setState({ filterMenuAnchor: target });
  };

  handleCloseFilter = () => {
    this.setState({ filterMenuAnchor: null });
  };

  handleFilterCategoryClick = (key) => (event) => {
    this.setState((prevState) => {
      const typeFilter = { ...prevState.typeFilter };
      typeFilter[key] = !typeFilter[key];
      return { typeFilter };
    }, this.filterTemplatesAndSave);
  };

  handleFilterAllClick = (filterType) => () => {
    this.setState((prevState) => {
      const filter = { ...prevState[filterType] };

      let statusOfFilters = false;
      switch (filterType) {
        case filter_types.TYPE:
          statusOfFilters = this.allTypeFilterChecked();
          break;
        case filter_types.LANGUAGE:
          statusOfFilters = this.allLanguageFilterChecked();
          break;
        default:
          break;
      }
      if (!statusOfFilters) {
        for (let id in filter) {
          if (filter.hasOwnProperty(id)) {
            filter[id] = true;
          }
        }
      } else {
        for (let id in filter) {
          if (filter.hasOwnProperty(id)) {
            filter[id] = false;
          }
        }
      }
      return { [filterType]: filter };
    }, this.filterTemplatesAndSave);
  };

  handleFilterLanguageClick = (language) => (event) => {
    this.setState((prevState) => {
      const languageFilter = { ...prevState.languageFilter };
      languageFilter[language] = !languageFilter[language];
      return { languageFilter };
    }, this.filterTemplatesAndSave);
  };

  filterTemplates = (templates) => {
    const { typeFilter, languageFilter } = this.state;

    const filteredTemplates = templates.filter((template) => {
      //languageFilter[template.lang] === undefined because old templates dont have language set
      return (
        typeFilter[template.type] &&
        (languageFilter[template.lang] ||
          languageFilter[template.lang] === undefined)
      );
    });

    return filteredTemplates;
  };

  filterTemplatesAndSave = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        const filteredTemplates = this.filterTemplates(draft.templates);
        draft.filteredTemplates = filteredTemplates;
        draft.tableHeaderState.data = filteredTemplates;

        const maxPages = Math.max(
          Math.ceil(
            filteredTemplates.length / draft.tableHeaderState.rowsPerPage
          ) - 1,
          0
        );
        if (draft.tableHeaderState.page > maxPages) {
          draft.tableHeaderState.page = maxPages;
        }
      })
    );
  };

  allTypeFilterChecked = () => {
    const filter = { ...this.state['typeFilter'] };

    for (let status in filter) {
      if (filter.hasOwnProperty(status)) {
        if (!filter[status]) {
          return false;
        }
      }
    }

    return true;
  };

  allLanguageFilterChecked = () => {
    const filter = { ...this.state['languageFilter'] };

    for (let status in filter) {
      if (filter.hasOwnProperty(status)) {
        if (!filter[status]) {
          return false;
        }
      }
    }

    return true;
  };

  render() {
    const { classes, category, prevPageButtonDataCy, nextPageButtonDataCy } =
      this.props;
    const {
      template,
      filteredTemplates,
      menuAnchorEl,
      currentItem,
      tableHeaderState,
      highlightedId,
      notificationCategoryTypes,
      filterMenuAnchor,
      typeFilter,
      languageFilter,
    } = this.state;
    const { rowsPerPage, page, selected } = tableHeaderState;
    const signedDocumentsLayout = category === template_categories.SIGNED;

    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsDialogOpen}
                exportData={ExportService.templates(selected)}
                exportDataFilename={`${category}_templates.csv`}
                additionalButton={
                  category !== template_categories.SIGNED ? (
                    <FilterIconButton onClick={this.handleOpenFilter} />
                  ) : undefined
                }
                prevPageButtonDataCy={prevPageButtonDataCy}
                nextPageButtonDataCy={nextPageButtonDataCy}
              />
              <TableBody>
                {filteredTemplates
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((template) => {
                    const isSelected = this.isSelected(template);
                    return (
                      <TableRow
                        key={template._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === template._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onClick={this.handleClickRow(template)}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                          onClick={this.handleEditTemplate(template)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {template.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                          onClick={this.handleEditTemplate(template)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {template.lang &&
                              i18n.t('menu_language_' + template.lang) +
                                ` (${locales[template.lang]})`}
                          </Typography>
                        </TableCell>
                        {!signedDocumentsLayout && (
                          <TableCell
                            scope="row"
                            className={classNames(
                              classes.tableCell,
                              classes.textAlignRight
                            )}
                            padding="none"
                            onClick={this.handleEditTemplate(template)}
                          >
                            <Typography
                              variant="body2"
                              className={classNames(
                                classes.header,
                                classes.tableInfoFont
                              )}
                              noWrap
                            >
                              {i18n.t(
                                template_category_types[template.category][
                                  template.type
                                ]
                              )}
                            </Typography>
                          </TableCell>
                        )}
                        {signedDocumentsLayout && (
                          <TableCell
                            scope="row"
                            className={classNames(
                              classes.tableCell,
                              classes.textAlignRight
                            )}
                            padding="none"
                            onClick={this.handleEditTemplate(template)}
                          >
                            <Typography
                              variant="body2"
                              className={classNames(
                                classes.header,
                                classes.tableInfoFont
                              )}
                              noWrap
                            >
                              {template.templateType}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none"
                        >
                          <IconButton
                            className={classes.tableMenuButton}
                            aria-owns={menuAnchorEl ? 'table-menus' : null}
                            aria-haspopup="true"
                            onClick={(event) =>
                              this.handleMenuOpen(event, template)
                            }
                          >
                            <MoreHoriz className={classes.tableIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleEditTemplate(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          <MenuItem
            onClick={this.handleDeleteTemplateOpenDialog(currentItem._id)}
          >
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <TemplateEditor
          open={this.state.templateModalOpen}
          onClose={this.handleTemplateModalClose}
          category={category}
          editMode={
            !(
              (category === template_categories.ASSISTANT &&
                !Auth.isUserGlobalAdmin()) ||
              (category === template_categories.TEMPLATE &&
                !Auth.isUserGlobalAdmin())
            )
          }
          templateState={template}
          languages={true}
          onChange={this.handleChangeData}
          selectItems={notificationCategoryTypes}
          onTextEditorChange={this.onTextEditorChange}
          handleSendTemplate={this.handleSendTemplate}
          handleSaveTemplate={this.handleSaveTemplate}
          onSuggestionsClearRequested={this.handleUserSuggestionsClearRequested}
          onSuggestionSelected={this.handleUserSuggestionSelected}
          signedDocumentTypes={this.state.signedDocumentTypes}
        />
        {category !== template_categories.SIGNED && (
          <TemplatesFilterMenu
            selectedCategory={template_category_types[category]}
            typeFilter={typeFilter}
            languageFilter={languageFilter}
            filterMenuAnchor={filterMenuAnchor}
            handleCloseFilter={this.handleCloseFilter}
            handleFilterCategoryClick={this.handleFilterCategoryClick}
            handleFilterLanguageClick={this.handleFilterLanguageClick}
            handleFilterAllClick={this.handleFilterAllClick}
            stateOfTypeFilters={this.allTypeFilterChecked()}
            stateOfLanguageFilters={this.allLanguageFilterChecked()}
          />
        )}
      </div>
    );
  }
}

TemplatesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired, // type of template that are shown on this page
  prevPageButtonDataCy: PropTypes.string,
  nextPageButtonDataCy: PropTypes.string,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(TemplatesPage);
