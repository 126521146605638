import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';

const styles = (theme) => {
  return {
    helperTextRoot: {
      alignSelf: 'flex-end',
    },
  };
};

const VmsTextField = (props) => {
  const { classes, className, maxLength, rows, ...other } = props;
  const multiLine = rows ? rows > 1 : false;
  const usedChars = props.value ? props.value.length : 0;
  return (
    <TextField
      {...other}
      className={className}
      InputProps={{
        inputProps: {
          maxLength: maxLength,
        },
        rows: rows,
        multiline: multiLine,
      }}
      helperText={maxLength ? `${usedChars} / ${maxLength}` : null}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
    />
  );
};

VmsTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
};

export default withStyles(styles)(VmsTextField);
