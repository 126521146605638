import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { VmsAutocomplete, RequiredIcon } from 'components';
import commonStyle from 'assets/jss/commonStyles';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const VmsDialogRowAutocomplete = (props) => {
  const {
    placeholder,
    iconLeft,
    onChange,
    classes,
    rowClass,
    inputName,
    inputValue,
    disabled,
    suggestionItemValue,
    inputError,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    onClick,
    suggestionService,
    filterSuggestionsResponse,
    required,
    itemDataCy,
    disablePop,
  } = props;
  const [t] = useTranslation();

  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.icon}>
          <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
        </div>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <VmsAutocomplete
          showAllSuggestions={props.showAllSuggestions}
          inputName={inputName}
          disabled={disabled}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          inputValue={inputValue}
          placeholder={
            required ? `${placeholder} (${t('required')})` : placeholder
          }
          onClick={onClick}
          onChange={onChange}
          suggestionService={suggestionService}
          filterSuggestionsResponse={filterSuggestionsResponse}
          suggestionItemValue={suggestionItemValue}
          inputError={inputError}
          itemDataCy={itemDataCy}
          disablePop={disablePop}
        />
      </Grid>
    </Grid>
  );
};

VmsDialogRowAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  rowClass: PropTypes.string,
  disabled: PropTypes.bool,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  inputValue: PropTypes.string,
  inputName: PropTypes.string,
  suggestionService: PropTypes.func.isRequired,
  filterSuggestionsResponse: PropTypes.func.isRequired,
  suggestionItemValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inputError: PropTypes.bool,
  itemDataCy: PropTypes.string,
  disablePop: PropTypes.bool,
  showAllSuggestions: PropTypes.bool,
};

export default withStyles(styles)(VmsDialogRowAutocomplete);
