import { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import ReactStars from 'react-stars';

const styles = (theme) => ({
  starsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stars: {
    display: 'inline-block',
    margin: '0 ' + 0.2 + 'em',
    '& span': {
      fontSize: '0.875rem !important',
      lineHeight: '1 !important',
    },
  },
  starsText: {
    display: 'inline',
  },
});

const ExploreStarContainer = ({ classes, rating, reviews }) => {
  return (
    <div className={classes.starsContainer}>
      <Typography
        className={classes.starsText}
        variant="body1"
        color="textPrimary"
        component="p"
      >
        {rating}
      </Typography>
      <ReactStars
        className={classes.stars}
        count={5}
        value={rating}
        edit={false}
      />
      <Typography
        className={classes.starsText}
        variant="body1"
        color="textSecondary"
        component="p"
      >
        ({reviews})
      </Typography>
    </div>
  );
};

ExploreStarContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  rating: PropTypes.number.isRequired,
  reviews: PropTypes.number.isRequired,
};

export default memo(withStyles(styles)(ExploreStarContainer));
