import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  MenuItem,
  Grid,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import { RequiredIcon } from 'components';
import i18n from '../../../assets/i18n';
import commonStyles from '../../../assets/jss/commonStyles';
import { language_mapping } from 'AppSettings';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';

const styles = (theme) => ({
  ...commonStyles(theme),
  select: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  flag: {
    width: 30,
    height: 19,
    marginRight: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
});

class VmsLocalizationList extends Component {
  render() {
    const {
      classes,
      lang,
      onChange,
      justifyContent,
      iconLeft,
      rowClass,
      disabled,
      required,
      selectDataCy,
      itemDataCy,
    } = this.props;

    return (
      <Grid
        container
        className={rowClass}
        direction="row"
        justifyContent={justifyContent}
        alignItems="center"
      >
        {iconLeft && (
          <Grid item>
            <div
              className={classNames(
                classes.icon,
                required ? classes.required : undefined
              )}
            >
              <RequiredIcon>{iconLeft}</RequiredIcon>
            </div>
          </Grid>
        )}
        <Grid item className={classes.rowContent}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel
              htmlFor="lang"
              shrink={false}
              style={{
                transform: 'translate(0, 8px) scale(1)',
              }}
            >
              {lang ? null : i18n.t('preferred_language')}
            </InputLabel>
            <Select
              data-cy={selectDataCy}
              value={lang}
              onChange={onChange}
              displayEmpty
              name="lang"
              classes={{
                select: classes.select,
              }}
              disabled={disabled}
              style={{
                marginTop: 0,
              }}
              IconComponent={(prop) => (
                <ArrowDropDown
                  className={classNames(classes.iconSelect, prop.className)}
                />
              )}
            >
              {Object.keys(language_mapping).map((lang) => (
                <MenuItem data-cy={itemDataCy} key={lang} value={lang}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      color: 'rgba(110, 110, 110, 1.0)',
                    }}
                  >
                    <span>{i18n.t(`menu_language_${lang}`)}</span>
                    <img
                      className={classes.flag}
                      src={language_mapping[lang]}
                      alt={i18n.t(`menu_language_${lang}`)}
                    />
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

VmsLocalizationList.propTypes = {
  classes: PropTypes.object.isRequired,
  lang: PropTypes.string,
  onChange: PropTypes.func,
  iconLeft: PropTypes.object,
  justify: PropTypes.string,
  rowClass: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  selectDataCy: PropTypes.string,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsLocalizationList);
