import { ItineraryDetailDialog, openSnackbar } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { ItineraryService } from 'services';
import VisitItineraryCardDialog from './VisitItineraryCardDialog';
import ItineraryTypeDialog from './ItineraryTypeDialog';

const emptyItineraryItem = (category, itemType) => ({
  category: category ?? '',
  itemType: itemType ?? '',
  operator: '',
  number: '',
  place_departure: '',
  place_arrival: '',
  date_departure: null,
  date_arrival: null,
  status: '',
  airplane: '',
});

const ItineraryAssistantDialogs = ({ open, onClose, visit }) => {
  const [itinerary, setItinerary] = useState({});
  const [loadingItinerary, setLoadingItinerary] = useState(false);

  const [typeDialogOpen, setTypeDialogOpen] = useState(false);
  const [addType, setAddType] = useState(null);

  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [itineraryItem, setItineraryItem] = useState(emptyItineraryItem);

  const fetchData = useCallback(async () => {
    setLoadingItinerary(true);
    try {
      const it = await ItineraryService.getItineraryForVisit(visit._id);
      setItinerary(it);
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      setLoadingItinerary(false);
    }
  }, [visit._id]);

  const onTypeDialogOpen = (type) => {
    setAddType(type);
    setTypeDialogOpen(true);
  };

  const onTypeDialogClose = () => {
    //Not calling setAddType(null) to avoid tearing with emptying the rendered list
    setTypeDialogOpen(false);
  };

  const onDetailDialogOpen = (item) => {
    setItineraryItem(item);
    setDetailDialogOpen(true);
  };

  const onDetailDialogClose = () => {
    setItineraryItem(emptyItineraryItem());
    setDetailDialogOpen(false);
  };

  const onItemAdd = (category, itemType) => () => {
    onDetailDialogOpen(emptyItineraryItem(category, itemType));
    onTypeDialogClose();
  };

  const onItemEdit = (item) => {
    onDetailDialogOpen(item);
  };

  const onItemSave = () => {
    onDetailDialogClose();
    fetchData();
  };

  useEffect(() => {
    if (visit._id) {
      fetchData();
    }
  }, [visit._id, fetchData]);

  useEffect(() => {
    if (!open) {
      setItinerary({});
    }
  }, [open]);

  return (
    <>
      <VisitItineraryCardDialog
        open={open}
        loading={loadingItinerary}
        onClose={onClose}
        visit={visit}
        itinerary={itinerary}
        onAdd={onTypeDialogOpen}
        onEdit={onItemEdit}
        onDone={fetchData}
      />
      <ItineraryTypeDialog
        open={typeDialogOpen}
        onClose={onTypeDialogClose}
        onTypeAdd={onItemAdd}
        type={addType}
      />
      <ItineraryDetailDialog
        open={detailDialogOpen}
        visitId={visit._id}
        itinerary={itinerary}
        originalItem={itineraryItem}
        onClose={onDetailDialogClose}
        onSave={onItemSave}
      />
    </>
  );
};

export default ItineraryAssistantDialogs;
