import skTranslations from './sk.json';
import enTranslations from './en.json';
import deTranslations from './de.json';
import czTranslations from './cz.json';
import esTranslations from './es.json';
import frTranslations from './fr.json';
import huTranslations from './hu.json';
import zhTranslations from './zh.json';
import inTranslations from './in.json';
import itTranslations from './it.json';
import jaTranslations from './ja.json';
import krTranslations from './kr.json';
import myTranslations from './my.json';
import nlTranslations from './nl.json';
import phTranslations from './ph.json';
import plTranslations from './pl.json';
import ptTranslations from './pt.json';
import roTranslations from './ro.json';
import ruTranslations from './ru.json';
import thTranslations from './th.json';
import nlbeTranslations from './nlbe.json';

const translations = [
  ['sk', skTranslations],
  ['en', enTranslations],
  ['de', deTranslations],
  ['cz', czTranslations],
  ['es', esTranslations],
  ['fr', frTranslations],
  ['hu', huTranslations],
  ['zh', zhTranslations],
  ['in', inTranslations],
  ['it', itTranslations],
  ['ja', jaTranslations],
  ['kr', krTranslations],
  ['my', myTranslations],
  ['nl', nlTranslations],
  ['ph', phTranslations],
  ['pl', plTranslations],
  ['pt', ptTranslations],
  ['ro', roTranslations],
  ['ru', ruTranslations],
  ['th', thTranslations],
  ['nlbe', nlbeTranslations],
];
export default translations;
