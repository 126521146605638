import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepButton from '@material-ui/core/StepButton';
import Aux from 'hoc/Auxiliary';
import ItineraryItem from './ItineraryItem';
import i18n from '../../assets/i18n';
import moment from 'moment';
import { itinerary_categories } from '../../AppSettings';

const styles = (theme) => ({
  grid: {
    height: 142,
  },
  color: {
    color: theme.palette.primary4,
  },
  avatarSmall: {
    width: 41,
    height: 38,
    marginBottom: 20,
  },
  avatarLarge: {
    width: 110,
    height: 110,
  },
  centerText: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  stepper: {
    padding: 0,
  },
  button: {
    color: 'white',
  },
  heading: {
    color: theme.palette.primary4,
    fontSize: 12,
    fontWeight: 500,
  },
  subHeading: {
    color: theme.palette.primary8,
    fontSize: 10,
    fontWeight: 500,
  },
  stepButton: {
    textAlign: 'left',
  },
  actionsContainer: {
    marginBottom: theme.spacing(1),
  },
});

function getSteps() {
  return [
    i18n.t('itinerary_step_1'),
    i18n.t('itinerary_step_2'),
    i18n.t('itinerary_step_3'),
  ]; //, i18n.t('itinerary_step_4')];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return '';
    case 1:
      return '';
    case 2:
      return '';
    case 3:
      return `Please fill in documents in 'Documents' tab`;
    default:
      return '';
  }
}

class VisitItineraryStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.activeStep ? props.activeStep : 0,
    };
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleAdd = () => {
    switch (this.state.activeStep) {
      case 1:
        this.props.addClicked(
          itinerary_categories.TRANSPORT,
          this.props.itinerary,
          this.props.visit._id
        );
        break;
      case 2:
        this.props.addClicked(
          itinerary_categories.ACCOMODATION,
          this.props.itinerary,
          this.props.visit._id
        );
        break;
      default:
        break;
    }
  };

  handleUpdate = (item) => (event) => {
    this.props.updateClicked(item, this.props.itinerary, this.props.visit._id);
  };

  handleDone = () => {
    this.setState({
      activeStep: 0,
    });
    if (this.props.stepClicked) {
      this.props.stepClicked(0, this.props.visit._id);
    }

    switch (this.state.activeStep) {
      case 1:
        this.props.doneClicked(
          itinerary_categories.TRANSPORT,
          this.props.visit._id
        );
        break;
      case 2:
        this.props.doneClicked(
          itinerary_categories.ACCOMODATION,
          this.props.visit._id
        );
        break;
      default:
        break;
    }
  };

  handleStep = (index) => (event) => {
    this.setState({
      activeStep: index,
    });
    if (this.props.stepClicked) {
      this.props.stepClicked(index, this.props.visit._id);
    }
  };

  render() {
    const { classes, visit, itinerary } = this.props;
    const isOneDayVisit =
      moment(visit.timeFrom).format('L') === moment(visit.timeTo).format('L');
    const visitTimeTo = isOneDayVisit
      ? moment(visit.timeTo).format('HH:mm')
      : moment(visit.timeTo).format('dddd, L');
    const date = isOneDayVisit
      ? `${moment(visit.timeFrom).format('dddd, L HH:mm')} - ${visitTimeTo}`
      : `${moment(visit.timeFrom).format('dddd, L')} - ${visitTimeTo}`;
    const { activeStep } = this.state;
    const steps = getSteps();
    const itemDateFormat = i18n.t('itinerary_item_local_date');

    let itineraryItems = [[], [], [], []]; // [[dummy], [transport], [accomodation], [documents]]
    let itineraryStepCompleted = [true, false, false, false];
    let itineraryData = itinerary || {};
    if (itineraryData.transport) {
      (itineraryData.transport.items || []).sort((a, b) => {
        if (a.date_departure || b.date_departure) {
          return a.date_departure > b.date_departure ? 1 : -1;
        } else {
          return a._id.localeCompare(b._id);
        }
      });
      let key = 0;
      (itineraryData.transport.items || []).forEach((item) => {
        let departureDate = item.date_departure
          ? moment(item.date_departure).format(itemDateFormat)
          : '';
        let arrivalDate = item.date_arrival
          ? moment(item.date_arrival).format(itemDateFormat)
          : '';
        itineraryItems[1].push(
          <ItineraryItem
            key={`transport_${key++}`}
            item={{
              titleFrom: item.place_departure,
              titleTo: item.place_arrival,
              subTitleFrom: departureDate,
              subTitleTo: arrivalDate,
              type: item.itemType,
            }}
            onClick={this.handleUpdate(item)}
          />
        );
      });
      itineraryStepCompleted[1] = itineraryData.transport.completed === true;
    }
    if (itineraryData.accomodation) {
      (itineraryData.accomodation.items || []).sort((a, b) =>
        a.date_departure > b.date_departure ? 1 : -1
      );
      let key = 0;
      (itineraryData.accomodation.items || []).forEach((item) => {
        let departureDate = item.date_departure
          ? moment(item.date_departure).format(itemDateFormat)
          : '';
        let arrivalDate = item.date_arrival
          ? moment(item.date_arrival).format(itemDateFormat)
          : '';
        itineraryItems[2].push(
          <ItineraryItem
            key={`accomodation_${key++}`}
            item={{
              titleFrom: item.operator,
              titleTo: '',
              subTitleFrom: departureDate,
              subTitleTo: arrivalDate,
              type: item.itemType,
            }}
            onClick={this.handleUpdate(item)}
          />
        );
      });
      itineraryStepCompleted[2] = itineraryData.accomodation.completed === true;
    }

    return (
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        nonLinear
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              data-cy={`itinerary-button-step-${index + 1}`}
              className={classes.stepButton}
              onClick={this.handleStep(index)}
              completed={itineraryStepCompleted[index]}
              optional={
                index === 0 ? (
                  <Typography className={classes.subHeading}>{date}</Typography>
                ) : null
              }
            >
              <Typography className={classes.heading}>{label}</Typography>
            </StepButton>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {index > 0 && <Aux>{itineraryItems[index]}</Aux>}
              {activeStep > 0 && index > 0 && (
                <div className={classes.actionsContainer}>
                  <div>
                    {index < 3 && (
                      <Button
                        data-cy="itinerary-button-add"
                        variant="contained"
                        color="primary"
                        onClick={this.handleAdd}
                        className={classes.button}
                      >
                        {i18n.t('add')}
                      </Button>
                    )}
                    {!itineraryStepCompleted[index] && (
                      <Button
                        data-cy="itinerary-button-done"
                        variant="contained"
                        color="primary"
                        onClick={this.handleDone}
                        disabled={itineraryStepCompleted[index]}
                        className={classes.button}
                        style={{ marginLeft: 16 }}
                      >
                        {i18n.t('done')}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  }
}

VisitItineraryStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  addClicked: PropTypes.func.isRequired,
  updateClicked: PropTypes.func.isRequired,
  doneClicked: PropTypes.func.isRequired,
  stepClicked: PropTypes.func,
  itinerary: PropTypes.object.isRequired,
};

export default withStyles(styles)(VisitItineraryStepper);
