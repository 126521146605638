import AbstractService from './AbstractService';
import Auth from '../modules/Auth';
import { configuration_group } from '../AppSettings';

class ConfigurationServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'configurations';
  }

  /**
   * Create type configuration.
   *
   * @param {object} configuration ConfigurationModel containing configuration data
   */
  create(configuration) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, configuration.toJSON(), config);
  }

  createMixedType(configuration) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, configuration, config);
  }

  /**
   * Get list of all configurations.
   */
  getConfigurations() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  suggestConfigurations = (searchValue) => {
    var endpoint = 'configurations/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  /**
   * Get list of all configurations.
   */
  getConfigurationsForGroup(group, tenantId) {
    const endpoint =
      'configurations/group/' + group + (tenantId ? '/' + tenantId : '');
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  suggestConfigurationsForGroup = (searchValue, group) => {
    searchValue = searchValue.replaceAll('/', '%2F');
    const endpoint =
      'configurations/group/' + group + '/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  suggestLocationConfigurations = (searchValue) => {
    const endpoint =
      'configurations/group/' +
      configuration_group.LOCATION_SETTINGS +
      '/suggestions/' +
      searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  updateConfigurationForGroup(group, configuration) {
    const endpoint = 'configurations/group/' + group;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.put(endpoint, configuration, config);
  }

  getVisitorTypes = () => {
    const endpoint = 'configurations/visitor_types/';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  getTypesForConfiguration = (typeConstant) => {
    const endpoint = 'configurations/types/' + typeConstant;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };

  /**
   * Update configuration.
   *
   * @param {object} configuration ConfigurationModel containing updated configuration data
   */
  update(id, configuration) {
    const endpoint = 'configurations/' + id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.put(endpoint, configuration, config);
  }

  /**
   * Delete configuration with provided configuration ID.
   *
   * @param {string} configurationId ID of configuration that should be deleted
   */
  deleteConfiguration(configurationId) {
    var endpoint = 'configurations/' + configurationId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  getContactPeople() {
    var endpoint = 'help/contactPeople';
    var config = {};

    return this.get(endpoint, config);
  }

  getValueByKey(key) {
    var endpoint = 'configurations/key/' + key;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getUseBehaviourRulesConfig() {
    const endpoint = 'globalConfigurations/useBehaviour';
    return this.get(endpoint);
  }

  /**
   * Converts provided array of types to 'Key-Value object' required by VMSDialogRowSelect component.
   *
   * @param {object[]} types Array of ConfigurationModel objects representig types of specific configuration
   */
  convertTypesArrayToKeyValueObject(types) {
    let keyValueObject = { key: [], value: [] };
    types.forEach((type, idx) => {
      keyValueObject.key[idx] = type.key;
      keyValueObject.value[idx] = type.key;
    });
    return keyValueObject;
  }
}

const ConfigurationService = new ConfigurationServiceImpl();

export default ConfigurationService;
