class UserDocumentModel {
  constructor(
    _id,
    user_id,
    type,
    status,
    data,
    last_status_change,
    valid_until
  ) {
    this._id = _id;
    this.user = user_id;
    this.type = type;
    this.status = status;
    this.data = data;
    this.last_status_change = last_status_change;
    this.valid_until = valid_until;
  }

  /**
   * Creates JSON representation of QuestionModel object.
   *
   * @returns {string} JSON representation of QuestionModel object
   */
  toJSON() {
    const data = {
      user: this.user,
      type: this.type,
      status: this.status,
      data: this.data,
    };
    return JSON.stringify(data);
  }
}

UserDocumentModel.fromJson = function (json) {
  return new UserDocumentModel(
    json._id,
    json.user,
    json.type,
    json.status,
    json.data,
    json.last_status_change,
    json.valid_until
  );
};

export default UserDocumentModel;
