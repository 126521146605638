import { createElement, memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ExploreButton from './ExploreButton';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { getWeatherIcon } from './weatherUtil';
import { assistant_tabs } from 'AppSettings';

const styles = (theme) => ({
  grid: {
    padding: '0 ' + 2 * theme.spacing() + 'px',
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto',
    gridAutoFlow: 'column',
    gridRowGap: theme.spacing() + 'px',
    [theme.breakpoints.only('sm')]: {
      gridTemplateRows: 'auto 1fr',
      gridColumnGap: 6 * theme.spacing() + 'px',
    },
    [theme.breakpoints.up('md')]: {
      flex: 2,
    },
  },
  infoBox: {
    height: '100%',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  spaceBetweenColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  temperature: {
    fontWeight: 400,
  },
  icon: {
    float: 'left',
    fontSize: '3rem',
    paddingRight: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem',
    },
  },
  eventIcon: {
    [theme.breakpoints.only('sm')]: {
      float: 'right',
      paddingRight: 0,
      paddingLeft: theme.spacing(),
    },
  },
});

const ExploreGrid = ({
  classes,
  weather,
  handleChangeDashboardTabs,
  visitCount,
}) => {
  const [t] = useTranslation();

  const weatherObj = weather.weather || {};
  const list = weatherObj.list || [];
  const listItem = list[0] || {};
  const main = listItem.main || {};
  const weatherInfo = listItem.weather || [];
  const weatherInfoItem = weatherInfo[0] || {};
  const city = weatherObj.city || {};

  const visitMessage =
    visitCount === 0
      ? t('explore_meetings_none')
      : visitCount === 1
      ? t('explore_meetings_one')
      : t('explore_meetings_more', { count: visitCount });

  const onMeetingsClicked = (event) => {
    handleChangeDashboardTabs(assistant_tabs.user_visits);
  };

  return (
    <div className={classes.grid}>
      <div>
        <ExploreButton
          target="_blank"
          href={`https://openweathermap.org/city/${city.id}`}
          rel="noopener"
          buttonDataCy="explore-button-weather"
        >
          {t('explore_weather')}
        </ExploreButton>
      </div>
      <div className={classes.infoBox}>
        {createElement(getWeatherIcon(weatherInfoItem.icon), {
          className: classes.icon,
        })}
        <div className={classes.spaceBetweenRow}>
          <div className={classes.spaceBetweenColumn}>
            <Typography
              className={classes.temperature}
              variant="h6"
              component="p"
            >
              {weather.name}
            </Typography>
            <Typography
              className={classes.temperature}
              variant="h6"
              component="p"
            >
              {Math.round(main.temp)}°C
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              {t('explore_weather_' + weatherInfoItem.main)}
            </Typography>
            <div className={classes.spaceBetweenColumn}>
              <Typography variant="body2">
                {t('explore_weather_rain_chance')}:{' '}
                {Math.round(listItem.pop * 100)}%
              </Typography>
              <Typography variant="body2">
                {Math.round(main.temp_max)}°C/{Math.round(main.temp_min)}°C
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ExploreButton
          onClick={onMeetingsClicked}
          buttonDataCy="explore-button-meetings"
        >
          {t('explore_meetings')}
        </ExploreButton>
      </div>
      <div className={classes.infoBox}>
        <EventIcon className={classNames([classes.eventIcon, classes.icon])} />
        <div className={classes.spaceBetweenRow}>
          <Typography variant="body2">{t(visitMessage)}</Typography>
          <Typography variant="body2">{moment().format('dddd, L')}</Typography>
        </div>
      </div>
    </div>
  );
};

ExploreGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeDashboardTabs: PropTypes.func.isRequired,
  visitCount: PropTypes.number,
};

export default memo(withStyles(styles)(ExploreGrid));
