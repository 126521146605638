import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  PermDeviceInformation,
  PermIdentity,
  DevicesOther,
  CheckCircle,
} from '@material-ui/icons';
import modalStyle from '../../../assets/jss/modalStyle';
import commonStyle from '../../../assets/jss/commonStyles';
import { VmsInput, VmsDialog } from 'components';
import i18n from 'assets/i18n';

const styles = (theme) => ({
  ...modalStyle(theme, 800),
  ...commonStyle(theme),
});

const PeripheralCreateNewDialog = (props) => {
  const { classes, onSave, onClose, open, onChange, newDeviceInfo } = props;
  let disabled =
    newDeviceInfo.saveDisabled ||
    !newDeviceInfo.deviceName ||
    !newDeviceInfo.deviceId;

  return (
    <VmsDialog
      open={open}
      xsPaperMode={props.xsPaperMode}
      dialogHeaderTitle={i18n.t('settings_peripherals_new_peripheral_device')}
      onClose={onClose}
      dialogHeaderIcon={<PermDeviceInformation />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onSave}
      buttonDisabled={disabled}
    >
      <VmsInput
        rowClass={classes.dialogRow}
        iconLeft={<DevicesOther />}
        placeholder={i18n.t('settings_peripherals_name')}
        inputLabel={i18n.t('settings_peripherals_name')}
        onChange={onChange}
        inputName={'deviceName'}
        required
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<PermIdentity />}
        placeholder={i18n.t('settings_peripherals_id')}
        inputLabel={i18n.t('settings_peripherals_id')}
        onChange={onChange}
        inputName={'deviceId'}
        required
      />
    </VmsDialog>
  );
};

PeripheralCreateNewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  newDeviceInfo: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeripheralCreateNewDialog);
