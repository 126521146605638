import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import i18n from '../../../assets/i18n';
import CsvParse from '@vtex/react-csv-parse';
import { openSnackbar } from '../bars/SnackBar';

const styles = (theme) => ({});

class VmsImportData extends Component {
  constructor(props) {
    super(props);
    this.fileUploaderRef = createRef();
  }

  handleClick = (event) => {
    this.fileUploaderRef.current.click();
  };

  handleError = (error) => {
    openSnackbar(error.message);
  };

  onImportData = (data) => {
    this.fileUploaderRef.current.value = '';
    this.props.onImportData(data);
  };

  render() {
    return (
      <Tooltip title={i18n.t('import')}>
        <IconButton aria-label="Import" onClick={this.handleClick}>
          <CloudUpload />
          <CsvParse
            keys={this.props.importKeys}
            onDataUploaded={this.onImportData}
            onError={this.handleError}
            render={(onChange) => (
              <input
                type="file"
                ref={this.fileUploaderRef}
                onChange={onChange}
                style={{ display: 'none' }}
                accept="text/csv"
              />
            )}
          />
        </IconButton>
      </Tooltip>
    );
  }
}

VmsImportData.propTypes = {
  classes: PropTypes.object.isRequired,
  onImportData: PropTypes.func.isRequired,
  importKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(VmsImportData);
