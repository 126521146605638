import modalStyle from 'assets/jss/modalStyle';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  dialog: {
    '& p': {
      margin: 0,
    },
    maxHeight: '60vh',
  },
  dot: {
    height: '0.5em',
    width: '0.5em',
    backgroundColor: theme.palette.tabIndicator,
    borderRadius: '50%',
    display: 'inline-block',
  },
  selectedDot: {
    backgroundColor: theme.palette.primary.main,
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  dotContainerWrapper: {
    flexShrink: 0,
  },
  textContent: {
    width: '100%',
    '& .react-swipeable-view-container': {
      height: '100%',
      overflow: 'unset !important',
      '& > div': {
        padding: `0px ${theme.spacing(2)}px`,
        boxSizing: 'border-box',
      },
    },
    '& > div': {
      height: '100%',
    },
  },
  textContentWrapper: {
    overflow: 'hidden',
    display: 'flex',
  },
  dialogContent: {
    padding: 0,
  },
  paperScrollPaper: {
    padding: `${theme.spacing()}px 0px`,
  },
});

const AssistantDialog = ({
  classes,
  children,
  open,
  onClose,
  onTabChange,
  index,
}) => {
  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={onClose}
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <DialogContent
        className={classNames(
          classes.dialogContent,
          classes.textContentWrapper
        )}
      >
        <div className={classes.textContent}>
          <SwipeableViews
            containerStyle={{ borderStyle: 'none' }}
            enableMouseEvents
            index={index}
            onChangeIndex={onTabChange}
          >
            {children}
          </SwipeableViews>
        </div>
      </DialogContent>
      <DialogContent
        className={classNames(
          classes.dialogContent,
          classes.dotContainerWrapper
        )}
      >
        <div className={classes.dotContainer}>
          {children.map((child, i) => (
            <IconButton
              data-cy="assistant-button-page"
              key={i}
              onClick={() => onTabChange(i)}
            >
              <span
                className={classNames([
                  classes.dot,
                  i === index ? classes.selectedDot : null,
                ])}
              />
            </IconButton>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AssistantDialog);
