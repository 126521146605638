import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Modal,
  withStyles,
  IconButton,
  Grid,
  Paper,
  Button,
  Input,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Event from '@material-ui/icons/Event';
import Schedule from '@material-ui/icons/Schedule';
import Place from '@material-ui/icons/Place';
import Group from '@material-ui/icons/Group';
import EventNote from '@material-ui/icons/EventNote';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import {
  VmsAutocomplete,
  VmsDateTimePicker,
  VmsTextField,
  MeetingFormDetail,
} from '../../../components';
import i18n from '../../../assets/i18n';
import modalStyle from '../../../assets/jss/modalStyle';
import { elevation, colors } from '../../../assets/UISettings';
import { date_formats } from '../../../AppSettings';
import UserService from 'services/UserService';
import moment from 'moment-timezone';
import commonStyles from 'assets/jss/commonStyles';
import { VmsDialogRowSelect } from '../dialogs';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme, 400),
  container: {
    paddingBottom: 12,
  },
  leftIconContainer: {
    minWidth: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  modalHeader: {
    color: 'white',
    backgroundColor: colors.orangeYellow,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  modalRow: {
    minHeight: 48,
    paddingRight: 12,
    color: colors.warmGrey,
  },
  modalRowMultiLine: {
    paddingTop: 12,
  },
  modalButtonRow: {
    paddingTop: 12,
    paddingRight: 12,
    '& > :first-child': {
      paddingLeft: 0,
    },
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  modalButtonWrapper: {
    padding: '0 ' + theme.spacing() + 'px',
  },
  modalRowRightSide: {
    flex: 1,
  },
  multilineContent: {
    paddingTop: 3,
    wordBreak: 'break-word',
  },
  modalButton: {
    color: theme.palette.common.white,
  },
  detailDeleteButton: {
    margin: theme.spacing(2),
  },
  detailDeleteButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  removeButton: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  inputAdornment: {
    color: colors.warmGrey,
  },
  inputTextFieldHeader: {
    color: 'white',
    flexGrow: 1,
  },
  floatingButton: {
    color: 'white',
    position: 'absolute',
    zIndex: 5,
    right: 40,
    bottom: 40,
  },
  datePicker: {
    maxWidth: 160,
  },
  paddingRight: {
    paddingRight: theme.spacing() + 4,
  },
  posRelative: {
    position: 'relative',
  },
});

class MeetingForm extends Component {
  createDetailModal() {
    const {
      classes,
      onCancel,
      onEdit,
      onDelete,
      shouldOpen,
      meetingData,
      editable,
    } = this.props;

    return (
      <Modal
        className={classes.modal}
        open={shouldOpen}
        onClose={onCancel}
        disableAutoFocus
      >
        <Paper
          className={classNames(classes.paper, classes.posRelative)}
          elevation={elevation.light}
        >
          <MeetingFormDetail
            meetingData={meetingData}
            showHeader={true}
            onCancel={onCancel}
            onEdit={onEdit}
            editable={editable}
            classes={classes}
            onDelete={onDelete}
          />
        </Paper>
      </Modal>
    );
  }

  createEditModal() {
    const {
      classes,
      onCancel,
      onChange,
      onSave,
      onDelete,
      shouldOpen,
      onRemoveParticipant,
      meetingRooms,
      existingMeeting,
      meetingData,
      onUserSuggestionsClearRequested,
      onUserSuggestionSelected,
      onChangeDate,
      upcommingGroupVisits,
      handleGroupSelected,
    } = this.props;

    const startDate = meetingData.startDate;
    const endDate = startDate ? meetingData.endDate : null;
    const name = meetingData.name;
    const place = meetingData.place;
    const participants = meetingData.participants;
    const notes = meetingData.notes;
    const newParticipant = meetingData.newParticipant;
    // determine if save button should be disabled
    const datesValid =
      startDate != null && (endDate === null || endDate >= startDate);
    const roomSelected = place !== '';
    const nameEntered = name !== '';
    const saveButtonDisabled = !(datesValid && roomSelected && nameEntered);

    return (
      <Modal
        className={classes.modal}
        open={shouldOpen}
        onClose={onCancel}
        disableAutoFocus
      >
        <Paper
          className={classNames(classes.paper, classes.posRelative)}
          elevation={elevation.light}
        >
          <Grid container direction="column" className={classes.container}>
            <div className={classes.modalHeader}>
              <div className={classes.leftIconContainer}>
                <Event />
              </div>
              <Input
                name="name"
                placeholder={i18n.t(
                  'meeting_rooms_form_meeting_name_placeholder'
                )}
                className={classes.inputTextFieldHeader}
                fullWidth
                disableUnderline
                value={name}
                onChange={onChange('name')}
              />
              <div>
                <IconButton
                  data-cy="edit-meeting-button-close"
                  onClick={onCancel}
                  className={classes.modalButton}
                >
                  <Close />
                </IconButton>
              </div>
            </div>
            <Grid
              container
              alignItems="center"
              className={classes.modalRow}
              wrap="nowrap"
            >
              <Grid item className={classes.leftIconContainer}>
                <Schedule className={classes.required} />
              </Grid>
              <Grid container wrap="nowrap" justifyContent="space-between">
                <Grid item className={classes.datePicker}>
                  <VmsDateTimePicker
                    name="startDate"
                    value={startDate}
                    onChange={(date) => onChangeDate(date, 'startDate')}
                    placeholder={i18n.t(
                      'meeting_rooms_form_meeting_start_time_placeholder'
                    )}
                    format={date_formats.full_date_short}
                    minDate={moment().startOf('day')}
                  />
                </Grid>
                <Grid item className={classes.datePicker}>
                  <VmsDateTimePicker
                    name="endDate"
                    value={endDate}
                    onChange={(date) => onChangeDate(date, 'endDate')}
                    placeholder={i18n.t(
                      'meeting_rooms_form_meeting_end_time_placeholder'
                    )}
                    format={date_formats.full_date_short}
                    disabled={!startDate}
                    minDate={startDate}
                    minDateMessage="Too low date"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.modalRow}>
              <Grid item className={classes.leftIconContainer}>
                <Place className={classes.required} />
              </Grid>
              <Grid item className={classes.modalRowRightSide}>
                <VmsDialogRowSelect
                  placeholder={i18n.t('meeting_rooms_form_room_placeholder')}
                  onChange={onChange('place')}
                  selectTypes={meetingRooms}
                  selectedType={place}
                  inputName="place"
                  selectDataCy="edit-meeting-select-room"
                  itemDataCy="edit-meeting-list-item-room"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.modalRow}>
              <Grid item className={classes.leftIconContainer}>
                <Group />
              </Grid>
              <Grid item className={classes.modalRowRightSide}>
                <VmsDialogRowSelect
                  placeholder={i18n.t(
                    'meeting_rooms_form_add_group_placeholder'
                  )}
                  onChange={handleGroupSelected}
                  selectTypes={upcommingGroupVisits}
                  selectedType=""
                  inputName="group"
                  selectDataCy="edit-meeting-select-group"
                  itemDataCy="edit-meeting-list-item-group"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.modalRow}>
              <Grid item className={classes.leftIconContainer}>
                <Group />
              </Grid>
              <Grid item className={classes.modalRowRightSide}>
                <VmsAutocomplete
                  inputName="newParticipant"
                  suggestionService={(searchValue) =>
                    UserService.getUserSuggestions(
                      searchValue,
                      false,
                      false,
                      true
                    )
                  }
                  filterSuggestionsResponse={(suggestion) => {
                    return suggestion;
                  }}
                  suggestionItemValue={(suggestion) => {
                    return suggestion.name;
                  }}
                  onSuggestionsClearRequested={onUserSuggestionsClearRequested}
                  onSuggestionSelected={onUserSuggestionSelected}
                  inputValue={newParticipant}
                  placeholder={i18n.t(
                    'meeting_rooms_form_add_participant_placeholder'
                  )}
                  onChange={onChange}
                  itemDataCy="edit-meeting-list-item-new-participant"
                />
              </Grid>
            </Grid>
            {/* add row for each existing participant */}
            {participants.map((participant, idx) => (
              <Grid
                data-cy="edit-meeting-list-item-participant"
                container
                key={idx}
                className={classes.modalRow}
              >
                <Grid item className={classes.leftIconContainer}></Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.modalRowRightSide}
                >
                  <Grid item>
                    <IconButton
                      data-dy="edit-meeting-button-remove-participant"
                      className={classes.removeButton}
                      onClick={() => onRemoveParticipant(idx)}
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label data-cy="edit-meeting-text-participant-name">
                      {participant.user
                        ? participant.user.name
                        : participant.name}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              className={classNames(
                classes.modalRow,
                classes.modalRowMultiLine
              )}
              wrap="nowrap"
            >
              <Grid item className={classes.leftIconContainer}>
                <EventNote />
              </Grid>
              <Grid item className={classes.modalRowRightSide}>
                <VmsTextField
                  name="notes"
                  fullWidth
                  placeholder={i18n.t(
                    'meeting_rooms_form_input_notes_placeholder'
                  )}
                  value={notes}
                  onChange={onChange('notes')}
                  rows={5}
                  maxLength={120}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              className={classes.modalButtonRow}
            >
              <Grid item className={classes.modalButtonWrapper}>
                {existingMeeting ? (
                  <Button
                    data-cy="edit-meeting-button-delete"
                    variant="contained"
                    color="primary"
                    className={classes.modalButton}
                    onClick={onDelete}
                  >
                    {i18n.t('meeting_rooms_form_delete_button')}
                  </Button>
                ) : null}
              </Grid>
              <Grid item className={classes.modalButtonWrapper}>
                <Button
                  data-cy="edit-meeting-button-save"
                  variant="contained"
                  color="primary"
                  className={classes.modalButton}
                  onClick={onSave}
                  disabled={saveButtonDisabled}
                >
                  {i18n.t('meeting_rooms_form_save_button')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  }

  render() {
    const { editMode } = this.props;

    if (editMode) {
      return this.createEditModal();
    } else {
      return this.createDetailModal();
    }
  }
}

MeetingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemoveParticipant: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  meetingRooms: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  existingMeeting: PropTypes.bool.isRequired, // tells if modal is showing already existing meeting
  meetingData: PropTypes.object.isRequired,
  onUserSuggestionsClearRequested: PropTypes.func.isRequired,
  onUserSuggestionSelected: PropTypes.func.isRequired,
  selectedRoomKey: PropTypes.string,
  selectedRoomNote: PropTypes.string,
  editable: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MeetingForm);
