import AbstractService from '../services/AbstractService';
import Auth from '../modules/Auth';

class EmailServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'email';
  }
  /**
   * Send email to specified addresses.
   *
   * @param {string} to String containing email addresses
   * @param {string} subject Subject of email
   * @param {string} html Email message
   */
  send(to, subject, html, correlationEntity, correlationId) {
    // sent pure html code only
    html = html.replace(/(\r\n|\n|\r)/gm, '');

    var emailData = {
      to: to,
      subject: subject,
      html: html,
      correlationEntity: correlationEntity,
      correlationId: correlationId,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, emailData, config);
  }

  /**
   * Send email template to specified addresses.
   *
   * @param {string} to AString containing email addresses
   * @param {object} template Email template
   */
  sendTemplate(to, template, correlationEntity, correlationId) {
    return this.send(
      to,
      template.title,
      template.body,
      correlationEntity,
      correlationId
    );
  }

  /**
   * Get status of sent emails.
   *
   * @param {Array} messageIds Array containing messageIds of sent emails
   */
  getStatus(messageIds) {
    let endpoint = this.endpoint + '/status';
    var data = {
      messageIds: messageIds,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(endpoint, data, config);
  }
}

const EmailService = new EmailServiceImpl();

export default EmailService;
