import { withStyles, Input, Grid, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import commonStyle from '../../../assets/jss/commonStyles';
import { useTranslation } from 'react-i18next';
import { RequiredIcon } from 'components';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const VmsDialogRow = (props) => {
  const {
    placeholder,
    textValue,
    iconLeft,
    iconRight,
    onChange,
    inputLabel,
    classes,
    rowClass,
    disabled,
    inputName,
    onClick,
    inputType,
    inputError,
    onKeyPress,
    inputClass,
    inputClasses,
    inputProps,
    autoComplete,
    required,
    inputRef,
    ...others
  } = props;
  const [t] = useTranslation();

  return (
    <Grid
      container
      onClick={onClick}
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <Grid item>
          <div className={classes.icon}>
            <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
          </div>
        </Grid>
      )}
      <Grid item container className={classes.rowContent}>
        <Input
          ref={inputRef}
          classes={inputClasses}
          className={inputClass}
          name={inputName}
          placeholder={
            required ? `${placeholder} (${t('required')})` : placeholder
          }
          label={inputLabel}
          autoComplete={autoComplete}
          type={inputType}
          error={inputError}
          fullWidth
          value={textValue}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={onKeyPress}
          inputProps={inputProps}
          endAdornment={
            iconRight && (
              <InputAdornment className={classes.AdornmentIcon} position="end">
                {iconRight}
              </InputAdornment>
            )
          }
          {...others}
        />
      </Grid>
    </Grid>
  );
};

VmsDialogRow.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  rowClass: PropTypes.string,
  inputName: PropTypes.string,
  inputType: PropTypes.string,
  inputError: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
};

export default withStyles(styles)(VmsDialogRow);
