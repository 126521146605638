import { Component } from 'react';
import Auth from 'modules/Auth';
import { Redirect } from 'react-router-dom';

class LogoutPage extends Component {
  componentDidMount() {
    Auth.deauthenticateUser();
  }

  render() {
    return <Redirect to="/" />;
  }
}

export default LogoutPage;
