import { memo } from 'react';
import {
  withStyles,
  Typography,
  Button,
  Modal,
  Paper,
} from '@material-ui/core';
import { VmsDialogHeader } from 'components';
import LandscapeIcon from '@material-ui/icons/Landscape';
import { DocumentService } from 'services';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import { elevation } from 'assets/UISettings';
import { useTranslation } from 'react-i18next';
import ExploreStarContainer from './ExploreStarContainer';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...modalStyle(theme, 800),
  container: {
    padding: 2 * theme.spacing(),
  },
  image: {
    maxWidth: 'min(100%, 600px)',
    padding: 2 * theme.spacing() + 'px 0',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  starsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stars: {
    display: 'inline-block',
    margin: '0 ' + 0.2 + 'em',
    '& span': {
      fontSize: '0.875rem !important',
      lineHeight: '1 !important',
    },
  },
  starsText: {
    display: 'inline',
  },
});

const ExploreThingRead = ({ classes, thing, onClose }) => {
  const [t] = useTranslation();

  const { name, url, document, text, rating, reviews } = thing;
  const image = DocumentService.api + (document || {}).downloadUrl;

  return (
    <Modal className={classes.modal} open={true} onClose={onClose}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsDialogHeader
          title={t('settings_explore_type_thing')}
          onClose={onClose}
          icon={<LandscapeIcon />}
          closeButtonDataCy="explore-thing-button-close"
        />
        <div className={classes.container}>
          <Typography
            data-cy="explore-thing-text-name"
            variant="h5"
            component="h1"
          >
            {name}
          </Typography>
          <ExploreStarContainer rating={rating} reviews={reviews} />
          <div className={classes.imageContainer}>
            <img className={classes.image} src={image} alt={name} />
          </div>
          <Typography variant="body1">{text}</Typography>
          <div className={classes.buttonContainer}>
            <Button
              data-cy="explore-thing-button-display-more"
              className={classes.button}
              color="primary"
              variant="contained"
              target="_blank"
              href={url}
              rel="noopener"
            >
              {t('explore_display_more')}
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default memo(withStyles(styles)(ExploreThingRead));
