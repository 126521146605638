import { useCallback, useState } from 'react';
import { handleOpenConfirmDialog } from '../dialogs/ConfirmDialog';

const useMenu = (
  deleteService,
  onDeleteSuccess,
  onDeleteFailure,
  removeDialogTitle,
  removeDialogText
) => {
  const [clickedItem, setClickedItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = useCallback((event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setClickedItem(item);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    setClickedItem(null);
  }, []);

  const handleRemoveItem = useCallback(
    async (event) => {
      try {
        await deleteService(clickedItem._id);
        onDeleteSuccess([clickedItem]);
      } catch (err) {
        onDeleteFailure(err);
      }
    },
    [clickedItem, deleteService, onDeleteSuccess, onDeleteFailure]
  );

  const handleMenuRemoveItemOpenDialog = useCallback(
    (event) => {
      handleMenuClose();
      handleOpenConfirmDialog(
        removeDialogTitle,
        removeDialogText,
        handleRemoveItem
      );
    },
    [handleMenuClose, removeDialogTitle, removeDialogText, handleRemoveItem]
  );

  return {
    clickedItem,
    menuAnchorEl,
    handleMenuOpen,
    handleMenuClose,
    handleMenuRemoveItemOpenDialog,
  };
};

export default useMenu;
