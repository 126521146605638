import { Component } from 'react';
import {
  withStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  Grid,
  MenuItem,
  Menu,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { QuestionEditor } from 'components/index';
import PropTypes from 'prop-types';
import { openSnackbar } from 'components/common/bars/SnackBar';
import i18n from 'assets/i18n';
import Striptags from 'striptags';
import {
  FeedbackService,
  ExportService,
  TemplateService,
  ConfigurationService,
} from 'services';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import classNames from 'classnames';
import tableStyles from 'assets/jss/tableStyles';
import { emptySettingSearchState } from 'containers/settings/SettingsPage';
import arrayMove from 'array-move';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { routes, type_constants } from 'AppSettings';
import { handleOpenConfirmDialog } from '../../../components/common/dialogs/ConfirmDialog';

const styles = (theme) => ({
  ...tableStyles(theme),
  button: {
    margin: theme.spacing(),
  },
  tableCellType: {
    textAlign: 'right',
    fontWeight: 500,
    color: theme.palette.primary4,
    marginRight: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    color: theme.palette.common.white,
  },
});

class FeedbackPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openQuestionEditor: false,
      isEditing: false,
      questions: [],
      editorObject: {
        question: {
          _id: '',
          questionTitle: '',
          question: TemplateService.createEmpty(),
          answers: [],
          type: '',
        },
      },
      newAnswer: { answer: '', isCorrect: false },
      tableHeaderState: createTableHeaderState([]),
      highlightedId: '',
      questionnaire_types: [],
      menuAnchorEl: null,
      currentItem: {},
    };

    this.tableHeader = null;
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadQuestions();
    this.setSearchSettings();
    this.loadTypes();
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_FEEDBACK) this.handleNewQuestion();
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  loadTypes = () => {
    ConfigurationService.getTypesForConfiguration(type_constants.FEEDBACK)
      .then((response) => {
        this.setState({
          questionnaire_types: response,
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService =
        FeedbackService.getQuestionSuggestions;
      settingSearchState.onSuggestionsClearRequested =
        this.handleTrainingSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleTrainingSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleTrainingSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.questionTitle;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleTrainingSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleTrainingSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestTraining(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.questionTitle;
      onChangeSearch(settingSearchState);
    }
  };

  handleTrainingSuggestionChange = (name, value) => (event) => {
    const field = name || event.target.name;
    const fieldValue = value ?? event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestTraining = (_id) => {
    const { questions, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = questions.findIndex((u) => u._id === _id);
    console.log(index);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  loadQuestions = () => {
    FeedbackService.getQuestions()
      .then((response) => {
        this.setState(function (prevState) {
          prevState.questions = response;
          prevState.tableHeaderState.data = response;
          return prevState;
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleNewQuestion = () => {
    this.setState({
      editorObject: {
        question: {
          _id: '',
          questionTitle: '',
          question: TemplateService.createEmpty(),
          answers: [],
          type: '',
        },
      },
      openQuestionEditor: true,
      isEditing: false,
      selected: [],
      newAnswer: { answer: '', isCorrect: false },
    });
  };

  handleEditQuestion = (value) => {
    if (this.state.tableHeaderState.selected.length === 0) {
      let currentEditor = this.state.editorObject;
      currentEditor.question.question = TemplateService.createEditorFromHtml(
        value.question,
        'html'
      );
      currentEditor.question._id = value._id;
      currentEditor.question.answers = value.answers;
      currentEditor.question.questionTitle = value.questionTitle;
      currentEditor.question.type = value.type !== undefined ? value.type : '';

      this.setState({
        editorObject: currentEditor,
        openQuestionEditor: true,
        isEditing: true,
        selected: [],
        newAnswer: { answer: '', isCorrect: false },
        menuAnchorEl: null,
      });
    }
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    handleOpenConfirmDialog(
      i18n.t('settings_question_dialog_remove_selected_title'),
      i18n.t('settings_question_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => (event) => {
    const { questions, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = questions;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = questions.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      FeedbackService.remove_question(item._id)
        .then((response) => {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            this.setState(function (prevState) {
              prevState.questions = array;
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          this.loadQuestions();
        });
    });
  };

  handleDeleteQuestionOpenDialog = (questionId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_question_dialog_remove_title'),
      i18n.t('settings_question_dialog_remove_description'),
      this.handleDeleteQuestion(questionId)
    );
  };

  handleDeleteQuestion = (questionId) => (event) => {
    FeedbackService.remove_question(questionId)
      .then((response) => {
        openSnackbar(i18n.t('removed'));
        this.loadQuestions();
      })
      .catch((error) => {
        openSnackbar(error.message);
      });
    this.setState({
      menuAnchorEl: null,
    });
  };

  handleClose = () => {
    this.setState({
      openQuestionEditor: false,
      selected: [],
    });
  };

  handleEditorStateChange = (editorState) => {
    let currentEditor = this.state.editorObject;
    currentEditor.question.question = editorState;
    this.setState({
      editorObject: currentEditor,
    });
  };

  handleChangeTitle = (event) => {
    let currentEditor = this.state.editorObject;
    currentEditor.question.questionTitle = event.target.value;
    this.setState({
      questionTitle: currentEditor,
    });
  };

  handleChangeType = (name, value) => (event) => {
    const field = name || event.target.name;
    const fieldValue = value ?? event.target.value;
    if (field === 'newQuestionsType') {
      let currentEditor = this.state.editorObject;
      currentEditor.question.type = fieldValue ? fieldValue : '';
      this.setState({
        questionTitle: currentEditor,
      });
    }
  };

  handleSave = () => {
    const { isEditing } = this.state;
    const question = this.state.editorObject.question;

    if (isEditing) {
      FeedbackService.edit_question(
        question._id,
        question.questionTitle,
        TemplateService.draftToHtml(question.question.getCurrentContent()),
        question.answers,
        question.type
      )
        .then((response) => {
          this.setState({
            openQuestionEditor: false,
          });
          openSnackbar(i18n.t('edited'));
          this.loadQuestions();
        })
        .catch((error) => {
          console.log(error);
          openSnackbar(error.message);
          this.loadQuestions();
        });
    } else {
      FeedbackService.create(
        question.questionTitle,
        TemplateService.draftToHtml(question.question.getCurrentContent()),
        question.answers,
        question.type
      )
        .then((response) => {
          this.setState({
            openQuestionEditor: false,
          });
          openSnackbar(i18n.t('saved'));
          this.loadQuestions();
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
          this.loadQuestions();
        });
    }
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  handleNewAnswerKeyPress = (event) => {
    const answer = this.state.newAnswer.answer.trim();
    if (event.key === 'Enter' && answer !== '') {
      let currentEditor = this.state.editorObject;
      currentEditor.question.answers = [
        ...currentEditor.question.answers,
        this.state.newAnswer,
      ];
      this.setState({
        editorObject: currentEditor,
        newAnswer: { answer: '', isCorrect: false },
      });
    }
  };

  handleEnterNewAnswer = (_, answer) => {
    let currentAnswer = this.state.newAnswer;
    currentAnswer.answer = answer;
    this.setState({
      newAnswer: currentAnswer,
    });
  };

  handleRemoveAnswerButton = (index) => {
    let currentEditor = this.state.editorObject;
    currentEditor.question.answers = currentEditor.question.answers.filter(
      (_, i) => i !== index
    );
    this.setState({
      editorObject: currentEditor,
    });
  };

  handleOnAnswerInputChange = (index, answer) => {
    let currentEditor = this.state.editorObject;
    currentEditor.question.answers[index].answer = answer;
    this.setState({
      editorObject: currentEditor,
    });
  };

  onDrop = (dropResult) => {
    const { removedIndex, addedIndex } = dropResult;
    let currentEditor = this.state.editorObject;
    currentEditor.question.answers = arrayMove(
      currentEditor.question.answers,
      removedIndex,
      addedIndex
    );
    this.setState({
      items: currentEditor,
    });
  };

  handleTrainingsTypeSuggestionsClearRequested = () => (event) => {};

  handleTrainingsTypeSuggestionSelected = (suggestion) => (event) => {
    let copy = this.state.editorObject;
    copy.question.type = suggestion.key;
    this.setState({
      editorObject: copy,
    });
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  render() {
    const { classes } = this.props;
    const {
      questions,
      tableHeaderState,
      highlightedId,
      questionnaire_types,
      menuAnchorEl,
      currentItem,
    } = this.state;
    const { rowsPerPage, page, selected } = tableHeaderState;
    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                exportData={ExportService.questionnaires(selected)}
                exportDataFilename={'questionnaires.csv'}
                prevPageButtonDataCy="settings-feedback-button-previous-page"
                nextPageButtonDataCy="settings-feedback-button-next-page"
              />
              <TableBody>
                {questions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((question) => {
                    const isSelected = this.isSelected(question);
                    return (
                      <TableRow
                        key={question._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === question._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onClick={this.handleClickRow(question)}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                          onClick={() => this.handleEditQuestion(question)}
                        >
                          <Typography
                            variant="body2"
                            className={classes.header}
                            noWrap
                          >
                            {question.questionTitle}
                          </Typography>
                          <Typography variant="caption" gutterBottom noWrap>
                            {TemplateService.replaceHtmlSpecialChars(
                              Striptags(question.question)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignRight
                          )}
                          padding="none"
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableCellType
                            )}
                            noWrap
                          >
                            {question.type}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none" /*onClick={() => this.handleEditQuestion({ question })}*/
                        >
                          <IconButton
                            className={classes.tableMenuButton}
                            onClick={(event) =>
                              this.handleMenuOpen(event, question)
                            }
                          >
                            <MoreHoriz className={classes.tableIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => this.handleEditQuestion(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          <MenuItem
            onClick={this.handleDeleteQuestionOpenDialog(currentItem._id)}
          >
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <QuestionEditor
          editorObject={this.state.editorObject}
          open={this.state.openQuestionEditor}
          key={this.state.selectedIndex}
          onClose={this.handleClose}
          onSave={this.handleSave}
          editMode={true}
          onChangeTitle={this.handleChangeTitle}
          onEditorStateChange={this.handleEditorStateChange}
          onRemoveAnswer={this.handleRemoveAnswerButton}
          onAnswerInputChange={this.handleOnAnswerInputChange}
          onNewAnswerKeyPress={this.handleNewAnswerKeyPress}
          onEnterNewAnswer={this.handleEnterNewAnswer}
          newAnswer={this.state.newAnswer}
          indexOfNewAnswer={
            this.state.answers !== undefined ? this.state.answers.length - 1 : 0
          }
          typeSuggestion={this.state.trainingsTypeSuggestions}
          newQuestionsType={this.state.editorObject.question.type}
          onChangeType={this.handleChangeType}
          onTypeSuggestionsClearRequested={
            this.handleTrainingsTypeSuggestionsClearRequested
          }
          onTypeSuggestionSelected={this.handleTrainingsTypeSuggestionSelected}
          onDrop={this.onDrop}
          questionnaire_types={questionnaire_types}
          typePlaceholder={i18n.t('questionnaire_feedback_placeholder')}
        />
      </div>
    );
  }
}

FeedbackPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withBus(), withStyles(styles))(FeedbackPage);
