import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class FeedbackServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'feedback/';
  }

  getQuestions() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  getQuestionSuggestions = (searchValue) => {
    const endpoint = 'feedback/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getQuestion(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return this.get(this.endpoint + id, config);
  }

  getQuestionIDs(tenantId, type) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.get(this.endpoint + 'type/' + tenantId + '/' + type, config);
  }

  getDistinctTypes() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return this.get(this.endpoint + 'types/', config);
  }

  create(questionTitle, question, answers, type) {
    const data = { questionTitle, question, answers, type };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return this.post(this.endpoint, data, config);
  }

  remove_question(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.delete(this.endpoint + id, config);
  }

  edit_question(id, questionTitle, question, answers, type) {
    const data = { questionTitle, question, answers, type };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return this.put(this.endpoint + id, data, config);
  }
}

const FeedbackService = new FeedbackServiceImpl();

export default FeedbackService;
