import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AccountActivationForm from 'components/common/forms/AccountActivationForm';
import Auth from 'modules/Auth';
import { Redirect } from 'react-router-dom';
import i18n from 'assets/i18n';
import { UserService, TemplateService } from 'services';
import * as qs from 'query-string';
import commonStyles from 'assets/jss/commonStyles';
import { user_roles } from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import { openSnackbar } from 'components/common/bars/SnackBar';

const styles = (theme) => ({
  ...commonStyles(theme),
  loginModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '33%',
};

class AccountActivationPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: '',
        passwordVerify: '',
        message: i18n.t('account_activation_activate_verify'),
        stage: 'verify',
        agreeTerms: false,
        agreePrivacy: false,
        agreeBehaviour: false,
        role: user_roles.VISITOR,
      },
    };

    this.changeData = this.changeData.bind(this);
    this.userId = null;
    this.activationToken = null;
    this.documents = {
      privacy_policy: '',
      terms_of_use: '',
      rules_of_behaviour: '',
    };
  }

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeData(event) {
    const field = event.target.name;
    const data = this.state.data;
    data[field] = event.target.value;

    this.setState({
      data,
    });
  }

  changeTermsData = (field, value) => {
    this.setState((prevState) => {
      prevState.data[field] = value;
      return prevState;
    });
  };

  componentDidMount() {
    console.log(this.props);
    this.userId = this.props.match.params.userId;
    this.activationToken = qs.parse(this.props.location.search).token;
    this.verifyUserActivation(this.userId, this.activationToken);
    this.loadData();
  }

  async loadData() {
    try {
      const user = await UserService.getPendingUser(this.userId);
      console.log(user);
      i18n.changeLanguage(user.language);
    } catch (err) {
      console.log(err);
    }

    let documentPromises = [];
    documentPromises.push(
      TemplateService.getPublicTemplateByType('privacy_policy')
    );
    documentPromises.push(
      TemplateService.getPublicTemplateByType('terms_of_use')
    );
    documentPromises.push(
      TemplateService.getPublicTemplateByType('rules_of_behaviour')
    );

    Promise.all(documentPromises)
      .then((documents) => {
        this.documents.privacy_policy = (
          ((documents || [])[0] || [])[0] || {}
        ).body;
        this.documents.terms_of_use = (
          ((documents || [])[1] || [])[0] || {}
        ).body;
        this.documents.rules_of_behaviour = (
          ((documents || [])[2] || [])[0] || {}
        ).body;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  verifyUserActivation = (userId, activationToken) => {
    var self = this;
    let data = self.state.data;
    UserService.verifyUserActivation(userId, activationToken)
      .then(function (response) {
        data.stage = 'activate';
        data.user = response.name;
        data.role = response.role;
        self.setState({
          data: data,
        });
      })
      .catch(function (error) {
        data.stage = 'failed';
        data.message = i18n.t('account_activation_cannot_activate');
        self.setState({
          data: data,
        });
        console.log(error);
      });
  };

  handleActivate = () => {
    var self = this;
    let data = self.state.data;
    data.stage = 'activating';
    data.message = i18n.t('account_activation_activate_activating');
    self.setState({
      data: data,
    });
    UserService.activateUserAccount(
      this.userId,
      this.activationToken,
      data.password,
      data.agreeTerms
    )
      .then(function (response) {
        data.stage = 'success';
        data.message = i18n.t('account_activation_activate_success');
        Auth.authenticateUser(response.token, response.refresh_token);
        self.setState({
          data: data,
        });
      })
      .catch(function (error) {
        data.stage = 'activate';
        data.message = i18n.t('account_activation_activate_verify');
        openSnackbar(error.message);
        self.setState({
          data: data,
        });
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer} style={modalStyle}>
        {this.state.data.stage === 'success' ? (
          <Redirect to={Auth.getHomePage()} />
        ) : (
          <Aux>
            <AccountActivationForm
              handleActivate={this.handleActivate}
              onChange={this.changeData}
              onChangeTermsData={this.changeTermsData}
              data={this.state.data}
            />
          </Aux>
        )}
      </div>
    );
  }
}

AccountActivationPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountActivationPage);
