import { withStyles, Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { VmsTextField } from '../../material-ui';

const styles = (theme) => ({
  iconLeft: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  iconRight: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
  },
});

const VmsDialogRowTextField = (props) => {
  const {
    placeholder,
    textValue,
    iconLeft,
    onChange,
    classes,
    rowClass,
    inputName,
    multiline,
    rows,
    maxLength,
    disabled,
  } = props;
  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.iconLeft}>{iconLeft}</div>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <FormControl className={classes.textField} fullWidth>
          <VmsTextField
            className={classes.textField}
            type="text"
            name={inputName}
            value={textValue}
            placeholder={placeholder}
            onChange={onChange}
            fullWidth
            multiline={multiline}
            rows={rows}
            maxLength={maxLength}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

VmsDialogRowTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.string,
  disabled: PropTypes.bool,
  rowClass: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  multiline: PropTypes.bool.isRequired,
  rows: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
};

export default withStyles(styles)(VmsDialogRowTextField);
