import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class PassServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'pass';
  }

  /**
   * Get apple pass for visit.
   */
  getApplePassForVisit(visitId) {
    const endpoint = 'pass/apple/' + visitId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Get apple pass for visit.
   */
  getGooglePayJwtForVisit(visitId) {
    const endpoint = 'pass/google/' + visitId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }
}

const PassService = new PassServiceImpl();

export default PassService;
