import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { ScheduledJobDialog } from 'components/index';
import { openSnackbar } from 'components/common/bars/SnackBar';
import ScheduledJobService from 'services/ScheduledJobService';
import i18n from 'assets/i18n';
import moment from 'moment';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import tableStyles from 'assets/jss/tableStyles';
import { date_formats, scheduled_job_status } from 'AppSettings';

const styles = (theme) => ({
  ...tableStyles(theme),
});

class ScheduledJobsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [],
      menuAnchorEl: null,
      currentItem: null,
      dialogOpen: false,
      tableHeaderState: createTableHeaderState([]),
    };

    this.tableHeader = null;
  }

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  componentDidMount() {
    this.loadData();
  };

  loadData = () => {
    ScheduledJobService.getScheduledJobs()
      .then((results) => {
        this.setState(function (prevState) {
          prevState.jobs = results;
          prevState.tableHeaderState.data = results;
          return prevState;
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItems = () => {
    const { jobs, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = jobs;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = jobs.findIndex((q) => q._id === item.id);
      array.splice(index, 1);
      ScheduledJobService.cancelScheduledJob(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('canceled'));
            self.loadData();
            self.setState((prevState) => {
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadData();
        });
    });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleDialogOpen = (item) => (event) => {
    this.setState({
      dialogOpen: true,
      menuAnchorEl: null,
      currentItem: item,
    });
  };

  handleDialogOnClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleCancelJob = (item) => (event) => {
    const self = this;
    ScheduledJobService.cancelScheduledJob(item._id)
      .then(function (response) {
        openSnackbar(i18n.t('canceled'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
    this.setState({
      menuAnchorEl: null,
      currentItem: null,
    });
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  render() {
    const { classes } = this.props;
    const { jobs, menuAnchorEl, currentItem, dialogOpen, tableHeaderState } =
      this.state;
    const { rowsPerPage, page } = tableHeaderState;

    const visibleItems = jobs.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                selectFunction={(item) =>
                  item.status === scheduled_job_status.PENDING
                }
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItems}
                prevPageButtonDataCy="settings-scheduled-jobs-button-previous-page"
                nextPageButtonDataCy="settings-scheduled-jobs-button-next-page"
              />
              <TableBody>
                {visibleItems.map((item, idx) => {
                  const isSelected = this.isSelected(item);
                  const isCancelable =
                    item.status === scheduled_job_status.PENDING;
                  const typeTranslation = i18n.t(
                    `app_settings_type_${item.type}`
                  );
                  const statusTranslation = i18n.t(
                    `scheduled_job_status_${item.status}`
                  );
                  const deliveryDate = moment(item.deliveryDate).format(
                    date_formats.full_date_long
                  );
                  return (
                    <TableRow
                      key={idx}
                      hover
                      role="checkbox"
                      selected={isSelected}
                      aria-checked={isSelected}
                      className={classes.tableRow}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                      >
                        {isCancelable ? (
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onClick={this.handleClickRow(item)}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellAlignLeft
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {typeTranslation}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.textAlignRight
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {deliveryDate}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.textAlignRight
                        )}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {statusTranslation}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                        padding="none"
                      >
                        <IconButton
                          className={classes.tableMenuButton}
                          aria-owns={menuAnchorEl ? 'table-menus' : null}
                          aria-haspopup="true"
                          onClick={(event) => this.handleMenuOpen(event, item)}
                        >
                          <MoreHoriz className={classes.tableIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleDialogOpen(currentItem)}>
            {i18n.t('menu_show')}
          </MenuItem>
          {currentItem != null &&
          currentItem.status === scheduled_job_status.PENDING ? (
            <MenuItem onClick={this.handleCancelJob(currentItem)}>
              {i18n.t('menu_cancel')}
            </MenuItem>
          ) : null}
        </Menu>
        <ScheduledJobDialog
          open={dialogOpen}
          onClose={this.handleDialogOnClose}
          item={currentItem}
        />
      </div>
    );
  }
}

ScheduledJobsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ScheduledJobsPage);
