import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class ScheduledJobServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'jobs';
  }

  getScheduledJobs() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  cancelScheduledJob(jobId) {
    var endpoint = 'jobs/' + jobId;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    const body = {
      status: 'canceled',
      note: `Canceled by user with ID ${Auth.getUser()._id}`,
    };

    return this.put(endpoint, body, config);
  }

  scheduleJob(type, users, deliveryDate, data, note = '') {
    const endpoint = 'jobs/schedule';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    const body = {
      type: type,
      users: users,
      deliveryDate: deliveryDate,
      data: data,
      note: note,
    };

    return this.post(endpoint, body, config);
  }
}

const ScheduledJobService = new ScheduledJobServiceImpl();

export default ScheduledJobService;
