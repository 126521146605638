const buttonStyle = (theme) => ({
  fab: {
    position: 'fixed',
    margin: 0,
    bottom: theme.spacing(6),
    right: theme.spacing(8),
    top: 'auto',
    left: 'auto',
    color: theme.palette.common.white,
  },
  buttonBasic: {
    margin: theme.spacing(2),
    color: theme.palette.common.white,
  },
  menu: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.up('sm')]: {
      top: 8,
    },
  },
  fabProgress: {
    position: 'fixed',
    margin: 0,
    bottom: theme.spacing(6) - 6,
    right: theme.spacing(8) - 6,
    top: 'auto',
    left: 'auto',
    color: theme.palette.primary.main,
  },
});

export default buttonStyle;
