import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class FlightServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'flight';
  }

  getFlight = (flightNumber, flightDate) => {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    const endpoint = `${this.endpoint}/${flightNumber.trim()}/${flightDate}`;
    return this.get(endpoint, config);
  };
}

const FlightService = new FlightServiceImpl();

export default FlightService;
