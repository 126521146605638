import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ChatIcon from '@material-ui/icons/Chat';
import PlaceIcon from '@material-ui/icons/Place';
import LinkIcon from '@material-ui/icons/Link';
import StarIcon from '@material-ui/icons/Star';
import RateReviewIcon from '@material-ui/icons/RateReview';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReorderIcon from '@material-ui/icons/Reorder';
import {
  VmsInput,
  VmsDialog,
  VmsDialogRowTextField,
  useImageUpload,
  openSnackbar,
} from 'components';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { explore_types } from 'AppSettings';
import Coordinates from 'coordinate-parser';
import { DocumentService } from 'services';
import { useTranslation } from 'react-i18next';
import VmsDialogDragDropImage from 'containers/VmsDialogDragDropImage';
import { useState } from 'react';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  root: {
    overflow: 'auto',
  },
});

const isValidPosition = (position) => {
  try {
    new Coordinates(position);
    return true;
  } catch (error) {
    return false;
  }
};

const isNumber = (number) => {
  try {
    const n = Number(number);
    return isFinite(n);
  } catch (err) {
    return false;
  }
};

const largeDialogTypes = [explore_types.NEWS, explore_types.THING];
const imageTypes = [
  explore_types.TITLE_IMAGE,
  explore_types.NEWS,
  explore_types.THING,
];
const ratingAllowedValues = { min: 0, max: 5 };
const reviewsAllowedValues = { min: 0 };

const ExploreEditDialog = ({
  open,
  loading,
  classes,
  item,
  handleSave,
  handleClose,
  handleItemChange,
}) => {
  const [t] = useTranslation();
  const [saving, setSaving] = useState(false);

  const {
    _id: itemId,
    type,
    document,
    name,
    position,
    priority,
    text,
    url,
    rating,
    reviews,
  } = item || {};
  const { downloadUrl, _id: documentId } = document || {};

  const {
    imageState,
    upload,
    onNew,
    onEdit,
    onDrop,
    onDropAccepted,
    onDropRejected,
  } = useImageUpload(name, 'explore', '', true);
  const { uploading, files, base64, editExisting } = imageState;

  const close = () => {
    onNew();
    handleClose();
  };
  const allLoadings = loading || uploading || saving;

  const dialogTitle = t(
    (itemId ? 'settings_explore_edit_' : 'settings_explore_create_') + type
  );
  const itemPlaceholder = t(
    type === explore_types.WEATHER
      ? 'settings_explore_weather_city'
      : 'settings_explore_name'
  );
  const largeDialog = largeDialogTypes.includes(type);
  const imageType = imageTypes.includes(type);

  const saveDisabled =
    !name ||
    (type === explore_types.PLACE && !isValidPosition(position)) ||
    (imageType && !((downloadUrl && !editExisting) || files.length)) || //Any type which needs an image
    (largeDialog && ((!priority && !isNumber(priority)) || !text || !url)) || //News or thing
    (type === explore_types.THING &&
      ((!rating && !isNumber(rating)) || (!reviews && !isNumber(reviews))));

  const onChangeValue = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    handleItemChange({ [name]: value });
  };

  const onSave = async () => {
    if (imageType) {
      setSaving(true);
      try {
        //Copy is used so that we dont upload the same doc again in case the save fails
        const copy = { ...item };
        if (downloadUrl && base64) {
          await DocumentService.deleteDocument(documentId);
          copy.document = {};
          handleItemChange({ document: {} });
        }
        if (!copy.document._id) {
          const response = await upload();
          if (response) {
            handleItemChange({ document: { _id: response._id } });
            handleSave(response._id);
          }
        } else {
          handleSave();
        }
        onNew();
      } catch (err) {
        console.log(err);
        openSnackbar(err.message);
      } finally {
        setSaving(false);
      }
    } else {
      handleSave();
    }
  };

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={dialogTitle}
      onClose={close}
      dialogHeaderIcon={<EventIcon />}
      buttonIcon={<CheckCircleIcon className={classes.fabIcon} />}
      buttonText={t('save')}
      buttonAction={onSave}
      buttonDisabled={saveDisabled}
      width={largeDialog ? 800 : null}
      loading={allLoadings}
    >
      <div className={classes.root}>
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<ChatIcon className={classes.required} />}
          textValue={name || ''}
          placeholder={itemPlaceholder}
          inputLabel={itemPlaceholder}
          inputName="name"
          onChange={onChangeValue}
        />
        {imageType && (
          <VmsDialogDragDropImage
            name={name}
            imageState={imageState}
            document={document}
            onEdit={onEdit}
            onDrop={onDrop}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
          />
        )}
        {largeDialog && (
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<LinkIcon className={classes.required} />}
            textValue={url || ''}
            placeholder={t('settings_explore_url')}
            inputLabel={t('settings_explore_url')}
            inputName="url"
            onChange={onChangeValue}
          />
        )}
        {type === explore_types.PLACE && (
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<PlaceIcon className={classes.required} />}
            textValue={position || ''}
            placeholder={t('settings_explore_gps')}
            inputLabel={t('settings_explore_gps')}
            inputName="position"
            onChange={onChangeValue}
          />
        )}
        {type === explore_types.THING && (
          <>
            <VmsInput
              rowClass={classes.dialogRow2}
              iconLeft={<StarIcon className={classes.required} />}
              textValue={rating.toString()}
              placeholder={t('settings_explore_rating')}
              inputLabel={t('settings_explore_rating')}
              inputName="rating"
              onChange={onChangeValue}
              inputType="number"
              inputProps={ratingAllowedValues}
            />
            <VmsInput
              rowClass={classes.dialogRow2}
              iconLeft={<RateReviewIcon className={classes.required} />}
              textValue={reviews.toString()}
              placeholder={t('settings_explore_reviews')}
              inputLabel={t('settings_explore_reviews')}
              inputName="reviews"
              onChange={onChangeValue}
              inputType="number"
              inputProps={reviewsAllowedValues}
            />
          </>
        )}
        {largeDialog && (
          <>
            <VmsInput
              rowClass={classes.dialogRow2}
              iconLeft={<ReorderIcon className={classes.required} />}
              textValue={priority.toString()}
              placeholder={t('others_priority')}
              inputLabel={t('others_priority')}
              inputName="priority"
              onChange={onChangeValue}
              inputType="number"
            />
            <VmsDialogRowTextField
              rowClass={classes.dialogRow2}
              iconLeft={<EventNoteIcon className={classes.required} />}
              textValue={text || ''}
              placeholder={t('settings_explore_text')}
              inputLabel={t('settings_explore_text')}
              inputName="text"
              onChange={onChangeValue}
              multiline={true}
              rows={10}
            />
          </>
        )}
      </div>
    </VmsDialog>
  );
};

ExploreEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    document: PropTypes.shape({
      downloadUrl: PropTypes.string,
    }),
    priority: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    url: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleItemChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExploreEditDialog);
