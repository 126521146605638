import PropTypes from 'prop-types';
import { withStyles, Fab } from '@material-ui/core';
import buttonStyle from '../../../assets/jss/buttonStyle';

const styles = (theme) => ({
  ...buttonStyle(theme),
});

const ButtonFAB = (props) => {
  const { customStyle, classes, ...rest } = props;
  return (
    <Fab
      {...rest}
      color="primary"
      className={customStyle ? customStyle : classes.fab}
    >
      {props.children}
    </Fab>
  );
};

ButtonFAB.propTypes = {
  classes: PropTypes.object.isRequired,
  customStyle: PropTypes.string,
  variant: PropTypes.oneOf(['round', 'extended']),
};

export default withStyles(styles)(ButtonFAB);
