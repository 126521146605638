import { Component } from 'react';
import PropTypes from 'prop-types';
import modalStyle from 'assets/jss/modalStyle';
import commonStyles from 'assets/jss/commonStyles';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { HelpOutline, Build } from '@material-ui/icons';
import ConfigurationService from 'services/ConfigurationService';
import Auth from 'modules/Auth';
import i18n from 'assets/i18n';
import Aux from 'hoc/Auxiliary.js';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyles(theme),
  helpTextAlign: {
    textAlign: 'center',
  },
  iconStyle: {
    verticalAlign: 'middle',
    paddingRight: theme.spacing(),
  },
});

class HelpDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      helpContent: null,
    };
  }

  componentDidMount() {
    this.setState({
      helpContent: this.loadHelpContent(),
    });
  }

  loadHelpContent = () => {
    let content = {};
    ConfigurationService.getContactPeople()
      .then((fetchedData) =>
        fetchedData.forEach((item) => (content[item.key] = item.value))
      )
      .catch((err) => {});
    return content;
  };

  render() {
    const { classes, openHelp, handleHelp } = this.props;
    const { helpContent } = this.state;

    return (
      <Dialog
        open={openHelp}
        onClose={handleHelp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            className={classes.helpTextAlign}
            id="alert-dialog-description"
          >
            {helpContent && (
              <Aux>
                {Auth.isUserAuthenticated() ? (
                  <Aux>
                    <HelpOutline className={classes.iconStyle} />
                    {i18n.t('help_dialog_key_person_logged_in')}
                    <br />
                    <strong>{helpContent.key_person_name}</strong>
                    <br />
                    {helpContent.key_person_email}
                    <br />
                    {helpContent.key_person_phone}
                    <br />
                    <br />
                    <Build className={classes.iconStyle} />
                    {i18n.t('help_dialog_technical_person')}
                    <br />
                    <strong>{helpContent.technical_person_name}</strong>
                    <br />
                    {helpContent.technical_person_email}
                    <br />
                    {helpContent.technical_person_phone}
                    <br />
                  </Aux>
                ) : (
                  <Aux>
                    <HelpOutline className={classes.iconStyle} />
                    {i18n.t('help_dialog_key_person_not_logged_in')}
                    <br />
                    <strong>{helpContent.key_person_name}</strong>
                    <br />
                    {helpContent.key_person_email}
                    <br />
                    {helpContent.key_person_phone}
                    <br />
                  </Aux>
                )}
              </Aux>
            )}
            <br />
            {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

HelpDialog.propTypes = {
  openHelp: PropTypes.bool.isRequired,
  handleHelp: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(HelpDialog);
