import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Divider } from '@material-ui/core';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import i18n from 'assets/i18n';
import {
  VmsDialogRowSelectCollection,
  VmsDialogRowDocumentCollection,
  VmsDialogRowSelectArray,
  SafetyResultDialog,
} from 'components';
import {
  invite_tab_indexes,
  type_constants,
  user_roles,
  user_status,
} from 'AppSettings';
import VmsDialogDragDropPhoto from 'containers/VmsDialogDragDropPhoto';
import { createDragDropStateWithPhoto } from 'containers/VmsDialogDragDropPhoto';
import { createDragDropState } from 'containers/VmsDialogDragDropFile';
import Auth from 'modules/Auth';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import VmsDialogDragDropFile from 'containers/VmsDialogDragDropFile';
import FeedbackResultDialog from './FeedbackResultDialog';
import { VmsInput } from 'components/common/material-ui';
import { PersonOutline } from '@material-ui/icons';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

class GroupDocsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSafetyTraining: false,
      openFeedback: false,
      selectedDocument: {},
    };
  }

  shouldComponentUpdate(prevProps, nextProps) {
    const currentTab = nextProps.currentTab || prevProps.currentTab;
    return currentTab === invite_tab_indexes.DOCS;
  }

  handleOnClick = (document) => (event) => {
    this.setState({
      selectedDocument: document,
    });
    if (document.type === type_constants.QUESTIONNAIRE) {
      this.setState({
        openSafetyTraining: true,
      });
    } else if (document.type === type_constants.FEEDBACK) {
      this.setState({
        openFeedback: true,
      });
    } else {
      this.setState({
        openSafetyTraining: false,
      });
    }
  };

  handleCloseDialog = () => (event) => {
    this.setState({
      openSafetyTraining: false,
      openFeedback: false,
    });
  };

  changeData = (indexOfVisit) => (name, value, index) => (event) => {
    const { changeData } = this.props;
    console.log(name, value, index);

    changeData(name, value, index, indexOfVisit)(event);
  };

  changeFileData = (indexOfVisit) => (name, value, index) => (event) => {
    const { changeFileData } = this.props;

    changeFileData(name, value, index, indexOfVisit)(event);
  };

  onDragDropChange = (indexOfVisit) => (value) => {
    const { onDragDropChange } = this.props;

    onDragDropChange(value, indexOfVisit);
  };

  onDragDropFileChange = (indexOfVisit) => (value) => {
    const { onDragDropFileChange } = this.props;

    onDragDropFileChange(value, indexOfVisit);
  };

  render() {
    const {
      classes,
      changeData,
      invitation,
      invitationState,
      visitorTypes,
      visitClosed,
    } = this.props;
    const { documents, questionnaires, signed, feedback } =
      this.props.documents;
    const { invitations } = invitationState;
    const { selectedDocument, openSafetyTraining, openFeedback } = this.state;

    return (
      <Grid item>
        <div>
          <VmsDialogRowSelectArray
            rowClass={classes.dialogRow}
            iconLeft={<SupervisorAccount />}
            placeholder={i18n.t('invite_form_visitor_type')}
            onChange={changeData('visitorType')}
            inputName="visitorType"
            selectTypes={visitorTypes}
            selectedType={invitation.visitorType}
            mapOfItem={(visitorType) => visitorType.key}
            disabled={visitClosed}
            selectDataCy="invitation-select-group-visitor-type"
            itemDataCy="invitation-list-item-group-visitor-type"
          />
          {invitations.map((inv, index) => {
            const photoObject = inv.photo
              ? {
                  _id: inv.photo,
                  downloadUrl: inv.photoUrl,
                  name: i18n.t('invite_form_docs_photo'),
                }
              : null;

            let dragDropState =
              inv.dragDropState || createDragDropStateWithPhoto(photoObject);
            let dragDropFileState =
              inv.dragDropFileState || createDragDropState();
            const isVisitor = Auth.getUser().role === user_roles.VISITOR;
            const tenantIsSame = inv.tenantId
              ? Auth.getUser().tenantId === inv.tenantId
              : true;
            const userClosed = inv.user_status === user_status.CLOSED;

            return (
              <div key={`${inv.email}#${inv.phone}`}>
                <Divider />
                <VmsInput
                  rowClass={classes.dialogRow}
                  iconLeft={<PersonOutline />}
                  textValue={inv.name}
                  inputName="name"
                  placeholder={i18n.t('invite_form_name')}
                  inputLabel={i18n.t('invite_form_name')}
                  disabled={true}
                />
                <VmsDialogRowSelectArray
                  rowClass={classes.dialogRow2}
                  iconLeft={<SupervisorAccount />}
                  placeholder={i18n.t('invite_form_visitor_type')}
                  onChange={this.changeData(index)('visitorType')}
                  inputName="visitorType"
                  selectTypes={visitorTypes}
                  selectedType={inv.visitorType}
                  mapOfItem={(visitorType) => visitorType.key}
                  disabled={visitClosed}
                  selectDataCy="invitation-select-visitor-type"
                  itemDataCy="invitation-list-item-visitor-type"
                />
                <VmsDialogRowSelectCollection
                  disabled={userClosed || isVisitor || visitClosed}
                  rowClass={classes.dialogRow2}
                  iconLeft={<InsertDriveFile />}
                  placeholder={i18n.t('invite_form_add_document')}
                  onChange={this.changeData(index)}
                  inputName="documents-menu"
                  selectTypesA={documents}
                  selectTypesB={signed}
                  selectTypesC={questionnaires}
                  selectTypesD={feedback}
                  selectedTypeA={''}
                  selectedTypeB={''}
                  selectedTypeC={''}
                  selectedTypeD={''}
                  headerTypeA={i18n.t('invite_form_documents_documents')}
                  headerTypeB={i18n.t('invite_form_documents_signed')}
                  headerTypeC={i18n.t('invite_form_documents_safety_trainings')}
                  headerTypeD={i18n.t('invite_form_documents_feedback')}
                  inputDataCy="invitation-input-add-document"
                  itemTypeADataCy="invitation-list-item-document"
                  itemTypeBDataCy="invitation-list-item-signed"
                  itemTypeCDataCy="invitation-list-item-safety-training"
                  itemTypeDDataCy="invitation-list-item-feedback"
                />
                <VmsDialogRowDocumentCollection
                  documents={inv.documents}
                  onChange={this.changeData(index)}
                  onClick={this.handleOnClick}
                  disabled={userClosed || visitClosed}
                  itemDataCy="invitation-list-item-assigned-document"
                  textDocNameDataCy="invitation-text-assigned-document-name"
                  buttonRemoveDocDataCy="invitation-button-remove-assigned-document"
                />
                <VmsDialogDragDropFile
                  onChange={this.onDragDropFileChange(index)}
                  dragDropFileState={dragDropFileState}
                  disabled={userClosed || visitClosed}
                  buttonDataCy="invitation-button-drag-drop-file"
                  itemDataCy="invitation-list-item-drag-drop-file"
                />
                <VmsDialogRowDocumentCollection
                  documents={inv.personal_files}
                  onChange={this.changeFileData(index)}
                  onClick={this.handleOnClick}
                  disabled={userClosed || visitClosed}
                  itemDataCy="invitation-list-item-uploaded-document"
                  textDocNameDataCy="invitation-text-uploaded-document-name"
                  buttonRemoveDocDataCy="invitation-button-remove-uploaded-document"
                />
                <VmsDialogDragDropPhoto
                  onChange={this.onDragDropChange(index)}
                  dragDropState={dragDropState}
                  disabled={userClosed || visitClosed || !tenantIsSame}
                  buttonDataCy="invitation-button-drag-drop-photo"
                  itemDataCy="invitation-list-item-drag-drop-photo"
                />
                {openSafetyTraining && (
                  <SafetyResultDialog
                    document={selectedDocument}
                    openDialog={openSafetyTraining}
                    onClose={this.handleCloseDialog}
                  />
                )}
                {openFeedback && (
                  <FeedbackResultDialog
                    document={selectedDocument}
                    openDialog={openFeedback}
                    onClose={this.handleCloseDialog}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Grid>
    );
  }
}

GroupDocsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  invitationState: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  changeFileData: PropTypes.func.isRequired,
  onDragDropChange: PropTypes.func.isRequired,
  onDragDropFileChange: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  visitorTypes: PropTypes.array.isRequired,
  visitClosed: PropTypes.bool.isRequired,
};

export default withStyles(styles)(GroupDocsTab);
