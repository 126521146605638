import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';

const styles = (theme) => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  content: {
    fontSize: '3em',
    margin: theme.spacing(),
  },
});

let openSnackbarFn;

class SnackBar extends Component {
  state = {
    open: false,
    messageInfo: {
      messageData: {
        message: '',
        buttonMessage: undefined,
        onButtonClick: undefined,
      },
      key: 0,
    },
  };

  queue = [];

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = (message, buttonMessage, onButtonClick, variant, noClose) => {
    const { classes, enqueueSnackbar, closeSnackbar } = this.props;

    const key = enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: variant === 'big' ? 'center' : 'left',
      },
      persist: false,
      preventDuplicate: false, // This depends on situation
      autoHideDuration: noClose ? null : variant === 'big' ? 5000 : 10000,
      classes:
        variant === 'big'
          ? {
              root: classes.content,
            }
          : null,
      action: [
        buttonMessage ? (
          <Button
            key="undo"
            color="secondary"
            size="small"
            onClick={onButtonClick}
          >
            {buttonMessage}
          </Button>
        ) : null,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => closeSnackbar(key)}
        >
          <Close />
        </IconButton>,
      ],
    });
  };

  render() {
    return null;
  }
}

SnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  buttonMessage: PropTypes.string,
  onButtonClick: PropTypes.func,
  variant: PropTypes.string,
};

export function openSnackbar(
  message,
  buttonMessage,
  onButtonClick,
  variant,
  noClose
) {
  if (openSnackbarFn !== undefined)
    openSnackbarFn(message, buttonMessage, onButtonClick, variant, noClose);
}

export default withSnackbar(withStyles(styles)(SnackBar));
