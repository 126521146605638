import { withStyles, Grid } from '@material-ui/core';
import { date_formats } from 'AppSettings';
import commonStyle from 'assets/jss/commonStyles';

import VmsDatePicker from 'components/common/material-ui/VmsDatePicker';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const VmsDialogRowDatePicker = (props) => {
  const { rowClass, iconLeft, classes, onChange, placeholder, value } = props;
  const format = props.format || date_formats.full_date_long;
  let leftItem;
  if (iconLeft !== undefined) {
    leftItem = (
      <Grid item>
        <div className={classes.icon}>{iconLeft}</div>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {leftItem}
      <Grid item className={classes.rowContent}>
        <VmsDatePicker
          format={format}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(VmsDialogRowDatePicker);
