import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class InspectionServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'inspection/';
  }

  getActivities(tenantId) {
    const endpoint = this.endpoint + tenantId;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getDistinctActivityTypes() {
    const endpoint = this.endpoint + 'types/';

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getInspectionActivitiesForType(type) {
    const endpoint = this.endpoint + 'type/' + encodeURIComponent(type);

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  create(item) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(this.endpoint, item, config);
  }

  update(item) {
    const endpoint = this.endpoint + item._id;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.put(endpoint, item, config);
  }

  remove = (id) => {
    const endpoint = this.endpoint + id;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  };

  getActivitiesSuggestions = (searchValue) => {
    const endpoint = this.endpoint + 'suggestions/' + searchValue;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };
}

const InspectionService = new InspectionServiceImpl();

export default InspectionService;
