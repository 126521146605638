import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class ProcessEventServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'processEvent/';
  }

  getEvents(userId, page, limit) {
    const endpoint = `${this.endpoint}${userId}?page=${page}&limit=${limit}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }
}

const ProcessEventService = new ProcessEventServiceImpl();

export default ProcessEventService;
