import AbstractService from 'services/AbstractService';
import TemplateModel from 'services/models/TemplateModel';
import Auth from 'modules/Auth';
import objectPath from 'object-path';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { template_categories } from 'AppSettings';
import { Parser as HtmlToReactParser } from 'html-to-react';
import DOMPurify from 'dompurify';

const allowedNodes = ['div', 'table', 'style', 'img'];

const styleObjToCSS = (styleObj) =>
  Object.keys(styleObj).reduce((acum, style) => {
    return (
      style && styleObj[style] ? `${style}:${styleObj[style]}; ${acum}` : ''
    ).trim();
  }, '');

const nodeAttributesToObj = (attrs) => {
  const objAttrs = { style: null };
  for (let i = attrs.length - 1; i >= 0; i--) {
    if (attrs[i].name !== 'style') {
      if (attrs[i].name && attrs[i].value) {
        objAttrs[attrs[i].name] = attrs[i].value;
      }
    } else {
      const stylesInText = attrs[i].value.split(';');
      const styles = stylesInText.reduce((acum, style) => {
        const components = style.split(':');
        if (components[0] && components[1]) {
          acum[components[0]] = `${components[1]}`;
        }
        return acum;
      }, {});
      objAttrs.style = styles;
    }
  }
  return objAttrs;
};

function entityMapper(entity) {
  let type = entity.type;
  let data = { ...entity.data };

  if (type === 'IMAGE') {
    // added to support the existing image option in the editor
    type = 'IMG';
    data = { attributes: data, innerHTML: '' };
  }

  data.attributes = data.attributes ? data.attributes : {};
  let styleAsAttribute;
  if (data.attributes.style) {
    styleAsAttribute = styleObjToCSS(data.attributes.style);
  }

  const attributes = Object.keys(data.attributes).reduce(
    (acum, key) =>
      (key === 'style'
        ? `${key}="${styleAsAttribute}" ${acum}`
        : `${key}="${data.attributes[key]}" ${acum}`
      ).trim(),
    ''
  );

  const node = type.toLowerCase();
  if (allowedNodes.includes(node)) {
    return `<${node} ${attributes}>${data.innerHTML}</${node}>`;
  }
  return '';
}

function entityMapperToComponent(entity) {
  const htmlToReactParser = new HtmlToReactParser();
  return () =>
    htmlToReactParser.parse(DOMPurify.sanitize(entityMapper(entity)));
}

function customChunkRenderer(nodeName, node) {
  if (allowedNodes.includes(nodeName)) {
    let objAttrs = {};

    if (node.hasAttributes()) {
      objAttrs = nodeAttributesToObj(node.attributes);
    }

    return {
      type: nodeName.toString().toUpperCase(),
      mutability: 'MUTABLE',
      data: {
        // Pass whatever you want here (like id, or classList, etc.)
        innerText: node.innerText,
        innerHTML: node.innerHTML,
        attributes: objAttrs,
      },
    };
  }
  return null;
}

class TemplateServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'templates/';
  }
  /**
   * Create a template.
   */
  create(
    name,
    lang,
    category,
    type,
    template_title,
    template_body,
    is_default = false,
    required_for_entrance,
    expires_in,
    templateType
  ) {
    var templateData = new TemplateModel(
      null,
      name,
      lang,
      category,
      type,
      template_title,
      template_body,
      is_default,
      required_for_entrance,
      expires_in,
      templateType
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, templateData.toJSON(), config);
  }

  /**
   * Update a template.
   */
  update(
    id,
    name,
    lang,
    category,
    type,
    template,
    title,
    is_default,
    required_for_entrance,
    expires_in,
    templateType
  ) {
    var templateData = new TemplateModel(
      id,
      name,
      lang,
      category,
      type,
      template,
      title,
      is_default,
      required_for_entrance,
      expires_in,
      templateType
    );
    var endpoint = this.endpoint + id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.put(endpoint, templateData.toJSON(), config);
  }

  /**
   * Get all existing templates
   */
  getTemplates() {
    var endpoint = this.endpoint;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Find template by name or id
   * @param {string} templateIdOrName
   */
  getTemplate(templateIdOrName) {
    var endpoint = this.endpoint + templateIdOrName;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Find templates by category and type
   * @param {string} category
   * @param {string} type
   */
  getTemplatesByCategoryAndType(category, type) {
    var endpoint = this.endpoint + category + '/' + type;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getTemplatesByCategoryAndTypeForTenant(tenantId, category, type) {
    var endpoint = this.endpoint + tenantId + '/' + category + '/' + type;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Find templates by category and type
   * @param {string} category
   */
  getTemplatesByCategory(category) {
    var endpoint = this.endpoint + 'category/' + category;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Special endpoint for public site documents that are templates
   * @param {string} type
   */
  getPublicTemplateByType(type, lang = undefined) {
    var endpoint = 'public/documents/' + type;
    if (lang) {
      endpoint = endpoint + '?lang=' + lang;
    }
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getAssistantTemplates(role, lang) {
    const endpoint = `help/assistant?role=${role}&lang=${lang}`;

    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  suggestPUSHTemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.PUSH,
      searchValue
    );
  };

  suggestEMAILTemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.EMAIL,
      searchValue
    );
  };

  suggestTEMPLATETemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.TEMPLATE,
      searchValue
    );
  };

  suggestSIGNEDTemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.SIGNED,
      searchValue
    );
  };

  suggestSMSTemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.SMS,
      searchValue
    );
  };

  suggestAssistantTemplates = (searchValue) => {
    return this.suggestTemplatesForCategory(
      template_categories.ASSISTANT,
      searchValue
    );
  };

  suggestTemplatesForCategory(category, searchValue) {
    var endpoint =
      this.endpoint + 'category/' + category + '/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  /**
   * Removes a template
   * @param {string} id
   */
  deleteTemplate(id) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(this.endpoint + id, config);
  }

  replaceTemplate(template, data) {
    let updatedTemplate = JSON.parse(JSON.stringify(template));
    let dataObject = JSON.parse(JSON.stringify(data));
    updatedTemplate.body = this._compileLogic(template.body, dataObject);
    var params = this._getStringBetweenCurlyBrackets(template.body);

    for (var j = 0; j < params.length; j++) {
      const key = params[j];
      const value = objectPath.get(dataObject, key, null);
      if (value) {
        var re = new RegExp('{' + key + '}', 'g');
        updatedTemplate.body = updatedTemplate.body.replace(re, value);
      }
    }
    // Replace all unmatched occurences of {}
    const reclean = new RegExp('{([^}\\?]+)}', 'g');
    updatedTemplate.body = updatedTemplate.body.replace(reclean, '');
    return updatedTemplate;
  }

  replaceTemplates(templates, data) {
    let updatedTemplates = [];
    for (var i = 0; i < templates.length; i++) {
      updatedTemplates[i] = this.replaceTemplate(templates[i], data);
    }
    return updatedTemplates;
  }

  /**
   * Function that check if provided template contains provided parameter, and if so it takes
   * parameter value from it. It also replaces original parameter with value for plain parameter.
   * @param {String} parameterName Name of parameter we are looking for
   * @param {String} template Template which should contain parameter
   * @returns {Object} Object containing new template and parameter value.
   */
  getParameterValueFromTemplate(parameterName, template) {
    const startParam = '{' + parameterName + '=';
    const startIdx = template.indexOf(startParam) + startParam.length;
    const endIdx = template.indexOf('}', startIdx);
    // if parameter with value syntax is found
    if (startIdx > -1 && endIdx > startIdx) {
      const paramValue = template.substring(startIdx, endIdx);
      const fullParameter = startParam + paramValue + '}';
      const newParameter = '{' + parameterName + '}';
      const newTemplate = template.replace(fullParameter, newParameter);
      // return object with parameter value and updated template
      return {
        value: paramValue,
        template: newTemplate,
      };
    }
    // else return object with empty parameter value and original template
    return {
      value: '',
      template: template,
    };
  }

  _getStringBetweenCurlyBrackets(string) {
    var found = []; // an array to collect the strings that are found
    var rxp = /{([^}]+)}/g;
    var curMatch;

    while ((curMatch = rxp.exec(string))) {
      found.push(curMatch[1]);
    }
    return found;
  }

  createEditorFromHtml(html) {
    const contentBlock = htmlToDraft(html, customChunkRenderer);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  }

  // convert draft editor content to valid HTML
  draftToHtml(editorContent) {
    return draftToHtml(convertToRaw(editorContent), null, false, entityMapper);
  }

  createEmpty() {
    return EditorState.createEmpty();
  }

  // https://stackoverflow.com/questions/5796718/html-entity-decode
  replaceHtmlSpecialChars = (template) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = template;
    return textArea.value.length > 100
      ? textArea.value.substring(0, Math.min(textArea.value.length, 100)) +
          '...'
      : textArea.value;
  };

  customBlockRenderFunc = (block, config) => {
    if (block.getType() === 'atomic') {
      const contentState = config.getEditorState().getCurrentContent();
      if (block.getEntityAt(0)) {
        const entity = contentState.getEntity(block.getEntityAt(0));
        return {
          component: entityMapperToComponent(entity),
          editable: false,
          props: {
            children: () => entity.innerHTML,
          },
        };
      }
    }
    return undefined;
  };

  //second part of the huge regex
  //https://stackoverflow.com/questions/6711971/regular-expressions-match-anything
  //https://www.sitepoint.com/community/t/javascript-regex-making-dot-match-new-lines/1839/2
  _compileLogic(text, data) {
    const parseExp = new RegExp(
      '(({\\?(\\s*)(\\w+)(\\s*)\\(([^{}]+)\\)(\\s*)})(?:\\1??[\\S\\s]*?({\\/\\?})))+',
      'gm'
    );
    const commandExp = new RegExp(
      '({\\?(\\s*)(\\w+)(\\s*)\\(([^{}]+)\\)(\\s*)})',
      'gm'
    );
    const endCmdExp = new RegExp('({\\/\\?})');

    let matchedExp = text.match(parseExp);
    let ignored = [];
    while (matchedExp && matchedExp.length > 0) {
      for (let i = 0; i < matchedExp.length; i++) {
        let textBlock = matchedExp[i];
        let commandStr = textBlock.match(commandExp)[0];
        let indexOfEnd = textBlock.search(endCmdExp);
        let plainText = textBlock.substring(commandStr.length, indexOfEnd);

        try {
          text = text.replace(
            textBlock,
            this._parseCommand(commandStr, plainText, data)
          );
        } catch (err) {
          ignored.push(textBlock);
          console.error(err);
        }
      }

      matchedExp = text.match(parseExp);
      if (matchedExp && matchedExp.length > 0 && ignored.length > 0) {
        matchedExp = matchedExp.filter(function (x) {
          return ignored.indexOf(x) < 0;
        });
      }
    }
    return text;
  }

  _parseCommand(commandStr, plainText, data) {
    let command = commandStr
      .substring(commandStr.indexOf('?') + 1, commandStr.indexOf('('))
      .trim();
    let bool = this._parseCmdArgs(
      commandStr.substring(
        commandStr.indexOf('(') + 1,
        commandStr.indexOf(')')
      ),
      data
    );

    switch (command) {
      case 'if':
        if (bool) return plainText;
        return '';
      default:
        return '';
    }
  }

  _parseCmdArgs(cmdArgsString, data) {
    const operatorExp = new RegExp('(==|[><!][=]*)', 'gm');
    let operator = cmdArgsString.match(operatorExp)[0];
    let operatorIndex = cmdArgsString.search(operatorExp);

    let firstVal = cmdArgsString.substring(0, operatorIndex).trim();
    let secondVal = this._getSecondValValue(
      cmdArgsString.substring(operatorIndex + operator.length).trim()
    );

    let objectVal = objectPath.get(data, firstVal, null);

    switch (operator) {
      case '==':
        return objectVal === secondVal;
      case '>':
        return objectVal > secondVal;
      case '<':
        return objectVal < secondVal;
      case '>=':
        return objectVal >= secondVal;
      case '<=':
        return objectVal <= secondVal;
      case '!=':
        return objectVal !== secondVal;
      default:
        throw new Error('Invalid condition!');
    }
  }

  _getSecondValValue(val) {
    if (val === 'true') {
      return true;
    } else if (val === 'false') {
      return false;
    } else if (
      (val.startsWith("'") && val.endsWith("'")) ||
      (val.startsWith('"') && val.endsWith('"'))
    ) {
      return val.substring(1, val.length - 1);
    } else {
      let number = Number(val);
      if (isNaN(number)) {
        throw new Error('Invalid value!');
      }
      return number;
    }
  }

  getCompanyData = (tenantId) => {
    var endpoint = this.endpoint + 'companyData/' + (tenantId ? tenantId : '');
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  findNotificationTemplateByLanguage = (
    templates,
    userLanguage,
    companyLanguage
  ) => {
    let template;

    if (userLanguage) {
      template = templates.find((tmp) => tmp.lang === userLanguage);
    }

    if (!template && companyLanguage) {
      template = templates.find((tmp) => tmp.lang === companyLanguage);
    }

    if (!template) {
      template = templates.find((tmp) => tmp.lang === 'en');
    }

    if (!template) {
      template = templates[0];
    }

    return template;
  };

  getDistinctTypesForSignedDocuments() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint + 'signedTypes', config);
  }

  getSignedDocumentsForUser(userId) {
    const endpoint = this.endpoint + 'findDocumentByTypeAndLanguage/' + userId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }
}

const TemplateService = new TemplateServiceImpl();

export default TemplateService;
