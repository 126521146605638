import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';

const VmsLocalize = ({ children }) => {
  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      libInstance={moment}
      locale={i18n.language}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default VmsLocalize;
