import { withStyles, Button, Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '../../../../assets/i18n';

const styles = (theme) => ({
  container: {
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
  },
  button: {
    color: theme.palette.common.white,
  },
  secondGrid: {
    width: 'unset',
  },
});

const VmsDialogFooter = (props) => {
  const {
    classes,
    onSave,
    saveDisabled,
    onSend,
    sendDisabled,
    onDelete,
    deleteDisabled,
    deleteText,
    onConfirm,
    confirmText,
    buttonSaveDataCy,
    buttonSendDataCy,
    buttonDeleteDataCy,
  } = props;
  return (
    <Grid
      className={classes.container}
      container
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        {onConfirm && (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={onConfirm}
          >
            {confirmText ? confirmText : i18n.t('confirm')}
          </Button>
        )}
      </Grid>
      <Grid item>
        {onDelete && (
          <Button
            data-cy={buttonDeleteDataCy}
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={onDelete}
            disabled={deleteDisabled}
          >
            {deleteText ? deleteText : i18n.t('delete')}
          </Button>
        )}
      </Grid>
      <Grid
        className={classNames(classes.container, classes.secondGrid)}
        item
        container
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item>
          {onSend && (
            <Button
              data-cy={buttonSendDataCy}
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={onSend}
              disabled={sendDisabled}
            >
              {i18n.t('send')}
            </Button>
          )}
        </Grid>
        <Grid item>
          {onSave && (
            <Button
              data-cy={buttonSaveDataCy}
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={onSave}
              disabled={saveDisabled}
            >
              {props.buttonText ? props.buttonText : i18n.t('save')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

VmsDialogFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  saveDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  onDelete: PropTypes.func,
  deleteText: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  onCofirmation: PropTypes.func,
  confirmDisabled: PropTypes.bool,
  confirmText: PropTypes.string,
  buttonSaveDataCy: PropTypes.string,
  buttonSendDataCy: PropTypes.string,
  buttonDeleteDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsDialogFooter);
