import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class PeripheralsServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'peripherals';
  }

  getPeripheralStatuses() {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  createDevice(data) {
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.post(this.endpoint, data, config);
  }

  deleteDevice(id) {
    var endpoint = 'peripherals/' + id;
    const headers = {
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  getDeviceSuggestions = (searchValue) => {
    var endpoint = 'peripherals/suggestions/' + searchValue;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  };
}

const PeripheralsService = new PeripheralsServiceImpl();

export default PeripheralsService;
