import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalendarToolbar from './CalendarToolbar';
import CalendarMonthHeader from './CalendarMonthHeader';
import CalendarMonthDateHeader from './CalendarMonthDateHeader';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'assets/css/calendar.css';
import moment from 'moment';
// imported 'sk' because moment needs every used language (except 'en') imported separatly
import 'moment/locale/sk';
import { accessor } from 'react-big-calendar/lib/utils/accessors';

const styles = () => ({
  calendar: {
    '& .rbc-event-content': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
    '& .rbc-header span': {
      whiteSpace: 'normal',
    },
    '& .rbc-header + .rbc-header': {
      margin: 0,
    },
    '& .rbc-month-view .rbc-off-range-bg': {
      backgroundColor: 'transparent',
    },
    '& .rbc-month-view .rbc-today': {
      backgroundColor: 'transparent',
    },
  },
});

//https://github.com/jquense/react-big-calendar/issues/118
// remember the browser's local timezone, as it might by used later on
Calendar.tz = moment.tz.guess();
// format all dates in Calendar as they would be rendered in browser's local timezone (even if later on they won't)
const m = (...args) => moment.tz(...args, Calendar.tz);
m.localeData = moment.localeData;

const localizer = momentLocalizer(m);

export const convertDateTimeToDate = (datetime, timezone) => {
  const m = moment.tz(datetime, timezone);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

export const convertDateToDateTime = (date) => {
  const dateM = moment.tz(date, Calendar.tz);
  return moment.tz(
    {
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: dateM.hour(),
      minute: dateM.minute(),
    },
    Calendar.tz
  );
};

class CalendarComponent extends Component {
  eventStyleGetter = (event, start, end, isSelected) => {
    // use event "bgColor" property as backgroundColor if it was set
    // else use pre-defined blueish color
    const bgColor = event.bgColor ? event.bgColor : '#3174ad';
    var style = {
      backgroundColor: bgColor,
    };
    return {
      style: style,
    };
  };

  static defaultProps = {
    startAccessor: 'start',
    endAccessor: 'end',
  };

  startAccessor = (event) => {
    const start = accessor(event, this.props.startAccessor);
    return convertDateTimeToDate(start, this.props.timezone);
  };

  endAccessor = (event) => {
    const end = accessor(event, this.props.endAccessor);
    return convertDateTimeToDate(end, this.props.timezone);
  };

  render() {
    const {
      classes,
      onSelectEvent,
      onSelectSlot,
      onNavigate,
      onView,
      events,
      date,
      view,
      timezone,
      onRangeChange,
      ...props
    } = this.props;
    const n = moment.tz(new Date(), timezone); // CREATE NOW IN TZ
    // GENERATE A FAKE TIME IN LOCAL TIMEZONE
    const getNow = () =>
      new Date(n.year(), n.month(), n.date(), n.hour(), n.minute(), n.second());
    const bigCalendarProps = {
      ...props,
      startAccessor: this.startAccessor,
      endAccessor: this.endAccessor,
      onSelectSlot:
        onSelectSlot &&
        (({ start, end, slots }) => {
          onSelectSlot({
            start: convertDateToDateTime(start),
            end: convertDateToDateTime(end),
            slots: slots.map((date) => convertDateToDateTime(date)),
          });
        }),
    };

    return (
      <Calendar
        localizer={localizer}
        className={classes.calendar}
        {...bigCalendarProps}
        selectable
        components={{
          toolbar: CalendarToolbar,
          month: {
            header: CalendarMonthHeader,
            dateHeader: CalendarMonthDateHeader,
          },
        }}
        views={['month', 'week', 'day']}
        eventPropGetter={this.eventStyleGetter}
        defaultDate={getNow()}
        defaultView="month"
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onNavigate={onNavigate}
        onRangeChange={onRangeChange}
        onView={onView}
        events={events}
        date={date}
        view={view}
        getNow={getNow}
      />
    );
  }
}

CalendarComponent.propTypes = {
  onSelectEvent: PropTypes.func.isRequired,
  onSelectSlot: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  date: PropTypes.object,
  view: PropTypes.string,
};

export default withStyles(styles)(CalendarComponent);
