import { Component } from 'react';
import PropTypes from 'prop-types';
import { elevation, colors } from 'assets/UISettings';
import {
  Grid,
  Paper,
  Button,
  withStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  TableHead,
  TableSortLabel,
  InputAdornment,
  IconButton,
  Tooltip,
  Hidden,
  Menu,
  MenuItem,
  Badge,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import TodayIcon from '@material-ui/icons/Today';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Delete from '@material-ui/icons/Delete';
import Search from '@material-ui/icons/Search';
import Send from '@material-ui/icons/Send';
import striptags from 'striptags';
import i18n from 'assets/i18n';
import moment from 'moment-timezone';
import {
  VmsAutocomplete,
  VmsExportLink,
  VmsAvatar,
  SendNotificationDialog,
  VmsLocalize,
  VmsDrawer,
  VmsButtonFAB,
  TemplateEditor,
  FilterIconButton,
  VisitFilterMenu,
  InspectionDialog,
} from 'components';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { Calendar } from '@material-ui/pickers';
import { TimePicker } from '@material-ui/pickers';
import {
  visit_event_status_constants,
  visit_visitor_status_constants,
  template_categories,
  routes,
  isiOSMobileApp,
  vms_application_routes,
  time_configs,
  template_types,
  findCheckedInspectionForDate,
  notification_type,
  date_formats,
} from 'AppSettings';
import InviteVisitor from './visitor/InviteVisitor';
import {
  UserService,
  TemplateService,
  VisitService,
  ExportService,
  PushNotificationService,
  EmailService,
  SmsService,
} from 'services';
import Aux from 'hoc/Auxiliary';
import commonStyles from 'assets/jss/commonStyles';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { handleOpenConfirmDialog } from '../../components/common/dialogs/ConfirmDialog';
import Auth from 'modules/Auth';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import QRDialogReader from 'containers/meetingRoom/QRDialogReader';
import PorterDialog, {
  createEmptyPorterState,
  createPorterState,
} from '../../components/common/dialogs/porter/PorterDialog';
import { Announcement } from '@material-ui/icons';
import classNames from 'classnames';
import emptyImage from 'assets/jss/emptyImage';

import Visit from 'assets/images/Visit.jpg';
import produce from 'immer';
import ItineraryAssistantDialogs from 'components/visitor/ItineraryAssistantDialogs';
import locales from 'assets/momentlocales';
import ProcessEventService from 'services/ProcessEventService';
const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    position: 'relative',
  },
  paperTable: {
    height: 'calc(100vh - 140px)',
    overflow: 'auto',
  },
  addButton: {
    position: 'absolute',
    bottom: theme.spacing(7),
    right: theme.spacing(7),
    color: theme.palette.common.white,
  },
  tableCellVisitor: {
    padding: 10,
    maxWidth: 200,
  },
  tableCellPhoto: {
    width: 55,
    padding: 10,
  },
  tableCellTimePicker: {
    width: 100,
    color: colors.orangeYellow,
  },
  tableCellRight: {
    maxWidth: 100,
    padding: 10,
    textAlign: 'center',
  },
  tableCellRightLastChild: {
    right: 0,
    textAlign: 'right',
  },
  tableCellRightLastChildMore: {
    maxWidth: theme.spacing(11),
    right: 0,
    textAlign: 'right',
  },
  tableCellBodyRightLastChild: {
    paddingRight: theme.spacing(),
    textAlign: 'right',
  },
  tableRow: {
    height: 48,
  },
  tableCellCheckbox: {
    width: 21,
  },
  tableIcon: {
    color: theme.palette.primary4,
  },
  tableCellFont: {
    fontWeight: 500,
    color: colors.brownishGrey,
  },
  tableCellFontButton: {
    fontWeight: 500,
    color: colors.orangeYellow,
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    lineHeight: '1.71429em',
    opacity: 1,
    textAlign: 'center',
  },
  table: {
    width: '100%',
  },
  tableHeader: {
    backgroundColor: colors.greyFour,
    color: theme.palette.common.white,
  },
  QRbutton: {
    position: 'fixed',
    margin: 0,
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    top: 'auto',
    right: 'auto',
    color: theme.palette.common.white,
  },
  emergencyButton: {
    color: theme.palette.common.white,
  },
  emergencyContainer: {
    minHeight: '140px',
  },
  emergencyButtonContainer: {
    marginBottom: '8px',
  },
  requiredIconPending: {
    color: theme.palette.primary.main,
  },
  emptyView: {
    height: 'calc(100vh - 20vh)',
    display: 'flex',
    width: '100%',
  },
  emptyViewImage: emptyImage(Visit),
  assistant: {
    display: 'block',
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  sortCell: {
    display: 'flex',
  },
  floatingButton: {
    color: 'white',
    position: 'absolute',
    zIndex: 5,
    left: 40,
    bottom: 40,
  },
  phoneButtons: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary4,
  },
  visitContent: {
    flex: '1 1 100%', // Default to full width
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '1 1 66.6667%', // 8 columns out of 12
      maxWidth: '66.6667%',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '1 1 75%', // 9 columns out of 12
      maxWidth: '75%',
    },
  },
  calendarWrapper: {
    flex: '0 0 100%', // Default to full width
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      // md breakpoint
      flex: '0 0 33.3333%', // 4 columns out of 12
      maxWidth: '33.3333%',
    },
    [theme.breakpoints.up('lg')]: {
      // lg breakpoint
      flex: '0 0 25%', // 3 columns out of 12
      maxWidth: '25%',
    },
  },
  visitPageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'scroll',
  },
  '@global': {
    '*': {
      scrollbarWidth: 'thin', // "auto" or "thin"
      scrollbarColor: '#888 #f1f1f1', // thumb color and track color
    },
  },
});

class VisitorPage extends Component {
  constructor(props) {
    super(props);
    const isPorter = Auth.isUserPorter();
    this.state = {
      drawerOpen: false,
      DialogOpenCalendarMd: false,
      DialogOpenEmergencyEmailMd: false,
      visitors: [],
      filteredVisitors: [],
      page: 0,
      rowsPerPage: 5,
      selected: [],
      order: 'asc',
      orderBy: 'timeFrom',
      statusFilter: {
        invited: true,
        confirmed: true,
        closed: true,
      },
      purposeFilter: {
        undefined: true,
        option1: true,
        option2: true,
      },
      otherFilter: {
        related: false,
      },
      selectedDate: moment(),
      selectedSignedOutTime: null,
      selectedSignedInTime: null,
      visitorSuggestionValue: '', // value of meeting search field
      userSuggestions: [], // suggestions for user search
      highlightedId: '',
      menuAnchorElements: {
        // anchorEl when documents or other menus in visitor dialog is opened/closed
        visitMenu: false,
        inviteDialog: false,
      },
      currentMenuItem: {}, // Currently selected visit in table
      selectedVisit: null,
      editAsGroupInvite: false, // If selectedVisit is being edited as group invite
      notificationTemplates: [], // array of all existing notification templates
      sendNotificationDialogOpen: false,
      sendNotificationDialogState: {
        selectedCategory: '',
        selectedTemplate: '',
        sendingNotification: false,
      },
      qrDialogState: {
        QRResult: i18n.t('meeting_room_insert_qrcode'),
        isSearch: false,
        openQRDialog: false,
        user: {},
      },
      porterState: isPorter ? createEmptyPorterState() : {},
      emergencyState: {
        modalOpen: false,
        templateState: {
          name: '',
          recipient: '',
          recipientArray: [],
          title: '',
          selectedValue: null,
          editor: TemplateService.createEmpty(),
          sendingEmail: false,
          recipientObject: null,
          lang: null, // TODO currently unused, might changed druing multitenancy
          disabledLang: true,
          bulk: true,
        },
        emergencyTemplates: [],
        emergencyTemplatesSelectItems: { key: [], value: [] },
        category: '',
      },
      inspection: {
        inspectionOpen: false,
        inspectionVisit: {},
        inspectionActivityConfirmed: false,
      },
      itinerary: {
        itineraryOpen: false,
        itineraryVisit: {},
      },
    };
  }

  componentWillMount() {
    // Restore state after back from print badge
    if (this.props.location.state) {
      let state = this.props.location.state;
      this.setState((prevState) => {
        return {
          selectedDate: moment(state.selectedDate) || moment(),
          order: state.order || 'asc',
          orderBy: state.orderBy || 'timeFrom',
          statusFilter:
            state.statusFilter !== undefined
              ? state.statusFilter
              : prevState.statusFilter,
          purposeFilter:
            state.purposeFilter !== undefined
              ? state.purposeFilter
              : prevState.purposeFilter,
          otherFilter:
            state.otherFilter !== undefined
              ? state.otherFilter
              : prevState.otherFilter,
        };
      });
    }
  }

  componentDidMount() {
    this.props.bus.on('toggleDrawer', this.busToggleDrawer);
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.props.bus.on('handleReloadData', this.busHandleReloadData);
    this.loadData();
    this.interval = setInterval(
      () => this.loadData(),
      time_configs.REFRESH_DATA
    );
    if (Auth.isUserPorter()) {
      UserService.getUser(Auth.getUser()._id)
        .then((user) => {
          this.setState((prevState) => {
            prevState.qrDialogState.user = user;
            return prevState;
          });
          if (
            isiOSMobileApp() === true &&
            user.lockUrl &&
            !user.lockUrl.startsWith('http:')
          ) {
            setTimeout(() => {
              window.location =
                vms_application_routes.CONNECT_TO_BLE_LOCK + user.lockUrl;
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    this.props.bus.off('toggleDrawer', this.busToggleDrawer);
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.props.bus.off('handleReloadData', this.busHandleReloadData);
    clearInterval(this.interval);
  }

  busToggleDrawer = (data) => {
    if (data.pathname === routes.VISITORS) this.toggleDrawer(data.open)();
  };

  busHandleAddClick = (pathname) => {
    if (pathname === routes.VISITORS) this.handleOpenInviteDialog();
  };

  busHandleReloadData = (page) => {
    if (page === routes.VISITORS) this.loadData();
  };

  loadData = () => {
    this.loadVisitors(this.state.selectedDate);
    this.loadTemplates();
  };

  toggleDrawer = (open) => () => {
    this.setState({
      drawerOpen: open,
    });
  };

  loadVisitors = (date) => {
    VisitService.getListOfVisitorsByDate(date)
      .then((response) => {
        //TODO remove after visits are in separate invites
        const visitors = response.map((visitor) => {
          const signedInEvent = visitor.events.find((e) => {
            const eventDate = moment(e.eventTimestamp);
            return (
              e.type === visit_event_status_constants.SIGNED_IN &&
              eventDate.isSame(date, 'day')
            );
          });
          const signedOutEvent = visitor.events.find((e) => {
            const eventDate = moment(e.eventTimestamp);
            return (
              e.type === visit_event_status_constants.SIGNED_OUT &&
              eventDate.isSame(date, 'day')
            );
          });
          // if (response) {
          //   response.forEach((element) => {
          //     ProcessEventService.getEvents(element.user._id, 0, 20).then(
          //       (val) => {
          //         const Emergency = val.filter((e) => {
          //           const eventDate = moment(e.created_date);
          //           return (
          //             e.type === visit_event_status_constants.EMERGENCY &&
          //             eventDate.isSame(date, 'day')
          //           );
          //         });
          //         for (const element of Emergency) {
          //           visitor.events.push(element);
          //         }
          //       }
          //     );
          //   });
          // }

          //Add also active field so we dont have to recalculate
          visitor.status = signedOutEvent
            ? 'closed'
            : signedInEvent
            ? 'confirmed'
            : 'invited';
          return visitor;
        });
        console.log('visitors', visitors);
        const filteredVisitors = this.filterVisits(visitors);

        this.setState({
          visitors,
          filteredVisitors,
        });
      })
      .catch(function (error) {
        openSnackbar(error.message);
        console.log(error);
      });
  };

  loadTemplates = () => {
    const self = this;
    TemplateService.getTemplates()
      .then(function (response) {
        self.setState({
          notificationTemplates: response,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleVisitorStateChange = (porterState) => {
    this.setState({
      porterState: porterState,
    });
    this.loadVisitors(this.state.selectedDate);
  };

  isSelected = (item) =>
    this.state.selected.findIndex((i) => i._id === item._id) !== -1;

  handleSelectAllRowsClick = (checked, visibleVisitors) => {
    if (checked) {
      this.setState((state) => ({
        selected: visibleVisitors.map((visitor) => visitor),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClickRow = (event, item) => {
    if (Auth.isUserPorter()) {
      return;
    }
    const { selected } = this.state;
    const selectedIndex = selected.findIndex((i) => item._id === i._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected, highlightedId: '' });
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    handleOpenConfirmDialog(
      i18n.t('dashboard_visitors_dialog_remove_selected_title'),
      i18n.t('dashboard_visitors_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => async (event) => {
    const { selected, selectedDate } = this.state;

    const promises = selected.map((visit) =>
      VisitService.deleteVisit(visit._id)
    );
    try {
      await Promise.all(promises);
      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.selected = [];
        })
      );
      openSnackbar(i18n.t('removed'));
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    } finally {
      this.loadVisitors(selectedDate);
    }
  };

  handleDeleteVisit = async (visit, editAsGroupInvite) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.menuAnchorElements.visitMenu = null;
      })
    );

    try {
      if (!editAsGroupInvite) {
        await VisitService.deleteVisit(visit._id);
      } else {
        await VisitService.deleteGroupVisits(visit.group);
      }
      openSnackbar(i18n.t('removed'));
      this.loadData();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  deleteVisitOpenDialog = (visit, editAsGroupInvite) => {
    handleOpenConfirmDialog(
      i18n.t('dashboard_visitors_dialog_remove_title'),
      i18n.t('dashboard_visitors_dialog_remove_description'),
      () => this.handleDeleteVisit(visit, editAsGroupInvite)
    );
  };

  handleDeleteVisitOpenDialog = (visit) => (event) => {
    if (visit.group) {
      handleOpenConfirmDialog(
        i18n.t('dashboard_visitors_dialog_group_remove_title'),
        i18n.t('dashboard_visitors_dialog_group_remove_description'),
        () => this.deleteVisitOpenDialog(visit, true),
        () => this.deleteVisitOpenDialog(visit, false)
      );
    } else {
      this.deleteVisitOpenDialog(visit, false);
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({
      order: order,
      orderBy: orderBy,
    });
  };

  getSorting = (a, b) => {
    const { order, orderBy } = this.state;
    if (orderBy === 'timeFrom') {
      return order === 'desc'
        ? new Date(b[orderBy]).getTime() < new Date(a[orderBy]).getTime()
          ? -1
          : 1
        : new Date(a[orderBy]).getTime() < new Date(b[orderBy]).getTime()
        ? -1
        : 1;
    } else if (orderBy === visit_event_status_constants.SIGNED_IN) {
      return this.sortingSignedInOutHlpFunction(
        a,
        b,
        order,
        visit_event_status_constants.SIGNED_IN
      );
    } else if (orderBy === visit_event_status_constants.SIGNED_OUT) {
      return this.sortingSignedInOutHlpFunction(
        a,
        b,
        order,
        visit_event_status_constants.SIGNED_OUT
      );
    } else if (orderBy === 'visitor') {
      return order === 'desc'
        ? this.alphabeticalSort(b.user.name, a.user.name)
        : this.alphabeticalSort(a.user.name, b.user.name);
    } else if (orderBy === 'status') {
      return this.sortingStatus(a, b, order);
    } else if (orderBy === 'purpose') {
      return order === 'desc'
        ? this.alphabeticalSort(b.purpose, a.purpose)
        : this.alphabeticalSort(a.purpose, b.purpose);
    } else if (orderBy === 'group') {
      return order === 'desc'
        ? this.alphabeticalSort(b.group, a.group)
        : this.alphabeticalSort(a.group, b.group);
    }
    return order === 'desc'
      ? new Date(b[orderBy]).getTime() < new Date(a[orderBy]).getTime()
        ? -1
        : 1
      : new Date(a[orderBy]).getTime() < new Date(b[orderBy]).getTime()
      ? -1
      : 1;
  };

  alphabeticalSort = (a = '', b = '') => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  };

  sortingStatus = (a, b, order) => {
    const { selectedDate } = this.state;

    let aTodayEvents = a.events.filter((e) => {
      let eventTimestamp = moment(e.eventTimestamp);
      return eventTimestamp.isSame(selectedDate, 'day');
    });
    let bTodayEvents = b.events.filter((e) => {
      let eventTimestamp = moment(e.eventTimestamp);
      return eventTimestamp.isSame(selectedDate, 'day');
    });

    if (aTodayEvents.length === 0) {
      if (bTodayEvents.length === 0) {
        //compare by date
        return order === 'desc'
          ? new Date(b).getTime() < new Date(a).getTime()
            ? -1
            : 1
          : new Date(a).getTime() < new Date(b).getTime()
          ? -1
          : 1;
      } else {
        //a win
        return order === 'desc' ? 1 : -1;
      }
    } else {
      if (bTodayEvents.length === 0) {
        //b win
        return order === 'desc' ? -1 : 1;
      } else {
        //by alphabetic
        return order === 'desc'
          ? this.statusSort(b.status, a.status)
          : this.statusSort(a.status, b.status);
      }
    }
  };

  statusSort = (a, b) => {
    if (a === visit_visitor_status_constants.INVITED) {
      return -1;
    } else if (
      a === visit_visitor_status_constants.CONFIRMED &&
      b === visit_visitor_status_constants.CLOSED
    ) {
      return -1;
    }
    return 1;
  };

  sortingSignedInOutHlpFunction = (a, b, order, orderByID) => {
    if (
      a.events.find((e) => {
        return e.type === orderByID;
      }) !== undefined
    ) {
      if (
        b.events.find((e) => {
          return e.type === orderByID;
        }) !== undefined
      ) {
        return order === 'desc'
          ? new Date(
              b.events.find((e) => {
                return e.type === orderByID;
              }).eventTimestamp
            ).getTime() <
            new Date(
              a.events.find((e) => {
                return e.type === orderByID;
              }).eventTimestamp
            ).getTime()
            ? -1
            : 1
          : new Date(
              a.events.find((e) => {
                return e.type === orderByID;
              }).eventTimestamp
            ).getTime() <
            new Date(
              b.events.find((e) => {
                return e.type === orderByID;
              }).eventTimestamp
            ).getTime()
          ? -1
          : 1;
      } else {
        return -1;
      }
    } else {
      return 1;
    }
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: moment(date),
      visitorSuggestionValue: '',
      visitingSuggestionValue: '',
      highlightedId: '',
    });
    this.loadVisitors(date);
  };

  handleVisitorSuggestionsClearRequested = () => (event) => {
    /*this.setState({
     visitorSuggestions: [],
     });*/
  };

  handleVisitingSuggestionsClearRequested = () => (event) => {
    /*this.setState({
     visitorSuggestions: [],
     });*/
  };

  handleVisitorSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.setState({
        selectedDate: moment(suggestion.timeFrom),
        highlightedId: suggestion._id,
        visitorSuggestionValue:
          suggestion.user.name +
          ', ' +
          i18n.t('visiting') +
          ' ' +
          suggestion.host.name +
          ', ' +
          moment(suggestion.timeFrom).format('L'),
      });
      this.loadVisitors(suggestion.timeFrom);
    }
  };

  handleVisitingSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.setState({
        selectedDate: moment(suggestion.timeFrom),
        highlightedId: suggestion._id,
        visitingSuggestionValue:
          suggestion.host.name +
          ', ' +
          i18n.t('visitor') +
          ' ' +
          suggestion.user.name +
          ', ' +
          moment(suggestion.timeFrom).format('L'),
      });
      this.loadVisitors(suggestion.timeFrom);
    }
  };

  handleVisitorSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (
      field === 'searchVisitors' &&
      this.state.visitorSuggestionValue !== fieldValue
    ) {
      this.setState({
        visitorSuggestionValue: fieldValue ? fieldValue : '',
        visitingSuggestionValue: '',
      });
    }
  };

  handleVisitingSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (
      field === 'searchVisiting' &&
      this.state.visitingSuggestionValue !== fieldValue
    ) {
      this.setState({
        visitorSuggestionValue: '',
        visitingSuggestionValue: fieldValue ? fieldValue : '',
      });
    }
  };

  handlePrintBadge = (visit) => (event) => {
    this.setState(function (prevState) {
      prevState.menuAnchorElements['visitMenu'] = null;
      return prevState;
    });
    // Inspired by https://stackoverflow.com/questions/47495696/react-router-how-to-restore-state-after-browser-back-button
    this.props.history.replace(routes.VISITORS, {
      selectedDate: this.state.selectedDate.valueOf(),
      order: this.state.order,
      orderBy: this.state.orderBy,
      statusFilter: this.state.statusFilter,
      purposeFilter: this.state.purposeFilter,
      otherFilter: this.state.otherFilter,
    });
    this.props.history.push(routes.PRINT_VISIT, { visit: visit });
  };

  editVisit = (visit, editAsGroupInvite) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.menuAnchorElements.visitMenu = null;
        draft.selectedVisit = visit;
        draft.editAsGroupInvite = editAsGroupInvite;
      })
    );
    this.handleOpenInviteDialog();
  };

  handleEditVisit = (visit) => (event) => {
    if (visit.group) {
      handleOpenConfirmDialog(
        i18n.t('dashboard_visitors_dialog_group_edit_title'),
        i18n.t('dashboard_visitors_dialog_group_edit_description'),
        () => this.editVisit(visit, true),
        () => this.editVisit(visit, false)
      );
    } else {
      this.editVisit(visit, false);
    }
  };

  handleShowVisit = (visit) => (event) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.porterState = createPorterState(prevState.porterState, visit);
        draft.menuAnchorElements.visitMenu = null;
      })
    );
    this.handleOpenVisitInfoDialog();
  };

  handleOpenInviteDialog = () => {
    this.setState((prevState) => {
      const menuAnchorElements = { ...prevState.menuAnchorElements };
      menuAnchorElements.inviteDialog = true;
      return { menuAnchorElements };
    });
  };

  handleCloseInviteDialog = () => {
    this.setState((prevState) => {
      const menuAnchorElements = { ...prevState.menuAnchorElements };
      menuAnchorElements.inviteDialog = false;
      return { menuAnchorElements, selectedVisit: null };
    });
  };

  handleSaveInviteDialog = () => {
    this.loadVisitors(this.state.selectedDate);
  };

  handleOpenVisitInfoDialog = () => {
    this.setState(function (prevState) {
      prevState.porterState.open = true;
      return prevState;
    });
  };

  handleOpenMenu = (menu, item) => (event) => {
    let target = event.currentTarget;
    this.setState(function (prevState) {
      prevState.menuAnchorElements[menu] = target;
      prevState.currentMenuItem = item ? item : {};
      return prevState;
    });
  };

  handleCloseMenu = (menu) => (event) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.menuAnchorElements[menu] = null;
      })
    );
  };

  handleMenuClosed = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.currentMenuItem = {};
      })
    );
  };

  handleShowSendNotificationDialog = () => {
    this.setState({
      sendNotificationDialogOpen: true,
    });
  };

  handleOnCloseSendNotificationDialog = () => {
    this.setState({
      sendNotificationDialogOpen: false,
      sendNotificationDialogState: {
        selectedCategory: '',
        selectedTemplate: '',
        sendingNotification: false,
      },
    });
  };

  handleOnSendSendNotificationDialog = async () => {
    const {
      sendNotificationDialogState: { selectedCategory, selectedTemplate },
      selected,
    } = this.state;

    const failure = (error) => {
      console.log(error);
      openSnackbar(error.message);

      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.sendNotificationDialogState.sendingNotification = false;
        })
      );
    };

    const successMessage = i18n.t(`template_${selectedCategory}_sent`);

    // find template object by selected ID
    const template = this.state.notificationTemplates.find(
      (t) => t._id === selectedTemplate
    );

    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.sendNotificationDialogState.sendingNotification = true;
      })
    );

    try {
      switch (selectedCategory) {
        case template_categories.EMAIL:
          // get emails of selected users in form of single string
          const selectedUserEmails = selected
            .filter((invite) => {
              return (
                invite.user.email &&
                invite.user.enabledNotifications.some(
                  (e) => e.type === notification_type.EMAIL
                )
              );
            })
            .map((invite) => invite.user.email);

          if (selectedUserEmails.length) {
            const companyData = await TemplateService.getCompanyData(
              this.state.selected[0].tenantId
            );
            const promises = selectedUserEmails.map((email, index) => {
              const data = {
                user: this.state.selected[index].user,
                invite: {
                  arrival: moment(this.state.selected[index].timeFrom)
                    .locale(locales[template.lang])
                    .format('LLLL'),
                  wifi_enabled: this.state.selected[index].wifi_enabled,
                  wifi_username: this.state.selected[index].wifi_username,
                  wifi_password: this.state.selected[index].wifi_password,
                  host: this.state.selected[index].host,
                },
                company: companyData,
              };
              const replacedTemplate = TemplateService.replaceTemplate(
                template,
                data
              );
              return EmailService.sendTemplate(email, replacedTemplate);
            });
            const response = await Promise.allSettled(promises);
            const successCount = response.filter(
              (r) => r.status === 'fulfilled'
            ).length;

            openSnackbar(
              `${successMessage} (${successCount}/${response.length})`
            );

            this.handleOnCloseSendNotificationDialog();
          } else {
            openSnackbar(successMessage);
            this.handleOnCloseSendNotificationDialog();
          }
          break;

        case template_categories.PUSH:
          const selectedUserIds = selected
            .filter((visit) =>
              visit.user.enabledNotifications.some(
                (notification) => notification.type === notification_type.PUSH
              )
            )
            .map((visit) => visit.user._id);

          if (selectedUserIds.length) {
            const response = await PushNotificationService.sendTemplate(
              selectedUserIds,
              template
            );
            const successCount = response.filter((r) => r.success).length;

            // show message with success count
            openSnackbar(
              `${successMessage} (${successCount}/${response.length})`
            );

            // close dialog
            this.handleOnCloseSendNotificationDialog();
          } else {
            openSnackbar(successMessage);
            this.handleOnCloseSendNotificationDialog();
          }
          break;

        case template_categories.SMS:
          // get sms of selected users in form of single string
          const selectedUserPhones = selected
            .filter(
              (invite) =>
                invite.user.phone &&
                invite.user.enabledNotifications.some(
                  (e) => e.type === notification_type.SMS
                )
            )
            .map((invite) => invite.user.phone);

          if (selectedUserPhones.length) {
            // make string with comma separated emails
            const promises = selectedUserPhones.map((phone) =>
              SmsService.sendTemplate(phone, template)
            );

            const response = await Promise.allSettled(promises);
            const successCount = response.filter(
              (r) => r.status === 'fulfilled'
            ).length;

            openSnackbar(
              `${successMessage} (${successCount}/${response.length})`
            );

            this.handleOnCloseSendNotificationDialog();
          } else {
            openSnackbar(successMessage);
            this.handleOnCloseSendNotificationDialog();
          }
          break;
        default:
          break;
      }
    } catch (error) {
      failure(error);
    }
  };

  handleOnChangeSendNotificationDialog = (field) => (event) => {
    const fieldValue = event.target.value;
    this.setState(function (prevState) {
      // if category is changed, clear template selection
      if (
        field === 'selectedCategory' &&
        prevState.sendNotificationDialogState[field] !== fieldValue
      ) {
        prevState.sendNotificationDialogState['selectedTemplate'] = '';
      }
      prevState.sendNotificationDialogState[field] = fieldValue;
      return prevState;
    });
  };

  handleOpenQRDialog = () => (event) => {
    this.setState(function (prevState) {
      const { openQRDialog } = prevState.qrDialogState;
      prevState.qrDialogState.openQRDialog = !openQRDialog;
      this.callMobileNativeService(!openQRDialog);
      return prevState;
    });
  };

  callMobileNativeService = (enabled) => {
    window.location = vms_application_routes.QR_READING + enabled;
  };

  handleOnChangeQRDialogReader = (qrDialogState) => {
    this.setState({
      qrDialogState: qrDialogState,
    });
  };

  handleOnQRReadCompleted = (success, userId, visitId) => {
    if (success && visitId) {
      this.handleVisitorAddEvent(
        visitId,
        visit_event_status_constants.SIGNED_IN
      );
    }
  };

  handleVisitorAddEvent = async (visitor, type, date) => {
    const { selectedDate } = this.state;
    try {
      await VisitService.addEvent(visitor._id, type, date);
      if (type === 'signedIn') {
        openSnackbar(i18n.t('visitor_was_signed_in'));
      } else if (type === 'signedOut') {
        openSnackbar(i18n.t('visitor_was_signed_out'));
      }
      this.loadVisitors(selectedDate);
    } catch (err) {
      console.log(err);
      openSnackbar(i18n.t('visitor_was_update_error'));
    }
  };

  handleSendEmergencyWarning = () => {
    const {
      emergencyState: { category, templateState },
    } = this.state;

    VisitService.sendEmergencyWarning(category, templateState)
      .then(() => {
        openSnackbar(i18n.t(`emergency_${category}_sent`));
        this.handleEmergenyModalClose();
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleEmergencyModalOpen = (category) => {
    this.loadEmergencyTemplates(category);
    this.loadEmergencyUsersInSystem(category);

    this.changeEmergencyModalOpenState(true);
  };

  handleEmergenyModalClose = () => {
    this.changeEmergencyModalOpenState(false);
  };

  changeEmergencyModalOpenState = (state) => {
    this.setState((prevState) => {
      const { emergencyState } = prevState;

      return {
        emergencyState: {
          ...emergencyState,
          modalOpen: state,
        },
      };
    });
  };

  loadEmergencyTemplates = (category) => {
    // Load emergency templates according to category
    const self = this;
    TemplateService.getTemplatesByCategoryAndType(
      category,
      template_types.EMERGENCY_MESSAGE
    )
      .then((templates) => {
        //TODO might need to consider a lib like immutability-helper
        //updating state directly and calling this.forceUpdate() is highly discouraged!!!
        self.setState(
          (prevState) => {
            const { emergencyState } = prevState;

            let emergencyTemplatesSelectItems = { key: [], value: [] };
            for (var i = 0; i < templates.length; i++) {
              emergencyTemplatesSelectItems.key[i] = i;
              emergencyTemplatesSelectItems.value[i] = templates[i].name;
            }

            return {
              emergencyState: {
                ...emergencyState,
                emergencyTemplates: templates,
                emergencyTemplatesSelectItems: emergencyTemplatesSelectItems,
                category: category,
              },
            };
          },
          () => {
            //If templates exists, choose the first one as default
            if (templates.length) {
              this.handleEmergencyTemplateChange(0);
            }
          }
        );
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadEmergencyUsersInSystem = (category) => {
    const field =
      category === template_categories.PUSH
        ? '_id'
        : category === template_categories.SMS
        ? 'phone'
        : 'email';

    this.setState((prevState) => {
      const { visitors, emergencyState } = prevState;
      const { templateState } = emergencyState;

      //Get only confirmed visits and pick only unique values
      const filteredVisits = Array.from(
        new Set(
          visitors.filter(
            (visit) =>
              visit.status === visit_visitor_status_constants.CONFIRMED &&
              visit.user[field]
          )
        )
      );
      const visitorsString = filteredVisits
        .map((visit) => visit.user[field])
        .join(', ');
      const visitorsIds = filteredVisits.map((visit) => visit.user._id);

      return {
        emergencyState: {
          ...emergencyState,
          templateState: {
            ...templateState,
            recipient: visitorsString,
            recipientArray: visitorsIds,
          },
        },
      };
    });
  };

  //If anything changes in the template editor, update the state
  changeEmergencyData = (name, value, index) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'selectedValue') {
      // Prefill the email template data
      this.handleEmergencyTemplateChange(fieldValue);
    } else {
      this.setState((prevState) => {
        const { emergencyState } = prevState;
        const { templateState } = emergencyState;

        return {
          emergencyState: {
            ...emergencyState,
            templateState: {
              ...templateState,
              [field]: fieldValue,
            },
          },
        };
      });
    }
  };

  //Set emergency template state
  handleEmergencyTemplateChange = (index) => {
    this.setState((prevState) => {
      const { emergencyState } = prevState;
      const { templateState, emergencyTemplates } = emergencyState;
      let template = emergencyTemplates[index];

      return {
        emergencyState: {
          ...emergencyState,
          templateState: {
            ...templateState,
            name: template.name,
            title: template.title,
            selectedValue: index,
            editor: TemplateService.createEditorFromHtml(template.body),
            lang: template.lang,
          },
        },
      };
    });
  };

  //Triggered when the text in template is edited
  handleEmergencyEditorChange = (editorContext) => {
    this.setState((prevState) => {
      const { emergencyState } = prevState;
      const { templateState } = emergencyState;

      return {
        emergencyState: {
          ...emergencyState,
          templateState: {
            ...templateState,
            editor: editorContext,
            editorHTML: TemplateService.draftToHtml(
              editorContext.getCurrentContent()
            ),
          },
        },
      };
    });
  };

  handleDialogClickCalendar = () => {
    this.setState((prevState) => {
      prevState.DialogOpenCalendarMd = !prevState.DialogOpenCalendarMd;
      return prevState;
    });
  };
  handleDialogClickEmergencyEmail = () => {
    this.setState((prevState) => {
      prevState.DialogOpenEmergencyEmailMd =
        !prevState.DialogOpenEmergencyEmailMd;
      return prevState;
    });
  };

  filterVisits = (visits) => {
    const { statusFilter, purposeFilter, otherFilter } = this.state;
    const user = Auth.getUser();

    return visits.filter((visitor) => {
      return (
        (otherFilter.related
          ? visitor.host._id === user._id ||
            (visitor.host.assistant || {})._id === user._id
          : true) &&
        ((purposeFilter.option1 && visitor.option1) ||
          (purposeFilter.option2 && visitor.option2) ||
          (purposeFilter.undefined && !visitor.option1 && !visitor.option2)) &&
        statusFilter[visitor.status]
      );
    });
  };

  filterVisitsAndSave = () => {
    const { visitors } = this.state;
    const filteredVisitors = this.filterVisits(visitors);
    this.setState({ filteredVisitors });
  };

  onFilterItemClick = (type, item) => () => {
    this.setState((prevState) => {
      const filter = { ...prevState[type] };
      filter[item] = !filter[item];
      return { [type]: filter };
    }, this.filterVisitsAndSave);
  };

  onFilterSelectAllClick = () => () => {
    this.setState((prevState) => {
      const filter = [
        { ...prevState['statusFilter'] },
        { ...prevState['purposeFilter'] },
        { ...prevState['otherFilter'] },
      ];

      if (!this.allFiltersChecked()) {
        for (let id in filter) {
          for (let status in filter[id]) {
            if (filter[id].hasOwnProperty(status)) {
              filter[id][status] = true;
            }
          }
        }
      } else {
        for (let id in filter) {
          for (let status in filter[id]) {
            if (filter[id].hasOwnProperty(status)) {
              filter[id][status] = false;
            }
          }
        }
      }
      return {
        statusFilter: filter[0],
        purposeFilter: filter[1],
        otherFilter: filter[2],
      };
    }, this.filterVisitsAndSave);
  };

  allFiltersChecked = () => {
    const filter = [
      { ...this.state['statusFilter'] },
      { ...this.state['purposeFilter'] },
      { ...this.state['otherFilter'] },
    ];
    for (let id in filter) {
      for (let status in filter[id]) {
        if (filter[id].hasOwnProperty(status)) {
          if (!filter[id][status]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  openInspectionDialog = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.inspection.inspectionVisit = draft.currentMenuItem;
        draft.inspection.inspectionOpen = true;
        draft.menuAnchorElements.visitMenu = null;
      })
    );
  };

  closeInspectionDialog = () => {
    const {
      inspection: { inspectionActivityConfirmed },
    } = this.state;
    if (inspectionActivityConfirmed) {
      this.loadData();
    }

    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.inspection.inspectionVisit = {};
        draft.inspection.inspectionOpen = false;
        draft.inspection.inspectionActivityConfirmed = false;
      })
    );
  };

  onInspectionActivityChecked = () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.inspection.inspectionActivityConfirmed = true;
      })
    );
  };

  handleItineraryOpen = (visit) => () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.itinerary.itineraryOpen = true;
        draft.itinerary.itineraryVisit = visit;
        draft.menuAnchorElements.visitMenu = null;
      })
    );
  };

  handleItineraryClose = () => {
    this.setState({
      itinerary: {
        itineraryOpen: false,
        itineraryVisit: {},
      },
    });
  };

  render() {
    const { classes } = this.props;
    const {
      filteredVisitors,
      selected,
      order,
      orderBy,
      selectedDate,
      visitorSuggestionValue,
      visitingSuggestionValue,
      highlightedId,
      currentMenuItem,
      sendNotificationDialogOpen,
      sendNotificationDialogState,
      notificationTemplates,
      qrDialogState,
      statusFilter,
      purposeFilter,
      otherFilter,
      emergencyState,
      selectedVisit,
      editAsGroupInvite,
      inspection,
      itinerary,
    } = this.state;
    const { inspectionOpen, inspectionVisit } = inspection;
    const { itineraryOpen, itineraryVisit } = itinerary;
    const { showFilterMenu, inviteDialog } = this.state.menuAnchorElements;
    const isSelectedDateToday = moment().isSame(selectedDate, 'day');
    const showPrintForCurrentItem =
      currentMenuItem.events &&
      currentMenuItem.events.find((e) => {
        const eventDate = moment(e.eventTimestamp);
        return (
          e.type === visit_event_status_constants.SIGNED_OUT &&
          eventDate.isSame(selectedDate, 'day')
        );
      })
        ? false
        : true;
    const isAdmin = Auth.isUserAdmin();
    const isPorter = Auth.isUserPorter();
    const isAssistant = Auth.isUserAssistant();

    const canCheckInspectionActivities = isPorter || isAdmin;
    const hasUncheckedInspectionActivities =
      currentMenuItem.inspectionActivities?.find(
        (act) => !findCheckedInspectionForDate(act.inspections, selectedDate)
      );
    const showInspectionAction =
      isSelectedDateToday &&
      canCheckInspectionActivities &&
      hasUncheckedInspectionActivities;

    const canFillItinerary = isAdmin || isAssistant;

    let tableHeader;
    let self = this;
    let renderInputSignIn = (props) => (
      <span onClick={props.onClick} id={props.id} {...props.inputProps}>
        {renderValues(props, i18n.t('visitor_button_signed_in'))}
      </span>
    );
    let renderInputSignOut = (props) => (
      <span onClick={props.onClick} id={props.id} {...props.inputProps}>
        {renderValues(props, i18n.t('visitor_button_signed_out'))}
      </span>
    );
    let renderValues = (props, text) => {
      return (
        <span>
          {props.value ? (
            <Typography
              variant="body2"
              className={classes.tableCellFont}
              noWrap
            >
              {props.value}
            </Typography>
          ) : isSelectedDateToday ? (
            <Button color="primary">
              <Typography
                variant="body2"
                className={classes.tableCellTimePicker}
                noWrap
              >
                {text}
              </Typography>
            </Button>
          ) : null}
        </span>
      );
    };

    if (selected.length > 0) {
      tableHeader = (
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableRow}>
            {!isPorter && (
              <TableCell padding="none" className={classes.tableCellCheckbox}>
                <Checkbox
                  color="primary"
                  checked={
                    selected.length === filteredVisitors.length &&
                    selected.length > 0
                  }
                  onChange={(event) =>
                    this.handleSelectAllRowsClick(
                      event.target.checked,
                      filteredVisitors
                    )
                  }
                />
              </TableCell>
            )}
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellPhoto}
            >
              <Typography
                variant="body2"
                className={classes.tableCellFont}
                noWrap
              >
                {i18n.t('visitor_photo')}
              </Typography>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellVisitor}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_visitor')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'visitor'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'visitor');
                  }}
                />
              </div>
            </TableCell>
            {isPorter && (
              <TableCell
                variant="head"
                padding="none"
                className={classes.tableCellRight}
              />
            )}
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_group')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'group'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'group');
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_purpose')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'purpose'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'purpose');
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}></div>
              <TableSortLabel
                active={orderBy === 'status'}
                direction={order}
                onClick={(event) => {
                  this.handleRequestSort(event, 'status');
                }}
              >
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_status')}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_signed_in')}
                </Typography>
                <TableSortLabel
                  active={orderBy === visit_event_status_constants.SIGNED_IN}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(
                      event,
                      visit_event_status_constants.SIGNED_IN
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_signed_out')}
                </Typography>
                <TableSortLabel
                  active={orderBy === visit_event_status_constants.SIGNED_OUT}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(
                      event,
                      visit_event_status_constants.SIGNED_OUT
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRightLastChildMore}
            >
              <div style={{ display: 'inline-flex' }}>
                <Tooltip title={i18n.t('delete')}>
                  <IconButton
                    aria-label={i18n.t('delete')}
                    onClick={this.handleRemoveSelectedItemsOpenDialog}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                <VmsExportLink
                  downloadData={ExportService.visits(selected)}
                  filename={'visits.csv'}
                />
                <Tooltip title={i18n.t('send_notification')}>
                  <IconButton
                    aria-label={i18n.t('send_notification')}
                    onClick={this.handleShowSendNotificationDialog}
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
      );
    } else {
      tableHeader = (
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableRow}>
            {!isPorter && (
              <TableCell padding="none" className={classes.tableCellCheckbox}>
                <Checkbox
                  color="primary"
                  checked={
                    selected.length === filteredVisitors.length &&
                    selected.length > 0
                  }
                  onChange={(event) =>
                    this.handleSelectAllRowsClick(
                      event.target.checked,
                      filteredVisitors
                    )
                  }
                />
              </TableCell>
            )}
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellPhoto}
            >
              <Typography
                variant="body2"
                className={classes.tableCellFont}
                noWrap
              >
                {i18n.t('visitor_photo')}
              </Typography>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellVisitor}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_visitor')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'visitor'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'visitor');
                  }}
                />
              </div>
            </TableCell>
            {isPorter && (
              <TableCell
                variant="head"
                padding="none"
                className={classes.tableCellRight}
              />
            )}
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_group')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'group'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'group');
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_purpose')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'purpose'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'purpose');
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_status')}
                </Typography>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(event, 'status');
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_signed_in')}
                </Typography>
                <TableSortLabel
                  active={orderBy === visit_event_status_constants.SIGNED_IN}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(
                      event,
                      visit_event_status_constants.SIGNED_IN
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRight}
            >
              <div className={classes.sortCell}>
                <Typography
                  variant="body2"
                  className={classes.tableCellFont}
                  noWrap
                >
                  {i18n.t('visitor_signed_out')}
                </Typography>
                <TableSortLabel
                  active={orderBy === visit_event_status_constants.SIGNED_OUT}
                  direction={order}
                  onClick={(event) => {
                    this.handleRequestSort(
                      event,
                      visit_event_status_constants.SIGNED_OUT
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              variant="head"
              padding="none"
              className={classes.tableCellRightLastChild}
            >
              <FilterIconButton
                onClick={this.handleOpenMenu('showFilterMenu')}
              />
            </TableCell>
          </TableRow>
        </TableHead>
      );
    }

    const datePicker = (
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsLocalize>
          <div>
            <div className="picker">
              <Calendar
                leftArrowButtonProps={{
                  'data-cy': 'visits-button-calendar-left',
                }}
                rightArrowButtonProps={{
                  'data-cy': 'visits-button-calendar-right',
                }}
                date={selectedDate}
                onChange={self.handleDateChange}
                allowKeyboardControl={false}
              />
            </div>
          </div>
        </VmsLocalize>
      </Paper>
    );

    const emergencyButtons = (
      <Grid container direction="row" alignItems="stretch">
        <Grid item xs={12} className={classes.emergencyButtonContainer}>
          <Button
            data-cy="visits-button-emergency-email"
            color="primary"
            variant="contained"
            className={classes.emergencyButton}
            fullWidth
            onClick={() =>
              this.handleEmergencyModalOpen(template_categories.EMAIL)
            }
          >
            {i18n.t('send_emergency_email')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.emergencyButtonContainer}>
          <Button
            data-cy="visits-button-emergency-push"
            color="primary"
            variant="contained"
            className={classes.emergencyButton}
            fullWidth
            onClick={() =>
              this.handleEmergencyModalOpen(template_categories.PUSH)
            }
          >
            {i18n.t('send_emergency_push')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.emergencyButtonContainer}>
          <Button
            data-cy="visits-button-emergency-sms"
            color="primary"
            variant="contained"
            className={classes.emergencyButton}
            fullWidth
            onClick={() =>
              this.handleEmergencyModalOpen(template_categories.SMS)
            }
          >
            {i18n.t('send_emergency_sms')}
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Aux>
        <Grid
          className={classes.visitPageWrapper}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item className={classes.calendarWrapper}>
            <div className={classes.search}>
              <VmsAutocomplete
                customClass={classes.searchField}
                inputClass={classes.inputSearchField}
                disableUnderline
                inputName={'searchVisitors'}
                suggestionService={VisitService.getVisitSuggestions}
                suggestionItemValue={(suggestion) => {
                  return (
                    suggestion.user.name +
                    ', ' +
                    i18n.t('visiting') +
                    ' ' +
                    suggestion.host.name +
                    ', ' +
                    moment(suggestion.timeFrom).format('L')
                  );
                }}
                filterSuggestionsResponse={(response) => {
                  return response;
                }}
                onSuggestionsClearRequested={
                  this.handleVisitorSuggestionsClearRequested
                }
                onSuggestionSelected={this.handleVisitorSuggestionSelected}
                onChange={this.handleVisitorSuggestionChange}
                inputValue={visitorSuggestionValue}
                placeholder={i18n.t('visitor_find_visitor')}
                endAdornment={
                  <InputAdornment
                    className={classes.searchAdornment}
                    position="end"
                  >
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            <div className={classes.search}>
              <VmsAutocomplete
                customClass={classes.searchField}
                inputClass={classes.inputSearchField}
                disableUnderline
                inputName={'searchVisiting'}
                suggestionService={VisitService.getVisitingSuggestions}
                suggestionItemValue={(suggestion) => {
                  return (
                    suggestion.host.name +
                    ', ' +
                    i18n.t('visitor') +
                    ' ' +
                    suggestion.user.name +
                    ', ' +
                    moment(suggestion.timeFrom).format('L')
                  );
                }}
                filterSuggestionsResponse={(response) => {
                  return response;
                }}
                onSuggestionsClearRequested={
                  this.handleVisitingSuggestionsClearRequested
                }
                onSuggestionSelected={this.handleVisitingSuggestionSelected}
                onChange={this.handleVisitingSuggestionChange}
                inputValue={visitingSuggestionValue}
                placeholder={i18n.t('visitor_find_visiting')}
                endAdornment={
                  <InputAdornment
                    className={classes.searchAdornment}
                    position="end"
                  >
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            <Hidden smDown>
              <div className={classes.dashboardCalendar}>{datePicker}</div>
              {isAdmin && (
                <div className={classes.search}>{emergencyButtons}</div>
              )}
            </Hidden>
            <Hidden mdUp>
              <div className={classes.phoneButtons}>
                <IconButton
                  variant="outlined"
                  onClick={this.handleDialogClickCalendar}
                >
                  <TodayIcon />
                </IconButton>
                <IconButton
                  variant="outlined"
                  onClick={this.handleDialogClickEmergencyEmail}
                >
                  <DehazeIcon />
                </IconButton>
                <Typography
                  data-cy="visits-text-visitor-name"
                  variant="body2"
                  noWrap
                >
                  <VmsLocalize>{moment(selectedDate).format('LL')}</VmsLocalize>
                </Typography>
              </div>
              <Dialog
                open={this.state.DialogOpenCalendarMd}
                onClose={this.handleDialogClickCalendar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className={classes.dashboardCalendar}>{datePicker}</div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={this.state.DialogOpenEmergencyEmailMd}
                onClose={this.handleDialogClickEmergencyEmail}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  {isAdmin && (
                    <div className={classes.emergencyContainer}>
                      {emergencyButtons}
                    </div>
                  )}
                </DialogContent>
              </Dialog>
            </Hidden>
          </Grid>
          <Grid item className={classes.visitContent}>
            <Paper
              className={classNames(
                classes.paperTable,
                !filteredVisitors.length ? classes.emptyViewImage : undefined
              )}
              elevation={elevation.light}
            >
              <Grid container>
                <Grid item className={classes.table}>
                  <Table>
                    {tableHeader}
                    <VisitFilterMenu
                      anchorEl={showFilterMenu}
                      statusFilter={statusFilter}
                      purposeFilter={purposeFilter}
                      otherFilter={otherFilter}
                      onClose={this.handleCloseMenu('showFilterMenu')}
                      onItemClick={this.onFilterItemClick}
                      stateOfFilters={this.allFiltersChecked()}
                      onFilterAllClick={this.onFilterSelectAllClick}
                    />
                    <TableBody>
                      {filteredVisitors.sort(this.getSorting).map((visitor) => {
                        const isSelected = this.isSelected(visitor);

                        //This overrides variables with the same name near the beginning of render
                        const hasUncheckedInspectionActivities =
                          visitor.inspectionActivities.find(
                            (act) =>
                              !findCheckedInspectionForDate(
                                act.inspections,
                                selectedDate
                              )
                          );
                        const showInspectionAction =
                          isSelectedDateToday &&
                          canCheckInspectionActivities &&
                          hasUncheckedInspectionActivities;

                        let purposeOptionString = '';
                        purposeOptionString = visitor.option1
                          ? i18n.t('visitor_purpose_option1')
                          : '';
                        purposeOptionString = purposeOptionString.concat(
                          visitor.option2
                            ? purposeOptionString
                              ? `, ${i18n.t('visitor_purpose_option2')}`
                              : i18n.t('visitor_purpose_option2')
                            : ''
                        );

                        return (
                          <>
                            {!(
                              visitor.daysOff?.includes(
                                moment(selectedDate)
                                  .locale('en')
                                  .format('dddd')
                                  .toLowerCase()
                              ) ||
                              visitor.datesOff?.includes(
                                moment(selectedDate).format(date_formats.date)
                              )
                            ) && (
                              <TableRow
                                data-cy="visits-list-item-visit"
                                key={visitor._id}
                                hover
                                role="checkbox"
                                selected={
                                  isSelected || visitor._id === highlightedId
                                }
                                aria-checked={isSelected}
                                className={classes.tableRow}
                              >
                                {!isPorter && (
                                  <TableCell
                                    padding="none"
                                    className={classes.tableCellCheckbox}
                                  >
                                    <Checkbox
                                      checked={isSelected}
                                      color="primary"
                                      onClick={(event) =>
                                        this.handleClickRow(event, visitor)
                                      }
                                    />
                                  </TableCell>
                                )}
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className={classes.tableCellPhoto}
                                >
                                  <Badge
                                    classes={{
                                      badge: classes.badge,
                                    }}
                                    overlap="circular"
                                    badgeContent="!"
                                    invisible={!showInspectionAction}
                                  >
                                    <VmsAvatar
                                      avatar={visitor.user.avatar}
                                      alt={
                                        visitor.user
                                          ? visitor.user.name
                                          : 'avatar_image'
                                      }
                                      photoId={(visitor.user || {}).photo}
                                      className={classes.avatar}
                                    />
                                  </Badge>
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className={classes.tableCellVisitor}
                                  onClick={
                                    !isPorter
                                      ? this.handleEditVisit(visitor)
                                      : this.handleShowVisit(visitor)
                                  }
                                >
                                  <Typography
                                    data-cy="visits-text-visitor-name"
                                    variant="body2"
                                    className={classes.header}
                                    noWrap
                                  >
                                    {(visitor.user || {}).name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={classes.assistant}
                                    noWrap
                                  >
                                    {i18n.t('visitor_visiting')}:{' '}
                                    {striptags((visitor.host || {}).name)}
                                    {visitor.host.assistant
                                      ? ' ' +
                                        i18n.t('visitor_visiting_assistant') +
                                        ': ' +
                                        visitor.host.assistant.name
                                      : ''}
                                  </Typography>
                                </TableCell>
                                {isPorter && (
                                  <TableCell
                                    cope="row"
                                    padding="none"
                                    className={classes.tableCellRight}
                                  >
                                    {visitor.requiresDocs && (
                                      <Grid
                                        container
                                        direction="row"
                                        style={{ flexFlow: 'nowrap' }}
                                        spacing={1}
                                      >
                                        <Grid
                                          container
                                          item
                                          xs
                                          style={{ alignContent: 'center' }}
                                        >
                                          <Announcement
                                            className={
                                              classes.requiredIconPending
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography
                                            variant="body2"
                                            className={classes.tableCellFont}
                                          >
                                            {i18n.t('documents_required')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </TableCell>
                                )}
                                <TableCell
                                  cope="row"
                                  padding="none"
                                  className={classes.tableCellRight}
                                >
                                  <Tooltip title={visitor.group || ''}>
                                    <Typography
                                      variant="body2"
                                      className={classes.tableCellFont}
                                      noWrap
                                    >
                                      {visitor.group}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  cope="row"
                                  padding="none"
                                  className={classes.tableCellRight}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.tableCellFont}
                                    noWrap
                                  >
                                    {visitor.purpose}
                                  </Typography>
                                  <Typography variant="caption" noWrap>
                                    {
                                      visitor.visitorTypes[
                                        visitor.visitorTypes.length - 1
                                      ]
                                    }
                                    {visitor.visitorTypes[
                                      visitor.visitorTypes.length - 1
                                    ] &&
                                      purposeOptionString &&
                                      ' | '}
                                    {purposeOptionString}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  cope="row"
                                  padding="none"
                                  className={classes.tableCellRight}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.tableCellFont}
                                    noWrap
                                  >
                                    {i18n.t('visitor_' + visitor.status)}
                                  </Typography>
                                  {visitor.status ===
                                    visit_visitor_status_constants.INVITED && (
                                    <Typography variant="caption" noWrap>
                                      {moment(visitor.timeFrom).format('LLL')}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className={classes.tableCellRight}
                                >
                                  {visitor.events.find((e) => {
                                    let eventTimestamp = moment(
                                      e.eventTimestamp
                                    );
                                    return (
                                      e.type ===
                                        visit_event_status_constants.SIGNED_IN &&
                                      eventTimestamp.isSame(selectedDate, 'day')
                                    );
                                  }) !== undefined ? (
                                    <Aux>
                                      <Typography
                                        variant="body2"
                                        className={classes.tableCellFont}
                                        noWrap
                                      >
                                        {moment(
                                          visitor.events.find((e) => {
                                            let eventTimestamp = moment(
                                              e.eventTimestamp
                                            );
                                            return (
                                              e.type ===
                                                visit_event_status_constants.SIGNED_IN &&
                                              eventTimestamp.isSame(
                                                selectedDate,
                                                'day'
                                              )
                                            );
                                          }).eventTimestamp
                                        ).format('LT')}
                                      </Typography>
                                      <Typography variant="caption" noWrap>
                                        {moment(
                                          visitor.events.find((e) => {
                                            let eventTimestamp = moment(
                                              e.eventTimestamp
                                            );
                                            return (
                                              e.type ===
                                                visit_event_status_constants.SIGNED_IN &&
                                              eventTimestamp.isSame(
                                                selectedDate,
                                                'day'
                                              )
                                            );
                                          }).eventTimestamp
                                        ).format('L')}
                                      </Typography>
                                    </Aux>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      className={classes.tableCellFontButton}
                                      noWrap
                                    >
                                      <TimePicker
                                        value={self.state.selectedSignedInTime}
                                        onChange={(date) => {
                                          this.handleVisitorAddEvent(
                                            visitor,
                                            visit_event_status_constants.SIGNED_IN,
                                            date
                                          );
                                        }}
                                        format={i18n.t('visitor_local_time')}
                                        ampm={
                                          i18n.t('visitor_time_am_pm') ===
                                          'true'
                                        }
                                        disableOpenOnEnter
                                        TextFieldComponent={renderInputSignIn}
                                      />
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className={classes.tableCellRight}
                                >
                                  {visitor.events.find((e) => {
                                    let eventTimestamp = moment(
                                      e.eventTimestamp
                                    );
                                    return (
                                      e.type ===
                                        visit_event_status_constants.SIGNED_IN &&
                                      eventTimestamp.isSame(selectedDate, 'day')
                                    );
                                  }) !== undefined ? (
                                    visitor.events.find((e) => {
                                      let eventTimestamp = moment(
                                        e.eventTimestamp
                                      );
                                      return (
                                        e.type ===
                                          visit_event_status_constants.SIGNED_OUT &&
                                        eventTimestamp.isSame(
                                          selectedDate,
                                          'day'
                                        )
                                      );
                                    }) !== undefined ? (
                                      <Aux>
                                        <Typography
                                          variant="body2"
                                          className={classes.tableCellFont}
                                          noWrap
                                        >
                                          {moment(
                                            visitor.events.find((e) => {
                                              let eventTimestamp = moment(
                                                e.eventTimestamp
                                              );
                                              return (
                                                e.type ===
                                                  visit_event_status_constants.SIGNED_OUT &&
                                                eventTimestamp.isSame(
                                                  selectedDate,
                                                  'day'
                                                )
                                              );
                                            }).eventTimestamp
                                          ).format('LT')}
                                        </Typography>
                                        <Typography variant="caption" noWrap>
                                          {moment(
                                            visitor.events.find((e) => {
                                              let eventTimestamp = moment(
                                                e.eventTimestamp
                                              );
                                              return (
                                                e.type ===
                                                  visit_event_status_constants.SIGNED_OUT &&
                                                eventTimestamp.isSame(
                                                  selectedDate,
                                                  'day'
                                                )
                                              );
                                            }).eventTimestamp
                                          ).format('L')}
                                        </Typography>
                                      </Aux>
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        className={classes.tableCellFontButton}
                                        noWrap
                                      >
                                        <TimePicker
                                          value={
                                            self.state.selectedSignedOutTime
                                          }
                                          onChange={(date) => {
                                            this.handleVisitorAddEvent(
                                              visitor,
                                              visit_event_status_constants.SIGNED_OUT,
                                              date
                                            );
                                          }}
                                          format={i18n.t('visitor_local_time')}
                                          disableOpenOnEnter
                                          ampm={
                                            i18n.t('visitor_time_am_pm') ===
                                            'true'
                                          }
                                          className={
                                            classes.tableCellTimePicker
                                          }
                                          TextFieldComponent={
                                            renderInputSignOut
                                          }
                                        />
                                      </Typography>
                                    )
                                  ) : (
                                    <div></div>
                                  )}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  padding="none"
                                  className={
                                    classes.tableCellBodyRightLastChild
                                  }
                                >
                                  <IconButton
                                    data-cy="visits-button-visit-menu"
                                    onClick={this.handleOpenMenu(
                                      'visitMenu',
                                      visitor
                                    )}
                                  >
                                    <MoreHoriz className={classes.tableIcon} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!isPorter && (
          <InviteVisitor
            page={routes.VISITORS}
            open={inviteDialog}
            editAsGroupInvite={editAsGroupInvite}
            selectedVisit={selectedVisit}
            selectedDate={selectedDate}
            onClose={this.handleCloseInviteDialog}
            onSave={this.handleSaveInviteDialog}
            reset={this.loadData}
          />
        )}
        {isPorter && (
          <PorterDialog
            porterState={this.state.porterState}
            onChange={this.handleVisitorStateChange}
            selectedDate={selectedDate}
            handlePrintBadge={this.handlePrintBadge}
          />
        )}
        <SendNotificationDialog
          open={sendNotificationDialogOpen}
          onClose={this.handleOnCloseSendNotificationDialog}
          onSend={this.handleOnSendSendNotificationDialog}
          onChange={this.handleOnChangeSendNotificationDialog}
          dialogState={sendNotificationDialogState}
          availableTemplates={notificationTemplates}
        />
        {isAdmin && (
          <TemplateEditor
            open={emergencyState.modalOpen}
            onClose={this.handleEmergenyModalClose}
            category={emergencyState.category}
            editMode={false}
            templateState={emergencyState.templateState}
            languages={true}
            onChange={this.changeEmergencyData}
            selectItems={emergencyState.emergencyTemplatesSelectItems}
            onTextEditorChange={this.handleEmergencyEditorChange}
            handleSendTemplate={this.handleSendEmergencyWarning}
          />
        )}
        <VmsDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
        >
          <div className={classes.dashboardCalendar}>{datePicker}</div>
          {isAdmin && <div className={classes.search}>{emergencyButtons}</div>}
        </VmsDrawer>
        {canCheckInspectionActivities && (
          <InspectionDialog
            open={inspectionOpen}
            onClose={this.closeInspectionDialog}
            onCheck={this.onInspectionActivityChecked}
            visit={inspectionVisit}
            selectedDate={selectedDate}
          />
        )}
        <Menu
          id="table-menu"
          anchorEl={this.state.menuAnchorElements['visitMenu']}
          open={Boolean(this.state.menuAnchorElements['visitMenu'])}
          onClose={this.handleCloseMenu('visitMenu')}
          getContentAnchorEl={null}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionProps={{
            onExited: this.handleMenuClosed,
          }}
        >
          {isSelectedDateToday && showPrintForCurrentItem && (
            <MenuItem
              data-cy="visit-menu-list-item-print-badge"
              onClick={this.handlePrintBadge(currentMenuItem)}
            >
              {i18n.t('menu_print_badge')}
            </MenuItem>
          )}
          {!isPorter && ( // Editing disabled for Porter role
            <Aux>
              <MenuItem
                data-cy="visit-menu-list-item-edit"
                onClick={this.handleEditVisit(currentMenuItem)}
              >
                {i18n.t('menu_edit')}
              </MenuItem>
              <MenuItem
                data-cy="visit-menu-list-item-delete"
                onClick={this.handleDeleteVisitOpenDialog(currentMenuItem)}
              >
                {i18n.t('menu_delete')}
              </MenuItem>
            </Aux>
          )}
          {isPorter && (
            <MenuItem
              data-cy="visit-menu-list-item-show"
              onClick={this.handleShowVisit(currentMenuItem)}
            >
              {i18n.t('menu_show_visit_info')}
            </MenuItem>
          )}
          {isSelectedDateToday && showInspectionAction && (
            <MenuItem
              data-cy="visit-menu-list-item-inspection"
              onClick={this.openInspectionDialog}
            >
              {i18n.t('menu_inspection')}
            </MenuItem>
          )}
          {canFillItinerary && (
            <MenuItem
              data-cy="visit-menu-list-item-itinerary"
              onClick={this.handleItineraryOpen(currentMenuItem)}
            >
              {i18n.t('menu_itinerary')}
            </MenuItem>
          )}
        </Menu>
        {isPorter && isiOSMobileApp() === true && (
          <Aux>
            <VmsButtonFAB
              customStyle={classes.QRbutton}
              onClick={this.handleOpenQRDialog()}
            >
              <PhotoCamera />
            </VmsButtonFAB>
            <QRDialogReader
              qrDialogData={qrDialogState}
              onChange={this.handleOnChangeQRDialogReader}
              onCompleted={this.handleOnQRReadCompleted}
              rejectUsed={true}
              successMessage={i18n.t('snackbar_entry_success_welcome')}
            />
          </Aux>
        )}
        {canFillItinerary && (
          <ItineraryAssistantDialogs
            open={itineraryOpen}
            onClose={this.handleItineraryClose}
            visit={itineraryVisit}
          />
        )}
      </Aux>
    );
  }
}

VisitorPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withBus(), withStyles(styles))(withRouter(VisitorPage));
