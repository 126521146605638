import PropTypes from 'prop-types';
import {
  withStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import i18n from '../../../../assets/i18n';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';

const styles = (theme) => ({
  menuColor: {
    fontSize: 14,
    color: theme.palette.primary6,
  },
  icon: {
    color: theme.palette.primary6,
  },
  singleNestedMenuItem: {
    paddingLeft: 56,
  },
  nestedText: {
    paddingLeft: 0,
  },
  flag: {
    width: 36,
    height: 24,
  },
});

const VisitFilterMenuItem = ({ classes, onClick, checked, text }) => {
  return (
    <MenuItem onClick={onClick}>
      {checked && (
        <ListItemIcon className={classes.icon}>
          <Check />
        </ListItemIcon>
      )}
      <ListItemText
        className={!checked ? classes.singleNestedMenuItem : undefined}
        classes={{ primary: classes.menuColor }}
        primary={text}
      />
    </MenuItem>
  );
};

const VisitFilterMenu = ({
  classes,
  anchorEl,
  statusFilter,
  purposeFilter,
  otherFilter,
  onClose,
  onItemClick,
  onFilterAllClick,
  stateOfFilters,
}) => {
  return (
    <Menu
      id="visit-filter-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onFilterAllClick()}>
        {stateOfFilters && (
          <ListItemIcon className={classes.icon}>
            <DoneAllOutlinedIcon />
          </ListItemIcon>
        )}
        <ListItemText
          className={!stateOfFilters ? classes.singleNestedMenuItem : undefined}
          classes={{ primary: classes.menuColor }}
          primary={i18n.t(!stateOfFilters ? `select_all` : `deselect_all`)}
        />
      </MenuItem>
      <Divider />
      {Object.keys(statusFilter).map((status) => {
        return (
          <VisitFilterMenuItem
            key={status}
            classes={classes}
            onClick={onItemClick('statusFilter', status)}
            checked={statusFilter[status]}
            text={i18n.t(`visitor_${status}`)}
          />
        );
      })}
      <Divider />
      {Object.keys(purposeFilter).map((purpose) => {
        return (
          <VisitFilterMenuItem
            key={purpose}
            classes={classes}
            onClick={onItemClick('purposeFilter', purpose)}
            checked={purposeFilter[purpose]}
            text={i18n.t(`visitor_purpose_${purpose}`)}
          />
        );
      })}
      <Divider />
      {Object.keys(otherFilter).map((other) => {
        return (
          <VisitFilterMenuItem
            key={other}
            classes={classes}
            onClick={onItemClick('otherFilter', other)}
            checked={otherFilter[other]}
            text={i18n.t(`visitor_${other}`)}
          />
        );
      })}
    </Menu>
  );
};

VisitFilterMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  statusFilter: PropTypes.object.isRequired,
  purposeFilter: PropTypes.object.isRequired,
  otherFilter: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onFilterAllClick: PropTypes.func.isRequired,
  stateOfFilters: PropTypes.bool.isRequired,
};

export default withStyles(styles)(VisitFilterMenu);
