import React from 'react';
import { avatar_mapping } from 'AppSettings';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100px',
    height: '100px',
    padding: 2,
  },
  border: {
    borderRadius: 75,
  },
  borderActive: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  borderDisabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    '&:hover': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  none: {
    border: 0,
    borderRadius: 75,
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  iconLeft: {
    color: theme.palette.primary6,
    marginLeft: theme.spacing(2),
  },
  rowContent: {
    flex: 1,
    justifyContent: 'center',
  },
}));

const UserSelectAvatar = ({ item, onChange, disabled }) => {
  const classes = useStyles();
  const colorClass = disabled ? classes.borderDisabled : classes.borderActive;

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid>
        <AccountCircleIcon className={classes.iconLeft} />
      </Grid>
      <Grid item container className={classes.rowContent}>
        {Object.keys(avatar_mapping).map((av) => (
          <Grid item key={av}>
            <Button
              data-cy="user-button-avatar"
              className={
                item.avatar === av
                  ? classNames(classes.border, colorClass)
                  : classes.none
              }
              onClick={
                item.avatar === av
                  ? onChange('avatar', '')
                  : onChange('avatar', av)
              }
              disabled={disabled}
            >
              <img
                className={classes.img}
                src={avatar_mapping[av]}
                alt="avatar"
              />
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

UserSelectAvatar.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
export default UserSelectAvatar;
