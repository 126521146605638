import { withStyles, Grid, Typography, IconButton } from '@material-ui/core';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  iconLeft: {
    marginLeft: 28,
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  iconRight: {
    color: theme.palette.primary6,
    marginRight: theme.spacing(2),
  },
  rowDoc: {
    marginTop: -24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rowContent: {
    width: '100%',
  },
  documentStatus: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  documentLabelItem: {
    flex: 1,
    minWidth: 0,
  },
  documentLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
  },
  root: {
    paddingTop: 24,
  },
});

const VmsDialogRowSimpleDocumentCollection = (props) => {
  const { onChange, classes, documents } = props;
  const [t] = useTranslation();
  return (
    <div className={classes.root}>
      {documents.map((doc, index) => {
        const required_for_entrance = doc.data.required_for_entrance
          ? ` (${t('required_for_entrance')})`
          : '';
        return (
          <Grid
            container
            key={index}
            className={classes.rowDoc}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item className={classes.rowContent}>
              <Grid
                container
                alignItems="center"
                className={classes.rowContent}
              >
                <Grid item>
                  <IconButton
                    className={classes.iconLeft}
                    onClick={onChange('documents_remove', null, index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Grid>
                <Grid item className={classes.documentLabelItem}>
                  <Typography className={classes.documentLabel} noWrap>
                    {`${doc.data.name}${required_for_entrance}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

VmsDialogRowSimpleDocumentCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(VmsDialogRowSimpleDocumentCollection);
