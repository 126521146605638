import { memo, useCallback, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import tableStyles from 'assets/jss/tableStyles';
import { withBus } from 'react-bus';
import { routes } from 'AppSettings';
import ExploreCreateDialog from './ExploreCreateDialog';
import ExploreEditDialog from './ExploreEditDialog';
import ExploreTable from './ExploreTable';
import { explore_types } from 'AppSettings';
import { ExploreService } from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import Auth from 'modules/Auth';
import { useTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { useMenu, useSettingsSearch, useTableHeader } from 'components';

const styles = (theme) => ({
  ...tableStyles(theme),
});

const currentItemReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'NEW':
      return {};
    case 'REPLACE':
      return action.payload;
    default:
      return state;
  }
};

const createEmptyExploreItemState = (type) => {
  const state = { type: type, name: '' };
  switch (type) {
    case explore_types.TITLE_IMAGE:
      state.document = {};
      break;
    case explore_types.WEATHER:
      break;
    case explore_types.NEWS:
      state.document = {};
      state.priority = 0;
      break;
    case explore_types.PLACE:
      break;
    case explore_types.THING:
      state.document = {};
      state.priority = 0;
      state.rating = 0;
      state.reviews = 0;
      break;
    default:
      return { type: 'undefined' };
  }
  return state;
};

const ExploreSettingsPage = ({ classes, bus, onChangeSearch }) => {
  const [t] = useTranslation();
  const [exploreItems, setExploreItems] = useState([]);

  const [currentItem, dispatchCurrentItem] = useReducer(currentItemReducer, {});

  const [savingItem, setSavingItem] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const highlightedId = useSettingsSearch(
    onChangeSearch,
    ExploreService.getItemsSuggestions
  );

  const loadData = useCallback(() => {
    ExploreService.getItems(Auth.getUser().tenantId)
      .then((response) => {
        setExploreItems(response);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  }, []);

  const onDeleteSuccess = useCallback(
    (deleted) => {
      openSnackbar(t('removed'));
      setExploreItems((prevItems) =>
        prevItems.filter((item) => !deleted.find((dlt) => dlt._id === item._id))
      );
    },
    [t]
  );

  const onDeleteFailure = useCallback(
    (err) => {
      console.log(err);
      openSnackbar(err.message);
      loadData();
    },
    [loadData]
  );

  const {
    tableHeaderState,
    setTableHeaderPage,
    setTableHeaderRef,
    handleChangeTableHeader,
    handleRemoveSelectedItemsOpenDialog,
    handleClickRow,
  } = useTableHeader(
    exploreItems,
    ExploreService.remove,
    onDeleteSuccess,
    onDeleteFailure,
    t('settings_explore_dialog_remove_selected_title'),
    t('settings_explore_dialog_remove_selected_description')
  );
  const { page, rowsPerPage } = tableHeaderState;

  const {
    clickedItem,
    menuAnchorEl,
    handleMenuOpen,
    handleMenuClose,
    handleMenuRemoveItemOpenDialog,
  } = useMenu(
    ExploreService.remove,
    onDeleteSuccess,
    onDeleteFailure,
    t('settings_explore_dialog_remove_title'),
    t('settings_explore_dialog_remove_description')
  );

  // ########## NEW DIALOG ##########
  const handleNewClick = (pathname) => {
    if (pathname === routes.SETTINGS_EXPLORE) {
      setCreateModalOpen(true);
    }
  };

  const handleCreateModalClose = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const handleCreateItem = useCallback(
    (type) => {
      handleCreateModalClose();
      setEditModalOpen(true);
      dispatchCurrentItem({
        type: 'REPLACE',
        payload: createEmptyExploreItemState(type),
      });
    },
    [handleCreateModalClose]
  );

  // ########## EDIT DIALOG ##########
  const handleEditModalOpen = useCallback(
    (item) => (event) => {
      handleMenuClose();
      dispatchCurrentItem({ type: 'REPLACE', payload: item });
      setEditModalOpen(true);
    },
    [handleMenuClose]
  );

  const handleEditModalClose = useCallback(() => {
    setEditModalOpen(false);
  }, []);

  const handleItemSave = async (documentId) => {
    setSavingItem(true);

    try {
      const item = documentId
        ? { ...currentItem, document: documentId }
        : currentItem;
      if (currentItem._id) {
        await ExploreService.update(item);
      } else {
        await ExploreService.create(item);
      }
      openSnackbar(t('saved'));
      dispatchCurrentItem({ type: 'NEW' });
      loadData();
      handleEditModalClose();
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    } finally {
      setSavingItem(false);
    }
  };

  const handleItemChange = (change) => {
    dispatchCurrentItem({ type: 'UPDATE', payload: change });
  };

  // ########## EFFECTS ##########
  useEffect(() => {
    bus.on('handleAddClick', handleNewClick);

    return () => bus.off('handleAddClick', handleNewClick);
  }, [bus]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (highlightedId) {
      const index = exploreItems.findIndex((u) => u._id === highlightedId);
      const curPage = Math.floor(index / rowsPerPage);

      setTableHeaderPage(curPage);
    }
  }, [highlightedId, rowsPerPage, exploreItems, setTableHeaderPage]);

  useEffect(() => {
    if (clickedItem) {
      dispatchCurrentItem({ type: 'REPLACE', payload: clickedItem });
    } else {
      dispatchCurrentItem({ type: 'NEW' });
    }
  }, [clickedItem]);

  return (
    <>
      <Grid container>
        <Grid item className={classes.settingsTableGridContent}>
          <ExploreTable
            classes={classes}
            tableHeaderState={tableHeaderState}
            exploreItems={exploreItems}
            page={page}
            rowsPerPage={rowsPerPage}
            highlightedId={highlightedId}
            handleClickRow={handleClickRow}
            handleEditModalOpen={handleEditModalOpen}
            menuAnchorEl={menuAnchorEl}
            handleMenuOpen={handleMenuOpen}
            setTableHeaderRef={setTableHeaderRef}
            handleChangeTableHeader={handleChangeTableHeader}
            handleRemoveSelectedItemsOpenDialog={
              handleRemoveSelectedItemsOpenDialog
            }
          />
        </Grid>
      </Grid>
      <Menu
        id="explore-settings-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEditModalOpen(clickedItem)}>
          {t('menu_edit')}
        </MenuItem>
        <MenuItem onClick={handleMenuRemoveItemOpenDialog}>
          {t('menu_delete')}
        </MenuItem>
      </Menu>
      <ExploreCreateDialog
        open={createModalOpen}
        items={exploreItems}
        handleClose={handleCreateModalClose}
        handleAdd={handleCreateItem}
      />
      <ExploreEditDialog
        open={editModalOpen}
        loading={savingItem}
        item={currentItem}
        handleClose={handleEditModalClose}
        handleSave={handleItemSave}
        handleItemChange={handleItemChange}
      />
    </>
  );
};

ExploreSettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  bus: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
};

export default memo(
  compose(withBus(), withStyles(styles))(ExploreSettingsPage)
);
