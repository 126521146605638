import PropTypes from 'prop-types';
import { withStyles, CircularProgress, Backdrop } from '@material-ui/core';
import Aux from 'hoc/Auxiliary';

const styles = (theme) => ({
  backdrop: {
    zIndex: 9999,
  },
  progress: {
    zIndex: 10000,
    position: 'fixed',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
});

const vmsActivityBackdrop = (props) => {
  const { classes } = props;
  return (
    <Aux>
      <CircularProgress className={classes.progress} />
      <Backdrop open={true} classes={{ root: classes.backdrop }} />
    </Aux>
  );
};

vmsActivityBackdrop.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default withStyles(styles)(vmsActivityBackdrop);
