import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Auth from 'modules/Auth';
import { OtherDialog, ErrorBoundary } from 'components/index';
import { createEmptyOtherState } from 'components/common/dialogs/OtherDialog';
import i18n from 'assets/i18n';
import { OtherService, TemplateService } from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import classNames from 'classnames';
import tableStyles from 'assets/jss/tableStyles';
import { emptySettingSearchState } from '../SettingsPage';
import OtherDetail from 'containers/dashboard/visitor/OtherDetail';
import objectPath from 'object-path';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { routes, other_types } from 'AppSettings';
import { handleOpenConfirmDialog } from '../../../components/common/dialogs/ConfirmDialog';

const styles = (theme) => ({
  ...tableStyles(theme),
  previewDialog: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class OthersPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      user: Auth.getUser(),
      value: 0,
      others: [],
      modalOpen: false,
      menuAnchorEl: null,
      currentItem: {},
      importDialogOpen: false,
      importDialogDetails: null,
      editState: createEmptyOtherState(),
      tableHeaderState: createTableHeaderState([]),
      highlightedId: '',
      previewOpen: false,
    };

    this.tableHeader = null;
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadData();
    this.setSearchSettings();
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_OTHERS) this.handleNewClick();
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  handleImportDialogClose = () => {
    this.setState({
      importDialogOpen: false,
      importDialogDetails: null,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
    this.loadData();
  };

  handleNewClick = () => {
    this.setState({
      editState: createEmptyOtherState(),
      modalOpen: true,
    });
  };

  handleEditModalOpen = (item) => (event) => {
    let editState = JSON.parse(JSON.stringify(item));
    if (item.type === other_types.WYSIWYG) {
      let editor = TemplateService.createEditorFromHtml(editState.data, 'html');
      editState.data = { editor: editor };
    }
    this.setState({
      modalOpen: true,
      menuAnchorEl: null,
      currentItem: item,
      editState: editState,
    });
  };

  loadData = async () => {
    const self = this;
    OtherService.getOthers()
      .then(function (others) {
        self.setState(function (prevState) {
          prevState.currentItem = {};
          prevState.others = others;
          prevState.tableHeaderState.data = others;
          return prevState;
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_others_dialog_remove_selected_title'),
      i18n.t('settings_others_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => (event) => {
    const { others, tableHeaderState } = this.state;
    const selected = tableHeaderState.selected;
    var array = others;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = others.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      OtherService.deleteOther(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            self.setState(function (prevState) {
              prevState.others = array;
              prevState.tableHeaderState.selected = [];
              return prevState;
            });
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadData();
        });
    });
  };

  handleDeleteOtherOpenDialog = (otherId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_others_dialog_remove_title'),
      i18n.t('settings_others_dialog_remove_description'),
      this.handleDeleteOther(otherId)
    );
  };

  handleDeleteOther = (otherId) => (event) => {
    var self = this;
    this.handleMenuClose();
    OtherService.deleteOther(otherId)
      .then(function (response) {
        openSnackbar(i18n.t('removed'));
        self.loadData();
      })
      .catch(function (error) {
        openSnackbar(error.message);
      });
  };

  handleEditSave = () => {
    let self = this;
    const { currentItem, editState } = this.state;
    const { name, category, type, data, priority } = editState;
    let saveData = data;
    if (type === other_types.WYSIWYG) {
      saveData = (data || {}).editor
        ? TemplateService.draftToHtml(data.editor.getCurrentContent())
        : '';
    }
    if (currentItem && currentItem._id) {
      OtherService.update(
        currentItem._id,
        name,
        category,
        type,
        saveData,
        priority
      )
        .then(function (response) {
          openSnackbar(i18n.t('saved'));
          self.handleModalClose();
        })
        .catch(function (error) {
          openSnackbar(error.message);
        });
    } else {
      OtherService.create(name, category, type, saveData, priority)
        .then(function (user) {
          openSnackbar(i18n.t('saved'));
          self.handleModalClose();
        })
        .catch(function (error) {
          openSnackbar(error.message);
        });
    }
  };

  handleEditModal = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    let fieldValue = value !== undefined ? value : event.target.value;
    this.setState(function (prevState) {
      if (field === 'priority') fieldValue = parseInt(fieldValue, 10);
      if (field === 'type' && prevState.editState[field] !== fieldValue) {
        if (
          fieldValue !== other_types.HTML &&
          fieldValue !== other_types.WYSIWYG
        ) {
          // On change of type reset the 'data' value
          prevState.editState['data'] = null;
        }
        if (fieldValue === other_types.WYSIWYG) {
          let editor = TemplateService.createEditorFromHtml(
            prevState.editState.data || '',
            'html'
          );
          prevState.editState.data = { editor: editor };
        }

        if (
          fieldValue === other_types.HTML &&
          prevState.editState.type === other_types.WYSIWYG
        ) {
          let data = (prevState.editState['data'] || {}).editor
            ? TemplateService.draftToHtml(
                prevState.editState['data'].editor.getCurrentContent()
              )
            : '';
          prevState.editState.data = data;
        }
      }
      if (field.startsWith('data.')) {
        if (!prevState.editState.data) prevState.editState.data = {};
        objectPath.set(prevState.editState, field, fieldValue);
      } else {
        prevState.editState[field] = fieldValue;
      }
      return prevState;
    });
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };

  isSelected = (item) =>
    this.state.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  handleUserSuggestionsClearRequested = () => (event) => {
    this.setState({
      highlightedId: '',
    });
  };

  handleUserSuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestUser(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.name;
      onChangeSearch(settingSearchState);
    }
  };

  handleUserSuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestUser = (_id) => {
    const { others, tableHeaderState } = this.state;
    const { rowsPerPage } = tableHeaderState;
    const index = others.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    this.setState({
      highlightedId: _id,
      tableHeaderState: tableHeaderState,
    });
  };

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService = OtherService.suggestOthers;
      settingSearchState.onSuggestionsClearRequested =
        this.handleUserSuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleUserSuggestionSelected;
      settingSearchState.onChangeSearch = this.handleUserSuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.name;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  handlePreview = (open) => (event) => {
    this.setState({ previewOpen: open });
  };

  render() {
    const { classes } = this.props;
    const {
      others,
      menuAnchorEl,
      currentItem,
      tableHeaderState,
      highlightedId,
    } = this.state;
    const { rowsPerPage, page } = tableHeaderState;

    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                prevPageButtonDataCy="settings-others-button-previous-page"
                nextPageButtonDataCy="settings-others-button-next-page"
              />
              <TableBody>
                {others
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((other) => {
                    const isSelected = this.isSelected(other);
                    return (
                      <TableRow
                        key={other._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === other._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onClick={this.handleClickRow(other)}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classes.tableCell}
                          padding="none"
                          onClick={this.handleEditModalOpen(other)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {other.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(classes.tableCell)}
                          padding="none"
                          onClick={this.handleEditModalOpen(other)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {other.category}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.textAlignRight
                          )}
                          padding="none"
                          onClick={this.handleEditModalOpen(other)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {other.type}
                          </Typography>
                        </TableCell>
                        {/* <TableCell scope="row" className={classNames(classes.tableCell, classes.textAlignRight)} padding="none" onClick={this.handleEditModalOpen(other)}>
                      <Typography variant='body2' className={classNames(classes.header, classes.tableInfoFont)} noWrap>{other.roles.join(', ')}</Typography>
                    </TableCell> */}
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.textAlignRight
                          )}
                          padding="none"
                          onClick={this.handleEditModalOpen(other)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {other.priority}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none"
                        >
                          <IconButton
                            className={classes.tableMenuButton}
                            aria-owns={menuAnchorEl ? 'table-menus' : null}
                            aria-haspopup="true"
                            onClick={(event) =>
                              this.handleMenuOpen(event, other)
                            }
                          >
                            <MoreHoriz className={classes.tableIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleEditModalOpen(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          <MenuItem onClick={this.handleDeleteOtherOpenDialog(currentItem._id)}>
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <OtherDialog
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          onChange={this.handleEditModal}
          item={this.state.editState}
          onSave={this.handleEditSave}
          onPreview={this.handlePreview(true)}
        />
        <Dialog
          open={this.state.previewOpen}
          onClose={this.handlePreview(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent classes={{ root: classes.previewDialog }}>
            <ErrorBoundary>
              <OtherDetail
                previewMode={true}
                onClose={this.handlePreview(false)}
                item={this.state.editState}
                onChange={() => null}
                detailState={this.state.detailState}
              />
            </ErrorBoundary>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePreview(false)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

OthersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(OthersPage);
