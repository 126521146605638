import { Button, withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { openSnackbar } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { VisitService } from 'services';

const styles = (theme) => ({
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    border: '3px solid orange',
    borderRadius: '9px',
  },
  active: { position: 'absolute' },
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '2em',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  pinInput: {
    width: '15px',
    border: 'none',
    outline: 'none',
    fontSize: '1.3em',
    userSelect: 'all',
  },
  formEnterPin: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
    padding: '50px',
    textAlign: 'center',
    width: '50%',
    marginTop: '5%',
  },
  containerosPin: {
    display: 'grid',
    height: '100%',
    justifyItems: 'center',
    alignItems: 'start',
  },
  button: {
    height: theme.spacing(12),
  },
});

const EnterPinPage = ({ classes, history, login }) => {
  const [t] = useTranslation();

  const [pin, setPin] = useState(['', '', '', '', '', '']);
  const handleInputChange = (newValue, index, e) => {
    if (!isNaN(newValue)) {
      setPin((prevPin) =>
        prevPin.map((value, idx) => (index === idx ? newValue : value))
      );

      if (index < 5 && newValue !== '') {
        e.currentTarget.parentNode.parentNode.childNodes[
          index + 1
        ].childNodes[0].focus();
      }
    }
  };

  const handleSubmitPin = async () => {
    try {
      let inputPin = '';
      pin.forEach((element) => {
        inputPin += element;
      });
      const data = {
        pin: inputPin,
      };
      const response = await VisitService.validatePin(data);
      setPin(['', '', '', '', '', '']);
      if (response?.message !== 'code is not valid') {
        login(response);
        history.push('/');
      } else {
        openSnackbar(t('submited_access_code_is_not_valid'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <NavLink
        className={classes.active}
        data-cy="settings-link-email"
        to="/"
        onClick={() => {
          setPin(['', '', '', '', '', '']);
        }}
      >
        <ChevronLeft className={classes.navLinkBack} />
      </NavLink>

      <div className={classes.containerosPin}>
        <div className={classes.formEnterPin}>
          <h1 className={(classes.vmsInput, classes.vmsHeader)}>
            {t('enter_recieved_access_code')}
          </h1>
          <div className={classes.container}>
            {pin.map((value, index) => (
              <div
                key={index}
                className={classes.cell}
                onClick={(e) => {
                  e.currentTarget.childNodes[0].focus();
                }}
              >
                <input
                  onFocus={(e) => {
                    e.currentTarget.select();
                  }}
                  onChange={(e) => {
                    handleInputChange(e.currentTarget.value, index, e);
                  }}
                  className={classes.pinInput}
                  type="text"
                  value={value}
                  maxLength="1"
                ></input>
              </div>
            ))}
          </div>
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={handleSubmitPin}
          >
            <div className={classes.text}>{t('submit')}</div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(EnterPinPage);
