import { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Webcam from 'react-webcam';
import { VmsDialogHeader } from '../components';
import i18n from '../assets/i18n';
import { Grid } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import SwitchCamera from '@material-ui/icons/SwitchCamera';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  buttonSave: {
    color: theme.palette.common.white,
  },
});

class CameraDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      camera: 'user',
      device: {},
    };
    this.deviceIndex = 0;
    this.devices = [];
    this.uploadDialog = null;
  }

  // Kept in case we need to enumerate camera devices exactly
  componentDidMount() {
    let self = this;
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          self.deviceIndex = 0;
          let j = 0;
          for (let i = 0; i < devices.length; i++) {
            let device = devices[i];
            //console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
            if (device.kind === 'videoinput') {
              self.devices[j] = {
                label: device.label,
                deviceId: device.deviceId,
              };
              j = j + 1;
            }
          }
          if (self.devices.length > 0) {
            self.setState({
              device: self.devices[self.deviceIndex],
            });
          }
        })
        .catch(function (err) {
          console.log(err.name + ': ' + err.message);
        });
    }
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.capture(imageSrc);
  };

  handleSwitchCamera = () => {
    // this.setState(function (prevState) {
    //   prevState.camera = prevState.camera === 'user' ? 'environment' : 'user';
    //   return prevState;
    // });
    let devicesCount = this.devices.length;
    if (devicesCount > 0) {
      this.deviceIndex =
        this.deviceIndex >= devicesCount - 1 ? 0 : this.deviceIndex + 1;
      console.log('Selected next device', this.devices[this.deviceIndex].label);
      this.setState(function (prevState) {
        prevState.device = this.devices[this.deviceIndex];
        return prevState;
      });
    }
  };

  render() {
    const { classes, onClose } = this.props;

    const videoConstraints = {
      // width: 720,
      // height: 1280,
      // aspectRatio: 0.75,
      //facingMode: { 'exact' : camera },
      deviceId: { exact: this.state.device.deviceId },
    };

    return (
      <Dialog
        fullScreen={isWidthDown('xs', this.props.width)}
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={true}
      >
        <VmsDialogHeader
          title={i18n.t('profile_photo_dialog_photo')}
          onClose={onClose}
          icon={<PhotoCamera />}
        />
        <Grid
          container
          className={classes.container}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            <Webcam
              audio={false}
              height={320}
              ref={this.setRef}
              screenshotFormat="image/jpeg"
              width={240}
              videoConstraints={videoConstraints}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" direction="row">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.buttonSave}
                  onClick={this.capture}
                >
                  {i18n.t('profile_photo_dialog_photo_capture')}
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="Send"
                  onClick={this.handleSwitchCamera}
                  disabled={this.devices.length < 2}
                >
                  <SwitchCamera />
                </IconButton>
              </Grid>
              {/* <Grid item>
                {device.label + ' ' + device.deviceId}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

CameraDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  capture: PropTypes.func.isRequired,
};

export default compose(withWidth(), withStyles(styles))(CameraDialog);
