import { useCallback, useState } from 'react';

const useSuggestion = (onSuggestionSelected, suggestionItemValue) => {
  const [visitorSuggestionValue, setVisitorSuggestionValue] = useState('');

  const handleVisitorSuggestionChange = (name, value) => (event) => {
    const fieldValue = value !== undefined ? value : event.target.value;
    setVisitorSuggestionValue(fieldValue ? fieldValue : '');
  };

  const handleVisitorSuggestionSelected = (suggestion) => () => {
    if (suggestion) {
      onSuggestionSelected(suggestion);
      setVisitorSuggestionValue(suggestionItemValue(suggestion));
    }
  };

  const handleVisitorSuggestionsClearRequested = () => () => {
    onSuggestionSelected(null);
  };

  const clearSuggestion = useCallback(() => {
    setVisitorSuggestionValue('');
    onSuggestionSelected(null);
  }, [onSuggestionSelected]);

  return [
    visitorSuggestionValue,
    handleVisitorSuggestionChange,
    handleVisitorSuggestionSelected,
    handleVisitorSuggestionsClearRequested,
    clearSuggestion,
  ];
};

export default useSuggestion;
