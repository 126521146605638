import Loadable from 'react-loadable';
import Loading from 'components/common/Loading';

export * from './meetings';

const CalendarComponent = Loadable({
  loader: () =>
    import('./CalendarComponent') /* webpackChunkName: "CalendarComponent" */,
  loading: Loading,
});

export { CalendarComponent };
