import { emptySettingSearchState } from 'containers/settings/SettingsPage';
import { useCallback, useEffect, useState } from 'react';

const useSettingsSearch = (onChangeSearch, service) => {
  const [highlightedId, setHighlightedId] = useState(null);

  const handleItemSuggestionsClearRequested = useCallback(
    () => (event) => {
      setHighlightedId(null);
    },
    []
  );

  const handleItemSuggestionSelected = useCallback(
    (suggestion) => (event) => {
      if (suggestion) {
        setHighlightedId(suggestion._id);
        onChangeSearch({ searchValue: suggestion.name });
      }
    },
    [onChangeSearch]
  );

  const handleItemSuggestionChange = useCallback(
    (name, value) => (event) => {
      const field = name !== undefined ? name : event.target.name;
      const fieldValue = value !== undefined ? value : event.target.value;
      if (field === 'searchSettings') {
        onChangeSearch({ searchValue: fieldValue ? fieldValue : '' });
      }
    },
    [onChangeSearch]
  );

  useEffect(() => {
    const searchState = {};
    searchState.disableSearch = false;
    searchState.suggestionService = service;
    searchState.onSuggestionsClearRequested =
      handleItemSuggestionsClearRequested;
    searchState.onSuggestionSelected = handleItemSuggestionSelected;
    searchState.onChangeSearch = handleItemSuggestionChange;
    searchState.suggestionItemValue = (suggestion) => {
      return suggestion.name;
    };
    searchState.filterSuggestionsResponse = (response) => {
      return response;
    };
    onChangeSearch(searchState);

    return () => {
      onChangeSearch(emptySettingSearchState());
    };
  }, [
    service,
    onChangeSearch,
    handleItemSuggestionsClearRequested,
    handleItemSuggestionChange,
    handleItemSuggestionSelected,
  ]);

  return highlightedId;
};

export default useSettingsSearch;
