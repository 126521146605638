import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  ProfileMenu,
  DocumentDetail,
  ItineraryDetailPage,
  Layout,
  ProfileCardVisitPrint,
} from 'components';
import SnackBar from 'components/common/bars/SnackBar';
import ProgressBar from 'components/common/bars/ProgressBar';
import ConfirmDialog from 'components/common/dialogs/ConfirmDialog';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Auth from 'modules/Auth';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import locales from 'assets/momentlocales';
import {
  routes,
  user_roles,
  assistant_tabs_employee,
  assistant_tabs_visitor,
  assistant_tabs_porter,
  isMobileApp,
  isiOSMobileApp,
} from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import logoAppBarMobile from 'assets/images/logo_appbar_mobile.png';
import logoAppBar from 'assets/images/logo_appbar.png';
import { InstanceDialog } from '../components/common/dialogs';

// Pages
import LoginPage from 'containers/LoginPage';
import LogoutPage from 'containers/LogoutPage';
import PublicDocumentPage from 'containers/PublicDocumentPage';
import DashboardPage from './DashboardPage';
import ProfilePage from './profile/ProfilePage';
import AccountActivationPage from 'containers/AccountActivationPage';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import commonStyles from 'assets/jss/commonStyles';
import {
  UserService,
  DocumentService,
  ConfigurationService,
  UserAssistantService,
} from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import OtherDetail from './dashboard/visitor/OtherDetail';
import MeetingRoom from './meetingRoom/MeetingRoom';
import TurnstilePage from './turnstile/TurnstilePage';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import HelpDialog from '../components/help/HelpDialog';
import AssistantDialog from 'components/assistant/AssistantDialog';
import SigningTabletPage from './signingtablet';

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    flexGrow: 1,
  },
  avatar: {
    width: 32,
    height: 32,
    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
    },
  },
});

const emptyAssistantState = () => ({
  openAssistant: false,
  assistantTabIndex: 0,
  assistantTabs: [],
  userAssistant: {
    my_visits: true,
    host_visits: true,
    meeting_rooms: true,
    settings: true,
    reports: true,
    explore: true,
    user_visits: true,
    itinerary: true,
    documents: true,
    porter_visits: true,
  },
});

class BaseAppBar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      openHelp: false,
      openLanguageMenu: false,
      openInstanceDialog: false,
      user: {},
      visitorView:
        sessionStorage.getItem('visitor_view') === 'true' ? true : false,
      companyName: null,
      companyLogos: {},
      openTenantMenu: false,
      layoutKey: new Date(),
      assistantState: emptyAssistantState(),
    };
    moment.locale(locales[props.i18n.language]);
  }

  componentDidMount() {
    this.props.bus.on('reloadApp', this.reloadApp);
    this.props.bus.on('reloadLogos', this.changeCompanyLogo);
    this.loadUserInformations();
    this.changeCompanyLogo();
    moment.tz.setDefault(sessionStorage.getItem('timezone'));

    this.preventSecondTab();
  }

  componentWillUnmount() {
    this.props.bus.off('reloadApp', this.reloadApp);
    this.props.bus.off('reloadLogos', this.changeCompanyLogo);

    window.removeEventListener('storage', this.onLocalStorageEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, visitorView } = this.state;
    if (user._id !== Auth.getUser()._id) {
      this.loadUserInformations();
    }
    if (Auth.isUserAuthenticated()) {
      // Make sure if we are authenticated to restart toekn refresh service on reload
      Auth.startTokenRefresh();
      // If global admin changes the tenant
      if (
        user &&
        user.role !== user_roles.VISITOR &&
        (user._id !== prevState.user._id ||
          visitorView !== prevState.visitorView)
      ) {
        this.changeCompanyName();
        this.changeCompanyLogo();
      }
    } else {
      //True either on first page load or during log out, update the user info and logo
      if (prevState.user && prevState.user._id !== user._id) {
        this.loadUserInformations();
        this.changeCompanyLogo();
      }
    }
  }

  preventSecondTab() {
    this.tabId = Date.now();
    if (!(isMobileApp() || isiOSMobileApp())) {
      //If this is the first tab, nothing will happen.
      //If this is the second tab, it will trigger the first if in the first tab.
      localStorage.setItem('instanceOpen', this.tabId);
      this.onLocalStorageEvent = (event) => {
        //This gets triggered in the first tab when the second tab is opened
        //This will trigger the second if in the second tab
        //We are passing tabId as to distinguish between the two tabs (because IE would trigger both ifs in one tab)
        //Date is only passed so JSON.stringify returns a unique string
        if (event.key === 'instanceOpen') {
          const tabInfo = { tabId: this.tabId, date: Date.now() };
          localStorage.setItem('instanceAvailable', JSON.stringify(tabInfo));
        }

        //This gets triggered in the second tab, it opens up the dialog
        //The tabId comparison is a guard for IE (because IE would trigger both ifs in one tab)
        if (event.key === 'instanceAvailable') {
          const tabInfo = JSON.parse(localStorage.getItem(event.key));
          if (tabInfo.tabId !== this.tabId) {
            this.setState({
              openInstanceDialog: true,
            });
          }
        }
      };
      window.addEventListener('storage', this.onLocalStorageEvent, false);
    }
  }

  reloadApp = (reloadLogos) => {
    this.setState({
      layoutKey: new Date(),
    });
    if (reloadLogos) {
      this.changeCompanyLogo();
    }
  };

  loadUserInformations = () => {
    const self = this;
    if (Auth.getUser()._id) {
      UserService.getUser(Auth.getUser()._id)
        .then(function (response) {
          self.setState({
            user: response,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      this.setState({
        user: {},
      });
    }
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleHelp = (open) => (event) => {
    this.setState({
      openHelp: open,
      anchorEl: null,
    });
  };

  handleVisitorView = () => {
    this.setState((prevState) => {
      if (!prevState.visitorView) {
        sessionStorage.removeItem('timezone');
      } else {
        sessionStorage.setItem('timezone', Auth.getUser().timezone);
      }
      moment.tz.setDefault(sessionStorage.getItem('timezone'));

      sessionStorage.setItem('visitor_view', !prevState.visitorView);
      return {
        visitorView: !prevState.visitorView,
        anchorEl: null,
      };
    });
  };

  handleLanguageMenu = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        openLanguageMenu: !prevState.openLanguageMenu,
      };
    });
  };

  handleTenantMenu = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        openTenantMenu: !prevState.openTenantMenu,
      };
    });
  };

  changeTenant = (tenantId) => {
    Auth.changeTenant(tenantId)
      .then(() => {
        this.reloadApp();
        this.changeCompanyName();
        this.changeCompanyLogo();
        this.handleClose();
      })
      .catch((err) => {
        this.handleClose();
        console.log(err);
        openSnackbar(err.message);
        Auth.deauthenticateUser();
      });
  };

  navigateTo = (path) => {
    return () => {
      //If we are logging out get rid of visitorView state because the state is preserved
      //Also reset assistant
      if (path === '/logout') {
        this.setState({
          visitorView: false,
          assistantState: emptyAssistantState(),
        });
        sessionStorage.removeItem('visitor_view');
      }
      this.props.history.push(path);
      this.handleClose();
    };
  };

  handle = () => {
    this.props.history.push('/profile');
    this.handleClose();
  };

  changeLanguage = (lng) => (event) => {
    let lang = lng ? lng : event.target.value;
    this.props.i18n.changeLanguage(lang);
    this.props.bus.emit('languageChanged');
    moment.locale(locales[lang]);
    this.handleClose();
  };

  changeCompanyName = () => {
    const { visitorView } = this.state;
    if (visitorView) {
      this.setState({
        companyName: null,
      });
    } else {
      ConfigurationService.getValueByKey('name')
        .then((conf) => {
          this.setState({
            companyName: conf.value,
          });
        })
        .catch((err) => {
          console.log(err);
          openSnackbar(err.message);
        });
    }
  };

  changeCompanyLogo = () => {
    const { visitorView } = this.state;

    const logos = {};
    logos.logo = logoAppBar;
    logos.logo_mobile = logoAppBarMobile;

    //If nobody is authenticated display default logo
    if (!Auth.isUserAuthenticated() || Auth.isUserVisitor() || visitorView) {
      this.setState({
        companyLogos: logos,
      });
    } else {
      DocumentService.getCompanyLogos()
        .then((response) => {
          response.forEach((logo) => {
            logos[logo.name] = DocumentService.api + logo.downloadUrl;
          });

          this.setState({
            companyLogos: logos,
          });
        })
        .catch((err) => {
          console.log(err);
          openSnackbar(err.message);
        });
    }
  };

  //### ASSISSTANT ###
  changeAssistantState = (assistantState) => {
    this.setState((prevState) => ({
      ...prevState,
      assistantState: {
        ...prevState.assistantState,
        ...assistantState,
      },
    }));
  };

  handleAssistantDialog = (open) => () => {
    this.changeAssistantState({ openAssistant: open });
  };

  changeAssistantTab = (index) => {
    const {
      assistantState: { userAssistant },
      visitorView,
    } = this.state;
    const assistantTabsForRole =
      Auth.isUserVisitor() || visitorView
        ? assistant_tabs_visitor
        : Auth.isUserPorter()
        ? assistant_tabs_porter
        : assistant_tabs_employee;
    if (!userAssistant[assistantTabsForRole[index]]) {
      const item = { [assistantTabsForRole[index]]: true };
      const updatedAssistant = { ...userAssistant, ...item };

      UserAssistantService.updateUserAssistant(item).catch((err) => {
        console.log(err);
      });

      this.changeAssistantState({
        userAssistant: updatedAssistant,
        openAssistant: true,
      });
    }
    this.changeAssistantState({ assistantTabIndex: index });
  };

  populateAssistant = (contents) => {
    this.changeAssistantState({ assistantTabs: contents });
  };

  populateUserAssistant = (userAssistant) => {
    this.changeAssistantState({ userAssistant });
  };
  handleInstanceDialogClose = (event) => {
    this.setState({
      openInstanceDialog: false,
    });
  };

  render() {
    const { location } = this.props;

    const {
      anchorEl,
      openHelp,
      user,
      visitorView,
      openLanguageMenu,
      companyName,
      openTenantMenu,
      layoutKey,
      assistantState,
      companyLogos,
      openInstanceDialog,
    } = this.state;
    const { openAssistant, assistantTabIndex, assistantTabs } = assistantState;
    const menuOpen = Boolean(anchorEl);
    const photoUrl = user.photo ? user.photo.downloadUrl : undefined;
    const imgUrl = photoUrl
      ? DocumentService.api + photoUrl
      : '/images/avatar.png';
    const pathname = location.pathname;
    const menuButtonVisible =
      pathname === routes.VISITORS ||
      pathname === routes.MEETINGROOMS ||
      pathname.startsWith(routes.SETTINGS);

    let visit = {};
    if (pathname.startsWith(routes.PRINT_VISIT)) {
      visit = location.state.visit;
    }

    const isAuthenticated = Auth.isUserAuthenticated();
    const isTurnstile = Auth.isUserTurnstile();
    const isMeetingRoom = Auth.isUserMeetingRoom();
    const isSigningTablet = Auth.isUserSigningTablet();

    if (
      pathname.startsWith(routes.PRIVACY_POLICY) ||
      pathname.startsWith(routes.TERMS_OF_USE) ||
      pathname.startsWith(routes.RULES_OF_BEHAVIOUR)
    ) {
      return (
        <Layout
          pathname={'/'}
          handleLanguage={this.changeLanguage}
          companyLogo={companyLogos.logo}
          companyLogoMobile={companyLogos.logo_mobile}
        >
          <Switch>
            <Route
              exact
              path={routes.PRIVACY_POLICY}
              component={() => <PublicDocumentPage document="privacy_policy" />}
            />
            <Route
              exact
              path={routes.TERMS_OF_USE}
              component={() => <PublicDocumentPage document="terms_of_use" />}
            />
            <Route
              exact
              path={routes.RULES_OF_BEHAVIOUR}
              component={() => (
                <PublicDocumentPage document="rules_of_behaviour" />
              )}
            />
          </Switch>
        </Layout>
      );
    } else if (isAuthenticated && pathname.startsWith(routes.PRINT_VISIT)) {
      return <ProfileCardVisitPrint visit={visit} />;
    } else if (isAuthenticated && isSigningTablet) {
      return (
        <>
          <SigningTabletPage
            companyLogo={companyLogos.logo}
            companyName={companyName}
            handleLanguage={this.changeLanguage}
          />
          <SnackBar />
        </>
      );
    } else {
      return (
        <Aux>
          <Layout
            empty={isAuthenticated && (isMeetingRoom || isTurnstile)}
            menuButtonVisible={menuButtonVisible}
            user={user}
            menuOpen={menuOpen}
            pathname={pathname}
            imgUrl={imgUrl}
            handleMenu={this.handleMenu}
            handleAssistantClick={this.handleAssistantDialog(true)}
            handleLanguage={this.changeLanguage}
            companyName={companyName}
            key={layoutKey}
            companyLogo={companyLogos.logo}
            companyLogoMobile={companyLogos.logo_mobile}
          >
            {isAuthenticated && !Auth.isAgreeTermsNeeded() ? (
              !(isMeetingRoom || isTurnstile) ? (
                <Switch>
                  <Route
                    path="/profile"
                    render={(props) => (
                      <ProfilePage
                        {...props}
                        visitorView={visitorView}
                        companyName={companyName}
                      />
                    )}
                  />
                  <Route path="/logout" component={LogoutPage} />
                  <Route
                    exact
                    path="/documents/detail"
                    component={DocumentDetail}
                  />
                  <Route exact path="/others/detail" component={OtherDetail} />
                  <Route
                    exact
                    path={routes.ITINERARY_DETAIL}
                    component={ItineraryDetailPage}
                  />
                  <Route
                    path="/"
                    render={(props) => (
                      <DashboardPage
                        {...props}
                        user={user}
                        visitorView={visitorView}
                        changeAssistantTab={this.changeAssistantTab}
                        populateAssistant={this.populateAssistant}
                        populateUserAssistant={this.populateUserAssistant}
                      />
                    )}
                  />{' '}
                  {/* This has to be last because it matches everything*/}
                </Switch>
              ) : (
                <Switch>
                  <Route path="/logout" component={LogoutPage} />
                  <Route
                    path="/"
                    component={
                      Auth.isUserMeetingRoom() ? MeetingRoom : TurnstilePage
                    }
                  />
                </Switch>
              )
            ) : (
              <Switch>
                <Route
                  path="/account/activate/:userId"
                  component={AccountActivationPage}
                />
                <Route
                  path="/account/resetpassword/:action"
                  component={ResetPasswordPage}
                />
                <Route path="/logout" component={LogoutPage} />
                <Route component={LoginPage} />
              </Switch>
            )}
          </Layout>
          <SnackBar />
          <ProgressBar />
          <ConfirmDialog />
          <ProfileMenu
            open={menuOpen}
            openLanguageMenu={openLanguageMenu}
            handleClose={this.handleClose}
            anchorEl={anchorEl}
            history={this.props.history}
            languageMenuClick={this.handleLanguageMenu}
            changeLanguage={this.changeLanguage}
            profile={this.navigateTo('/profile')}
            logout={this.navigateTo('/logout')}
            help={this.handleHelp(true)}
            visitorView={visitorView}
            handleVisitorView={this.handleVisitorView}
            openTenantMenu={openTenantMenu}
            tenantMenuClick={this.handleTenantMenu}
            changeTenant={this.changeTenant}
            user={user}
            handleAssistantClick={this.handleAssistantDialog(true)}
          />
          <HelpDialog openHelp={openHelp} handleHelp={this.handleHelp} />
          <AssistantDialog
            open={openAssistant}
            onClose={this.handleAssistantDialog(false)}
            onTabChange={this.changeAssistantTab}
            index={assistantTabIndex}
          >
            {assistantTabs.map((tab, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: tab }} />
            ))}
          </AssistantDialog>
          <InstanceDialog
            open={openInstanceDialog}
            handleCloseDialog={this.handleInstanceDialogClose}
          />
        </Aux>
      );
    }
  }
}

BaseAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withBus(),
  withTranslation('translations')
)(withRouter(withStyles(styles)(BaseAppBar)));
