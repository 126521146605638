import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  colorError: {
    color: theme.palette.red,
    padding: theme.spacing(),
  },
  colorWarning: {
    color: theme.palette.primaryIcon,
    padding: theme.spacing(),
  },
  colorBlack: {
    padding: theme.spacing(),
  },
  highlightedLog: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.primaryIcon,
  },
  logContent: {
    overflow: 'auto',
    wordBreak: 'break-word',
  },
}));

const LogsTable = ({ logs, highlightedId }) => {
  const classes = useStyles();
  const logsContainterRef = useRef();

  const scrollToHighligtedId = (target) => {
    if (target && logsContainterRef.current) {
      const style = window.getComputedStyle(target);
      logsContainterRef.current.scrollTo({
        behavior: 'smooth',
        top:
          target.offsetTop -
          2 * (parseInt(style.margin) + parseInt(style.padding)) -
          2 * target.offsetHeight, // offsetHeight is height of p element
      });
    }
  };

  return (
    <div ref={logsContainterRef} className={classes.logContent}>
      {logs.map((line) => {
        return (
          <p
            ref={highlightedId === line.id ? scrollToHighligtedId : undefined}
            key={line.id}
            className={
              highlightedId === line.id
                ? classes.highlightedLog
                : line.level >= 40
                ? line.level >= 50
                  ? classes.colorError
                  : classes.colorWarning
                : classes.colorBlack
            }
          >
            {line.time && (
              <span>
                <b> {line.time} </b>{' '}
              </span>
            )}
            {line.hostname && <span>host: {line.hostname} </span>}
            {line.level && <span>level: {line.level} </span>}
            {line.userId && <span>userID: {line.userId} </span>}
            {line.userName && <span>userName: {line.userName} </span>}
            {line.method && <span>method: {line.method} </span>}
            {line.tenantId && <span>tenantID: {line.tenantId} </span>}
            {line.url && <span>url: {line.url} </span>}
            {line.pid && <span>pid: {line.pid} </span>}
            {line.msg && (
              <span>
                msg: <b>{line.msg} </b>{' '}
              </span>
            )}
          </p>
        );
      })}
    </div>
  );
};

LogsTable.propTypes = {
  logs: PropTypes.array.isRequired,
};
export default LogsTable;
