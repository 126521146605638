import AbstractService from '../services/AbstractService';
import Auth from '../modules/Auth';

class ExchangeServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'exchange/';
    this.username = '';
    this.password = '';
  }

  configure(username, passwordPlainText) {
    this.username = username;
    this.password = passwordPlainText;
  }

  ewsApiRequest(ewsFunction, ewsArgs) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    let request = {
      username: this.username,
      password: this.password,
      ewsFunction: ewsFunction,
      ewsArgs: ewsArgs,
    };
    return this.post(this.endpoint, request, config);
  }

  loadExchangeCalendar(mailbox, from, to) {
    const endpoint =
      this.endpoint + 'meetings/' + mailbox + '/' + from + '/' + to;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    let request = {
      username: this.username,
      password: this.password,
    };

    return this.post(endpoint, request, config);
  }
}

const ExchangeService = new ExchangeServiceImpl();

export default ExchangeService;
