import { Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { visit_event_status_constants } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  documentStatus: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  documentLabelItem: {
    flexGrow: 1,
  },
  documentLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary6,
    maxWidth: 132,
  },
}));

const PorterEventsTab = ({ porterState, selectedDate }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const signedIn = porterState.visit.events.filter((event) => {
    const eventTimestamp = moment(event.eventTimestamp);
    return event.type === visit_event_status_constants.SIGNED_IN &&
      eventTimestamp.isSame(selectedDate, 'day')
      ? event
      : null;
  })[0];
  const signedOut = porterState.visit.events.filter((event) => {
    const eventTimestamp = moment(event.eventTimestamp);
    return event.type === visit_event_status_constants.SIGNED_OUT &&
      eventTimestamp.isSame(selectedDate, 'day')
      ? event
      : null;
  })[0];

  const events = [];
  if (signedIn) {
    events.push({
      id: signedIn._id,
      type: visit_event_status_constants.SIGNED_IN,
      label: t('visitor_signed_in'),
      status: moment(signedIn.eventTimestamp).format(
        t('visitor_local_date_full')
      ),
    });
  }
  if (signedOut) {
    events.push({
      id: signedOut._id,
      type: visit_event_status_constants.SIGNED_OUT,
      label: t('visitor_signed_out'),
      status: moment(signedOut.eventTimestamp).format(
        t('visitor_local_date_full')
      ),
    });
  }

  const eventChildren = [];
  let i = 0;
  events.forEach((event) => {
    eventChildren.push(
      <Grid item key={`event_${i++}`}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item className={classes.documentLabelItem}>
            <Typography className={classes.documentLabel} noWrap>
              {event.label}
            </Typography>
            <Typography className={classes.documentLabel} noWrap>
              {event.subLabel}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.documentStatus} noWrap>
              {event.status}
            </Typography>
            <Typography
              className={classes.documentStatus}
              noWrap
              align="center"
            >
              {event.subStatus}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid item>
      <Grid
        container
        className={classNames(classes.dialogRow, classes.contentOverflow)}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.rowContent}>
          <Grid container direction="column">
            {eventChildren}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PorterEventsTab.propTypes = {
  porterState: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
};

export default PorterEventsTab;
