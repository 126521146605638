import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Button, Paper, Typography } from '@material-ui/core';
import mobileStyles from 'assets/jss/mobileStyles';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import LocalHotel from '@material-ui/icons/LocalHotel';
import RestaurantMenu from '@material-ui/icons/RestaurantMenu';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Wifi from '@material-ui/icons/Wifi';
import Apps from '@material-ui/icons/Apps';
import People from '@material-ui/icons/People';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import AccessTime from '@material-ui/icons/AccessTime';
import classNames from 'classnames';
import { other_categories, configuration_group } from 'AppSettings';
import OtherService from 'services/OtherService';
import UserService from 'services/UserService';
import ConfigurationService from 'services/ConfigurationService';
import { openSnackbar } from 'components/common/bars/SnackBar';
import Auth from 'modules/Auth';
import { withRouter, Redirect } from 'react-router-dom';
import { elevation } from 'assets/UISettings';
import moment from 'moment';

const styles = (theme) => ({
  ...mobileStyles(),
  gridItemSmall: {
    // TODO: try to get it working using 50% width instead of 'vw'
    width: '47.5vw',
    height: '47.5vw',
    minWidth: 136,
    minHeight: 136,
    maxWidth: 300,
    maxHeight: 300,
    position: 'relative',
  },
  itemContent: {
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
  },
  button: {
    letterSpacing: 0.4,
    fontSize: 12,
    textTransform: 'none',
  },
  iconRight: {
    position: 'absolute',
    top: 24,
    right: 24,
    color: theme.palette.primary.main,
  },
  grid: {
    height: '100%',
  },
  root: {
    padding: '0 ' + 2 * theme.spacing() + 'px',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  contact: {
    color: theme.palette.primary4,
    paddingLeft: theme.spacing(),
    display: 'inline-flex',
    alignItems: 'center',
  },
  visitIcon: {
    marginRight: theme.spacing(2),
  },
});

function OtherItem(props) {
  const { classes, item, onClick } = props;
  return (
    <Grid item className={classes.gridItemSmall}>
      <KeyboardArrowRight className={classes.iconRight} />
      <Button
        className={classNames(
          classes.parentWithShadow,
          classes.itemContent,
          classes.button
        )}
        onClick={onClick}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.itemContent}
        >
          <Grid item>{item.icon}</Grid>
          <Grid item>
            <label>{item.name}</label>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}

OtherItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }),
};

class OtherPage extends Component {
  constructor() {
    super();
    this.state = {
      others: [],
      detailOpen: false,
      detailItem: undefined,
      detailState: undefined,
      user: undefined,
      companySettings: [],
    };
  }

  componentDidMount() {
    this.user = Auth.getUser();
    this.loadData();
    //Used when returning from OtherDetail
    if (this.props.selectedVisit) {
      this.loadOthers();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedVisit } = this.props;
    if (
      (selectedVisit && !prevProps.selectedVisit) || //First visit
      (selectedVisit &&
        prevProps.selectedVisit &&
        selectedVisit._id !== prevProps.selectedVisit._id)
    ) {
      //Visit change
      this.loadOthers();
    }
  }

  failure = (error) => {
    console.log(error);
    openSnackbar(error.message);
  };

  loadData = () => {
    const self = this;

    UserService.getUser(this.user._id)
      .then(function (user) {
        self.setState({
          user: user,
        });
      })
      .catch(this.failure);

    ConfigurationService.getConfigurationsForGroup(
      configuration_group.LOCATION_SETTINGS
    )
      .then((settings) => {
        this.setState(function (prevState) {
          prevState.companySettings = settings;
          return prevState;
        });
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  loadOthers = () => {
    const self = this;
    const { selectedVisit, onVisitChange } = this.props;

    OtherService.getOthersAsVisitor(selectedVisit._id)
      .then(function (others) {
        self.setState({
          others: others,
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
        onVisitChange(null);
      });
  };

  handleItemClick = (item) => (event) => {
    this.setState({
      detailItem: item,
      detailOpen: true,
    });
  };

  handleDetailClose = () => {
    this.setState({
      detailItem: undefined,
      detailOpen: false,
    });
  };

  handleDetailState = (detailState) => {
    this.setState({
      detailState: detailState,
    });
  };

  render() {
    const { classes, selectedVisit } = this.props;
    const { others } = this.state;
    let items = [];

    for (let i = 0; i < others.length; i++) {
      const other = others[i];
      let icon = null;
      switch (other.category) {
        case other_categories.MEAL:
          icon = <RestaurantMenu />;
          break;
        case other_categories.ACCOMODATION:
          icon = <LocalHotel />;
          break;
        case other_categories.TRANSPORT:
          icon = <DirectionsCar />;
          break;
        case other_categories.REFRESHMENT:
          icon = <RestaurantMenu />;
          break;
        case other_categories.WIFI:
          icon = <Wifi />;
          break;
        case other_categories.OTHER:
          icon = <Apps />;
          break;
        default:
          break;
      }

      const item = {
        name: other.name,
        icon: icon,
        item: other, // own data
      };

      if (selectedVisit) {
        // Some are allowed based on visit purpose options
        if (
          selectedVisit.purposeOptions &&
          selectedVisit.purposeOptions.indexOf(item.item.category) >= 0
        ) {
          items.push(item);
        }
      }
    }

    const { detailOpen, detailItem, user, companySettings } = this.state;
    if (detailOpen) {
      let company = {};
      if (Array.isArray(companySettings)) {
        companySettings.forEach((setting) => {
          company[setting.key] = setting.value;
        });
      }
      const myState = {
        detailOpen,
        item: detailItem.item,
        visit: selectedVisit,
        user: user,
        company: company,
      };
      return (
        <Redirect
          push={true}
          to={{
            pathname: '/others/detail',
            state: myState,
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        {selectedVisit && (
          <Paper className={classes.paper} elevation={elevation.light}>
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <Typography variant="body1" className={classes.contact}>
                  <People className={classes.visitIcon} />
                  {selectedVisit.host.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" className={classes.contact}>
                  <BusinessCenter className={classes.visitIcon} />
                  {selectedVisit.host.company}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" className={classes.contact}>
                  <AccessTime className={classes.visitIcon} />
                  {moment(selectedVisit.timeFrom).format('LL HH:mm')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
        {!detailOpen && (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            className={classes.grid}
          >
            {items.map((item, idx) => (
              <OtherItem
                key={`otherItem-${idx}`}
                classes={classes}
                item={item}
                onClick={this.handleItemClick(item)}
              />
            ))}
          </Grid>
        )}
      </div>
    );
  }
}

OtherPage.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedVisit: PropTypes.object,
  onVisitChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(OtherPage));
