import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import i18n from 'assets/i18n';
import {
  VmsDialogRowSelectCollection,
  VmsDialogRowDocumentCollection,
  VmsDialogRowSelectArray,
  SafetyResultDialog,
} from 'components';
import { invite_tab_indexes, type_constants, user_roles } from 'AppSettings';
import VmsDialogDragDropPhoto from 'containers/VmsDialogDragDropPhoto';
import { createDragDropStateWithPhoto } from 'containers/VmsDialogDragDropPhoto';
import { createDragDropState } from 'containers/VmsDialogDragDropFile';
import Auth from 'modules/Auth';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import VmsDialogDragDropFile from 'containers/VmsDialogDragDropFile';
import FeedbackResultDialog from './FeedbackResultDialog';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

class DocsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSafetyTraining: false,
      openFeedback: false,
      selectedDocument: {},
    };
  }

  shouldComponentUpdate(prevProps, nextProps) {
    const currentTab = nextProps.currentTab || prevProps.currentTab;
    return currentTab === invite_tab_indexes.DOCS;
  }

  handleOnClick = (document) => (event) => {
    this.setState({
      selectedDocument: document,
    });
    if (document.type === type_constants.QUESTIONNAIRE) {
      this.setState({
        openSafetyTraining: true,
      });
    } else if (document.type === type_constants.FEEDBACK) {
      this.setState({
        openFeedback: true,
      });
    } else {
      this.setState({
        openSafetyTraining: false,
      });
    }
  };

  handleCloseDialog = () => (event) => {
    this.setState({
      openSafetyTraining: false,
      openFeedback: false,
    });
  };

  render() {
    const {
      classes,
      changeData,
      changeFileData,
      invitation,
      visitorTypes,
      userClosed,
      visitClosed,
      onDragDropChange,
      onDragDropFileChange,
    } = this.props;
    const { documents, questionnaires, signed, feedback } =
      this.props.documents;
    const { selectedDocument, openSafetyTraining, openFeedback } = this.state;

    const photoObject = invitation.photo
      ? {
          _id: invitation.photo,
          downloadUrl: invitation.photoUrl,
          name: i18n.t('invite_form_docs_photo'),
        }
      : null;

    let dragDropState =
      invitation.dragDropState || createDragDropStateWithPhoto(photoObject);
    let dragDropFileState =
      invitation.dragDropFileState || createDragDropState();
    const isVisitor = Auth.getUser().role === user_roles.VISITOR;
    const tenantIsSame = invitation.tenantId
      ? Auth.getUser().tenantId === invitation.tenantId
      : true;

    return (
      <Grid item>
        <div>
          <VmsDialogRowSelectArray
            rowClass={classes.dialogRow}
            iconLeft={<SupervisorAccount />}
            placeholder={i18n.t('invite_form_visitor_type')}
            onChange={changeData('visitorType')}
            inputName="visitor-type"
            selectTypes={visitorTypes}
            selectedType={invitation.visitorType}
            mapOfItem={(visitorType) => visitorType.key}
            disabled={userClosed || visitClosed}
            selectDataCy="invitation-select-visitor-type"
            itemDataCy="invitation-list-item-visitor-type"
          />
          <VmsDialogRowSelectCollection
            disabled={userClosed || isVisitor || visitClosed}
            rowClass={classes.dialogRow2}
            iconLeft={<InsertDriveFile />}
            placeholder={i18n.t('invite_form_add_document')}
            onChange={changeData}
            inputName="documents-menu"
            selectTypesA={documents}
            selectTypesB={signed}
            selectTypesC={questionnaires}
            selectTypesD={feedback}
            selectedTypeA={''}
            selectedTypeB={''}
            selectedTypeC={''}
            selectedTypeD={''}
            headerTypeA={i18n.t('invite_form_documents_documents')}
            headerTypeB={i18n.t('invite_form_documents_signed')}
            headerTypeC={i18n.t('invite_form_documents_safety_trainings')}
            headerTypeD={i18n.t('invite_form_documents_feedback')}
            inputDataCy="invitation-input-add-document"
            itemTypeADataCy="invitation-list-item-document"
            itemTypeBDataCy="invitation-list-item-signed"
            itemTypeCDataCy="invitation-list-item-safety-training"
            itemTypeDDataCy="invitation-list-item-feedback"
          />
          <VmsDialogRowDocumentCollection
            documents={invitation.documents}
            onChange={changeData}
            onClick={this.handleOnClick}
            disabled={userClosed || visitClosed}
            itemDataCy="invitation-list-item-assigned-document"
            textDocNameDataCy="invitation-text-assigned-document-name"
            buttonRemoveDocDataCy="invitation-button-remove-assigned-document"
          />
          <VmsDialogDragDropFile
            onChange={onDragDropFileChange}
            dragDropFileState={dragDropFileState}
            disabled={userClosed || visitClosed}
            buttonDataCy="invitation-button-drag-drop-file"
            itemDataCy="invitation-list-item-drag-drop-file"
          />
          <VmsDialogRowDocumentCollection
            documents={invitation.personal_files}
            onChange={changeFileData}
            onClick={this.handleOnClick}
            disabled={userClosed || visitClosed}
            itemDataCy="invitation-list-item-uploaded-document"
            textDocNameDataCy="invitation-text-uploaded-document-name"
            buttonRemoveDocDataCy="invitation-button-remove-uploaded-document"
          />
          <VmsDialogDragDropPhoto
            onChange={onDragDropChange}
            dragDropState={dragDropState}
            disabled={userClosed || visitClosed || !tenantIsSame}
            buttonDataCy="invitation-button-drag-drop-photo"
            itemDataCy="invitation-list-item-drag-drop-photo"
          />
          {openSafetyTraining && (
            <SafetyResultDialog
              document={selectedDocument}
              openDialog={openSafetyTraining}
              onClose={this.handleCloseDialog}
            />
          )}
          {openFeedback && (
            <FeedbackResultDialog
              document={selectedDocument}
              openDialog={openFeedback}
              onClose={this.handleCloseDialog}
            />
          )}
        </div>
      </Grid>
    );
  }
}

DocsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  changeFileData: PropTypes.func.isRequired,
  onDragDropChange: PropTypes.func.isRequired,
  onDragDropFileChange: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  visitorTypes: PropTypes.array.isRequired,
  visitClosed: PropTypes.bool.isRequired,
  userClosed: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DocsTab);
