import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      left: 'initial',
      right: 'initial',
      padding: '0 ' + 3 * theme.spacing() + 'px',
      '&> div': {
        minWidth: 'initial',
        maxWidth: 'initial',
        width: 450,
      },
    },
  },
});

const SnackbarProviderWithStyle = ({ classes, children, ...props }) => {
  const classProp = {
    root: classes.root,
  };

  return (
    <SnackbarProvider {...props} classes={classProp}>
      {children}
    </SnackbarProvider>
  );
};

SnackbarProviderWithStyle.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withStyles(styles)(SnackbarProviderWithStyle);
