import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, LinearProgress } from '@material-ui/core';

const styles = (theme) => ({
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: 2,
  },
});

let openProgressBarFn;

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowing: false,
    };
  }

  openProgressBar = (isShowing) => {
    this.setState({
      isShowing: isShowing,
    });
  };

  componentDidMount() {
    openProgressBarFn = this.openProgressBar;
  }

  render() {
    const { classes } = this.props;
    let progress = '';
    if (this.state.isShowing) {
      progress = (
        <LinearProgress color="primary" className={classes.progress} />
      );
    } else {
      progress = '';
    }
    return <div>{progress}</div>;
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isShowing: PropTypes.bool,
};

export function openProgressBar(isShowing) {
  if (openProgressBarFn !== undefined) openProgressBarFn(isShowing);
}

export default withStyles(styles)(ProgressBar);
