import AbstractService from 'services/AbstractService';
import Auth from 'modules/Auth';

class UserAssistantServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'userAssistant/';
  }

  getUserAssistant() {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }

  updateUserAssistant(assistant) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.put(this.endpoint, assistant, config);
  }
}

const UserAssistant = new UserAssistantServiceImpl();

export default UserAssistant;
