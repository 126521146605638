import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/PersonOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { VmsInput } from 'components';
import VmsDialog from './VmsDialog';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { pin_settings, validatePin } from 'AppSettings';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

const pinDialog = (props) => {
  const {
    classes,
    open,
    adminMode,
    onChange,
    onPinChange,
    xsPaperMode,
    onClose,
  } = props;
  let { dialogState } = props;
  if (!dialogState) {
    dialogState = {};
  }

  const { oldPin, newPin, newPinVerify } = dialogState;

  const handlePinChange = (name) => (event) => {
    onChange(name, validatePin(event.target.value))(event);
  };

  const validateLength = (pin = '') => {
    return (
      pin.length >= pin_settings.minLength &&
      pin.length <= pin_settings.maxLength
    );
  };

  const oldPinValid = validateLength(oldPin);
  const newPinValid = validateLength(newPin);
  const newPinVerifyValid = validateLength(newPinVerify);

  const saveDisabled =
    (!oldPinValid && !adminMode) ||
    !newPinValid ||
    !newPinVerifyValid ||
    newPin !== newPinVerify;

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={i18n.t('pin_dialog_title')}
      onClose={onClose}
      dialogHeaderIcon={<PersonOutline />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onPinChange}
      buttonDisabled={saveDisabled}
      xsPaperMode={xsPaperMode}
    >
      {!adminMode && (
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<LockOutlined />}
          textValue={oldPin}
          placeholder={i18n.t('pin_dialog_old_pin')}
          inputLabel={i18n.t('pin_dialog_old_pin')}
          onChange={handlePinChange('oldPin')}
          inputType="password"
          inputName="oldPin"
          inputProps={pin_settings}
          inputError={!oldPinValid}
        />
      )}
      <VmsInput
        rowClass={adminMode ? classes.dialogRow : classes.dialogRow2}
        iconLeft={<LockOutlined />}
        textValue={newPin}
        placeholder={i18n.t('pin_dialog_new_pin')}
        inputLabel={i18n.t('pin_dialog_new_pin')}
        onChange={handlePinChange('newPin')}
        inputType="password"
        inputName="newPin"
        inputProps={pin_settings}
        inputError={!newPinValid}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<LockOutlined />}
        textValue={newPinVerify}
        placeholder={i18n.t('pin_dialog_new_pin_verify')}
        inputLabel={i18n.t('pin_dialog_new_pin_verify')}
        onChange={handlePinChange('newPinVerify')}
        inputType="password"
        inputName="newPinVerify"
        inputError={newPin !== newPinVerify || !newPinVerifyValid}
        inputProps={pin_settings}
      />
    </VmsDialog>
  );
};

pinDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPinChange: PropTypes.func.isRequired,
  adminMode: PropTypes.bool.isRequired,
  dialogState: PropTypes.shape({
    oldPin: PropTypes.string.isRequired,
    newPin: PropTypes.string.isRequired,
    newPinVerify: PropTypes.string.isRequired,
  }).isRequired,
  xsPaperMode: PropTypes.bool,
};

export default withStyles(styles)(pinDialog);
