import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';
import { TemplateService } from 'services';
import commonStyles from 'assets/jss/commonStyles';
import i18n from 'assets/i18n';
import { withRouter } from 'react-router-dom';
import { browser_language } from 'AppSettings';

const styles = (theme) => ({
  ...commonStyles(theme),
  container: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.primary6,
  },
  closeButton: {
    marginTop: theme.spacing(2),
  },
});

class PublicDocumentPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      document: '',
      title: '',
    };
  }

  componentDidMount() {
    this.loadData(browser_language());
  }

  loadData(lang) {
    TemplateService.getPublicTemplateByType(this.props.document, lang)
      .then((document) => {
        this.setState({
          document: ((document || [])[0] || {}).body,
          title: ((document || [])[0] || {}).name,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleClose = () => {
    this.props.history.push('/');
  };

  render() {
    const { classes } = this.props;
    const { document, title } = this.state;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} inline={true}>
            {title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: document }} />
        </Paper>
        <Button
          className={classes.closeButton}
          color="primary"
          onClick={this.handleClose}
        >
          {i18n.t('close')}
        </Button>
      </div>
    );
  }
}

PublicDocumentPage.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.string.isRequired,
};

export default withRouter(withStyles(styles)(PublicDocumentPage));
