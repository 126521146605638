import PropTypes from 'prop-types';
import { Dialog, List, ListItem, ListItemText } from '@material-ui/core';
import { explore_types } from 'AppSettings';
import { useTranslation } from 'react-i18next';

const ExploreCreateDialog = ({ open, items, handleClose, handleAdd }) => {
  const [t] = useTranslation();

  const displayTypes = { ...explore_types };

  items.forEach((item) => {
    if (item.type === explore_types.TITLE_IMAGE) {
      delete displayTypes.TITLE_IMAGE;
    } else if (item.type === explore_types.WEATHER) {
      delete displayTypes.WEATHER;
    }
  });

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <List>
        {Object.values(displayTypes).map((type) => (
          <ListItem button onClick={() => handleAdd(type)} key={type}>
            <ListItemText primary={t(`settings_explore_type_${type}`)} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

ExploreCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

export default ExploreCreateDialog;
