import useUploadUsers from './useUploadUsers';
import ImportUsersDialog from './ImportUsersDialog';
import ImportResultsDialog from './ImportResultDialog';
import { useEffect } from 'react';

const ImportUsers = ({ open, onClose }) => {
  const {
    result: statistics,
    loading,
    uploadUsers,
    resetResult,
  } = useUploadUsers();

  // Closes ImportUsersDialog once the upload is complete
  useEffect(() => {
    if (!!statistics) {
      onClose(true);
    }
  }, [statistics, onClose]);

  return (
    <>
      <ImportUsersDialog
        open={open}
        onClose={onClose}
        loading={loading}
        uploadUsers={uploadUsers}
      />
      <ImportResultsDialog
        open={!!statistics}
        onClose={resetResult}
        statistics={statistics}
      />
    </>
  );
};

export default ImportUsers;
