import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PersonAdd from '@material-ui/icons/PersonAdd';
import PersonOutline from '@material-ui/icons/PersonOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Email from '@material-ui/icons/Email';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import { VmsInput, VmsDialogRowAutocomplete } from 'components';
import i18n from '../../assets/i18n';
import modalStyle from '../../assets/jss/modalStyle';
import commonStyle from '../../assets/jss/commonStyles';
import VmsDialog from '../common/dialogs/VmsDialog';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

const profileAssistantDialog = (props) => {
  const {
    classes,
    onSave,
    onClose,
    onChange,
    open,
    saveDisabled,
    selectedItem,
    inputValue,
    title,
    inputName,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    suggestionService,
    suggestionItemValue,
    filterSuggestionsResponse,
    deleteDisabled,
    onDelete,
  } = props;
  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={title}
      onClose={onClose()}
      dialogHeaderIcon={<PersonAdd />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onSave}
      buttonDisabled={!saveDisabled}
      buttonDeleteDisabled={deleteDisabled}
      buttonDeleteAction={onDelete}
    >
      <VmsDialogRowAutocomplete
        rowClass={classes.dialogRow}
        iconLeft={<PersonOutline />}
        inputName={inputName}
        suggestionService={suggestionService}
        suggestionItemValue={suggestionItemValue}
        filterSuggestionsResponse={filterSuggestionsResponse}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        inputValue={inputValue}
        placeholder={i18n.t('invite_form_name')}
        onChange={onChange}
        required
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Email />}
        textValue={selectedItem && selectedItem.email ? selectedItem.email : ''}
        placeholder={i18n.t('invite_form_email')}
        inputLabel={i18n.t('invite_form_email')}
        disabled
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<PhoneIphone />}
        textValue={selectedItem && selectedItem.phone ? selectedItem.phone : ''}
        placeholder={i18n.t('invite_form_phone')}
        inputLabel={i18n.t('invite_form_phone')}
        disabled
      />
    </VmsDialog>
  );
};

profileAssistantDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  saveDisabled: PropTypes.bool,
  suggestionService: PropTypes.func.isRequired,
  suggestionItemValue: PropTypes.func.isRequired,
  filterSuggestionsResponse: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  selectedItem: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  deleteDisabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(profileAssistantDialog);
