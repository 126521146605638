import PropTypes from 'prop-types';
import { withStyles, Grid, Paper, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { elevation } from '../../assets/UISettings';
import i18n from '../../assets/i18n';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
  },
  row: {
    height: 42,
  },
  plantText: {
    color: theme.palette.primary4,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary4,
    paddingLeft: theme.spacing(),
    paddingBottom: 12,
    paddingTop: 12,
  },
  font: {
    color: theme.palette.primary4,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
});

const ProfileCardVisitedPlants = (props) => {
  const { classes, plants } = props;
  return (
    <Grid item xs={12} md={6} className={classes.grid}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <Grid
          container
          direction={'column'}
          justifyContent={'space-around'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid container direction={'row'} className={classes.row}>
            <Grid item xs={1}>
              <BusinessIcon className={classes.icon} />
            </Grid>
            <Grid item xs={11} className={classes.row}>
              <Typography variant="body1" className={classes.font}>
                {i18n.t('profile_inviting_tenants')}
              </Typography>
            </Grid>
          </Grid>
          {plants.length ? (
            plants.map((plant, i) => (
              <Grid container direction={'row'} key={i}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <Typography variant="body1" className={classes.plantText}>
                    {plant}
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container direction={'row'}>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Typography variant="body1" className={classes.plantText}>
                  {i18n.t('profile_no_invitations')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

ProfileCardVisitedPlants.propTypes = {
  classes: PropTypes.object.isRequired,
  plants: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProfileCardVisitedPlants);
