import { Component } from 'react';
import { withStyles, Avatar } from '@material-ui/core';
import DocumentService from '../../../services/DocumentService';
import PropTypes from 'prop-types';
import { avatar_mapping } from 'AppSettings';

const styles = (theme) => {};

class VmsAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      photoId: '',
    };
  }

  loadPhotoUrl = (photoId) => {
    if (photoId) {
      DocumentService.getDocument(photoId)
        .then((response) => {
          this.setState({
            photoId: photoId,
            photoUrl: response.downloadUrl,
          });
        })
        .catch((error) => {
          console.log(error);
          // Do not show error if cannot be loaded
          // openSnackbar(error.message);
          // In case of Unauthorized the later call does not trigger render because of condition in componentDidUpdate
          this.setState({
            photoId: undefined,
            photoUrl: undefined,
          });
        });
    } else if (this.state.photoUrl) {
      this.setState({
        photoUrl: undefined,
      });
    }
  };

  componentDidMount() {
    this.loadPhotoUrl(this.props.photoId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.photoId !== prevProps.photoId) {
      this.loadPhotoUrl(this.props.photoId);
    }
  }

  render() {
    const { alt, className, avatar } = this.props;
    const { photoUrl } = this.state;

    const imgUrl = avatar
      ? avatar_mapping[this.props.avatar]
      : photoUrl
      ? DocumentService.api + photoUrl
      : '/images/avatar.png';
    return <Avatar alt={alt} src={imgUrl} className={className} />;
  }
}

VmsAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  photoId: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.any,
  avatar: PropTypes.string,
};

export default withStyles(styles)(VmsAvatar);
