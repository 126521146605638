import PropTypes from 'prop-types';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import i18n from '../../../assets/i18n';
import { CSVLink } from 'react-csv';

const styles = (theme) => ({});

const VmsExportLink = (props) => {
  const { filename, downloadData } = props;

  return (
    <Tooltip title={i18n.t('export')}>
      <CSVLink
        headers={downloadData.headers}
        data={downloadData.data}
        filename={filename}
        separator={';'}
      >
        <IconButton aria-label="Download">
          <CloudDownload />
        </IconButton>
      </CSVLink>
    </Tooltip>
  );
};

VmsExportLink.propTypes = {
  classes: PropTypes.object.isRequired,
  downloadData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
  }).isRequired,
  filename: PropTypes.string.isRequired,
};

export default withStyles(styles)(VmsExportLink);
