import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { colors } from '../../assets/UISettings';
import moment from 'moment';

const styles = (theme) => ({
  // rbcBottomRightCorner is defined in 'calendar.css' as ::after element of '.rbc-day-bg'
  rbcTopLeftCorner: {
    content: '',
    display: 'block',
    height: '10pt',
    width: '10pt',
    color: colors.whiteFour,
    borderTop: '2px solid',
    borderLeft: '2px solid',
  },
  rbcDayHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 24,
    color: colors.warmGrey,
  },
  rbcDayHeaderOffRange: {
    color: colors.pinkishGrey,
  },
  rbcDayLink: {
    color: 'inherit',
    padding: 0, // disabled padding because
    width: 24,
    height: 24,
    border: 'none',
    outline: 'none',
    backgroundColor: 'inherit', // inherit background color because of safari gray background issue
    //iPhone 5/SE
    '@media (max-width: 360px)': {
      width: 14.825,
      height: 14.825,
    },
    //Other small devices
    '@media (min-width: 361px) and (max-width: 420px)': {
      width: 20,
      height: 20,
    },
  },
  rbcDayLinkToday: {
    display: 'block',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: colors.orangeYellow,
  },
});

class CalendarMonthHeader extends Component {
  render() {
    const { classes } = this.props;
    const isOffRange = this.props.isOffRange;
    const dayNumber = this.props.date.getDate();
    const isToday = moment(this.props.date).isSame(moment(), 'day');
    // const isToday = dayNumber === 1;
    const onDrillDown = this.props.onDrillDown;

    return (
      <div
        className={`${classes.rbcDayHeader} ${
          isOffRange ? classes.rbcDayHeaderOffRange : ''
        }`}
      >
        <span className={classes.rbcTopLeftCorner} />
        <button
          onClick={onDrillDown}
          className={`${classes.rbcDayLink} ${
            isToday ? classes.rbcDayLinkToday : ''
          }`}
        >
          {dayNumber}
        </button>
      </div>
    );
  }
}

CalendarMonthHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarMonthHeader);
