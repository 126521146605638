import AbstractService from './AbstractService';
import Auth from '../modules/Auth';

class LogsServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'logs/';
  }

  getLogs(date) {
    const endpoint = `logs/${date ? `?date=${date}` : ''}`;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getLogsFiles() {
    const endpoint = 'logs/files';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }
}

const LogsService = new LogsServiceImpl();

export default LogsService;
