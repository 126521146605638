import { IconButton, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const FilterMenuButton = ({ onClick }) => {
  const [t] = useTranslation();
  return (
    <Tooltip title={t('filter')}>
      <IconButton aria-label={t('filter')} onClick={onClick}>
        <MoreVert />
      </IconButton>
    </Tooltip>
  );
};

export default FilterMenuButton;
