import { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import {
  ProfileCardContactHalf,
  ProfileCardSettingsHalf,
  ProfileAssistantDialog,
  VmsInfoBar,
  PasswordDialog,
  PinDialog,
} from 'components';
import Person from '@material-ui/icons/Person';
import {
  UserService,
  SecurityItemsService,
  ConfigurationService,
} from 'services';
import i18n from 'assets/i18n';
import { openSnackbar } from 'components/common/bars/SnackBar';
import Auth from 'modules/Auth';
import QRCode from 'qrcode.react';
import { elevation } from 'assets/UISettings';
import UserEditor, {
  createEmptyUserEditorState,
  createUserEditorState,
} from 'containers/settings/users/UserEditor';
import { user_roles, notification_type, type_constants } from 'AppSettings';
import debounce from 'debounce';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import commonStyles from 'assets/jss/commonStyles';
import Aux from 'hoc/Auxiliary';
import ProfileCardVisitedPlants from 'components/profile/ProfileCardVisitedPlants';
import UserDocumentModel from 'services/models/UserDocumentModel';
import produce from 'immer';

const emptyPassword = () => {
  return {
    oldPassword: '',
    newPassword: '',
    newPasswordVerify: '',
  };
};

const emptyPin = () => {
  return {
    pin: '',
  };
};

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    flexGrow: 1,
  },
  editorContainer: {
    position: 'absolute',
    marginTop: 48,
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    padding: theme.spacing(2),
  },
  flex: {
    flex: 1,
  },
  loginModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  paperQR: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  title: {
    color: theme.palette.primary4,
  },
});

class ProfilePage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    const user = Auth.getUser();
    this.state = {
      //Get these 2 values early to avoid unwanted layout shifts
      name: user.name,
      role: user.role,
      phone: '',
      email: '',
      assistant: null,
      deputy: null,
      avatar: null,
      photoId: undefined,
      enabledNotifications: [],
      emailNotification: false,
      pushNotification: false,
      smsNotification: false,
      openAssistantDialog: false,
      openDeputyDialog: false,

      assistantSuggestions: [],
      assistantSuggestionValue: '',
      selectedAssistant: {},
      canSaveAssistant: false,
      deputySuggestionValue: '',
      userEditState: createEmptyUserEditorState(),
      user: {},
      dialogs: {
        openAssistantDialog: false,
        openDeputyDialog: false,
        openUserEditorDialog: false,
        openPasswordDialog: false,
        openPinDialog: false,
      },
      securityItem: undefined,
      visitedPlants: [],
      passwordData: emptyPassword(),
      pinData: emptyPin(),
    };
    this.userId = user._id;
  }

  loadDepartments = () => {
    ConfigurationService.getTypesForConfiguration('department')
      .then((response) => {
        const departments = { key: [], value: [] };
        response.forEach((department) => {
          departments.key.push(department.key);
          departments.value.push(department.key);
        });

        this.setState((prevState) =>
          produce(prevState, (draft) => {
            draft.userEditState.departments = departments;
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleChangeNotifications = (type) => (event) => {
    let enabledNotifications = this.state.enabledNotifications;
    switch (type) {
      case notification_type.PUSH: {
        if (event.target.checked) {
          enabledNotifications.push({ type: notification_type.PUSH });
        } else {
          enabledNotifications = enabledNotifications.filter((n) => {
            return n.type !== notification_type.PUSH;
          });
        }
        break;
      }
      case notification_type.SMS: {
        if (event.target.checked) {
          enabledNotifications.push({ type: notification_type.SMS });
        } else {
          enabledNotifications = enabledNotifications.filter((n) => {
            return n.type !== notification_type.SMS;
          });
        }
        break;
      }
      case notification_type.EMAIL: {
        if (event.target.checked) {
          enabledNotifications.push({ type: notification_type.EMAIL });
        } else {
          enabledNotifications = enabledNotifications.filter((n) => {
            return n.type !== notification_type.EMAIL;
          });
        }
        break;
      }
      default:
        break;
    }

    this.setState({
      enabledNotifications,
    });

    UserService.updateUserNotifications(this.userId, enabledNotifications)
      .then(function (response) {
        openSnackbar(i18n.t('profile_notifications_changed'));
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  componentDidMount() {
    this.loadProfile();
    this.loadTenants();
    if (!Auth.isUserVisitor()) {
      this.loadDepartments();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visitorView !== prevProps.visitorView) {
      this.loadTenants();
      this.loadProfile();
      this.loadDepartments();
    }
  }

  loadTenants = () => {
    const self = this;
    const { visitorView } = this.props;
    if (Auth.isUserVisitor() || visitorView) {
      UserService.getUsersTenants(this.userId)
        .then(function (response) {
          self.setState({
            visitedPlants: response,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  loadUserDocumentsById = async (user_id) => {
    try {
      const userDocuments = await UserService.getUserDocuments(user_id);
      return userDocuments
        .filter((ud) => ud.type === type_constants.PERSONAL_DOCUMENT)
        .map((doc) => {
          return UserDocumentModel.fromJson(doc);
        });
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
      return [];
    }
  };

  loadProfile = () => {
    const { visitorView } = this.props;
    if (!Auth.isUserVisitor()) {
      UserService.getUser(this.userId)
        .then(async (response) => {
          const files = await this.loadUserDocumentsById(this.userId);
          console.log('response', response);
          this.setState((prevState) => {
            return {
              assistant: response.assistant ? response.assistant : null,
              deputy: response.deputy ? response.deputy : null,
              phone: response.phone ? response.phone : '',
              email: response.email ? response.email : '',
              name: response.name ? response.name : '',
              avatar: response.avatar ? response.avatar : '',
              role: response.role,
              photoId: response.photo ? response.photo._id : undefined,
              enabledNotifications: response.enabledNotifications
                ? response.enabledNotifications
                : [],
              selectedAssistant: response.assistant ? response.assistant : {},
              assistantSuggestionValue:
                response.assistant && response.assistant.name
                  ? response.assistant.name
                  : '',
              selectedDeputy: response.deputy ? response.deputy : {},
              deputySuggestionValue:
                response.deputy && response.deputy.name
                  ? response.deputy.name
                  : '',
              securityItem: response.securityItem,
              user: response,
              files,
            };
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });

      if (!visitorView) {
        UserService.getUser(this.userId)
          .then((response) => {
            this.setState({
              //securityItem: response.securityItem,
            });
          })
          .catch(function (error) {
            console.log(error);
            openSnackbar(error.message);
          });
      }
    } else {
      UserService.getUser(this.userId)
        .then((response) => {
          this.setState({
            assistant: null,
            deputy: null,
            phone: response.phone !== undefined ? response.phone : '',
            email: response.email !== undefined ? response.email : '',
            avatar: response.avatar !== undefined ? response.avatar : '',
            role: response.role,
            name: response.name ? response.name : '',
            photoId: response.photo ? response.photo._id : undefined,
            enabledNotifications: response.enabledNotifications
              ? response.enabledNotifications
              : [],
            user: response,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });

      // Load security item for visitor
      SecurityItemsService.getActiveSecurityItem(this.userId)
        .then((response) => {
          this.setState({
            //securityItem: response.securityItem,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleDialogOpen = (name, open) => (event) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        if (['openPasswordDialog', 'openPinDialog'].includes(name)) {
          draft.dialogs[name] = open;

          if (name === 'openPasswordDialog' && !open) {
            draft.passwordData = emptyPassword();
          } else if (name === 'openPinDialog') {
            if (!open) {
              draft.pinData = emptyPin();
            } else {
              draft.pinData.pin = draft.userEditState.editData.pin;
            }
          }
        } else if (name === 'openUserEditorDialog' && open) {
          draft.dialogs[name] = open;
          draft.userEditState = createUserEditorState(
            draft.user,
            draft.userEditState.departments,
            draft.files
          );
        } else {
          draft.dialogs[name] = open;

          // Dialog custom logic
          if (name === 'openAssistantDialog' && !open) {
            draft.canSaveAssistant = false;
            draft.selectedAssistant = {};
          }
        }
      })
    );
  };

  handleUserDialogSave = () => {
    this.handleDialogOpen('openUserEditorDialog', false)();
    this.loadProfile();
  };

  handleAssistantDialogOpen = () => {
    this.setState({
      openAssistantDialog: true,
    });
  };

  handleDeputyDialogOpen = () => {
    this.setState({
      openDeputyDialog: true,
    });
  };

  handleAssistantDialogClose = () => () => {
    this.setState({
      openAssistantDialog: false,
      canSaveAssistant: false,
      selectedAssistant: {},
    });
    this.loadProfile();
  };

  handleAssistantSave = () => {
    const self = this;
    this.setState({
      openAssistantDialog: false,
      canSaveAssistant: false,
    });
    UserService.getUser(this.state.selectedAssistant._id)
      .then(function (response) {
        UserService.updateUserAssistant(self.userId, response)
          .then(function (response) {
            self.setState({
              selectedAssistant: {},
            });
            openSnackbar(i18n.t('profile_assistant_changed'));
            self.loadProfile();
          })
          .catch(function (error) {
            console.log(error);
            openSnackbar(error.message);
          });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleAssistantDelete = () => {
    const self = this;
    this.setState({
      openAssistantDialog: false,
      canSaveAssistant: false,
    });

    UserService.updateUserAssistant(self.userId, null)
      .then(function (response) {
        self.setState({
          selectedAssistant: {},
        });
        openSnackbar(i18n.t('profile_assistant_changed'));
        self.loadProfile();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleDeputyClose = () => () => {
    this.setState({
      openDeputyDialog: false,
      canSaveAssistant: false,
      selectedAssistant: {},
    });
    this.loadProfile();
  };

  handleDeputySave = () => {
    const self = this;
    this.setState({
      openDeputyDialog: false,
      canSaveAssistant: false,
    });
    UserService.getUser(this.state.selectedAssistant._id)
      .then(function (response) {
        UserService.updateUserDeputy(self.userId, response)
          .then(function (response) {
            self.setState({
              selectedAssistant: {},
            });
            openSnackbar(i18n.t('profile_deputy_changed'));
            self.loadProfile();
          })
          .catch(function (error) {
            console.log(error);
            openSnackbar(error.message);
          });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleDeputyDelete = () => {
    const self = this;
    this.setState({
      openDeputyDialog: false,
      canSaveAssistant: false,
    });
    UserService.updateUserDeputy(self.userId, null)
      .then(function (response) {
        self.setState({
          selectedAssistant: {},
        });
        openSnackbar(i18n.t('profile_deputy_changed'));
        self.loadProfile();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  // ### START - Assistant Search in autocomplete
  handleAssistantSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      canSaveAssistant: false,
    });
    this._getAssistantSuggestions(value);
  };

  handleAssistantSuggestionSelected = (suggestion) => (event) => {
    this.setState({
      selectedAssistant: {
        avatar: suggestion.avatar,
        email: suggestion.email,
        _id: suggestion._id,
        phone: suggestion.phone,
      },
      selectedDeputy: {
        avatar: suggestion.avatar,
        email: suggestion.email,
        _id: suggestion._id,
        phone: suggestion.phone,
      },
      assistantSuggestionValue: suggestion.name,
      deputySuggestionValue: suggestion.name,
      canSaveAssistant: true,
    });
  };

  handleAssistantSuggestionChange = (name, value) => (event) => {
    //const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (fieldValue !== undefined) {
      this.setState({
        assistantSuggestionValue: fieldValue,
        deputySuggestionValue: fieldValue,
      });
    }
  };

  // debounced function so it only gets suggestions every 500ms
  _getAssistantSuggestions = debounce((value) => {
    const self = this;
    UserService.getUserSuggestions(value, true)
      .then(function (response) {
        console.log(response);
        self.setState({
          assistantSuggestions: response.filter((u) => {
            return u.role !== user_roles.VISITOR;
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  }, 500);

  handleAssistantSuggestionsClearRequested = () => (event) => {};

  // ### END - Assistant Search in Calendar name autocomplete

  // ### START - Edit user profile
  handleEditUserEditContainer = (userEditState) => {
    this.setState({
      userEditState: userEditState,
    });
  };
  // ### END - Edit user profile

  handleExitToDashboard = () => {
    this.props.history.go(-1);
  };

  changePassword = async () => {
    const {
      passwordData: { oldPassword, newPassword },
    } = this.state;
    try {
      const response = await UserService.changePassword(
        this.userId,
        oldPassword,
        newPassword
      );
      Auth.authenticateUser(response.token, response.refresh_token);
      openSnackbar(i18n.t('saved'));
      this.handleDialogOpen('openPasswordDialog', false)();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  handlePasswordChangeState = (name, value) => (event) => {
    const field = name || event.target.name;
    const fieldValue = value ?? event.target.value;
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.passwordData[field] = fieldValue;
      })
    );
  };

  changePin = async () => {
    const {
      pinData: { oldPin, newPin },
    } = this.state;
    try {
      await UserService.changePin(this.userId, oldPin, newPin);

      this.setState((prevState) =>
        produce(prevState, (draft) => {
          draft.userEditState.editData.pin = newPin;
        })
      );

      openSnackbar(i18n.t('saved'));
      this.handleDialogOpen('openPinDialog', false)();
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  handlePinChangeState = (name, value) => (event) => {
    const field = name || event.target.name;
    const fieldValue = value ?? event.target.value;
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.pinData[field] = fieldValue;
      })
    );
  };

  closePasswordOrPinDialogInUserEditor = (type) => () => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.userEditState[type] = false;
      })
    );
  };

  getBackAction = () => {
    const {
      userEditState: { pinDialogOpen, passwordDialogOpen },
      dialogs: { openUserEditorDialog },
    } = this.state;
    if (passwordDialogOpen) {
      return this.closePasswordOrPinDialogInUserEditor('passwordDialogOpen');
    } else if (pinDialogOpen) {
      return this.closePasswordOrPinDialogInUserEditor('pinDialogOpen');
    } else if (openUserEditorDialog) {
      return this.handleDialogOpen('openUserEditorDialog', false);
    } else {
      return this.handleExitToDashboard;
    }
  };

  render() {
    const { classes, visitorView } = this.props;
    const {
      assistant,
      assistantSuggestionValue,
      canSaveAssistant,
      selectedAssistant,
      deputy,
      phone,
      email,
      name,
      userEditState,
      enabledNotifications,
      openAssistantDialog,
      openDeputyDialog,
      deputySuggestionValue,
      selectedDeputy,
      photoId,
      visitedPlants,
      avatar,
      role,
      dialogs,
      passwordData,
      pinData,
    } = this.state;
    const { openUserEditorDialog, openPasswordDialog, openPinDialog } = dialogs;

    return (
      <div className={classes.root}>
        <VmsInfoBar
          backMode={true}
          onBack={this.getBackAction()}
          buttonDataCy="profile-button-back"
        />
        <div className={classes.editorContainer}>
          {(!openUserEditorDialog ||
            (openUserEditorDialog && isWidthUp('sm', this.props.width))) && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
              className={classes.container}
            >
              <ProfileCardContactHalf
                changeButtonDataCy="profile-button-change"
                isUser
                name={name}
                email={email}
                avatar={avatar}
                phone={phone}
                photoId={photoId}
                header={<Person />}
                onChange={this.handleDialogOpen}
              />
              {role !== user_roles.GLOBAL_ADMIN && (
                <Aux>
                  <ProfileCardSettingsHalf
                    push={
                      enabledNotifications.filter((n) => {
                        return n.type === notification_type.PUSH;
                      }).length > 0
                    }
                    email={
                      enabledNotifications.filter((n) => {
                        return n.type === notification_type.EMAIL;
                      }).length > 0
                    }
                    sms={
                      enabledNotifications.filter((n) => {
                        return n.type === notification_type.SMS;
                      }).length > 0
                    }
                    changeEmail={this.handleChangeNotifications(
                      notification_type.EMAIL
                    )}
                    changePush={this.handleChangeNotifications(
                      notification_type.PUSH
                    )}
                    changeSms={this.handleChangeNotifications(
                      notification_type.SMS
                    )}
                  />
                  {role !== user_roles.ASSISTANT && (
                    <ProfileAssistantDialog
                      open={openAssistantDialog}
                      title={
                        assistant !== null
                          ? i18n.t('profile_change_assistant')
                          : i18n.t('profile_add_assistant')
                      }
                      selectedItem={selectedAssistant}
                      inputValue={assistantSuggestionValue}
                      onClose={this.handleAssistantDialogClose}
                      onSave={this.handleAssistantSave}
                      onChange={this.handleAssistantSuggestionChange}
                      inputName="assistant"
                      suggestionService={(searchValue) =>
                        UserService.getUserSuggestions(searchValue, true)
                      }
                      filterSuggestionsResponse={(s) => {
                        return s.filter((u) => {
                          return u.role === user_roles.ASSISTANT;
                        });
                      }}
                      suggestionItemValue={(s) => {
                        return s.name;
                      }}
                      onSuggestionsClearRequested={
                        this.handleAssistantSuggestionsClearRequested
                      }
                      onSuggestionSelected={
                        this.handleAssistantSuggestionSelected
                      }
                      saveDisabled={canSaveAssistant}
                      deleteDisabled={!assistant}
                      onDelete={this.handleAssistantDelete}
                    />
                  )}
                  <ProfileAssistantDialog
                    open={openDeputyDialog}
                    title={
                      deputy !== null
                        ? i18n.t('profile_change_deputy')
                        : i18n.t('profile_add_deputy')
                    }
                    item={deputy}
                    onClose={this.handleDeputyClose}
                    onSave={this.handleDeputySave}
                    selectedItem={selectedDeputy}
                    inputValue={deputySuggestionValue}
                    onChange={this.handleAssistantSuggestionChange}
                    inputName="deputy"
                    suggestionService={(searchValue) =>
                      UserService.getUserSuggestions(searchValue, true)
                    }
                    filterSuggestionsResponse={(s) => {
                      return s.filter((u) => {
                        return u.role !== user_roles.VISITOR;
                      });
                    }}
                    suggestionItemValue={(s) => {
                      return s.name;
                    }}
                    onSuggestionsClearRequested={
                      this.handleAssistantSuggestionsClearRequested
                    }
                    onSuggestionSelected={
                      this.handleAssistantSuggestionSelected
                    }
                    saveDisabled={canSaveAssistant}
                    deleteDisabled={!deputy}
                    onDelete={this.handleDeputyDelete}
                  />
                  {![user_roles.VISITOR, user_roles.ASSISTANT].includes(role) &&
                    !visitorView &&
                    (assistant !== null ? (
                      <ProfileCardContactHalf
                        changeButtonDataCy="profile-button-change-assistant"
                        avatar={assistant.avatar}
                        onChange={this.handleAssistantDialogOpen}
                        photoId={assistant.photo}
                        name={assistant.name}
                        email={assistant.email}
                        phone={assistant.phone}
                        header={
                          <Typography className={classes.title}>
                            {i18n.t('profile_assistant')}
                          </Typography>
                        }
                      />
                    ) : (
                      <ProfileCardContactHalf
                        changeButtonDataCy="profile-button-add-assistant"
                        empty
                        onChange={this.handleAssistantDialogOpen}
                        addNewButtonMessage={i18n.t('profile_add_assistant')}
                        header={
                          <Typography className={classes.title}>
                            {i18n.t('profile_assistant')}
                          </Typography>
                        }
                      />
                    ))}
                  {role !== user_roles.VISITOR &&
                    !visitorView &&
                    (deputy !== null ? (
                      <ProfileCardContactHalf
                        changeButtonDataCy="profile-button-change-deputy"
                        avatar={deputy.avatar}
                        onChange={this.handleDeputyDialogOpen}
                        photoId={deputy.photo}
                        name={deputy.name}
                        email={deputy.email}
                        phone={deputy.phone}
                        header={
                          <Typography className={classes.title}>
                            {i18n.t('profile_deputy')}
                          </Typography>
                        }
                      />
                    ) : (
                      <ProfileCardContactHalf
                        changeButtonDataCy="profile-button-add-deputy"
                        empty
                        onChange={this.handleDeputyDialogOpen}
                        addNewButtonMessage={i18n.t('profile_add_deputy')}
                        header={
                          <Typography className={classes.title}>
                            {i18n.t('profile_deputy')}
                          </Typography>
                        }
                      />
                    ))}
                  {this.state.securityItem && (
                    <Grid item xs={12} md={6}>
                      <Paper
                        className={classes.paperQR}
                        elevation={elevation.light}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <QRCode
                              value={this.state.securityItem}
                              size={254}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  )}
                  {(role === user_roles.VISITOR || visitorView) && (
                    <ProfileCardVisitedPlants plants={visitedPlants} />
                  )}
                </Aux>
              )}
            </Grid>
          )}
          <UserEditor
            open={openUserEditorDialog}
            onSave={this.handleUserDialogSave}
            onClose={this.handleDialogOpen('openUserEditorDialog', false)}
            onChange={this.handleEditUserEditContainer}
            userEditState={userEditState}
            xsPaperMode={true}
            visitorView={visitorView}
          />
          <PasswordDialog
            open={openPasswordDialog}
            dialogState={passwordData}
            onChange={this.handlePasswordChangeState}
            onPasswordChange={this.changePassword}
            onClose={this.handleDialogOpen('openPasswordDialog', false)}
            xsPaperMode={true}
          />
          <PinDialog
            open={openPinDialog}
            dialogState={pinData}
            onChange={this.handlePinChangeState}
            onPinChange={this.changePin}
            onClose={this.handleDialogOpen('openPinDialog', false)}
            xsPaperMode={true}
          />
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  visitorView: PropTypes.bool.isRequired,
};

export default compose(withWidth(), withStyles(styles))(ProfilePage);
