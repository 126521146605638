import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Event from '@material-ui/icons/Event';
import FolderOpen from '@material-ui/icons/FolderOpen';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { VmsDialogRowSelect } from './rows';
import VmsDialog from './VmsDialog';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { notification_type } from 'AppSettings';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

const sendNotificationDialog = (props) => {
  const {
    classes,
    onSend,
    onClose,
    onChange,
    open,
    availableTemplates,
    dialogState,
  } = props;
  const { selectedCategory, selectedTemplate, sendingNotification } =
    dialogState;

  // Transform available types to object required by VmsDialogRowSelect component
  let typeValues = { key: [], value: [] };
  const typeKeys = Object.keys(notification_type);
  typeKeys.forEach((key, idx) => {
    typeValues.key[idx] = notification_type[key];
    typeValues.value[idx] = i18n.t(
      `app_settings_type_${notification_type[key]}`
    );
  });

  let templateValues = { key: [], value: [] };
  let idx = 0;
  availableTemplates.forEach((temp) => {
    if (temp.category === dialogState.selectedCategory) {
      templateValues.key[idx] = temp._id;
      templateValues.value[idx] = temp.name;
      idx++;
    }
  });

  const sendDisabled =
    dialogState.selectedCategory === '' || dialogState.selectedTemplate === '';

  return (
    <VmsDialog
      open={open}
      dialogHeaderTitle={i18n.t('send_notification_dialog_title')}
      onClose={onClose}
      dialogHeaderIcon={<Event />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={sendingNotification ? i18n.t('sending') : i18n.t('send')}
      buttonAction={onSend}
      buttonDisabled={sendingNotification || sendDisabled}
    >
      <VmsDialogRowSelect
        rowClass={classes.dialogRow}
        iconLeft={<FolderOpen />}
        placeholder={i18n.t('send_notification_dialog_category_placeholder')}
        onChange={onChange('selectedCategory')}
        selectTypes={typeValues}
        selectedType={selectedCategory}
      />
      <VmsDialogRowSelect
        rowClass={classes.dialogRow2}
        iconLeft={<InsertDriveFile />}
        placeholder={i18n.t('send_notification_dialog_template_placeholder')}
        onChange={onChange('selectedTemplate')}
        selectTypes={templateValues}
        selectedType={selectedTemplate}
        disabled={selectedCategory === ''}
      />
    </VmsDialog>
  );
};

sendNotificationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  availableTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
  dialogState: PropTypes.shape({
    selectedCategory: PropTypes.string.isRequired,
    selectedTemplate: PropTypes.string.isRequired,
    sendingNotification: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withStyles(styles)(sendNotificationDialog);
