class VisitModel {
  constructor(
    user_id,
    visitorTypes,
    group,
    delegate,
    timeFrom,
    timeTo,
    host_id,
    purpose,
    note,
    events,
    status,
    wifi_username,
    wifi_password,
    wifi_enabled,
    option1,
    option2,
    porterNote,
    accompanimentRequired,
    confirmationRequired,
    authenticationRequired,
    signInSelfRegistration,
    daysOff,
    datesOff
  ) {
    this.user_id = user_id;
    this.group = group;
    this.delegate = delegate;
    this.timeFrom = timeFrom;
    this.timeTo = timeTo;
    this.host_id = host_id;
    this.purpose = purpose;
    this.note = note;
    this.events = events;
    this.status = status;
    this.wifi_username = wifi_username;
    this.wifi_password = wifi_password;
    this.wifi_enabled = wifi_enabled;
    this.option1 = option1;
    this.option2 = option2;
    this.porterNote = porterNote;
    this.accompanimentRequired = accompanimentRequired;
    this.confirmationRequired = confirmationRequired;
    this.visitorTypes = visitorTypes;
    this.authenticationRequired = authenticationRequired;
    this.signInSelfRegistration = signInSelfRegistration;
    this.daysOff = daysOff;
    this.datesOff = datesOff;
  }

  toJSON() {
    const data = {
      user: this.user_id,
      group: this.group,
      delegate: this.delegate,
      timeFrom: this.timeFrom ? this.timeFrom.toISOString() : undefined,
      timeTo: this.timeTo ? this.timeTo.toISOString() : undefined,
      host: this.host_id,
      purpose: this.purpose,
      note: this.note,
      events: this.events,
      status: this.status,
      wifi_username: this.wifi_username,
      wifi_password: this.wifi_password,
      wifi_enabled: this.wifi_enabled,
      option1: this.option1,
      option2: this.option2,
      porterNote: this.porterNote,
      accompanimentRequired: this.accompanimentRequired,
      confirmationRequired: this.confirmationRequired,
      visitorTypes: this.visitorTypes,
      authenticationRequired: this.authenticationRequired,
      daysOff: this.daysOff,
      datesOff: this.datesOff,
      signInSelfRegistration: this.signInSelfRegistration,
    };
    return JSON.stringify(data);
  }
}

export default VisitModel;
