class TemplateModel {
  constructor(
    id,
    name,
    lang,
    category,
    type,
    title,
    body,
    is_default,
    required_for_entrance,
    expires_in,
    templateType
  ) {
    this.id = id;
    this.name = name;
    this.lang = lang;
    this.category = category;
    this.type = type;
    this.title = title;
    this.body = body;
    this.is_default = is_default;
    this.required_for_entrance = required_for_entrance;
    this.expires_in = expires_in;
    this.templateType = templateType;
  }

  toJSON() {
    const data = {
      name: this.name,
      lang: this.lang,
      category: this.category,
      type: this.type,
      title: this.title,
      body: this.body,
      is_default: this.is_default,
      required_for_entrance: this.required_for_entrance,
      expires_in: this.expires_in,
      templateType: this.templateType,
    };
    return JSON.stringify(data);
  }
}

TemplateModel.fromJson = function (json) {
  var obj = JSON.parse(json);
  return new TemplateModel(
    obj._id,
    obj.name,
    obj.lang,
    obj.category,
    obj.type,
    obj.title,
    obj.body,
    obj.is_default,
    obj.required_for_entrance,
    obj.expires_in,
    obj.templateType
  );
};

export default TemplateModel;
