import { Button, Grid, withStyles } from '@material-ui/core';
import { VmsButtonFAB } from 'components';
import DocumentsPage from 'containers/dashboard/documents/DocumentsPage';
import { useTranslation } from 'react-i18next';
import { Route, NavLink, useHistory } from 'react-router-dom';
import visitService from 'services/VisitService';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { VmsInput } from 'components';
import qrCodeImage from 'assets/images/qr_code.png';
import formImage from 'assets/images/form_with_signarute.png';
import EmployeeImage from 'assets/images/user_with_badge.png';
import printerImage from 'assets/images/printer_image.png';
import React, { useEffect, useState } from 'react';
import { openSnackbar } from 'components/common/bars/SnackBar';
import SideBarInfo from './pages/SideBarInfo';
import EnterPinPage from './pages/EnterPinPage';
import SelfRegistrationPage from './pages/SelfRegistrationPage';
import TabletLogin from './pages/TabletLogin';
import {
  ConfigurationService,
  EmailService,
  SecurityItemsService,
  SmsService,
  TemplateService,
} from 'services';
import {
  configuration_group,
  getFocusOnInput,
  isShellApp,
  template_categories,
  template_types,
} from 'AppSettings';
import Auth from 'modules/Auth';
import useLoadData from 'containers/dashboard/visitor/InviteVisitor/useLoadData';
import NotificationForVisitor from './pages/NotificationForVisitor';
import locales from 'assets/momentlocales';
import moment from 'moment';
const styles = (theme) => ({
  documentsWrapper: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexGrow: '1',
  },
  fab: {
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    position: 'fixed',
    margin: 0,
    color: theme.palette.common.white,
  },
  qrButton: {
    color: 'white',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: theme.spacing(16),
    width: '30%',
    fontSize: '1.3em',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  navLink: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: theme.spacing(16),
    width: '30%',
    textDecoration: 'none',
  },
  qrButtonImage: {
    height: 80,
    width: 80,
    marginRight: theme.spacing(),
    color: 'white',
  },
  qrWrapper: {
    height: '100%',
  },
  pinWrapper: {
    height: '40%',
  },
  button: {
    width: '100%',
    height: '100%',
    color: 'white',
    fontSize: '1.3em',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  text: {
    flexGrow: '1',
  },
  navLinkBack: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    backgroundColor: 'orange',
    borderRadius: '50%',
    color: 'white',
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '1.3em',
  },
  active: {
    position: 'absolute',
  },
  vmsInput: {
    marginLeft: '30%',
    width: '40%',
    marginTop: theme.spacing(4),
  },
  vmsHeader: {
    width: '100%',
    textAlign: 'center',
  },
  sideBar: {
    width: '200px',
    backgroundColor: 'red',
  },
  wholePageWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    gap: '1em',
  },
  form: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '90%',
    textAlign: 'center',
    height: '70%',
    alignContent: 'space-evenly',
    display: 'grid',
  },
  containeros: {
    display: 'grid',
    placeItems: 'center',
    height: '80%',
  },
});

const TabletMainPage = ({ user, classes, enableScanner, logOut, login }) => {
  const [t] = useTranslation();

  const [input, setInput] = useState('');

  const [tenantConfig, setTenantConfig] = useState([]);

  const navigate = useHistory();

  const [visitorType] = useLoadData();

  useEffect(() => {
    loadData();
    if (isShellApp() === true) {
      document.body.addEventListener('click', getFocusOnInput);
    }
  }, []);

  const loadData = async () => {
    try {
      setTenantConfig(
        await ConfigurationService.getConfigurationsForGroup(
          configuration_group.TENANT_SETTINGS
        )
      );
    } catch (e) {
      console.log(e);
    }
  };
  const handleSendPin = async () => {
    if (input === '') {
      openSnackbar(t('please_enter_contact_value'));
      return;
    }
    const data = {
      input: input,
    };
    try {
      const response = await visitService.sendPin(data);
      if (!response?.message && !response?.error) {
        sendNotification(response);
        navigate.push('/enterPin');
        setInput('');
      } else {
        openSnackbar(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendNotification = async (response) => {
    try {
      const company = await TemplateService.getCompanyData(
        Auth.getUser().tenantId
      );
      const visit = await visitService.getVisitByUser(response._id);
      const data = {
        user: {
          name: response.name,
        },
        invite: {
          arrival: visit.timeFrom,
          accessCode: response.code,
          accessCodeValidity: '20',
        },
        company: company,
      };
      if (response.email) {
        const replacedTemplate = await getTemplate(
          template_categories.EMAIL,
          template_types.ACCESS_CODE,
          response,
          data
        );
        if (replacedTemplate) {
          await EmailService.sendTemplate(response.email, replacedTemplate);
        } else {
          openSnackbar(t('email_template_not_found'));
        }
      }
      if (response.phone) {
        const replacedTemplate = await getTemplate(
          template_categories.SMS,
          template_types.ACCESS_CODE,
          response,
          data
        );
        if (replacedTemplate) {
          await SmsService.sendTemplate(response.phone, replacedTemplate);
        } else {
          openSnackbar(t('sms_template_not_found'));
        }
      }
    } catch (e) {
      console.log(e);
      openSnackbar(e.message);
    }
  };

  const getTemplate = async (templateCategory, template_type, user, data) => {
    try {
      let templates = await TemplateService.getTemplatesByCategoryAndType(
        templateCategory,
        template_type
      );
      if (!templates.length) {
        return null;
      }

      let template = await TemplateService.findNotificationTemplateByLanguage(
        templates,
        user.language,
        Auth.getUser().language
      );
      data.invite.arrival = moment(data.invite.arrival)
        .locale(locales[template.lang])
        .format('LLLL');
      data.invite.arrival = moment(data.invite.arrival)
        .locale(locales[template.lang])
        .format('LLLL');
      return await TemplateService.replaceTemplate(template, data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {!user ? (
        <>
          <Route
            exact
            path="/enterPin"
            render={() => (
              <EnterPinPage
                history={navigate}
                login={login}
                classes={classes}
              />
            )}
          />
          <Route
            exact
            path="/notification"
            render={(props) => (
              <NotificationForVisitor
                classes={classes}
                host={props.location.state.host}
                history={navigate}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={() => (
              <Grid
                className={classes.qrWrapper}
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                {tenantConfig.find(
                  (item) =>
                    item.key === 'scan_qr_code_signing_tablet' &&
                    item.value === 'true'
                )?.value === 'true' && (
                  <Button
                    data-cy="signing-tablet-button-scan-qr"
                    color="primary"
                    variant="contained"
                    className={classes.qrButton}
                    onClick={enableScanner}
                  >
                    <img
                      className={classes.qrButtonImage}
                      src={qrCodeImage}
                      alt="QR"
                    />
                    <div className={classes.text}>{t('scan_qr_code')}</div>
                  </Button>
                )}
                {tenantConfig.find(
                  (item) =>
                    item.key === 'print_qr_code_signing_tablet' &&
                    item.value === 'true'
                )?.value === 'true' && (
                  <NavLink
                    className={classes.navLink}
                    activeClassName={classes.active}
                    to="/sendPin"
                  >
                    <Button
                      color="primary"
                      className={classes.button}
                      variant="contained"
                    >
                      <img
                        className={classes.qrButtonImage}
                        src={printerImage}
                        alt="printer"
                      />
                      <div className={classes.text}>{t('print_qr_code')}</div>
                    </Button>
                  </NavLink>
                )}
                {tenantConfig.find(
                  (item) =>
                    item.key === 'self-registration_signing_tablet' &&
                    item.value === 'true'
                )?.value === 'true' && (
                  <>
                    {visitorType.map((item) => {
                      return item.value.useForSelfRegistration ? (
                        <div
                          key={item.key}
                          className={classes.navLink}
                          activeClassName={classes.active}
                          onClick={() => {
                            navigate.push('/registration', {
                              visitorTypeParam: item.key,
                            });
                          }}
                        >
                          <Button
                            color="primary"
                            className={classes.button}
                            variant="contained"
                          >
                            <img
                              className={classes.qrButtonImage}
                              src={formImage}
                              alt="form"
                            />
                            <div className={classes.text}>
                              <p>{t('self-registration')}</p>
                              <p>{item.key}</p>
                            </div>
                          </Button>
                        </div>
                      ) : null;
                    })}
                  </>
                )}
                {tenantConfig.find(
                  (item) =>
                    item.key === 'employee_entrance_signing_tablet' &&
                    item.value === 'true'
                )?.value === 'true' && (
                  <NavLink
                    className={classes.navLink}
                    activeClassName={classes.active}
                    to="/employee"
                  >
                    <Button
                      color="primary"
                      className={classes.button}
                      variant="contained"
                    >
                      <img
                        className={classes.qrButtonImage}
                        src={EmployeeImage}
                        alt="employee"
                      />
                      <div className={classes.text}>
                        {t('employee_entrance')}
                      </div>
                    </Button>
                  </NavLink>
                )}
              </Grid>
            )}
          />

          <Route
            exact
            path="/sendPin"
            render={() => (
              <>
                <NavLink
                  activeClassName={classes.active}
                  to="/"
                  onClick={() => {
                    setInput('');
                  }}
                >
                  <ChevronLeft className={classes.navLinkBack} />
                </NavLink>
                <div className={classes.containeros}>
                  <div className={classes.form}>
                    <h1 className={(classes.vmsInput, classes.vmsHeader)}>
                      {t(
                        'Enter valid contact to recieve a security access code. You must have valid invitation.'
                      )}
                    </h1>
                    <VmsInput
                      placeholder={t('email_login_phone')}
                      className={classes.vmsInput}
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                      value={input}
                    />
                    <Grid
                      className={classes.pinWrapper}
                      direction="row"
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        color="primary"
                        className={classes.qrButton}
                        variant="contained"
                        onClick={handleSendPin}
                      >
                        <div className={classes.text}>
                          {t('send_security_access_code')}
                        </div>
                      </Button>
                    </Grid>
                  </div>
                </div>
              </>
            )}
          />
          <Route
            path="/registration"
            render={(props) => (
              <SelfRegistrationPage
                login={login}
                classes={classes}
                navigate={navigate}
                findTemplate={getTemplate}
                visitorTypeParam={props.location.state.visitorTypeParam}
              />
            )}
          />
          <Route
            exact
            path="/employee"
            render={() => (
              <TabletLogin
                classes={classes}
                login={login}
                navigate={navigate}
              />
            )}
          />
        </>
      ) : (
        <>
          <div className={classes.wholePageWrapper}>
            <SideBarInfo user={user} history={navigate} />
            <div className={classes.documentsWrapper}>
              <DocumentsPage user={user} />
              <VmsButtonFAB
                variant="extended"
                customStyle={classes.fab}
                onClick={() => {
                  navigate.push('/');
                  logOut();
                }}
              >
                {t('menu_logout')}
              </VmsButtonFAB>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(TabletMainPage);
