import PropTypes from 'prop-types';
import { withStyles, SwipeableDrawer, Hidden } from '@material-ui/core';
import commonStyles from 'assets/jss/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
});

const vmsDrawer = (props) => {
  const { classes, toggleDrawer, clickCloses } = props;
  let onClick = undefined;
  let onKeyDown = undefined;
  if (clickCloses) {
    onClick = toggleDrawer(false);
    onKeyDown = toggleDrawer(false);
  }
  return (
    <Hidden mdUp>
      <SwipeableDrawer
        open={props.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div tabIndex={0} role="button" onClick={onClick} onKeyDown={onKeyDown}>
          {props.children}
        </div>
      </SwipeableDrawer>
    </Hidden>
  );
};

vmsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  clickCloses: PropTypes.bool,
  children: PropTypes.node,
};

export default withStyles(styles)(vmsDrawer);
