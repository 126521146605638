import { Component } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { withStyles, Button, IconButton } from '@material-ui/core';
import { colors } from '../../assets/UISettings';
import i18n from '../../assets/i18n';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },
  viewButton: {
    margin: '0px 4px 12px 4px',
    color: colors.white,
  },
  navigationButton: {
    color: colors.warmGrey,
  },
  controlsWrapper: {
    display: 'flex',
  },
});

class CalendarToolbar extends Component {
  navigate = (action) => {
    this.props.onNavigate(action);
  };

  changeView = (action) => {
    this.props.onView(action);
  };

  render() {
    const { classes } = this.props;
    const views = this.props.views;
    const view = this.props.view;

    return (
      <div className={classes.toolBar}>
        <div className={classes.controlsWrapper}>
          <IconButton
            data-cy="meeting-rooms-button-previous"
            className={classes.navigationButton}
            onClick={() => {
              this.navigate('PREV');
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Button
            data-cy="meeting-rooms-button-today"
            className={classes.navigationButton}
            onClick={() => {
              this.navigate('TODAY');
            }}
          >
            {this.props.label}
          </Button>
          <IconButton
            data-cy="meeting-rooms-button-next"
            className={classes.navigationButton}
            onClick={() => {
              this.navigate('NEXT');
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
        <div className={classes.controlsWrapper}>
          {views.map((name) => (
            <Button
              data-cy={`meeting-rooms-button-${name}`}
              key={name}
              className={classes.viewButton}
              variant="contained"
              color={view === name ? 'primary' : undefined}
              onClick={() => this.changeView(name)}
            >
              {i18n.t(`calendar_view_${name}`)}
            </Button>
          ))}
        </div>
      </div>
    );
  }
}

CalendarToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarToolbar);
