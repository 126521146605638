import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Paper,
  Typography,
  Button,
  Hidden,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { elevation } from '../../assets/UISettings';
import i18n from '../../assets/i18n';
import { VmsAvatar } from 'components';
import classNames from 'classnames';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    height: '100%',
    position: 'relative',
  },
  color: {
    color: theme.palette.primary4,
  },
  contact: {
    color: theme.palette.primary4,
    paddingLeft: theme.spacing(),
  },
  contactWrapper: {
    width: '100%',
  },
  avatarSmall: {
    width: 41,
    height: 38,
  },
  avatarLarge: {
    width: 110,
    height: 110,
  },
  changeButton: {
    textTransform: 'none',
  },
  centerText: {
    flex: 1,
  },
  fab: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  header: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: theme.spacing(),
  },
  contentWrapper: {
    flex: 1,
  },
});

const ProfileCardContactHalf = (props) => {
  const {
    classes,
    isUser,
    name,
    email,
    phone,
    header,
    empty,
    addNewButtonMessage,
    onChange,
    photoId,
    avatar,
    changeButtonDataCy,
  } = props;
  return (
    <Grid item xs={12} md={6} className={classes.grid}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <Grid container direction="row" alignItems="stretch" wrap="nowrap">
          {!empty && (
            <Hidden smUp>
              <Grid item>
                <VmsAvatar
                  avatar={avatar}
                  photoId={photoId}
                  alt="image_avatar"
                  className={classes.avatarSmall}
                />
              </Grid>
            </Hidden>
          )}
          {!empty && (
            <Hidden xsDown>
              <Grid item>
                <VmsAvatar
                  avatar={avatar}
                  photoId={photoId}
                  alt="image_avatar"
                  className={classes.avatarLarge}
                />
              </Grid>
            </Hidden>
          )}
          <Grid
            item
            container
            className={classes.contentWrapper}
            direction="column"
            alignItems="stretch"
            justifyContent="space-between"
            wrap="nowrap"
            zeroMinWidth
          >
            {!empty && (
              <Grid item container>
                <Grid
                  item
                  container
                  className={classes.centerText}
                  zeroMinWidth
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item zeroMinWidth className={classes.contactWrapper}>
                      <Typography
                        variant="body1"
                        noWrap
                        className={classes.contact}
                      >
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item zeroMinWidth className={classes.contactWrapper}>
                      <Typography
                        variant="body1"
                        noWrap
                        className={classes.contact}
                      >
                        {email}
                      </Typography>
                    </Grid>
                    <Grid item zeroMinWidth className={classes.contactWrapper}>
                      <Typography
                        variant="body1"
                        noWrap
                        className={classes.contact}
                      >
                        {phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden xsDown>
                  <Grid item className={classes.color}>
                    {header}
                  </Grid>
                </Hidden>
              </Grid>
            )}
            <Grid
              item
              container
              direction={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
              spacing={2}
            >
              <Grid item>
                {!empty && (
                  <Hidden xsDown>
                    {isUser ? (
                      <>
                        <Button
                          data-cy={
                            email
                              ? 'profile-button-change-password'
                              : 'profile-button-change-pin'
                          }
                          color="primary"
                          className={classes.changeButton}
                          onClick={onChange(
                            email ? 'openPasswordDialog' : 'openPinDialog',
                            true
                          )}
                        >
                          {i18n.t(email ? 'profile_password' : 'profile_pin')}
                        </Button>
                        <Button
                          data-cy={changeButtonDataCy}
                          color="primary"
                          className={classes.changeButton}
                          onClick={onChange('openUserEditorDialog', true)}
                        >
                          {i18n.t('profile_change')}
                        </Button>
                      </>
                    ) : (
                      <Button
                        data-cy={changeButtonDataCy}
                        color="primary"
                        className={classes.changeButton}
                        onClick={onChange}
                      >
                        {i18n.t('profile_change')}
                      </Button>
                    )}
                  </Hidden>
                )}
                {empty && (
                  <Button
                    data-cy={changeButtonDataCy}
                    color="primary"
                    className={classes.changeButton}
                    onClick={onChange}
                  >
                    {addNewButtonMessage}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!empty && (
          <Hidden smUp>
            <IconButton
              className={classes.fab}
              onClick={
                isUser ? onChange('openUserEditorDialog', true) : onChange
              }
            >
              <KeyboardArrowRight className={classes.color} />
            </IconButton>
            <Typography
              variant="body1"
              noWrap
              className={classNames([classes.contact, classes.header])}
            >
              {header}
            </Typography>
          </Hidden>
        )}
      </Paper>
    </Grid>
  );
};

ProfileCardContactHalf.propTypes = {
  classes: PropTypes.object.isRequired,
  isUser: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  header: PropTypes.object,
  empty: PropTypes.bool,
  photoId: PropTypes.string,
  addNewButtonMessage: PropTypes.string,
  onChange: PropTypes.func,
  avatar: PropTypes.string,
  changeButtonDataCy: PropTypes.string,
};

export default withStyles(styles)(ProfileCardContactHalf);
