import Auth from 'modules/Auth';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TemplateService } from 'services';
import { openSnackbar } from '../bars';

const emptyData = {
  location: {},
};

const CompanyDataContext = createContext(emptyData);

const withCompanyDataContext = () => (Component) => (props) => {
  const companyDataContext = useContext(CompanyDataContext);

  return <Component {...props} companyDataContext={companyDataContext} />;
};

const CompanyDataProvider = ({ children }) => {
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [companyData, setCompanyData] = useState(emptyData);

  const revalidateCompanyData = useCallback(async () => {
    //Dont do anything if a visitor is logged in
    if (!Auth.isUserVisitor()) {
      setLoadingCompanyData(true);
      try {
        const data = await TemplateService.getCompanyData();
        setCompanyData(data);
      } catch (err) {
        console.log(err);
        openSnackbar(err.message);
      } finally {
        setLoadingCompanyData(false);
      }
    }
  }, []);

  const value = useMemo(() => {
    return {
      loadingCompanyData,
      companyData,
      revalidateCompanyData,
    };
  }, [loadingCompanyData, companyData, revalidateCompanyData]);

  useEffect(() => {
    revalidateCompanyData();
  }, [revalidateCompanyData]);

  return (
    <CompanyDataContext.Provider value={value}>
      {children}
    </CompanyDataContext.Provider>
  );
};

export { CompanyDataContext, withCompanyDataContext };
export default CompanyDataProvider;
