import {
  withStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import commonStyle from 'assets/jss/commonStyles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

const styles = (theme) => ({
  ...commonStyle(theme),
  menuItem: {
    margin: 0,
  },
});

const VmsSelect = (props) => {
  const {
    placeholder,
    onChange,
    classes,
    selectTypes,
    selectedType,
    inputName,
    disabled,
    multiple,
    renderValue,
    required,
    selectDataCy,
    itemDataCy,
  } = props;
  const [t] = useTranslation();
  const placeholderText = required
    ? `${placeholder} (${t('required')})`
    : placeholder;
  const [selectWidth, setSelectWidth] = useState();

  const setSelectWidthRef = useCallback(
    (el) => setSelectWidth(el?.offsetWidth),
    []
  );

  return (
    <Grid innerRef={setSelectWidthRef} item className={classes.rowContent}>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel
          htmlFor={inputName}
          shrink={false}
          style={{
            transform: 'translate(0, 8px) scale(1)',
          }}
          disabled={disabled}
        >
          {!selectedType ? placeholderText : null}
        </InputLabel>
        <Select
          data-cy={selectDataCy}
          multiple={multiple}
          value={selectedType}
          onChange={onChange}
          inputProps={{
            name: inputName,
            id: inputName,
          }}
          style={{
            marginTop: 0,
          }}
          MenuProps={{
            style: {
              width: selectWidth,
            },
          }}
          IconComponent={(prop) => (
            <ArrowDropDown
              className={classNames(classes.iconSelect, prop.className)}
            />
          )}
          disabled={disabled}
          renderValue={renderValue}
        >
          {selectTypes.key &&
            selectTypes.key.map((option, index) => {
              return (
                <MenuItem
                  data-cy={itemDataCy}
                  key={option}
                  selected={option === selectedType}
                  value={option}
                >
                  <ListItemText
                    className={classes.menuItem}
                    primaryTypographyProps={{ noWrap: true }}
                  >
                    {selectTypes.value && selectTypes.value[index]
                      ? selectTypes.value[index]
                      : option}
                  </ListItemText>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Grid>
  );
};

VmsSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  selectTypes: PropTypes.object.isRequired,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  inputName: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  selectDataCy: PropTypes.string,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles)(VmsSelect);
