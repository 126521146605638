import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  MobileStepper,
  Grid,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  List,
  Button,
  Typography,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import i18n from 'assets/i18n';
import FeedbackService from 'services/FeedbackService';
import { openSnackbar } from '../../common/bars/SnackBar';
import UserService from 'services/UserService';
import Parser from 'html-react-parser';
import { user_document_status_constants } from 'AppSettings';
import Aux from 'hoc/Auxiliary';
import commonStyles from 'assets/jss/commonStyles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import FeedbackDialog from './FeedbackDialog';

const styles = (theme) => ({
  ...commonStyles(theme),
  answers: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
    overflow: 'auto',
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  button: {
    margin: theme.spacing(),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  iconSmall: {
    fontSize: 20,
  },
  htmlParser: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
    overflow: 'auto',
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  stepper: {
    width: 'calc(100% + ' - theme.spacing(4) + 'px)',
  },
  grid: {
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
});

export const createEmptyFeedbackState = () => {
  return {
    activeIndex: 0,
    questionIDs: [],
    question: { _id: '', questionTitle: '', question: '', answers: [] },
    checked: [],
    isLoading: true,
    myAnswers: [],
    isOpenSubmit: false,
    json: { date_of_filling_in: {}, questions: [] },
  };
};

export const editFeedbackState = (feedback) => {
  return {
    activeIndex: feedback.activeIndex,
    questionIDs: feedback.questionIDs,
    question: feedback.question,
    checked: feedback.checked,
    isLoading: feedback.isLoading,
    myAnswers: feedback.myAnswers,
    isOpenSubmit: feedback.isOpenSubmit,
    json: feedback.json,
  };
};

class FeedbackDetail extends Component {
  componentDidMount() {
    this.loadDocument();
  }

  componentDidUpdate(prevProps) {
    if (this.props.feedbackId !== prevProps.feedbackId) {
      const { feedbackId, tenantId } = this.props;
      this.loadQuestionIDs(tenantId, feedbackId);
    }
  }

  loadDocument() {
    const { documentId, feedbackId, tenantId } = this.props;

    UserService.getUserDocument(documentId)
      .then((response) => {
        this.onChangeState();

        if (feedbackId) {
          this.loadQuestionIDs(tenantId, feedbackId);
        }
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err.message);
      });
  }

  loadQuestionIDs = (tenantId, documentId) => {
    var self = this;
    const { feedbackState } = this.props;
    FeedbackService.getQuestionIDs(tenantId, documentId)
      .then(function (response) {
        feedbackState.questionIDs = response;
        self.onChangeState();
        if (response.length > 0) {
          self.loadQuestion(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  onChangeState = () => {
    this.props.onChange(this.props.feedbackState);
  };

  handleToggleCheckbox = (value) => () => {
    const { feedbackState } = this.props;
    const { checked, question, myAnswers } = feedbackState;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let answerCopy = [...myAnswers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length) {
      let found = false;
      for (let i = 0; i < myAnswers.length; i++) {
        if (myAnswers[i]._id === question._id) {
          answerCopy[i].checked = newChecked;
          found = true;
          break;
        }
      }
      if (!found) {
        answerCopy.push({ _id: question._id, checked: newChecked });
      }
    } else {
      //Remove question if no answer is provided
      const i = answerCopy.findIndex((a) => a._id === question.id);
      answerCopy.splice(i, 1);
    }

    this.editOrCreateJsonQuestionByID(question._id, question, newChecked);

    feedbackState.checked = newChecked;
    feedbackState.myAnswers = answerCopy;
    this.onChangeState();
  };

  editOrCreateJsonQuestionByID = (id, question, checkedIndexOfAnswers) => {
    const { feedbackState } = this.props;
    const { json } = feedbackState;
    let containts = false;
    let checkedAnswers = [];

    if (checkedIndexOfAnswers.length) {
      checkedIndexOfAnswers.forEach((index) => {
        checkedAnswers.push(question.answers[index].answer);
      });

      for (let i = 0; i < json.questions.length; i++) {
        if (json.questions[i].question._id === id) {
          if (question !== undefined) {
            json.questions[i].question = question;
          }
          if (checkedIndexOfAnswers !== undefined) {
            json.questions[i].checkedAnswers = checkedAnswers;
          }
          containts = true;
          break;
        }
      }
      if (!containts) {
        json.date_of_filling_in = Date.now();
        json.questions.push({
          question: question,
          checkedAnswers: checkedAnswers,
        });
      }
    } else {
      //Remove question if no answer is provided
      const i = json.questions.findIndex((q) => q._id === question.id);
      json.questions.splice(i, 1);
    }

    this.onChangeState();
  };

  handleNext = () => {
    const { feedbackState } = this.props;
    const { activeIndex, questionIDs } = feedbackState;
    if (activeIndex !== questionIDs.length - 1) {
      this.loadQuestion(activeIndex + 1);
      feedbackState.activeIndex = activeIndex + 1;
      this.onChangeState();
    } else {
      this.loadSumbit();
    }
  };

  handleBack = () => {
    const { feedbackState } = this.props;
    const { activeIndex } = feedbackState;
    this.loadQuestion(activeIndex - 1);
    feedbackState.activeIndex = activeIndex - 1;
    this.onChangeState();
  };

  loadSumbit = () => {
    const { feedbackState } = this.props;
    const { feedbackName, feedbackId, visitId } = this.props;
    let { json, myAnswers, activeIndex } = feedbackState;

    json.date_of_filling_in = Date.now();
    json.answered = myAnswers.length;
    json.totalQuestions = feedbackState.questionIDs.length;
    json.name = feedbackName;
    json.document_id = feedbackId;
    json.visit_id = visitId;

    feedbackState.question = { question: '', answers: [] };
    feedbackState.activeIndex = activeIndex + 1;
    feedbackState.isOpenSubmit = true;
    this.onChangeState();
    console.log(json);
  };

  loadQuestion = (index) => {
    const self = this;
    const { feedbackState } = this.props;
    const { questionIDs } = feedbackState;
    feedbackState.question = { question: '', answers: [] };
    feedbackState.checked = [];
    feedbackState.isOpenSubmit = false;
    this.onChangeState();
    if (questionIDs.length > index) {
      FeedbackService.getQuestion(questionIDs[index]._id)
        .then(function (response) {
          feedbackState.question = response;
          feedbackState.checked =
            feedbackState.myAnswers.find((a) => a._id === response._id) !==
            undefined
              ? feedbackState.myAnswers.find((a) => a._id === response._id)
                  .checked
              : [];
          self.props.onChange(feedbackState);
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  handleSendAnswers = () => {
    const self = this;
    const { json } = this.props.feedbackState;
    const { documentId } = this.props;
    const status = user_document_status_constants.COMPLETED;
    //user_id, user_document_id, status, data
    UserService.sendFeedbackAnswers(documentId, status, { ...json })
      .then(function (response) {
        openSnackbar(i18n.t('sended'));
        self.props.onChange(createEmptyFeedbackState(), true);
        self.handleGoToDocuments();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleGoToDocuments = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, theme, feedbackState } = this.props;
    const {
      questionIDs,
      question,
      checked,
      isOpenSubmit,
      myAnswers,
      activeIndex,
    } = feedbackState;

    return (
      <Aux>
        {!isOpenSubmit && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            className={classNames(classes.grid, classes.contentOfVmsInfoBar)}
          >
            <Grid item className={classes.htmlParser}>
              <Typography
                data-cy="feedback-text-question"
                variant="h5"
                component="h3"
              >
                {activeIndex + 1 + '. '} {question.questionTitle}
              </Typography>
              {Parser(question.question)}
            </Grid>
            <Grid item>
              <List className={classes.answers}>
                {question.answers.map((ans, index) => (
                  <ListItem
                    data-cy="feedback-list-item-answer"
                    key={index}
                    dense
                    button
                    className={classes.listItem}
                    onClick={this.handleToggleCheckbox(index)}
                  >
                    <ListItemText
                      data-cy="feedback-text-answer-name"
                      primary={ans.answer}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        data-cy="feedback-checkbox-answer-checkbox"
                        color="primary"
                        onChange={this.handleToggleCheckbox(index)}
                        checked={checked.indexOf(index) !== -1}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <MobileStepper
                variant="progress"
                steps={questionIDs.length + 1}
                position="static"
                activeStep={activeIndex}
                nextButton={
                  <Button
                    data-cy="feedback-button-next"
                    size="small"
                    onClick={this.handleNext}
                    disabled={activeIndex === questionIDs.length}
                  >
                    {i18n.t('next')}
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    data-cy="feedback-button-back"
                    size="small"
                    onClick={this.handleBack}
                    disabled={activeIndex === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    {i18n.t('back')}
                  </Button>
                }
                className={classes.stepper}
              />
            </Grid>
          </Grid>
        )}
        {isOpenSubmit && (
          <div className={classes.contentOfVmsInfoBar}>
            <FeedbackDialog
              open={isOpenSubmit}
              totalQuestions={questionIDs.length}
              totalAnsweredQuestions={myAnswers.length}
              sendAnswers={this.handleSendAnswers}
              onClose={this.handleBack}
            />
          </div>
        )}
      </Aux>
    );
  }
}

FeedbackDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  feedbackId: PropTypes.string.isRequired,
  feedbackName: PropTypes.string.isRequired,
  visitId: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  feedbackState: PropTypes.shape({
    activeIndex: PropTypes.number.isRequired,
    question: PropTypes.shape({
      _id: PropTypes.string,
      questionTitle: PropTypes.string,
      answers: PropTypes.array,
    }),
    checked: PropTypes.array,
    isLoading: PropTypes.bool,
    myAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        checked: PropTypes.any,
        answer: PropTypes.bool,
      })
    ),
    isOpenSubmit: PropTypes.bool,
    json: PropTypes.shape({
      date_of_filling_in: PropTypes.any,
      questions: PropTypes.array,
    }),
  }).isRequired,
  tenantId: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(FeedbackDetail)
);
