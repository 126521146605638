import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useInvitationState = () => {
  const [t] = useTranslation();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [selectedVisit, setselectedVisit] = useState(null);
  const [editAsGroupInvite, setEditAsGroupInvite] = useState(false);

  const editVisit = useCallback((visit, editAsGroupInvite) => {
    setInviteDialogOpen(true);
    setselectedVisit(visit);
    setEditAsGroupInvite(editAsGroupInvite);
  }, []);

  const handleEditVisit = useCallback(
    (visit) => {
      if (visit.group) {
        handleOpenConfirmDialog(
          t('dashboard_visitors_dialog_group_edit_title'),
          t('dashboard_visitors_dialog_group_edit_description'),
          () => editVisit(visit, true),
          () => editVisit(visit, false)
        );
      } else {
        editVisit(visit, false);
      }
    },
    [editVisit, t]
  );

  const handleCloseVisit = () => {
    setInviteDialogOpen(false);
  };

  return [
    inviteDialogOpen,
    selectedVisit,
    editAsGroupInvite,
    handleEditVisit,
    handleCloseVisit,
  ];
};

export default useInvitationState;
