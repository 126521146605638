import { Component } from 'react';
import commonStyles from 'assets/jss/commonStyles';
import { withStyles, Typography, Grid } from '@material-ui/core';
import Aux from 'hoc/Auxiliary';
import i18n from 'assets/i18n';

const styles = (theme) => ({
  ...commonStyles(theme),
  errorContainer: {
    padding: '8px',
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Aux>
          <Grid
            className={this.props.classes.errorContainer}
            container
            direction="column"
          >
            <Typography variant="h3" align="center">
              {i18n.t('error_boundary_error')}
            </Typography>
            <Typography align="center">
              {this.props.message
                ? this.props.message
                : i18n.t('error_boundary_generic')}
            </Typography>
          </Grid>
        </Aux>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
