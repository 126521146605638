import AbstractService from '../services/AbstractService';
import Auth from '../modules/Auth';

class PushNotificationServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'push';
  }
  /**
   * Send push notification to specified user.
   *
   * @param {string[]} to Array of person IDs which should get notification
   * @param {string} title Text displayed in notification title
   * @param {string} body Text displayed in notification body
   */
  send(to, title, body) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    const pushData = {
      title: title,
      body: body,
      to: to,
    };

    return this.post(this.endpoint, pushData, config);
  }

  /**
   * Send push notification template to specified user.
   *
   * @param {string[]} to Array of person IDs which should get notification
   * @param {object} template Notification template
   */
  sendTemplate(to, template) {
    return this.send(to, template.title, template.body);
  }
}

const PushNotificationService = new PushNotificationServiceImpl();

export default PushNotificationService;
