import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  itinerary_categories,
  itinerary_types_accomodation,
  itinerary_types_transport,
  itinerary_type_icon,
} from 'AppSettings';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  list: {
    minWidth: 200,
  },
  itemIcon: {
    color: theme.palette.primary4,
  },
}));

const ItineraryTypeDialog = ({ open, onClose, onTypeAdd, type }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  let list = [];
  switch (type) {
    case itinerary_categories.TRANSPORT:
      list = itinerary_types_transport;
      break;
    case itinerary_categories.ACCOMODATION:
      list = itinerary_types_accomodation;
      break;
    default:
      break;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <List className={classes.list}>
          {Object.keys(list).map((key) => {
            const Icon = itinerary_type_icon(list[key]);
            return (
              <ListItem
                data-cy="itinerary-type-list-item-add"
                button
                onClick={onTypeAdd(type, list[key])}
                key={key}
              >
                <ListItemIcon>
                  <Icon className={classes.itemIcon} />
                </ListItemIcon>
                <ListItemText style={{ padding: 0 }}>
                  <Typography className={classes.itemIcon}>
                    {t(list[key])}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </div>
      <DialogActions>
        <Button
          data-cy="itinerary-type-button-close"
          onClick={onClose}
          color="primary"
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ItineraryTypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTypeAdd: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ItineraryTypeDialog;
